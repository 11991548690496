import { html, render } from "lit";

import "../components/avoidable-events/avoidable-events-header";
import "../components/avoidable-events/avoidable-events-patient-visits";
import "../components/avoidable-events/avoidable-events-missed-overview";
import "../components/avoidable-events/avoidable-events-hospital-resumptions";
import "../components/avoidable-events/avoidable-events-visit-cost";
import "../components/avoidable-events/avoidable-events-minute-cost";

export default class SceneAvoidableEventsMetrics extends HTMLElement {
    constructor() {
        super();
        this._metrics = null;
    }

    get metrics() {
        return this._metrics;
    }
    set metrics(value) {
        this._metrics = value;
        this.render();
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <div
                    id="chart_wrapper"
                    style="
                        display: flex; 
                        flex-wrap: wrap; 
                        flex-direction: row; 
                        align-items: center; 
                        justify-content: flex-start;
                    "
                >
                    <div class="col-12 col-lg-6 col-xl-4">
                        <app-avoidable-events-patient-visits .metrics=${this.metrics}></app-avoidable-events-patient-visits>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4">
                        <app-avoidable-events-missed-overview .metrics=${this.metrics}></app-avoidable-events-missed-overview>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4">
                        <app-avoidable-events-hospital-resumptions .metrics=${this.metrics}></app-avoidable-events-hospital-resumptions>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4">
                        <app-avoidable-events-visit-cost .metrics=${this.metrics}></app-avoidable-events-visit-cost>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4">
                        <app-avoidable-events-minute-cost .metrics=${this.metrics}></app-avoidable-events-minute-cost>
                    </div>
                </div>
            `;
        };

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.metrics) return;
        render(this.template(), this);
    }
}

customElements.define("scene-avoidable-events-metrics", SceneAvoidableEventsMetrics);