import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
import SurveyDefinition from "shared/lib/lib-survey-definition";
import { adddirectusClientFilter, getCurrentClientId, } from "shared/lib/lib-user";
import {
  Chart,
  DoughnutController,
  ArcElement,
  CategoryScale,
  Tooltip,
} from "chart.js";
Chart.register(DoughnutController, ArcElement, CategoryScale, Tooltip);

import "shared/components/app-color-label";
import "../components/dashboard/app-dashboard-critical-alerts";
//import "../components/dashboard/app-dashboard-noncritical-alerts";
import "../components/dashboard/app-dashboard-level-two-alerts";
import "../components/dashboard/app-dashboard-level-three-alerts";
import "../components/dashboard/app-dashboard-high-risk-events";
import "../components/dashboard/app-dashboard-high-risk-events-chart";
import "../components/dashboard/app-dashboard-hospitalization";
import "../components/dashboard/app-dashboard-oasis-transfers";
import "../components/dashboard/app-dashboard-availability";
//import "../components/dashboard/app-dashboard-checkpoints";
import "../components/dashboard/app-dashboard-visits.js";
import "../components/dashboard/app-dashboard-communication.js";
import "../components/dashboard/app-dashboard-communication-weekly.js";
import "../components/dashboard/app-dashboard-satisfaction";
import "../components/dashboard/app-dashboard-satisfaction-chart";
import "../components/dashboard/app-dashboard-header";
//import "../components/dashboard/app-dashboard-alert-list";
import "../components/dashboard/app-dashboard-care-experience.js";
import AppAlertDetail from "shared/components/alert/app-alert-detail";

export default class SceneDashboardOverview extends HTMLElement {
  constructor() {
    super();
    this.alerts = [];
  }

  set alerts(value) {
    this._alerts = value;
    this.render();
  }

  get alerts() {
    return this._alerts;
  }

  set metrics(value) {
    this._metrics = value;
    this.render();
  }

  get metrics() {
    return this._metrics;
  }

  set alert_metrics(value) {
    this._alert_metrics = value;
    this.render();
  }

  get alert_metrics() {
    return this._alert_metrics;
  }

  connectedCallback() {
    this.template = () => {
      return html`
        <style>
          #dashboard-overview {
            height: 100%;
          }
          #dashboard-overview .row {
            height: 100%;
          }
          #dashboard-overview .scroll-container {
            padding: 0 10px 16px 16px;
            overflow-x: hidden;
            height: calc(100% - 150px);
          }

          .alerts_overview_critical_alert_item:hover {
            background-color: #f0f0f0;
            cursor: pointer;
          }
          /* #dashboard-overview canvas {
            max-height: 180px;
            margin-top: 16px;
            z-index: 1;
          } */
          #dashboard-overview .scroll-container .col-12 > * {
            height: 360px;
          }
          #dashboard-overview #chart_wrapper > div {
            padding: 0 16px;
            margin: 0px 0 32px 0;
          }
          #dashboard-overview app-dashboard-alert-list {
            padding: 16px 16px 0 16px;
            margin-top: 16px;
            display: block;
          }
          #dashboard-overview #alerts_overview_chart > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
          }
          #dashboard-overview #alert-chart-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 110px;
            align-self: center;
          }
          #dashboard-overview #alert-chart-inner h3 {
            height: 56px;
            font-weight: 700;
            font-size: 50px;
            line-height: 56px;
            letter-spacing: 0.5px;
            color: var(--t-color-dark);
          }
          #dashboard-overview #alert-chart-inner span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: var(--t-color-grey);
          }
        </style>
        <div id="dashboard-overview" class="container-fluid">
          <div class="row">
            <app-dashboard-header
              .page=${"overview"}
              style="padding: 0 32px;"
              class="col-12"
            ></app-dashboard-header>
            <div class="scroll-container">
              <div
                id="chart_wrapper"
                style="
                                    display: flex; 
                                    flex-wrap: wrap; 
                                    flex-direction: row; 
                                    align-items: center; 
                                    justify-content: flex-start;
                                "
              >
                <div class="col-12 col-lg-6 col-xl-4 dash-widget alerts_overview_list">
                  <app-dashboard-critical-alerts
                    .alerts=${this.alerts}
                    .survey_definition=${this.survey_definition}
                    @alertclick=${(e) => this.showAlert(e.detail)}
                  >
                  </app-dashboard-critical-alerts>
                </div>
                <!-- <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link" id="alerts_overview_list">
                                    <app-dashboard-noncritical-alerts
                                        .alerts=${this.alerts}
                                        .survey_definition=${this
          .survey_definition}
                                        @alertclick=${(e) =>
          this.showAlert(e.detail)}
                                    >
                                    </app-dashboard-noncritical-alerts>
                                </div> -->
                <div class="col-12 col-lg-6 col-xl-4 dash-widget alerts_overview_list">
                  <app-dashboard-level-two-alerts
                    .alerts=${this.alerts}
                    .survey_definition=${this.survey_definition}
                    @alertclick=${(e) => this.showAlert(e.detail)}
                  >
                  </app-dashboard-level-two-alerts>
                </div>
                <div class="col-12 col-lg-6 col-xl-4 dash-widget alerts_overview_list">
                  <app-dashboard-level-three-alerts
                    .alerts=${this.alerts}
                    .survey_definition=${this.survey_definition}
                    @alertclick=${(e) => this.showAlert(e.detail)}
                  >
                  </app-dashboard-level-three-alerts>
                </div>
                <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-satisfaction-chart class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-satisfaction-chart>
                </div>
                <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-high-risk-events-chart class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-high-risk-events-chart>
                </div>


                <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-oasis-transfers class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-oasis-transfers>
                </div>


             <!--   <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-hospitalization class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-hospitalization>
                </div> -->


                <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-care-experience class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-care-experience>
                </div>
                
                <!--
                <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-availability class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-availability>
                </div>

                
                <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-visits class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-visits>
                </div>
                -->

                <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-communication-weekly class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-communication-weekly>
                  </div>
                <div class="col-12 col-lg-6 col-xl-4 dash-widget dash-widget-link">
                  <app-dashboard-communication class="dash-overview-apps"
                    .metrics=${this.metrics}
                  ></app-dashboard-communication>
                </div>
              </div>

              <!-- <app-dashboard-alert-list
                                class="col-12"
                                .alerts=${this.alerts}
                                .alert_metrics=${this.alert_metrics}
                                .show_patient_name=${true}
                                @alertclick=${(e) => this.showAlert(e.detail)}
              ></app-dashboard-alert-list> -->
              
            </div>
          </div>
        </div>
      `;
    };

    this.init();
    this.render();
  }

  async loadAlerts() {
    let result = await directus.items("alert").readByQuery({
      fields: [
        "*",
        "patient_id.id",
        "patient_id.first_name",
        "patient_id.last_name",
        "survey_id.*",
        "survey_id.survey_schedule_item_id.day",
        "survey_id.episode_id.start_date",
        "survey_id.episode_id.end_date",
        "survey_id.survey_schedule_item_id.form_design_id",
        "last_view_user_id.first_name",
        "last_view_user_id.last_name",
      ],
      filter: adddirectusClientFilter({
        status: "active",
        date_created: {
          _gte: past30DaysISO
        },
      }),
      limit: "-1",
      sort: ["level", "-date_created"],
    });
    this.alerts = result.data || [];
    this.alert_metrics = {
      "Level 1": 0,
      "Level 2": 0,
      "Level 3": 0,
    };
    for (let alert of this.alerts) {
      this.alert_metrics["Level " + alert.level]++;
    }
    this.render();
  }
  render() {
    if (!this.template) return;

    if (!this.metrics) return;

    render(this.template(), this);
    // this.renderAlertsChart();
  }

  async init() {
    this.survey_definition = await SurveyDefinition.getSurveyDefinition();
    await this.loadMetrics();
    await this.loadAlerts();
  }

  async loadMetrics() {
    //const user_today_midnight = new Date();
    //user_today_midnight.setHours(0, 0, 0, 0);

    const client_id = getCurrentClientId();
    const result = await directus.transport.get("/vbh/dashboard_metrics", {
      params: {
        client_ids: [client_id],
        // user_today_midnight,
      },
    });
    this.metrics = result.data.dashboard_metrics;
  }
  async loadAlerts() {

    const today = new Date();
    const past30Days = new Date(today); // Clone the date object
    past30Days.setDate(today.getDate() - 30); // Adjust the date
    const past30DaysISO = past30Days.toISOString();

    let result = await directus.items("alert").readByQuery({
      fields: [
        "*",
        "patient_id.id",
        "patient_id.first_name",
        "patient_id.last_name",
        "survey_id.*",
        "survey_id.survey_schedule_item_id.day",
        "survey_id.episode_id.start_date",
        "survey_id.episode_id.end_date",
        "survey_id.survey_schedule_item_id.form_design_id",
        "last_view_user_id.first_name",
        "last_view_user_id.last_name",
      ],
      filter: adddirectusClientFilter({
        //status: "active",
        date_created: {
          _gte: past30DaysISO
        },
      }),
      limit: "-1",
      sort: ["status", "-date_created"],
    });
    this.alerts = result.data || [];
    this.alert_metrics = {
      "Level 1": 0,
      "Level 2": 0,
      "Level 3": 0,
    };
    for (let alert of this.alerts) {
      this.alert_metrics["Level " + alert.level]++;
    }
    this.render();
  }

  async showAlert(alert) {
    let alert_modal = new AppAlertDetail();
    alert_modal.alert = alert;
    alert_modal.patient = this.patient;
    await alert_modal.showModal();
    let result = await alert_modal.onDidDismiss();
    if (result) await this.loadAlerts();
  }
}
customElements.define("scene-dashboard-overview", SceneDashboardOverview);


