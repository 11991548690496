import { html, render } from "lit";
import { ref, createRef } from "lit/directives/ref.js";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"

import directus from "shared/lib/lib-directus";
import DataDefinition from "shared/lib/lib-data-definition";
import { adddirectusClientFilter, getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";

import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";

import { noteCellFormatter } from "../util/lib-report";

window.luxon = { DateTime };

/**
 * @todo report superclass with common sort/filter functions, calls this.fetchData()
 * An encapuslated table view of unplanned patient hospitalizations.
 * Data matches dashboard widget <app-dashboard-hospitalization>.
 */
export default class ReportPatientHospitalization extends HTMLElement {
    static get config() {
        return {
            icon: "local_hospital",
            report: "hospitalization",
            title: "Patient Hospitalization (old)",
            description: "Unreported hospital care events.",
            component: ReportPatientHospitalization
        };
    }

    constructor() {
        super();
        this.data = [];
        this.data_table = null;
        this._loading_data = true;

        // filters
        this.patient_status_filter_options = [];
        this.length_of_stay_filter_options = [];
        this.hha_notified_filter_options = [];
        const filter_string = new URL(location.href).searchParams?.get("filters")
        this.filters = JSON.parse(filter_string) || {};
        //pagination
        this._current_page = 1;
        this._page_size = 1000000;
        this._all_pages_fetched = false;

        //sort
        this.sort_fields = [{ column: "survey_completion_date", direction: "desc" }];

        this.header_ref = createRef();
        this.content_ref = createRef()
    }

    get filters_expanded() {
        return this._filters_expanded;
    }

    set filters_expanded(value) {
        this._filters_expanded = value;
        this.render();
    }

    connectedCallback() {
        // This alows showing/hiding the filter dropdown through css and avoid multiple filter instances
        // const filters_breakpoint_query = window.matchMedia("(min-width: 1275px)");
        // this.filters_expanded = filters_breakpoint_query.matches
        // filters_breakpoint_query.addEventListener("change", (evt) => {
        //     this.filters_expanded = evt.matches
        //     if (!evt.matches) {
        //         this.initFilterDropdown();
        //     }
        // });

        this.template = () => {
            return html`
              
                   <div id="main-content" class="container-fluid">
                        <div id="widget_wrapper">
                            <div id="widget_wrapper">
                                <div class="button-list">
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Patient Status"}
                                            .options=${this.patient_status_filter_options}
                                            .value=${this.filters.patient_status}
                                            @optionclick=${e => this.handleFilterChange("patient_status", e)}
                                            @clear=${_e => this.handleFilterClear("patient_status")}
                                        ></app-report-filter>
                                    </div>
                                    <!-- <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Primary Clinician"}
                                            .options=${this.primary_clinician_options || []}
                                            .value=${this.filters.primary_clinician}
                                            @optionclick=${e => this.handleFilterChange("primary_clinician", e)}
                                            @clear=${_e => this.handleFilterClear("primary_clinician")}
                                        ></app-report-filter>
                                    </div> -->
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Date Reported"}
                                            .is_datepicker=${true}
                                            .value=${this.filters.survey_completion_date_range}
                                            @dateselect=${e => this.handleDateFilterChange(e)}
                                            @clear=${_e => this.handleFilterClear("survey_completion_date_range")}
                                        ></app-report-filter>
                                    </div>
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Length of Stay"}
                                            .options=${this.length_of_stay_filter_options}
                                            .value=${this.filters.pr_acc_stay_length}
                                            @optionclick=${e => this.handleFilterChange("pr_acc_stay_length", e)}
                                            @clear=${_e => this.handleFilterClear("pr_acc_stay_length")}
                                        ></app-report-filter>
                                    </div>
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"HHA Notified"}
                                            .options=${this.hha_notified_filter_options}
                                            .value=${this.filters.pr_er_called_hha}
                                            @optionclick=${e => this.handleFilterChange("pr_er_called_hha", e)}
                                            @clear=${_e => this.handleFilterClear("pr_er_called_hha")}
                                        ></app-report-filter>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="mod-base" id="patient-hospitalization-table" data-toggle="table"></div>
                    </div>
                </div>
            `
        }

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Load survey definitions, fetch report data, and update table.
     */
    async init() {
        this.fetchFilterOptions();
        await this.fetchHospitalizationData();
        this.initTable();
        this.setTableHeight();
    }

    /**
     * Fetches latest report data and updates the data table. 
     */
    async fetchHospitalizationData() {
        if (!this._page_size) {
            // still being calculated/initialized
            return;
        }

        this._loading_data = true;
        const client_id = getCurrentClientId()
        const {
            patient_status = ['active', 'inactive'],
            primary_clinician,
            survey_completion_date_range,
            pr_acc_stay_length,
            pr_er_called_hha
        } = this.filters

        const res = await directus.transport.get('/vbh/reports/hospitalization', {
            params: {
                filters: {
                    client_ids: [client_id],
                    patient_status,
                    primary_clinician,
                    survey_completion_date_range,
                    pr_acc_stay_length,
                    pr_er_called_hha,
                },
                page_size: this._page_size,
                page: this._current_page,
                sort: JSON.stringify(this.sort_fields)
            }
        });

        // Tabulator Table
        this.data = this.formatRecords(res.data);
        this.data_table?.setData(this.data);

        this._all_pages_fetched = res.data.length < this._page_size;

        this.render();
        this._loading_data = false;
    }

    async fetchFilterOptions() {
        const primary_clinician_data = await directus.items("junction_directus_users_client").readByQuery({
            fields: ["*", "directus_users_id.*"],
            filter: adddirectusClientFilter({
                directus_users_id: {
                    role: {
                        name: { _in: ["Clinician", "Client"] }
                    }
                },
            }),
        });
        const unique_clinician_ids = new Set();
        this.primary_clinician_options = primary_clinician_data.data
            .map(item => ({
                value: item.directus_users_id.id,
                label: `${item.directus_users_id.first_name || ''} ${item.directus_users_id.last_name || ''}`,
            }))
            .filter(option => {
                if (!unique_clinician_ids.has(option.value)) {
                    unique_clinician_ids.add(option.value);
                    return true;
                }
                return false;
            });

        const patient_definition = await DataDefinition.getDefinition("patient");
        this.patient_status_filter_options = patient_definition.field_dictionary.status.meta.options.choices
            .filter(choice => choice.value === "active" || choice.value === "inactive")
            .map(
                choice => ({
                    value: choice.value,
                    label: choice.text,
                })
            );
        this.length_of_stay_filter_options = [
            { value: 'more_than_two_nights', label: "More than 2 Nights in ER" },
            { value: 'less_than_two_nights', label: "Less than 2 Nights in ER" },
        ];

        this.hha_notified_filter_options = [
            { value: "no", label: "No" },
            { value: "yes", label: "Yes" }
        ]

        this.render();
    }

    /**
     * Format records to display tabulator table.
     * @param {*} records 
     * @returns 
     */
    formatRecords(records) {
        //console.log("formatRecords" + JSON.stringify(records));

        return records
            .filter(record => record.current_episode_id !== null && record.last_task_id !== null)
            .map(record => ({
                patient_id: record.patient_id,
                survey_id: record.survey_id,
                patient_status: (record.patient_status?.charAt(0)?.toUpperCase() + record.patient_status?.slice(1)) || "",
                patient_first_name: record.patient_first_name,
                patient_last_name: `${record.patient_first_name || ""} ${record.patient_last_name || ""}`,
                primary_clinician_last_name: `${record.primary_clinician_first_name || ""} ${record.primary_clinician_last_name || ""}`,
                survey_completion_date: record.survey_completion_date,
                pr_acc_stay_length: this.formatPrAccStayLength(record.pr_acc_stay_length),
                pr_er_called_hha: (record.pr_er_called_hha?.charAt(0)?.toUpperCase() + record.pr_er_called_hha?.slice(1)) || "",
                notes: noteCellFormatter(record.notes) // Assuming noteCellFormatter is in scope
            }));
    }

    formatPrAccStayLength(accStayLength) {
        switch (accStayLength) {
            case 'more_than_two_nights':
                return "More than 2 Nights in ER"
            case 'less_than_two_nights':
                return "Less than 2 Nights in ER"
            default:
                return accStayLength;
        }
    }

    /**
     * @param {CustomEvent} e AppReportFilter#dateselect event
     */
    handleDateFilterChange(e) {
        const { start, end } = e.detail
        this.filters["survey_completion_date_range"] = `${getISODateStringWithoutTime(start)},${getISODateStringWithoutTime(end)}`;
        this.fetchHospitalizationData();
    }

    /**
     * handler for AppReportFilter#clear
     * @param {string} filter_key one of this.filters
     */
    handleFilterClear(filter_key) {
        delete this.filters[filter_key];
        this.fetchHospitalizationData();
    }

    /**
     * @param {string} filter_key one of this.filters
     * @param {CustomEvent} e AppReportFilter#optionclick event
     */
    handleFilterChange(filter_key, e) {
        if (e.detail) this.filters[filter_key] = e.detail;
        else delete this.filters[filter_key];
        this.fetchHospitalizationData();
    }

    /**
     * Initializes an instance of TabulatorTable with the specified options. 
     */
    initTable() {
        //console.log("initTable" + JSON.stringify(this.filters));
        this.data_table = new Tabulator("#patient-hospitalization-table", {
            data: this.data,           //load row data from array
            history: true,             //allow undo and redo actions on the table
            pagination: "local",       //paginate the data
            paginationSize: 25,         //allow 7 rows per page of data
            paginationCounter: "rows", //display count of paginated rows in footer
            layout: "fitDataStretch",
            layoutColumnsOnNewData: true, //fit columns to width of table (optional)
            movableColumns: true,      //allow column order to be changed
            initialSort: [             //set the initial sort order of the data
                { column: "survey_completion_date", dir: "desc" },
            ],
            columnDefaults: {
                tooltip: true,         //show tool tips on cells
            },
            columns: [
                { title: "Patient Status", field: "patient_status" },
                { title: "Patient Name", field: "patient_last_name" },
                // { title: "Primary Clinician", field: "primary_clinician_last_name" },
                { title: "Date Reported", field: "survey_completion_date", formatter: this.dateFormatter },
                { title: "Length of ER/AC Stay", field: "pr_acc_stay_length" },
                { title: "HHA Notified", field: "pr_er_called_hha" },
                { title: "Additional Notes/Comments", field: "notes", formatter: "html", headerSort: false, widthGrow: 5 },
            ]
        });

        this.data_table.on("rowClick", function (e, row) {
            let hospitalization = row.getData();
            navigate(`patients/${hospitalization.patient_id}/checkpoints/${hospitalization.survey_id}`)
        });
    }

    dateFormatter(cell) {
        const time = cell.getValue();

        if (time && time != "")
            return DateTime.fromISO(time).toFormat('MM/dd/yyyy');
        return "";
    }
    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }
}

customElements.define("report-patient-hospitalization", ReportPatientHospitalization);
