import { html, nothing, render } from 'lit';
import ApplicationState from 'applicationstate';
import { navigate } from '../lib/lib-router';
import '../components/filter/app-filter';
//import '../components/filter/app-filter-data-view';
import '../components/filter/app-filter-data-view-server';
import '../components/filter/app-filter-saved-views';
import '../components/filter/app-filter-bar-graph';
import '../components/filter/app-filter-big-number';
import { getCurrentClientId } from '../lib/lib-user';
import "../components/filter/app-filter-csv-export";
import directus from '../lib/lib-directus';
import AppAlertDetail from '../components/alert/app-alert-detail';

export default class SceneInsightsAlerts extends HTMLElement {
    set filter_state(value) {
        this._filter_state = value;
    }

    /** @type {import('shared/components/filter/app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;
    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    get selected_columns() {
        return this._selected_columns;
    }

    set view(value) {
        this._view = value;
        this.render();
    }

    get view() {
        return this._view;
    }

    set data(value) {
        this._data = value;
        this.render();
    }

    get data() {
        return this._data;
    }
    get options() {
        return this._options;
    }

    set options(value) {
        this._options = value;

    }
    set table_columns(value) {
        this._table_columns = value;
    }
    get table_columns() {
        return this._table_columns;
    }
    constructor() {
        super();
        this._options = {
            ajaxURL: "/items/alert",
            ajaxParams: {},
            sortMode: "remote",
            filterMode: "remote",
            pagination: true,
            paginationMode: "remote",
            paginationSize: 25,
            paginationCounter: "rows",
            alignEmptyValues: "bottom",
            layout: "fitColumns",
            //layoutColumnsOnNewData: true,  
            movableColumns: true,
            initialSort: [{ column: "date_created", dir: "desc" },],
            columnDefaults: {
                tooltip: true,
            },
            checkbox: false
        };
        this._selected_columns = [
            "level",
            'date_created',
            'patient_id.first_name',
            'patient_id.last_name',
            'subject',
            'last_view_user_id.first_name',
            'last_view_user_id.last_name',
            'last_view_date'
        ];
        this.table_columns = [
            {
                name: "Level",
                fields: ["level"],
                field: "level",
            },
            {
                name: "Date Created",
                fields: ["date_created"],
                field: "date_created",
                formatter: "datetime",
                formatterParams: {
                    inputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    outputFormat: "MM/dd/yyyy",
                    invalidPlaceholder: "(invalid date)"
                }
            },
            //{
            //    name: "Patient Name",
            //    fields: ["patient_id.last_name", "patient_id.first_name"],
            //    field: "patient_name",
            //    link: ", ",
            //    sorter: "string",
            //    sorterParams: {
            //        field: "patient_id.last_name"
            //    }
            //},
            {
                title: "Patient Name",
                field: "patient_id.last_name",
                formatter: row => `${row.getData().patient_id.first_name} ${row.getData().patient_id.last_name}`
            },
            {
                name: "Subject",
                fields: ["subject"],
                field: "subject",
            },
            {
                title: "Last View User",
                field: "last_view_user_id.last_name",
                formatter: row => `${row.getData().last_view_user_id.first_name} ${row.getData().last_view_user_id.last_name}`
            },
            //{
            //    name: "Last View User",
            //    fields: ["last_view_user_id.first_name", "last_view_user_id.last_name"],
            //    field: "last_view_user",
            //    link: " ",
            //    sorter: "string",
            //    sorterParams: {
            //        field: "last_view_user_id.last_name"
            //    }
            //},
            {
                name: "Last View Date",
                fields: ["last_view_date"],
                field: "last_view_date",
                formatter: "datetime",
                formatterParams: {
                    inputFormat: "yyyy-MM-dd'T'HH:mm:ss",
                    outputFormat: "MM/dd/yyyy",
                    invalidPlaceholder: "(invalid date)"
                }
            }
        ];

        this._filter_config = {
            collection: { name: 'alert', auto_configure: true, depth: 3 },
            search_fields: [
                'patient_id.first_name',
                'patient_id.last_name',
                'mrn',
                'client_id.name',
                'last_view_user_id.first_name',
                'last_view_user_id.last_name',
            ],
            field_rules: {
                hh_patient_reported_measures: {
                    mode: "blacklist",
                    fields: [
                        "user_created",
                        "date_created",
                        "user_updated",
                        "escalated_checkpoint",
                        "checkpoint_summary",
                        "pr_med_mgmt_discussion",
                        "pr_med_bottle_review",
                        "pr_ct_healthy_routine_discussion",
                        "pr_ct_healthy_routine_discussion",
                        "pr_ct_med_routine_established",
                        "pr_ct_listend_understood",
                        "pr_ct_visit_communication",
                        "pr_ct_gentle",
                        "pr_ct_kind_respectful",
                        "pr_ct_preparedness_visit",
                        "pr_ct_listened_understood",
                        "pr_ct_pain",
                        "submission",
                        "survey_schedule_item_id",
                    ],
                },
                patient: {
                    mode: "blacklist",
                    fields: [
                        "created_by_integration_id",
                        "updated_by_integration_id",
                        "scorecard",
                        "access",
                        "checkpoint_results",
                        "accordion-9h-2nxw",
                        "internal_memo",
                        "tasks",
                        "sort_date",
                        "user_created",
                        "user_updated",
                        "date_created",
                        "date_updated",
                        "last_task_id",
                    ],
                },
                client: {
                    mode: "whitelist",
                    fields: [
                        "name",
                    ]
                },
                episode: {
                    mode: "blacklist",
                    fields: [
                        "survey_schedule_id",
                        "auto_create_tasks",
                        "patient_id",
                        "user_created",
                        "user_updated",
                        "date_created",
                        "date_updated",
                    ],
                },
                alert: {
                    mode: "blacklist",
                    fields: [
                        "sort",
                    ],
                },
                directus_users: {
                    mode: "whitelist",
                    fields: [
                        "first_name",
                        "last_name",
                    ],
                }
            }
        };
    }

    connectedCallback() {
        this.template = () => {

            return html`
                <style>
                    /* TODO */
                    .task-row:hover {
                        background-color: #f0f0f0;
                        cursor: pointer;
                    }
                </style>
                <div class="container-fluid" style="height: 100%;">
                    <div class="row" style="padding: 0 32px;">
                     <nav class="navbar" id="patient-insights-nav">
                            <div style="display: flex; flex-direction: column; align-items:left;">
                                
                                <app-filter 
                                    @filter_change=${e => this.handleFilterChange(e.detail)}
                                    style="
                                    margin-right: 10px;
                                    "
                                    .expanded=${true}
                                    .filter_state=${this.filter_state}
                                    .config=${this._filter_config}
                                    .enable_aggregate=${false}
                                    .show_filters=${true}
                                    .disable_sort=${false}
                                ></app-filter>
                            </div>
                            <div style="display: flex; flex-direction: column; align-items:left;">
                                
                                <app-filter-saved-views
                                    .screen=${"alert_insights"}
                                    @filter_change=${e => this.handleFilterChange(e.detail)}
                                    .collection_name=${"alert"}
                                    .filter_state=${this.filter_state}
                                ></app-filter-saved-views>
                            </div>
                            <app-filter-csv-export 
                                style="position: absolute; top: -55px; right: 10px;"
                                .filter_state=${this.filter_state}
                                .selected_columns=${this.selected_columns}
                                .config=${this._filter_config} 
                                .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        }
                    ]
                }
                }
                            ></app-filter-csv-export>
                        </nav> 
                    </div>
                    <div class="row" style="padding: 0 32px;" id="patient-insights-scroll-container">
                        <app-filter-data-view-server
                            .config=${this._filter_config}
                            .filter_state=${this.filter_state}
                            .options=${this.options}
                            .table_columns=${this.table_columns}
                            @data=${e => this.data = e.detail}
                            .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        }
                    ]
                }
                }
                            .selected_columns=${this.selected_columns}
                            @select-row=${e => this.handleSelectRow(e.detail)}
                            style="padding: 0;"
                        ></app-filter-data-view-server>
                    </div>
                </div>
            `
        };

        this.render();
        this.init();

        this.addEventListener("select-row", (e) => {
            if (e.detail?.id) {
                this.handleSelectPatient(e.detail);
            }
        });
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    init() {
        let filter_state = ApplicationState.get('app.insights_alerts.filter_state');
        let filter_element = this.querySelector('app-filter');
        filter_element.filter_state = filter_state;
        this.filter_state = filter_state;
        this.render();
    }

    async handleFilterChange(filter_state) {
        ApplicationState.set('app.insights_alerts.filter_state', filter_state, { immutable: true });
        this.filter_state = filter_state;
        this.render();
    }

    async handleSelectRow(row) {
        let alert = await directus.items("alert").readOne(row.id,
            {
                fields: [
                    "*",
                    "patient_id.id",
                    "patient_id.first_name",
                    "patient_id.last_name",
                    "survey_id.*",
                    "survey_id.survey_schedule_item_id.day",
                    "survey_id.episode_id.start_date",
                    "survey_id.episode_id.end_date",
                    "survey_id.survey_schedule_item_id.form_design_id",
                    "last_view_user_id.first_name",
                    "last_view_user_id.last_name",
                ],
            });
        let alert_modal = new AppAlertDetail();
        alert_modal.alert = alert;
        await alert_modal.showModal();
        let result = await alert_modal.onDidDismiss();
        //await navigate(`patients/${patient.id}/checkpoints`);
    }

}

customElements.define('scene-insights-alerts', SceneInsightsAlerts);
