import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
////import BootstrapTable from 'bootstrap-table/dist/bootstrap-table';
//import DataDefinition from "shared/lib/lib-data-definition";
//import { Dropdown } from 'bootstrap';
//import { ref, createRef } from "lit/directives/ref.js";
import { getCurrentClientId } from "shared/lib/lib-user";
//import { navigate } from "shared/lib/lib-router";
//import { noteCellFormatter } from "../util/lib-report";
import { format, parseISO, differenceInDays, startOfMonth, endOfMonth, addDays, isSameDay, subDays, subMonths, startOfDay, max } from 'date-fns';
import { DateTime } from "luxon";
//import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
//import { easepick, RangePlugin } from '@easepick/bundle';
import { EditModule, TabulatorFull as Tabulator } from 'tabulator-tables';

//import { parseISODateWithoutTime } from "shared/lib/lib-date";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import "shared/components/app-report-filter-alt";
import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";
import ApexCharts from 'apexcharts';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import { Collapse } from 'bootstrap';
import * as bootstrap from "bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
window.luxon = { DateTime };

export default class ReportPatientOasis extends HTMLElement {
    static get config() {
        return {
            icon: "personal_injury",
            report: "oasis",
            title: "Hospitalizations",
            description: "Hospitalizations",
            component: ReportPatientOasis
        };
    }
    get status_filter() {
        return this._status_filter;
    }

    set status_filter(value) {
        this._status_filter = value;
    }
    set filter_state(value) {
        this._filter_state = value;
    }
    /** @type {import('shared/components/filter/app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;

    }
    get startDate() {
        return this._startDate;
        console.log("startDate get", this._startDate);
    }

    set startDate(value) {
        this._startDate = value;
        this.loadPatients();
        this.handleDateFilterChange();
        console.log("```````````` startDate set", this._startDate);
    }

    get endDate() {
        return this._endDate;
        console.log("endDate get", this._endDate);
    }

    set endDate(value) {
        this._endDate = value;
        this.loadPatients();
        this.handleDateFilterChange();
        console.log("```````````` endDate set", this._endDate);
    }

    constructor() {
        super();
        this.usePast90DaysFilter = true;
        const filter_string = new URL(location.href).searchParams?.get("filters");
        const { fromDate, toDate } = this.defaultFilterDates();
        this.filters = JSON.parse(filter_string) || {
            "oasis_date_range": `${fromDate},${toDate}`
        };
        this._startDate = new Date(fromDate);
        this._endDate = new Date(toDate);
        this._calendar;
        this._heatmap;
    }

    connectedCallback() {
        this.render();
        //this.loadVisits();
        this.loadPatients();
        this.initializeTooltips();
    }

    async loadPatients() {
        try {
            const filters = {
                client_id: getCurrentClientId()
            };

            const response = await directus.transport.get('/vbh/reports/patient_transfers', {
                params: {
                    filters: JSON.stringify(filters)
                },
                limit: 200
            });

            if (response && response.data) {
                const patients = response.data;
                await this.processPatients(patients);
                return patients;
            } else {
                console.error('Invalid API response:', response);
                throw new Error('Invalid API response');
            }
        } catch (error) {
            console.error('Error loading visits:', error);
            throw error;
        }
    }

    updateRehospitalizationCount(count) {
        const rehospitalizationCountElement = this.querySelector("#rehosp-value");
        if (rehospitalizationCountElement) {
            rehospitalizationCountElement.textContent = count;
        }
    }
    async processPatients(patients) {
        this.hospitalTransfers = patients.map(patient => {
            const startDate = patient.start_date ? new Date(patient.start_date) : null;
            const visitDate = patient.visit_date ? new Date(patient.visit_date) : null;

            let progress = null;

            if (startDate && visitDate) {
                progress = differenceInDays(visitDate, startDate) + 1;
            }

            return {
                ...patient,
                progress,
            };
        });

        console.log('Patients:', this.hospitalTransfers);

        // Create date-ranged objects for the heatmap and table
        this.dateRangedData = {};

        this.hospitalTransfers.forEach(patient => {
            const visitDate = patient.visit_date;

            if (!this.dateRangedData[visitDate]) {
                this.dateRangedData[visitDate] = [];
            }

            this.dateRangedData[visitDate].push(patient);
        });

        // Calculate rehospitalization count
        const rehospitalizationCount = this.getFilteredData().reduce((count, patient) => {
            const isWithinDateRange = patient.visit_date && patient.visit_date >= this.startDate.toISOString() && patient.visit_date <= this.endDate.toISOString();
            return count + (isWithinDateRange ? 1 : 0);
        }, 0);

        console.log('Rehospitalization Count:', rehospitalizationCount);

        this.updateRehospitalizationCount(rehospitalizationCount);
        this.renderCalendarh(this.hospitalTransfers);
        this.renderHeatmap(this.getFilteredData());
        this.renderTransfersTable(this.getFilteredData());
    }

    getFilteredData() {
        const filteredData = [];

        for (const dateKey in this.dateRangedData) {
            if (dateKey >= this.startDate.toISOString() && dateKey <= this.endDate.toISOString()) {
                filteredData.push(...this.dateRangedData[dateKey]);
            }
        }

        return filteredData;
    }

    renderTransfersTable(patients) {
        console.log("patients,", patients);
        //console.log('Hospital Transfers:', this.hospitalTransfers);
        const level1Alert = `<span class="material-symbols-outlined" style="display: inline-block; margin: 0px; font-size: 1.2rem; font-variation-settings: 'FILL' 1; color: #F29188;">report</span>`;
        const level2Alert = `<span class="material-symbols-outlined" style="display: inline-block; margin: 0px; font-size: 1.1rem; font-variation-settings: 'FILL' 1; color: #F2AA80;">warning</span>`;
        const level3Alert = `<span class="material-symbols-outlined" style="display: inline-block; margin: 0px; font-size: 1.2rem; font-variation-settings: 'FILL' 1; color: #F2C777;">error</span>`;

        const tableOptions = {
            data: patients,
            layoutColumnsOnNewData: true,
            layout: "fitDataFIll",
            sortMode: "local",

            columns: [
                {
                    title: "Patient",
                    field: "patient_id",
                    width: "13%",
                    formatter: (cell, formatterParams, onRendered) => {
                        const rowData = cell.getRow().getData();
                        return `<strong>${rowData.last_name}, ${rowData.first_name}</strong>`;
                    }
                },

                {
                    title: "Transfer Date",
                    field: "visit_date",
                    width: "9%",
                    hozAlign: "center",
                    formatter: "datetime",
                    formatterParams: {
                        inputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",  // Corrected input format
                        outputFormat: "MM/dd/yy",
                        invalidPlaceholder: "(invalid date)"
                    },
                    sorter: "datetime", sorterParams: {
                        format: "MM/dd/yy",
                    }

                },

                {
                    title: "Last Completed Survey",
                    field: "completed_date",
                    width: "15%",
                    formatter: function (cell, formatterParams, onRendered) {
                        var completedDate = luxon.DateTime.fromISO(cell.getValue());
                        var visitDate = luxon.DateTime.fromISO(cell.getRow().getData().visit_date);
                        var completedDateFormatted = completedDate.toFormat("MM/dd/yy");
                        var relativeDiff = visitDate.toRelative({ base: completedDate })
                            .replace("ago", "prior")
                            .replace("in", "<strong>after</strong>");
                        return `${completedDateFormatted} - <span>${relativeDiff}</span>`;
                    }
                },

                {
                    title: "Current Episode",
                    field: "current_episode_start",
                    width: "15%",
                    hozAlign: "center",
                    //minWidth: 265,
                    formatter: function (cell, formatterParams) {
                        const rowData = cell.getRow().getData();
                        const startDate = rowData.start_date ? format(parseISO(rowData.start_date), "MM/dd/yyyy") : "No Current Episode";
                        const endDate = rowData.end_date ? format(parseISO(rowData.end_date), "MM/dd/yyyy") : "";
                        return `${startDate} - ${endDate}`;
                    },
                    //variableHeight: true
                },
                {
                    title: "Days Into Episode",
                    field: "progress",
                    width: "12%",
                    sorter: "number",
                    hozAlign: "left",
                    formatter: "progress",
                    cssClass: "patient-progress",
                    formatterParams: {
                        min: 0,
                        max: 60,
                        legend: false,
                        //color: ["#F29188", "#9ac58f"],
                        color: ["#9cc8d3", "#bbd4da"],
                        //legendColor: "#000000",
                        //legendAlign: "center",
                    },
                    //width: 150,
                    //tooltip: true,
                    //tooltip: function (cell) {
                    //    const rowData = cell.getRow().getData();
                    //    const progress = rowData.progress;
                    //    if (progress !== null) {
                    //        return `Visit on day ${progress} of 60`;
                    //    }
                    //    return "No visit data available";
                    //},
                },

                {
                    title: "Alerts",
                    field: "alerts",
                    width: "5%",
                    formatter: function (cell, formatterParams, onRendered) {
                        const alerts = cell.getValue();

                        if (alerts && alerts.length > 0) {
                            const alertIcons = alerts.map(alert => {
                                if (alert.level) {
                                    if (alert.level === 1) {
                                        return level1Alert;
                                    } else if (alert.level === 2) {
                                        return level2Alert;
                                    } else if (alert.level === 3) {
                                        return level3Alert;
                                    }
                                } else {
                                    return '';
                                }
                            }).join('');

                            return alertIcons;
                        }

                        return '';
                    },
                    tooltip: function (e, cell, onRendered) {
                        const alerts = cell.getValue();

                        if (alerts && alerts.length > 0) {
                            var el = document.createElement("div");
                            el.style.backgroundColor = "white";
                            el.style.fontSize = "16px";

                            alerts.forEach(alert => {

                                let alertIcon = '';
                                const alertLevel = alert.level;
                                const alertMessage = alert.message;
                                const noteContent = alert.notes ? alert.notes.map(note => note.content).join('<br>') : 'No notes';
                                if (alert.level) {
                                    if (alert.level) {
                                        if (alert.level === 1) {
                                            alertIcon = level1Alert;
                                        } else if (alert.level === 2) {
                                            alertIcon = level2Alert;
                                        } else if (alert.level === 3) {
                                            alertIcon = level3Alert;
                                        }
                                    } else {
                                        return '';
                                    }
                                    el.innerHTML += `
                        <div>
                            <span>${alertIcon}<strong>Level ${alertLevel} Alert</strong></span>
                        </div>
                        <div>
                            <span>${alertMessage}</span>
                        </div>
                        
                        <div>
                            <span><strong>Notes:</strong><br>${noteContent}</span>
                        </div>
                        <hr>
                    `;
                                }
                            });

                            return el;
                        }

                        return '';
                    },
                    headerSort: false,
                    hozAlign: "center",
                    vertAlign: "middle",
                    responsive: 0,
                },

                {
                    title: "Reported",
                    field: "pr_acc_utilization",
                    hozAlign: "center",
                    width: "6%",
                    formatter: (cell, formatterParams, onRendered) => {
                        // reeturn yes if 'yes' or 1 and no if 'no' or 0
                        const reported = cell.getValue();
                        if (reported === 'yes' || reported === '1') {
                            return 'yes';
                        } else if (reported === 'no' || reported === '0') {
                            return 'no';
                        }
                        return;
                    }
                },
                {
                    title: "Reason",
                    width: "11%",
                    hozAlign: "center",
                    editable: true,
                    editor: "input",
                    field: "pr_acc_reason",
                    formatter: (cell, formatterParams, onRendered) => {
                        const reason = cell.getValue();
                        if (reason) {
                            return reason;
                        } else {
                            return '<span class="material-symbols-outlined oasis-alert-icon" style="font-variation-settings:\'FILL\' 0,\'wght\' 600,\'GRAD\' -25,\'opsz\' 48; color: #cccccc;">edit_square</span>';
                        }

                    }
                },

                {
                    title: "Checkpoint Detail",
                    width: "14%",
                    hozAlign: "center",
                    sorter: false,
                    formatter: (cell, formatterParams, onRendered) => {
                        const rowData = cell.getRow().getData();
                        if (rowData.survey_id) {
                            const checkpointDetailUrl = `/client/patients/${rowData.patient_id}/checkpoints/${rowData.survey_id}`;
                            return `<a class="oasis-cp-detail-btn" href="${checkpointDetailUrl}" target="_blank">View Checkpoint</a>`;
                        }
                        return 'N/A';
                    }
                },
                //{
                //    title: "",
                //    sorter: false,
                //    field: "pr_acc_utilization",
                //    formatter: (cell, formatterParams, onRendered) => {
                //
                //        return '';
                //    }
                //},
            ],
            //rowSelected: (row) => {
            //    //const rowData = row.getData();
            //    //const selectedDate = new Date(rowData.visit_date);
            //    //this.highlightChartPoint(selectedDate);
            //}
        };

        if (this.table) {
            this.table.setData(tableOptions.data);
            //this.table.setLayout('fitColumns');
            //this.renderHeatmap(tableOptions.data);
        } else {
            this.table = new Tabulator("#transfersTable", tableOptions);
            //this.renderHeatmap(tableOptions.data);

        }
    }
    getUserTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    renderCalendarh(visits) {
        //console.log("in cal", visits);
        let tempStart = null;
        let tempEnd = null;
        const calendarEl = document.getElementById('calendarh');
        this._calendar = new Calendar(calendarEl, {

            plugins: [interactionPlugin, bootstrap5Plugin, dayGridPlugin, multiMonthPlugin],
            initialView: 'multiMonthCustom',
            themeSystem: 'bootstrap5',
            timeZone: 'UTC',
            height: 200,
            aspectRatio: 1.8,
            //background: true,
            //selectable: true,
            fixedMirrorParent: document.body,
            unselectAuto: false,
            datesSet: (info) => {
                // Highlight the currently selected date range
                if (this._startDate && this._endDate) {
                    this._calendar.select({
                        start: this._startDate,
                        end: addDays(this._endDate, 1),
                    });
                }
            },
            showNonCurrentDates: false,
            //selectAllow: () => true,
            //select: (info) => {
            //    // Handle date range selection
            //    this.startDate = info.start;
            //    this.endDate = info.end;
            //},
            initialDate: subMonths(new Date(), 8), // Set initial date
            dateClick: (info) => {
                let clickedDate = info.date;

                if (!tempStart || (tempStart && tempEnd)) {
                    console.log("no start or both set");
                    tempStart = clickedDate;
                    tempEnd = null;
                    this._calendar.unselect();
                    this._calendar.select(clickedDate); // select the new start date
                } else if (tempStart && !tempEnd) {
                    console.log("start but no end");

                    if (clickedDate < tempStart) {
                        console.log("date less than start");
                        tempEnd = tempStart;
                        tempStart = clickedDate;
                    } else {
                        console.log("else");
                        tempEnd = clickedDate;
                    }

                    this._calendar.unselect();
                    this._calendar.select(tempStart, addDays(tempEnd, 1)); // Select the new range, adding one day to the end date

                    if (tempStart && tempEnd) {
                        this.setStartDate(tempStart);
                        this.setEndDate(tempEnd);
                        this.handleDateFilterChange();
                    }
                }
            },
            dayHeaderFormat: { weekday: 'narrow' },
            customButtons: {
                myPrevButton: {
                    text: '',
                    click: function () {
                        this._calendar.prev();
                    }
                },
                myNextButton: {
                    text: '',
                    click: function () {
                        this._calendar.next();
                    }
                }
            },
            views: {
                multiMonthCustom: {
                    type: 'multiMonth',
                    duration: { months: 9 },
                    fixedWeekCount: true,
                    columnFormat: {
                        month: 'ddd'
                    },

                    height: 200,
                    contentHeight: 200

                }
            },
            headerToolbar: {
                left: 'myPrevButton',
                center: '',
                right: 'myNextButton'
            },
            events: visits.map(visit => ({
                //title: `${visit.visit_description}.`,
                start: visit.visit_date,
                allDay: true,
                display: 'background',
                color: '#c97972',
                textColor: '#ffffff',

            })),
            eventDidMount: (info) => {
                info.el.title = info.event.title;
            },
            // eventClick: (info) => {
            //     const selectedDate = new Date(info.event.start);
            //     this.highlightTableRows(selectedDate);
            //     this.highlightChartPoint(selectedDate);
            // }
        });

        const collapseEl = this.querySelector('#h-cal-hidden');
        if (collapseEl.classList.contains('show')) {
            this._calendar.render();
        }
        this._calendar.render();
    }

    setStartDate(date) {
        if (!isSameDay(this._startDate, date)) {
            this._startDate = date;
            console.log('Start Date Set:', this._startDate);
        }
    }

    setEndDate(date) {
        if (!isSameDay(this.endDate, date)) {
            this.endDate = date;
            console.log('End Date Set:', this._endDate);
        }
    }


    getValue() {
        console.log("***** Start", this.startDate);
        console.log("***** End", this.endDate);
    }

    renderHeatmap(patients) {
        const episodeDuration = 60; // Assuming the episode duration is 60 days

        const oasisVisitCounts = {};
        patients.forEach(patient => {
            if (patient.start_date && patient.visit_date) {
                const episodeStartDate = new Date(patient.start_date);
                const visitDate = new Date(patient.visit_date);
                let dayInEpisode = differenceInDays(visitDate, episodeStartDate) + 1;

                if (dayInEpisode < 1) {
                    dayInEpisode = dayInEpisode + Math.ceil((0 - dayInEpisode) / episodeDuration) * episodeDuration;
                }

                if (dayInEpisode >= 1 && dayInEpisode <= episodeDuration) {
                    oasisVisitCounts[dayInEpisode] = (oasisVisitCounts[dayInEpisode] || 0) + 1;
                }
            }
        });

        const series = [];
        for (let day = 1; day <= episodeDuration; day++) {
            series.push({
                x: day,
                y: oasisVisitCounts[day] || 0
            });
        }

        const heatmapOptions = {
            series: [{
                name: 'OASIS Visits',
                data: series,
            }],
            chart: {
                height: 100,
                type: 'heatmap',
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#fff']
                },
            },
            xaxis: {
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0);
                    }
                }
            },
            yaxis: {
                show: false
            },
            tooltip: {
                x: {
                    formatter: function (val) {
                        return `Day ${val}`;
                    }
                }
            },
            plotOptions: {
                heatmap: {
                    colorScale: {
                        ranges: [
                            { from: 0, to: 0, color: '#f8f8f8' },
                            { from: 1, to: 1, color: '#f5b8ab' },
                            { from: 2, to: 2, color: '#f09481' },
                            { from: 3, to: 3, color: '#eb7067' },
                            { from: 4, to: 4, color: '#e74c3c' },
                            { from: 5, to: 5, color: '#c0392b' },
                            { from: 6, to: 6, color: '#942417' },
                            { from: 7, to: 7, color: '#691d13' },
                            { from: 8, to: 8, color: '#4e160e' },
                            { from: 9, to: 9, color: '#33100a' },
                            { from: 10, to: 10, color: '#1a0b05' },
                        ]
                    }
                }
            },
        };

        console.log("this._heatmap", this._heatmap);

        if (this._heatmap) {
            this._heatmap.updateSeries([{
                name: 'OASIS Transfers',
                data: series,
            }]);
        } else {
            this._heatmap = new ApexCharts(document.querySelector("#heatmap"), heatmapOptions);
            this._heatmap.render();
        }
    }

    defaultFilterDates() {
        const curDate = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(curDate.getDate() - 30);  // 30 days ago from current date.

        const fromDate = thirtyDaysAgo.toISOString().split('T')[0];
        const toDate = curDate.toISOString().split('T')[0];

        return {
            "fromDate": fromDate,
            "toDate": toDate
        };
    }

    handleDateFilterChange(e) {
        console.log("Date Filter Changed");
        const datePickerElement = this.querySelector("app-report-filter-alt");
        if (datePickerElement && e === undefined) {
            //let start = this.startDate;
            //let end = this.endDate;
            datePickerElement.value = `${getISODateStringWithoutTime(this.startDate)},${getISODateStringWithoutTime(this.endDate)}`;
        }

        if (e !== null && e !== undefined && e.detail !== null && e.detail !== undefined) {
            this.setStartDate(e.detail.start);
            this.setEndDate(e.detail.end);

            this._calendar.select({
                start: this.startDate,
                end: this.endDate,
            });

            this.renderTransfersTable(this.hospitalTransfers);
            this.renderHeatmap(this.hospitalTransfers);
        }
    }

    handleFilterClear(filterName) {
        if (filterName === "oasis_date_range") {
            const { fromDate, toDate } = this.defaultFilterDates();
            this.filters["oasis_date_range"] = `${fromDate},${toDate}`;

            // Clear the date range in the calendar
            this.startDate = null;
            this.endDate = null;

            this.loadPatients();
        }
    }
    initializeTooltips() {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-tooltip="tooltip"]');
        tooltipTriggerList.forEach(tooltipTriggerEl => {
            new bootstrap.Tooltip(tooltipTriggerEl, {
                placement: 'top',
                trigger: 'hover',
                container: 'body', // Append the tooltip to the body element
                fallbackPlacement: 'none', // Disable fallback placement
                boundary: 'viewport', // Prevent overflow outside of the viewport
                popperConfig: {
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: [] // Disable flipping to other placements
                            }
                        },
                        {
                            name: 'arrow',
                            options: {
                                element: '.tooltip-arrow', // or '.arrow' in Bootstrap 4
                            },
                        },
                    ]
                }
                //return Object.assign({}, defaultBsPopperConfig, newPopperConfig);
            });
        });
    }
    render() {
        render(html`

                


                <div class="container-fluid oasis-transfers" style="height: 100%; padding: 0;">
                <div class="row" style="padding: 0 25px 0 25px;">
                    <div class="col-3" style="">
                        <div id="oasis-controls">

                            <div class="oasis-stat-cont">
                                <span id="rehosp-value" class="oasis-stat-value">17</span>
                                <span class="oasis-stat-title">Hospitalizations Within Range</span>

                            </div>

                        </div>
                    </div>  
                    <div class="col-9" style="">
                    <h5 class="oasis-title">Hospitalizations by Episode Day</h5>
                        <div id="heatmap"></div>
                    </div>   
                </div>        
                <div class="row oasis-h-cal-cont" style="padding: 0 25px 0 25px; margin-bottom: 40px;">
                
                    <div class="cal-h-scroll collapse" id="h-cal-hidden" style="">
                        
                        <div id="calendarh"></div>
                    </div>    
                </div>  
                <div class="row" style="padding: 0; margin: 0 0 0 0" id="oasis-transfers-charts">
                        <div class="col-md-12 oasis-table-cont">
                            <div class="oasis-h-cal-expand"
                            data-bs-tooltip="tooltip" title="View the interactive calendar and select ranges of interest."
                                data-bs-custom-class="task-tooltip" data-bs-placement="left"
                                    
                                    data-bs-toggle="collapse" href="#h-cal-hidden" role="button" aria-expanded="false" aria-controls="collapseCal"
                            >
                            <span>View Timeline</span>
                            <span class="material-symbols-outlined down-carrot">expand_more</span>
                        </div>
                            <div id="transfersTable"></div>
                        </div>

                </div>     
            </div>
            <!-- <button id="testbtn" class="btn btn-primary" style="margin-bottom: 16px;" @click=${_e => this.getValue()}>Get Current Values</button> -->
            <div id="oasis-date-picker" style="align-items: center;">
               <app-report-filter-alt
                    .title=${"Current Range"}
                    .is_datepicker=${true}
                    .value=${this.filters.oasis_date_range}
                    @dateselect=${e => this.handleDateFilterChange(e)}
                    @clear=${_e => this.handleFilterClear("oasis_date_range")}
                ></app-report-filter-alt>
                </div>
        `, this);
    }

}

customElements.define("report-patient-oasis", ReportPatientOasis);