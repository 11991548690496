import { html, render } from "lit";
import * as bootstrap from "bootstrap";

/**
 * @param {AvoidableEventsMetrics} metrics flat object with all impact metrics
 * A widget displaying metrics around hospital transfers and resumption of care.
 */
export default class AppAvoidableEventsHospitalResumptions extends HTMLElement {
    constructor() {
        super();
        this.metrics = {};
    }

    set metrics(value) {
        this._metrics = value || {};
        this.render();
    }
    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #avoidable-events-hospital-resumptions h6 {
                        margin: unset;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 26px;
                        color: var(--t-color-dark);
                    }
                    #avoidable-events-hospital-resumptions .widget-row {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    #avoidable-events-hospital-resumptions .widget-metric {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                        height: 84px;
                        position: relative;
                    }
                    #avoidable-events-hospital-resumptions .widget-metric__value {
                        font-weight: 700;
                        line-height: 56px;
                        font-size: 40px;
                        color: var(--t-color-dark);
                        letter-spacing: 0.5px;
                    }
                    #avoidable-events-hospital-resumptions .widget-metric__label {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 26px;
                        color: var(--t-color-dark);
                        text-align: center;
                        white-space: pre-line;
                    }
                </style>
                <div 
                    id="avoidable-events-hospital-resumptions" 
                    style="
                        height: 100%; 
                        display: flex; 
                        flex-direction: column;
                    "
                >
                    <div style="
                        margin-top: 8px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-around;
                    ">
                        <div class="widget-row">
                            <div 
                                class="widget-metric"
                                title="Number of Transfer Oasis"
                                data-bs-html="true"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                data-bs-custom-class="widget-formula-tooltip"
                            >
                                <span class="widget-metric__value" >
                                    ${(Math.round(this.metrics.total_transfers) || 0).toLocaleString()}
                                </span>
                                <span class="widget-metric__label">Transfer to Hospital</span>
                            </div>
                        </div>
                        <div 
                            class="widget-row"
                            title="Number of Resumption of Care Oasis"
                            data-bs-html="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            data-bs-custom-class="widget-formula-tooltip"
                        >
                            <div class="widget-metric">
                                <span class="widget-metric__value" >
                                    ${(Math.round(this.metrics.total_resumptions) || 0).toLocaleString()}
                                </span>
                                <span class="widget-metric__label">Resumption of Cares</span>
                            </div>
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
        });

        this.render();
        [...this.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((el) => new bootstrap.Tooltip(el));
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

}

customElements.define("app-avoidable-events-hospital-resumptions", AppAvoidableEventsHospitalResumptions);
