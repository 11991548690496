import { html, render } from "lit";

export default class FormTextarea extends HTMLElement {
    get inputid() {
        return this._inputid;
    }

    set inputid(value) {
        this._inputid = value;
        this.render();
    }

    get label() {
        return this._label;
    }

    set label(value) {
        this._label = value;
        this.render();
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
        this.render();
    }

    get placeholder() {
        return this._placeholder;
    }

    set placeholder(value) {
        this._placeholder = value;
        this.render();
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
        this.render();
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = !!value;
        this.render();
    }

    get captured() {
        return this._captured;
    }

    set captured(value) {
        this._captured = !!value;
    }

    static get observedAttributes() {
        return [
            "caputured",
            "inputid",
            "label",
            "name",
            "placeholder",
            "value",
            "disabled",
        ];
    }

    constructor() {
        super();
        this._label = "";
        this._name = "";
        this._placeholder = "";
        this._value = "";
        this._type = "";
        this._captured = true;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <label for="${this._inputid}" class="form-label">${this._label}</label>
                <textarea id=${this._inputid}
                    class="form-control"
                    name="${this._name}"
                    .value=${this._value}
                    placeholder="${this._placeholder}"
                    ?disabled=${this._disabled}
                    @focus=${(e) => this.handleEvent("focus", e)}
                    @blur=${(e) => this.handleEvent("blur", e)}
                    @input=${(e) => this.handleEvent("input", e)}
                    @change=${(e) => this.handleEvent("change", e)}
                    @keypress=${(e) => this.handleEvent("keypress", e)}
                    @keydown=${(e) => this.handleEvent("keydown", e)}
                    @keyup=${(e) => this.handleEvent("keyup", e)}
                >${this._value}</textarea>
            `;
        };

        this.render();
    }

    attributeChangedCallback(name, old_value, new_value) {
        if (old_value == new_value) return;
        this[name] = new_value;
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }

    handleEvent(event_type, event) {
        this.value = event.target.value;
        if (this._captured) {
            event.stopPropagation();
            event.stopImmediatePropagation();

            this.dispatchEvent(new CustomEvent(event_type));
        }
    }
}

customElements.define("form-textarea", FormTextarea);