import { html, render } from "lit";
import directus from "../lib/lib-directus";
import "../components/alert/app-alert-card";
import "../components/alert/app-alert-list";
import AppAlertDetail from "../components/alert/app-alert-detail";
import cache from "../lib/lib-cache";

class ScenePatientAlerts extends HTMLElement {
    constructor() {
        super();
        this.patient = null;
        this.alerts = [];
        this.boundResizeHandler = this.handleWindowResize.bind(this);
        this._current_tab = "alerts";
    }

    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
        this.alert_id = value.params.alert_id;
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        if (!(this.patient?.id == value)) this.loadPatient();
    }

    get patient_id() {
        return this._patient_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    set alert_id(value) {
        this._alert_id = value;
    }

    get alert_id() {
        return this._alert_id;
    }

    set alerts(value) {
        if (value?.length) value.sort((a, b) => a.level - b.level);
        this._alerts = value;
        this.render();
    }

    get alerts() {
        return this._alerts;
    }

    get top_alerts() {
        let alerts = this.alerts.filter((alert) => alert.level == 1 && alert.status == "active");
        return alerts || [];
    }

    connectedCallback() {
        this.template = () => html`
            <style>
            scene-patient-alerts .note-header {
                background-color: var(--t-color-primary);
                color: white;
                padding: 5px;
                font-size: 12px;
                font-weight: 700;
                display: block;
            }
            scene-patient-alerts .nav-pills .nav-link {
                font-size: 14px;
                color: var(--t-color-dark);
                border: 1px solid #fff;
                margin-right: 4px;
            }
            scene-patient-alerts .nav-pills .nav-link:hover {
                border: 1px solid var(--t-color-dark);
            }
            scene-patient-alerts .nav-pills .nav-link.active {
                font-weight: 600;
                background-color: var(--t-color-primary);
            }
            scene-patient-alerts .nav-pills .nav-link.active:hover {
                border: 1px solid #fff;
            }
            scene-patient-alerts .list-group-item {
                border: none;
                border-radius: 8px;
                box-shadow: var(--t-box-shadow);
                margin-bottom: 8px;
            }
                app-alert-card {
                }
                @media (max-width: 768px) {
                    app-alert-card {
                        width: 100%;
                    }
                }
                @media (min-width: 768px) {
                    app-alert-card {
                        width: 360px;
                    }
                }
            </style>
            <app-patient-header .page=${"alerts"} .patient=${this.patient}></app-patient-header>
            <div style="height: calc(100% - 32px - ${this._patient_header_height}px); overflow-y: auto; padding-top: 16px;">
            <div style="margin: 0 32px 16px 32px; border-radius: 8px; height: 100%;">
                <div style="
                    display: flex; 
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin: 0 0px;
                "
                    id="top_alerts">
                    ${this.top_alerts.map(
            (alert) => html`
                            <app-alert-card
                                @refresh-alerts=${(_e) => this.loadAlerts()}
                                .alert=${alert}
                                .patient=${this.patient}></app-alert-card>
                        `
        )}
                </div>
                <app-alert-list
                    style="margin: 16px 32px 16px 0px;"
                    @refresh-alerts=${(_e) => this.loadAlerts()}
                    .patient=${this.patient}
                    .alerts=${this.alerts}
                ></app-alert-list>
                </div>
            </div>
        `;

        this.render();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        if (!this.patient_id) return;
        render(this.template(), this);
    }

    // get links() {
    //     const links = [
    //         { page: "all", url: `patients/${this.patient.id}/activity`, title: "All" },
    //         { page: "alerts", url: `patients/${this.patient.id}/alerts`, title: "Alerts" },
    //         { page: "notes", url: `patients/${this.patient.id}/notes`, title: "Notes" },
    //         { page: "communication", url: `patients/${this.patient.id}/communication`, title: "Communication" },
    //         { page: "calls", url: `patients/${this.patient.id}/activity`, title: "Calls" },
    //         { page: "checkpoints", url: `patients/${this.patient.id}/activity`, title: "Checkpoints" },
    //         { page: "notes", url: `patients/${this.patient.id}/activity`, title: "Notes" },
    //         { page: "availability", url: `patients/${this.patient.id}/availability`, title: "Availability" },
    //     ];
    //     return links;
    // }  

    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = document.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

    async loadPatient() {
        if (this._loading_patient) return;
        //vaadin router will set the patient_id multiple times, so add a guard to prevent redundant loads
        this._loading_patient = true;
        this.patient = await cache.getPatient(this.patient_id);
        await this.loadAlerts();
        this._loading_patient = false;
        if (!this._patient_header_height) {
            this._patient_header_height = this.querySelector('app-patient-header')?.clientHeight;
        }
        this.render();
    }

    async loadAlerts() {
        let result = await directus.items("alert").readByQuery({
            fields: [
                "*",
                "survey_id.*",
                "survey_id.survey_schedule_item_id.day",
                "survey_id.episode_id.start_date",
                "survey_id.episode_id.end_date",
                "survey_id.survey_schedule_item_id.form_design_id",
                "last_view_user_id.first_name",
                "last_view_user_id.last_name",
                "patient_id.id",
                "patient_id.first_name",
                "patient_id.last_name",
                "resolved_user_id.first_name",
                "resolved_user_id.last_name",
            ],
            filter: {
                patient_id: { _eq: this.patient_id },
            },
            sort: ["level", "-date_created"],
        });
        this.alerts = result.data || [];
        if (this.alert_id) this.showAlert();
    }

    async showAlert() {
        if (this._alert_shown) return;

        let alert_modal = new AppAlertDetail();
        let alert = this.alerts.find((alert) => alert.id == this.alert_id);
        alert_modal.alert = alert;
        alert_modal.patient = this.patient;
        this._alert_shown = true;
        await alert_modal.showModal();
        let result = await alert_modal.onDidDismiss();
        if (result) await this.loadAlerts();
        this.render();
    }
}

customElements.define("scene-patient-alerts", ScenePatientAlerts);
export default ScenePatientAlerts;
