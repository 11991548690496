import { html, render } from "lit";
import { navigate, buildURL } from "../../lib/lib-router";
import directus from "../../lib/lib-directus";
import FormsDataDictionary from "../../lib/lib-forms-data-dictionary";
import SurveyDefinition from "../../lib/lib-survey-definition";
import ApplicationState from "applicationstate";
import { getResponseText, getSupplementalResponseText } from "../../lib/lib-util";
import "../alert/app-alert-reason";
import "../patient/app-patient-note-text";
import AppAlertDetail from '../alert/app-alert-detail';
import { formatDistanceToNow } from "date-fns";

/**
 * @param {object[]} checkpoint
 * @param {object[]} field_groups from SurveyDefinition.getFieldGroups()
 */
export default class AppPatientCheckpointDetails extends HTMLElement {
    constructor() {
        super();
        this._checkpoint = null;
        this._alerts = [];
        this._question_alerts = { no_question: [] };
        this._form_supplemental_questions = {};
        this._survey_question_notes = {};
        this._app_name = ApplicationState.get('app.name');
        this.patient_checkpoints = [];
        this.current_checkpoint_index = null;
        this.task_type = window.location.search.substring(1);
        console.log('task_type', this.task_type);

    }

    get checkpoint() {
        return this._checkpoint;
    }

    set checkpoint(value) {
        if (value?.survey_id !== this.checkpoint?.survey_id) {
            this._checkpoint = value;
            this.render();
            this.init();
            console.log('checkpointsss', (this.checkpoint));
        }
    }

    connectedCallback() {
        this.lg_query = window.matchMedia("(min-width: 992px)");

        this.lg_query.addEventListener("change", _e => {
            this.render();
        });

        this.template = () => {

            const items = [];
            if (this.checkpoint?.submission?.array_data) {
                this.checkpoint.submission.array_data.forEach(question => items.push(this.tableSectionReviewTemplate(question)));

            } else {
                for (const group of this._field_groups) {
                    const filtered_fields = group.fields.filter((field) => this.hasBeenAsked(field.field));
                    items.push(this.tableSectionTemplate(group, filtered_fields));
                }
            }
            const survey_date = this.checkpoint.survey_completion_date ? new Date(this.checkpoint.survey_completion_date).toLocaleDateString() : ''

            const current_checkpoint = this.patient_checkpoints[this.current_checkpoint_index];
            const previous_checkpoint = this.patient_checkpoints[this.current_checkpoint_index - 1];
            const next_checkpoint = this.patient_checkpoints[this.current_checkpoint_index + 1];

            const checkpoint_base_uri = `patients/${this.patient.id}/checkpoints/`;

            return html`
                <style>
                    .checkpoint-wrapper {
                        background: white; 
                        border-radius: 8px; 
                        box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08); 
                        padding: 24px;
                    }

                    #checkpoint-details .checkpoint-nav {
                            margin: 16px 0;
                    }
                    #checkpoint-details .checkpoint-nav a {
                        cursor: pointer;
                        color:white;
                    }
                    #checkpoint-details .checkpoint-nav select {
                        border-radius: 0;
                        border: 1px;
                        border-right: 1px solid var(--t-color-grey-40);
                        border-left: 1px solid var(--t-color-grey-40);
                        background-color: var(--t-color-primary);
                        color: white;
                        cursor: pointer;
                    }
                    #checkpoint-details .checkpoint-nav select:hover {
                        background-color: var(--t-color-primary-darkened);
                    }
                    #checkpoint-details #mobile-table-header:not(:first-of-type) {
                        margin-top: 32px;
                    }
                    #checkpoint-details td, #checkpoint-details tr {
                        border: none;
                    }
                    #checkpoint-details tr {
                        padding: 0;
                    }
                    #checkpoint-details td {
                        /* height: 40px; */
                        padding: 8px;
                        text-align: left;
                    }
                    #checkpoint-details #table-section-header {
                        font-size: 1.2rem; 
                        border-bottom: 1px solid #6f6f6f;
                        text-align: left;
                        margin: 0 0 10px 0;
                        padding: 0 0 5px 0px;
                    }
                    #checkpoint-details .response-wrapper {
                        display: inline-flex; 
                        align-items: center;
                        padding: 0;
                        height: 100%;
                    }
                    #checkpoint-details .response-cell {
                        margin-left: 6px;
                        padding: 0 8px;
                    }
                    #checkpoint-details .nested-cell {
                        margin-left: 6px;
                        padding: 0 8px;
                    }
                    #checkpoint-details .response-cell.nested-cell {
                        margin-left: 48px;
                    }
                    #checkpoint-details app-alert-reason {
                        display: block;
                        box-shadow: var(--t-box-shadow);
                        border-radius: 2px;
                        border: 0;
                        padding: 0px;
                        margin-bottom: 4px;
                        white-space: nowrap;
                        /*width: fit-content;*/
                        max-width: 325px;
                    }
                    #checkpoint-details app-alert-reason:hover {
                        background: var(--t-color-background);
                        cursor: pointer;
                        
                    }
                    #checkpoint-details app-alert-reason .alert-reason-text {
                        /* width: 180px; */
                        overflow: hidden;
                        text-overflow: ellipsis;
                        
                    }
                    #checkpoint-details app-alert-reason div {
                        justify-content: flex-start;
                    }

                    .key-class {
                        display: flex; 
                        align-items: center;
                        flex-direction: row;
                    }

                    .checkpoint-div {
                        justify-content: normal;
                        align-items: center;
                        display: block;
                    }

                    .question-response-wrapper {
                        display: block;
                        width: 100%;
                        align-items: normal;
                    }

                    @media (min-width: 601px) {
                        #checkpoint-details .checkpoint-nav {
                            margin: unset;
                        }
                        #checkpoint-details .question-cell {
                        }
                        #checkpoint-details .response-cell {
                            padding: 8px;
                        }
                        #checkpoint-details .nested-cell {
                            padding: 0 8px;
                        }
                        #checkpoint-details tr:not(:first-of-type) #table-section-header {
                            margin-top: 32px;
                        }
                        .checkpoint-details .response-cell {
                            margin-left: unset;
                        }
                    }

                    @media (max-width: 600px) {
                        .key-class {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .form-control {
                        font-size: 0.875rem;
                    }
                    
                    .checkpoint-wrapper {
                        padding: 10px;
                    }

                    #checkpoint-details #table-section-header {
                        font-size: 3.5vw; 
                        border-bottom: 1px solid #6f6f6f ;
                        text-align: center;
                        font-weight: 600;
                    }
                    
                    #checkpoint-details app-alert-reason {
                        white-space: normal;
                    }

                    .checkpoint-div {
                        justify-content: center;
                        align-items: center;
                        display: flex;
                    }

                    .question-response-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    
                    }
                    
                    @media (max-width: 390px) {
                        .checkpoint-div {
                        justify-content: none;
                        align-items: left;
                        display: block;
                    }

                    #checkpoint-details {
                        max-width: 300px;
                        overflow: hidden;
                    }

                    #checkpoint-details #table-section-header {
                        font-size: 4.5vw; 
                        border-bottom: 1px solid #6f6f6f ;
                        text-align: left;
                    }

                    .question-response-wrapper {
                        display: flex;
                        align-items: left;
                        flex-direction: column;
                        justify-content: start;

                    }
                    }
                </style>
                <div class="checkpoint-div">
           
                <div id="checkpoint-details" class="checkpoint-wrapper ${this.task_type}">
                    <div class="cp-detail-nav-wrap">
                        <span><span style="font-weight: 600;">Survey Completion Date: </span>${survey_date} <span style="margin-left: 10px">(${formatDistanceToNow(new Date(this.checkpoint.survey_completion_date))} ago)</span></span>
                        

                        <div class="btn-group btn-group-sm checkpoint-nav" role="group" aria-label="Patient Checkpoint Navigation" style="display: flex;">
                            <a 
                                href=${buildURL(checkpoint_base_uri + previous_checkpoint?.id)} 
                                ?disabled=${!previous_checkpoint}
                                role="button" 
                                aria-label="previous checkpoint"
                                class=${`btn btn-primary ${previous_checkpoint ? null : "disabled"}`}
                                style="display: flex; align-items: center; justify-content: center; background-color: var(--t-color-primary);"
                            >
                                <span class="material-symbols-outlined">
                                    navigate_before
                                </span>
                            </a>
                            <select class="form-control" @change=${e => navigate(checkpoint_base_uri + e.target.value)}>
                                ${this.patient_checkpoints.map(checkpoint => html`
                                    <option value=${checkpoint.id} ?selected=${checkpoint.id === current_checkpoint.id}>
                                        ${new Date(checkpoint.survey_completion_date).toLocaleDateString()}
                                    </option>
                                `)}
                            </select>
                            <a 
                                href=${buildURL(checkpoint_base_uri + next_checkpoint?.id)} 
                                ?disabled=${!next_checkpoint}
                                role="button" 
                                aria-label="next checkpoint"
                                class=${`btn btn-primary ${next_checkpoint ? null : "disabled"}`}
                                style="display: flex; align-items: center; justify-content: center; margin-left: unset; background-color: var(--t-color-primary);"
                            >
                                <span class="material-symbols-outlined">
                                    navigate_next
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-3 key-class">
                            <span style="font-weight: 600;">Key:</span>
                            <div>
                            <span
                                class="material-symbols-outlined"
                                style="height: 14px; margin: 0 4px 0 8px; font-size: 1rem; font-variation-settings: 'FILL' 1; color: #F29188"
                                >arrow_circle_down</span
                            >
                            declined
                            </div>
                            <div>
                            <span
                                class="material-symbols-outlined"
                                style="height: 14px; margin: 0 4px 0 8px; font-size: 1rem; font-variation-settings: 'FILL' 1; color: #9ac58f"
                                >arrow_circle_up</span
                            >
                            improved
                            </div>
                            <div>
                            <span
                                class="material-symbols-outlined"
                                style="height: 14px; margin: 0 4px 0 8px; font-size: 1rem; font-variation-settings: 'FILL' 1; color: #74AAB7"
                                >circle</span
                            >
                            same
                            </div>
                        </div>
                    </div>
                      
                    ${this._question_alerts.no_question?.length ? html`
                        <div style="display: flex; flex-wrap: wrap;">
                            ${this._question_alerts.no_question.map(alert => html`
                                <app-alert-reason 
                                    .alert=${alert}
                                    @click=${_e => this.showAlert(alert)}
                                    class="shadow-sm"
                                    style="margin: 0 16px 16px 0;"
                                ></app-alert-reason>
                            `)}
                        </div>
                    `: null}
                    <table id="cp-detail" class="table table-responsive p-cp-detail" style="border: none;">
                        ${items}    
                    </table>
                    </div>
                </div>`;
        };


        this.tableSectionTemplate = (group, filtered_fields) => {
            //const table_columns = this.lg_query.matches ? 3 : 2;
            const table_columns = 3
            if (filtered_fields.length === 0) {
                return "";
            }
            return html`
                <tr style="margin: unset;">
                    <td colspan="${table_columns}" style="padding: 0 0 0 8px;">
                        <h4 id="table-section-header">
                            ${group.label}
                        </h4>
                    </td>
                </tr>
                ${filtered_fields.map((field) => {
                const response = getResponseText(this.checkpoint, field.field, this._field_definition);
                const alerts = this._question_alerts[field.field] || [];
                const supplemental_question_count = this._form_supplemental_questions[field.field]?.length || 0;

                return html`
                        <tr class="question-response-wrapper">
                            <td class="question-cell"><span>${field.label} ${this.getFieldIcon(field.field)}</span></td>
                            <td class="response-cell">
                            ${this.task_type !== "legacy" ? html`<span class="cp-detail-bullet"></span>` : ''}
                                <span class="response-wrapper">
                                    ${this.getResponseIcon(field.field)}
                                    <span style="margin-left: 6px">${response}</span>
                                </span>
                            </td>

                            ${table_columns === 3 && alerts.length ? html`
                                <td class="alert-cell" style="vertical-align: top;" rowspan="${1 + supplemental_question_count}">
                                    ${alerts.map(alert => html`
                                        <app-alert-reason 
                                            .alert=${alert}
                                            @click=${_e => this.showAlert(alert)}
                                            class="shadow-sm"
                                        ></app-alert-reason>
                                    `)}
                                </td>
                            ` : null}
                        </tr>
                        ${this.supplementalQuestionTemplate(field)}
                        ${this.questionNotesTemplate(field.field)}
                        ${table_columns === 2 && alerts?.length ? html`
                            <tr>
                                <td class="alert-cell" colspan="2">
                                    <div style="display: flex; flex-wrap: wrap;">
                                        ${alerts.map(alert => html`
                                            <app-alert-reason 
                                                .alert=${alert}
                                                @click=${_e => this.showAlert(alert)}
                                                class="shadow-sm"
                                                style="margin: 0 16px 4px 0;"
                                            ></app-alert-reason>
                                        `)}
                                    </div>
                                </td>
                            </tr>
                        ` : null}
                    `;
            })}
        `;
        };

        this.tableSectionReviewTemplate = (question) => {
            //const table_columns = this.lg_query.matches ? 3 : 2;
            const table_columns = 3;

            return html`
                <tr style="margin: unset;">
                    <td colspan="${table_columns}" style="padding: 0 0 0 8px;">
                        <h4 id="table-section-header">
                            ${question.title}
                        </h4>
                    </td>
                </tr>

                ${question.components.map((item) => {
                const response = Array.isArray(item.response) ? "" : item.response;
                const alerts = this._question_alerts[item.key] || [];

                if (item.response?.length == 0 && this._survey_question_notes[item.key] == undefined) {
                    return "";
                }

                return html`
                        <tr class="question-response-wrapper">
                            <td class="question-cell"><span>${item.label}</span></td>
                            <td class="response-cell">
                         ${this.task_type !== "legacy" ? html`<span class="cp-detail-bullet"></span>` : ''}
                                <span class="response-wrapper">
                                    <span style="margin-left: 6px">${response}</span>
                                </span>
                            </td>

                            ${table_columns === 3 && alerts.length ? html`
                                <td class="alert-cell" style="vertical-align: top;">
                                    ${alerts.map(alert => html`
                                        <app-alert-reason 
                                            .alert=${alert}
                                            @click=${_e => this.showAlert(alert)}
                                            class="shadow-sm"
                                        ></app-alert-reason>
                                    `)}
                                </td>
                            ` : null}
                        </tr>
                        ${this.childQuestionTemplate(item.response)}
                        ${this.questionNotesTemplate(item.key)}
                    `;
            })}
        `;
        };

        this.childQuestionTemplate = (response) => {
            if (Array.isArray(response) === false) {
                return "";
            }

            return response.map(text => html`
                <tr class="question-response-wrapper">
                    <td class="question-cell nested-cell">
                        <div style="display: flex;">
                            <span style="font-weight: 700; padding-left: 24px;">
                                &bull;
                            </span>
                            <span style="margin-left: 6px;">
                                ${text}
                            </span>
                        </div>
                    </td>
                </tr>
            `);
        }

        this.questionNotesTemplate = (key) => {
            const question_notes = this._survey_question_notes[key];
            return question_notes ? html`
                <tr>
                    <td class="nested-cell" colspan="3" style="padding: 8px 8px 0 32px;">
                        <div class="cp-detail-notes">
                            <b>Notes: </b>
                            <app-patient-note-text .content=${question_notes}></app-patient-note-text>
                        </div>
                    </td>
                </tr>
            ` : null;
        }

        this.supplementalQuestionTemplate = (field) => {
            const field_supplemental_questions = this._form_supplemental_questions[field.field]
            if (field_supplemental_questions?.length) {
                return field_supplemental_questions
                    .filter(supplemental_question => this.hasBeenAsked(supplemental_question.field, true))
                    .map(supplemental_question => {
                        {/* TODO: deltas don't work if supplemental values are text */ }
                        const response = getSupplementalResponseText(this.checkpoint.submission, supplemental_question);
                        return html`
                        <tr class="question-response-wrapper">
                            <td class="question-cell nested-cell">
                                <div style="display: flex;">
                                    <span style="font-weight: 700; padding-left: 24px;">
                                        &bull;
                                    </span>
                                    <span style="margin-left: 6px;">
                                        ${supplemental_question.label} ${this.getFieldIcon(supplemental_question.field)}
                                    </span>
                                </div>
                            </td>
                            <td class="response-cell nested-cell">
                            ${this.task_type !== "legacy" ? html`<span class="cp-detail-bullet"></span>` : ''}
                                <span class="response-wrapper">
                                    ${this.getResponseIcon(field.field)}
                                    <span style="margin-left: 6px">${response}</span>
                                </span>
                            </td>
                        </tr>
                    `
                    })
            }
        }

        Object.assign(this.style, {
            display: "block",
        });

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        if (!this.checkpoint) return;
        if (!this._field_groups) return;
        render(this.template(), this);
    }

    async init() {
        if (this.checkpoint?.survey_id) {
            const [
                survey_definition,
                _survey_question_notes,
                _form_supplemental_questions,
                _alerts,
                _patient_checkpoints,
            ] = await Promise.all([
                SurveyDefinition.getSurveyDefinition(this.checkpoint.survey_id),
                this.loadSurveyQuestionNotes(),
                this.loadFormSupplementalQuestions(),
                this.loadAlerts(),
                this.loadPatientCheckpoints()
            ]);
            this.survey_definition = survey_definition;
            this._field_groups = this.survey_definition.getFieldGroups();
            this._field_definition = this.survey_definition.getFieldDefinition();
            this.render();
        }
    }

    /**
     * Produce an html template containing a properly colored font icon representing the delta's trend: up, down, or no change.
     * @param {string} field_key The field key/column e.g. pr_fall
     * @returns html template
     */
    getFieldIcon(field_key) {
        if (!this.checkpoint) return;
        let delta = this.checkpoint["delta_" + field_key];

        if (delta == 0) return "";

        //improved
        if (delta < 0) {
            return html`
                <span
                    class="material-symbols-outlined"
                    style="
                    font-size: 16px; 
                    color: #9ac58f;
                    right: 30px;
                    font-variation-settings: 'FILL' 1
                    ">
                    check_circle
                </span>
            `;
        }

        //declined
        if (delta > 0) {
            return html`
                <span
                    class="material-symbols-outlined"
                    style="
                    font-size: 16px; 
                    right: 30px;
                    color: #F29188;
                    ">
                    trending_down
                </span>
            `;
        }
    }

    /**
     * Based on the checkpoint delta for given field key, renders a circle
     * colored red with down arrow, blue, or green with up arrow depending on the trend. 
     * @param {string} field_key 
     * @returns {object} lit icon
     */
    getResponseIcon(field_key) {
        const delta = this.checkpoint["delta_" + field_key];

        if (!delta || delta == 0) {
            return html`<span
                class="material-symbols-outlined"
                style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #74AAB7"
                >circle
            </span>`;
        } else if (delta > 0) {
            return html`<span
                class="material-symbols-outlined"
                style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #F29188"
                >arrow_circle_down
            </span>`;
        } else {
            return html`<span
                class="material-symbols-outlined"
                style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #9ac58f"
                >arrow_circle_up
            </span>`;
        }
    }

    /**
     * Determine if the field was ever used in this checkpoint
     * @param {string} field_key The field string to test against
     * @returns {boolean}
     */
    hasBeenAsked(field_key, is_supplemental = false) {
        const value = is_supplemental ? this.checkpoint.submission[field_key] : this.checkpoint[field_key]
        if (value !== null && value !== undefined) {
            return true;
        }
        return false;
    }

    async loadAlerts() {
        let result = await directus.items("alert").readByQuery({
            filter: {
                survey_id: this.checkpoint.survey_id,
            },
            fields: [
                "id",
                "date_created",
                "level",
                "survey_id",
                "survey_id.*",
                "survey_id.survey_schedule_item_id.day",
                "survey_id.episode_id.start_date",
                "survey_id.episode_id.end_date",
                "survey_id.survey_schedule_item_id.form_design_id",
                "survey_id.task_id.task_type",
                "question",
                "subject",
                "patient_id",
                "patient_id.id",
                "patient_id.first_name",
                "patient_id.last_name",
                "resolved_user_id.first_name",
                "resolved_user_id.last_name",
            ],
            sort: "level",
        });
        this._alerts = result.data;
        this._question_alerts = { no_question: [] };
        for (let alert of this._alerts) {
            if (alert.question) {
                const alerts_for_question = this._question_alerts[alert.question] || [];
                alerts_for_question.push(alert);
                this._question_alerts[alert.question] = alerts_for_question;
            }
            else this._question_alerts.no_question.push(alert);
        }
    }

    /**
     * Load all checkpionts for the patient into the checkpoint nav
     */
    async loadPatientCheckpoints() {
        const patient_checkpoints = await directus.items("hh_patient_reported_measures").readByQuery({
            fields: ["id", "survey_completion_date"],
            filter: {
                patient_id: this.patient.id,
                status: "complete",
                survey_completion_date: { _nnull: true },
            },
            sort: "survey_completion_date"
        });

        this.patient_checkpoints = patient_checkpoints.data;
        this.current_checkpoint_index = this.patient_checkpoints.findIndex(checkpoint => checkpoint.id === this.checkpoint?.survey_id);
        console.log("this.checkpoint", this.checkpoint);
    }

    /**
     * Using the checkpoint's form_design_id, 
     * loads a map of parent field names/keys to child fields/questions.
     */
    async loadFormSupplementalQuestions() {
        if (this.checkpoint?.form_design_id) {
            const response = await directus.items('task').readByQuery({
                filter: {
                    form_design_id: this.checkpoint.form_design_id,
                    survey_id: this.checkpoint.survey_id,
                },
                fields: ['task_type'],
                limit: 1,
            });

            if (response.data.length > 0) {
                this.task_type = response.data[0].task_type; // Assign the task_type to the component property
            }

            this._form_supplemental_questions = await FormsDataDictionary.loadFormSupplementalQuestions(this.checkpoint.form_design_id);
        } else {
            this._form_supplemental_questions = {};
        }
        console.log("TASK_TYPE", this.task_type)
        return this._form_supplemental_questions;

    }

    /**
     * @returns {object} map of question names to most recent note
     */
    async loadSurveyQuestionNotes() {
        const question_notes = {};
        const result = await directus.items("note").readByQuery({
            filter: {
                survey_id: { _eq: this.checkpoint.survey_id },
            },
            sort: "-date_created",
        });
        for (let note of result.data) {
            if (note.question && !question_notes[note.question]) {
                // only display the first note for the question
                question_notes[note.question] = note.content;
            }
        }
        this._survey_question_notes = question_notes;
        return question_notes;
    }

    showAlert(alert) {
        let alert_modal = new AppAlertDetail();
        alert_modal.alert = alert;
        alert_modal.showModal();
    }
}

customElements.define("app-patient-checkpoint-details", AppPatientCheckpointDetails);
