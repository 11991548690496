import { html, render } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"
//import "tabulator-tables/dist/css/tabulator_bootstrap5.min.css"

import AppFilterUtil from './util/app-filter-util';
import './app-filter-field-select-list';
import './app-filter-csv-export';

window.luxon = { DateTime };

const PAGE_SIZE = 20;

/**
 * @typedef {"none","single","multiple"} SelectionType - The type of row selection, if any
 */

class AppFilterTable extends HTMLElement {
    constructor() {
        super();

        this.data = [];
        this.data_table = null;

        this.selected_ids = new Set();
        this.selected_data = [];
        //this.handleRowMouseOver = this.handleRowMouseOver.bind(this);
        //this.handleRowMouseOut = this.handleRowMouseOut.bind(this);

        this._options = {
            history: true,
            height: "90%",
            //dataLoaderLoading: "<div style='display:inline-block; border:10px solid #333; border-radius:10px; background:#fff; font-weight:bold; font-size:16px; color:#000; padding:10px 20px;'>Loading Data</div>",
            alignEmptyValues: "bottom",
            pagination: "local",            // Paginate the data
            paginationSize: 25,             // Allow 7 rows per page of data
            paginationCounter: "rows",      // Display count of paginated rows in footer
            layout: "fitDataStretch",
            layoutColumnsOnNewData: true,   // Fit columns to width of table (optional)
            movableColumns: true,           // Allow column order to be changed
            initialSort: [],
            columnDefaults: {
                tooltip: true,              // Show tooltips on cells
            },
            checkbox: true                  // Show Checkbox
        }
    }

    /**
     * The raw directus query result
     */
    set result(value) {
        this._result = value;

        // Update tabulator table when records are updated
        if (this.data_table != null) {
            let oldRecords = this.data_table.getData();
            let newRecords = this._result?.data;
            if (oldRecords != null && newRecords != null && oldRecords.length != newRecords.length) {
                if (this.table_columns)
                    this.data = this.formatTableRecords(this.table_columns, this.result?.data);
                else
                    this.data = this.formatRecords(this._result?.data);

                this.data_table.setData(this.data);
            }
        }

        this.render();
    }

    get result() {
        return this._result;
    }

    get filter_count() {
        return this._result?.meta.filter_count || 0;
    }

    get rows() {
        return this._result?.data;
    }

    set filter_state(value) {
        this._filter_state = value;
        this.render();
    }

    /** @type {import('./app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;
    }

    set collection_config(value) {
        if (!value)
            return;
        this._collection_config = value;
        this.init();
        this.render();
    }

    /** @type {import('./app-filter').CollectionConfig} */
    get collection_config() {
        return this._collection_config;
    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    /** @type {string[]} - A list of paths for columns that are selected for display in the table */
    get selected_columns() {
        return this._selected_columns;
    }

    set table_columns(value) {
        this._table_columns = value;
    }

    get table_columns() {
        return this._table_columns;
    }

    set options(value) {
        if (value)
            this._options = value;
        //console.log("options set", this._options);
    }

    get options() {
        return this._options;
        //console.log("options get", this._options);
    }

    /** @type {Object[]|Object} Selected row(s) in the table */
    get selected_data() {
        return this._selected_data;
    }

    set selected_data(value) {
        this._selected_data = value;
        if (this.selection_type === "multiple") {
            if (value?.length) {
                this.select_all = value.length === this.rows.length;
                this.selected_ids = new Set(value.map(item => item.id));
            }
            else {
                this.select_all = false;
                this.selected_ids = new Set();
            }
        }
        this.render();
    }

    set selection_type(value) {
        if (!['none', 'single', 'multiple'].includes(value))
            value = 'none';
        this._selection_type = value;
    }

    /** @type {SelectionType} - Determines whether rows should be selectable, and if they are single or multiselect */
    get selection_type() {
        return this._selection_type;
    }

    set select_all(value) {
        this._select_all = value || false;
    }

    get select_all() {
        return this._select_all || false;
    }

    /**
     * All columns in the collection that are not hidden
     */
    get visible_columns() {
        return AppFilterUtil.getVisibleColumnPaths(null, this.collection_config.fields);
    }

    get unselected_columns() {
        return this.visible_columns?.filter(
            field => !this.selected_columns.includes(field)
        )
    }

    set page(value) {
        this._page = value;
    }

    get page() {
        return this._page;
    }

    get page_count() {
        return Math.ceil(this.filter_count / PAGE_SIZE)
    }

    connectedCallback() {
        // this.initSortable();
        this.render();
        /* this.querySelectorAll("tbody tr").forEach(row => {
            row.addEventListener('mouseover', this.handleRowMouseOver);
            row.addEventListener('mouseout', this.handleRowMouseOut);
        }); */
        this.initTable();
        this.setTableHeight();
    }

    initTable() {
        let columns;
        //console.log("initTable", this._table_columns);
        if (this.table_columns) {
            columns = this.formatTableColumns(this.table_columns);
            this.data = this.formatTableRecords(this.table_columns, this.rows);
        } else {
            columns = this.formatColumns(this.selected_columns);
            this.data = this.formatRecords(this.rows);
        }

        // Make tabulator table option
        let tableOptions = {
            data: this.data,           //load row data from array
            columns: columns,
        };
        let optionsWithType = this.getOptionsWithTypes(this.options);
        let optionCnt = optionsWithType.length;
        for (let i = 0; i < optionCnt; i++) {
            let option = optionsWithType[i];
            if (option.key == "data" || option.key == "columns" || option.key == "checkbox")
                continue;

            tableOptions[option.key] = option.value;
        }

        this.data_table = new Tabulator("#app-filter-tabulator-table", tableOptions);

        const thisObj = this;

        this.data_table.on("rowSelectionChanged", function (data, rows, selected, deselected) {
            thisObj.dispatchEvent(new CustomEvent('select-row', { bubbles: true, composed: true, detail: data }));
            //console.log("rowSelectionChanged", data);
        });

        this.data_table.on("rowClick", function (e, row) {
            const rowData = row.getData();
            thisObj.dispatchEvent(new CustomEvent('row-click', { bubbles: true, composed: true, detail: rowData }));
            //console.log("rowClick", rowData);
        });

    }

    refreshTableData() {
        this.data_table.replaceData(this.data);
    }

    formatColumns(cols) {
        const collectionConfigObj = this.collection_config;
        let columns = [];

        if (this.options?.checkbox)
            columns.push({
                formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false
            });

        cols?.map(column => columns.push({
            title: unsafeHTML(AppFilterUtil.getDisplayField(this.collection_config, column)).values[0],
            field: column,
            formatter: function (cell) {
                const field = cell.getField();
                const value = cell.getValue();

                const record = AppFilterUtil.formatValue(
                    collectionConfigObj,
                    field,
                    value
                );

                return record;
            }
        }));

        return columns;
    }

    formatRecords(rows) {
        let rowCnt = rows?.length;
        let columnCnt = this.selected_columns?.length;

        for (let i = 0; i < rowCnt; i++) {
            for (let k = 0; k < columnCnt; k++) {
                if (!rows[i][this.selected_columns[k]])
                    rows[i][this.selected_columns[k]] = AppFilterUtil.getValue(this.selected_columns[k], rows[i]);
            }
        }

        return rows;
    }

    formatTableColumns(cols) {
        const collectionConfigObj = this.collection_config;
        let columns = [];

        if (this.options?.checkbox)
            columns.push({
                formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false
            });

        cols?.map(column => columns.push({
            title: column.name,
            field: column.field,
            sorter: column.sorter === 'dateRange' ? this.dateRangeSorter : undefined,
            formatter: function (cell) {
                const field = cell.getField();
                const value = cell.getValue();

                try {
                    const record = AppFilterUtil.formatValue(
                        collectionConfigObj,
                        field,
                        value,



                    );

                    return record;
                } catch (e) {
                    return value;
                }
            }
        }));

        return columns;
    }

    formatTableRecords(cols, rows) {
        let rowCnt = rows?.length;
        let colCnt = cols?.length;

        for (let i = 0; i < rowCnt; i++) {
            for (let k = 0; k < colCnt; k++) {
                let col = cols[k];

                if (!rows[i][col.field]) {
                    let fields = col.fields;
                    let fieldCnt = fields ? fields.length : 0;
                    let link = col.link;
                    let value = "";

                    for (let j = 0; j < fieldCnt; j++) {
                        let field = fields[j];

                        if (j != 0)
                            value += link ? link : " ";

                        value += AppFilterUtil.getValue(field, rows[i]) ? AppFilterUtil.getValue(field, rows[i]) : "";
                    }

                    rows[i][col.field] = value;
                }
            }
        }

        return rows;
    }

    getOptionsWithTypes(obj) {
        const keysWithTypes = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const valueType = typeof obj[key];  // Get the type of the value
                keysWithTypes.push({ key: key, value: obj[key], type: valueType });
            }
        }
        return keysWithTypes;
    }

    dateRangeSorter(a, b, aRow, bRow, column, dir, sorterParams) {
        //console.log("dateRangeSorter", a, b);

        const getDateFromString = (dateStr) => {
            if (!dateStr) return new Date(0); // Handle empty or undefined date strings
            const parts = dateStr.split(' - ')[0].split('/');
            if (parts.length !== 3) return new Date(0); // Handle malformed date strings
            const [month, day, year] = parts.map(Number);
            return new Date(year, month - 1, day);
        };

        let firstDateA = getDateFromString(a);
        let firstDateB = getDateFromString(b);


        // Compare the dates
        return firstDateA - firstDateB; // Sort based on the first date
    }


    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 20);
    }

    disconnectedCallback() {
        /* this.querySelectorAll("tbody tr").forEach(row => {
            row.removeEventListener('mouseover', this.handleRowMouseOver);
            row.removeEventListener('mouseout', this.handleRowMouseOut);
        }); */
    }

    render() {
        if (!this.template || !this.collection_config) return;

        render(this.template(), this);
    }

    async init() {
        this.selected_columns = AppFilterUtil.getVisibleColumnPaths(this.collection_config).slice(0, 4);
    }

    /* handleRowMouseOver(e) {
        const row = e.target.closest('tr');
        if (row) row.classList.add('hovered');
    }

    handleRowMouseOut(e) {
        const row = e.target.closest('tr');
        if (row) row.classList.remove('hovered');
    } */

    template() {
        return html`
            <style>
                .app-filter-table-column-header:hover .app-filter-table-remove-column {
                    display: block !important;
                    cursor: pointer;
                }
            </style>
        
            <div id="app-filter-table">
                <div id="drag-clone" style="display: none; position: absolute;" class="app-filter-table-column-header"></div>
                <div id="app-filter-tabulator-table" class="mod-base"></div>    
            </div>
        `;
    }
}

customElements.define('app-filter-table', AppFilterTable);
export default AppFilterTable;