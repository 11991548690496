import { html, render, nothing } from "lit";
import ApplicationState from 'applicationstate';
import { Dropdown } from 'bootstrap';
import directus from "shared/lib/lib-directus";
import AppWarningModal from 'shared/components/app-warning-modal';
import { getCurrentClientId } from "shared/lib/lib-user";
import AppNotificationModal from 'shared/components/app-notification-modal';
import AppConfirmationModal from 'shared/components/app-confirmation-modal';
import 'shared/components/filter/app-filter-data-view-server';
import { format } from 'date-fns';

/**
 * Page where one can select imported patients and take actions to move them towards approval/activation.
 */
export default class ImportPendingPatients extends HTMLElement {
    set filter_state(value) {
        this._filter_state = value;
    }

    /** @type {import('shared/components/filter/app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;
    }
    get status_filter() {
        return this._status_filter;
    }

    set status_filter(value) {
        this._status_filter = value;
        //console.log("status filter setter", value);
        this._options.checkbox = this.status_filter === "import_approval_requested";
    }
    set selected_columns(value) {
        this._selected_columns = value;
    }

    get selected_columns() {
        return this._selected_columns;
    }

    set table_columns(value) {
        this._table_columns = value;
    }

    get table_columns() {
        return this._table_columns;
    }

    set selected_patients(value) {
        this._selected_patients = value;
        this.renderActionsMenu();
        this.updateDropdown();
        //console.log("selected patients setter", value);
    }


    get selected_patients() {
        return this._selected_patients;
    }

    constructor() {
        super();
        this._app_name = ApplicationState.get('app.name');
        this._options = {
            ajaxURL: "/items/patient",
            ajaxParams: {},
            sortMode: "remote",
            filterMode: "remote",
            //pagination: true,
            //paginationMode: "remote",
            //paginationSize: 25,
            paginationCounter: "rows",
            layout: "fitDataFill",
            progressiveLoad: "scroll",
            progressiveLoadScrollMargin: 300,
            responsiveLayout: "collapse",
            responsiveLayoutCollapseStartOpen: false,
            alignEmptyValues: "bottom",
            initialSort: [{ column: "current_episode_start", dir: "desc" },],
            columnDefaults: {
                tooltip: false,
            },
            checkbox: true
        };
        this._selected_columns = [
            "status",
            "status_reason",
            "first_name",
            "last_name",
            "mrn",
            "primary_diagnosis",
            "current_episode_id.start_date",
            "current_episode_id.end_date",
            "episodes.start_date",
            "episodes.end_date",
            "current_episode_start",
            "current_episode_end",
            "current_soc",
            "primary_payer",
            "engagement_specialist.last_name",
            "internal_memo",
            "status_reason",
            "tasks.scheduled_date",
            //"client_id.name",
            //"iso_start",
            "ext_referral",
            "referral_source"
        ];
        this._table_columns = [


            { formatter: "responsiveCollapse", field: "responiveIcon", headerSort: false },
            {
                title: "Status",
                fields: ["status"],
                minWidth: 290,
                field: "statuses",
                sorter: "string",
                sorterParams: {
                    field: "status_reason"
                },

                formatter: function (cell, formatterParams) {
                    let rowData = cell.getRow().getData();

                    let status = rowData.status ? '<strong>' + rowData.status.replace(/_/g, " ").replace("import ", "") + '</strong>' : '';
                    let statusReason = rowData.status_reason ? ' (' + rowData.status_reason.replace(/_/g, " ") + ')' : '';

                    return status + statusReason;
                    //return status;
                },

            },


            //{
            //    name: "Agency",
            //    fields: ["client_id.name"],
            //    field: "client_id.name"
            //},
            {
                name: "Patient Name",
                fields: ["last_name", "first_name"],
                field: "name",
                link: ", ",
                sorter: "string",
                responsive: 0,
                sorterParams: {
                    field: "last_name"
                },
                minWidth: 180,
            },
            {
                name: "MRN",
                fields: ["mrn"],
                field: "mrn",
                minWidth: 50,
            },
            {
                title: "Primary Diagnosis",
                name: "PDX",
                minWidth: 280,
                tooltip: true,
                fields: ["primary_diagnosis"],
                field: "primary_diagnosis",
                formatter: function (cell) {
                    const value = cell.getValue();
                    return value ? value : "";
                }

            },

            {
                title: "Episodes",
                field: "current_episode_start",
                minWidth: 265,
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getRow().getData();
                    const episodes = rowData.episodes;
                    const summary = document.createElement("div");

                    // Conditionally create and append expand button if episodes exist
                    if (Array.isArray(episodes) && episodes.length > 0) {
                        const expandBtn = document.createElement("button");
                        expandBtn.textContent = "+";
                        summary.appendChild(expandBtn);

                        // Event listener for expand/collapse
                        expandBtn.addEventListener("click", function () {
                            if (expandBtn.textContent === "+") {
                                expandBtn.textContent = "-";
                                // Render full list of episodes
                                const episodesList = document.createElement("ul");
                                episodes.forEach(episode => {
                                    const listItem = document.createElement("li");
                                    const startDate = episode.start_date ? format(new Date(episode.start_date), "MM/dd/yyyy") : "(no start date)";
                                    const endDate = episode.end_date ? format(new Date(episode.end_date), "MM/dd/yyyy") : "(no end date)";
                                    listItem.textContent = `${startDate} - ${endDate}`;
                                    episodesList.appendChild(listItem);
                                });
                                summary.appendChild(episodesList);
                            } else {
                                expandBtn.textContent = "+";
                                // Remove the full list of episodes
                                summary.removeChild(summary.lastChild);
                            }
                            cell.getRow().normalizeHeight(); // Update the row height
                        });
                    }

                    // Create summary text container for the initial date and count bubble
                    const currentStart = rowData.current_episode_start ? format(new Date(rowData.current_episode_start), "MM/dd/yyyy") : "(no start date)";
                    const currentEnd = rowData.current_episode_end ? format(new Date(rowData.current_episode_end), "MM/dd/yyyy") : "(no end date)";
                    const summaryTextContainer = document.createElement("span");
                    summaryTextContainer.textContent = `${currentStart} - ${currentEnd}`;
                    summary.appendChild(summaryTextContainer);

                    // Append count bubble to summary text container if episodes exist
                    if (Array.isArray(episodes) && episodes.length > 0) {
                        const countBubble = document.createElement("span");
                        countBubble.classList.add("episode-count-bubble");
                        countBubble.textContent = ` ${episodes.length}`;
                        summaryTextContainer.appendChild(countBubble);
                    }

                    return summary;
                },



                variableHeight: true // Enable variable row height

            },

            {
                name: "Primary Payor",
                fields: ["primary_payer"],
                field: "primary_payer",
                minWidth: 300
            },
            {
                name: "Engagement Specialist",
                fields: ["engagement_specialist.last_name"],
                field: "engagement_specialist.last_name",
                minWidth: 100
            },
            {
                title: " Referral Source",
                field: "referral", // This field name is arbitrary since we are not using it directly
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getData();
                    let content = '';

                    if (rowData.referral_source) {
                        content += `${rowData.referral_source}`;
                    }
                    if (rowData.referral_source && rowData.ext_referral) {
                        content += '<span style="width:15px;"></span>';
                    }
                    if (rowData.ext_referral) {
                        content += `<strong style="margin-right:20px;">External Referral</strong> ${rowData.ext_referral}`;
                    }

                    return content;
                }
            },

            {
                name: "Internal Memo",
                fields: ["internal_memo"],
                field: "internal_memo",
                minWidth: 100,
                maxWidth: 350,
                formatter: "html",
            },
        ];
        this._filter_config = {
            collection: { name: "patient", auto_configure: false, depth: 3 },
            search_fields: [
                "first_name",
                "last_name",
                "status",
                "source",
                "mrn",
                "medicare_id",
            ],
        };
        this._filter_state = { group: { type: "_and", groups: [] }, user_search: '' };
        this.dropdown = null;
    }

    connectedCallback() {
        this.template = () =>
            html`
                <style>
                    import-pending-patients .item-row:hover {
                        background-color: #f0f0f0;
                        cursor: pointer;
                    }
                    import-pending-patients li.disabled {
                        cursor: not-allowed;
                    }
                </style>
                <div class="container-fluid">
                    <div class="row nav-cont">
                        <div class="actions-menu">

                         ${this.renderActionsMenu()}
                            <app-filter 
                                 style=""
                                .filter_state=${this._filter_state}
                                .config=${this._filter_config}
                                .enable_aggregate=${false}
                                .disable_sort=${true}
                                .show_filters=${false}
                            ></app-filter>
                           
                </div> 
                    </div>
                    <div class="row" id="patients-scroll-container">
                       
                        <app-filter-data-view-server
                           .config=${this._filter_config}
                            .filter_state=${this.filter_state}
                            .selected_columns=${this.selected_columns}
                            .table_columns=${this.table_columns}
                            .options=${this._options}
                            .selected_data=${this.selected_patients}
                            .selection_type=${"multiple"}
                           .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        },

                    ]
                }}
                            @select-row=${e => this.handleSelect(e.detail)}
                            style="padding: 0;"
                        ></app-filter-data-view-server>
                       
                    </div>
                </div>
            `;

        this.render();
        this.init();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    renderActionsMenu() {
        // Only show the dropdown if status_filter is "pending"
        if (this.status_filter !== "import_approval_requested") {
            return nothing;
        }

        const actions = ["Approve", "Decline Approval"];
        const selected_count = this.selected_patients?.length;

        return html`
        <div class="input-group mb-3 dropdown-actions">
        <div class="dropdown">
            <button 
                class="btn custom-dropdown-btn dropdown-toggle" 
                id="btn-dropdown"
                type="button" 
                data-bs-toggle=${this.is_datepicker ? nothing : "dropdown"}
                aria-expanded="false"
            >
                ${`${(selected_count || 0)} Patient${selected_count === 1 ? "" : "s"} Selected`}
            </button>
            <div class="dropdown-menu" style=${`width: ${this.has_typeahead ? "200px" : "auto"};`}>
                <ul style="padding: 0; margin: 0;">
                    ${actions.map(action_name => html`
                        <li 
                            class=${"dropdown-item" + (selected_count ? "" : " disabled")}
                            style="cursor: pointer;"
                            @click=${_e => this.handleActionClick(action_name)}
                        >
                            ${action_name}
                        </li>
                    `)}
                </ul>
            </div>
        </div>
        </div>
    `;
    }


    init() {
        // let filter_state = ApplicationState.get('app.import_queued_patients.filter_state');
        let filter_state;
        if (!filter_state) {
            // default filter to non-active imported patients
            filter_state = { user_search: "" };
            filter_state.group = {
                type: "_and",
                filters: [
                    {
                        "field": "status",
                        "op": "_in",
                        "value": [this.status_filter]
                    },


                    //{
                    //    "field": "iso_start",
                    //    "op": "_gte",
                    //    "value": "$NOW(-10d)"
                    //},
                    //{
                    //    "field": "on_service",
                    //    "op": "_eq",
                    //    "value": false
                    //}

                ],
                groups: [],
            };
        }
        let filter_element = this.querySelector('app-filter');
        filter_element.filter_state = filter_state;
        this.filter_state = filter_state;

        let dropdownElement = this.querySelector(".dropdown-toggle");
        if (dropdownElement) {
            this.dropdown = new Dropdown(dropdownElement, {
                autoClose: "outside"
            });
        }


        this.render();
    }

    /**
     * Warns the user if incorrect patient selections are made for an action.
     * @param {String} modal_text 
     */
    showWarningModal(modal_text) {
        const warning_modal = new AppWarningModal();
        warning_modal.modal_text = modal_text;
        warning_modal.showModal();
        //this.refreshTableData();
    }

    updateDropdown() {
        const dropdownButton = this.querySelector("#btn-dropdown");
        const actionItems = this.querySelectorAll(".dropdown-item");

        const selectedCount = this._selected_patients.length;
        dropdownButton.textContent = `${selectedCount} Patient${selectedCount === 1 ? "" : "s"} Selected`;

        actionItems.forEach(item => {
            if (selectedCount > 0) {
                item.classList.remove("disabled");
            } else {
                item.classList.add("disabled");
            }
        });
    }

    async updateSelectedPatients(updates) {
        const selected_patient_ids = this.selected_patients.map(patient => patient.id);
        await directus.items("patient").updateMany(selected_patient_ids, updates);
        this.selected_patients = [];

        const filterDataView = this.querySelector('app-filter-data-view-server');
        filterDataView?.handlePageChange();
    }

    /**
     * Confirms correct selections for action and executes the action.
     * @param {String} action_name 
     */
    handleActionClick(action_name) {
        switch (action_name) {
            case "Approve":
                if (this.selected_patients.find(patient => patient.status !== "import_approval_requested"))
                    this.showWarningModal("Only patients in the 'Import Approval Requested' status may be selected for approval.")
                else this.handleApprove("Request for approval has been approved.");
                break;
            case "Decline Approval":
                if (this.selected_patients.find(patient => patient.status !== "import_approval_requested"))
                    this.showWarningModal("Only patients in the 'Import Approval Requested' status may be declined for approval.")
                else this.handleDeclineApproval("Request for approval has been declined.");
                break;
        }
    }

    async handleApprove(message) {
        const modal = new AppConfirmationModal();
        modal.modal_text = `Are you sure you want to approve selected patients`;
        await modal.showModal();
        const res = await modal.onDidDismiss();
        if (res?.confirmed) {
            await this.updateSelectedPatients({ status: "import_approved" });
            this.showConfirmationModal(message);
        }
    }

    async handleDeclineApproval(message) {
        const modal = new AppConfirmationModal();
        modal.modal_text = `Are you sure you want to decline selected patients`;
        await modal.showModal();
        const res = await modal.onDidDismiss();
        if (res?.confirmed) {
            await this.updateSelectedPatients({ status: "import_declined" });
            this.showConfirmationModal(message);
        }
    }

    async handleSelect(patients) {
        this.selected_patients = patients;
    }

    showConfirmationModal(message) {
        const confirmation_modal = new AppNotificationModal();
        confirmation_modal.modal_text = message;
        confirmation_modal.showModal();

        //this.refreshTableData();
    }
    async refreshTableData() {
        await new Promise(resolve => setTimeout(resolve, 250));
        const filterDataViewComponent = this.querySelector('app-filter-data-view-server');
        if (filterDataViewComponent) {
            filterDataViewComponent.refreshTableData();
        }
    }
}

customElements.define("import-pending-patients", ImportPendingPatients);
