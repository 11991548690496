import { LitElement, html, css } from "lit";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import { Draggable } from '@fullcalendar/interaction';
import 'bootstrap';
import TimePicker from 'tui-time-picker';
import 'shared/assets/css/time-picker.css';
import ClientSettings from "shared/lib/lib-client-settings";
import { Popover } from 'bootstrap';
import directus from "shared/lib/lib-directus";
import { getCurrentClientId } from "shared/lib/lib-user";
import "shared/components/app-report-filter";
import AppConfirmationModal from "shared/components/app-confirmation-modal";
import { addDays } from 'date-fns';
import AppSettingsScheduleModal from "shared/components/app-settings-schedule-modal.js";
import { ca } from "date-fns/locale";
import { WindowScrollController } from "@fullcalendar/core/internal";

export default class SceneSettingsSchedule extends LitElement {

    static styles = css`

    `;

    static properties = {

        availableSchedules: { type: Object },
        availableForms: { type: Object },
        availableSmsTemplates: { type: Object },
        scheduleItems: { type: Object },
        //surveyScheduleId: { type: Number },
        loading: { type: Boolean },
        calendarHeight: { type: Number },
    };

    constructor() {
        super();
        this.availableSchedules = { data: [] };
        this.availableForms = { data: [] };
        this.availableSmsTemplates = { data: [] };
        this.scheduleItems = { data: [] };
        this.surveyScheduleId = null;
        this.calendarHeight = null;
        this.startDate = new Date();
        this.loading = true; // Add loading state
    }

    createRenderRoot() {
        return this;
    }

    async firstUpdated() {
        await this.loadData();
        this.initializeCalendar();
        this.initializeExternalEvents();
        this.loadSettings();

    }

    async loadData() {
        try {
            // Load available survey schedules with type and tags
            const schedulesResponse = await directus.items('survey_schedule').readByQuery({
                filter: {
                    client_id: getCurrentClientId(),
                    status: "published"
                },
                fields: ['id', 'name', 'status', 'survey_schedule_items', 'client_id', 'type', 'tags'],
                limit: -1,
            });
            this.availableSchedules = schedulesResponse;

            // Load available forms
            const formsResponse = await directus.items('form_design').readByQuery({
                filter: {
                    _and: [
                        //{ client_id: getCurrentClientId() },
                        { category: { _in: ['survey', 'prn', 'self'] } },
                        { status: "published" }
                    ]
                },

                fields: ['id', 'status', 'title', 'category', 'client_id'],
                limit: -1,
            });

            this.availableForms = formsResponse;

            // Load available SMS templates
            const smsTemplatesResponse = await directus.items('sms_templates').readByQuery({
                filter: {
                    client_id: getCurrentClientId(),
                    status: "published"
                },
                limit: -1,
            });

            this.availableSmsTemplates = smsTemplatesResponse;

            // Load schedule items for the current survey schedule
            if (this.surveyScheduleId) {
                const itemsResponse = await directus.items('survey_schedule_item').readByQuery({
                    filter: { survey_schedule_id: this.surveyScheduleId },
                    fields: ['id', 'day', 'time', 'form_design_id.id', 'form_design_id.title', 'form_design_id.category', 'form_design_id.status', 'survey_schedule_id.id', 'sms_template_id.status', 'sms_template_id.template_name', 'sms_template_id.template_type', 'sms_template_id.template_content'],
                    limit: -1,
                });
                this.scheduleItems = itemsResponse;
            } else {
                this.scheduleItems = { data: [] };
            }

            this.loading = false;
            this.requestUpdate();
            await this.updateComplete;
            if (this.calendar) {
                this.calendar.removeAllEvents();
                this.calendar.addEventSource(this.mapScheduleItemsToEvents(this.startDate));
            }
        } catch (error) {
            console.error("Error loading data:", error);
        }
    }

    mapScheduleItemsToEvents(startDate) {
        const events = this.scheduleItems.data.map(item => {
            let title = 'Unknown Item';
            let formId = null;
            let smsTemplateId = null;
            let eClass = null;
            let smsContent = null;

            if (item.form_design_id) {
                title = item.form_design_id.title;
                formId = item.form_design_id.id;
                eClass = item.form_design_id.category;
            }
            if (item.sms_template_id) {
                title = item.sms_template_id.template_name;
                smsTemplateId = item.sms_template_id.id;
                eClass = item.sms_template_id.template_type;
                smsContent = item.sms_template_id.template_content;
            }
            console.log('map', eClass);
            const event = {
                id: item.id,
                title: title,
                className: `${eClass}${item.time ? ' has-time' : ''}`,
                allDay: true,
                start: addDays(new Date(startDate), item.day - 1),
                extendedProps: {
                    day: item.day,
                    time: item.time,
                    formId: formId,
                    smsTemplateId: smsTemplateId,
                    category: eClass,
                    smsContent: smsContent,
                },
                editable: true,
            };


            return event;
        });

        return events;
    }

    async initializeCalendar() {
        const calendarEl = this.querySelector('#calendar');
        this.startDate = new Date(2040, 0, 1);
        //this.startDate = new Date();
        this.calendar = new Calendar(calendarEl, {
            plugins: [interactionPlugin, dayGridPlugin, multiMonthPlugin],
            initialView: 'sixtyDayCont',
            initialDate: this.startDate,
            editable: true,
            droppable: true,
            views: {
                sixtyDayCont: {
                    type: 'dayGrid',
                    duration: { months: 2 },
                    dayCount: 60,
                    showNonCurrentDates: false,
                    buttonText: 'Continuous',
                },
                sixtyDayDual: {
                    type: 'multiMonth',
                    duration: { months: 2 },
                    multiMonthMaxColumns: 2,
                    showNonCurrentDates: false,
                    fixedWeekCount: false,
                    buttonText: '2-UP',
                    aspectRatio: 1.8,
                    multiMonthTitleFormat: { month: 'short' },
                }
            },
            headerToolbar: {
                left: '',
                center: '',
                right: '',
            },
            //dateClick: (info) => {
            //    this.handleDateClick(info);
            //},
            eventClick: (info) => {
                if (info.event.extendedProps.category === 'self' || info.event.extendedProps.smsTemplateId !== null) {
                    this.showTimeSettingPopover(info.el, info.event);
                }
            },
            eventDidMount: (info) => {
                //console.log('Event mounted:', info.event);
                //if (info.event.extendedProps.needsTimeSetting) {
                //    console.log('Showing time setting popover for:', info.event);
                //    this.showTimeSettingPopover(info.el, info.event);
                //    info.event.setExtendedProp('needsTimeSetting', false);
                //}

                info.el.addEventListener('contextmenu', (e) => {
                    e.preventDefault();
                    this.handleEventRightClick(info);
                });
            },
            eventData: (eventEl) => {
                let eventJson = JSON.parse(eventEl.getAttribute('data-event'));
                // Check if it's an SMS item and adjust the title accordingly
                if (eventJson.template_name) {
                    eventJson.title = eventJson.template_name;
                }
                return eventJson;
            },
            eventDragStart: (info) => {
                //info.el.classList.add('is-dragging');
                const mirrorElement = document.querySelector('.fc-event-dragging');
                if (mirrorElement) {
                    mirrorElement.classList.add('custom-dragging');

                    // Add class based on event type
                    if (info.event.extendedProps.category === 'survey') {
                        mirrorElement.classList.add('survey');
                    } else if (info.event.extendedProps.category === 'prn') {
                        mirrorElement.classList.add('prn');
                    } else if (info.event.extendedProps.template_type !== null) {
                        mirrorElement.classList.add('sms');
                    }
                }
            },
            eventDragStop: (info) => {
                //info.el.classList.remove('is-dragging');
                const mirrorElement = document.querySelector('.fc-event-dragging');
                if (mirrorElement) {
                    mirrorElement.classList.remove('custom-dragging', 'survey', 'prn', 'sms');
                }
            },
            eventClassNames: (arg) => {
                const classes = [];
                if (arg.event.extendedProps.category) {
                    classes.push(arg.event.extendedProps.category);
                }
                if (arg.event.extendedProps.template_type) {
                    classes.push(arg.event.extendedProps.template_type);
                }
                return classes;
            },
            eventContent: (arg) => {
                const { extendedProps } = arg.event;
                let timeString = '';
                if (extendedProps.time) {
                    const [hours, minutes] = extendedProps.time.split(':');
                    const time = new Date(2000, 0, 1, hours, minutes);
                    timeString = time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
                }
                if (extendedProps.category === 'self' || extendedProps.smsTemplateId !== null) {
                    return {
                        html: `
                    <div class="1a fc-event-main">
                      <div class="2a fc-event-title">${arg.event.title}</div>
                      ${timeString ? `<div class="fc-event-time">${timeString}</div>` : ''}
                    </div>
                `};
                } else {
                    return { html: `<div class="3a fc-event-title">${arg.event.title}</div>` };
                }


            },
            dayCellDidMount: this.customizeDayCell.bind(this),
            events: this.mapScheduleItemsToEvents(this.startDate),
            eventDrop: this.handleEventDrop.bind(this),
            eventReceive: this.handleEventReceive.bind(this),
            dayHeaders: false,
            headerToolbar: false,
            showNonCurrentDates: false,
            height: 910,
            contentHeight: 'auto',

        });
        this.calendar.render();
        if (this.calendar) {
            const viewportHeight = window.innerHeight;
            const calendarTop = calendarEl.getBoundingClientRect().top;
            const calendarHeight = viewportHeight - calendarTop - 80;
            const calendarContentHeight = calendarHeight - 90;

            this.calendar.setOption("height", calendarHeight);
            this.calendar.setOption("contentHeight", calendarContentHeight);
            this.calendar.updateSize();
        }
        //this.calendar.updateSize()


    }
    //getVisibleRange(currentDate) {
    //    const start = this.startDate;
    //    const end = addDays(start, 59);
    //    return { start, end };
    //}

    customizeDayCell(arg) {
        const { el, date, view } = arg;
        el.innerHTML = '';
        const dayNumber = document.createElement('div');
        dayNumber.className = 'fc-daygrid-day-number';
        const dayCount = this.getDayCount(date, view);
        dayNumber.textContent = dayCount >= 0 && dayCount <= 60 ? dayCount : '';
        el.appendChild(dayNumber);
    }

    getDayCount(date, view) {
        const viewStart = view.currentStart;
        return Math.floor((date - viewStart) / (24 * 60 * 60 * 1000)) + 1;
    }


    handleEventDrop(info) {
        console.log('drop', info);
        const newDay = this.getDayCount(info.event.start, this.calendar.view);
        this.updateScheduleItem(info.event.id, newDay);
    }
    handleEventReceive(info) {
        console.log('receive', info.event);
        if (info.event.extendedProps.template_name) {
            info.event.setProp('title', info.event.extendedProps.template_name);
        }

        const newDay = this.getDayCount(info.event.start, this.calendar.view);
        this.addScheduleItem(info.event, newDay);
    }


    initializeExternalEvents() {
        const externalEventElements = this.querySelectorAll('.external-event');
        externalEventElements.forEach(eventEl => {
            new Draggable(eventEl, {
                itemSelector: '.fc-event',
                eventData: (eventEl) => {
                    return JSON.parse(eventEl.getAttribute('data-event'));
                }
            });
        });

        const calendarEl = this.querySelector('#calendar');
        const dayCells = calendarEl.querySelectorAll('.fc-daygrid-day');
        dayCells.forEach(cell => {
            cell.addEventListener('dragover', (e) => e.preventDefault());
            cell.addEventListener('drop', (e) => e.preventDefault());
        });
    }

    async updateScheduleItem(eventId, newDay) {
        await directus.items('survey_schedule_item').updateOne(eventId, {
            day: newDay,
        });
    }

    async addScheduleItem(event, day) {
        try {
            const itemId = event.id;
            const payload = {
                survey_schedule_id: this.surveyScheduleId,
                day: day,
            };

            if (event.extendedProps.category) {
                payload.form_design_id = itemId;
            } else if (event.extendedProps.template_type) {
                payload.sms_template_id = itemId;
            } else {
                console.warn('Unknown item type:', itemId);
                return;
            }

            const response = await directus.items('survey_schedule_item').createOne(payload);
            console.log('Item added successfully:', response);

            // Set flag for time setting if needed
            if (event.extendedProps.category === 'self' || event.extendedProps.smsTemplateId !== null) {
                event.setExtendedProp('needsTimeSetting', true);
            }

            // Reload the data and refresh the calendar
            await this.loadData();
            this.calendar.refetchEvents();
        } catch (error) {
            console.error('Error adding schedule item:', error);
        }
    }



    handleEventRightClick(info) {
        // Remove any existing popover
        const existingPopover = document.querySelector('.popover');
        if (existingPopover) {
            existingPopover.remove();
        }

        // Create the content for the popover
        const popoverContent = document.createElement('div');
        const removeButton = document.createElement('button');
        removeButton.className = 'btn btn-danger btn-sm remove-sched-item';
        removeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#9d3056"><path d="M336.87-262.87 480-406l143.13 143.13 74-74L554-480l143.13-143.13-74-74L480-554 336.87-697.13l-74 74L406-480 262.87-336.87l74 74ZM480-44.65q-90.36 0-169.91-34.16-79.56-34.16-138.34-92.94T78.81-310.09Q44.65-389.64 44.65-480q0-90.61 34.22-170.27 34.22-79.66 93.1-138.61 58.88-58.95 138.34-92.71 79.45-33.76 169.69-33.76 90.6 0 170.26 33.76 79.65 33.76 138.61 92.72 58.96 58.96 92.72 138.64 33.76 79.68 33.76 170.31 0 90.64-33.76 169.85-33.76 79.22-92.71 138.1-58.95 58.88-138.61 93.1Q570.61-44.65 480-44.65Z"/></svg>';

        removeButton.onclick = () => {
            this.removeScheduleItem(info.event);
            // Hide the popover after action
            info.el._popover.hide();
        };

        popoverContent.appendChild(removeButton);

        // Initialize the popover
        const popover = new Popover(info.el, {
            content: popoverContent,
            html: true,
            //title: 'Event Actions',
            placement: 'right', // Base placement is 'top'
            trigger: 'manual',
            popperConfig: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: ({ popper, reference }) => {
                                const alignment = reference.width / 2 - popper.width / 2;
                                return [-22, - 0];
                            },
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: 'viewport',
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: ['auto'],
                        },
                    },
                ],
            },
        });

        popover.show();

        info.el._popover = popover;
        const popoverElement = document.querySelector('div.popover');
        if (popoverElement) {
            popoverElement.classList.add('remove-sched-popover');
        }
        const hidePopover = (event) => {
            if (!popoverContent.contains(event.target) && event.target !== info.el) {
                popover.hide();
                document.removeEventListener('click', hidePopover);
            }
        };

        document.addEventListener('click', hidePopover);
    }

    async showTimeSettingPopover(element, event) {
        console.log('showTimeSettingPopover', event);
        const existingPopovers = document.querySelectorAll('.popover');
        existingPopovers.forEach(p => p.remove());
        const smsContent = event.extendedProps.smsContent;
        const defaultTime = this.settings?.ALL?.default_sms_time?.value || '10:00';
        const eventTime = event.extendedProps.time || defaultTime;
        const [hours, minutes] = eventTime.split(':');
        const initialHour = parseInt(hours);
        const initialMinute = parseInt(minutes);

        const popoverContent = `
    <div class="time-setting-popover custom-popover-width">
    <div class="sch-pop-left">    
    <div id="timePickerContainer"></div>
        <button class="btn btn-primary btn-sm mt-2" id="saveTimeBtn">Save</button>
    </div>
        <div class="sch-pop-right">
        ${smsContent ? `<div class="sms-content">${smsContent}</div>` : ''}
        <div>
    </div>
    `;

        const popover = new Popover(element, {
            content: popoverContent,
            html: true,
            sanitize: false,
            width: '500px',
            trigger: 'manual',
            placement: 'auto',
            container: 'body',
            customClass: 'sched-time-pop-cont'
        });

        popover.show();

        await new Promise(resolve => setTimeout(resolve, 0));

        const popoverBody = document.querySelector('.time-setting-popover');
        if (popoverBody) {
            const timePickerContainer = popoverBody.querySelector('#timePickerContainer');
            if (timePickerContainer) {
                const timePicker = new TimePicker(timePickerContainer, {
                    initialHour: initialHour,
                    initialMinute: initialMinute,
                    inputType: 'spinbox',
                    showMeridiem: true,
                    theme: 'dark'
                });

                const saveButton = popoverBody.querySelector('#saveTimeBtn');
                saveButton.addEventListener('click', () => {
                    const selectedHour = timePicker.getHour();
                    const selectedMinute = timePicker.getMinute();
                    const formattedTime = `${selectedHour.toString().padStart(2, '0')}:${selectedMinute.toString().padStart(2, '0')}`;
                    this.updateEventTime(event, formattedTime);
                    popover.hide();
                });
            }
        }

        const closePopover = (e) => {
            if (!element.contains(e.target) && !document.querySelector('.popover').contains(e.target)) {
                popover.hide();
                document.removeEventListener('click', closePopover);
            }
        };

        document.addEventListener('click', closePopover);

        // Add time indication to the event title
        const eventTitle = element.querySelector('.fc-title');
        if (eventTitle) {
            const timeIndicator = document.createElement('span');
            timeIndicator.className = 'time-indicator';
            timeIndicator.style.marginLeft = '10px';
            if (currentTime) {
                timeIndicator.textContent = currentTime;
                eventTitle.appendChild(timeIndicator);
            } else {
                timeIndicator.textContent = 'No time set';
                eventTitle.appendChild(timeIndicator);
            }
        }
    }

    async updateEventTime(event, newTime) {
        // Convert the newTime to 24-hour format if it's not already
        const [time, period] = newTime.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours);

        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes}`;

        event.setExtendedProp('time', formattedTime);
        event.setProp('title', `${event.title} (${formattedTime})`);

        // Update the underlying schedule item
        await directus.items('survey_schedule_item').updateOne(event.id, {
            time: formattedTime
        });
    }


    async removeScheduleItem(event) {
        try {
            await directus.items('survey_schedule_item').deleteOne(event.id);
            this.calendar.getEventById(event.id).remove();
        } catch (error) {
            console.error('Error removing schedule item:', error);
        }
    }

    async handleScheduleChange(newScheduleId) {
        this.surveyScheduleId = newScheduleId;
        await this.loadData();
        this.calendar.removeAllEvents();
        this.calendar.addEventSource(this.mapScheduleItemsToEvents(this.startDate));
    }

    handleScheduleAction(action) {
        switch (action) {
            case 'add':
                this.handleAddSchedule();
                break;
            case 'duplicate':
                this.handleDuplicateSchedule();
                break;
            case 'delete':
                this.handleDeleteSchedule();
                break;
        }
    }

    showScheduleActionsPopover(event) {
        const button = event.currentTarget;
        const existingPopover = document.querySelector('.popover');
        if (existingPopover) {
            existingPopover.remove();
        }

        const popoverContent = document.createElement('div');
        popoverContent.className = 'schedule-actions-popover d-flex flex-column';

        ['Add', 'Duplicate', 'Delete'].forEach(action => {
            const actionButton = document.createElement('button');
            actionButton.className = 'btn btn-sm btn-outline-secondary mb-2';
            actionButton.textContent = action;
            actionButton.onclick = () => this.handleScheduleAction(action.toLowerCase());
            popoverContent.appendChild(actionButton);
        });

        const popover = new Popover(button, {
            content: popoverContent,
            html: true,
            placement: 'bottom',
            trigger: 'manual',
            customClass: 'schedule-actions-popover'
        });

        popover.show();

        const hidePopover = (e) => {
            if (!popoverContent.contains(e.target) && e.target !== button) {
                popover.hide();
                document.removeEventListener('click', hidePopover);
            }
        };
        document.addEventListener('click', hidePopover);
    }

    async handleScheduleNameUpdate(scheduleId, newName) {
        if (!scheduleId || !newName.trim()) return;

        await directus.items('survey_schedule').updateOne(scheduleId, {
            name: newName
        });

        const schedule = this.availableSchedules.data.find(s => s.id === scheduleId);
        if (schedule) {
            schedule.name = newName;
        }
        this.requestUpdate();
    }

    async handleAddSchedule(message) {
        const modal = new AppSettingsScheduleModal();
        await modal.showModal();
        const result = await modal.onDidDismiss();
        if (result?.confirmed) {
            const newSchedule = await directus.items('survey_schedule').createOne({
                name: result.name,
                type: result.type,
                tags: result.tags,
                status: 'published',
                client_id: getCurrentClientId()
            });

            console.log('New schedule created parent scene:', result.message);
            this.availableSchedules.data.push(newSchedule);
            this.showConfirmationModal(message);
            this.requestUpdate();
        }
    }



    renderScheduleBadges(schedule) {
        return html`
            <span class="schedule-badge type-badge">${schedule.type || 'patient'}</span>
            ${(schedule.tags || []).map(tag => html`
                <span class="schedule-badge tag-badge ${tag.toLowerCase()}">${tag.toUpperCase()}</span>
            `)}
        `;
    }

    showConfirmationModal(message) {
        const confirmation_modal = new AppConfirmationModal();
        const title = 'Success';
        confirmation_modal.modal_text = message;
        confirmation_modal.modal_title = title;

        confirmation_modal.showModal(message);

    }

    async handleDeleteSchedule() {
        if (!this.surveyScheduleId) {
            alert("Please select a schedule to delete.");
            return;
        }
        if (confirm("Are you sure you want to delete this schedule?")) {
            try {
                await directus.items('survey_schedule').deleteOne(this.surveyScheduleId);
                this.availableSchedules.data = this.availableSchedules.data.filter(s => s.id !== this.surveyScheduleId);
                this.surveyScheduleId = null;
                this.requestUpdate();
            } catch (error) {
                console.error('Error deleting schedule:', error);
            }
        }
    }

    handleViewChange(event) {
        const clickedLabel = event.target.closest('label');
        const view = clickedLabel.textContent.includes('table_rows') ? 'sixtyDayCont' : 'sixtyDayDual';
        this.calendar.changeView(view);

        // Update active state
        this.querySelectorAll('.view-toggle label').forEach(label => {
            label.classList.remove('active');
        });
        clickedLabel.classList.add('active');
    }

    getCurrentScheduleName() {
        const currentSchedule = this.availableSchedules.data.find(s => s.id === this.surveyScheduleId);
        return currentSchedule ? currentSchedule.name : '-';
    }


    handlePrimaryScheduleChange(newScheduleId) {
        this.surveyScheduleId = newScheduleId;
        this.loadData();
    }

    async loadSettings() {
        let client_id = getCurrentClientId();
        this.settings = await ClientSettings.getSettings(client_id);

        if (!this.settings?.ALL?.default_sms_time) {
            await ClientSettings.addSetting({
                client_id: client_id,
                key: 'default_sms_time',
                value: '10:00',
                group: 'schedule'
            });
            this.settings = await ClientSettings.getSettings(client_id);
        }

        const defaultTime = this.settings.ALL.default_sms_time.value;
        this.setupDefaultTimePicker(defaultTime);
    }

    setupDefaultTimePicker(defaultTime) {
        const [hours, minutes] = defaultTime.split(':');
        let hour = parseInt(hours);
        const minute = parseInt(minutes);
        const meridiem = hour >= 12 ? 'PM' : 'AM';

        if (hour > 12) hour -= 12;
        if (hour === 0) hour = 12;

        this.defaultHour = hour;
        this.defaultMinute = minute.toString().padStart(2, '0');
        this.defaultMeridiem = meridiem;

        this.requestUpdate();
    }

    async saveDefaultTime(type, value) {
        console.log('Save method called with:', type, value);

        if (type === 'hour') {
            this.defaultHour = value;
        } else if (type === 'minute') {
            this.defaultMinute = value;
        } else if (type === 'meridiem') {
            this.defaultMeridiem = value;
        }

        let hour = parseInt(this.defaultHour);
        let minute = parseInt(this.defaultMinute);

        if (this.defaultMeridiem === 'PM' && hour !== 12) hour += 12;
        if (this.defaultMeridiem === 'AM' && hour === 12) hour = 0;

        const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

        const client_id = getCurrentClientId();
        await ClientSettings.setValue(client_id, 'default_sms_time', timeString);
        this.settings = await ClientSettings.getSettings(client_id);

        this.requestUpdate();
        console.log('Saved time:', timeString);
    }
    async eventTooltips() {

        document.addEventListener('DOMContentLoaded', function () {
            var scheduleNames = document.querySelectorAll('.schedule-name');
            scheduleNames.forEach(function (element) {
                element.setAttribute('title', element.textContent.trim());
            });

            // Initialize Bootstrap tooltips
            $(function () {
                $('[data-toggle="tooltip"]').tooltip();
            });
        });
    }

    setFullCalendarHeight = () => {
        // Get the viewport height
        console.log('setFullCalendarHeight');
        //const viewportHeight = window.innerHeight;

        //// Get the calendar's top position
        //const calendarTop = this.calendar.el.getBoundingClientRect().top;

        //// Calculate available space (subtract padding/margins)
        //const calendarHeight = viewportHeight - calendarTop - 20; // 20px bottom margin

        //// Set both height and contentHeight
        //this.calendar.setOption("height", calendarHeight);
        //this.calendar.setOption("contentHeight", calendarHeight);
        //console.log('calendarHeight', calendarHeight);
        //// Force calendar to update its size
        //this.calendar.updateSize();
    }



    render() {
        const surveyAndPrnForms = this.availableForms.data.filter(form => form.category === 'survey' || form.category === 'prn');
        const selfForms = this.availableForms.data.filter(form => form.category === 'self');

        return html`
        <div class="sched-cont">
        ${this.loading ? html`<p>Loading...</p>` : html`
        <div class="row">
            <div class="col col-lg-2 sched-events">

                <div id="external-events">
      <div class="ext-event-cont ext-forms">
        <h4 @click=${(e) => this._toggleSection(e)}>TeleChecks™ <span class="material-symbols-outlined updown">keyboard_arrow_up</span></h4>
        ${surveyAndPrnForms.sort((a, b) => a.title.localeCompare(b.title)).map(form => html`
          <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event external-event ${form.category}" draggable="true" data-event='${JSON.stringify(form)}'>
            <div class="fc-event-main" title="${form.title}">${form.title}</div>
          </div>
        `)}
      </div>
      <div class="ext-event-cont ext-self">
        <h4 @click=${(e) => this._toggleSection(e)}>BridgeChecks™ <span class="material-symbols-outlined updown">keyboard_arrow_up</span></h4>
        ${selfForms.sort((a, b) => a.title.localeCompare(b.title)).map(form => html`
          <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event external-event ${form.category}" draggable="true" data-event='${JSON.stringify(form)}'>
            <div class="fc-event-main" title="${form.title}">${form.title}</div>
          </div>
        `)}
      </div>
      <div class="ext-event-cont ext-sms">
        <h4 @click=${(e) => this._toggleSection(e)}>BridgeEDU™ <span class="material-symbols-outlined updown">keyboard_arrow_up</span></h4>
        ${this.availableSmsTemplates.data.sort((a, b) => a.template_name.localeCompare(b.template_name)).map(template => html`
          <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event external-event ${template.template_type}" draggable="true" data-event='${JSON.stringify(template)}'>
            <div class="fc-event-main" title="${template.template_name}">${template.template_name}</div>
          </div>
        `)}
      </div>
    </div>

            </div>
            <div class="col col-lg-10 sched-cal">
                <div class="row sched-actions">     
                    <div class="dropdown sched-dropdown">
                        <span class="sched-label">Editing</span>
                        <button class="btn btn-secondary dropdown-toggle custom-dropdown-btn" type="button" id="primaryScheduleDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            ${this.surveyScheduleId ? this.getCurrentScheduleName() : 'Select Schedule'}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="primaryScheduleDropdown">
                            ${this.availableSchedules.data.map(schedule => html`
                                <li>
                                    <a class="dropdown-item" href="#" @click=${(e) => {
                e.preventDefault();
                this.handlePrimaryScheduleChange(schedule.id);
            }}>
                                        ${schedule.name}
                                        ${this.renderScheduleBadges(schedule)}
                                    </a>
                                </li>
                            `)}
                        </ul>
                    </div>

                    <! -- overlay schedule currently not in use -->
                        <app-report-filter
                            .has_typeahead=${false}
                            .title=${"Select Overlay"}
                            .options=${this.availableSchedules.data.map(schedule => ({ label: schedule.name, value: schedule.id }))}
                            .multiselect=${true}
                            .class=${"custom-dropdown-btn"}
                            .value=${[this.surveyScheduleId]}
                            @optionclick=${e => this.handleScheduleChange(e.detail.value)}
                        ></app-report-filter>


                       <div class="defaultPicker-container">
    <span class="sched-label">Default SMS Time</span>
    <div id="defaultTimePicker">
        <!-- Hour Dropdown -->
        <div class="timepicker-col">
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle custom-dropdown-btn" type="button" id="hourDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    ${this.defaultHour}
                </button>
                <ul class="dropdown-menu" aria-labelledby="hourDropdown">
                    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(hour => html`
                        <li><a class="dropdown-item" href="#" @click=${(e) => { e.preventDefault(); this.saveDefaultTime('hour', hour); }}>${hour}</a></li>
                    `)}
                </ul>
            </div>
        </div>

        <!-- Colon Separator -->
        <span class="timepicker-col"><span class="bootstrap-ico-colon">:</span></span>

        <!-- Minute Dropdown -->
        <div class="timepicker-col">
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle custom-dropdown-btn" type="button" id="minuteDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    ${this.defaultMinute}
                </button>
                <ul class="dropdown-menu" aria-labelledby="minuteDropdown">
                    ${[0, 15, 30, 45].map(minute => html`
                        <li><a class="dropdown-item" href="#" @click=${(e) => { e.preventDefault(); this.saveDefaultTime('minute', minute.toString().padStart(2, '0')); }}>${minute.toString().padStart(2, '0')}</a></li>
                    `)}
                </ul>
            </div>
        </div>

        <!-- AM/PM Dropdown -->
        <div class="timepicker-col">
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle custom-dropdown-btn" type="button" id="meridiemDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                    ${this.defaultMeridiem}
                </button>
                <ul class="dropdown-menu" aria-labelledby="meridiemDropdown">
                    <li><a class="dropdown-item" href="#" @click=${(e) => { e.preventDefault(); this.saveDefaultTime('meridiem', 'AM'); }}>AM</a></li>
                    <li><a class="dropdown-item" href="#" @click=${(e) => { e.preventDefault(); this.saveDefaultTime('meridiem', 'PM'); }}>PM</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

                <div class="schedule-name-wrapper">
                <span class="material-symbols-outlined schedule-edit-icon">edit</span>

                <input type="text"
                class="schedule-name-edit"
                .value=${this.getCurrentScheduleName()}
                ?disabled=${!this.surveyScheduleId}
                @blur=${(e) => this.handleScheduleNameUpdate(this.surveyScheduleId, e.target.value)}
                @keyup=${(e) => {
                    if (e.key === 'Enter') {
                        e.target.blur();
                    }
                }}
                >
                </div>?
                                <span class="vb-tip">*Right-click event to remove it.</span>
                                            
                                <div class="input-group mb-3 dropdown-actions sched-action-btns" style="">
                    
                                
            <!-- Schedule Actions -->                   
            <button class="btn btn-secondary xxx sched-actions" type="button" id="sched-actions" @click=${this.showScheduleActionsPopover}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF" @click=${(e) => e.stopPropagation()}>
                    <path d="m670.76-14.09-15.52-76.56q-8-3-15.76-7t-15.52-8.76l-72.81 20-54.72-93.68 58.81-49.28q-.24-8-.24-16.88 0-8.88.24-16.88l-58.81-49.04 54.72-93.68 72.81 20q7.76-4.76 15.52-8.64 7.76-3.88 15.76-6.88l15.52-76.56h108.15l16 76.56q7.76 3 15.64 6.88 7.88 3.88 15.41 8.64l72.56-20 54.96 93.68-59.05 49.04q.24 8 .24 16.88 0 8.88-.24 16.88l59.05 49.28-54.96 93.68-72.56-20q-7.76 4.76-15.53 8.76-7.76 4-15.52 7l-16 76.56H670.76Zm54.2-155.48q31.52 0 53.92-22.52 22.4-22.52 22.4-54.04t-22.4-53.92q-22.4-22.41-53.92-22.41-31.53 0-54.05 22.41-22.52 22.4-22.52 53.92 0 31.52 22.52 54.04t54.05 22.52Zm-567.13 43.4q-47.11 0-80.15-33.16-33.03-33.15-33.03-80.02v-481.3q0-46.87 33.03-80.02 33.04-33.16 80.15-33.16h644.34q47.11 0 80.15 33.16 33.03 33.15 33.03 80.02v210.41q-25.44-18.19-53.73-31.29-28.29-13.1-59.45-20.86v-96.98H157.83v195.78h325.08q-39.39 43.72-61.7 99.01-22.32 55.3-22.32 118.69 0 32 6 61.74 6 29.74 16.76 57.98H157.83Z"/>
                </svg>    
            </button>



                      
                    </div>

                    <div class="btn-group view-toggle" id="toggle-container" role="group">
                        <label class="btn btn-outline-secondary active" @click=${this.handleViewChange}>
                             <span class="material-symbols-outlined">table_rows</span>
                        </label>
                        <label class="btn btn-outline-secondary" @click=${this.handleViewChange}>
                            <span class="material-symbols-outlined">view_cozy</span>
                        </label>
                    </div>

                       </div>

                    <div class="row cal-container"> 
                        <div id="calendar"></div>
                </div>
            </div>
        </div>
        `}
        </div>
        `;
    }
    _toggleSection(e) {
        const section = e.target.parentElement;
        section.classList.toggle('collapsed');
        console.log('section', section);
    }

    connectedCallback() {
        super.connectedCallback();
        // Add this line after the calendar initialization
        const dropdownElementList = this.querySelectorAll('.dropdown-toggle');
        dropdownElementList.forEach(dropdown => new bootstrap.Dropdown(dropdown));
        window.addEventListener('resize', this.setFullCalendarHeight);
        this.setFullCalendarHeight();
    }


    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('resize', this.setFullCalendarHeight);
    }



}

customElements.define("scene-settings-schedule", SceneSettingsSchedule);
