import { LitElement, html } from 'lit';
import { DateTime } from 'luxon';

export class WidgetClock extends LitElement {


    createRenderRoot() {
        return this;
    }
    constructor() {
        super();
        this.time = ''; // Initialize the reactive property
    }

    connectedCallback() {
        super.connectedCallback();
        this.updateTime(); // Initial call to set the time immediately
        this.interval = setInterval(() => this.updateTime(), 1000); // Update every second
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearInterval(this.interval); // Clear the interval if the element is removed from the DOM
    }

    updateTime() {
        const now = DateTime.now();
        const day = now.day;
        const ordinal = this.getOrdinal(day);
        this.time = now.toFormat(`cccc, LLLL d'${ordinal}' h:mm:ss a`);
        this.requestUpdate(); // Manually trigger a re-render
    }

    getOrdinal(day) {
        if (day > 3 && day < 21) return 'th'; // 11th to 13th
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }

    render() {
        return html`<h1 class="widget-clock">${this.time}</h1>`;
    }
}

customElements.define('widget-clock', WidgetClock);