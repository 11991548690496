import { html, render } from "lit"
import ApplicationState from "applicationstate";
import { Dropdown } from 'bootstrap';
import directus from "../../lib/lib-directus";
import cache from "../../lib/lib-cache";
import AppTaskAdHocModal from "../app-task-ad-hoc-modal";
import AppTaskSelfCheckModal from "../app-task-self-check-modal";
import AppDeactivatePatientModal from "../app-deactivate-patient-modal";
import AppPatientProfileModal from '../patient/app-patient-profile-modal';
import AppAvailabilityDetail from "../availability/app-availability-detail";
import AppPatientProfile from "./app-patient-profile";


/**
 * An actions menu displayed in various headers.
 * @param {object} patient
 * @fires AppPatientActions#patientupdate 
 */
export default class AppPatientActions extends HTMLElement {
    constructor() {
        super();
        this._app_name = ApplicationState.get('app.name');
        this._current_user = ApplicationState.get("app.user");
    }

    get patient() {
        return this._patient;
    }
    set patient(value) {
        this._patient = value;
        this.render();
    }

    connectedCallback() {
        document.addEventListener('keydown', this.handleKeyPress);
        this.template = () => {
            const activation_button = this.patient.status !== "inactive" ? html`
                <button 
                    class="dropdown-item" 
                    @click=${_e => this.handleDeactivateClick()}
                    style="color: var(--t-color-danger);"
                >
                    Deactivate Patient
                </button>`
                : html`
                <button  class="dropdown-item" @click=${_e => this.handleActivateClick()}>
                    Activate Patient
                </button>`

            return html`
                <div style="display: flex; align-items: center">
                    <h4
                        @click=${e => {
                    let component = new AppPatientProfile();
                    component.patient = this.patient;
                    /** @type {import("../app-drawer").DrawerOptions} */
                    let drawer_options = {
                        placement: 'end',
                        title: `${this.patient.first_name} ${this.patient.last_name}`,
                        component,
                        show_backdrop: false
                    }
                    this.dispatchEvent(
                        new CustomEvent('show_drawer',
                            {
                                bubbles: true,
                                composed: true,
                                detail: drawer_options
                            })
                    )
                }}
                        style="
                            display: inline-block;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 20px;
                            color: #131313;
                            padding: 0;
                            margin: 10px 0;
                            cursor: pointer;
                        ">
                        ${this.patient.first_name} ${this.patient.last_name}
                    </h4>
                    <span 
                        style="
                            font-size: 25px;
                            line-height: 20px;
                            cursor: pointer;
                            background-color: white;
                        "
                        class="material-symbols-outlined" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false">
                        expand_more
                    </span>
                    <ul class="dropdown-menu actions-dropdown">
                        <li>
                            <button class="dropdown-item" @click=${_e => this.handleCallPatient()}>
                                Call ${this.patient.primary_phone}
                            </button>
                        </li>
                        <li>
                            <button class="dropdown-item" @click=${_e => this.handleAddAvailability()}>
                                Add unavailable date
                            </button>
                        </li>
                        <li>
                            <button class="dropdown-item" @click=${_e => this.handleOpenProfile()}>
                                View Profile
                            </button>
                        </li>
                        ${this._app_name === "staff" ?
                    html`
                                <li>
                                    <button class="dropdown-item" @click=${_e => this.handleSelfCheckClick()}>
                                        Schedule Self Check
                                    </button>
                                </li>
                                 <li>
                                    <button class="dropdown-item" @click=${_e => this.handlePRNCheckpointClick()}>
                                        Schedule PRN Checkpoint
                                    </button>
                                </li>
                                ${["Administrator", "Survey Manager", "Survey Team", "Engagement Specialist", "Agency Admin"].includes(this._current_user.role.name) ? html`
                                    <li>
                                        ${activation_button}
                                    </li>
                                    
                                    
                                ` : ''}
                            ` : ''
                }
                    </ul>
                </div>
            `
        }
        this.render();
    }

    disconnectedCallback() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        // Replace with your desired key combination
        if (event.ctrlKey && event.altKey && event.key === 'c') {
            this.handleCallPatient();
        }
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;

        render(this.template(), this);
    }

    async handleCallPatient() {
        let softphone_container_element = document.querySelector("app-softphone-container");
        await softphone_container_element.dial(this.patient.id, this.task);
    }

    async handleAddAvailability() {
        const modal = new AppAvailabilityDetail(null, this.patient, true);
        await modal.showModal();
        await modal.onDidDismiss();
    }

    async handleOpenProfile() {
        const profile_modal = new AppPatientProfileModal();
        profile_modal.patient = this.patient;
        // profile_modal.editable = true;
        await profile_modal.showModal();
        const result = await profile_modal.onDidDismiss();
        if (result?.should_update) {
            this.patient = result.patient;
            this.updatePatient();
        }
    }

    /**
     * Activates the patient
     */
    async handleActivateClick() {
        await directus.items("patient").updateOne(this.patient.id, {
            status: "active"
        });
        await directus.items("patient_status_history").createOne({
            patient_id: this.patient.id,
            old_status: this.patient.status,
            new_status: "active",
            date_changed: new Date(),
        })
        this.patient = await cache.getPatient(this.patient.id, true);
        this.updatePatient()
    }

    /**
     * Deactivates the patient, invoking a confirmation modal that requires a reason and notes
     */
    async handleDeactivateClick() {
        const deactivate_modal = new AppDeactivatePatientModal();
        await deactivate_modal.showModal();
        const result = await deactivate_modal.onDidDismiss();
        if (result?.confirmed) {
            await directus.items("patient").updateOne(this.patient.id, {
                status: "inactive"
            });
            await directus.items("patient_status_history").createOne({
                patient_id: this.patient.id,
                old_status: this.patient.status,
                new_status: "inactive",
                reason: result.reason,
                notes: result.notes,
                date_changed: new Date(),
            })
            this.patient = await cache.getPatient(this.patient.id, true);
            this.updatePatient()
        }
    }

    async handlePRNCheckpointClick() {
        const prn_task_modal = new AppTaskAdHocModal();
        prn_task_modal.patient = this.patient;
        await prn_task_modal.showModal();
    }
    async handleSelfCheckClick() {
        const prn_task_modal = new AppTaskSelfCheckModal();
        prn_task_modal.patient = this.patient;
        await prn_task_modal.showModal();
    }
    /**
     * Sends updated patient data to the parent component.
     * @fires AppPatientActions#patientupdate
     */
    updatePatient() {
        this.dispatchEvent(
            new CustomEvent("patientupdate", {
                detail: this.patient
            })
        );
    }
}

customElements.define("app-patient-actions", AppPatientActions)
