import { html, render } from "lit";
import { ref, createRef } from "lit/directives/ref.js";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"

import directus from "shared/lib/lib-directus";
import DataDefinition from "shared/lib/lib-data-definition";
import { adddirectusClientFilter, getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";
import { dateFormatter, getISODateStringWithoutTime } from "shared/lib/lib-date";

import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";

import { noteCellFormatter } from "../util/lib-report";

window.luxon = { DateTime };

/**
 * @todo report superclass with common sort/filter functions, calls this.fetchData()
 * An encapuslated component showing 'high-risk' (level 1) alerts from the last 30 days.
 * Data matches dashboard widget <app-dashboard-high-risk-events>.
 */
export default class ReportPatientHighRiskEvents extends HTMLElement {
    static get config() {
        return {
            icon: "warning",
            report: "alert-history",
            title: "Patient Alert History",
            description: "Patient Alerts from the last 30 days.",
            component: ReportPatientHighRiskEvents
        };
    }

    constructor() {
        super();
        this.data = [];
        this.data_table = null;
        this.survey_definition = null;
        this._loading_data = true;

        // filters
        this.patient_status_filter_options = [];
        this.incident_type_filter_options = [];
        this.alert_status_filter_options = [];
        this.alert_level_filter_options = [];
        const filter_string = new URL(location.href).searchParams?.get("filters")
        this.filters = JSON.parse(filter_string) || {};

        //pagination
        this._current_page = 1;
        this._page_size = 1000000;
        this._all_pages_fetched = false;

        //sort
        this.sort_fields = [
            { column: "alert_level", direction: "asc" },
            { column: "alert_date_created", direction: "desc" },
        ];

        this.header_ref = createRef();
        this.content_ref = createRef();
    }

    get filters_expanded() {
        return this._filters_expanded;
    }

    set filters_expanded(value) {
        this._filters_expanded = value;
        this.render();
    }

    connectedCallback() {
        // This alows showing/hiding the filter dropdown through css and avoid multiple filter instances
        // const filters_breakpoint_query = window.matchMedia("(min-width: 1275px)");
        // this.filters_expanded = filters_breakpoint_query.matches
        // filters_breakpoint_query.addEventListener("change", (evt) => {
        //     this.filters_expanded = evt.matches
        //     if (!evt.matches) {
        //         this.initFilterDropdown();
        //     }
        // });
        const dt = luxon.DateTime.fromISO('2023-08-02T14:25:32.636Z');
        console.log("test", dt.toISO()) // 
        this.template = () => {
            return html`
        
                <!-- <style>
                    report-patient-high-risk-events #patient-high-risk-events-table .report-note:not(:first-child) {
                        margin-top: 12px;
                    }
                    report-patient-high-risk-events #main-content .fixed-table-border, report-patient-high-risk-events #main-content .fixed-table-container {
                        border: none;
                    }
                    report-patient-high-risk-events thead {
                        /* bootstrap-table dynamically adds inline styles for this */
                        display: none !important;
                    }
                    report-patient-high-risk-events .filter-dropdown-toggle {
                        display: flex;
                    }
                    report-patient-high-risk-events .filter-dropdown-menu {
                        padding: 8px 16px 24px 16px;
                    }
                    report-patient-high-risk-events select option:checked {
                        background: #0C61CA;
                        color: white;
                    }
                    @media (min-width: 600px) {
                        report-patient-high-risk-events thead {
                            display: table-header-group !important;
                        }
                    }
                    @media (min-width: 1275px) {
                        report-patient-high-risk-events app-report-filter {
                            margin-left: 16px;
                        }
                        report-patient-high-risk-events .report-filters {
                            justify-content: space-around;
                        }
                        report-patient-high-risk-events .filter-dropdown-toggle {
                            display: none;
                        }
                        report-patient-high-risk-events .filter-dropdown-menu {
                            padding: unset;
                            display: flex;
                        }   
                    }
                </style> -->
                
                    <div id="main-content" class="container-fluid">
                        <div id="widget_wrapper">
                            <div class="button-list">
                                <div class="filter-container" style="align-items: center;">
                                    <app-report-filter
                                        .title=${"Alert Status"}
                                        .options=${this.alert_status_filter_options}
                                        .value=${this.filters.alert_status}
                                        @optionclick=${e => this.handleFilterChange("alert_status", e)}
                                        @clear=${_e => this.handleFilterClear("alert_status")}
                                    >    
                                    </app-report-filter>
                                </div>
                                <div class="filter-container" style="align-items: center;">
                                    <app-report-filter
                                        .title=${"Patient Status"}
                                        .options=${this.patient_status_filter_options}
                                        .value=${this.filters.patient_status}
                                        @optionclick=${e => this.handleFilterChange("patient_status", e)}
                                        @clear=${_e => this.handleFilterClear("patient_status")}
                                    >    
                                    </app-report-filter>
                                </div>
                                <!-- <div class="filter-container" style="align-items: center;">
                                    <app-report-filter
                                        .title=${"Primary Clinician"}
                                        .options=${this.primary_clinician_options || []}
                                        .value=${this.filters.primary_clinician}
                                        @optionclick=${e => this.handleFilterChange("primary_clinician", e)}
                                        @clear=${_e => this.handleFilterClear("primary_clinician")}
                                    >    
                                    </app-report-filter>
                                </div> -->
                                <div class="filter-container" style="align-items: center;">
                                    <app-report-filter
                                        .is_datepicker=${true}
                                        .required=${true}
                                        .title=${"Date Reported"}
                                        .value=${this.filters.alert_date_created_range}
                                        @dateselect=${e => this.handleDateFilterChange(e)}
                                        @clear=${_e => this.handleFilterClear("alert_date_created_range")}
                                    >    
                                    </app-report-filter>
                                </div>
                                <div class="filter-container" style="align-items: center;">
                                    <app-report-filter
                                        .title=${"Alert Level"}
                                        .options=${this.alert_level_filter_options || []}
                                        .value=${this.filters.alert_level}
                                        @optionclick=${e => this.handleFilterChange("alert_level", e)}
                                        @clear=${_e => this.handleFilterClear("alert_level")}
                                    >    
                                    </app-report-filter>
                                </div>
                                <div class="filter-container" style="align-items: center;">
                                    <app-report-filter
                                        .title=${"Incident Type"}
                                        .options=${this.incident_type_filter_options || []}
                                        .value=${this.filters.alert_rule_description}
                                        @optionclick=${e => this.handleFilterChange("alert_rule_description", e)}
                                        @clear=${_e => this.handleFilterClear("alert_rule_description")}
                                    >    
                                    </app-report-filter>
                                </div>
                            </div>
                        </div>
                        <div class="mod-base" id="patient-high-risk-events-table" data-toggle="table"></div>
                    </div>
                </div>
            `
        }

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });

        this.render();
        this.init();

    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Load survey definitions, fetch report data, and update table.
     */
    async init() {
        await this.fetchFilterOptions();
        await this.fetchAlertData();
        this.initTable();
        this.setTableHeight();
    }

    /**
     * Fetches latest report data and updates the data table. 
     */
    async fetchAlertData() {
        // console.log("fetchAlertData" + JSON.stringify(this.filters));
        if (!this._page_size) {
            // still being calculated/initialized
            return;
        }

        this._loading_data = true;
        const client_id = getCurrentClientId()
        const {
            patient_status = ['active', 'inactive'],
            alert_status,
            primary_clinician,
            alert_rule_description,
            alert_date_created_range,
            alert_level
        } = this.filters

        const res = await directus.transport.get('/vbh/reports/high_risk_incidents', {
            params: {
                filters: {
                    client_ids: [client_id],
                    patient_status,
                    alert_status,
                    primary_clinician,
                    alert_rule_description,
                    alert_date_created_range,
                    alert_level,
                },
                page_size: this._page_size,
                page: this._current_page,
                sort: JSON.stringify(this.sort_fields)
            }
        });

        // Tabulator Table
        this.data = this.formatRecords(res.data);
        this.data_table?.setData(this.data);

        this._all_pages_fetched = res.data.length < this._page_size;

        this.render();
        this._loading_data = false;
    }

    async fetchFilterOptions() {
        //console.log("fetchFilterOptions" + JSON.stringify(this.filters));
        const alert_definition_request = DataDefinition.getDefinition("alert");
        const patient_definition_request = DataDefinition.getDefinition("patient");
        const alert_rule_descriptions_request = directus.items("alert_rule").readByQuery({
            filter: {
                _and: [
                    {
                        status: {
                            _eq: 'published'
                        }
                    },
                    {
                        _or: [
                            { client_id: { _null: true } },
                            { client_id: getCurrentClientId() },
                        ]
                    }
                ]
            },
            fields: ["description"],
            limit: -1,
        });

        const primary_clinician_request = directus.items("junction_directus_users_client").readByQuery({
            fields: ["*", "directus_users_id.*"],
            filter: adddirectusClientFilter({
                directus_users_id: {
                    role: {
                        name: { _in: ["Clinician", "Client"] }
                    }
                },
            })
        });

        const [
            alert_definition,
            patient_definition,
            alert_rule_descriptions,
            primary_clinician_data
        ] = await Promise.all([
            alert_definition_request,
            patient_definition_request,
            alert_rule_descriptions_request,
            primary_clinician_request
        ]);

        const unique_descriptions = new Set(alert_rule_descriptions?.data?.map(rule => rule.description))

        this.alert_level_filter_options = alert_definition.field_dictionary.level.meta.options.choices.map(
            choice => ({
                value: String(choice.value),
                label: choice.text,
            })
        );
        this.alert_status_filter_options = alert_definition.field_dictionary.status.meta.options.choices.map(
            choice => ({
                value: choice.value,
                label: choice.text,
            })
        );
        this.patient_status_filter_options = patient_definition.field_dictionary.status.meta.options.choices
            .filter(choice => choice.value === "active" || choice.value === "inactive")
            .map(
                choice => ({
                    value: choice.value,
                    label: choice.text,
                })
            );

        this.incident_type_filter_options = Array.from(unique_descriptions).map(value => ({
            value,
            label: value,
        }));

        const unique_clinician_ids = new Set();
        this.primary_clinician_options = primary_clinician_data.data
            .map(item => ({
                value: item.directus_users_id.id,
                label: `${item.directus_users_id.first_name || ''} ${item.directus_users_id.last_name || ''}`,
            }))
            .filter(option => {
                if (!unique_clinician_ids.has(option.value)) {
                    unique_clinician_ids.add(option.value);
                    return true;
                }
                return false;
            });
    }

    /**
     * Format records to display tabulator table.
     * @param {*} records 
     * @returns 
     */
    formatRecords(records) {
        //console.log("formatRecords" + JSON.stringify(records));

        return records
            .filter(record => record.current_episode_id !== null && record.last_task_id !== null)
            .map(record => ({
                patient_id: record.patient_id,
                survey_id: record.survey_id,
                patient_status: (record.patient_status?.charAt(0)?.toUpperCase() + record.patient_status?.slice(1)) || "",
                patient_first_name: record.patient_first_name,
                patient_last_name: `${record.patient_first_name || ""} ${record.patient_last_name || ""}`,
                primary_clinician_last_name: `${record.primary_clinician_first_name || ""} ${record.primary_clinician_last_name || ""}`,
                alert_level: "Level " + record.alert_level,
                alert_status: record.alert_status,
                alert_date_created: record.alert_date_created, // Use desired date format here
                alert_rule_description: record.alert_rule_description || "Manual Alert",
                notes: noteCellFormatter(record.notes) // Assuming noteCellFormatter is in scope
            }));
    }

    /**
     * Initializes an instance of TabulatorTable with the specified options. 
     */
    initTable() {

        //const dateFormatterLocal = this.dateFormatter.bind(this);
        //console.log("initTable" + JSON.stringify(this.filters));
        this.data_table = new Tabulator("#patient-high-risk-events-table", {
            data: this.data,           //load row data from array
            history: true,
            height: "100%",       //allow undo and redo actions on the table
            pagination: "local",       //paginate the data
            paginationSize: 25,         //allow 7 rows per page of data
            paginationCounter: "rows", //display count of paginated rows in footer
            layout: "fitDataStretch",
            layoutColumnsOnNewData: true, //fit columns to width of table (optional)
            movableColumns: true,      //allow column order to be changed
            initialSort: [             //set the initial sort order of the data
                { column: "alert_date_created", dir: "desc" },
            ],
            columnDefaults: {
                tooltip: true,         //show tool tips on cells
            },
            columns: [
                { title: "Alert Status", field: "alert_status" },
                { title: "Alert Level", field: "alert_level" },
                { title: "Patient Name", field: "patient_last_name" },
                { title: "Patient Status", field: "patient_status" },
                //{ title: "Primary Clinician", field: "primary_clinician_last_name" },
                {
                    title: "Date Reported",
                    field: "alert_date_created",
                    formatter: dateFormatter,
                    //sorter: "date",

                },
                { title: "Incident Type", field: "alert_rule_description" },
                { title: "Additional Notes/Comments", field: "notes", formatter: "html", headerSort: false, widthGrow: 5 },
            ]
        });

        this.data_table.on("rowClick", function (e, row) {
            let alert = row.getData();
            navigate(`patients/${alert.patient_id}/checkpoints/${alert.survey_id}`)
        });
    }

    multiSelectHeaderFilter(cell, onRendered, success, cancel, editorParams) {
        // console.log("multiSelectHeaderFilter" + JSON.stringify(this.filters));
        var values = editorParams.values;

        const filterFunc = (rowData) => {
            return values.includes(rowData[editorParams.field]);
        }

        const getSelectedValues = (multiSelect) => {
            var result = [];
            var options = multiSelect && multiSelect.options;
            var opt;

            for (var i = 0, iLen = options.length; i < iLen; i++) {
                opt = options[i];

                if (opt.selected) {
                    result.push(opt.value || opt.text);
                }
            }
            return result;
        }

        const onChange = () => {
            var editor = document.getElementById(editorParams.selector);
            values = getSelectedValues(editor);
            cell.getColumn().getTable().removeFilter(filterFunc);
            cell.getColumn().getTable().addFilter(filterFunc);
        }

        var select = document.createElement("select");
        select.multiple = "multiple";
        select.id = editorParams.selector;
        select.class = "chosen-select";
        select.style = 'width: 100%;';

        let cnt = values.length;
        for (let k = 0; k < cnt; k++) {
            select.innerHTML += "<option id='" + values[k] + "' value='" + values[k] + "' selected='selected' style='padding-top: 5px; padding-bottom: 5px;'>" + values[k] + "</option>";
        }
        cell.getColumn().getTable().addFilter(filterFunc);
        select.addEventListener('change', onChange);

        return select;

    }

    /**
     * @param {CustomEvent} e AppReportFilter#dateselect event
     */
    handleDateFilterChange(e) {
        const { start, end } = e.detail;
        this.filters["alert_date_created_range"] = `${getISODateStringWithoutTime(start)},${getISODateStringWithoutTime(end)}`;
        this.fetchAlertData();
    }

    /**
     * @param {string} filter_key one of this.filters
     * @param {CustomEvent} e AppReportFilter#optionclick event
     */
    handleFilterChange(filter_key, e) {
        if (e.detail) this.filters[filter_key] = e.detail;
        else delete this.filters[filter_key];
        this.fetchAlertData();
        //console.log(this.filters);

    }

    /**
     * handler for AppReportFilter#clear
     * @param {string} filter_key one of this.filters
     */
    handleFilterClear(filter_key) {
        delete this.filters[filter_key];
        this.fetchAlertData();
    }
    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }
}

customElements.define("report-patient-high-risk-events", ReportPatientHighRiskEvents);
