import { html, render } from "lit";
import "./app-calendar-view";

export default class AppAvailabilityView extends HTMLElement {
    constructor() {
        super();
        this.addFlag = true;
        this.type = "all";
        this.sms_id = null;

    }

    connectedCallback() {
        //console.log("patient_activities view connectedCallback", this._patient_activities);
        console.log('callback', this.addFlag);
        this.template = () => {
            return html`
               <app-calendar-view 
                    .patient=${this.patient}
                    .type=${this.type}
                    .addFlag=${this.addFlag}
                    .sms_id=${this.sms_id}
                ></app-calendar-view>
            `
        };

        Object.assign(this.style, {
            position: "relative"
        });

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-availability-view", AppAvailabilityView);
