import { html, render } from "lit";
import { navigate } from "shared/lib/lib-router";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import ApexCharts from 'apexcharts';

export default class AppDashboardHighRiskEventsChart extends HTMLElement {
    constructor() {
        super();

        this.event_categories = [
            "falls",
            "medication_changes",
            "medication_side_effects",
            "medication_refills",
            "dyspnea",
        ];

        this.report_filters = {
            event_categories: this.event_categories,
            alert_date_created_range: this.reportDateRange(30)
        };

        this.chart = null; // Initialize chart instance variable
    }

    reportDateRange = (period) => {
        const now = new Date();
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() - period);

        return `${getISODateStringWithoutTime(startDate)},${getISODateStringWithoutTime(now)}`;
    }

    set metrics(value) {
        if (this._metrics !== value) {
            this._metrics = value;
            this.updateChart();
        }
    }

    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #dashboard-high-risk {
                        height: 100%; 
                        cursor: pointer; 
                        display: flex; 
                        flex-direction: column;
                        position:relative;
                    }
                    #chart {
                        width: 110%;
                        height: auto;
                        position: absolute;
                        left: -6%;
                        bottom: -20px;
                    }
                </style>
                <div id="dashboard-high-risk">
                    <h6>ACU Risk Incidents</h6>
                    <div id="chart"></div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
        });

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
        this.updateChart();
    }

    async updateChart() {
        if (!this.metrics) return;

        const aggregated_metrics = await this.getAggregatedRiskMetrics();

        const options = {
            chart: {
                type: 'bar',
                height: 300,
                width: '100%',
                offsetY: 25,
                offsetX: -0,
                toolbar: {
                    show: false,
                },
                fontFamily: 'Quicksand',
                dropShadow: {
                    enabled: true,
                    top: 20,
                    left: 20,
                    blur: 0,
                    opacity: 0.03
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    type: 'vertical',
                    shadeIntensity: .2,
                    opacityFrom: 0.6,
                    opacityTo: 0.9,
                    //stops: [0, 100],
                    //colorStops: [
                    //    { value: 0, color: '#3e3f67' },
                    //    { value: 30, color: '#3e3f67' },
                    //    { value: 60, color: '#3e3f67' },
                    //    { value: 100, color: '#bd2520' }
                    //]
                },

                colors: ['#3e3f67'],
                //opacity: .7,
            },
            series: [{
                name: 'Incidents',
                data: this.event_categories.map(category => aggregated_metrics[category].count),
            }],
            yaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
            },
            xaxis: {
                type: 'category',
                categories: ['Falls', 'Med Changes', 'Side Effects', 'Med Refills', 'Dyspnea'],
                labels: {
                    show: true,
                    hideOverlappingLabels: false,
                    rotate: 0,
                    style: {
                        fontSize: '14px',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                }
            },
            colors: this.event_categories.map(category => aggregated_metrics[category].color),
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: false,
                    borderRadius: 3,
                    borderRadiusApplication: 'end',
                    columnWidth: '60%',
                    barHeight: '100%',
                }
            },
            dataLabels: {
                enabled: true,
            },
            legend: {
                show: false,
            },
            grid: {
                show: false,
            },
            tooltip: {
                enabled: true,
            }
        };

        if (this.chart) {
            this.chart.updateOptions(options);
        } else {
            this.chart = new ApexCharts(this.querySelector("#chart"), options);
            this.chart.render();
        }
        this.chart.addEventListener('click', (event, chartContext, config) => {
            if (config.dataPointIndex !== -1) {
                const clickedCategory = this.event_categories[config.dataPointIndex];
                this.report_filters.event_categories = [clickedCategory];
                navigate(`reports/patient/high-risk-incident-breakout?filters=${JSON.stringify(this.report_filters)}`);
            }
        });
    }

    async getAggregatedRiskMetrics() {
        const high_risk_incidents = this.metrics?.high_risk_incidents || [];
        const aggregated_metrics = {
            falls: {
                //color: "#74AAB7",
                count: high_risk_incidents.falls,
                text: "Falls"
            },
            medication_changes: {
                //color: "var(--t-color-danger)",
                count: high_risk_incidents.medication_changes,
                text: "Medication Changes"
            },
            medication_side_effects: {
                //color: "#F2AA80",
                count: high_risk_incidents.medication_side_effects,
                text: "Medication Side Effects"
            },
            medication_refills: {
                //color: "var(--t-color-success-light)",
                count: high_risk_incidents.medication_refills,
                text: "Medication Refills"
            },
            dyspnea: {
                //color: "#F2C777",
                count: high_risk_incidents.dyspnea,
                text: "Dyspnea",
            },
            total_count: (
                high_risk_incidents.falls +
                high_risk_incidents.medication_changes +
                high_risk_incidents.medication_side_effects +
                high_risk_incidents.medication_refills +
                high_risk_incidents.dyspnea
            ),
        };
        return aggregated_metrics;
    }
}

customElements.define("app-dashboard-high-risk-events-chart", AppDashboardHighRiskEventsChart);
