import ApplicationState from 'applicationstate';
import { html, render, nothing } from 'lit';
import { format } from 'date-fns';
import directus from '../../lib/lib-directus';
import { currentUserIsAdmin, getCurrentClientId } from '../../lib/lib-user';
import '../util/tagging-content-editable';

class AppPatientNote extends HTMLElement {
    set editing(value) {
        this._editing = value;
        if (value) this.style.borderLeft = "none";
        else {
            this.style.paddingLeft = "5px";
            this.style.borderLeft = "2px solid #F0F0F0";
        }
        this.render();
    }

    get editing() {
        return this._editing;
    }

    /**
     * Indicates that the component should remain in edit mode and not collapse into view mode.
     * This is used, for example, in the app-visit-activity-modal where adding a note should blank the 
     * component out and show it in the activity list
     */
    set force_edit_mode(value) {
        this._force_edit_mode = value;
    }

    get force_edit_mode() {
        return this._force_edit_mode;
    }

    set note(value) {
        this._note = value;
        this.alert_id = value.alert_id;
        this.client_id = value.client_id;
        this.patient_id = value.patient_id;
        this.survey_id = value.survey_id?.id ? value.survey_id.id : value.survey_id;
        this.question = value.question;
        this.render();
    }

    get note() {
        return this._note;
    }

    set client_id(value) {
        this._client_id = value;
    }

    get client_id() {
        return this._client_id;
    }

    set patient_id(value) {
        this._patient_id = value;
    }
    get patient_id() {
        return this._patient_id;
    }

    set survey_id(value) {
        this._survey_id = value;
    }

    get survey_id() {
        return this._survey_id;
    }

    set question(value) {
        this._question = value;
    }

    get question() {
        return this._question;
    }

    constructor() {
        super();
        this._editing = false;
    }

    connectedCallback() {
        this.template = () => html`
        <style>
            .selected_search_result {
                background-color: #77c3e0;
            }
        </style>
        ${!this.editing
                ? html`
                
                        <div
                        style="font-size: 12px; display: flex; align-items: center; position: relative;"
                        class="patient-note-header">

        ${this.note && this.note.content && this.note.content.trim() !== '' ? html`

            <span style="color:#747474; fontp-weight:400;" class="material-symbols-outlined">description</span>Note added by

            <span class="added-by">${this.note?.user_created?.first_name} ${this.note?.user_created?.last_name}</span>

            on
            <span
                class="added-by"
                title=${this.note?.date_created ? format(new Date(this.note.date_created), "MM/dd/y 'at' h:mm aaa") : nothing}>
                ${new Date(this.note.date_created).toLocaleDateString()}</span>
            <div class="note_controls" style="position: absolute; right: 0px; display: none;">
                <span
                    @click=${(e) => this.handleEditNote(e)}
                    class="material-symbols-outlined 111"
                    style='
                    margin-left: 10px;
                    cursor: pointer;
                    color: var(--t-color-primary);
                    font-size: 14px;
                    line-height: 24px;
                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                '>edit</span>
                <span
                    class="material-symbols-outlined 222"
                    @click=${(_e) => {
                            this.querySelector("#confirm-delete").style.display = "flex";
                        }}
                    style='
                        margin-left: 10px;
                        cursor: pointer;
                        color: var(--t-color-primary);
                        font-size: 14px;
                        line-height: 24px;
                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                    '>delete</span>
            </div>
        ` : html`
            <span> - No notes yet - </span>
        `}
                    </div>
                `
                : ""
            }
        <div 
            style="
                display: none;
                align-items: center;
                font-size: 12px;
            "
            id="confirm-delete" class="alert alert-danger" role="alert">
            <div>
                Are you sure you want to delete this note? You won't be able to undo this.
            </div>
            <button @click=${(_e) => {
                this.querySelector("#confirm-delete").style.display = "none";
            }} type="button" class="btn btn-sm btn-info cancel-btn">Cancel</button>
            <button style="margin-left: 10px" @click=${(e) =>
                this.handleDeleteNote(e)} type="button" class="btn btn-sm btn-danger delete-btn">Delete</button>
        </div>
        <app-tagging-contenteditable
            .editing=${this.editing}
            .mention_search_function=${this.mentionSearch}
            .tag_search_function=${this.tagSearch}
            .content=${this.note?.content}
            style="
                /*width: 100%; 
                padding: 10px;
                height: 100%; 
                display: block;
                border-radius: 8px;
                border: ${this.editing ? "1px solid var(--t-color-primary)" : "none"};
                word-wrap: break-word;*/
            " 
            class="mention-editor"> </app-tagging-contenteditable>
        
        ${this.editing
                ? html`
                      <div
                          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-top: 7px;
        ">
                          <button
                              @click=${(e) => this.handleCancelEditNote(e)}
                              class="btn btn-outline-warning btn-sm cancel-btn">
                              Cancel
                          </button>
                          <button
                              @click=${(e) => this.handleSaveNote(e)}
                              class="btn btn-outline-primary btn-sm save-btn"
                              style="margin-left: 10px;">
                              Save
                          </button>
                      </div>
                  `
                : ""
            }

        </div>
    `;
        this.addEventListener("mouseover", (_e) => this.maybeShowControls());
        this.addEventListener("mouseout", (_e) => {
            if (!this.editing) this.querySelector(".note_controls").style.display = "none";
        });
        this.render();
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }

    maybeShowControls() {
        if (this.editing) return;

        let user = ApplicationState.get("app.user");
        if (user.role.name == "Administrator" || user.id == this.note.user_created.id)
            this.querySelector(".note_controls").style.display = "block";
    }

    async mentionSearch(search) {
        const is_admin = currentUserIsAdmin()
        // if not an admin, only pull admins + users for the current client
        const filter = is_admin ? {} : {
            _or: [
                {
                    role: { name: 'Administrator' }
                },
                {
                    client_access: {
                        client_id: getCurrentClientId()
                    }
                }
            ]
        }
        let results = await directus.items("directus_users").readByQuery({
            search: search,
            fields: ["id", "first_name", "last_name"],
            sort: "last_name",
            filter,
        });
        return results.data;
    }

    async tagSearch(search) {
        let results = await directus.transport.get(`/vbh/tags/${search}`);
        return results.data;
    }

    handleCancelEditNote(e) {
        //these are needed to keep the click even from dismissing a modal
        e.preventDefault();
        e.stopImmediatePropagation();
        this.editing = false;
        this.dispatchEvent(new CustomEvent("cancel", { bubbles: true, composed: true }));
    }

    async handleSaveNote(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let editor = this.querySelector("app-tagging-contenteditable");
        let content = editor.content;
        let client_id;
        if (typeof this.client_id == 'object')
            client_id = this.client_id.id
        else
            client_id = this.client_id

        //mentions and tags are parsed on the server in a hook
        if (this.note.id)
            await directus.items('note').updateOne(this.note.id, { content })
        else
            this.note = await directus.items("note").createOne({
                alert_id: this.alert_id,
                client_id,
                patient_id: this.patient_id,
                survey_id: this.survey_id,
                question: this.question,
                content,
            });

        this.dispatchEvent(new CustomEvent("save", { bubbles: true, composed: true }));
        if (!this.force_edit_mode)
            this.editing = false;
    }

    handleEditNote(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.editing = true;
    }

    async handleDeleteNote(_note) {
        await directus.items("note").deleteOne(this.note.id);
        this.querySelector("#confirm-delete").style.display = "none";
        this.dispatchEvent(new CustomEvent("delete", { bubbles: true, composed: true }));
    }
}

customElements.define("app-patient-note", AppPatientNote);
export default AppPatientNote;
