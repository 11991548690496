import { html, render } from "lit";
import { format } from 'date-fns';
import SurveyDefinition from "../../lib/lib-survey-definition";
import { getEpisodeWeek } from "../../lib/lib-util";
import AppAlertDetail from "./app-alert-detail";
import "../app-report-filter";

class AppAlertList extends HTMLElement {
    constructor() {
        super();
        this._filter_active_alert_levels = [];
    }

    set alerts(value) {
        this._alerts = value;
        this.render();
    }

    get alerts() {
        return this._alerts;
    }

    get active_alerts() {
        return this._alerts.filter((alert) => alert.status == "active");
    }

    set filter_active_alert_levels(value) {
        this._filter_active_alert_levels = value;
        this.render();
    }

    get filter_active_alert_levels() {
        return this._filter_active_alert_levels;
    }

    get previous_alerts() {
        return this._alerts.filter((alert) => alert.status !== "active");
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    connectedCallback() {
        this.template = () => {
            const filtered_active_alerts = this.filter_active_alert_levels.length ?
                this.active_alerts.filter(alert => this.filter_active_alert_levels.includes(alert.level))
                : this.active_alerts;

            return html`
                <style>
                    #active_alert_table th {
                        font-weight: 800;
                        font-size: 10px;
                        line-height: 14px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        color: #6e6c7b;
                    }

                    #active_alert_table td {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: #201b3a;
                    }

                    #previous_alert_table th {
                        font-weight: 800;
                        font-size: 10px;
                        line-height: 14px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        color: #6e6c7b;
                    }

                    #previous_alert_table td {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: #201b3a;
                    }

                    #previous_alert_table .alert-status-label {
                        padding: 0px 8px;
                        width: fit-content;
                        text-transform: capitalize;
                        line-height: 24px;
                        border-radius: 12px;
                        color: white;
                    }

                    .alert_table_row:hover {
                        background-color: #fafafa;
                        cursor: pointer;
                    }
                    app-alert-list .alert-status {
                        display: flex;
                        justify-content: flex-end;
                    }

                    @media (min-width: 768px) {
                        app-alert-list .alert-status {
                            justify-content: center;
                        }
                    }
                </style>
                <div
                    style="
                    background: #FFFFFF;
                    box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                    border-radius: 8px;
                    padding: 10px;
                ">
                    <!-- active alerts -->
                    <div style="
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        flex-flow: wrap;
                    ">
                        <h3 style="
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 26px;
                            color: #131313;
                        ">
                            Active Alerts
                        </h3>
                        <app-report-filter
                            .title=${"Level"}
                            .options=${[
                    { label: "Level 1", value: 1 },
                    { label: "Level 2", value: 2 },
                    { label: "Level 3", value: 3 },
                ]}
                            @optionclick=${e => { this.filter_active_alert_levels = e.detail || [] }}
                            @clear=${e => { this.filter_active_alert_levels = [] }}
                            style="position: relative; bottom: 15px; margin-left: 40px;"
                        ></app-report-filter>
                        <div style="
                            display: flex; 
                            align-items: center; 
                            margin-left: auto;
                            padding: 16px 0;
                        ">
                            <span
                                class="material-symbols-outlined"
                                style=' color: #ca463d; font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;'
                            >
                                warning
                            </span>
                            <span style="
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 20px;
                                letter-spacing: 0.5px;
                                text-transform: uppercase;
                                color: #ca463d;
                                margin-left: 10px;
                            ">
                                ${this.active_alerts.length} Active Alert${this.active_alerts.length === 1 ? "" : "s"}
                            </span>
                        </div>
                    </div>
                    <table id="previous_alert_table">
                        <thead>
                            <tr>
                                <th>ALERT REASON</th>
                                <th>LEVEL</th>
                                <th>CHECKPONT</th>
                                <th>CHECKPOINT DATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${filtered_active_alerts.map(
                    (alert) => html`
                                    <tr @click=${(_e) => this.showAlert(alert)} class="alert_table_row">
                                        <td data-label="ALERT REASON">
                                            <app-alert-reason .alert=${alert}></app-alert-reason>
                                        </td>
                                        <td data-label="LEVEL">Level ${alert.level}</td>
                                        <td data-label="CHECKPONT">
                                            Week ${getEpisodeWeek(this.patient.current_episode_id, alert.date_created)}
                                        </td>
                                        <td data-label="CHECKPOINT DATE">
                                            ${format(new Date(alert.date_created), "MM/dd/y 'at' h:mm aaa")}
                                        </td>
                                    </tr>
                                `
                )}
                        </tbody>
                    </table>

                    <!-- previous alerts -->
                    <div style="
                        margin-top: 20px;
                        display: flex;
                        align-items: center;
                    ">
                        <h3 style="
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 26px;
                            color: #131313;
                        ">
                                Previous Alerts
                        </h3>
                    </div>
                    <table id="previous_alert_table">
                        <thead>
                            <tr>
                                <th>ALERT REASON</th>
                                <th>LEVEL</th>
                                <th>CHECKPONT</th>
                                <th>CHECKPOINT DATE</th>
                                <th>STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${this.previous_alerts.map(
                    (alert) => html`
                                    <tr @click=${(_e) => this.showAlert(alert)} class="alert_table_row">
                                        <td data-label="ALERT REASON">
                                            ${alert.subject}
                                        </td>
                                        <td data-label="LEVEL">Level ${alert.level}</td>
                                        <td data-label="CHECKPONT">
                                            Week ${getEpisodeWeek(this.patient.current_episode_id, alert.date_created)}
                                        </td>
                                        <td data-label="CHECKPOINT DATE">
                                            ${format(new Date(alert.date_created), "MM/dd/y 'at' h:mm aaa")}
                                        </td>
                                        <td data-label="STATUS">
                                            <div class="alert-status">
                                                ${alert.status == "expired"
                            ? html`
                                                        <div class="alert-status-label" style="background-color: var(--t-color-grey);">
                                                            Expired
                                                        </div>
                                                    `
                            : html` 
                                                        <div class="alert-status-label" style="background-color: var(--t-color-success);">
                                                            Resolved
                                                        </div>
                                                    `
                        }
                                            </div>
                                        </td>
                                    </tr>
                                `
                )}
                        </tbody>
                    </table>
                </div>
            `;
        }

        Object.assign(this.style, { display: "block" });

        this.render();
        this.init();
    }

    render() {
        if (!this.template)
            return;

        if (!this.alerts)
            return;

        if (!this.patient)
            return;

        if (!this.survey_definition)
            return;

        render(this.template(), this);
    }

    async init() {
        this.survey_definition = await SurveyDefinition.getSurveyDefinition();
        this.render();
    }

    async showAlert(alert) {
        let alert_modal = new AppAlertDetail();
        alert_modal.alert = alert;
        alert_modal.patient = this.patient;
        await alert_modal.showModal();
        let result = await alert_modal.onDidDismiss();
        if (result) this.dispatchEvent(new CustomEvent("refresh-alerts", { bubbles: true, composed: true }));
    }
}

customElements.define("app-alert-list", AppAlertList);
export default AppAlertList;
