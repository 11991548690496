import { html, render } from "lit";

import ModalBase from './util/modal-base';

export default class AppOvalModal extends ModalBase {
    constructor() {
        super();
        this._modalText = ''; // Private variable to store the modal text
    }

    // Getter for modalText
    get modalText() {
        return this._modalText;
    }

    // Setter for modalText (this will handle the re-rendering)
    set modalText(value) {
        this._modalText = value;
        this.render(); // Re-render the modal when the text changes
    }

    connectedCallback() {
        this.template = () => {
            return html`

                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <!--  <div class="modal-header flex justify-center items-center" style="justify-content: center; align-items: center; border-bottom: none;">
                            <h4 class="modal-title" style="font-weight: 600;">
                                Success
                            </h4>
                    </div> -->


                        <div class="modal-body schedule-conf-body" style="display: flex; justify-content: center; align-items: center; padding-top: 2px; padding-bottom: 2px;">
                            <!-- Render modalText -->
                            <h3 class="schedule-conf-message">${this._modalText}</h3>

                            <!-- Conditional content based on modalText -->
                           
                                    <div class="schedule-conf-btns">
                                        <button id="to-dash" class="btn-light" @click=${() => this.navigate(`/staff/dashboard/overview`)}>Go to Dashboard</button>
                                        <button id="modal-close" class="btn-light" data-bs-dismiss="modal">Continue Scheduling</button>
                                    </div>
                               
                        </div>
                        <!--  <div class="modal-footer" style="display: flex; justify-content: center; border-top: none;">
                          <button
                                type="button"
                                class="btn btn-primary"
                                @click=${(_e) => this.handleConfirmClick()}>
                                Okay
                            </button> -->
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    // Custom method for navigation
    navigate(path) {
        window.location.href = path; // Simulate navigation
    }

    /**
     * Click handler for 'Confirm' button.
     * Resolves the ModalBase promise by calling dismiss()
     */
    async handleConfirmClick() {
        this.dismiss({ confirmed: true });
    }
}

customElements.define("app-oval-modal", AppOvalModal);
