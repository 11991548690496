import { html, render } from 'lit';
import '../components/filter/app-filter';
import ApplicationState from 'applicationstate';
import { getCurrentClientId } from '../lib/lib-user';
import "../components/sms-hub/sms-lists";
import { format, parseISO } from "date-fns";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"
import directus from '../lib/lib-directus';

class SceneSMSHubOverview extends HTMLElement {
    constructor() {
        super();
        this._app_name = ApplicationState.get('app.client.filter_state');
        this.SMSes = [];
        this.creator = '';
        this.patient_id = '';
        this.selectedName = '';
        this.data_table = null;
        this.totalCount = 0;
        this.clientId = getCurrentClientId();
        this.isScheduledView = false;
        this.filters = {
            sms_date_range: this.defaultFilterDates(),
            patient_status: ['active', 'inactive'],
            communication_type: ['sms'],
            search: ''
        };

        this.historyColumns = [
            {
                title: "Patient Name",
                field: "patient_first_name",
                sorter: "string",
                headerSort: false,
                width: "20%",
                formatter: (cell) => {
                    const data = cell.getRow().getData();
                    return `${data.patient_first_name} ${data.patient_last_name}`;
                },
            },
            {
                title: "Last SMS Date",
                field: "scheduled_date",
                sorter: "datetime",
                headerSort: false,
                formatter: (cell) => {
                    const value = cell.getValue();
                    return value ? this.formatTime(value) : "";
                },
            },
            {
                title: "Episode",
                field: "episode_start",
                sorter: "date",
                headerSort: false,
                formatter: (cell) => {
                    const rowData = cell.getRow().getData();
                    const startDate = rowData.episode_start ? parseISO(rowData.episode_start) : null;
                    const endDate = rowData.episode_end ? parseISO(rowData.episode_end) : null;

                    if (!startDate || !endDate) return "";

                    const startFormatted = format(startDate, "M/dd/yyyy");
                    const endFormatted = format(endDate, "M/dd/yyyy");

                    const today = new Date();
                    if (today < startDate || today > endDate) {
                        cell.getElement().style.backgroundColor = "#ffd8b1";
                    }

                    return `${startFormatted} - ${endFormatted}`;
                }
            },
            {
                title: "Created By",
                field: "user_created",
                width: "20%",
                headerSort: false,
                formatter: (cell) => {
                    const data = cell.getRow().getData();
                    const userCreated = `${data.user_created_first_name} ${data.user_created_last_name}`.toLowerCase();
                    if (userCreated.includes('service')) {
                        return "Automated";
                    }
                    return data.user_created_first_name && data.user_created_last_name
                        ? `${data.user_created_first_name} ${data.user_created_last_name}`
                        : "Automated";
                }
            },
            {
                title: "Last Msg From",
                field: 'sms_message',
                headerSort: false,
                sorterParams: {
                    field: 'caller_type'
                },
                tooltip: true,
                cssClass: 'sms-hub-message',
                formatter: (cell) => {
                    const rowData = cell.getRow().getData();
                    const isPatient = rowData.caller_type === 'patient';
                    const backgroundColor = isPatient ? 'background-color: #96bb99b8;' : '';
                    return `<span class="material-symbols-outlined sms-hub-icon">sms</span>
                <span class="sms-hub-from"><strong style="text-transform:uppercase; ${backgroundColor}">${rowData.caller_type}</strong></span>`;
                }
            }
        ];

        this.scheduledColumns = [
            {
                title: "Patient Name",
                field: "patient_first_name",
                sorter: "string",
                headerSort: false,
                width: "20%",
                formatter: (cell) => {
                    const data = cell.getRow().getData();
                    return `${data.patient_first_name} ${data.patient_last_name}`;
                },
            },
            {
                title: "Scheduled Send Date",
                field: "scheduled_date",
                sorter: "datetime",
                headerSort: true,
                formatter: (cell) => {
                    const value = cell.getValue();
                    return value ? this.formatTime(value) : "";
                },
            },
            {
                title: "Time Until Send",
                field: "scheduled_date",
                sorter: "datetime",
                headerSort: true,
                formatter: (cell) => {
                    const scheduledDate = new Date(cell.getValue());
                    const now = new Date();
                    const diffTime = Math.abs(scheduledDate - now);
                    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

                    return `${diffDays}d ${diffHours}h`;
                }
            },
            {
                title: "Message",
                field: "sms_message",
                headerSort: false,
                tooltip: true,
                formatter: (cell) => {
                    return cell.getValue() || "";
                }
            },
            {
                title: "Created By",
                field: "user_created",
                width: "20%",
                headerSort: false,
                formatter: (cell) => {
                    const data = cell.getRow().getData();
                    const userCreated = `${data.user_created_first_name} ${data.user_created_last_name}`.toLowerCase();
                    if (userCreated.includes('service')) {
                        return "Automated";
                    }
                    return data.user_created_first_name && data.user_created_last_name
                        ? `${data.user_created_first_name} ${data.user_created_last_name}`
                        : "Automated";
                }
            }
        ];
    }

    connectedCallback() {
        this.template = () => html` 
        <div id="widget_wrapper">
            <div class="button-list">
                <div class="app-filters-search-solo input-group" tabindex="1">
                    <span class="material-symbols-outlined input-group-text">search</span>
                    <input
                        id="sms-search"
                        class="app-filters-search-input form-control"
                        type="text"
                        @input=${e => this.handleTypeaheadInput(e)}
                    >
                </div>

                <div id="total-count"></div>

                <app-report-filter
                    style="margin-left:auto;"
                    class="sms-date-range"
                    .title=${"SMS Date Range"}
                    .is_datepicker=${true}
                    .value=${this.filters.sms_date_range}
                    @dateselect=${e => this.handleDateFilterChange(e)}
                    @clear=${_e => this.handleFilterClear("sms_date_range")}
                ></app-report-filter>
            </div>
            
            <div id="sms-hub-overview">
                <div class="col-12 col-xl-7 p-0 sms-hub-table">
                    <!--
                    <ul class="nav nav-pills" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="history-tab" data-bs-toggle="pill" href="#history-content" role="pill" aria-controls="history-content" aria-selected="true" @click=${() => this.handleTabChange(false)}>History</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="scheduled-tab" data-bs-toggle="pill" href="#scheduled-content" role="pill" aria-controls="scheduled-content" aria-selected="false" @click=${() => this.handleTabChange(true)}>Scheduled</a>
                        </li>
                    </ul>
    -->

                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="history-content" role="tabpanel" aria-labelledby="history-tab">
                            <div class="row" style="padding: 0px 0 16px 0;" id="tasks-scroll-container">
                               <div id="sms-hub-table"></div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="scheduled-content" role="tabpanel" aria-labelledby="scheduled-tab">
                            <div class="row" style="padding: 0px 0 16px 0;">
                               <div id="sms-hub-table"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-xl-5 p-0 sms-hub-convo">
                    ${this.SMSes.length === 0 ? html`
                        <div class="placeholder-instructions">
                            <ul>
                                <li>Click on a patient to review their conversation.</li>
                                <li>Hover over the SMS icon to see the last message.</li>
                            </ul>
                        </div>
                    ` : html`
                        <app-smses-lists
                            .SMSes=${this.SMSes}
                            .name=${this.selectedName}
                            .creator=${this.creator}
                            .patient_id=${this.patient_id}
                        ></app-smses-lists>
                    `}
                </div>
            </div>
        </div>
        `;
        this.render();
        this.initTable();
        this.setTableHeight();
        this.testGet();
    }

    handleTabChange(isScheduled) {
        this.isScheduledView = isScheduled;
        if (this.data_table) {
            this.data_table.destroy();
        }
        // Update date range when switching views
        this.filters.sms_date_range = this.defaultFilterDates();
        this.initTable();
    }

    handleDateChange(e) {
        if (e.target.id === 'start-date') {
            this.startDate = e.target.value;
        } else if (e.target.id === 'end-date') {
            this.endDate = e.target.value;
        }
    }

    applyDateFilter() {
        this.data_table.setFilter([
            { field: "last_sms_date", type: ">=", value: this.startDate },
            { field: "last_sms_date", type: "<=", value: this.endDate }
        ]);
    }

    render() {
        render(this.template(), this);


    }


    async testGet() {

        let collection_name = "episode";
        const test = await directus.fields.readMany(collection_name);

        // this._collection_promises[collection_name] = promise;
        //console.log(this._collection_promises[collection_name]);
        console.log('xxxxxxxxxxx', test)
        return test;
    }



    initTable() {
        if (!this.clientId) {
            console.error("Client ID is not set");
            return;
        }

        const tableConfig = {
            ajaxURL: this.isScheduledView ? "/items/patient_communication" : "/vbh/sms_hub",
            ajaxConfig: {
                method: "GET",
            },
            ajaxParams: () => {
                const now = new Date().toISOString();

                if (this.isScheduledView) {
                    // Build Directus URL query params
                    const fields = [
                        'id',
                        'sms_message',
                        'scheduled_date',
                        'patient_id.first_name',
                        'patient_id.last_name',
                        'user_created.first_name',
                        'user_created.last_name'
                    ];

                    const filter = {
                        client_id: { _eq: this.clientId },
                        communication_type: { _eq: 'sms' },
                        scheduled_date: { _gt: now },
                        sms_message: { _nnull: true }
                    };

                    if (this.filters.search) {
                        filter._or = [
                            { 'patient_id.first_name': { _icontains: this.filters.search } },
                            { 'patient_id.last_name': { _icontains: this.filters.search } },
                            { sms_message: { _icontains: this.filters.search } }
                        ];
                    }

                    const sort = this.data_table ?
                        this.data_table.getSorters().map(s => `${s.dir === 'asc' ? '' : '-'}${s.field}`)[0]
                        : 'scheduled_date';

                    return {
                        fields: fields.join(','),
                        filter: JSON.stringify(filter),
                        sort,
                        limit: 50,
                        page: this.data_table ? this.data_table.getPage() : 1
                    };
                } else {
                    // Original UDF params for history view
                    const queryParams = {
                        filters: {
                            ...this.filters,
                            client_id: this.clientId,
                            scheduled_date: `[_lt]${now}`
                        },
                    };
                    if (queryParams.filters.search === '') {
                        delete queryParams.filters.search;
                    }

                    const sort = this.data_table ? this.data_table.getSorters().map(sorter => ({
                        field: sorter.field,
                        dir: sorter.dir
                    })) : [];

                    return {
                        filters: JSON.stringify(queryParams.filters),
                        sort: JSON.stringify(sort),
                        size: 50,
                        page: this.data_table ? this.data_table.getPage() : 1
                    };
                }
            },
            ajaxResponse: (url, params, response) => {
                if (response.error) {
                    console.error("Server Error:", response.error);
                    return { error: response.error };
                }

                if (this.isScheduledView) {
                    // Format Directus response
                    this.updateTotalCount(response.meta?.total_count || 0);
                    return {
                        data: response.data.map(item => ({
                            ...item,
                            patient_first_name: item.patient_id?.first_name,
                            patient_last_name: item.patient_id?.last_name,
                            user_created_first_name: item.user_created?.first_name,
                            user_created_last_name: item.user_created?.last_name
                        })),
                        last_page: Math.ceil((response.meta?.total_count || 0) / 50)
                    };
                } else {
                    // Original UDF response format
                    this.updateTotalCount(response.meta.total_count);
                    return {
                        data: response.data,
                        last_page: response.meta.last_page
                    };
                }
            },
            height: 750,
            layout: "fitDataFill",
            progressiveLoad: "scroll",
            progressiveLoadScrollMargin: 300,
            ajaxSorting: true,
            sortMode: "remote",
            filterMode: "remote",
            columns: this.isScheduledView ? this.scheduledColumns : this.historyColumns,
            initialSort: this.isScheduledView ? [
                { column: "scheduled_date", dir: "asc" }
            ] : []
        };

        this.data_table = new Tabulator("#sms-hub-table", tableConfig);

        this.data_table.on("rowClick", this.handleRowClick.bind(this));
        this.data_table.on("ajaxError", function (error) {
            console.error("Tabulator Ajax Error:", error);
        });
        this.data_table.on("dataLoaded", () => {
            this.updateTotalCount(this.totalCount);
        });
    }

    async handleRowClick(e, row) {
        const rowData = row.getData();
        const now = new Date().toISOString();
        const smses = await fetch(`/items/patient_communication?filter[client_id][_eq]=${this.clientId}&filter[patient_id][_eq]=${rowData.patient_id}&filter[communication_type][_eq]=sms&filter[scheduled_date][_lt]=${now}&fields=id,sms_message,caller_type,scheduled_date&sort=scheduled_date`)
            .then(response => response.json());

        this.selectedName = `${rowData.patient_first_name} ${rowData.patient_last_name}`;
        this.creator = `${rowData.user_created_first_name} ${rowData.user_created_last_name}`;
        this.SMSes = smses.data.filter(el => el.sms_message);
        this.patient_id = rowData.patient_id;
        this.render();
    }

    handleDateFilterChange(e) {
        if (e.detail && e.detail.start && e.detail.end) {
            const startDate = e.detail.start.toISOString();
            const endDate = e.detail.end.toISOString();
            this.filters.sms_date_range = `${startDate},${endDate}`;
            this.updateFilters();
        } else {
            console.error('Unexpected date format:', e.detail);
        }
    }

    async loadPatientVisits() {
        let result = await directus.items("visit").readByQuery({
            filter: {
                patient_id: this.visit.patient_id,
                visit_date: {
                    _between: [startOfWeek(new Date()), endOfWeek(new Date())]
                }
            },
            fields: [
                "*",
                "careteam_member_id.id",
                "careteam_member_id.first_name",
                "careteam_member_id.last_name",
            ]
        });
        this.patient_visits = result.data;
    }

    handleTypeaheadInput(e) {
        const search_term = e.target.value;
        if (search_term && search_term.length < 3) return;
        clearTimeout(this._debounce);
        this._debounce = setTimeout(() => {
            this.filters.search = search_term;
            this.updateFilters();
        }, 500);
    }

    handleFilterClear(filter_key) {
        delete this.filters[filter_key];
        this.updateFilters();
    }

    handleFilterChange(key, e) {
        this.filters[key] = e.detail;
        this.updateFilters();
    }

    updateFilters() {
        if (this.data_table) {
            this.data_table.setData();
        }
    }

    defaultFilterDates() {
        const now = new Date();

        if (this.isScheduledView) {
            // For scheduled view: today to 1 year from now
            const endDate = new Date();
            endDate.setFullYear(endDate.getFullYear() + 1);
            return `${now.toISOString()},${endDate.toISOString()}`;
        } else {
            // For history view: 30 days ago to today
            const startDate = new Date();
            startDate.setDate(now.getDate() - 30);
            return `${startDate.toISOString()},${now.toISOString()}`;
        }
    }

    updateTotalCount(count) {
        const totalCountElement = document.getElementById("total-count");
        if (totalCountElement) {
            totalCountElement.innerHTML = `<span class="total-count-label">Total Records: </span><h5>${count}</h5>`;
        }
        this.setTableHeight();
    }

    setTableHeight() {
        console.log('Setting table height');
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }

    formatTime(time) {
        try {
            const localDate = new Date(time + 'Z'); // Ensure UTC format
            return format(localDate, "M/dd/yyyy h:mm a");
        } catch (error) {
            return time;
        }
    }
}

customElements.define('scene-sms-hub-overview', SceneSMSHubOverview);
export default SceneSMSHubOverview;
