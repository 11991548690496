import { LitElement, html, css } from "lit";

import SceneSMSHubOverview from "./scene-sms-hub-overview";

import "../components/app-page-header";

/**
 * SceneSMSHub component
 * 
 * This component represents the SMS Communication Hub scene.
 * It uses LitElement for efficient rendering and updates.
 * Note: This component renders to the light DOM instead of using Shadow DOM.
 * This allows for easier style integration with the rest of the application
 * and enables direct access to the component's DOM from outside.
 */
export default class SceneSMSHub extends LitElement {
    static properties = {
        links: { type: Array },
        page: { type: String, reflect: true },
        error_message: { type: String },
        component: { type: Object },
        _location: { type: Object, state: true }
    };

    static styles = css`
        .scene-sms-hub .alert-danger {
            color: red;
            background-color: #ffeeee;
            padding: 10px;
            margin: 10px 0;
            border: 1px solid red;
            border-radius: 4px;
        }

        .scene-sms-hub .nav-pills {
            margin-bottom: 1rem;
            border-bottom: 1px solid #dee2e6;
            padding-bottom: 0.5rem;
        }

        .scene-sms-hub .nav-pills .nav-link {
            color: #495057;
            background: none;
            border: none;
            padding: 0.5rem 1rem;
            margin-right: 0.5rem;
            border-radius: 0.25rem;
        }

        .scene-sms-hub .nav-pills .nav-link.active {
            color: #fff;
            background-color: #007bff;
        }
    `;

    constructor() {
        super();
        this.links = [];
        this.page = "sms-hub";
        this.error_message = "";
        this.component = new SceneSMSHubOverview();
        this._location = null;
    }

    /**
     * Overrides the default render root to render into the light DOM
     * This allows for easier integration with external styles and scripts
     * @returns {Element} The element to render into
     */
    createRenderRoot() {
        return this;
    }

    /**
     * Custom setter for the location property
     * Updates the component and page based on the new location
     * @param {Object} value - The new location value
     */
    set location(value) {
        const oldValue = this._location;
        this._location = value;
        this.requestUpdate('location', oldValue);
        if (!oldValue || oldValue.path !== value.path) {
            for (const link of this.links) {
                if (value.route.path === "/" + link.url) {
                    this.component = new link.component();
                    this.page = link.page;
                    break;
                }
            }
        }
    }

    render() {
        return html`
            <style>${SceneSMSHub.styles}</style>
            <div class="scene-sms-hub">
                <app-page-header
                    style="margin: 0 32px 20px 32px;"
                    .icon=${"sms"}
                    .title=${"SMS Communication Hub"}
                    .links=${this.links}
                    .page=${this.page}
                ></app-page-header>
                ${this.error_message
                ? html`<div class="alert-danger">${this.error_message}</div>`
                : ''}
                <div>
                    ${this.component}
                </div>
            </div>
        `;
    }

    /**
     * Lifecycle method called after the component updates
     * @param {Map} changedProperties - Map of changed properties
     */
    updated(changedProperties) {
        super.updated(changedProperties);
        if (changedProperties.has('component')) {
            this.updateComplete.then(() => {
                // Perform any necessary actions after the component has been updated and rendered
                console.log('Component updated:', this.component);
            });
        }
    }
}

customElements.define("scene-sms-hub", SceneSMSHub);
