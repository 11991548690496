import { html, render } from 'lit';
import { format } from 'date-fns';
import { repeat } from 'lit/directives/repeat.js';
import { navigate } from "../../lib/lib-router";
import SurveyDefinition from '../../lib/lib-survey-definition';
import FormsDataDictionary from "../../lib/lib-forms-data-dictionary";
import directus from '../../lib/lib-directus';
import ModalBase from '../util/modal-base';
import ConfirmModal from "../app-confirmation-modal";
import ApplicationState from 'applicationstate';
import '../patient/app-patient-note';
import './app-alert-reason';
import { getEpisodeWeek, getSupplementalResponseText } from '../../lib/lib-util';
import { Collapse } from 'bootstrap';
import * as bootstrap from "bootstrap";


class AppAlertDetail extends ModalBase {
    constructor() {
        super();
        this._supplemental_questions = [];
        this.alert_views = [];
    }

    set alert(value) {
        this._alert = value;
        this.init();
    }

    get alert() {
        return this._alert;
    }

    connectedCallback() {

        this.template = () => {
            const supplemental_questions = this._supplemental_questions.filter(question =>
                this.hasBeenAsked(this.alert.survey_id.submission, question.field)
            );
            const has_supplemental_questions = !!supplemental_questions?.length;

            return html`
                <style>
                    #alert_detail_table th {
                        font-weight: 800;
                        font-size: 10px;
                        line-height: 14px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        color: #6e6c7b;
                    }
                    app-alert-detail .modal-title:hover span, app-alert-detail .modal-subtitle:hover span {
                        /*text-decoration: underline;*/
                        cursor: pointer;
                    }
                    app-alert-detail .modal-title:hover:after {
                        font-weight: normal;
                        cursor: pointer;
                        font-family: 'Material Symbols Outlined';
                        content: "dataset_linked";
                        font-feature-settings: 'liga';
                        -webkit-font-feature-settings: 'liga';
                        position: absolute;
                        font-size: 22px;
                        padding: 3px 8px;
                        color: var(--t-color-primary-darkened);
                    }
                    app-alert-detail .modal-subtitle:hover:after {
                        font-weight: normal;
                        cursor: pointer;
                        font-family: 'Material Symbols Outlined';
                        content: "account_circle";
                        font-variation-settings: "FILL" 1;
                        font-feature-settings: 'liga';
                        -webkit-font-feature-settings: 'liga';
                        position: absolute;
                        font-size: 22px;
                        padding: 2px 8px;
                        color: #353658;
                    }
                    app-alert-detail .modal-subtitle:after {
                        font-weight: normal;
                        cursor: pointer;
                        font-family: 'Material Symbols Outlined';
                        content: "account_circle";
                        font-variation-settings: "FILL" 1;
                        font-feature-settings: 'liga';
                        -webkit-font-feature-settings: 'liga';
                        position: absolute;
                        font-size: 22px;
                        padding: 2px 8px;
                        color: #fff;
                        transition: color 0.25s ease-in-out;
                    }
                    app-alert-detail .supplemental-table {
                        border: none;
                        margin: 0 0 0 8px;
                        font-size: 12px;
                        margin-left: 4px;
                        width: calc(100% - 4px);
                    }
                    app-alert-detail .supplemental-table tr {
                        border: 0;
                        text-align: left;
                    }
                    app-alert-detail .supplemental-table td {
                        border: 0;
                        text-align: left;
                        padding-left: 0;
                        display: table-cell;
                    }
                    app-alert-detail .supplemental-answer td:first-of-type {
                        padding-left: 8px;
                        /*width: 60%;*/
                    }
                    app-alert-detail .supplemental-answer td:last-of-type {
                        padding-left: 20px;
                        /*width: 40%;*/
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div>

                               <!-- <h4 
                                    class="modal-title" 
                                    style="font-weight: 700; height: 40px;"
                                    data-bs-dismiss="modal" 
                                    @click=${_e => navigate(`patients/${this.alert.patient_id.id || this.alert.patient_id}/checkpoints/${this.alert.survey_id.id || this.alert.survey_id}`)}
                                >
                                    <span>Level ${this.alert.level} Alert Reported</span>
                                </h4> -->

                                <h5 
                                    class="modal-subtitle" 
                                    data-bs-toggle="tooltip" data-bs-placement="left" title="View Patient Profile"
                                    style="font-size: 22px; margin: 4px 0 0 0;"
                                    data-bs-dismiss="modal" 
                                    @click=${_e => navigate(`patients/${this.alert.patient_id.id || this.alert.patient_id}/checkpoints`)}
                                >
                                    <span>${this.alert.patient_id.first_name} ${this.alert.patient_id.last_name}</span>
                                </h5>
                            </div>
                            <button 
                                style="align-self: start;"
                                type="button" 
                                class="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body alert level${this.alert.level}">
                            <div class="alert-reason level${this.alert.level}"
                                data-bs-toggle="tooltip" data-bs-placement="left" title="View Checkpoint Details"
                                 data-bs-dismiss="modal" 
                                style="
                                    /*border: 1px solid #ca463d;*/
                                    box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                                    border-radius: 8px;
                                    padding: 10px;
                                    
                                "
                                @click=${_e => navigate(`patients/${this.alert.patient_id.id || this.alert.patient_id}/checkpoints/${this.alert.survey_id.id || this.alert.survey_id}`)}
                                id="alert_details">
                                <app-alert-reason
                                    .alert=${this.alert}
                                    style="
                                        font-weight: 700;
                                        font-size: 20px;
                                        line-height: 26px;
                                        display: block;
                                        width: fit-content;
                                    "
                                ></app-alert-reason>
                                ${this.renderAlertViews()}
                                <table id="alert_detail_table">
                                    <thead>
                                        <tr>
                                            <th>CHECKPONT</th>
                                            <th>REPORTED TIME OF DAY</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="CHECKPONT">
                                                Week
                                                ${getEpisodeWeek(this.alert.survey_id.episode_id, this.alert.date_created)}
                                            </td>
                                            <td data-label="REPORTED TIME OF DAY">
                                                ${format(new Date(this.alert.date_created), "MM/dd/y 'at' h:mm aaa")}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p style="padding-left: 4px; font-weight: 600; margin: 24px 0 12px;">
                                ${this.alert.message}
                            </p>
                            ${has_supplemental_questions ? html`
                                <table class="table table-responsive supplemental-table">
                                    <tbody>
                                        <tr>
                                            <td colspan="3" style="font-weight: 700;">Supplemental Questions:</td>
                                        </tr>
                                        ${supplemental_questions.map(question => html`
                                                <tr class="supplemental-answer">
                                                    <td>
                                                        <span style="font-weight: 700; margin-right: 4px;">
                                                            &bull;
                                                        </span>
                                                        ${question.label}
                                                    </td>
                                                    <td>
                                                        ${getSupplementalResponseText(this.alert.survey_id.submission, question)}
                                                    </td>
                                                </tr>
                                            `)
                    }
                                    </tbody>
                                </table>
                            ` : null}
                            <div style="padding-left: 4px; margin-top: 15px; display: flex; flex-direction: row; align-items: center">
                                <h3
                                    style="
                                    color: #201B3A; 
                                    text-decoration: none; 
                                    font-size: 14px; 
                                    margin: 0px;
                                    font-weight: 700;">
                                    Notes
                                </h3>
                                <span
                                    @click=${(_e) => this.handleAddNote()}
                                    class="material-symbols-outlined"
                                    style='
                                        margin-left: 15px;
                                        cursor: pointer;
                                        color: var(--t-color-primary);
                                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                    '>add_circle</span>
                            </div>
                            <div class="collapse hide" id="add_note">
                                <app-patient-note
                                    id="new_note"
                                    @save=${(e) => this.handleSaveNewNote(e.detail)}
                                    @cancel=${(_e) => this.handleCancelAddNote()}
                                    .editing=${true}
                                    .note=${{
                    alert_id: this.alert?.id,
                    client_id: this.alert?.client_id,
                    patient_id: this.alert?.patient_id,
                    survey_id: this.alert?.survey_id,
                    question: this.alert?.question,
                }}
                                    style="display: block; width: 100%;"></app-patient-note>
                            </div>
                            ${this.notes?.length
                    ? html`
                                    <div id="alert_notes">
                                        <app-patient-note
                                            id="first_note"
                                            @delete=${(_e) => this.handleDeleteNote(this.notes[0])}
                                            .note=${this.notes[0]}
                                            style="display: block;"></app-patient-note>
                                        ${this.notes?.length > 1
                            ? html`
                                                    <div
                                                        style="
                                                            color: var(--t-color-primary); 
                                                            text-decoration: none; 
                                                            font-size: 12px; 
                                                            font-weight: 700; 
                                                            display: flex;
                                                            align-items: center;
                                                            line-height: 24px;
                                                            cursor: pointer;"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#more_notes"
                                                        aria-expanded="false"
                                                        aria-controls="more_alert_notes">
                                                        View ${this.notes?.length - 1 || 0} more
                                                        <span
                                                            class="material-symbols-outlined"
                                                            style='
                                                                margin-left: 10px;
                                                                color: var(--t-color-primary);
                                                                font-size: 14px;
                                                                line-height: 24px;
                                                                font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                                            '>expand_more</span
                                                        >
                                                    </div>
                                                    <div class="collapse" id="more_notes">
                                                        ${repeat(
                                this.notes.slice(1),
                                (note) => note.id,
                                (note) => html`
                                                                <app-patient-note
                                                                    style="display: block; margin-top: 5px;"
                                                                    @delete=${(_e) => this.handleDeleteNote(note)}
                                                                    .note=${note}></app-patient-note>
                                                            `
                            )}
                                                    </div>
                                                `
                            : ""}
                                    </div>
                                `
                    : ""}
                        </div>
                        <div class="modal-footer">
                            ${this.renderFooterButton()}
                        </div>
                    </div>
                </div>
            `;
        }

        this.render();
    }

    render() {
        if (!this.template)
            return;
        if (!this.alert)
            return;

        render(this.template(), this);
        this.initializeTooltips();
    }

    renderAlertViews() {
        // insert resolved record in correct order
        const history_items = this.alert_views.map(alert_view => ({
            action_text: "viewed",
            date: new Date(alert_view.date_created),
            user_name: `${alert_view.user_created.first_name} ${alert_view.user_created.last_name}`
        })) || [];
        if (this.alert.status === "resolved" && this.alert.resolved_date) {
            history_items.push({
                action_text: "resolved",
                date: new Date(this.alert.resolved_date),
                user_name: `${this.alert.resolved_user_id.first_name} ${this.alert.resolved_user_id.last_name}`
            });
            history_items.sort((a, b) => b.date - a.date);
        }

        // group history events by date
        const history_by_date = new Map();
        for (let history_item of history_items) {
            const date_string = history_item.date.toLocaleDateString();
            const date_items = history_by_date.get(date_string) || [];
            date_items.push(history_item);
            history_by_date.set(date_string, date_items);
        }
        const expanded_html = [];
        history_by_date.forEach((items, date_string) => {
            const date_items = items.length === 1 ? html`
                <div style="margin-top: 4px;">
                    <span style="font-weight: 700;">${date_string}</span> at ${format(items[0].date, "h:mm aaa")} ${items[0].action_text} by ${items[0].user_name}
                </div>
            ` : html`
                <div style="margin-top: 4px;">
                    <span style="font-weight: 700;">${date_string}</span>
                </div>
                ${items.map(item => html`
                    <div style="margin-left: 16px;">
                        ${format(item.date, "h:mm aaa")} ${item.action_text} by ${item.user_name}
                    </div>
                `)}
            `;
            expanded_html.push(date_items);
        });

        if (history_items?.length) {
            const last_item = history_items[0];
            return html`
                <div
                    style="
                        font-weight: 600;
                        color: #201B3A;
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                    ">
                    ${last_item.action_text === "viewed" ? "Last viewed" : "Resolved"} on ${last_item.date.toLocaleDateString()} by ${last_item.user_name}
                    <span 
                        role="button" 
                        class="material-symbols-outlined"
                        style="margin-left: 2px;"
                        @click=${_e => { this.history_expanded = !this.history_expanded; this.render(); }}
                    >
                        ${this.history_expanded ? "expand_less" : "expand_more"}
                    </span>
                </div>
                <div style="
                    margin: 0 0 16px 16px; 
                    font-weight: 500; 
                    font-size: 14px;
                    max-height: ${this.history_expanded ? "300px" : "0"};
                    overflow: ${this.history_expanded ? "auto" : "hidden"};
                    transition: max-height 0.25s ease-in-out;
                    
                ">
                    ${expanded_html}
                </div>
            `;
        }
    }

    renderFooterButton() {
        switch (this.alert.status) {
            case "resolved":
                return html`
                    <div style="cursor: not-allowed;">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            style="
                                pointer-events: none; 
                                background-color: var(--t-color-success); 
                                border-color: var(--t-color-success);
                                opacity: 0.6;
                            "
                            aria-disabled="true"
                        >
                            Resolved
                        </button>
                    </div>
                `
            case "expired":
                return html`
                    <div style="cursor: not-allowed;">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            style="
                                pointer-events: none;
                                background-color: var(--t-color-grey); 
                                border-color: var(--t-color-grey);
                                opacity: 0.6;
                            "
                            aria-disabled="true"
                        >
                            Expired
                        </button>
                    </div>
                `
            case "active":
            default:
                return html`
                    <button
                        type="button"
                        class="btn btn-danger"
                        style="background-color: var(--t-color-danger); border-color: var(--t-color-danger);"
                        @click=${(_e) => this.handleResolveClick()}
                    >
                        Resolve
                    </button>
                `
        }
    }

    async init() {
        let user = ApplicationState.get("app.user");
        let survey_id;
        if (typeof this.alert.survey_id == "string") survey_id = this.alert.survey_id;
        else survey_id = this.alert.survey_id.id;
        this.loadFormSupplementalQuestions();
        //track the view
        try {
            await directus.items("alert_view").createOne({
                alert_id: this.alert.id,
            });

            await directus
                .items("alert")
                .updateOne(this.alert.id, { last_view_user_id: user.id, last_view_date: new Date() });
        }
        catch (err) {
            console.error(err);
        }
        // load other data
        const [
            survey_definition,
            _notes,
            _alert_views
        ] = await Promise.all([
            SurveyDefinition.getSurveyDefinition(survey_id),
            this.loadNotes(),
            this.loadAlertViews(),
        ])
        this.survey_definition = survey_definition;
        this.render();
    }

    async loadAlertViews() {
        const result = await directus.items("alert_view").readByQuery({
            fields: ["*", "user_created.id", "user_created.first_name", "user_created.last_name"],
            filter: {
                alert_id: this.alert.id,
                user_created: {
                    role: {
                        name: { _nin: ["Administrator", "Survey Team", "Survey Manager", "Agency Admin", "Engagement Specialist"] }
                    }
                }
            },
            sort: "-date_created"
        });
        this.alert_views = result.data || [];
    }

    /**
     * Using the checkpoint's form_design_id, 
     * loads a map of parent field names/keys to child fields/questions.
     */
    async loadFormSupplementalQuestions() {
        const form_design_id = this.alert?.survey_id?.survey_schedule_item_id?.form_design_id;
        const all_supplemental_questions = this.alert.question && form_design_id ?
            await FormsDataDictionary.loadFormSupplementalQuestions(form_design_id)
            : {}
        this._supplemental_questions = all_supplemental_questions[this.alert.question] || [];
        this.render();
    }

    async loadNotes() {
        let patient_id;
        //determine if the patient_id is hydrated
        if (typeof this.alert.patient_id == "string") patient_id = this.alert.patient_id;
        else patient_id = this.alert.patient_id.id;

        let survey_id;
        if (typeof this.alert.survey_id == "string") survey_id = this.alert.survey_id;
        else survey_id = this.alert.survey_id.id;

        const filter = this.alert.question ? {
            patient_id,
            survey_id,
            question: this.alert.question,
        } : {
            alert_id: this.alert.id
        };

        //get the notes for the question
        let result = await directus.items("note").readByQuery({
            fields: ["*", "user_created.id", "user_created.first_name", "user_created.last_name"],
            sort: ["-date_created"],
            filter,
        });
        this.notes = result.data || [];
    }

    async handleAddNote() {
        let collapser = Collapse.getOrCreateInstance("#add_note");
        let new_note = this.querySelector("#new_note");
        let patient_id;

        //check to see if the patient is hydrated
        if (typeof this.alert.patient_id == "string") patient_id = this.alert.patient_id;
        else patient_id = this.alert.patient_id.id;

        new_note.editing = true;
        new_note.note = {
            alert_id: this.alert.id,
            client_id: this.alert.client_id,
            patient_id: patient_id,
            survey_id: this.alert.survey_id,
            question: this.alert.question,
            content: " ",
        };
        collapser.show();
    }

    async handleDeleteNote(_note) {
        await this.loadNotes();
        this.render();
    }

    async handleSaveNewNote(_content) {
        let first_note = this.querySelector("#first_note");
        if (first_note) first_note.style.display = "block";

        //clear out the new note
        let new_note = this.querySelector("#new_note");
        new_note.editing = true;

        let collapser = Collapse.getOrCreateInstance("#add_note");
        collapser.hide();
        await this.loadNotes();
        this.render();
    }

    async handleCancelAddNote() {
        let collapser = Collapse.getOrCreateInstance("#add_note");
        collapser.hide();
    }
    initializeTooltips() {
        Array.from(this.querySelectorAll('[data-bs-toggle="tooltip"]'))
            .forEach(tooltipTriggerEl => {
                if (!bootstrap.Tooltip.getInstance(tooltipTriggerEl)) {
                    new bootstrap.Tooltip(tooltipTriggerEl, {
                        placement: 'left'
                    });
                }
            });
    }


    async handleResolveClick() {
        // Hide the modal without triggering dismiss promise
        this.style.display = "none";
        document.querySelector('.modal-backdrop').style.display = "none"

        const confirm_modal = new ConfirmModal();
        confirm_modal.modal_text = "Are you sure you want to resolve this alert?";
        await confirm_modal.showModal();

        const result = await confirm_modal.onDidDismiss();
        if (result?.confirmed) {
            const user = ApplicationState.get("app.user");
            await directus.items("alert").updateOne(this.alert.id, {
                status: "resolved",
                resolved_user_id: user.id,
                resolved_date: new Date()

            });
            this.dismiss("resolved");
        }
        else this.dismiss();
    }

    /**
     * @param {object} checkpoint_submission hh_patient_reported_measures.submission json
     * @param {string} field_key name / key of field to check
     * @returns {boolean}
     */
    hasBeenAsked(checkpoint_submission, field_key) {
        const value = checkpoint_submission[field_key]
        if (value !== null && value !== undefined) {
            return true;
        }
        return false;
    }
}

customElements.define("app-alert-detail", AppAlertDetail);
export default AppAlertDetail;
