import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
//import BootstrapTable from 'bootstrap-table/dist/bootstrap-table';
import DataDefinition from "shared/lib/lib-data-definition";
import { Dropdown } from 'bootstrap';
import { ref, createRef } from "lit/directives/ref.js";
import { getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";
import { noteCellFormatter } from "../util/lib-report";
import { format, parseISO, differenceInDays } from 'date-fns';
import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";


export default class ReportPatientEpisodes extends HTMLElement {
    static get config() {
        return {
            icon: "personal_injury",
            report: "episodes",
            title: "Patient Episodes",
            description: "All Patient Episodes.",
            component: ReportPatientEpisodes
        };
    }
    get status_filter() {
        return this._status_filter;
    }

    set status_filter(value) {
        this._status_filter = value;
    }
    set filter_state(value) {
        this._filter_state = value;
    }
    /** @type {import('shared/components/filter/app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;

    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    get selected_columns() {
        return this._selected_columns;
    }

    set table_columns(value) {
        this._table_columns = value;
    }

    get table_columns() {
        return this._table_columns;
    }
    constructor() {
        super();
        //this.data = [];
        //this.data_table = null;
        //this.survey_definition = null;
        //this._loading_data = true;
        this._options = {
            ajaxURL: "/items/patient",
            ajaxParams: {},
            sortMode: "remote",
            filterMode: "remote",
            //pagination: true,
            //paginationMode: "remote",
            //paginationSize: 25,
            paginationCounter: "rows",
            layout: "fitDataFill",
            progressiveLoad: "scroll",
            progressiveLoadScrollMargin: 300,
            responsiveLayout: "collapse",
            //responsiveLayoutCollapseStartOpen: this.toggle,
            alignEmptyValues: "bottom",
            initialSort: [{ column: "current_episode_start", dir: "desc" },],
            columnDefaults: {
                tooltip: false,
            },
            checkbox: false
        };
        this._selected_columns = [
            "status",
            "status_reason",
            "current_soc",
            "first_name",
            "last_name",
            "mrn",
            "primary_diagnosis",
            "current_episode_id.start_date",
            "current_episode_id.end_date",
            "episodes.start_date",
            "episodes.end_date",
            "current_episode_start",
            "current_episode_end",
            "current_soc",
            "primary_payer",
            "engagement_specialist.last_name",
            "internal_memo",
            "tasks.scheduled_date",
            "client_id.name",
            //"iso_start",
            "ext_referral",
            "referral_source"
        ];
        this._table_columns = [


            {
                formatter: "responsiveCollapse",
                field: "responiveIcon",
                headerSort: false,
                titleFormatter: function (cell) {

                    var isExpanded = false;

                    // Create a container for the toggle button
                    var container = document.createElement("div");
                    container.className = "tabulator-responsive-collapse-toggle-header";

                    // Add SVG for 'expand all' and 'collapse all'
                    container.innerHTML = `<svg class="tabulator-responsive-collapse-toggle-open" viewBox="0 0 24 24">
                                             <line x1="7" y1="12" x2="17" y2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
                                             <line y1="7" x1="12" y2="17" x2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
                                          </svg>
                                          <svg class="tabulator-responsive-collapse-toggle-close" viewBox="0 0 24 24" style="display: none;">
                                             <line x1="7" y1="12" x2="17" y2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
                                          </svg>`;

                    // Event listener for the toggle
                    container.addEventListener("click", function () {
                        var table = cell.getTable();
                        table.getRows().forEach(function (row) {
                            var rowToggle = row.getElement().querySelector('.tabulator-responsive-collapse-toggle');
                            var rowContent = row.getElement().querySelector('.tabulator-responsive-collapse');

                            if (rowToggle && rowContent) {
                                if (isExpanded) {
                                    rowToggle.classList.remove('open');
                                    rowContent.style.display = 'none'; // Collapse the content
                                } else {
                                    rowToggle.classList.add('open');
                                    rowContent.style.display = ''; // Expand the content
                                }
                            }
                        });

                        container.querySelector('.tabulator-responsive-collapse-toggle-open').style.display = isExpanded ? '' : 'none';
                        container.querySelector('.tabulator-responsive-collapse-toggle-close').style.display = isExpanded ? 'none' : '';


                        isExpanded = !isExpanded;
                    });

                    return container;
                }
            },
            {
                title: "Status",
                fields: ["status"],
                minWidth: 290,
                field: "statuses",
                sorter: "string",
                sorterParams: {
                    field: "status_reason"
                },

                formatter: function (cell, formatterParams) {
                    let rowData = cell.getRow().getData();

                    let status = rowData.status ? '<strong>' + rowData.status.replace(/_/g, " ").replace("import ", "") + '</strong>' : '';
                    let statusReason = rowData.status_reason ? ' (' + rowData.status_reason.replace(/_/g, " ") + ')' : '';

                    return status + statusReason;
                    //return status;
                },

            },
            {
                title: "Previous Status",
                field: "status_reason",
                hozAlign: "center",
                formatter: function (cell, formatterParams) {
                    const value = cell.getValue();

                    switch (value) {
                        case "successfully_completed":
                            return "Complete";
                        case "requested_opt_out":
                            let cellElement = cell.getElement();
                            cellElement.style.backgroundColor = "#f7dfe0";
                            cellElement.style.fontWeight = "bold";
                            return "Opt-out";
                        case "client_removed":
                            return "Removed";
                        default:
                            return value;
                    }
                },
                sorter: "string"
            },


            {
                name: "Agency",
                fields: ["client_id.name"],
                field: "client_id.name"
            },
            {
                name: "Patient Name",
                fields: ["last_name", "first_name"],
                field: "name",
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getRow().getData();
                    const lastName = rowData.last_name;
                    const firstName = rowData.first_name;
                    const statusReason = rowData.status_reason;

                    let formattedName = "";

                    if (statusReason === "requested_opt_out") {
                        formattedName += '<span class="small-asterix">*</span> ';
                        cell.getElement().style.fontWeight = "bold";
                    }

                    formattedName += `${lastName}, ${firstName}`;

                    return formattedName;
                },
                sorter: "string",
                responsive: 0,
                sorterParams: {
                    field: "last_name"
                },
                minWidth: 180,
            },
            {
                name: "MRN",
                fields: ["mrn"],
                field: "mrn",
                minWidth: 50,
            },
            {
                title: "Primary Diagnosis",
                name: "PDX",
                minWidth: 280,
                tooltip: true,
                fields: ["primary_diagnosis"],
                field: "primary_diagnosis",
                formatter: function (cell) {
                    const value = cell.getValue();
                    return value ? value : "";
                }

            },
            {
                title: "SOC Date",
                field: "current_soc",
                formatter: function (cell, formatterParams) {
                    const value = cell.getValue();
                    if (!value) {
                        return "";
                    }
                    const rowData = cell.getRow().getData();
                    const currentEpisodeStart = rowData.current_episode_start;

                    let formattedDate = "";

                    if (currentEpisodeStart) {
                        const socDate = parseISO(value);
                        const episodeStartDate = parseISO(currentEpisodeStart);
                        const daysDiff = differenceInDays(episodeStartDate, socDate);

                        if (daysDiff >= 3) {
                            formattedDate += '<span class="small-asterix">*</span> ';
                            cell.getElement().style.fontWeight = "bold";
                        }
                    }

                    formattedDate += format(parseISO(value), "MM/dd/yyyy");

                    return formattedDate;
                },

                sorter: "date",
                sorterParams: {
                    alignEmptyValues: "bottom"
                }
            },




            {
                title: "Current Episode",
                field: "current_episode_start",
                minWidth: 265,
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getRow().getData();
                    const episodes = rowData.episodes;
                    const summary = document.createElement("div");

                    // Conditionally create and append expand button if episodes exist
                    if (Array.isArray(episodes) && episodes.length > 0) {
                        const expandBtn = document.createElement("button");
                        expandBtn.textContent = "+";
                        summary.appendChild(expandBtn);

                        // Event listener for expand/collapse
                        expandBtn.addEventListener("click", function () {
                            if (expandBtn.textContent === "+") {
                                expandBtn.textContent = "-";
                                // Render full list of episodes
                                const episodesList = document.createElement("ul");
                                episodes.forEach(episode => {
                                    const listItem = document.createElement("li");
                                    const startDate = episode.start_date ? format(new Date(episode.start_date), "MM/dd/yyyy") : "No Current Episode";
                                    const endDate = episode.end_date ? format(new Date(episode.end_date), "MM/dd/yyyy") : "";
                                    listItem.textContent = `${startDate} - ${endDate}`;
                                    episodesList.appendChild(listItem);
                                });
                                summary.appendChild(episodesList);
                            } else {
                                expandBtn.textContent = "+";
                                // Remove the full list of episodes
                                summary.removeChild(summary.lastChild);
                            }
                            cell.getRow().normalizeHeight(); // Update the row height
                        });
                    }

                    // Create summary text container for the initial date and count bubble

                    const currentStart = rowData.current_episode_start ? format(parseISO(rowData.current_episode_start), "MM/dd/yyyy") : "No Current Episode";
                    const currentEnd = rowData.current_episode_end ? format(parseISO(rowData.current_episode_end), "MM/dd/yyyy") : "";
                    //const currentStart = rowData.current_episode_start ? format(new Date(rowData.current_episode_start), "MM/dd/yyyy") : "(no start date)";
                    //const currentEnd = rowData.current_episode_end ? format(new Date(rowData.current_episode_end), "MM/dd/yyyy") : "(no end date)";
                    const summaryTextContainer = document.createElement("span");
                    summaryTextContainer.textContent = `${currentStart} - ${currentEnd}`;
                    summary.appendChild(summaryTextContainer);

                    // Append count bubble to summary text container if episodes exist
                    if (Array.isArray(episodes) && episodes.length > 0) {
                        const countBubble = document.createElement("span");
                        countBubble.classList.add("episode-count-bubble");
                        countBubble.textContent = ` ${episodes.length}`;
                        summaryTextContainer.appendChild(countBubble);
                    }

                    return summary;
                },



                variableHeight: true // Enable variable row height

            },
            {
                title: "Previous Status",
                field: "status_reason",
                hozAlign: "center",
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getRow().getData();
                    const pastStatusReasons = rowData.pastStatusReasons; // Assuming you have an array of past status reasons
                    const summary = document.createElement("div");

                    // Conditionally create and append expand button if past status reasons exist
                    if (Array.isArray(pastStatusReasons) && pastStatusReasons.length > 0) {
                        const expandBtn = document.createElement("button");
                        expandBtn.textContent = "+";
                        summary.appendChild(expandBtn);

                        // Event listener for expand/collapse
                        expandBtn.addEventListener("click", function () {
                            if (expandBtn.textContent === "+") {
                                expandBtn.textContent = "-";
                                // Render full list of past status reasons
                                const statusReasonsList = document.createElement("ul");
                                pastStatusReasons.forEach(statusReason => {
                                    const listItem = document.createElement("li");
                                    listItem.textContent = statusReason;
                                    statusReasonsList.appendChild(listItem);
                                });
                                summary.appendChild(statusReasonsList);
                            } else {
                                expandBtn.textContent = "+";
                                // Remove the full list of past status reasons
                                summary.removeChild(summary.lastChild);
                            }
                            cell.getRow().normalizeHeight(); // Update the row height
                        });
                    }

                    // Create summary text container for the current status reason
                    const currentStatusReason = rowData.status_reason;
                    const summaryTextContainer = document.createElement("span");
                    summaryTextContainer.textContent = currentStatusReason;
                    summary.appendChild(summaryTextContainer);

                    // Append count bubble to summary text container if past status reasons exist
                    if (Array.isArray(pastStatusReasons) && pastStatusReasons.length > 0) {
                        const countBubble = document.createElement("span");
                        countBubble.classList.add("status-reason-count-bubble");
                        countBubble.textContent = ` ${pastStatusReasons.length}`;
                        summaryTextContainer.appendChild(countBubble);
                    }

                    return summary;
                },
                sorter: "string",
                variableHeight: true // Enable variable row height
            },
            {
                name: "Primary Payor",
                fields: ["primary_payer"],
                field: "primary_payer",
                minWidth: 300
            },
            {
                name: "Engagement Specialist",
                fields: ["engagement_specialist.last_name"],
                field: "engagement_specialist.last_name",
                minWidth: 100
            },
            {
                title: " Referral Source",
                field: "referral",
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getData();
                    let content = '';

                    if (rowData.referral_source) {
                        content += `${rowData.referral_source}`;
                    }
                    if (rowData.referral_source && rowData.ext_referral) {
                        content += '<span style="width:15px;"></span>';
                    }
                    if (rowData.ext_referral) {
                        content += `<strong style="margin-right:20px;">External Referral</strong> ${rowData.ext_referral}`;
                    }

                    return content;
                }
            },

            {
                name: "Internal Memo",
                fields: ["internal_memo"],
                field: "internal_memo",
                minWidth: 100,
                maxWidth: 350,
                formatter: "html",
            },
        ];

        this._filter_config = {
            collection: { name: "patient", auto_configure: false, depth: 0 },
            search_fields: [
                "first_name",
                "last_name",
                "status",
                "source",
                "mrn",
                "medicare_id",
                "referral_source",
                "ext_referral"
            ],
            field_rules: {
                directus_users: {
                    mode: "whitelist",
                    fields: [
                        "first_name",
                        "last_name",
                    ],
                }
            }
        };
        this.dropdown = null;
    }

    connectedCallback() {


        //  const toggleButton = this.querySelector('#toggle-all-rows-btn');
        //  if (toggleButton) {
        //      toggleButton.addEventListener('click', () => {
        //          this.expandCollapseAllRows('expand');
        //          console.log("toggle all rows");
        //      });
        //  }

        this.template = () =>
            html`
                <style>
                    import-patients-imported .item-row:hover {
                        background-color: #f0f0f0;
                        cursor: pointer;
                    }

                    import-patients-imported li.disabled {
                        cursor: not-allowed;
                    }

                    .actions-menu .btn.btn-primary.dropdown-toggle {
                        background-color: #E4958B;
                        border-color: #E4958B; 
                    }
                </style>
                <div class="container-fluid" style="height: 100%; padding: 0;">
                    <div class="row" style="">
                     <div class="actions-menu">
                                    
                        <app-filter 
                            @filter_change=${e => this.handleFilterChange(e.detail)}
                            style=""
                            .filter_state=${this.filter_state}
                            .config=${this._filter_config}
                            .enable_aggregate=${false}
                            .disable_sort=${true}
                            .show_filters=${false}
                            .expanded=${true}
                        ></app-filter>
                    </div> 
                   
                     </div>
                     
                    <div class="row" style="padding: 0 16px; border-radius: 8px;" id="patients-import-container">
                        <app-filter-data-view-server
                            .config=${this._filter_config}
                            .filter_state=${this.filter_state}
                            .status_filter=${this.status_filter}
                            .selected_columns=${this.selected_columns}
                            .options=${this._options}
                            .table_columns=${this.table_columns}
                            .selection_type=${"multiple"}
                            .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        },

                    ]
                }}
                            @select-row=${e => this.handleSelect(e.detail)}
                            @expand-collapse-all=${e => this.handleExpandCollapseAll(e.detail.action)}
                            style="padding: 0;"
                        ></app-filter-data-view-server>
                    </div>
                </div>
            `;

        this.render();
        this.init();

        //this.addEventListener('click', event => { // listner for toggle all rows buttons expand/collapse (not being used)
        //    if (event.target.id === 'toggle-all-rows-btn') {
        //        this.expandCollapseAllRows('expand');
        //    }
        //});

    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    r

    init() {
        // let filter_state = ApplicationState.get('app.import_queued_patients.filter_state');
        let filter_state;
        if (!filter_state) {
            // default filter to non-active imported patients
            filter_state = { user_search: "" };
            filter_state.group = {
                type: "_and",
                filters: [
                    //{
                    //    "field": "status",
                    //    "op": "_in",
                    //    "value": [this.status_filter]
                    //},


                    //{
                    //    "field": "iso_start",
                    //    "op": "_gte",
                    //    "value": "$NOW(-10d)"
                    //},
                    //{
                    //    "field": "on_service",
                    //    "op": "_eq",
                    //    "value": false
                    //}

                ],
                groups: [],
            };
        }
        let filter_element = this.querySelector('app-filter');
        filter_element.filter_state = filter_state;
        this.filter_state = filter_state;

        let dropdownElement = this.querySelector(".dropdown-toggle");
        if (dropdownElement) {
            this.dropdown = new Dropdown(dropdownElement, {
                autoClose: "outside"
            });
        }


        this.render();
    }

    /**
     * Updates this._page_size based on window dimensions to support infinite scroll
     */
    //updatePageSize() {
    //    const report_height = this.content_ref.value.clientHeight;
    //    const header_height = this.header_ref.value.clientHeight;
    //    const thead_height = document.querySelector('thead').clientHeight;

    //    const scroll_container_max_height = report_height - header_height - thead_height;
    //    this._page_size = Math.ceil((scroll_container_max_height) / MIN_ROW_HEIGHT) + 1;
    //    this._current_page = 1;
    //    this._all_pages_fetched = false
    //    this.fetchData();
    //}
}

customElements.define("report-patient-episodes", ReportPatientEpisodes);
