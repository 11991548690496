import { html, render } from "lit";
import { subDays } from "date-fns";
import { navigate } from "shared/lib/lib-router";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";

/**
 * A widget displaying reported unplanned hospital care events.
 * @param {object} metrics reponse from /dashboard_metrics
 */
export default class AppDashboardHospitalization extends HTMLElement {
    constructor() {
        super();

        const today = new Date();
        const seven_days_ago = subDays(today, 7);
        this.report_filters = {
            survey_completion_date_range: `${getISODateStringWithoutTime(seven_days_ago)},${getISODateStringWithoutTime(today)}`
        };
    }

    set metrics(value) {
        this._metrics = value;
        this.render();
    }

    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            const hospitalization_length = this.metrics?.unplanned_hospital_care?.hospitalization_length || [];
            const less_than_two_nights_count = hospitalization_length.find(row => row.length === 'less_than_two_nights')?.incident_count || 0;
            const more_than_two_nights_count = hospitalization_length.find(row => row.length === 'more_than_two_nights')?.incident_count || 0;

            const hospitalized_count = this.metrics?.unplanned_hospital_care?.recent_hospitalization?.hospitalized_patients || 0;
            const total_count = this.metrics?.total_patient_count;

            return html`
                <style>
                   
                    #dashboard-hospital .hospital-metric {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                        width: 120px;
                        position: relative;
                    }
                    #dashboard-hospital .hospital-metric span:first-child {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                    }
                    #dashboard-hospital .hospital-metric__value {
                        font-weight: 700;
                        line-height: 56px;
                        font-size: 56px;
                        color: var(--t-color-dark);
                        letter-spacing: 0.5px;
                    }
                    #dashboard-hospital .hospital-metric__label {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: var(--t-color-grey);
                        text-align: center;
                        margin-top: 8px;
                    }
                    #dashboard-hospital .hospital-widget-row {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    #dashboard-hospital .hospital-widget-row__value {
                        margin-left: auto;
                    }
                </style>
                <div 
                    id="dashboard-hospital" 
                    style="
                        height: 100%; 
                        cursor: pointer; 
                        display: flex; 
                        flex-direction: column;
                    "
                    @click=${_e => navigate(`reports/patient/hospitalization?filters=${JSON.stringify(this.report_filters)}`)}
                >
                    <h6>Unplanned Hospital Care Events</h6>
                    <div style="
                        margin: auto 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    ">
                    (Active Patients)
                        <div style="display: flex; justify-content: center;">
                            <div class="hospital-metric">
                                <span class="hospital-metric__value">
                                    ${hospitalized_count}
                                </span>
                                <span class="hospital-metric__label">
                                    of ${total_count} Patient${total_count === 1 ? "" : "s"}
                                </span>
                            </div>
                            <div class="hospital-metric" style="margin-left: 48px;">
                                <span class="hospital-metric__value">
                                    ${Math.round(
                (
                    this.metrics?.unplanned_hospital_care?.recent_hospitalization?.hospitalized_patients /
                    this.metrics?.unplanned_hospital_care?.recent_hospitalization?.total_active_patients
                ) * 100
            ) || 0
                }%
                                </span>
                                <span class="hospital-metric__label">
                                    Of patients on service
                                </span>
                            </div>
                        </div>
                        <span class="hospital-metric__label" style="margin-top: 32px;">
                            Patients Per Reported of ER Length Stay
                        </span>
                        <div style="
                            margin-top: 16px;
                            width: 100%;
                            max-width: 300px;
                        ">
                            <div class="hospital-widget-row">
                                <app-color-label .text=${"Less than 2 Nights in ER"} .color=${"var(--t-color-warning)"}></app-color-label>    
                                <span class="hospital-widget-row__value">
                                    ${less_than_two_nights_count}
                                </span>
                            </div>
                            <div class="hospital-widget-row">
                                <app-color-label .text=${"More than 2 Nights in ER"} .color=${"var(--t-color-danger)"}></app-color-label>    
                                <span class="hospital-widget-row__value">
                                    ${more_than_two_nights_count}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
            paddingBottom: "12px",
        });

        this.init();
    }

    render() {
        if (!this.template)
            return;
        if (!this.metrics)
            return;
        render(this.template(), this);
    }

    //TODO: figure out how to crack supplemental field values when we don't have a survey definition
    async init() {
        this.render();
    }
}

customElements.define("app-dashboard-hospitalization", AppDashboardHospitalization);
