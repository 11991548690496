import { html, render } from "lit";

import "./component-question-history-chart";
import "./component-question-history-detail";
import { getEpisodeWeek } from "../lib/lib-util";
import SurveyDefinition from "../lib/lib-survey-definition";
import FormsDataDictionary from "../lib/lib-forms-data-dictionary";
import directus from "../lib/lib-directus";

class ComponentPatientCheckpoints extends HTMLElement {
    get patient_id() {
        return this._patient_id;
    }

    set patient_id(value) {
        this._patient_id = value;
        this.init();
    }

    get hovered_checkpoint_survey_id() {
        return this._hovered_checkpoint_survey_id;
    }

    set hovered_checkpoint_survey_id(value) {
        this._hovered_checkpoint_survey_id = value;
        this.render();
    }

    constructor() {
        super();
        this._hovered_checkpoint_survey_id = null;
    }

    connectedCallback() {
        this.template = () => html`
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" cross-origin />
            <!-- <link
                href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap"
                rel="stylesheet" /> -->
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            <!--<link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:FILL" rel="stylesheet" />-->
            <style>
                .question_list_item_selected {
                    background-color: #3674b61a;
                }
                .question_list_item:hover {
                    background-color: #3674b61a;
                }

                @media (max-width: 768px) {
                    #question_list {
                        margin-bottom: 20px;
                    }
                    #patient_checkpoints {
                        display: block;
                    }
                    #question_view {
                        margin-left: 0px;
                    }
                    #mobile_question_list {
                        display: flex;
                    }

                    #full_question_list {
                        display: none;
                    }

                    .field_indicator {
                        position: relative;
                    }
                }
                @media (min-width: 768px) {
                    #patient_checkpoints {
                        display: flex;
                    }
                    #question_view {
                        margin-left: 10px;
                    }
                    #mobile_question_list {
                        display: none;
                    }

                    #full_question_list {
                        display: block;
                    }

                    .field_indicator {
                        position: absolute;
                        right: 30px;
                    }
                }
            </style>
            <div class="error">${this.error_message || ""}</div>
            ${this.episodes?.length ?
                html`
            <div
                id="patient_checkpoints"
                style="
                border-radius: 5px;
            ">
                <div
                    id="question_list"
                    style="
                    display: block; 
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 10px;">
                    <div
                        style="
                        height: 51px;
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
                        display: flex; 
                        flex-direction: row; 
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px;
                        ">
                        <h3
                            style="
                        
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 20px;
                            color: #201B3A;
                            white-space: nowrap;
                            margin-bottom: 0px;
                        ">
                            Ep. of Care
                        </h3>
                        <select
                            style="
                            border: none;
                            font-size: 14px;
                            line-height: 20px;
                            "
                            @change=${(e) => this.handleSelectEpisode(e.target.value)}>
                            ${this.episodes && this.episodes.length
                        ? html`
                                      ${this.episodes.map(
                            (episode) => html`
                                              <option value=${episode.id}>
                                                  ${new Date(episode.start_date).toLocaleDateString()} -
                                                  ${new Date(episode.end_date).toLocaleDateString()}
                                              </option>
                                          `
                        )}
                                  `
                        : html`<option>Loading episodes...</option>`}
                        </select>
                    </div>
                    <div
                        style="
                        align-items: center; 
                        justify-content: space-between;
                        margin-top: 10px;
                        height: 45px;"
                        id="mobile_question_list">
                        <div
                            style="
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 45px;
                            color: #201B3A;
                        ">
                            Topic
                        </div>
                        <div class="dropdown">
                            <div
                                class="dropdown-toggle"
                                data-bs-toggle="dropdown"
                                style="
                                font-weight: 400;
                                font-size: 14px;
                                color: #201B3A;
                                display: flex;
                                align-items: center;
                            ">
                                <span style="margin-right: 10px;">${this.selected_question?.label}</span>
                                ${this.getFieldIcon(this.selected_question)}
                                <span class="caret"></span>
                            </div>
                            <ul
                                class="dropdown-menu"
                                style="
                                padding: 10px">
                                ${this.field_groups
                        ? Object.keys(this.field_groups).map(
                            (group_name) => html`
                                              ${this.field_groups[group_name].map(
                                (field) => html`
                                                      <li
                                                          class="question_list_item"
                                                          @click=${(e) =>
                                        this.handleSelectQuestion(field, e.currentTarget)}
                                                          style="
                                                        display: grid;
                                                        grid-template-columns: auto 10px 20px;
                                                        align-items: center;
                                                        line-height: 36px; 
                                                        cursor: pointer; 
                                                        position: relative;">
                                                          <span
                                                              style="
                                                      
                                                        font-weight: 400;
                                                        font-size: 14px;
                                                        color: #201B3A;
                                                        white-space: nowrap;
                                                        grid-column-start: 1;
                                                    "
                                                              >${field.label}</span
                                                          >
                                                          ${this.getFieldIcon(field)}
                                                      </li>
                                                  `
                            )}
                                          `
                        )
                        : ""}
                            </ul>
                        </div>
                    </div>

                    <div id="full_question_list">
                        ${this.field_groups
                        ? Object.keys(this.field_groups).map((group_name) =>
                            this.field_groups[group_name].length == 0
                                ? ""
                                : html`
                                            <div
                                                style="
                                                    font-weight: 700;
                                                    font-size: 14px;
                                                    line-height: 18px;
                                                    letter-spacing: 0.5px;
                                                    text-transform: uppercase;
                                                    color: #201B3A;
                                                    border-top: 1px solid #C8D6E4;
                                                    padding-top: 15px;
                                                ">
                                                ${group_name}
                                            </div>

                                            ${this.field_groups[group_name].map(
                                    (field) => html`
                                                    <div
                                                        class="question_list_item"
                                                        @click=${(e) =>
                                            this.handleSelectQuestion(field, e.currentTarget)}
                                                        style="display: flex; flex-direction: row; align-items: center; line-height: 36px; cursor: pointer; position: relative;">
                                                        <span
                                                            style="
                                                                font-weight: 400;
                                                                font-size: 14px;
                                                                color: #201B3A;
                                                            "
                                                            >${field.label}</span
                                                        >
                                                        ${this.getFieldIcon(field)}
                                                        <span
                                                            class="material-symbols-outlined"
                                                            style="font-size: 16px; position: absolute; right: 1px;">
                                                            arrow_circle_right
                                                        </span>
                                                    </div>
                                                `
                                )}
                                        `
                        )
                        : ""}
                    </div>
                </div>
                <div
                    id="question_view"
                    style="
                    width: 100%;
                    padding: 10px;
                    border-radius: 5px;
                    background-color: #ffffff;
                ">
                    <app-question-history-chart
                        @checkpointmouseover=${(e) => this.handleCheckpointMouseover(e)}
                        @checkpointmouseleave=${(e) => this.handleCheckpointMouseleave(e)}
                        .checkpoints=${this.checkpoints}
                        .episode=${this.selected_episode}
                        .selected_question=${this.selected_question}
                        .field_definition=${this.field_definition}></app-question-history-chart>

                    <app-question-history-detail
                        style="margin-top: 30px;display: block;"
                        .hovered_checkpoint_survey_id=${this.hovered_checkpoint_survey_id}
                        .checkpoints=${this.checkpoints}
                        .selected_question=${this.selected_question}
                        .field_definition=${this.field_definition}
                        .form_supplemental_questions=${this.form_supplemental_questions}
                        .survey_question_notes=${this.survey_question_notes}
                        @viewdetailsclick=${(e) => this.handleViewDetailsClick(e)}></app-question-history-detail>
                </div>
            </div>
            `: ''}
        `;
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    async init() {
        this.style.display = "block";
        this.style.backgroundColor = "#f5f5f5";
        await this.loadAlerts();
        await this.loadPatientData();
        await this.loadFieldDefinition();
    }

    /**
     * @param {CustomEvent} e <app-question-history-chart> checkpointmouseover event
     */
    handleCheckpointMouseover(e) {
        this.hovered_checkpoint_survey_id = e.detail.checkpoint_survey_id;
    }

    /**
     * @param {CustomEvent} e <app-question-history-chart> checkpointmouseleave event
     */
    handleCheckpointMouseleave(_e) {
        this.hovered_checkpoint_survey_id = null;
    }

    /**
     * @param {CustomEvent} e ComponentQuestionHistoryChart#viewdetailsclick
     * @fires ComponentPatientCheckpoints#viewdetailsclick
     */
    handleViewDetailsClick(e) {
        e.stopPropagation();
        this.dispatchEvent(
            new CustomEvent("viewdetailsclick", {
                detail: e.detail,
            })
        );
    }

    async handleSelectEpisode(episode_id) {
        this.selected_episode = this.episodes.find((episode) => episode.id == episode_id);
        await this.loadCheckpointData();
    }

    handleSelectQuestion(field, target_element) {
        if (this._selected_element) this._selected_element.classList.remove("question_list_item_selected");
        this._selected_element = target_element;
        this._selected_element.classList.add("question_list_item_selected");
        this.selected_question = field;
        this.render();
    }

    getFieldIcon(field) {
        if (!this.checkpoints) return;
        if (!this.checkpoints.length) return;
        if (!field) return;

        if (field.alert) return this.getAlertIcon(field.alert.level);

        let checkpoint = this.checkpoints[0];
        let delta = checkpoint["delta_" + field.field];

        //improved
        if (delta < 0) {
            return html`
                <span
                    class="field_indicator material-symbols-outlined"
                    style="
                    font-size: 16px; 
                    color: #a4d394;
                    grid-column-start: 3;
                    font-variation-settings: 'FILL' 1;
                    margin-left: 4px;
                ">
                    check_circle
                </span>
            `;
        }

        //declined
        if (delta > 0) {
            return html`
                <span
                    class="field_indicator material-symbols-outlined"
                    style="
                    font-size: 16px; 
                    grid-column-start: 3;
                    color: #ca463d;
                    margin-left: 4px;
                ">
                    trending_down
                </span>
            `;
        }
    }

    async loadFieldDefinition() {
        if (!this.checkpoints?.length)
            return;
        const base_survey_definitions = await SurveyDefinition.getSurveyDefinition();
        this.field_definition = base_survey_definitions.getFieldDefinition()
            .filter((field) => this.hasBeenAsked(field))
            .map((field) => {
                let alert;
                //cherrypick alerts for the last checkpoint
                if (this.checkpoints.length)
                    alert = this.alerts.find((alert) => {
                        alert.survey_id == this.checkpoints[0].survey_id && alert.question == field.field;
                    });
                return {
                    ...field,
                    label:
                        field.meta?.translations?.find((translation) => translation.language == "en-US")?.translation ||
                        SurveyDefinition.formatFieldName(field.field),
                    alert,
                };
            });

        //build field groups
        this.field_groups = {};

        for (let field of this.field_definition) {
            if (!field.meta?.group)
                continue;
            let group_name = SurveyDefinition.formatFieldName(field.meta.group);
            let group = this.field_groups[group_name];
            if (!group) group = [];
            group.push(field);
            this.field_groups[group_name] = group;
        }
        this.selected_question = this.field_definition[0];
        this.render();
    }

    hasBeenAsked(field) {
        for (let checkpoint of this.checkpoints) {
            /* eslint-disable-next-line */
            if (checkpoint.hasOwnProperty(field.field))
                if (checkpoint[field.field] !== null) return true;
        }
        return false;
    }

    async loadPatientData() {
        try {
            this.patient = await directus.items("patient").readOne(this.patient_id);
            let result = await directus.items("episode").readByQuery({
                filter: {
                    patient_id: this.patient.id,
                },
            });
            if (!result.data.length) {
                this.error_message = "Patient has no episodes";
                this.render();
                return;
            }
            this.episodes = result.data;
            this.selected_episode = this.episodes[0];
            await this.loadCheckpointData();
        } catch (err) {
            console.error(err);
        }
    }

    getAlertColor(level) {
        switch (level) {
            case 1:
                return "#ca463d";
            case 2:
                return "#E4A00F";
            case 3:
                return "#201B3A";
        }
    }

    getAlertIcon(level) {
        const icon_name = level === 1 ? "report" : "warning";
        return html`
            <span
                class="material-symbols-outlined"
                style='
                    margin-left: 4px;
                    color: ${this.getAlertColor(level)};
                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
            '>
                ${icon_name}
            </span>
        `;
    }

    async loadCheckpointData() {
        let result = await directus.transport.get(
            `/vbh/measures_delta/${this.patient_id}/${this.selected_episode.id}`
        );
        if (!result.data?.length)
            return this.checkpoints = [];
        this.checkpoints = result.data;
        this.checkpoints.sort((a, b) => new Date(b.survey_completion_date) - new Date(a.survey_completion_date));
        for (let checkpoint of this.checkpoints)
            checkpoint.week = getEpisodeWeek(this.selected_episode, checkpoint.survey_completion_date);

        const form_design_ids = this.checkpoints.map(checkpoint => checkpoint.form_design_id);
        this.form_supplemental_questions = await FormsDataDictionary.loadFormsSupplementalQuestions(form_design_ids);
        this.survey_question_notes = await this.loadSurveyQuestionNotes();
        this.render();
    }

    async loadAlerts() {
        let result = await directus.items("alert").readByQuery({
            filter: {
                patient_id: this.patient_id,
            },
            fields: ["id", "level", "survey_id", "question"],
        });
        this.alerts = result.data;
    }

    async loadSurveyQuestionNotes() {
        const notes_map = {};
        const survey_ids = this.checkpoints.map(checkpoint => checkpoint.survey_id);
        if (survey_ids.length) {
            const result = await directus.items("note").readByQuery({
                filter: {
                    survey_id: { _in: survey_ids },
                    user_created: {
                        role: {
                            name: { _in: ["Survey Team", "Survey Manager", "Engagement Specialist", "Agency Admin"] }
                        }
                    },
                },
                sort: "-date_created",
            });
            for (let note of result.data) {
                if (!notes_map[note.survey_id]) {
                    notes_map[note.survey_id] = {}
                }
                if (note.question) {
                    if (!notes_map[note.survey_id][note.question]) {
                        // only display the first note for the question
                        notes_map[note.survey_id][note.question] = note.content;
                    }
                }

            }
        }
        return notes_map;
    }
}

customElements.define("app-patient-checkpoints", ComponentPatientCheckpoints);
export default ComponentPatientCheckpoints;
