import { html, render } from 'lit';
import { until } from 'lit/directives/until.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { OPERATIONS, TYPE_OPERATIONS } from './app-filter';
import { Dropdown } from 'bootstrap';
import AppFilterRelationSelectorModal from './app-filter-relation-selector-modal';
import mustache from 'mustache/mustache.mjs';
import { Collapse } from 'bootstrap';
import AppFilterUtil from './util/app-filter-util';

/**
 * @typedef {Object} FilterItem
 * @property {string} field - The field the filter applies to
 * @property {string} op - The operation to execute
 * @property {any} value - The value to filter on
 */
class AppFilterItem extends HTMLElement {

    set collection_config(value) {
        this._collection_config = value;

        if (this.filter_field) {
            this.selected_field = AppFilterUtil.findField(value, this.filter_field)
        }

        if (this.filter_operation) {
            this.selected_operation = OPERATIONS[this.filter_operation];
        }

        this.render();
    }

    /** @type {import('./app-filter').CollectionConfig} */
    get collection_config() {
        return this._collection_config;
    }

    /** @type {FilterItem} */
    set filter(value) {
        this._filter = value;
        this.filter_field = value.field;
        this.filter_operation = value.op;
        this.filter_value = value.value;
    }

    get filter() {
        return this._filter;
    }

    set filter_field(value) {
        this._filter.field = value;
        if (!value)
            return this.selected_field = null;
        if (this.collection_config) {
            this.selected_field = AppFilterUtil.findField(this.collection_config, value);
        }
    }

    get filter_field() {
        return this._filter.field;
    }

    set filter_operation(value) {
        this._filter.op = value;
        this.selected_operation = OPERATIONS[value];
        this.selected_relational_data = null;
    }

    get filter_operation() {
        return this.selected_operation?.op;
    }

    set filter_value(value) {
        this._filter.value = value;
        if (typeof value == 'undefined')
            return;
        this.setValueInterface();
    }

    get filter_value() {
        return this._filter.value;
    }

    constructor() {
        super();
        this._filter = {};
    }

    connectedCallback() {
        this.template = () => html`
        <div class="app-filters-item">
            <div class="dropdown"  style="width: 180px">
                <button style="font-size: 14px; font-weight: 500; padding: 5px;" class="btn dropdown-toggle app-filter-item-field-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ${unsafeHTML(AppFilterUtil.getDisplayField(this.collection_config, this.filter_field) || 'Select field')}
                </button>
                <ul style="font-size: 14px; overflow-y: auto; max-height: 350px; background:white" class="dropdown-menu">
                    ${this.getFieldItems(this.collection_config)}
                </ul>
            </div>
            <div class="dropdown" style="width: 250px">
                <button style="font-size: 14px; font-weight: 500; padding: 5px;" class="btn dropdown-toggle app-filter-item-operation-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ${this.selected_operation?.label || 'Select operation'}
                </button>
                <ul style="font-size: 14px;" class="dropdown-menu">
                    ${TYPE_OPERATIONS[this.selected_field?.type]?.map(
            operation_key => html`
                        <li>
                            <a 
                                @click=${e => this.handleSelectOperation(e, OPERATIONS[operation_key])}
                                class="dropdown-item ${operation_key == this.selected_operation.op ? 'active' : ''} " title=${OPERATIONS[operation_key].description} href="#">${OPERATIONS[operation_key].label}</a>
                        </li>
                        `
        )}
                </ul>
            </div>
            <div style="font-size: 14px; padding: 0px; width:300px;">
            ${this.value_interface}
            </div>
            <div
                @click=${(e) => {
                e.preventDefault();
                e.stopImmediatePropagation();
                this.dispatchEvent(new CustomEvent('delete', { composed: true, bubbles: true }));
            }}
                class="app-filters-item-delete material-symbols-outlined"
                style='
                    font-size: 15px;
                    cursor: pointer;
                    margin-left: 5px;
                    color: var(--t-color-grey);
                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                '
            >cancel</div>

        </div>
        `;
        this.render();
    }

    render() {
        if (!this.template)
            return;

        if (!this.collection_config)
            return;

        render(this.template(), this);
    }

    /**
     * 
     * @param {*} collection_config 
     * @param {*} path 
     * @todo Replace this with the new AppFilterFieldSelectList component
     * @returns 
     */
    getFieldItems(collection_config, path) {
        return html`
            ${collection_config.fields.map(
            /**
             * @param {import('./app-filter').FieldConfig} field 
             */
            (field) => html`
                <li>
                    <div
                        style="display: flex; flex-direction: row; align-items: center;"
                    >
                        <a
                            @click=${e => this.handleSelectField(e, `${path ? `${path}.${field.field}` : field.field}`, field)}
                            class="dropdown-item ${field == this.selected_field ? 'active' : ''}" 
                            href="#">${field.label}</a>
                        ${field.related_collection && field.related_collection.depth > 0 ?
                    html`
                            <span
                                @click=${e => this.handleToggleExpandedField(e, field)}
                                class="material-symbols-outlined"
                                data-bs-toggle="collapse"
                                data-bs-target="${`#app-filter-item-select-${field.field}`}"
                                style='
                                    margin-right: 10px;
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: var(--t-color-dark);
                                    font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 48;
                                '>expand_more</span>
                        `: ''}
                    </div>
                    ${field.related_collection && field.related_collection.depth > 0 ?
                    html`
                    <div class="collapse" id="${`app-filter-item-select-${field.field}`}">
                        <ul>
                            ${this.getFieldItems(field.related_collection, `${path ? `${path}.${field.field}` : field.field}`)}
                        </ul>
                    </div>
                    `: ''}
                </li>
                `
        )}
        `;
    }

    /**
     * 
     * @param {Event} e 
     * @param {import('./app-filter').FieldConfig} field 
     */
    handleToggleExpandedField(e, field) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let expand_element = this.querySelector(`#app-filter-item-select-${field.field}`);
        let collapse = Collapse.getOrCreateInstance(expand_element);
        collapse.toggle();
    }

    handleSelectField(e, path) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let element = this.querySelector('.app-filter-item-field-filter');
        let dropdown = new Dropdown(element);
        dropdown.hide();
        element.focus();
        this.filter_field = path;
        this.selectOperation(OPERATIONS['_eq']);
        this.render();
    }

    handleSelectOperation(e, operation) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let element = this.querySelector('.app-filter-item-operation-filter');
        let dropdown = new Dropdown(element);
        dropdown.hide();
        element.focus();
        this.selectOperation(operation);
    }

    handleSelectFieldValue(e, value, index) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let elements = this.querySelectorAll('.dropdown-toggle');
        let element = elements[elements.length - 1];
        let dropdown = new Dropdown(element);
        dropdown.hide();
        element.focus();
        this.setValue(value, index);

        //force re-render
        this.setValueInterface();
        this.render();
        this.dispatchEvent(new CustomEvent("item-change", { bubbles: true, composed: true }));
    }

    async handleShowRelationSelector(index) {
        let modal = new AppFilterRelationSelectorModal();
        let multiple;

        if (Array.isArray(this.filter_value))
            multiple = true;

        if (multiple) {
            modal.title = `Select some items`;
            modal.multiselect = true;
        }
        else
            modal.title = `Select an item`;

        modal.collection_name = this.selected_field.related_collection.name;
        await modal.showModal();
        let result = await modal.onDidDismiss();
        if (result) {
            let { value, data } = result;
            this.filter_value = value;
            this.selected_relational_data = data;

            //force rerender
            this.setValueInterface();
            this.render();
            this.dispatchEvent(new CustomEvent("item-change", { bubbles: true, composed: true }));
        }
        this.dispatchEvent(new CustomEvent('set-focus', { bubbles: true, composed: true }));
    }

    selectOperation(selected_operation) {
        this.selected_relational_data = null;
        this.filter_operation = selected_operation.op;
        this.setValueInterface(true);
        this.render();
    }

    setValueInterface(clear) {
        if (clear)
            this.filter_value = null;
        if (['_in', '_nin'].includes(this.selected_operation.op)) {
            if (clear)
                this.filter_value = [null];
            if (!Array.isArray(this.filter_value))
                this.filter_value = [null];
            return this.value_interface = this.multiValueInterface();
        }
        if (['_between', '_nbetween'].includes(this.selected_operation.op)) {
            if (clear)
                this.filter_value = [null, null];
            if (!Array.isArray(this.filter_value))
                this.filter_value = [null, null];
            return this.value_interface = this.twoValueInterface();
        }
        if (['_null', '_nnull'].includes(this.selected_operation.op)) {
            this.filter_value = true;
            this.dispatchEvent(new CustomEvent('item-change', { bubbles: true, composed: true }));
            return this.value_interface = '';
        }

        this.value_interface = this.singleValueInterface();
        this.render();
    }

    multiValueInterface() {
        return html`
        <div 
            style="display: flex; flex-direction: row; align-items: center;"
        >
            ${this.filter_value.map(
            (filter_value, index) => html`
                    ${index > 0 ? ', ' : ''}
                    ${this.singleValueInterface(index)}
                `
        )}
            ${this.selected_field.related_collection ? '' :
                html`
                    <span 
                        style="cursor: pointer; font-size: 16px; margin-left: 7px; font-weight: 700;"
                        @click=${e => {
                        this.filter_value.push(null);
                        //we need to do this to force re-render
                        this.value_interface = this.multiValueInterface();
                        this.render();
                    }}> + </span>
                `
            }
        </div>
        `;

    }

    twoValueInterface() {
        return html`
        <div 
            style="display: flex; flex-direction: row; align-items: center;"
        >
                ${this.singleValueInterface(0)}
                <span style="margin-left: 7px; margin-right: 7px;"> and </span>
                ${this.singleValueInterface(1)}
        </div>
        `;
    }

    singleValueInterface(index) {
        if (this.selected_field.related_collection) {
            return this.relationSelectorInterface(index);
        }

        switch (this.selected_field.type) {
            case 'string':
            case 'text':
                return this.stringInterface(index);
            case 'boolean':
                return this.booleanInterface(index);
            case 'integer':
            case 'bigInteger':
            case 'float':
            case 'decimal':
                return this.numberInterface(index);
            case 'timestamp':
            case 'dateTime':
                return this.dateTimeInterface(index);
            case 'date':
                return this.dateInterface(index);
            case 'time':
                return this.timeInterface(index);

        }
    }

    relationSelectorInterface(index) {
        return html`
        <button 
            @click=${e => this.handleShowRelationSelector(index)}
            style="font-size: 12px; padding: 5px;" 
            class="btn" 
            type="button">
        ${this.getDisplayValue(index) || 'Select..'}
        </button>
        `;
    }

    getDisplayValue(index) {
        let choice;
        if (this.selected_field?.meta?.options?.choices) {
            if (Array.isArray(this.filter_value))
                choice = this.selected_field.meta.options.choices.find(
                    choice => choice.value == this.filter_value[index]
                );
            else
                choice = this.selected_field.meta.options.choices.find(
                    choice => choice.value == this.filter_value
                );
            return choice?.text || '';
        }

        if (this.selected_field.related_collection) {
            if (this.selected_relational_data)
                return this.getFormattedRelationalValue(index);
        }

        if (Array.isArray(this.filter_value))
            return this.filter_value[index];

        return this.filter_value;
    }

    getFormattedRelationalValue(index) {
        let template = this.selected_field?.meta?.options?.template;
        if (!template) {
            if (Array.isArray(this.filter_value))
                return this.filter_value[index];
            return this.filter_value;
        }
        if (Array.isArray(this.filter_value))
            return mustache.render(template, this.selected_relational_data[index]);
        else
            return mustache.render(template, this.selected_relational_data);
    }

    getValue(index) {
        if (Array.isArray(this.filter_value))
            return this.filter_value[index];
        else
            return this.filter_value;

    }

    setValue(value, index) {
        if (Array.isArray(this.filter_value))
            this.filter_value[index] = value;
        else
            this.filter_value = value
    }

    throttleChange(value, index) {
        this.setValue(value, index);

        //throttle search
        if (this._change_timeout)
            clearTimeout(this._change_timeout)
        this._change_timeout = setTimeout(() => {
            this.dispatchEvent(
                new CustomEvent('item-change', {
                    composed: true,
                    bubbles: true,
                })
            );
        }, 400)
    }

    stringInterface(index) {
        if (this.selected_field.meta?.options?.choices?.length) {
            return html`
            <div class="dropdown">
                <button style="font-size: 12px; padding: 5px;" class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ${this.getDisplayValue(index) || 'Select value'}
                </button>
                <ul style="font-size: 12px;" class="dropdown-menu">
                    ${this.selected_field.meta.options.choices.map(
                choice => html`
                        <li>
                            <a
                                @click=${e => this.handleSelectFieldValue(e, choice.value, index)}
                                class="dropdown-item" 
                                href="#">${choice.text}</a>
                        </li>
                        `
            )}
                </ul>
            </div>
            `;
        }
        return html`
            <input 
                style="
                    width: 100%;
                    font-size: 14px;
                    border: none;
                    padding: 5px 10px 5px 10px;
                    border-bottom: 1px solic var(--t-color-dark)"
                @input=${e => this.throttleChange(e.target.value, index)}
                .value=${this.getValue(index)}
                type="text" />
        `;
    }

    booleanInterface(index) {
        return html`
        <div class="form-check form-switch">
            <input 
                @input=${e => this.throttleChange(e.target.value, index)}
                .value=${this.getValue(index)}
                class="form-check-input" 
                type="checkbox" 
                role="switch" 
                checked />
            <label class="form-check-label" >${this.getValue(index)}</label>
        </div>
        `;
    }

    dateTimeInterface(index) {
        return html`
        <input
            @input=${e => this.throttleChange(e.target.value, index)}
            .value=${this.getValue(index)}
            style="max-width: 150px;"
            type="datetime-local"
            value=${(new Date()).toISOString()} />
        `
    }

    dateInterface(index) {
        return html`
        <input
            @input=${e => this.throttleChange(e.target.value, index)}
            .value=${this.getValue(index)}
            style="max-width: 150px;"
            type="date"
            value=${(new Date()).toISOString()} />
        `
    }

    numberInterface(index) {
        if (this.selected_field.meta?.options?.choices?.length) {
            return html`
            <div class="dropdown">
                <button style="font-size: 12px; padding: 5px;" class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ${this.getDisplayValue(index) || 'Select value'}
                </button>
                <ul style="font-size: 12px;" class="dropdown-menu">
                    ${this.selected_field.meta.options.choices.map(
                choice => html`
                        <li>
                            <a
                                @click=${e => this.handleSelectFieldValue(e, parseFloat(choice.value), index)}
                                class="dropdown-item" 
                                href="#">${choice.text}</a>
                        </li>
                        `
            )}
                </ul>
            </div>
            `;
        }
        return html`
        <input
            @input=${e => this.throttleChange(e.target.value, index)}
            .value=${this.getValue(index)}
            style="max-width: 150px;"
            type="number"
            value=${(new Date()).toISOString()} />
        `
    }
}

customElements.define('app-filter-item', AppFilterItem);
export default AppFilterItem;