import { isThisWeek } from "date-fns";

class NotificationRules {
    /**
     * @param {import("directus").ItemsService}
     */
    rule_service;

    /**
     * @param {NotificationRule[]}
     */
    rules;

    /**
     * @param {NotificationRuleMap}
     */
    rule_map;

    /**
     * 
     * @param {import("directus").ItemsService} notificationRuleService
     */
    constructor(client_id, user_id, notificationRuleService) {
        if (!client_id) throw new Error("A client_id is required");
        if (!notificationRuleService) throw new Error("Notification service is required");
        this.client_id = client_id;
        this.user_id = user_id;
        this.rule_service = notificationRuleService;
    }

    /**
     * Load the notification rules set them to a map, then return this instance.
     * @returns {RuleMap}
     */
    async loadRuleMap() {
        let res;
        if (this.client_id && this.user_id) {
            res = await this.rule_service.readByQuery({
                filter: {
                    client_id: {
                        _eq: this.client_id,
                    },
                    _or: [
                        {
                            user_id: {
                                _null: true,
                            },
                        },
                        {
                            user_id: {
                                _eq: this.user_id,
                            }
                        }
                    ]
                }
            });
        } else {
            res = await this.rule_service.readByQuery({
                filter: {
                    client_id: {
                        _eq: this.client_id,
                    },
                    user_id: {
                        _null: true,
                    },
                }
            }); 
        }

        this.rules = res.data;
        this.rule_map = this._createRuleMap(res.data);

        return this.rule_map;
    }

    /**
     * Remove a rule based on the level and delivery method
     * This method detects if we are deleting a user rule or client rule based on the presence of the `user_id` field on this class.
     * @param {Number} level The level number to match on
     * @param {String} delivery_method The delivery method to match on
     * @returns {NotificationRuleMap}
     */
    async removeRule(level, delivery_method) {
        if (this.user_id) {
            await this._removeUserRule(this.client_id, this.user_id, level, delivery_method);
        } else {
            await this._removeClientRule(this.client_id, level, delivery_method);
        }
        return this.loadRuleMap();
    }

    async resetUserRules() {
        if (!this.client_id) throw new Error("no client id was provided");
        if (!this.user_id) throw new Error("no user id was provided");

        const res = await this.rule_service.readByQuery({
            filter: {
                client_id: {
                    _eq: this.client_id,
                },
                user_id: {
                    _eq: this.user_id,
                }
            },
            fields: ["id"]
        });

        if (res?.data?.length < 1) return this.rule_map;

        const ids = res.data.map((i) => i.id);
        await this.rule_service.deleteMany(ids);

        return this.loadRuleMap();
    }

    async addUserRule(level, delivery_method, ignore_delivery_method) {
        if (!this.client_id) throw new Error("no client id was provided");
        if (!this.user_id) throw new Error("no user id was provided");;
        if (isNaN(level) || level < 1 || level > 4) throw new Error("level needs to be an int between 1 and 4");
        if (!delivery_method && typeof delivery_method !== "string") throw new Error("not delivery method was provided");

        await this._removeUserRule(this.client_id, this.user_id, level, delivery_method);

        await this.rule_service.createOne({
            status: "published",
            user_id: this.user_id,
            client_id: this.client_id,
            level,
            delivery_method,
            ignore_delivery_method: !!ignore_delivery_method,
        });

        return this.loadRuleMap();
    }

    async addClientRule(level, delivery_method, ignore_delivery_method, force) {
        if (!this.client_id) throw new Error("no client id was provided");
        if (isNaN(level) || level < 1 || level > 4) throw new Error("level needs to be an int between 1 and 3");
        if (!delivery_method && typeof delivery_method !== "string") throw new Error("not delivery method was provided");

        await this._removeClientRule(this.client_id, level, delivery_method);

        await this.rule_service.createOne({
            status: "published",
            user_id: null,
            client_id: this.client_id,
            level,
            delivery_method,
            ignore_delivery_method: !!ignore_delivery_method,
            ignore_user_id: !!force,
        });

        return this.loadRuleMap();
    }

    /**
     * Convert a list of notifciation rules into a rule map
     * @param {NotificationRule[]} rules The list of rules
     * @returns {NotificationRuleMap}
     */
    _createRuleMap(rules) {
        const rule_map = {
            level_1: {},
            level_2: {},
            level_3: {},
            patient_sms: {},
        };

        for (const rule of rules) {
            let level_str;
            if (rule.level == 4) {
                level_str = "patient_sms";
            } else {
                level_str = "level_" + rule.level;
            }
            const pre_existing = rule_map[level_str][rule.delivery_method]
            if (pre_existing) {
                if (pre_existing.ignore_user_id && !pre_existing.user_id) {
                    continue;
                } if (rule.ignore_user_id) {
                    rule_map[level_str][rule.delivery_method] = rule;
                } else if (pre_existing.user_id) {
                    continue;
                } else {
                    // Otherwise we just set the rule for the level and delivery method.
                    rule_map[level_str][rule.delivery_method] = rule;
                }
            } else {
                rule_map[level_str][rule.delivery_method] = rule;
            }
        }

        return rule_map;
    }

    /**
     * Remove all user rules that match the client, user, level, and delivery method
     * @param {String} client_id The client ID to match on
     * @param {String} user_id The user ID to match on
     * @param {Number} level The level to match on
     * @param {String} delivery_method The delivery method to match on
     * @param {String} delivery_method The delivery method to match on
     * @returns {NotificationRuleMap}
     */
    async _removeUserRule(client_id, user_id, level, delivery_method) {
        if (!client_id) throw new Error("no client id was provided");
        if (!user_id) throw new Error("no user id was provided");
        if (isNaN(level) || level < 1 || level > 4) throw new Error("level needs to be an int between 1 and 4");
        if (!delivery_method && typeof delivery_method !== "string") throw new Error("not delivery method was provided");

        const res = await this.rule_service.readByQuery({
            filter: {
                client_id: {
                    _eq: client_id,
                },
                user_id: {
                    _eq: user_id,
                },
                level: {
                    _eq: level,
                },
                delivery_method: {
                    _eq: delivery_method,
                }
            },
            fields: ["id"],
        });

        if (res?.data?.length < 1) return;

        const ids = res.data.map((i) => i.id);

        await this.rule_service.deleteMany(ids);
    }

    /**
     * Delete all client level rules that match the client, level, and delivery method.
     * @param {String} client_id The client ID to match on
     * @param {Number} level The level to match on
     * @param {String} delivery_method The delivery method to match on
     * @returns {NotificationRuleMap}
     */
    async _removeClientRule(client_id, level, delivery_method) {
        if (!client_id) throw new Error("no client id was provided");
        if (isNaN(level) || level < 1 || level > 4) throw new Error("level needs to be an int between 1 and 4");
        if (!delivery_method && typeof delivery_method !== "string") throw new Error("not delivery method was provided");
     
        const res = await this.rule_service.readByQuery({
            filter: {
                client_id: {
                    _eq: client_id,
                },
                user_id: {
                    _null: true,
                },
                level: {
                    _eq: level,
                },
                delivery_method: {
                    _eq: delivery_method,
                }
            },
            fields: ["id"],
        });

        if (res?.data?.length < 1) return;

        const ids = res.data.map((i) => i.id);

        await this.rule_service.deleteMany(ids);
    }
}

export default NotificationRules;
