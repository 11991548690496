import directus from './lib-directus';

class Cache {
    async getPatient(patient_id, force_refresh) {
        if ((this._patient?.id == patient_id) && (!force_refresh))
            return this._patient;

        let result = await directus.items('patient').readByQuery(
            {
                filter: { id: patient_id },
                fields: [
                    '*',
                    'current_episode_id.*',
                    'client_id.*',
                    'case_manager_id.*',
                    'primary_clinician_id.*',
                ]
            });
        if (!(result.data.length))
            throw new Error("Invalid patient id");

        this._patient = result.data[0];

        //if there's not a current episode id marker on the patient, get the most recent episode
        if (!this._patient.current_episode_id) {
            let episode_result = await directus.items('episode').readByQuery(
                {
                    filter: {
                        patient_id
                    },
                    sort: '-start_date',
                    limit: 1
                }
            );
            if (episode_result.data.length)
                this._patient.current_episode_id = episode_result.data[0];
        }

        return this._patient;
    }

    async getClient(client_id, force_refresh) {
        if (!this._client_cache)
            this._client_cache = {};
        if ((this._client_cache[client_id]) && (!force_refresh))
            return this._client_cache[client_id];

        let client = await directus.items('client').readOne(client_id);
        if (!client)
            return null;
        this._client_cache[client_id] = client;
        return client;
    }

    // In the event the cache was loaded elsewhere.
    setPatient(patient) {
        this._patient = patient;
        return this._patient;
    }


    async getTask(task_id, force_refresh) {
        if ((this._task?.id == task_id) && (!force_refresh))
            return this._task;

        let result = await directus.items('task').readByQuery(
            {
                filter: { id: task_id },
                fields: [
                    '*',
                    'patient_id.*',
                    'client_id.*',
                    'assigned_user_id.*',
                ]
            });
        if (!(result.data.length))
            throw new Error("Invalid task id");

        this._task = result.data[0];

        return this._task;
    }

    async getAlertRules(client_id, force_refresh) {
        if (this._alert_rules && (!force_refresh))
            return this._alert_rules;

        let final_rules = new Map();

        //get the default system rules to run
        let response = await directus.items('alert_rule').readByQuery({
            filter: {
                status: 'published',
                _or: [
                    { "client_id": { _null: true } },
                    { client_id }
                ]
            }
        });
        let rules = response.data;
        let default_rules = rules.filter(rule => !rule.client_id);
        let client_rules = rules.filter(rule => rule.client_id);
        for (let rule of default_rules)
            final_rules.set(rule.name, rule);

        //override default rules with client rules. Also add additional client rules into the final set of rules
        for (let rule of client_rules)
            final_rules.set(rule.name, rule);

        this._alert_rules = final_rules;
        return this._alert_rules;
    }

    async getFormDesign(form_design_id) {
        if (!this._form_design_cache)
            this._form_design_cache = {};

        if (!form_design_id) {
            return null;
        }

        if (this._form_design_cache[form_design_id])
            return this._form_design_cache[form_design_id];

        let form_design = await directus.items('form_design').readOne(form_design_id);
        if (!form_design)
            return null;

        //TODO: set up a FIFO mechanism to store a max number of form designs
        //so we don't blow out memory
        this._form_design_cache[form_design_id] = form_design;
        return form_design;
    }

    async getFormDesigns(form_design_ids) {
        const valid_ids = form_design_ids.filter(id => !!id);
        return await Promise.all(
            valid_ids.map(id => this.getFormDesign(id))
        );
    }

    getCollection(collection_name, force_refresh) {
        if (!this._collection_cache)
            this._collection_cache = {};

        if (!force_refresh)
            if (this._collection_cache[collection_name])
                return this._collection_cache[collection_name];

        if (!this._collection_promises)
            this._collection_promises = {};

        if (this._collection_promises[collection_name])
            return this._collection_promises[collection_name];

        let promise = new Promise(
            async (resolve, reject) => {
                try {
                    let result = await directus.fields.readMany(collection_name);
                    delete this._collection_promises[collection_name];
                    this._collection_cache[collection_name] = {
                        fields: result.data || result
                        //fields: result
                    };
                    resolve(this._collection_cache[collection_name]);
                }
                catch (err) {
                    reject(err);
                }
            }
        )
        this._collection_promises[collection_name] = promise;
        return promise;
    }

    async getFormDesignForSurvey(survey_id) {
        if (!this._form_design_survey_cache)
            this._form_design_survey_cache = {};

        if (this._form_design_survey_cache[survey_id])
            return this._form_design_survey_cache[survey_id];

        let result = await directus.items('task').readByQuery(
            {
                filter: { survey_id },
                fields: [
                    'form_design_id'
                ]
            }
        );

        if (result.data?.length == 0)
            return null;

        let task = result.data[0];
        this._form_design_survey_cache[survey_id] = task.form_design_id;
        return this._form_design_survey_cache[survey_id];
    }
}

const cache = new Cache();
export default cache;