import { html, render } from "lit";
import directus from '../lib/lib-directus';
import "../components/patient/app-patient-card";
import "../components/patient/app-patient-filters";
//import AppPatientManualEntryModal from "../components/patient/app-patient-manual-entry-modal";
import AppPatientCareTeamModal from "../components/patient/app-patient-care-team-modal";
import "../components/app-toggle-switch";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"
import { navigate } from "../lib/lib-router";
import { adddirectusClientFilter } from "../lib/lib-user";
import ApplicationState from 'applicationstate';
import { format, parseISO } from 'date-fns';
import AppCalendarViewModal from "../components/availability/app-calendar-view-modal";
import AppConfirmationModal from "../components/app-confirmation-modal";
import AppActivateScheduleModal from "../components/app-activate-schedule-modal";
import AppWarningModal from "../components/app-warning-modal";
import { getCurrentClient, getCurrentClientId } from "../lib/lib-user";
import AppOvalModal from "../components/app-oval-modal";
import ClientSettings from "../lib/lib-client-settings";
import { cs } from "date-fns/locale";

window.luxon = { DateTime };

const card_height = 112;
const patient_count_height = 30;

// TODO: exported?

const sort_label_to_column = {
    "LAST SURVEY DATE": "last_survey_date",
    "BIRTH DATE": "birth_date",
    "FIRST NAME": "first_name",
    "LAST NAME": "last_name",
    "MRN": "mrn",
    "SOC DATE": "current_episode_start",
    "STATUS": "status",
};

/**
 * A grid of patient cards, supports search, sort, and filter.
 */
export default class ScenePatients extends HTMLElement {

    get patient() {
        return this._patient;
    }

    set patient(value) {
        this._patient = value;
        this.expandPatient();
        this.render();
    }

    get has_changes() {
        return this._has_changes;
    }

    set has_changes(value) {
        this._has_changes = !!value;
        this.render();
    }

    get editable() {
        return this._editable;
    }

    set editable(value) {
        this._editable = !!value;
        this.render();
    }

    get clinicians() {
        return this._clinicians;
    }

    set clinicians(values) {
        this._clinicians = values;
    }

    get case_managers() {
        return this._case_managers;
    }

    set case_managers(values) {
        this._case_managers = values;
    }

    set care_team(values) {
        this._care_team = values;
        this.render();
    }

    get care_team() {
        return this._care_team;
    }
    set view(value) {
        if (value !== this._view) {
            this._view = value;
            this.render(); // Re-render when view changes
        }
    }

    // Optional getter
    get view() {
        return this._view;
    }

    constructor() {
        super();
        this._has_changes = false;
        this._editable = false;
        this._clinicians = null;
        this._care_team = null;
        this.changes = {};
        this.patient_access_changes = {};
        // filters
        this._view = 'list';
        this._search_term = null;
        this._onlyActiveEnabled = true;
        this._noScheduleActive = false;
        // This should be an array of objects with {column: string, direction: 'asc'|'desc'}
        this._sort_fields = [{ column: "last_survey_date", direction: "desc" }];

        // pagination
        this._current_page = 1;
        //this._filtered_count = 0;
        this._page_size = null;
        // TODO: loading state during initial render / fetch
        this._loading_patients = true;
        this.patients = [];

        this._appName = ApplicationState.get('app.name');
        this.boundResizeHandler = this.handleWindowResize.bind(this);
        this._hasRecentCommunication = false; // New property to track the filter state
        this.filters = {};
    }

    /**
     * Queries the server for patients based on the current filters.
     */
    formatDate(dateString) {
        if (!dateString) return 'N/A';
        return format(new Date(dateString), 'MM/dd/yyyy');
    }

    async fetchPatients() {
        if (!this._page_size) {
            return;
        }
        this._loading_patients = true;
        const query_filters = {
            fields: [
                "id", "last_communication_date", "last_survey_date", "first_name", "last_name", "mrn", "birth_date", "status", "current_episode_start", "current_episode_end", "current_soc", "client_id",
                //"access.user_id.first_name", "access.user_id.last_name",
                "current_episode_id.id", "current_episode_id.ext_referral", "current_episode_id.survey_schedule_id",
                "current_episode_id.diagnosis_description", "current_episode_id.diagnosis_icd10_code",
                "current_episode_id.survey_schedule_id.name",
                //"tasks.scheduled_date", "tasks.total_attempts, tasks.title, tasks.status",
                //"patient_communication.date_created",
                //"patient_communication.patient_id",
            ],
            sort: this._sort_fields.map(field => `${field.direction === 'desc' ? '-' : ''}${field.column}`).join(','),
            meta: "*",
            limit: this._page_size,
            page: this._current_page,
            filter: adddirectusClientFilter({})
        };

        if (this._onlyActiveEnabled) {
            query_filters.filter.current_episode_id = {
                survey_schedule_id: { _nnull: true }
            };
        } else if (this._noScheduleActive) {
            query_filters.filter.current_episode_id = {
                survey_schedule_id: { _null: true }
            };
        } else if (this._allEpisodesActive) {
            // Remove any filter related to survey_schedule_id
            delete query_filters.filter.current_episode_id;
        }



        // Apply date filter
        if (this.filters && this.filters.start_date_range) {
            const [start, end] = this.filters.start_date_range.split(',');
            if (start && end) {
                query_filters.filter._and = query_filters.filter._and || [];
                query_filters.filter._and.push({
                    current_episode_start: {
                        _between: [start, end]  // Use _between with an array of [start, end]
                    }
                });
            }

        }

        if (this._search_term) {
            query_filters.search = this._search_term;
        }

        //console.log('Query filters:', JSON.stringify(query_filters, null, 2));

        try {
            const patients_result = await directus.items("patient").readByQuery(query_filters);

            // Post-process the results to filter based on communication date
            if (this._hasRecentCommunication) {
                patients_result.data = patients_result.data.filter(patient => {
                    if (patient.current_episode_start && patient.patient_communication && patient.patient_communication.length > 0) {
                        const episodeStart = new Date(patient.current_episode_start);
                        return patient.patient_communication.some(comm => new Date(comm.date_created) > episodeStart);
                    }
                    return false;
                });
            }

            this.patients = this._current_page === 1 ? patients_result.data : this.patients.concat(patients_result.data);
            if (this._current_page === 1) {
                document.querySelector("#patients-scroll-container").scrollTop = 0;
            }
            this._filtered_count = patients_result.meta.filter_count;
            //console.log("fetchPatients", this._filtered_count);
            this._loading_patients = false;
            this._query = query_filters;

            if (this._view == "list") {
                this.initTable();
            }

            this.render();
        } catch (error) {
            console.error("Error fetching patients:", error);
            this._loading_patients = false;
            // Optionally, you can add some error handling UI here
        }
    }
    /**
     * Render the patient count across all pages.
     * @returns lit html
     */
    renderPatientsCount() {
        const { _filtered_count } = this;

    }

    connectedCallback() {
        this.addEventListener('filterchange', (e) => {
            this.filters = e.detail.filters;
            this._current_page = 1;
            this.fetchPatients();
        });

        this.addEventListener('scheduletoggle', (e) => {
            this._onlyActiveEnabled = e.detail.hasSchedule;
            this._noScheduleActive = e.detail.noSchedule;
            this._current_page = 1;
            this.fetchPatients();
        });



        this.addEventListener("viewchange", (e) => {
            this.view = e.detail.view;
            if (this.view == "list") {
                this.initTable();
            };
            console.log("viewchange listener", this.view);
        });
        this.addEventListener("datefilterchange", (e) => this.handleDateFilterChange(e));
        const last_date = this.patients.last_survey_date;
        console.log("CC View", this.view);
        this.template = () => {
            let content;
            switch (this.view) {
                case "card":
                    content = this.renderCardView();
                    //this.fetchPatients();
                    break;
                case "list":
                default:
                    //this.fetchPatients();
                    content = this.renderListView();

                    //this.initTable();
                    //this.fetchPatients();
                    break;
            }

            const initial_nav_height = document.body.clientWidth < 768 ? 133 : 188;
            const nav_height = document.querySelector("scene-patients nav")?.clientHeight || initial_nav_height;
            //console.log("nav height", nav_height);


            return html`
        <style>
            #patients-scroll-container {
                max-height: calc(100% - ${nav_height + patient_count_height}px);
                overflow-y: auto;
            }
        </style>
        <div class="container-fluid" style="">
            <div class="row" style="padding: 0 32px;">
                <app-patient-filters
                
                    .count=${this._filtered_count}
                    .sort_fields=${this._sort_fields}
                    .active=${this._onlyActiveEnabled}
                    .hasRecentCommunication=${this._hasRecentCommunication}
                    .view=${this.view}
                    @patientsearch=${(e) => this.handleSearch(e)}
                    @activetoggle=${(e) => this.handleActiveToggle(e)}
                    @recentcommunicationtoggle=${(e) => this.handleRecentCommunicationToggle(e)}
                    @tagifyclick=${(e) => this.handleTagifyClick(e)}
                    @tagifyadd=${(e) => this.handleSortColumnAdd(e)}
                    @tagifyremove=${(e) => this.handleSortColumnRemove(e)}
                ></app-patient-filters>
                
            </div>
            ${this.renderPatientsCount()}
               
                 ${content}
                             
        </div>
    `;


        };

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });

        this.render();

        // Apply initial filters
        this.filters = {
            start_date_range: this.defaultFilterDates()
        };
        this.fetchPatients();
        this.init();
        // Schedule updatePageSize for the next frame to ensure DOM is ready
        requestAnimationFrame(() => this.updatePageSize());
        window.addEventListener("resize", this.boundResizeHandler);
    }

    renderCardView() {

        return html`
        <div class="row g-0 px-3 pt-2" id="patients-scroll-container" @scroll=${(e) => this.handleScroll(e)}>
        <div class="row g-0" id="patients-cards">
            ${this.patients.map(patient => html`
                <div class="col-sm-12 col-md-6 col-lg-4 p-card-cont">
                    <app-patient-card
                        @click=${() => navigate(`patients/${patient.id}/checkpoints`)}
                        .patient=${patient}
                        style="cursor: pointer;">
                    </app-patient-card>
                </div>
            `)}
        </div>
        </div>
    `;
    }

    renderListView() {
        return html`
             <div class="row px-3 pt-2" id="patients-scroll-container" style="overflow:hidden;">
            <div class="mod-base" id="patients-table" ></div> 
            </div>
            `

    }
    async getAuthToken() {
        if (!this.cachedToken) {
            this.cachedToken = await directus.auth.token; // retrieve token
        }
        return this.cachedToken;
    }
    async initTable() {

        const table_columns = [
            {
                title: "Name",
                formatter: function (cell) {
                    const data = cell.getRow().getData();
                    return `<strong>${data.first_name} ${data.last_name}</strong> - ${data.mrn}`;
                },
                cellClick: function (e, cell) {
                    navigate(`patients/${cell.getRow().getData().id}/activity`);
                },
                sorter: "string",
                sorterParams: {
                    field: "last_name",
                },
                width: "15%",
                //widthGrow: 2,

            },
            //{
            //    title: "MRN",
            //    field: "mrn",
            //    width: "10%",
            //    cellClick: function (e, cell) {
            //        navigate(`patients/${cell.getRow().getData().id}/checkpoints`);
            //    }
            //},
            //{
            //    title: "Birth Date",
            //    field: "birth_date",
            //    width: "10%",
            //    formatter: "datetime",
            //    formatterParams: {
            //        inputFormat: "yyyy-MM-dd",
            //        outputFormat: "MM/dd/yy",
            //        invalidPlaceholder: "(invalid date)",
            //        alignEmptyValues: "bottom",
            //    },
            //    cellClick: function (e, cell) {
            //        navigate(`patients/${cell.getRow().getData().id}/checkpoints`);
            //    }
            //},
            //{ title: "ID", field: "id" },
            //{
            //    title: "Last Comm",
            //    field: "last_communication_date",
            //    formatter: "datetime",
            //    formatterParams: {
            //        inputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
            //        outputFormat: "MM/dd/yyyy",
            //        invalidPlaceholder: "(invalid date)",
            //        alignEmptyValues: "bottom",
            //    },
            //    width: "10%",
            //    cellClick: function (e, cell) {
            //        navigate(`patients/${cell.getRow().getData().id}/checkpoints`);
            //    }
            //},
            //{
            //    title: "Last Survey",
            //    field: "last_survey_date",
            //    formatter: "datetime",
            //    formatterParams: {
            //        inputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
            //        outputFormat: "MM/dd/yyyy",
            //        invalidPlaceholder: "(invalid date)",
            //        alignEmptyValues: "bottom",
            //    },
            //    width: "10%",
            //    cellClick: function (e, cell) {
            //        navigate(`patients/${cell.getRow().getData().id}/checkpoints`);
            //    }
            //},
            //{ title: "Last Name", field: "last_name" },
            //{
            //    title: "Status",
            //    field: "status",
            //    width: "10%",
            //    cellClick: function (e, cell) {
            //        navigate(`patients/${cell.getRow().getData().id}/checkpoints`);
            //    }
            //},
            {
                title: "Episode",
                field: "current_episode_start",
                sorter: "datetime",
                hozAlign: "center",
                sorterParams: {
                    format: "yyyy-MM-dd",
                    alignEmptyValues: "bottom",
                },
                formatter: function (cell, formatterParams) {
                    try {
                        const rowData = cell.getRow().getData();
                        const start = format(parseISO(rowData.current_episode_start), "MM/dd/yyyy");
                        const end = format(parseISO(rowData.current_episode_end), "MM/dd/yyyy");
                        return start + " - " + end;
                    } catch (error) {
                        return ""
                    }
                }
            },
            {
                title: "Primary DX",
                field: "current_episode_id.diagnosis_description",
                width: "25%",
                widthGrow: 2,
            },
            {
                title: "ICD10",
                field: "current_episode_id.diagnosis_icd10_code",
                width: "10%",
            },
            {
                title: "Ext Refferal",
                field: "current_episode_id.ext_referral",
                //width: "10%",
                widthGrow: 1,
            },
            {
                title: "Schedule",
                field: "current_episode_id.survey_schedule_id",
                width: "15%",
                formatter: function (cell, formatterParams) {
                    // Check if the field has a value and return HTML with span
                    return cell.getValue() ? `
                    <div class="has-schedule"><span id="yess"><strong>
                    ${cell.getData().current_episode_id.survey_schedule_id.name}</strong></span>` :
                        '<div class="has-schedule"><span id="no">No Schedule Found</span></div>';
                },
                //cellClick: function (e, cell) {
                //    const patientData = cell.getRow().getData();
                //    if (!patientData.current_episode_id.survey_schedule_id) {
                //        this.handleActivateSchedule(patientData);
                //    } else {
                //        this.showWarningModal("Episode already appears to have a schedule.");
                //    }
                //}.bind(this)


            },


            //{
            //    title: "Current SOC",
            //    field: "current_soc",
            //    width: "10%",
            //    widthShrink: 1,
            //    formatter: "datetime",
            //    formatterParams: {
            //        inputFormat: "yyyy-MM-dd",
            //        outputFormat: "MM/dd/yy",
            //        invalidPlaceholder: "(invalid date)",
            //        alignEmptyValues: "bottom",
            //    },
            //    cellClick: function (e, cell) {
            //        navigate(`patients/${cell.getRow().getData().id}/checkpoints`);
            //    }
            //},
            //{ title: "Total Attempts", field: "tasks.total_attempts", width: "10%"},
            //{ title: "Last Checkpoint", field: "tasks.scheduled_date", width: "10%",
            {
                hozAlign: "left",
                cssClass: "patient-actions",
                tooltip: false,
                formatter: function (cell) {

                    const rowData = cell.getRow().getData();
                    const hasSchedule = rowData.current_episode_id.survey_schedule_id;
                    //console.log("hasSchedule", hasSchedule);

                    return `
                        <span class="material-symbols-outlined" style="font-size: 23px; cursor: pointer; margin-right: 10px;">id_card</span>
                        <span class="material-symbols-outlined" style="font-size: 23px; cursor: pointer; margin-right: 10px;">group_add</span>
                        <span class="material-symbols-outlined" style="font-size: 23px; cursor: pointer; margin-right: 10px;">calendar_month</span>
                        
                        
                        ${!hasSchedule ? '<span class="material-symbols-outlined" style="font-size: 23px; cursor: pointer; color: #af4f58;">pending_actions</span>' : '<span class="material-symbols-outlined" style="color: #595959; font-size: 23px; cursor: pointer;">pending_actions</span>'} 
                    `;
                },
                cellClick: (e, cell) => {
                    const patientData = cell.getRow().getData();
                    if (e.target.textContent.trim() === 'group_add') {
                        this.handleEditCareteam(patientData.id);
                    } else if (e.target.textContent.trim() === 'calendar_month') {
                        this.showCalendar(patientData);
                    } else if (e.target.textContent.trim() === 'pending_actions') {
                        this.handleActivateSchedule(patientData);
                    }
                },
                width: 170,
                hozAlign: "center",
                headerSort: false,
                //tooltip: "Edit Care Team / View Calendar",
            }
        ]
        //await this.fetchPatients();
        const token = await this.getAuthToken();
        const query = this._query;
        //const fieldNames = this._query.fields;
        let handleEdit = this.handleEditCareteam;
        //console.log("initTable Count", this._filtered_count);
        //console.log("initTable Paatients", this.patients);

        this.data_table = new Tabulator(this.querySelector("#patients-table"), {
            ajaxURL: "/items/patient",
            ajaxConfig: { headers: { 'Authorization': `Bearer ${token}` } },
            ajaxFiltering: true,
            ajaxSorting: true,
            progressiveLoad: "scroll",
            progressiveLoadScrollMargin: 200,
            dataSendParams: { "size": "limit" },
            //pagination:"true", 
            height: "auto",
            width: "100%",
            //paginationMode:"remote", 
            //paginationSize: 24,
            paginationCounter: "rows",
            alignEmptyValues: "bottom",
            //renderHorizontal:"virtual",
            layout: "fitColumns",
            //autoResize:false,
            layoutColumnsOnNewData: true,
            sortMode: "remote",
            filterMode: "remote",
            //dataTree: true,
            //dataTreeStartExpanded: false,  
            //dataTreeChildField: "tasks", 


            ajaxURLGenerator: function (url, config, params) {
                let queryParams = [];

                // Handle fields from query
                if (query?.fields?.length) {
                    queryParams.push(`fields[]=${query.fields.join('&fields[]=')}`);
                }

                // Combine external and internal sorting
                let sortParams = [];
                if (query?.sort) {
                    sortParams.push(query.sort);
                }
                if (params?.sort && params.sort.length) {
                    params.sort.forEach(sorter => {
                        if (sorter.field) {
                            let sortField = sorter.field;
                            // custom handling for combined fields 
                            // TODO: make this more generic and make it work
                            if (sortField === 'name') {
                                sortField = 'last_name'; // Adjust according to your needs
                            }
                            sortParams.push(`${sorter.dir === 'desc' ? '-' : ''}${sortField}`);
                        }
                    });
                }
                if (sortParams.length) {
                    queryParams.push(`sort=${sortParams.join(',')}`);
                }

                // Combine external and internal filters
                if (query?.filter) {
                    const addFilterParam = (prefix, obj) => {
                        Object.entries(obj).forEach(([key, value]) => {
                            if (typeof value === 'object' && value !== null) {
                                addFilterParam(`${prefix}[${key}]`, value);
                            } else {
                                queryParams.push(`${prefix}[${key}]=${encodeURIComponent(value)}`);
                            }
                        });
                    };
                    addFilterParam('filter', query.filter);
                }
                if (params?.filter && params.filter.length) {
                    params.filter.forEach(filter => {
                        queryParams.push(`filter[${filter.field}]=${filter.value}`);
                    });
                }

                if (query?.search) {
                    queryParams.push(`search=${encodeURIComponent(query.search)}`);
                }

                // Pagination parameters
                queryParams.push(`page=${params?.page || 1}`);
                queryParams.push(`limit=${params?.pageSize || 10}`);

                // Include meta data for filter count, etc.
                queryParams.push('meta=filter_count');

                // Construct the final URL
                let finalURL = `${url}?${queryParams.join('&')}`;
                return finalURL;
            },

            ajaxResponse: function (url, params, response) {
                const last_page = Math.ceil(response.meta.filter_count / params.limit);
                //console.log("last_page", last_page);
                //console.log("response", response);
                //console.log("params", params);
                return {
                    data: response.data,
                    last_page,
                };
            }.bind(this), // Bind it or else "this" will be undefined

            columns: table_columns
        });
        this.setTableHeight();
        //this.data_table.redraw(true); 
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
        this.patients.forEach(patient => {

        });
        //this.initTable();
    }

    /**
     * Updates this.page_size based on window dimensions to support infinite scroll
     */
    updatePageSize() {
        const sceneElement = this.querySelector(".container-fluid");
        const navElement = this.querySelector("nav");

        if (!sceneElement || !navElement) {
            // Elements not ready yet, schedule an update for the next frame
            requestAnimationFrame(() => this.updatePageSize());
            return;
        }

        const scene_height = sceneElement.clientHeight;
        const nav_height = navElement.clientHeight;

        let column_count = 3;
        if (document.body.clientWidth < 768) {
            column_count = 1;
        } else if (document.body.clientWidth < 992) {
            column_count = 2;
        }

        const scroll_container_max_height = scene_height - patient_count_height - nav_height;
        this._column_count = column_count;
        this._page_size = Math.ceil((scroll_container_max_height * column_count) / card_height) + 1;
        this._current_page = 1;
        this.fetchPatients();
    }
    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 20);
    }
    async handleConfirmClick() {
        try {
            if (Object.keys(this.patient_access_changes).length > 0) {
                const to_remove = [];
                const to_add = [];
                for (const id in this.patient_access_changes) {
                    const action = this.patient_access_changes[id];

                    switch (action) {
                        case "remove":
                            to_remove.push(id);
                            break;
                        case "add":
                            const access_record = this.patient_access.find((i) => i.user_id === id);
                            // It already exists, and we've staged and unstaged a remove
                            if (access_record) break;
                            to_add.push({
                                patient_id: this.patient.id,
                                user_id: id,
                            });
                            break;
                    }
                }

                if (to_remove.length) {
                    await directus.items("patient_access").deleteMany(to_remove);
                    for (const id of to_remove) {
                        const pa = this.patient_access.find((i) => i.id === id);
                        if (
                            this.patient.case_manager_id === pa?.user_id?.id ||
                            this.patient.case_manager_id?.id === pa?.user_id?.id
                        ) {
                            this.changes.case_manager_id = null;
                        }
                        if (
                            this.patient.primary_clinician_id === pa?.user_id.id ||
                            this.patient.primary_clinician_id?.id === pa?.user_id.id
                        ) {
                            this.changes.primary_clinician_id = null;
                        }
                    }
                }

                if (to_add.length)
                    await directus.items("patient_access").createMany(to_add);
            }

            // If we have any patient changes, save those.
            if (Object.keys(this.changes).length > 0) {
                if (this.changes?.primary_clinician_id?.id)
                    this.changes.primary_clinician_id = this.changes.primary_clinician_id.id;
                if (this.changes?.case_manager_id?.id)
                    this.changes.case_manager_id = this.changes.case_manager_id.id;

                const res = await directus.items("patient").updateOne(this.patient.id, this.changes, {
                    fields: [
                        '*',
                        'current_episode_id.*',
                        'client_id.*',
                    ]
                });

                this.changes = {};
            }
        } catch (err) {
            console.error(err);
            this.error_message = err.message;
            return;
        }

        // reload the patient to make sure that all
        this.patient = await Cache.getPatient(this.patient.id, true);
        this.dismiss({
            should_update: this.editable,
            patient: this.patient,
        });
    }
    ////////////////////////////////////////////////////////////////////////////////////////////

    async handleEditCareteam(patientId) {
        console.log("handleEditCareteam", patientId);

        // Fetch the full patient object based on patientId
        const patientData = await directus.items("patient").readOne(patientId);

        const modal = new AppPatientCareTeamModal();
        modal.patient = patientData;
        await modal.showModal();
        await modal.onDidDismiss();
        //this.patient = patient;
    }

    async handleCareTeamAdd() {
        const id_to_add = this.querySelector("#potential-care-team-member").value;
        if (!id_to_add) return;

        this.care_team.push(this.clinicians.find((i) => i.id === id_to_add));
        this.patient_access_changes[id_to_add] = "add";
        this.hasChanges();
    }

    /**
     * Removes the care team member
     * @param member
     * @returns {Promise<void>}
     */
    async handleRemoveCareTeamMember(e, member) {
        e.stopPropagation();
        const to_remove = this.patient_access.find((i) => member.id === i.user_id?.id);
        if (!to_remove) return;
        this.patient_access_changes[to_remove.id] = "remove";
        this.care_team = this.care_team.filter((i) => i.id !== to_remove.user_id?.id);
        this.hasChanges();
    }


    /**
     * Updates only active toggle and queries the server.
     * @param {Event} e change event from 'only active patients' checkbox
     */
    handleActiveToggle(e) {

        if (this._onlyActiveEnabled !== undefined) {
            this._onlyActiveEnabled = e.detail;
        }


        this._currentPage = 1;
        this.fetchPatients();
    }

    async handlePatientAddClick(e) {
        e.stopPropagation();
        e.preventDefault();
        // load patient-add modal
        const appPatientManualEntryModal = new AppPatientManualEntryModal();
        await appPatientManualEntryModal.showModal();
    }

    async showCalendar(patient) {
        const modal = new AppCalendarViewModal(patient);
        await modal.showModal();
    }

    /**
     * If the number of columns changes based on window width,
     * recalculate page size for infinite scroll and refetch patients.
     * @todo handle height changes
     * @param {Event} e window resize event
     */
    handleWindowResize(_e) {
        const { clientWidth } = document.body;
        if (
            (this._column_count === 1 && clientWidth >= 768) ||
            (this._column_count === 2 && clientWidth >= 992) ||
            (this._column_count === 2 && clientWidth < 768) ||
            (this._column_count === 3 && clientWidth < 992)
        ) {
            this.updatePageSize();
        }
    }

    /**
     * Enables infinite scroll / pagination on the scroll container.
     * @param {Event} e scroll event
     */
    handleScroll(e) {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const all_pages_fetched = this.patients.length === this._filtered_count;
        if (!all_pages_fetched && !this._loading_patients && scrollHeight - clientHeight - scrollTop < 300) {
            this._current_page++;
            this.fetchPatients();
        }
    }

    /**
     * Debounces a patient search query.
     * @param {Event} e input event from search box
     */
    handleSearch(e) {
        e.stopPropagation();
        const search_term = e.detail.target.value;
        if (search_term && search_term.length < 3) return;
        clearTimeout(this._debounce);
        this._debounce = setTimeout(() => {
            this._search_term = search_term;
            this._current_page = 1;
            this.fetchPatients();
            //this.initTable();
        }, 500);
    }

    /**
     * Appends a sort column with default direction asc and re-fetches.
     * @param {Event} e tagify 'add' event
     */
    handleSortColumnAdd(e) {
        const { data: tag_data } = e.detail;

        this._sort_fields.push({
            column: sort_label_to_column[tag_data.value],
            direction: "asc",
        });
        this._current_page = 1;
        this.fetchPatients();
    }

    /**
     * Removes the selected sort column and re-fetches.
     * @param {Event} e tagify 'remove' event
     */
    handleSortColumnRemove(e) {
        const { data: tag_data } = e.detail;
        const index_to_remove = this._sort_fields.findIndex(
            (field) => field.column === sort_label_to_column[tag_data.value]
        );
        this._sort_fields.splice(index_to_remove, 1);
        this._current_page = 1;
        this.fetchPatients();
    }

    /**
     * On clicking a sort tag/chip, reverse the sort direction for the given field and refetch patients.
     * @param {CustomEvent} e tagify 'click' event from <app-patient-filters>
     */
    handleTagifyClick(e) {
        const { data: tag_data } = e.detail;
        e.stopPropagation();
        // change sort direction
        const sort_field = this._sort_fields.find((field) => field.column === sort_label_to_column[tag_data.value]);
        sort_field.direction = sort_field.direction === "asc" ? "desc" : "asc";
        this._current_page = 1;
        this.fetchPatients();
    }

    // Add a new method to handle the toggle
    handleRecentCommunicationToggle(e) {
        this._hasRecentCommunication = e.detail;
        this._current_page = 1;
        this.fetchPatients();
    }

    handleDateFilterChange(e) {
        const { start, end } = e.detail;
        this.filters = this.filters || {};
        // Ensure we're using the correct date format (YYYY-MM-DD)
        this.filters.start_date_range = `${start.toISOString().split('T')[0]},${end.toISOString().split('T')[0]}`;
        this._current_page = 1;
        this.fetchPatients();
    }

    // Add this method to match the one in app-patient-filters.js
    defaultFilterDates() {
        const curDate = new Date();
        const thirtyDaysAgo = new Date(curDate);
        thirtyDaysAgo.setDate(curDate.getDate() - 30);

        const fromDate = thirtyDaysAgo.toISOString().split('T')[0];
        const toDate = curDate.toISOString().split('T')[0];

        return `${fromDate},${toDate}`;
    }

    /**
 * Confirms correct selections for action and executes the action.
 * @param {String} action_name 
 */
    handleActionClick(action_name) {
        //switch (action_name) {

        // case "Remove For This Episode":
        //     // Check if any selected episodes have active tasks
        //     if (this.selected_episodes.find(episode => episode.tasks !== "")) {
        //         this.showWarningModal("Episode has active surveys and cannot be removed.");
        //     } else {
        //         this.handleDeclineApproval("Approval Declined");
        //     }
        //     break;
        //
        // case "Assign Schedule":
        // Check if any selected episodes already have a schedule assigned
        if (this.selected_episodes.find(episode => episode.survey_schedule_id !== null)) {
            this.showWarningModal("Episode already appears to have a schedule.");
        } else {
            // Modify the message to include more text and a button if necessary
            this.handleActivate('Success');
        }
        // break;
    }

    /**
    * Warns the user if incorrect patient selections are made for an action.
    * @param {String} modal_text 
    */
    showWarningModal(modal_text) {
        const warning_modal = new AppWarningModal();
        warning_modal.modal_text = modal_text;
        warning_modal.showModal();

        this.refreshTableData();
    }

    async init() {
        const current_client_id = getCurrentClientId();

        if (current_client_id) {
            const client_settings = await ClientSettings.getSettings(current_client_id);

            if (client_settings?.schedule?.default_sms_time?.value) {
                this.default_sms_time = client_settings.schedule.default_sms_time.value
            }
            if (client_settings?.company?.default_timezone?.value) {
                this.default_timezone = client_settings.company.default_timezone.value
            }
            this.render();
        }
    }

    handleSelect(episodes) {
        console.log("handleSelect", episodes);
        this.selected_episodes = episodes;
        this.selected_patients = episodes.map(episode => ({
            id: episode.id,
            first_name: episode.first_name,
            last_name: episode.last_name,
            client_id: episode.client_id,
            episode_id: episode.current_episode_id
        }));
    }

    async handleActivateSchedule(patientData) {
        const episode_obj = {
            id: patientData.id,
            first_name: patientData.first_name,
            last_name: patientData.last_name,
            client_id: patientData.client_id,
            episode_id: patientData.current_episode_id.id
        };
        console.log("handleActivate", patientData);

        console.log("handleActivate", episode_obj);

        if (!patientData.current_episode_id.survey_schedule_id) {
            const activation_modal = new AppActivateScheduleModal();
            activation_modal.patients = [episode_obj]; // Wrap in an array
            activation_modal.default_sms_time = this.default_sms_time;
            activation_modal.default_timezone = this.default_timezone;
            await activation_modal.showModal();
            const modal_result = await activation_modal.onDidDismiss();

            if (modal_result?.confirmed) {
                this.showConfirmationModal('Schedule successfully assigned');
                this.refreshTableData();
            }
        } else {
            this.showWarningModal("Patient already has a schedule assigned.");
        }
    }
    showConfirmationModal(message) {
        const confirmation_modal = new AppOvalModal();
        confirmation_modal.modalText = message; // Set the modalText property

        confirmation_modal.showModal(); // Make sure this method is properly invoking the Bootstrap modal
        this.refreshTableData();
    }



}

customElements.define("scene-patients", ScenePatients);