import { html, render } from "lit";

/**
 * Generic styled button for the client app.
 * @param {string} text button text
 * @param {boolean} filled default=true, if false applies outlined button styles
 * @param {boolean} is_destructive default=false, if true applies red destructive button styles
 */
export default class AppButton extends HTMLElement {
    constructor() {
        super();
        this._text = null;
        this._filled = true;
        this._is_destructive = false;
    }

    get text() {
        return this._text;
    }

    set text(value) {
        this._text = value;
        this.render();
    }

    get filled() {
        return this._filled;
    }

    set filled(value) {
        this._filled = value;
        this.render();
    }

    get is_destructive() {
        return this._is_destructive;
    }

    set is_destructive(value) {
        this._is_destructive = value;
        this.render();
    }

    connectedCallback() {
        this.setAttribute("role", "button");

        this.template = () => {
            const button_id = `app-button-${this.text.replace(/ +/g, "").toLowerCase()}`
            const primary_color = this._is_destructive ? 'var(--t-color-danger)' : 'var(--t-color-primary)';
            const darkened_color = this._is_destructive ? 'var(--t-color-danger-darkened)' : 'var(--t-color-primary-darkened)';
            return html`
                <style>
                    #${button_id}.app-button {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 20px;
                        height: 32px;
                        padding: 6px 12px;
                        border: 1px solid ${primary_color};
                        border-radius: 2px;
                        background-color: ${primary_color};
                        color: var(--t-color-white);
                    }
                    #${button_id}.app-button.app-button--outlined {
                        background-color: var(--t-color-white);
                        color: ${primary_color};
                    }
                    #${button_id}.app-button:hover {
                        background-color: ${darkened_color};
                        border-color: ${darkened_color};
                    }
                    #${button_id}.app-button.app-button--outlined:hover {
                        background-color: ${primary_color};
                        color: var(--t-color-white);
                    }
                </style>
                <button 
                    id=${button_id} 
                    class="app-button ${this.filled ? "" : "app-button--outlined"}"
                >
                    ${this.text}
                </span>
            `;
        };

        Object.assign(this.style, {});
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-button", AppButton);
