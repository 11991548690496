import { html, render } from "lit";
import { Chart, BarController, BarElement, LinearScale } from "chart.js";
Chart.register(BarController, BarElement, LinearScale);
import "../app-button";

/**
 * A styled chart.js bar graph for the patient dashboard with customizable labels and data.
 *
 * @param {string} title the main graph heading
 * @param {string} subtitle graph subheading, typically a survey question
 * @param {string|TemplateResult} graph_x_label label for graph x-axis, supports a lit html`` template
 * @param {DashboardGraphDataItem[]} data formatted data for this component's chart.js config.
 * @fires AppDashboardMeasures#weeklytoggle when the daily/weekly switch is toggled
 */
export default class AppDashboardGraph extends HTMLElement {
  constructor() {
    super();
    this._title = "";
    this._subtitle = "";
    this._graph_x_label = "";
    this._data = [];
    this._selected_bar_label = null;

    window.matchMedia("(max-width: 1200px)").onchange =
      this.updateChartOrientation.bind(this);
  }

  get data() {
    return this._data;
  }

  set data(val) {
    this._data = val;
    this.render();
  }

  get title() {
    return this._title;
  }

  set title(val) {
    this._title = val;
    this.render();
  }

  get subtitle() {
    return this._subtitle;
  }

  set subtitle(val) {
    this._subtitle = val;
    this.render();
  }

  get graph_x_label() {
    return this._graph_x_label;
  }

  set graph_x_label(val) {
    this._graph_x_label = val;
    this.render();
  }

  get selected_bar_label() {
    return this._selected_bar_label;
  }

  set selected_bar_label(val) {
    if (val !== this._selected_bar_label) {
      this._selected_bar_label = val;
      this.render();
    }
  }

  /**
   * Generates id attribute for chart.js <canvas> based on this._title
   * @returns {string}
   */
  getChartCanvasID() {
    return this._title.replace(/ +/g, "").toLowerCase();
  }

  /**
   * Creates or updates a chartjs instance bound to the <canvas> with latest this._data
   */
  renderChart() {
    if (!this._chart) {
      const chartjsContext = document
        .getElementById(this.getChartCanvasID())
        .getContext("2d");
      this._chart = new Chart(chartjsContext, chartjs_config);
      var red_green_gradient = chartjsContext.createLinearGradient(
        0,
        0,
        0,
        300
      );
      red_green_gradient.addColorStop(0, "#03A696");
      red_green_gradient.addColorStop(1, "#353658");
      // configure dynamic chart handlers/props depending on this
      this._chart.options.onClick = this.handleBarClick.bind(this);
      this._chart.data.datasets[0].borderRadius = 6;
      this._chart.data.datasets[0].backgroundColor = (context) =>
        context.raw?.label === this._selected_bar_label
          ? "#000000"
          : red_green_gradient;
      minor_axis_scale_config.ticks.font.weight = (context) =>
        context.tick.label === this._selected_bar_label ? "700" : "600";
      minor_axis_scale_config.ticks.color = (context) =>
        context.tick.label === this._selected_bar_label ? "#201b3a" : "#585662";

      if (window.innerWidth > 1200) {
        this.renderVerticalChart();
      }
    }

    if (this._data.length) {
      this._chart.data.labels = this._data.map((item) => item.label).sort();
      this._chart.data.datasets[0].data = this._data;
      this._chart.update();
    }
  }

  /**
   * Update chartjs config to a horizontal bar chart for smaller screens
   */
  renderHorizontalChart() {
    this._chart.data.datasets[0].axis = "y";
    this._chart.options.indexAxis = "y";
    this._chart.options.layout.padding = { right: 70 };
    this._chart.options.parsing = { yAxisKey: "label", xAxisKey: "value" };
    // swap scales
    this._chart.options.scales.x = major_axis_scale_config;
    this._chart.options.scales.y = minor_axis_scale_config;
    this._chart.update();
  }

  /**
   * Update chartjs config to a vertical bar chart for larger screens
   */
  renderVerticalChart() {
    this._chart.data.datasets[0].axis = "x";
    this._chart.options.indexAxis = "x";
    this._chart.options.layout.padding = { top: 20 };
    this._chart.options.parsing = { xAxisKey: "label", yAxisKey: "value" };
    // swap scales
    this._chart.options.scales.x = minor_axis_scale_config;
    this._chart.options.scales.y = major_axis_scale_config;
    this._chart.update();
  }

  /**
   * Updates bar chart orientation to be vertical (desktop) or horizontal (mobile/tablet)
   */
  updateChartOrientation() {
    if (this._chart) {
      if (window.innerWidth < 1200) {
        this.renderHorizontalChart();
      } else {
        this.renderVerticalChart();
      }
    }
  }

  connectedCallback() {
    this.template = () => {
      return html`
        <style>
          #dashboard-graph {
            background: var(--t-color-white);
            box-shadow: var(--t-box-shadow);
            border-radius: 8px;
            padding: 24px;
            margin-top: 16px;
            color: var(--t-color-dark);
            display: flex;
            flex-direction: column;
          }
          #dashboard-graph .button-container {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            align-items: center;
          }
          #dashboard-graph .button-container button {
            width: 112px;
          }
          #dashboard-graph .button-container app-toggle-switch {
            width: 112px;
            margin-bottom: 8px;
          }
          #dashboard-graph canvas {
            max-height: 350px;
          }
          #dashboard-graph h4 {
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;
          }
          #dashboard-graph h5 {
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            margin: 10px 0 0 0;
            display: flex;
            align-items: center;
          }
          #dashboard-graph h5 .material-symbols-outlined {
            font-variation-settings: "FILL" 1;
            color: var(--t-color-dark);
            font-size: 23px;
            line-height: 20px;
            margin-right: 8px;
          }
          #dashboard-graph .graph-x-label {
            align-self: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
          }
          @media (min-width: 768px) {
            #dashboard-graph {
              margin-top: unset;
            }
            #dashboard-graph .button-container {
              flex-direction: row;
            }
            #dashboard-graph .button-container app-toggle-switch {
              margin-bottom: unset;
            }
            #app-button-viewpatients {
              margin-left: 16px;
            }
          }
        </style>
        <div id="dashboard-graph-wrapper">
          <div id="dashboard-graph">
            <div class="top d-flex">
              <h4 style="margin: 0;">${this._title}</h4>
              <div class="button-container">
                <app-toggle-switch
                  @change=${(e) => this.handleWeeklyToggle(e)}
                  .enabled_text=${"WEEKLY"}
                  .disabled_text=${"DAILY"}
                >
                </app-toggle-switch>
              </div>
            </div>
            <h5 style="margin-bottom: 32px;">
              <span class="material-symbols-outlined">help</span>
              ${this._subtitle}
            </h5>
            <canvas id=${this.getChartCanvasID()}></canvas>
            <span class="graph-x-label" style="margin-top: 16px;"
              >${this._graph_x_label}</span
            >
          </div>
        </div>
      `;
    };

    Object.assign(this.style, {
      display: "block",
      padding: "unset",
    });

    this.render();
  }

  disconnectedCallback() {
    // TODO: is this ok?
    window.matchMedia("(max-width: 1200px)").onchange = null;
  }

  render() {
    if (!this.template) return;
    render(this.template(), this);
    this.renderChart();
  }

  /**
   * Handler for chartjs onClick, passes bar data back to parent when a bar is clicked.
   * @param {Event} _event chartjs click event
   * @param {BarElement[]} chartElement the bar element itself
   */
  handleBarClick(_event, chartElement) {
    if (chartElement.length) {
      const bar_data = chartElement[0].element.$context.raw;
      this._selected_bar_label = bar_data.label;
      this.dispatchEvent(
        new CustomEvent("barclick", {
          detail: bar_data,
        })
      );
    }
  }

  /**
   * Toggles graph date range between daily and weekly
   * @param {Event} e <app-toggle-switch> click event
   * @fires AppDashboardMeasures#weeklytoggle
   */
  handleWeeklyToggle(e) {
    e.preventDefault();
    this.dispatchEvent(
      new CustomEvent("weeklytoggle", {
        detail: { is_weekly: e.target.enabled },
      })
    );
  }
}

customElements.define("app-dashboard-graph", AppDashboardGraph);

const minor_axis_scale_config = {
  grid: {
    lineWidth: 0,
    drawBorder: false,
  },
  ticks: {
    font: {
      size: 14,
      family: "Open Sans",
    },
  },
};

const major_axis_scale_config = {
  beginAtZero: true,
  grid: {
    color: "#E7E5F0",
    lineWidth: (context) => (context.tick.value == 0 ? 1 : 0), //Set only 0 line visible
    drawBorder: false,
  },
  ticks: {
    callback: (value) => (value * 100).toFixed(0) + "%",
    font: {
      size: 12,
      family: "Open Sans",
    },
    maxTicksLimit: 6,
  },
};

const chartjs_config = {
  type: "bar",
  data: {
    datasets: [
      {
        axis: "y",
        borderRadius: {
          topLeft: 2,
          topRight: 2,
        },
      },
    ],
  },
  options: {
    animation: {
      onComplete: renderCustomBarLabels,
      onProgress: renderCustomBarLabels,
    },
    onHover: (event, chartElement) => {
      // cursor: pointer on bar hover
      event.native.target.style.cursor = chartElement[0]
        ? "pointer"
        : "default";
    },
    indexAxis: "y",
    layout: { padding: { right: 70 } }, // needed for custom bar labels
    maintainAspectRatio: false,
    parsing: {
      yAxisKey: "label",
      xAxisKey: "value",
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: minor_axis_scale_config,
      x: major_axis_scale_config,
    },
  },
};

/**
 * Use chartjs animation callbacks to render custom labels for the bars
 * @param {Object} x described here https://www.chartjs.org/docs/latest/configuration/animations.html#animation-callbacks
 */
function renderCustomBarLabels(x) {
  // Custom labels above bars, source: https://stackoverflow.com/questions/68043049/chart-js-not-showing-value-on-top-of-bars
  const chart = x.chart;
  const is_horizontal = chart.options.indexAxis === "y";
  const { ctx } = chart;
  ctx.textAlign = "center";
  ctx.fillStyle = "var(--t-color-primary)";
  ctx.textBaseline = "bottom";
  ctx.font = 'normal 14px "Quicksand"';
  chart.data.datasets.forEach((dataset, i) => {
    const meta = chart.getDatasetMeta(i);
    meta.data.forEach((bar, index) => {
      const data_item = dataset.data[index];
      ctx.fillText(
        `${(data_item.value * 100).toFixed(1)}% (${data_item.count})`,
        bar.x + (is_horizontal ? 35 : 0),
        bar.y + (is_horizontal ? 5 : -5)
      );
    });
  });
}
