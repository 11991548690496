import { endOfWeek, startOfWeek } from "date-fns";
import { html, render } from "lit";
import { unsafeHTML } from 'lit/directives/unsafe-html.js'
import ModalBase from "shared/components/util/modal-base";
import directus from "shared/lib/lib-directus";
import { getCurrentClientId } from "shared/lib/lib-user";

class AppVisitActivityModal extends ModalBase {
    set visit(value) {
        this._visit = value;
    }

    /** @type {import("../../scenes/scene-visit-confirmation").Visit} */
    get visit() {
        return this._visit;
    }

    set careteam(value) {
        this._careteam = value;
        this.render();
    }

    get careteam() {
        return this._careteam;
    }

    set patient_visits(value) {
        this._patient_visits = value;
        this.render();
    }

    get patient_visits() {
        return this._patient_visits;
    }

    set activity(value) {
        //sort descending
        value.sort(
            (a, b) => new Date(b.date_created) - new Date(a.date_created)
        );
        this._activity = value;
        this.render();
    }

    get activity() {
        return this._activity;
    }

    constructor() {
        super();
        this._patient_visits = [];
        this._activity = [];
        this.loading = true;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }

                    .visit-item {
                        font-size: 12px;
                        border-bottom: 1px solid var(--t-color-lightest-grey);
                        display: flex;
                        align-items: center;
                    }

                    .visit-item-user {
                        font-size: 12px;
                        margin-right: 5px;
                        font-weight: 600;
                    }

                    .visit-item-date-label {
                        font-weight: 600;
                        margin-right: 5px;
                        white-space: nowrap;
                    }

                    .visit-activity-icon {
                        margin-right: 5px;
                        font-size: 13px;
                    }

                    .visit-activity-type {
                        margin-right: 8px;
                        font-size: 12px;

                    }
                    .profile-circle {
                        display: inline-block;
                        background-color: var(--t-color-primary);
                        margin: 5px;
                        border-radius: 50%;
                    }

                    .profile-circle-inner {
                        display: table-cell;
                        vertical-align: middle; 
                        text-align: center;
                        text-decoration: none;
                        height: 23px;
                        width: 23px;  
                        font-size: 12px;
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Visit Activity</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ${this.loading ? html`Loading...` :
                    html`
                            <div>
                                <h4 style="
                                    font-size: 18px;
                                    color: var(--t-color-dark);
                                ">Visits This Week</h4>
                                <div>
                                    ${this.patient_visits.map(
                        visit => html`
                                        <div class="visit-item">
                                            <span class="visit-item-date-label">${new Date(visit.visit_date).toLocaleString('en-US', { dateStyle: "short", timeStyle: "short" })}</span>
                                            <span style="margin-right: 7px; font-weight: bold;">${visit.visit_type}</span>
                                            <span style="margin-right: 5px;">${visit.careteam_member_id.first_name}</span>
                                            <span>${visit.careteam_member_id.last_name}</span>
                                        </div>
                                        `
                    )}
                                </div>
                            </div>
                            <div>
                                <h4 style="
                                    margin-top: 15px;
                                    font-size: 18px;
                                    color: var(--t-color-dark);
                                ">Activity</h4>
                                <div style="max-height: 150px; overflow-y: auto">
                                    ${this.activity.map(
                        care_event => html`
                                        <div class="visit-item">
                                            <span class="visit-item-date-label">${new Date(care_event.date_created).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short" })}</span>
                                            ${this.renderActivityType(care_event)}
                                            <span class="visit-item-user">${care_event.item_data?.user_created?.first_name} ${care_event.item_data?.user_created?.last_name?.[0]}.</span>
                                            ${this.renderActivityMessage(care_event)}
                                        </div>
                                        `
                    )}

                                </div>

                            </div>
                            <div style="display: flex;">
                                <div style="flex-basis: 50%;">
                                    <h4 style="
                                        margin-top: 15px;
                                        font-size: 18px;
                                        color: var(--t-color-dark);
                                    ">Care Team</h4>
                                    <div>
                                        ${this.careteam?.map?.(
                        careteam_member => html`
                                            <div style="display: flex; align-items: center;">
                                                <div class="profile-circle" style="margin-right: 10px;">
                                                    <p class="profile-circle-inner">${careteam_member.first_name[0].toUpperCase()}${careteam_member.last_name[0].toUpperCase()}</p>
                                                </div>
                                                <span style="text-transform: capitalize; margin-right: 5px; font-size: 14px;">${careteam_member.first_name}</span>
                                                <span style="text-transform: capitalize; font-size: 14px;">${careteam_member.last_name}</span>
                                            </div>
                                            <a style="
                                                margin-left: 20px;
                                                text-decoration: none;
                                                display: flex; 
                                                align-items: center; 
                                                color: var(--t-color-primary); 
                                                font-size: 13px;" 
                                                href="tel:${careteam_member.phone_number}">
                                                <span class="material-symbols-outlined" style="font-size: 16px; margin-right: 5px;">call</span>
                                                <span>${careteam_member.phone_number}</span>
                                            </a>
                                            `
                    )}
                                    </div>
                                </div>
                                <div style="flex-basis: 50%;">
                                    <h4 style="
                                        margin-top: 15px;
                                        font-size: 18px;
                                        color: var(--t-color-dark);
                                    ">Add Note</h4>
                                    <app-patient-note
                                        id="new_note"
                                        @save=${(e) => this.handleSaveNewNote(e.detail)}
                                        @cancel=${(e) => this.handleCancelAddNote(e)}
                                        .editing=${true}
                                        .force_edit_mode=${true}
                                        .note=${{
                            client_id: this.visit.client_id,
                            patient_id: this.visit.patient_id,
                            content: " ",
                        }}
                                        style="display: block; width: 100%;"></app-patient-note>
                                </div>
                            </div>
                            `
                }
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            `;
        };
        //this is a bootstrap modal, so initial render needs to be done to display properly
        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    async init() {
        await this.loadPatientVisits();
        await this.loadActivity();
        await this.loadCareteam();
        this.loading = false;
        this.render();
    }

    async loadPatientVisits() {
        let result = await directus.items("visit").readByQuery({
            filter: {
                patient_id: this.visit.patient_id,
                visit_date: {
                    _between: [startOfWeek(new Date()), endOfWeek(new Date())]
                }
            },
            fields: [
                "*",
                "careteam_member_id.id",
                "careteam_member_id.first_name",
                "careteam_member_id.last_name",
            ]
        });
        this.patient_visits = result.data;
    }

    async loadActivity() {
        let result = await directus.items("care_event").readByQuery({
            filter: {
                patient_id: this.visit.patient_id
            },
            fields: [

            ]
        });
        this.activity = result.data;
    }

    async loadCareteam() {
        let result = await directus.items("patient_access").readByQuery({
            filter: {
                patient_id: this.visit.patient_id,
                user_id: {
                    client_access: {
                        client_id: getCurrentClientId()
                    }
                }
            },
            fields: [
                'user_id.id',
                'user_id.first_name',
                'user_id.last_name',
                'user_id.phone_number'
            ]
        });
        let rows = result.data;
        let careteam = rows.map(row => row.user_id);
        this.careteam = careteam;
    }

    renderActivityType(care_event) {
        switch (care_event.type) {
            case "patient_call":
                return html`
                    <span class="material-symbols-outlined visit-activity-icon" style="color: var(--t-color-primary);">contact_phone</span>
                    <span class="visit-activity-type" style="color: var(--t-color-primary);">CALL</span>
                `;
            case "note":
                return html`
                    <span class="material-symbols-outlined visit-activity-icon" style="color: var(--t-color-primary);">clinical_notes</span>
                    <span class="visit-activity-type" style="color: var(--t-color-primary);">NOTE</span>
                `;
            case "unavailability":
                return html`
                    <span class="material-symbols-outlined visit-activity-icon" style="color: var(--t-color-danger);">event_busy</span>
                    <span class="visit-activity-type" style="color: var(--t-color-danger);">UNAVAILABLE</span>
                `;
            case "completed_checkpoint":
                return html`
                    <span class="material-symbols-outlined visit-activity-icon" style="color: var(--t-color-success);">fact_check</span>
                    <span class="visit-activity-type" style="color: var(--t-color-success);">CHECKPONT</span>
                `;
            default:
                return "";

        }
    }

    /**
     * Render an item as a string for a humanized simple one-line display
     * @param {string} collection 
     * @param {object} item 
     */
    renderActivityMessage(care_event) {
        switch (care_event.type) {
            case "patient_call":
                return html`
                <span class="visit-activity-content">Call to patient 
                    ${care_event.item_data.duration ?
                        html`for ${Math.round(care_event.item.item_data.duration / 60)} minutes.`
                        : ''
                    } </span>
                `;
            case "note":
                return html`<span class="visit-activity-content">${unsafeHTML(care_event.item_data.content)}</span>`
            case "unavailability":
                return html`<span class="visit-activity-content">
                        <div>
                            Patient reported unavalability from ${new Date(care_event.item_data.start_time).toLocaleDateString()} to ${new Date(care_event.item_data.end_time).toLocaleDateString()}
                        </div>
                        <div style="font-size: 10px;">
                            ${care_event.item_data.note}
                        </div>
                        </span>`;
            case "completed_checkpoint":
                return html`<span class="visit-activity-content">
                    Patient <a href=${`patients/${care_event.patient_id}/checkpoints/${care_event.item}`}>completed a checkpoint</a>
                </span>`
            default:
                return "";
        }

    }

    async handleSaveNewNote(content) {
        //clear out the new note
        let new_note = this.querySelector("#new_note");
        new_note.note = {
            client_id: this.visit.client_id,
            patient_id: this.visit.patient_id
        }

        //this is super lame, but there's a race condition here.
        //the care event gets created on an action hook on note create.
        //if we fire this load off immediately, it doesn't catch the new event creation.
        setTimeout(
            async () => {
                await this.loadActivity();
                this.render();
            }, 1000
        )
    }

    async handleCancelAddNote(e) {

    }
}

customElements.define("app-visit-activity-modal", AppVisitActivityModal);
export default AppVisitActivityModal;
