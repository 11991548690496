import { html, render } from "lit";
import 'shared/components/util/app-nav-links';
import { getCurrentClient } from "shared/lib/lib-user" //*AP - add agency name
import ClientSettings from "shared/lib/lib-client-settings";
/**
 * Shared header for the 'Dashboard' sidebar nav item.
 * Auto-selects the appropriate sub-nav item for the current route.
 */
export default class AppDashboardHeader extends HTMLElement {
    set page(value) {
        this._page = value;
        this.render();
    }

    get page() {
        return this._page;
    }

    get links() {
        if (this.selfmanaged) {
            return [
                { page: "overview", url: "dashboard/overview", title: "Overview" },
                { page: "all-alerts", url: "dashboard/all-alerts", title: "All Alerts" },
            ];
        }


        return [
            { page: "overview", url: "dashboard/overview", title: "Overview" },
            { page: "critical-issues", url: "dashboard/critical-issues", title: "Critical Issues" },
            { page: "patient-trends", url: "dashboard/trends", title: "Patient Trends" },
            { page: "all-alerts", url: "dashboard/all-alerts", title: "All Alerts" },
        ];
    }

    constructor() {
        super();
        // re-render when location changes for nav highlight
        window.addEventListener("popstate", this.render.bind(this));
        this._current_client = getCurrentClient()//*AP - add agency name
        this.selfmanaged = false;
    }

    async connectedCallback() {
        await this.initApp();
        this.template = () => {
            const currentPageLink = this.links.find(link => link.page === this.page);
            const title = currentPageLink ? currentPageLink.title : this._current_client.name; // Default to client name if no match found

            return html`
                <div class="mod-base dash-header">
                    <h1 class="header-title">${title}</h1>
                    <app-nav-links .links=${this.links} .page=${this.page}></app-nav-links>
                </div>
            `;
        };

        this.render();
    }

    async initApp() {
        const client_id = this._current_client?.id
        if (client_id) {
            const client_settings = await ClientSettings.getSettings(client_id);
            this.selfmanaged = client_settings?.company?.selfmanaged?.value;
        }
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-dashboard-header", AppDashboardHeader);
