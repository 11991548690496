import { html, render } from "lit";
import "../components/patient/app-patient-header";
import "../components/patient/app-patient-checkpoint-card";
import cache from "../lib/lib-cache";
import { navigate } from "../lib/lib-router";
import directus from "../lib/lib-directus";
import { differenceInDays, format, formatDistance, formatDuration, intervalToDuration } from 'date-fns';
import { parseISODateWithoutTime } from "../lib/lib-date";
import Isotope from 'isotope-layout';

class ScenePatientCheckpointCards extends HTMLElement {
    constructor() {
        super();
        this.checkpoints = { data: [] };
        this.tasks = [];
        this.taskDetails = [];
        this.measures = [];
        this.notes = [];
        this.alerts = [];
        this.episodes = [];
    }

    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
        this.boundResizeHandler = this.handleWindowResize.bind(this);
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        if (value && (!this.patient || this.patient?.id != value)) {
            this.loadPatient();
        }
    }


    get patient_id() {
        return this._patient_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    connectedCallback() {

        this.template = () => html`
            <app-patient-header .page=${"checkpoints"} .patient=${this.patient}></app-patient-header>
            <div class="checkpoint-card-nav">
                <div class="checkpoint-card-filter">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            Filter
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="filterDropdown">
                            <li><button class="dropdown-item filter-button" data-filter="*">Show All <span class="count"></span></button></li>
                            <li><button class="dropdown-item filter-button" data-filter=".unable_to_complete">Unsuccessful <span class="count"></span></button></li>
                            <li><button class="dropdown-item filter-button" data-filter=".active">Pending <span class="count"></span></button></li>
                            <li><button class="dropdown-item filter-button" data-filter=".complete">Complete <span class="count"></span></button></li>
                        </ul>
                    </div>
                </div>
                <div class="checkpoint-card-sort">
                    <h5>Sort by:</h5>
                    
                    <!-- <button class="sort-button" data-sort-by="title">Checkpoint</button> -->
                    <button class="sort-button" data-sort-by="date">Date</button>
                </div>
            </div>
            <div class="patient-scroll-container">
                <div class="grid app-wrap">
                    ${this.tasks.map(task => { // map tasks to cards
            console.log('parent task details', this.taskDetails);
            return html`
                            <div data-title="${task.title.replace(/\D/g, '')}" data-date="${task.scheduled_date}" class="checkpoint-card ${task.status} ${task.task_type} col-12 col-lg-6 col-xl-4"> 
                                <app-patient-checkpoint-card class="mod-base"
                                    .task=${task}
                                    .taskDetails=${this.taskDetails}
                                    .alerts=${this.alerts} 
                                    .notes=${this.notes}>
                                </app-patient-checkpoint-card>
                            </div>
                        `;
        })
            }
                </div>
            </div>
        `;

        this.style.display = "block";
        this.style.height = "100%";
        this.style.overflowY = "auto";
        this.render();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;

        render(this.template(), this);

        if (!this._patient_header_height) {
            this._patient_header_height = this.querySelector('app-patient-header')?.clientHeight;
            this._patient_header_height && this.render();
        }
        this.filterSort();
    }

    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = document.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

    filterSort() {
        try {
            const iso = new Isotope('.grid', {
                itemSelector: '.checkpoint-card',
                layoutMode: 'fitRows',
                filter: '.complete',
                getSortData: {
                    scheduled_date: '[data-date]',
                    title: '[data-title]'
                }
            });

            // Check for any .complete items
            const completeCount = iso.items.filter(item => item.element.classList.contains('complete')).length;

            // Reference to the dropdown button
            const dropdownButton = document.querySelector('#filterDropdown');

            // If no complete items, set filter to show all and update dropdown label
            if (completeCount === 0) {
                iso.arrange({ filter: '*' });
                dropdownButton.textContent = "Viewing Show All";
            } else {
                dropdownButton.textContent = `Viewing Complete (${completeCount})`;
            }

            const filterButtons = document.querySelectorAll('.filter-button');
            filterButtons.forEach(button => {
                button.addEventListener('click', () => {
                    const filterValue = button.getAttribute('data-filter');
                    iso.arrange({ filter: filterValue });

                    // Update the dropdown label to reflect the selected filter and its count
                    dropdownButton.textContent = `Viewing ${button.textContent.trim()}`;
                });

                const filterValue = button.getAttribute('data-filter');
                const countElement = button.querySelector('.count');
                const count = iso.items.filter(item => {
                    if (filterValue === '*') {
                        return true;
                    } else {
                        return item.element.classList.contains(filterValue.slice(1));
                    }
                }).length;

                countElement.textContent = ` (${count})`;
            });

            const sortButtons = document.querySelectorAll('.sort-button');
            sortButtons.forEach(button => {
                button.sortAscending = true;

                button.addEventListener('click', () => {
                    const sortByValue = button.getAttribute('data-sort-by');
                    button.sortAscending = !button.sortAscending;

                    iso.arrange({ sortBy: sortByValue, sortAscending: button.sortAscending });
                });
            });
        } catch (error) {
            console.error('Sorting is not working:', error);
        }
    }




    async loadPatient() {
        this.patient = await cache.getPatient(this.patient_id);
        await this.loadCheckpoints();
        this.render();
    }

    async loadCheckpoints() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        try {
            this.checkpoints = await directus.transport.get(`/vbh/cp_cards/${this.patient_id}`);
        } catch (error) {
            console.error('Failed to fetch checkpoints:', error);
            return; // Ensure further processing is halted if there's an error
        }

        const { tasks, patient_notes, patient_alerts, task_details, patient_reported_measures, episodes } = this.checkpoints.data[0].result;

        // Ensure these properties are defined before using them
        if (patient_reported_measures) {
            this.measures = patient_reported_measures;
        }
        if (episodes) {
            this.episodes = episodes;
        }

        // Define the statuses to be filtered out
        const excludedStatuses = ['blocked', 'archived']; // Replace with actual statuses to exclude

        // Filter tasks based on the excluded statuses
        this.tasks = tasks.filter(task => !excludedStatuses.includes(task.status));

        // Sort tasks by scheduled_date
        this.tasks.sort((a, b) => new Date(b.scheduled_date) - new Date(a.scheduled_date));

        // Assign other data
        this.notes = patient_notes;
        this.alerts = patient_alerts;
        this.taskDetails = task_details;

        this.render();
        this.filterSort();
    }

}

customElements.define("scene-patient-checkpoint-cards", ScenePatientCheckpointCards);
export default ScenePatientCheckpointCards;
