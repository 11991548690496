import { html, render } from "lit";
import { getCurrentClientId, getAvailableClients, setCurrentClient } from "../lib/lib-user";

export default class AppCurrentClientSelect extends HTMLElement {
    constructor() {
        super();
        this._client_options = getAvailableClients();
        this._current_client_id = getCurrentClientId();
    }

    connectedCallback() {
        // Listen for click events on the document
        document.addEventListener('click', (event) => {
            if (!this.contains(event.target)) {
                const dropdown = this.querySelector('.options-list');
                if (dropdown) { // Check if dropdown is not null
                    dropdown.classList.add('hidden');
                }
            }
        });

        this.template = () => {
            return html`
                <div class="client-select" @click=${this.toggleDropdown}>
                    <div class="selected-option">
                        ${this._current_client_id
                    ? this._client_options.find(client => client.id === this._current_client_id)?.name
                    : 'Choose a client'
                }
                    </div>
                    <ul class="options-list hidden">
                        ${this._client_options
                    .filter(client => client.status === "active") // Filter active clients
                    .map(client => html`
                                <li @click=${(event) => this.handleClientChange(event, client.id)} ?selected=${client.id === this._current_client_id}>
                                    ${client.name}
                                </li>
                            `)
                }
                    </ul>
                </div>
            `;
        }

        this.render();
    }

    toggleDropdown(event) {
        event.stopPropagation();  // Prevent event from propagating to the document
        const dropdown = this.querySelector('.options-list');
        dropdown.classList.toggle('hidden');
    }

    async handleClientChange(event, client_id) {
        event.stopPropagation();  // Prevent event from propagating to the document
        const client = this._client_options.find(client => client.id === client_id);
        await setCurrentClient(client);
        location.reload();
    }

    render() {
        if (!this.template) return;
        if (!(this._client_options.length > 1)) return;

        render(this.template(), this);
    }
}

customElements.define("app-current-client-select", AppCurrentClientSelect);
