import { html, render } from "lit";
import { subDays } from "date-fns";
import { navigate } from "shared/lib/lib-router";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";

/**
 * @param {object} metrics reponse from /dashboard_metrics
 * A widget displaying care experience in the last 30 days
 */
export default class AppDashboardCareExperience extends HTMLElement {
    constructor() {
        super();
        this.event_categories = [
            "unsatisfied",
            "doesnt_understand",
            "poor_care_experience",
            "poor_home_safety",
        ];

        this.report_filters = {
            event_categories: this.event_categories,
            alert_date_created_range: this.reportDateRange(30)
        };
    }

    /**
     * Generate date range string for the given period
     * @returns Date range string
     */
    reportDateRange = (period) => {
        const now = new Date();
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() - period);

        return `${getISODateStringWithoutTime(startDate)},${getISODateStringWithoutTime(now)}`;
    }

    set metrics(value) {
        this._metrics = value;
        this.render();
    }

    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {

            const aggregated_metrics = this.getAggregatedMetrics()

            return html`
                <style>
                    
                    #dashboard-care-experience .care-experience-widget-row {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        align-self: center;
                        width: 100%;
                        max-width: 300px;
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                    #dashboard-care-experience app-color-label {
                        margin-right: 16px;
                    }
                    #dashboard-care-experience .care-experience-widget-row__value {
                        margin-left: auto; 
                        align-self: flex-start;
                    }
                </style>
                <div 
                    id="dashboard-care-experience" 
                    style="
                        height: 100%; 
                        cursor: pointer; 
                        display: flex; 
                        flex-direction: column;
                    "
                    @click=${_e => navigate(`reports/patient/high-risk-incident-breakout?filters=${JSON.stringify(this.report_filters)}`)}
                >
                    <h6>Care Experience Incidents</h6>
                    <div style="
                        margin: 20px 0 50px 0; 
                        display: flex; 
                        align-items: center;
                        flex-direction: column;
                    ">
                        <span style="
                            font-size: 50px;
                            font-weight: 700;
                            line-height: 56px;
                            color: var(--t-color-dark);
                            letter-spacing: 0.5px;
                        ">
                            ${aggregated_metrics.total_count}
                        </span>
                        <span style="
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            color: var(--t-color-grey);
                            text-align: center;
                            margin-top: 8px;
                        ">
                            Past 30 Days
                        </span>
                    </div>
                    ${this.event_categories.map(category => html`
                        <div class="care-experience-widget-row">
                            <app-color-label 
                                .text=${aggregated_metrics[category].text} 
                                .color=${aggregated_metrics[category].color}
                            ></app-color-label>    
                            <span class="care-experience-widget-row__value">
                                ${aggregated_metrics[category].count}
                            </span> 
                        </div>
                    `)}
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
        });

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * @returns {object} holding counts for each incident grouping/category
            "unsatisfied",
            "doesnt_understand",
            "poor_care_experience",
            "poor_home_safety",
     */
    getAggregatedMetrics() {
        const high_risk_incidents = this.metrics?.care_experience || [];
        const aggregated_metrics = {
            unsatisfied: {
                color: "var(--t-color-danger)",
                count: high_risk_incidents.unsatisfied,
                text: "Unsatisfied with Care"
            },
            doesnt_understand: {
                color: "var(--t-color-success-light)",
                count: high_risk_incidents.doesnt_understand,
                text: "Understanding of HHA Services"
            },
            poor_care_experience: {
                color: "var(--t-color-warning)",
                count: high_risk_incidents.poor_care_experience,
                text: "Care Experience"
            },
            poor_home_safety: {
                color: "var(--t-color-warning-light)",
                count: high_risk_incidents.poor_home_safety,
                text: "Home Safety"
            },
            total_count: (
                high_risk_incidents.unsatisfied +
                high_risk_incidents.doesnt_understand +
                high_risk_incidents.poor_care_experience +
                high_risk_incidents.poor_home_safety
            ),
        };
        return aggregated_metrics;
    }
}

customElements.define("app-dashboard-care-experience", AppDashboardCareExperience);
