import { startOfMonth, parse, format } from "date-fns";
import { html, render } from "lit";

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
//import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import { getEpisodeWeek } from "../lib/lib-util";
import { convertUtcToLocalTime, averageDate, formatToLocalTime } from "../lib/lib-date";
import DataDefinition from "../lib/lib-data-definition";
import AppPatientActivityModal from "../components/patient/app-patient-activity-modal";
import { el } from "date-fns/locale";
import * as bootstrap from "bootstrap";


export default class AppPatientActivityCalendar extends HTMLElement {
    constructor() {
        super();
        this.start_date = startOfMonth(new Date());
        this.calendar = null;
        this.calendar_items = [];
        this.filtered_patient_ids = [];
        this.start = null;
        this.end = null;
        this.lastStartStr = null;
        this.lastEndStr = null;
        this.patient_communication_definition = null;
        this.type = "all";
    }

    set patient_id(value) {
        if (!value)
            return;
        this._patient_id = value;
    }

    get patient_id() {
        return this._patient_id;
    }

    set type(value) {
        if (value)
            this._type = value;
    }

    get type() {
        return this._type;
    }

    set patient_activities(value) {
        if (value) {
            this._patient_activities = value;

            // Initialize or update the calendar if the element is already connected to the DOM
            if (this.isConnected && this.calendar) {
                // Update the calendar's events with the new patient_activities data
                //this.calendar.removeAllEventSources();
                //this.calendar.addEventSource(this._patient_activities);
                //this.calendar.refetchEvents();
                this.updateReport()
                //console.log("isConnected type", this.type);
                //console.log("isConnected", this._patient_activities);
            } else if (this.isConnected && !this.calendar) {
                // Initialize the calendar
                //console.log("isConnected not", this.isConnected);
                this.initializeFullCalendar();
            }
        }
    }


    get patient_activities() {
        return this._patient_activities;
    }

    async connectedCallback() {
        this.render();
        // Delay to ensure the DOM is fully ready before initializing FullCalendar
        await new Promise(resolve => setTimeout(resolve, 0));
        this.patient_communication_definition = await DataDefinition.getDefinition("patient_communication");
        if (this.patient_activities) {
            //console.log('this.patient_activities', this.patient_activities);
            this.updateReport();
        }
    }

    initializeFullCalendar() {
        if (this.calendar) return;
        //console.log('this.calendar_items', this.calendar_items);
        const calendarEl = this.querySelector('#calendar');
        let isMobile = window.matchMedia("(max-width: 768px)").matches;
        let defaultView = isMobile ? 'listMonth' : 'dayGridMonth';
        this.calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, listPlugin, multiMonthPlugin],
            initialView: defaultView,
            height: 630,
            listDayFormat: { weekday: 'long', month: 'short', day: 'numeric' },
            headerToolbar: {
                left: 'prev today next',
                center: '',
                right: 'multiMonthYear,dayGridMonth,listMonth'
            },

            dayMaxEvents: 6,
            events: this.calendar_items,
            eventClick: (info) => {
                this.showEventDetails(info.event);
            },
            timeZone: 'local',
            expandRows: false,
            datesSet: async (info) => {
                this.start = info.start;
                this.end = info.end;
                this.lastStartStr = info.startStr;
                this.lastEndStr = info.endStr;

                await this.updateReport(this.start, this.end);
            },
            eventContent: (arg) => { // Use an arrow function here
                //console.log('arg', arg);
                //console.log('type', arg.event.extendedProps.type);
                var titleContainer = document.createElement('div');
                titleContainer.classList.add('custom-event-title');
                // Right part of the title for 'self' checkpoints
                var rightPart = document.createElement('div');
                rightPart.classList.add('title-right');
                // Left part of the title
                var leftPart = document.createElement('div');
                leftPart.classList.add('title-left');
                // 2nd row part of the title
                var secondPart = document.createElement('div');
                secondPart.classList.add('title-second-row');

                leftPart.textContent = arg.event.title;
                rightPart.textContent = arg.event.extendedProps.clinician;
                var formattedTime = this.calendar.formatDate(arg.event.start, {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: true
                });
                if (arg.event.extendedProps.task_type) {
                    leftPart.textContent = arg.event.extendedProps.title_left;
                    rightPart.textContent = arg.event.extendedProps.title_right;
                    secondPart.textContent = arg.event.extendedProps.clinician;
                    console.log('arg.event.extendedProps.status', arg.event.extendedProps.compare_variable);
                    if (arg.event.extendedProps.compare_variable == "unable_to_complete") {
                        secondPart.textContent = arg.event.extendedProps.status_reason;

                    } else if (arg.event.extendedProps.task_type === 'prn') {
                        rightPart.textContent = arg.event.extendedProps.title_right;
                        leftPart.textContent = arg.event.extendedProps.title_left;
                        secondPart.textContent = arg.event.title;

                    } else if (arg.event.extendedProps.task_type === 'self') {
                        rightPart.textContent = formattedTime;
                        leftPart.textContent = arg.event.extendedProps.title_left;
                        secondPart.textContent = arg.event.title;
                    }
                } else if (arg.event.extendedProps.visit_type) {
                    leftPart.textContent = arg.event.extendedProps.title_left;
                    rightPart.textContent = arg.event.extendedProps.title_right;
                    secondPart.textContent = arg.event.extendedProps.visit_status;

                } else if (arg.event.extendedProps.type == 'Care Team Call') {
                    leftPart.textContent = arg.event.extendedProps.type;
                    rightPart.textContent = formattedTime;
                    secondPart.textContent = arg.event.extendedProps.status_reason;
                } else {
                    rightPart.textContent = formattedTime;
                    leftPart.textContent = arg.event.extendedProps.type;
                    secondPart.textContent = arg.event.title;
                }


                titleContainer.appendChild(leftPart);
                titleContainer.appendChild(rightPart);

                if (secondPart.textContent) {
                    titleContainer.classList.add('has-second-part'); // Add class if secondPart has content
                    titleContainer.appendChild(secondPart)
                }


                return { domNodes: [titleContainer] };

            },
            /////// FUTURE: eventRender if needed
            //eventRender: function (info) {
            //    var tooltip = new Tooltip(info.el, {
            //        title: info.event.title,
            //        placement: 'top',
            //        trigger: 'hover',
            //        container: 'body'
            //    });
            //},
            windowResize: (arg) => {
                console.log('windowResize', arg);
                let newWindowHeight = window.innerHeight;
                let newCalendarTop = calendarEl.getBoundingClientRect().top;
                let newCalendarHeight = newWindowHeight - newCalendarTop;
                this.calendar.setOption('height', newCalendarHeight);
            },
            eventDidMount: (info) => {

                /////// FUTURE: bootstrap tooltips if needed
                //let tooltipContent = ``;
                //if (info.event.extendedProps.task_type === "self") {
                //    tooltipContent = `
                //        <div class="tooltip-title">${info.event.title ? info.event.title : ''}</div>
                //        <div class="tooltip-description">${info.event.extendedProps.description ? info.event.extendedProps.description : ''}</div>
                //
                //    `;
                //}
                //info.el.setAttribute('data-bs-toggle', 'tooltip');
                //info.el.setAttribute('data-bs-html', 'true');
                //info.el.setAttribute('title', tooltipContent);
                //
                //
                //this.initializeTooltips();

                if (this.calendar.view.type === 'dayGridMonth') {
                    const monthTitle = parse(this.calendar.view.title.split(' ')[0], 'MMMM', new Date());
                    const month = monthTitle.getMonth();
                    const monthDropdown = this.querySelector('#c-month');
                    monthDropdown.value = month;
                }

                if (this.calendar.view.type === 'listMonth') {
                    let source_record = info.event.extendedProps.source_record;
                    let thead = document.querySelector('.fc-list-table thead');

                    if (thead) {
                        let headers = thead.querySelector('tr');
                        headers.innerHTML = `
                            <th class="a-datetime">Date & Day</th>
                            <th class="a-name">Name</th>
                            <th class="a-reason">Reason</th>
                            <th class="a-doc>With</th>
                            <th class="a-spec">Specialty</th>
                            <th class="a-appt">Appointment Time</th>
                            <th class="a-notes">Notes</th>
                        `;
                    }

                    // Modify the day headers to include the new headers
                    let dayHeaders = Array.from(document.querySelectorAll('tr[data-date] th[scope="colgroup"]'));
                    dayHeaders.forEach(header => {
                        if (!header.parentElement.querySelector('.custom-day-header')) {
                            let headers;

                            if (this.type == "all") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Name', colspan: 2 },
                                    { class: 'custom-day-header a-type', text: 'Type', colspan: 1 },
                                ];
                            } if (this.type == "communication") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Name', colspan: 2 },
                                    { class: 'custom-day-header a-username', text: 'User Name', colspan: 1 },
                                    { class: 'custom-day-header a-date', text: 'Date/Time', colspan: 1 },
                                    { class: 'custom-day-header a-type', text: 'Type', colspan: 1 },
                                    { class: 'custom-day-header a-note', text: 'Note', colspan: 1 },
                                    { class: 'custom-day-header a-reason', text: 'Reason', colspan: 1 },
                                ];
                            } else if (this.type == "calls") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'From', colspan: 2 },
                                    { class: 'custom-day-header a-date', text: 'Date', colspan: 1 },
                                    //{ class: 'custom-day-header a-duration', text: 'Duration', colspan: 1 },
                                    { class: 'custom-day-header a-note', text: 'Note', colspan: 1 },
                                    { class: 'custom-day-header a-reason', text: 'Disposition', colspan: 1 },
                                ];
                            } else if (this.type == "visits") {
                                headers = [
                                    { class: 'custom-day-header a-type', text: 'Visit Type', colspan: 2 },
                                    { class: 'custom-day-header a-name', text: 'Clinician', colspan: 1 },

                                    { class: 'custom-day-header a-reason', text: 'Visit Status', colspan: 1 },
                                    { class: 'custom-day-header a-sync', text: 'Last Sync', colspan: 1 }
                                ];
                            } else if (this.type == "checkpoints") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Checkpoint', colspan: 2 },
                                    { class: 'custom-day-header a-title', text: 'Clinician', colspan: 1 },
                                    { class: 'custom-day-header a-status', text: 'Status', colspan: 1 },
                                    { class: 'custom-day-header a-week', text: 'Episode', colspan: 1 },
                                    { class: 'custom-day-header a-alerts', text: 'Alerts', colspan: 1 },

                                ];


                            } else if (this.type == "notes") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Added By', colspan: 2 },
                                    { class: 'custom-day-header a-addedby', text: 'Question', colspan: 1 },
                                    { class: 'custom-day-header a-date', text: 'Date', colspan: 1 },
                                    { class: 'custom-day-header a-note', text: 'Note', colspan: 1 }
                                ];
                            } else if (this.type == "availability") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Name', colspan: 2 },
                                    { class: 'custom-day-header a-reason', text: 'Reason', colspan: 1 },
                                    { class: 'custom-day-header a-date', text: 'Date', colspan: 1 },
                                    { class: 'custom-day-header a-time', text: 'Time', colspan: 1 },
                                    { class: 'custom-day-header a-note', text: 'Note', colspan: 1 }
                                ];
                            }

                            let lastHeader = header;
                            headers.forEach(h => {
                                let newHeader = document.createElement('th');
                                newHeader.className = h.class;
                                newHeader.textContent = h.text;

                                if (h.colspan && h.colspan > 1) {
                                    newHeader.setAttribute('colspan', h.colspan);
                                }

                                header.parentElement.insertBefore(newHeader, lastHeader.nextSibling);
                                lastHeader = newHeader;
                            });
                        }
                    });

                    let redundantDayLabels = Array.from(document.querySelectorAll('.fc-list-day-side-text'));
                    redundantDayLabels.forEach(label => label.remove());
                    let dateHeaderColgroup = document.querySelector('tr[data-date] th[scope="colgroup"]');
                    if (dateHeaderColgroup) {
                        // Change the scope to just a single column
                        dateHeaderColgroup.setAttribute('scope', 'col');

                        // Remove the colspan attribute if it exists
                        if (dateHeaderColgroup.hasAttribute('colspan')) {
                            dateHeaderColgroup.removeAttribute('colspan');
                        }
                    }

                    let titleCell = info.el.querySelector('.fc-list-event-title');

                    if (titleCell) {
                        if (this.type == "all") {
                            let typeCell = document.createElement('td');
                            typeCell.innerHTML = `${info.event.extendedProps.type}`;
                            titleCell.parentElement.insertBefore(typeCell, titleCell.nextSibling);

                        } else if (this.type == "communication") {
                            let userNameCell = document.createElement('td');
                            userNameCell.innerHTML = `${source_record.user_created?.first_name + " " + source_record.user_created?.last_name}`;
                            titleCell.parentElement.insertBefore(userNameCell, titleCell.nextSibling);

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${format(convertUtcToLocalTime(source_record.date_created), "MM/dd/y 'at' h:mm aaa")}`;
                            titleCell.parentElement.insertBefore(dateCell, userNameCell.nextSibling);

                            let typeCell = document.createElement('td');
                            typeCell.innerHTML = `${this.patient_communication_definition?.getChoiceLabel('communication_type', source_record.communication_type)}`;
                            titleCell.parentElement.insertBefore(typeCell, dateCell.nextSibling);

                            let noteCell = document.createElement('td');
                            noteCell.innerHTML = `${source_record.notes != null ? source_record.notes : ""}`;
                            titleCell.parentElement.insertBefore(noteCell, typeCell.nextSibling);

                            let reasonCell = document.createElement('td');
                            reasonCell.innerHTML = `${source_record.status_reason != null ? source_record.status_reason : ""}`;
                            titleCell.parentElement.insertBefore(reasonCell, noteCell.nextSibling);

                        } else if (this.type == "calls") {

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${convertUtcToLocalTime(source_record.date_created).toLocaleDateString()}`;
                            titleCell.parentElement.insertBefore(dateCell, titleCell.nextSibling);

                            //let durationCell = document.createElement('td');
                            //durationCell.innerHTML = `${source_record.call_duration > 90 ? (Math.round(source_record.call_duration / 60) + " minutes") : ((source_record.call_duration || 0) + " seconds")}`;
                            //titleCell.parentElement.insertBefore(durationCell, dateCell.nextSibling);

                            let noteCell = document.createElement('td');
                            noteCell.innerHTML = `${source_record.notes != null ? source_record.notes : ""}`;
                            titleCell.parentElement.insertBefore(noteCell, dateCell.nextSibling);

                            let reasonCell = document.createElement('td');
                            reasonCell.innerHTML = `${source_record.status_reason != null ? source_record.status_reason : ""}`;
                            titleCell.parentElement.insertBefore(reasonCell, noteCell.nextSibling);

                        } else if (this.type == "visits") {

                            //let typeCell = document.createElement('td');
                            //typeCell.innerHTML = `${source_record.visit_type}`;
                            //titleCell.parentElement.insertBefore(typeCell, titleCell.nextSibling);

                            let userNameCell = document.createElement('td');
                            userNameCell.innerHTML = `${source_record.careteam_member_id.first_name?.[0] + '.' || ''} ${source_record.careteam_member_id.last_name || ''}`;
                            titleCell.parentElement.insertBefore(userNameCell, titleCell.nextSibling);

                            let reasonCell = document.createElement('td');
                            reasonCell.innerHTML = `${source_record.visit_status != null ? source_record.visit_status : ""}`;
                            titleCell.parentElement.insertBefore(reasonCell, userNameCell.nextSibling);

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${format(convertUtcToLocalTime(source_record.last_sync_date), "MM/dd/y 'at' h:mm aaa")}`;
                            titleCell.parentElement.insertBefore(dateCell, reasonCell.nextSibling);



                        } else if (this.type == "checkpoints") {
                            let titleCell = info.el.querySelector('.fc-list-event-title');
                            if (titleCell) {
                                titleCell.classList.add('nowrap');
                                titleCell.style.minWidth = '150px';
                                const properties = ['clinician', 'status', 'episode', 'alerts'];

                                properties.forEach(prop => {
                                    const cell = document.createElement('td');
                                    if (prop === 'status') {
                                        // Interpret the HTML string for status
                                        cell.innerHTML = info.event.extendedProps[prop] || 'No information provided';
                                    } else if (prop === 'alerts') {
                                        cell.innerHTML = info.event.extendedProps[prop] || 'No information provided';
                                    } else {
                                        cell.textContent = info.event.extendedProps[prop] || 'No information provided';
                                    }
                                    titleCell.parentElement.appendChild(cell);
                                });
                            }

                        } else if (this.type == "notes") {
                            let addedByCell = document.createElement('td');
                            addedByCell.innerHTML = `${source_record.question}`;
                            titleCell.parentElement.insertBefore(addedByCell, titleCell.nextSibling);

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${convertUtcToLocalTime(source_record.date_created).toLocaleDateString()}`;
                            titleCell.parentElement.insertBefore(dateCell, addedByCell.nextSibling);

                            let noteCell = document.createElement('td');
                            noteCell.innerHTML = `${source_record.content}`;
                            titleCell.parentElement.insertBefore(noteCell, dateCell.nextSibling);

                        } else if (this.type == "availability") {
                            let reasonCell = document.createElement('td');

                            if (source_record.reason === "dr_appointment")
                                reasonCell.innerHTML = "Doctor's Appointment";
                            else if (source_record.reason === "travel")
                                reasonCell.innerHTML = "Travel";
                            else if (source_record.reason === "dialysis")
                                reasonCell.innerHTML = "Dialysis";
                            else if (source_record.reason === "other")
                                reasonCell.innerHTML = `Other: ${source_record.reason_other}`;

                            titleCell.parentElement.insertBefore(reasonCell, titleCell.nextSibling);

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${convertUtcToLocalTime(source_record.date_created).toLocaleDateString()}`;
                            titleCell.parentElement.insertBefore(dateCell, reasonCell.nextSibling);

                            let timeCell = document.createElement('td');
                            timeCell.innerHTML = `${`
                                from ${format(convertUtcToLocalTime(source_record.start_time), "M/d/yyyy hh:mm a")}
                                to ${format(convertUtcToLocalTime(source_record.end_time), "M/d/yyyy hh:mm a")}
                                ${source_record.dr_appt_doctor ? `
                                    with ${source_record.dr_appt_doctor}
                                    ${source_record.dr_appt_specialty ? `(${source_record.dr_appt_specialty})` : ""}
                                ` : ""}
                            `}`;
                            titleCell.parentElement.insertBefore(timeCell, dateCell.nextSibling);

                            let noteCell = document.createElement('td');
                            noteCell.innerHTML = `${source_record.notes}`;
                            titleCell.parentElement.insertBefore(noteCell, timeCell.nextSibling);
                        }
                    }
                }
            }
        });

        this.calendar.render();
    }

    async updateReport() {
        // const activityResult = await directus.transport.get("/vbh/patient/activity", {
        //     params: {
        //         filters: {
        //             patient_id: this.patient_id
        //         }
        //     }
        // });

        this.calendar_items = this.mapToCalendarItems(this.patient_activities);
        if (this.calendar) {
            this.calendar.removeAllEvents();
            this.calendar.addEventSource(this.calendar_items);
        }

        this.render();

    }

    render() {
        const yearsArray = Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i);

        const template = html`
            <div class="cal-wrap p-cal">xxxxxxxxxxxxxxxxxxx
                <div class="col month-select">
                    <div class="btn-group" style="align-items: center;">
                        <select id="c-month" class="form-select" style="width: auto; margin-left: 16px;" @change=${(evt) => this.handleMonthChange(evt)}>
                            ${Array.from({ length: 12 }, (_, i) => html`
                                <option value=${i} ?selected=${averageDate(this.start, this.end).getMonth() === i}>
                                    ${new Date(2000, i).toLocaleString('default', { month: 'long' })}
                                </option>
                            `)}
                        </select>

                        <select class="form-select" style="width: auto;" @change=${(evt) => this.handleYearChange(evt)}>
                            ${yearsArray.map(year => html`
                                <option value=${year} ?selected=${averageDate(this.start, this.end).getFullYear() === year}>${year}</option>
                            `)}
                        </select>
                    </div>
                </div>
                <div id="calendar"></div>
            </div>
        `;

        render(template, this);
        this.initializeFullCalendar();
        this.handleWindowResize();
    }

    initializeTooltips() {
        Array.from(this.querySelectorAll('.cal-cp-self[data-bs-toggle="tooltip"]'))
            .forEach(tooltipTriggerEl => {
                if (!bootstrap.Tooltip.getInstance(tooltipTriggerEl)) {
                    new bootstrap.Tooltip(tooltipTriggerEl, {
                        placement: 'top'
                    });
                }
            });
    }

    showEventDetails(event) {
        const { source_record, type } = event.extendedProps;
        const modal = new AppPatientActivityModal(source_record, type);
        modal.showModal();

        setTimeout(() => {
            let element = document.querySelector('app-patient-activity-modal');
            element.style.zIndex = '9999';
        }, 100);

        setTimeout(() => {
            let element = document.querySelector('app-patient-activity-modal');
            element.style.zIndex = '9999';
        }, 500);
    }

    handleMonthChange(evt) {
        this.start_date.setMonth(evt.target.value);
        this.calendar.gotoDate(this.start_date);
        // Set the dropdown value explicitly after changing the calendar date
        evt.target.value = this.start_date.getMonth();
    }

    handleYearChange(evt) {
        this.start_date.setFullYear(evt.target.value);
        this.calendar.gotoDate(this.start_date);
        // Set the dropdown value explicitly after changing the calendar date
        evt.target.value = this.start_date.getFullYear();
    }
    handleWindowResize() {
        if (this.calendar) {
            const calendarRect = this.calendar.el.getBoundingClientRect();
            const distanceFromTop = calendarRect.top + 20;
            const availableHeight = window.innerHeight - distanceFromTop;
            this.calendar.setOption('height', availableHeight);
            //console.log('availableHeight', availableHeight);
        }
    }

    mapToCalendarItems(data) {
        //console.log('map data', data);
        let calendarItems = [];
        if (data.patient_calls && (this.type === "calls" || this.type === "all")) {
            data.patient_calls.map(patient_call => {
                if (patient_call.communication_type == "phone_voice" && patient_call.caller_type == "staff") {
                    const calendarItem = {
                        start: patient_call.date_created,
                        description: `Duration: ${patient_call.call_duration >= 120 ? (Math.round(patient_call.call_duration / 60) + " Minutes") : (patient_call.call_duration + " Seconds")}`,
                        title: `${patient_call.user_created?.first_name?.[0] + '.' || ''} ${patient_call.user_created?.last_name || ''}`,
                        source_record: patient_call,
                        color: "var(--bs-primary)",
                        type: "Care Team Call",
                        className: 'cal-call cp-expander',
                        groupId: 'cal-call',
                        status_reason: patient_call.status_reason?.replace(/_/g, ' ') ?? 'No reason provided',
                    }
                    //console.log('calendarItem from CALLS', calendarItem);
                    calendarItems.push(calendarItem);
                    //console.log('calendarItems from CALLS', patient_call.date_created);
                } else if (patient_call.communication_type == "sms") {
                    try {
                        const types = {
                            "general": "General",
                            "education": "Education",
                            "clinician": "Clinician",
                        };
                        const title = types[patient_call.notes] || "";
                        const calendarItem = {
                            start: formatToLocalTime(patient_call.scheduled_date),
                            description: patient_call.sms_message,
                            title: title,
                            source_record: patient_call,
                            color: "var(--bs-primary)",
                            type: "SMS",
                            className: 'cal-sms cp-expander',
                            groupId: 'cal-sms',
                        }
                        calendarItems.push(calendarItem);
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        }
        if (data.communications && (this.type === "communication" || this.type === "all")) {
            data.communications.map(communication => {
                const calendarItem = {
                    start: communication.date_created,
                    end: communication.date_created,
                    description: `${communication.call_duration >= 120 ? (Math.round(communication.call_duration / 60) + " Minutes") : (communication.call_duration + " Seconds")}`,
                    title: `${communication.user_created.first_name?.[0] + '.' || ''} ${communication.user_created.last_name || ''}`,
                    source_record: communication,
                    color: "#000000",
                    type: "Agency Call",
                    className: 'cal-comm cp-expander',
                    groupId: 'cal-comm'
                }

                calendarItems.push(calendarItem);
            })
        }

        if (data.checkpoints && (this.type === "checkpoints" || this.type === "all")) {
            data.checkpoints.forEach(checkpoint => {
                let dotColor = '#9ac58f'; // Default dot color

                const alertsDetails = checkpoint.alerts?.length
                    ? checkpoint.alerts.map(alert => {
                        const notesDetails = alert.notes?.length
                            ? alert.notes.map(note => `<span style="padding-left:20px;" class='note-detail'>${note.content}</span>`).join('<br>')
                            : '<span class="no-notes">(no notes)</span>';
                        return `<div style="">
                    <span class="material-symbols-outlined" style=" color: #ca463d; font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48; font-size:14px;">
                        warning
                    </span>
                    <strong class='alert-subject'>${alert.subject}:</strong> ${notesDetails}`;
                    }).join('</div>')
                    : '<span class="no-alerts">No Alerts</span>'; // Display "No Alerts" if no alerts

                let statusText = checkpoint.status;
                let startTime = formatToLocalTime(checkpoint.date_to_use);
                let isAllDay = true;
                let cpTitle = checkpoint.title;
                let cpTLeft = checkpoint.title;
                let cpTRight = checkpoint.episode_id ? checkpoint.episode_id.id : 'No episode'
                let cpClass = 'cal-cp cp-expander';
                let cpTSecond = "";
                let compare_variable = "";

                if (checkpoint.task_type == 'prn') {
                    startTime = checkpoint.date_to_use;
                    isAllDay = true;
                    dotColor = '#b986ab';
                    cpClass = 'cal-cp, cal-cp-prn cp-expander';
                    cpTitle = checkpoint.form_design?.title;
                    cpTLeft = checkpoint.title;
                }
                if (checkpoint.task_type == 'self') {
                    startTime = formatToLocalTime(checkpoint.date_to_use);
                    isAllDay = false;
                    dotColor = '#e49d73';
                    cpClass = 'cal-cp, cal-cp-self cp-expander';
                    cpTitle = checkpoint.form_design?.title;
                    cpTLeft = checkpoint.title;
                    cpTRight = startTime;

                }
                if (statusText === 'unable_to_complete') {
                    dotColor = '#989898';
                    cpClass = 'cal-cp cal-cp-unable cp-expander';
                    const formattedReason = '<span class="note-detail">' + (checkpoint.status_reason?.replace(/_/g, ' ') ?? 'No reason provided');
                    statusText = `<strong class="alert-subject">Unsuccessful:</strong> ${formattedReason}`;
                    status = 'Unsuccessful' + (checkpoint.status_reason?.replace(/_/g, ' ') ?? 'No reason provided');
                    cpTSecond = checkpoint.status_reason?.replace(/_/g, ' ') ?? 'No reason provided';
                    compare_variable = checkpoint.status;
                    console.log('compare_variable', compare_variable);

                } else if (statusText === 'active' && checkpoint.task_type !== 'self' && checkpoint.task_type !== 'prn') {
                    dotColor = '#74AAB7'; // pending color
                    statusText = '<strong class="alert-subject">Pending</strong>';

                } else if (statusText === 'complete' && checkpoint.alerts?.length) {
                    dotColor = '#9ac58f';
                    statusText = '<strong class="alert-subject">Complete</strong>';
                    cpClass = 'cal-cp cal-cp-alert cp-expander';
                }
                else if (statusText === 'complete') {
                    dotColor = '#9ac58f';
                    statusText = '<strong class="alert-subject">Complete</strong>';
                    cpClass = 'cal-cp cal-cp-complete cp-expander';
                }

                let checkpointItem = {
                    start: startTime,
                    title: cpTitle,
                    title_left: cpTLeft,
                    title_right: cpTRight,
                    title_second: cpTSecond,
                    compare_variable: compare_variable,
                    extendedProps: {
                        clinician: checkpoint.assigned_user ? `${checkpoint.assigned_user.first_name} ${checkpoint.assigned_user.last_name}` : 'Unknown',
                        date: checkpoint.date_to_use ? new Date(checkpoint.date_to_use).toLocaleString() : 'No date provided',
                        episode: checkpoint.episode_id ? checkpoint.episode_id.id : 'No episode',
                        alerts: alertsDetails, // string of all alerts and their nested notes
                        status: statusText, // status in HTML string
                        status_reason: checkpoint.status_reason?.replace(/_/g, ' ') ?? 'No reason provided',
                    },
                    color: dotColor,
                    task_type: checkpoint.task_type,
                    type: "Checkpoints",
                    source_record: checkpoint,
                    allDay: isAllDay,
                    className: cpClass,
                    display: "block",
                };

                calendarItems.push(checkpointItem);
            });
        }


        if (data.notes && (this.type === "notes" || this.type === "all")) {
            data.notes.map(note => {
                const calendarItem = {
                    start: convertUtcToLocalTime(note.date_created),
                    // end: convertUtcToLocalTime(note.date_created),
                    description: `${note.content}`,
                    title: `${note.user_created?.first_name?.[0] + '.' || ''} ${note.user_created?.last_name || ''}`,
                    source_record: note,
                    color: "#c9b89b",
                    type: "Note",
                    //display: "block",
                    className: 'cal-note cp-expander',
                    groupId: 'cal-note'
                }

                calendarItems.push(calendarItem);
            })
        }

        if (data.unavailabilities && (this.type === "availability" || this.type === "all")) {
            data.unavailabilities.map(unavailability => {

                let reason = "";
                let startTime = unavailability.start_time;

                if (unavailability.reason === "dr_appointment") {
                    reason = "Doctor's Appointment";
                    //startTime = unavailability.dr_appt_datetime;
                } else if (unavailability.reason === "travel") {
                    reason = "Travel";
                } else if (unavailability.reason === "dialysis") {
                    reason = "Dialysis";
                } else if (unavailability.reason === "other") {
                    reason = `Other: ${unavailability.reason_other}`;
                }


                const calendarItem = {
                    start: startTime,
                    //end: unavailability.end_time,
                    description: `${unavailability.notes}`,
                    clinician: `${unavailability.user_created.first_name?.[0] + '.' || ''} ${unavailability.user_created.last_name || ''}`,
                    title: reason,
                    source_record: unavailability,
                    color: "var(--bs-danger)",
                    type: "Unavailability",
                    className: 'cal-avail cp-expander',
                    groupId: 'cal-avail'
                }
                calendarItems.push(calendarItem);
            })
        }
        if (data.agency_visits && (this.type === "visits" || this.type === "all")) {
            data.agency_visits.map(visit => {
                const calendarItem = {
                    start: convertUtcToLocalTime(visit.visit_date),
                    allDay: true,
                    title_left: visit.visit_type === 'SN' ? `${visit.visit_type} Visit` : visit.visit_type,
                    // TODO: add conditional for oasis visits to show description
                    title_right: `${visit.careteam_member_id.first_name?.[0] + '.' || ''} ${visit.careteam_member_id.last_name || ''}`,
                    clinician: `${visit.careteam_member_id.first_name?.[0] + '.' || ''} ${visit.careteam_member_id.last_name || ''}`,
                    visit_status: visit.visit_status,
                    visit_type: visit.visit_type,
                    last_sync_date: visit.last_sync_date,
                    source_record: visit,
                    color: "#584e6e",
                    type: "Visits",
                    className: 'cal-visit cp-expander',
                    groupId: 'cal-visit'

                }
                //console.log('calendarItem from visits', calendarItem);
                calendarItems.push(calendarItem);
            })
        }
        //console.log('calendarItems MASTER', calendarItems);
        return calendarItems;
    }
}

customElements.define("app-patient-activity-calendar", AppPatientActivityCalendar);