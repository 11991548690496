import { html, render } from 'lit';
import AppAlertDetail from './app-alert-detail';

class AppAlertCard extends HTMLElement {
    set alert(value) {
        this._alert = value;
        this.render();
    }

    get alert() {
        return this._alert;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    connectedCallback() {
        const is_manual_alert = !this.alert.alert_rule_id
        this.template = () => html`
            <div
                style="
            padding: 24px;
            padding-bottom: 70px;
            box-sizing: border-box;
            background: #FFFFFF;
            border: 1px solid #ca463d;
            box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
            border-radius: 8px;
            position: relative;
        ">
                <h3 style="display: flex; flex-direction: row; align-items: center;">
                    <span
                        class="material-symbols-outlined"
                        style='
                    color: #ca463d;
                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                '
                        >report</span
                    >
                    <span
                        style="
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 26px;
                    color: #ca463d;
                    margin-left: 10px;
                "
                        >${this.alert.subject}</span
                    >
                </h3>
                <div
                    style="
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #201B3A;
            ">
                    Patient reported this alert on ${new Date(this.alert.date_created).toLocaleDateString()}
                </div>
                <a
                    style="
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px 20px;
                gap: 10px;
                width: 100%;
                margin-top: 10px;
                color: white;
                text-decoration: none;
                height: 40px;
                background: #ca463d;
                border-radius: 2px;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
            "
                    href="tel:${this.patient.primary_phone}"
                    >Call patient: ${this.patient.primary_phone}</a
                >
                <div
                    style="
                position: absolute;
                padding: 10px;
                bottom: 0px;
                left: 0px;
                margin-top: 10px;
                width: 100%;
                border-top: 1px solid #C8D6E4;
                display: flex;
                justify-content: center;
            ">
                    <button
                        @click=${(_e) => this.showAlert()}
                        class="btn"
                        style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 10px 20px;
                        gap: 10px;
                        border: 1px solid var(--t-color-primary);
                        border-radius: 2px;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--t-color-primary);
                        background-color: #fff;
                ">
                        View Details
                    </button>
                </div>
            </div>
        `;
        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.alert) return;
        if (!this.patient) return;

        render(this.template(), this);
    }

    async showAlert() {
        let alert_modal = new AppAlertDetail();
        alert_modal.alert = this.alert;
        alert_modal.patient = this.patient;
        await alert_modal.showModal();
        let result = await alert_modal.onDidDismiss();
        if (result) this.dispatchEvent(new CustomEvent("refresh-alerts", { bubbles: true, composed: true }));
    }
}

customElements.define("app-alert-card", AppAlertCard);
export default AppAlertCard;
