import { format, differenceInCalendarDays } from "date-fns";
import { html, render } from "lit";
import directus from "../../lib/lib-directus";
import { convertUtcToLocalTime } from "../../lib/lib-date";
import ModalBase from "../util/modal-base";

export default class AppAvailabilityDetail extends ModalBase {
    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    set availability(value) {
        this._availability = value;
        //
        //if (value.start_time) {
        //    value.start_time = new Date(value.start_time);
        //    value.start_time.setHours(5, 0, 0);
        //}
        //
        //if (value.end_time) {
        //    value.end_time = new Date(value.end_time);
        //    value.end_time.setHours(18, 0, 0);
        //}

        if (value.dr_appt_datetime) {
            value.dr_appt_datetime = new Date(value.dr_appt_datetime);
        }

        if (value.start_time && value.end_time) {
            this.multi_day = differenceInCalendarDays(value.end_time, value.start_time) + 1;
        }

        this.render();
    }

    get availability() {
        return this._availability;
    }

    set editing(value) {
        this._editing = !!value;
    }

    get editing() {
        return this._editing;
    }

    constructor(availability, patient, editing, readonly = false) {
        super();

        this.resetErrors();
        this.multi_day = false;
        this.disabled = false;
        this.confirm_delete = false;

        if (!availability || !availability.id) {
            this.is_new = true;
        } else {
            this.is_new = false;
        }

        if (availability) {
            this.availability = availability;
        } else {
            this.availability = {
                start_time: null,
                end_time: null,
                reason: null,
                reason_other: null,
                notes: null,
                dr_appt_datetime: null,
                dr_appt_doctor: null,
                dr_appt_specialty: null,
            };
        }

        if (patient) {
            this.patient = patient;
        }

        this.editing = !!editing;
        this.readonly = !!readonly;
    }

    connectedCallback() {
        this.template = () => html`
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${this.editing ? this.is_new ? "Add" : "Update" : "Viewing"} ${this.availability.reason == "hh_visit" ? "visit" : "unavailable"} date for ${this.patient?.first_name} ${this.patient?.last_name}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ${this.openTemplate()}
                    </div>
                </div>
            </div>
        `;

        this.render();
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }

    openTemplate() {
        if (!this.patient) return "";

        if (!this.editing) {
            return this.staticTemplate();
        }

        let multi_day = this.multi_day;

        return html`
       
        <p>Provide details for when the patient will not be available for a visit.</p>
        ${this.errors.global ? html`<div class="alert alert-danger">${this.errors.global}</div>` : ""}
        <div class="row">
            <div class="col col-md-6 mb-2">
                <label for="start_time" class="form-label">Start Date *</label>
                <input
                    type="date"
                    id="start_time"
                    class="form-control ${this.errors.start_time && "is-invalid"}"
                    @input=${(e) => this.onDateParamChange("start_time", e)}
                    ?disabled=${this.disabled}
                    value=${this.availability.start_time && format(this.availability.start_time, "yyyy-MM-dd")} />
                <div class="invalid-feedback">${this.errors.start_time}</div>
            </div>
            <div class="col col-md-6 mb-2">
                <label for="end_time" class="form-label">End Date</label>
                <input
                    type="date"
                    id="end_time"
                    class="form-control ${this.errors.end_time && "is-invalid"}"
                    @input=${(e) => this.onDateParamChange("end_time", e, true)}
                    ?disabled=${this.disabled}
                    value=${this.availability.end_time && format(this.availability.end_time, "yyyy-MM-dd")} />
                <div class="invalid-feedback">${this.errors.end_time}</div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-2">
                <label for="reason" class="form-label">Reason *</label>
                <select
                    id="reason"
                    class="form-select ${this.errors.reason && "is-invalid"}"
                    @change=${(e) => this.onStringParamChange("reason", e)}
                    ?disabled=${this.disabled}
                >
                    <option disabled ?selected=${!this.availability.reason}>Select...</option>
                    <option value="dr_appointment" ?selected=${this.availability.reason === "dr_appointment"
            }>Doctors Appointment</option>
                    <option value="dialysis" ?selected=${this.availability.reason === "dialysis"
            }>Dialysis</option>
                    <option value="travel" ?selected=${this.availability.reason === "travel"
            }>Travel</option>
                    <option value="other" ?selected=${this.availability.reason === "other"
            }>Other</option>
                </select>
                <div class="invalid-feedback">${this.errors.reason}</div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-2" style=${this.availability.reason === "other" ? "display: block;" : "display: none;"}>
                <label for="reason_other" class="form-label">Other Reason</label>
                <input
                    type="text"
                    id="reason_other"
                    class="form-control ${this.errors.reason_other && "is-invalid"}"
                    placeholder="Other reason"
                    value=${this.availability.reason_other}
                    ?disabled=${this.disabled}
                    @input=${(e) => this.onStringParamChange("reason_other", e)}/>
                <div class="invalid-feedback">${this.errors.reason_other}</div>
            </div>
        </div>
        <div style=${this.availability.reason === "dr_appointment" ? "display: block;" : "display: none;"}>
            <div class="row">
                <div class="col col-md-6 mb-2">
                    <label for="dr_start_time" class="form-label">Start Time *</label>
                    <input
                        type="time"
                        id="dr_start_time"
                        class="form-control ${this.errors.dr_appt_datetime && "is-invalid"}"
                        placeholder="Appointment time"
                        value=${this.availability.dr_appt_datetime && format(this.availability.dr_appt_datetime, "HH:mm")}
                        ?disabled=${this.disabled}
                        @input=${(e) => this.onStartTimeChange(e)} />
                </div>
                <div class="col col-md-6 mb-2">
                    <label for="dr_end_time" class="form-label">End Time</label>
                    <input
                        type="time"
                        id="dr_end_time"
                        class="form-control ${this.errors.end_time && "is-invalid"}"
                        placeholder="Appointment time"
                        value=${this.availability.end_time && format(this.availability.end_time, "HH:mm")}
                        ?disabled=${this.disabled}
                        @input=${(e) => this.onEndTimeChange(e)} />
                </div>
            </div>
            <div class="row">
                <div class="mb-2">
                    <label for="dr_appt_doctor" class="form-label">Doctor's Name *</label>
                    <input
                        type="text"
                        id="dr_appt_doctor"
                        class="form-control ${this.errors.dr_appt_doctor && "is-invalid"}"
                        placeholder="Doctor's name"
                        value=${this.availability.dr_appt_doctor}
                        ?disabled=${this.disabled}
                        @input=${(e) => this.onStringParamChange("dr_appt_doctor", e)} />
                    <div class="invalid-feedback">${this.errors.dr_appt_doctor}</div>
                </div>
                <div class="mb-2">
                    <label for="dr_appt_specialty" class="form-label">Specialty *</label>
                    <input
                        type="text"
                        list="dr_appt_specialty_list"
                        id="dr_appt_specialty"
                        class="form-control ${this.errors.dr_appt_specialty && "is-invalid"}"
                        placeholder="Specialty"
                        value=${this.availability.dr_appt_specialty}
                        ?disabled=${this.disabled}
                        @input=${(e) => this.onStringParamChange("dr_appt_specialty", e)} />
                    <div class="invalid-feedback">${this.errors.dr_appt_specialty}</div>
                    <datalist id="dr_appt_specialty_list">
                        <option value=""></option>
                        <option value="Allergy and immunology">Allergy and immunology</option>
                        <option value="Anesthesiology">Anesthesiology</option>
                        <option value="Dermatology">Dermatology</option>
                        <option value="Radiology">Radiology</option>
                        <option value="Emergency medicine">Emergency medicine</option>
                        <option value="Family medicine">Family medicine</option>
                        <option value="Internal medicine">Internal medicine</option>
                        <option value="Medical genetics">Medical genetics</option>
                        <option value="Neurology">Neurology</option>
                        <option value="Nuclear medicine">Nuclear medicine</option>
                        <option value="Obstetrics and gynecology">Obstetrics and gynecology</option>
                        <option value="Ophthalmology">Ophthalmology</option>
                        <option value="Pathology">Pathology</option>
                        <option value="Pediatrics">Pediatrics</option>
                        <option value="Physical medicine and rehabilitation">Physical medicine and rehabilitation</option>
                        <option value="Preventive medicine">Preventive medicine</option>
                        <option value="Psychiatry">Psychiatry</option>
                        <option value="Oncology">Oncology</option>
                        <option value="Surgery">Surgery</option>
                        <option value="Urology">Urology</option>
                        <option value="Other">Other</option>
                    </datalist>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mb-2">
                <label for="additional_notes" class="form-label">Additional Notes</label>
                <textarea
                    id="additional_notes"
                    class="form-control"
                    placeholder="Add any additional notes..."
                    ?disabled=${this.disabled}
                    @input=${(e) => this.onStringParamChange("notes", e)}
                    .value=${this.availability.notes || ""}></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col mb-3">
                <small>* indicates a required field</small>
            </div>
        </div>
        <div class="row">
            <div class="col text-end">
                 
                 
                <button class="btn btn-primary" @click=${(e) => this.saveAvailability(e)}>${this.is_new ? "Add" : "Update"}</button>
                <button class="btn btn-danger" @click=${() => this.dismiss(null)}>Cancel</button>
            </div>
        </div>
        `;
    }

    staticTemplate() {
        let end_str = "";
        //console.log(this.availability.end_time);
        if (this.multi_day) {
            end_str = html`<span style="margin: 0 5px 0 10px !important; color:#bdbdbd ">through</span> ${format(this.availability.end_time, "MMM do, yyyy")}`;
        }

        let reason;
        switch (this.availability.reason) {
            case "dr_appointment":
                reason = "Doctor's Appointment";
                break;
            case "travel":
                reason = "Travel";
                break;
            case "dialysis":
                reason = "Dialysis";
                break;
            case "other":
                reason = `Other (${this.availability.reason_other})`;
                break;
            case "hh_visit":
                reason = `Visit (${this.availability.visit_description})`;
                break;
        }

        const buttons_row = !this.confirm_delete ? html`
            <div class="row">
                <div class="col">
                    <a href="javascript:void(0)"
                        class="btn btn-danger"
                        @click=${(e) => this.confirmArchive(e, true)}>Delete</a>
                </div>
                
                <div class="col text-end">
                     
                    <a href="javascript:void(0);" class="btn btn-primary edit-btn" @click=${(e) => {
                e.stopPropagation();
                this.editing = !this.editing;
                this.render();
            }}>Edit</a>
                   <!-- <a href="javascript:void(0)" class="btn btn-danger" @click=${() => this.dismiss(false)}>Close</a> -->
                </div>
            </div>
        `: html`
                <div class="row">
                    <div class="col text-end">
                        <strong>Are you sure you want to delete?</strong>
                        <a href="javascript:void(0)"
                            class="btn btn-danger"
                            @click=${(e) => this.delete(e)}>Delete</a>
                        <a href="javascript:void(0)"
                            class="btn btn-secondary"
                            @click=${(e) => this.confirmArchive(e, false)}>Cancel</a>
                    </div>
                </div>
            `
        return html`
            <div class="row">
                <div class="col mb-2">
                    <small>${format(this.availability.start_time, "MMM do, yyyy")}${end_str}</small>
                    
                </div>
                </div>
                <div class="row">
                    <div class="col mb-2">
                        ${this.availability.reason === "hh_visit" ? html`Visit description` : "Reason"}: <strong>${reason}</strong>
                    </div>
                </div>
                ${this.availability.reason === "dr_appointment" ? html`<div class="row">
                    <div class="col mb-2">
                        Apointment: <strong>${format(new Date(this.availability?.start_time), "MMM do, yyyy hh:mm a")}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-2">
                        With: <strong>${this.availability.dr_appt_doctor} (${this.availability.dr_appt_specialty})</strong>
                    </div>
                </div>` : ""}
                <div class="row">
                    <div class="col mb-2">${this.availability.notes}</div>
                </div>
                ${this.readonly ? null : buttons_row}
            </div>
        `
    }

    async saveAvailability(e) {
        e.stopPropagation();
        if (!this.isValid()) {
            return;
        }

        this.disabled = true;
        this.render();

        this.availability.patient = this.patient.id;
        const client_id = this.patient.client_id?.id || this.patient.client_id;
        this.availability.client_id = client_id;

        try {
            const patientAvalabilityService = directus.items("patient_availability");
            if (this.is_new && !this.availability.id) {
                this.availability = await patientAvalabilityService.createOne(this.availability);
            } else {
                this.availability = await patientAvalabilityService.updateOne(this.availability.id, this.availability);
            }

            this.dismiss(this.availability);
        } catch (err) {
            this.errors.global = "Error saving, please retry. If the error persists please contact support. ";
            this.disabled = false;
            this.render();
        }
    }
    async delete(e) {
        try {
            e.stopPropagation();
            this.disabled = true;
            this.render();

            // Delete the record instead of updating it
            await directus.items("patient_availability").deleteOne(this.availability.id);
            await this.dismiss(true);
        } catch (err) {
            this.errors.global = "Error deleting this record. If the problem persists, please contact support.";
            this.disabled = false;
            this.render();
        }
    }
    async archive(e) {
        try {
            e.stopPropagation();
            this.disabled = true;
            this.render();
            this.availability.status = "archived";
            const client_id = this.availability.client_id?.id || this.availability.client_id;
            this.availability.client_id = client_id;

            await directus.items("patient_availability").updateOne(this.availability.id, this.availability);
            await this.dismiss(true);
        } catch (err) {
            this.errors.global = "Error deleting this record, if the problem perisists please contact support. ";
            this.disabled = false;
            this.render();
        }
    }

    isValid() {
        this.resetErrors();
        let has_errors = false;

        // validate and go
        if (!this.availability.start_time) {
            this.errors.start_time = "A start date is required. ";
            has_errors = true;
        }

        if (
            this.availability.start_time &&
            this.availability.end_time.getTime() < this.availability.start_time.getTime()
        ) {
            this.errors.end_time = "End date cannot "
            has_errors = true;
        }

        if (!this.availability.reason) {
            this.errors.reason = "Please provide a reason. ";
            has_errors = true;
        }

        if (this.availability.reason === "dr_appointment") {
            if (!this.availability.dr_appt_datetime) {
                this.errors.dr_appt_datetime = "Please provide a date and time. ";
                has_errors = true;
            }

            /* commenting this validation out because we're changing the way start and end time work
            as a temporary measure to fix the timezone issue - ccg
            if (
                this.availability.start_time &&
                this.availability.dr_appt_datetime &&
                this.availability.dr_appt_datetime.getTime() <= this.availability.start_time.getTime()
            ) {
                this.errors.dr_appt_datetime += "Appointment is before start date. ";
                has_errors = true;
            }

            if (
                this.availability.end_time &&
                this.availability.dr_appt_datetime &&
                this.availability.dr_appt_datetime.getTime() >= this.availability.end_time.getTime()
            ) {
                this.errors.dr_appt_datetime += "Appointment is after end date. ";
                has_errors = true;
                console.log(this.availability.dr_appt_datetime, this.availability.end_time);
            }
            */

            if (!this.availability.dr_appt_doctor) {
                this.errors.dr_appt_doctor = "Please provide the doctor's name. ";
                has_errors = true;
            }

            if (!this.availability.dr_appt_specialty) {
                this.errors.dr_appt_specialty = "Please provide the doctor's specialty. ";
                has_errors = true;
            }
        }

        if (this.availability.reason === "other" && !this.availability.reason_other) {
            this.errors.reason_other = "Please provide the reason. ";
            has_errors = true;
        }

        if (has_errors) {
            this.render();
        }

        return !has_errors;
    }

    confirmArchive(e, confirm_delete) {
        e.stopPropagation();

        this.confirm_delete = confirm_delete;
        this.render();
    }

    onStringParamChange(key, e) {
        this.availability[key] = e.target.value;
        if (key === "reason" && e.target.value === "dr_appointment" && this.availability.start_time) {
            this.availability.dr_appt_datetime = new Date(this.availability.start_time);
            const now = new Date();
            this.availability.dr_appt_datetime.setHours(now.getHours() + 1, 0, 0);
        }
        this.render();
    }

    onDateParamChange(key, e, end_of_day) {
        if (!e.target.value) this.availability[key] = null;
        const [year, month, day] = e.target.value.split("-");
        this.availability[key] = new Date(year, parseInt(month) - 1, day);

        if (end_of_day) {
            this.availability[key].setHours(18, 0, 0);
        } else {
            this.availability[key].setHours(5, 0, 0);
        }

        if (
            this.availability.start_time &&
            this.availability.end_time &&
            differenceInCalendarDays(this.availability.end_time, this.availability.start_time) > 0
        ) {
        } else {
            this.multi_day = false;
            const { dr_appt_datetime, start_time } = this.availability;
            if (dr_appt_datetime) {
                dr_appt_datetime.setDate(start_time.getDate());
            }
        }

        this.render();
    }

    onStartTimeChange(e) {
        if (this.availability.start_time && !this.availability.dr_appt_datetime) {
            this.availability.dr_appt_datetime = new Date(this.availability.start_time);
        } else if (!this.availability.dr_appt_datetime) {
            this.availability.dr_appt_datetime = new Date();
        }

        if (e.target.value.indexOf(":") !== -1) {
            const [hours, minutes] = e.target.value.split(":");
            this.availability.dr_appt_datetime.setHours(hours, minutes, 0);
            this.availability.start_time = this.availability.dr_appt_datetime;
            this.render();
        }
    }

    onEndTimeChange(e) {
        if (!this.availability.end_time) {
            this.availability.end_time = new Date();
        }

        if (e.target.value.indexOf(":") !== -1) {
            const [hours, minutes] = e.target.value.split(":");
            this.availability.end_time.setHours(hours, minutes, 0);
            this.render();
        }
    }

    resetErrors() {
        this.errors = {
            start_time: "",
            end_time: "",
            reason: "",
            reason_other: "",
            notes: "",
            dr_appt_datetime: "",
            dr_appt_doctor: "",
            dr_appt_specialty: "",
        };
    }
}

customElements.define("app-availability-detail", AppAvailabilityDetail);
