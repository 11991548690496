import ApplicationState from "applicationstate";
import { html, render } from "lit";
import { styleMap } from "lit/directives/style-map.js";
import "./app-current-client-select";
import "./app-live-badge";

// TODO: potentially make wrapper vanilla webcomponent for fancy animations

/**
 * @typedef {object} NotificationBadgeSettings
 * @property {string} state_path - The path to listen to in ApplicationState
 */

/**
 * @typedef {object} SidebarRoute
 * @property {string} href - The link to navigate to
 * @property {string} label - The label to display
 * @property {string} icon - The icon to use
 * @property {NotificationBadgeSettings} notification_settings - Settings to display a notification badge on the sidebar
 */

/**
 * Main Sidebar for the app
 * Initial markup taken from https://getbootstrap.com/docs/5.0/examples/sidebars/
 */
export default class AppSidebar extends HTMLElement {
    set app_title(value) {
        this._app_title = value;
    }

    get app_title() {
        return this._app_title;
    }

    /**
     * @type {NotificationBadgeSettings[]}
     */
    get routes() {
        return this._routes || [];
    }

    set routes(values) {
        if (!Array.isArray(values)) return;
        this._routes = values;
        this.render();
    }

    get utilities() {
        return this._utilities || [];
    }

    set utilities(values) {
        if (!Array.isArray(values)) return;
        this._utilities = values;
    }

    constructor() {
        super();
        this._is_clinician_app = ApplicationState.get("app.name") === "clinician";
        // re-render when location changes for nav highlight
        window.addEventListener("popstate", this.render.bind(this));
        // Listen for the sidebar open event
        document.addEventListener("sidebar:open", () => {
            if (!this.classList.contains("open")) {
                // debounce open to allow auto-close events to hit first
                setTimeout(() => {
                    this.classList.add("open");
                }, 100);
            }
        });
        // listen for the sidebar close event
        document.addEventListener("sidebar:close", () => {
            if (this.classList.contains("open")) {
                this.classList.remove("open");
            }
        });
        // listen for the sidebar toggle event
        document.addEventListener("sidebar", (_e) => {
            if (this.classList.contains("open")) {
                this.classList.remove("open");
            } else {
                // debounce open to allow auto-close events to hit first
                setTimeout(() => {
                    this.classList.add("open");
                }, 100);
            }
        });
    }

    /**
     * Uses lit's styleMap to generate dynamic nav item styles
     * @param {boolean} is_active if the nav item is active
     * @returns {string} html style string
     */
    getNavItemStyleMap(is_active) {
        const active_styles = is_active
            ? {
                //backgroundColor: "#465b71",
                //borderRight: `6px solid ${this._is_clinician_app ? "var(--t-color-primary)" : "var(--t-color-white)"}`,
                //paddingLeft: "5px !important",
                //color: "var(--t-color-white)",
                //boxShadow: "0px 97px 90px -79px rgba(0, 0, 0, 0.75)"

            }
            : {};
        const styles = Object.assign(
            {
                display: "flex",
                alignItems: "center",
                color: this._is_clinician_app ? "var(--t-color-primary)" : "var(--t-color-white)",
                fontVariationSettings: "'opsz' 16",
                fontSize: "14px",
                borderRadius: 0,
                height: "48px",
                padding: "14px 24px",
                width: "100%",
            },
            active_styles
        );

        return styleMap(styles);
    }

    /**
     * Render helper for nav items
     * @param {SidebarRoute} sidebar_route
     * @param {string} class_names optional class names to add to the wrapping <li> tag.
     * @returns lit html
     */
    renderNavItem(sidebar_route, class_names = "") {
        let nav_item_route = sidebar_route.href;
        let nav_item_label = sidebar_route.label;
        let material_icon_name = sidebar_route.icon;
        let path = location.pathname;
        let base = new URL(document.querySelector("base").href).pathname;
        if (path.startsWith(base)) path = path.substring(base.length);
        const first_part_of_path = path.split("/")[0];
        const is_active = nav_item_route === first_part_of_path;
        let font_weight = is_active ? 700 : 600;
        if (nav_item_route === null) font_weight = 400;
        return html`
            <li class=${class_names} style="position: relative">
                <a
                    href=${nav_item_route}
                    @click=${() => this.classList.remove("open")}
                    class="nav-link ${is_active}"
                    style=${this.getNavItemStyleMap(is_active)}>
                    <span class="material-symbols-outlined" style="width: 24px;">
                        ${material_icon_name}
                    </span>
                    <span
                        style=${styleMap({
            fontWeight: font_weight,
            marginLeft: "20px",
        })}>
                        ${nav_item_label}
                    </span>
                    ${sidebar_route.notification_settings?.state_path ?
                html`
                    <app-live-badge .state_path=${sidebar_route.notification_settings.state_path}></app-live-badge>
                    `: ''}
                </a>
            </li>
        `;
    }

    connectedCallback() {
        const currentPath = window.location.pathname.split('/');
        const basePath = currentPath[1]; // This will give you the root of the current app
        //linkElement.href = `/${basePath}/dashboard/overview`;

        this.template = () => html`
        <a href="/${basePath}/dashboard/overview" class="logo-link">
            <img
                class="vb-logo"
                src="images/vb-h-logo-white-alpha.svg"
                alt="Visit Bridge"/>
            </a>
            <!-- <div
                style="
                display: flex;
                align-items: center;
                justify-content: center;
            ">
                <span
                    class="badge rounded-pill bg-white text-dark"
                    style="
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 600;
                    padding-left: 7px;
                    padding-right: 7px;
                    box-shadow: var(--t-box-shadow);
                ">
                    ${this.app_title}
                </span>
            </div> -->
            <app-current-client-select></app-current-client-select>
            <ul class="nav nav-pills flex-column mb-auto">
                ${this.routes.map((r) => this.renderNavItem(r))}
            </ul>
            ${this.utilities.length ? html`
            <ul class="nav nav-pills flex-column mt-auto" style="margin-bottom: 46px;">
                ${this.utilities.map((r) => this.renderNavItem(r))}
            </ul>` : ""}
        `;

        Object.assign(this.style, {
            background: "var(--t-color-sidebar)",
            display: "flex",
            flexDirection: "column",
            boxShadow: "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px",
        });
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-sidebar", AppSidebar);
