import { html, render } from "lit";
import { ref, createRef } from "lit/directives/ref.js";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"

import directus from "shared/lib/lib-directus";
import DataDefinition from "shared/lib/lib-data-definition";
import { adddirectusClientFilter, getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";

import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";

window.luxon = { DateTime };

/**
 * @todo report superclass with common sort/filter functions, calls this.fetchData()
 * An encapuslated component showing 'high-risk' (level 1) alerts from the last 30 days.
 * Data matches dashboard widget <app-dashboard-high-risk-events>.
 */
export default class ReportPatientCpReports extends HTMLElement {
    static get config() {
        return {
            icon: "warning",
            report: "report-patient",
            title: "Patient Checkpoint Reports",
            description: "Reports that pulls all checkpoint information for all patients",
            component: ReportPatientCpReports
        };
    }

    constructor() {
        super();
        this.data = [];
        this.tot_data = null;
        this.data_table = null;
        this.survey_definition = null;
        this._loading_data = true;

        // filters
        this.patient_status_filter_options = [];
        this.incident_type_filter_options = [];
        this.alert_level_filter_options = [];
        const filter_string = new URL(location.href).searchParams?.get("filters")
        const { fromDate, toDate } = this.defaultFilterDates();
        this.filters = JSON.parse(filter_string) || {
            "cp_date_created_range": `${fromDate},${toDate}`
        };
        //pagination
        this._current_page = 1;
        this._page_size = 1000000;
        this._all_pages_fetched = false;
        //sort
        this.sort_fields = [
            { column: "patient_status", direction: "asc" },
        ];

        this.header_ref = createRef();
        this.content_ref = createRef();
    }

    defaultFilterDates() {
        const curDate = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(curDate.getDate() - 30);  // 30 days ago from current date.

        const fromDate = `${thirtyDaysAgo.getFullYear()}-${String(thirtyDaysAgo.getMonth() + 1).padStart(2, '0')}-${String(thirtyDaysAgo.getDate()).padStart(2, '0')}`;
        const toDate = `${curDate.getFullYear()}-${String(curDate.getMonth() + 1).padStart(2, '0')}-${String(curDate.getDate()).padStart(2, '0')}`;

        return {
            "fromDate": fromDate,
            "toDate": toDate
        }
    }

    get filters_expanded() {
        return this._filters_expanded;
    }

    set filters_expanded(value) {
        this._filters_expanded = value;
        this.render();
    }

    connectedCallback() {
        // This alows showing/hiding the filter dropdown through css and avoid multiple filter instances
        // const filters_breakpoint_query = window.matchMedia("(min-width: 1275px)");
        // this.filters_expanded = filters_breakpoint_query.matches
        // filters_breakpoint_query.addEventListener("change", (evt) => {
        //     this.filters_expanded = evt.matches
        //     if (!evt.matches) {
        //         this.initFilterDropdown();
        //     }
        // });

        this.template = () => {
            return html`
                <div id="main-content" class="container-fluid">
                        <div id="widget_wrapper">
                                <div class="button-list">
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Date Reported"}
                                            .is_datepicker=${true}
                                            .value=${this.filters.cp_date_created_range}
                                            @dateselect=${e => this.handleDateFilterChange(e)}
                                            @clear=${_e => this.handleFilterClear("cp_date_created_range")}
                                        ></app-report-filter>
                                    </div>
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Patient Status"}
                                            .options=${this.patient_status_filter_options}
                                            .value=${this.filters.patient_status}
                                            @optionclick=${e => this.handleFilterChange("patient_status", e)}
                                            @clear=${_e => this.handleFilterClear("patient_status")}
                                        ></app-report-filter>
                                    </div>
                                </div>
                                <div class="cp-stats mod-base"><span>${this.tot_data?.completed_cnt}</span>Completed</div>
                                <div class="cp-stats mod-base"><span>${this.tot_data?.uncompleted_cnt}</span>Uncompleted</div>
                                <div class="cp-stats mod-base"><span>${this.tot_data?.pending_cnt}</span>Pending</div>
                                <div class="cp-stats mod-base"><span>${this.tot_data?.total_cnt}</span>Total</div>
                        </div>
                        <div class="mod-base" id="patient-cp-report-table" data-toggle="table"></div>
                    </div>
                </div>
            `
        }

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Load survey definitions, fetch report data, and update table.
     */
    async init() {
        this.fetchFilterOptions();
        await this.fetchReportData();
        this.initTable();
        this.setTableHeight();
    }

    /**
     * Fetches latest report data and updates the data table. 
     */
    async fetchReportData() {
        if (!this._page_size) {
            // still being calculated/initialized
            return;
        }
        this._loading_data = true;
        const client_id = getCurrentClientId()
        const {
            patient_status = ['active', 'inactive'],
            cp_date_created_range,
        } = this.filters
        const res = await directus.transport.get('/vbh/reports/cp_reports', {
            params: {
                filters: {
                    client_ids: [client_id],
                    patient_status,
                    cp_date_created_range,
                },
                page_size: this._page_size,
                page: this._current_page,
                sort: JSON.stringify(this.sort_fields)
            }
        });

        // Tabulator Table
        if (res.data.length > 0) {
            this.tot_data = res.data[0];
            res.data.splice(0, 1);
        }
        this.data = this.formatRecords(res.data);
        this.data_table?.setData(this.data);

        this._all_pages_fetched = res.data.length < this._page_size;

        this.render();
        this._loading_data = false;
    }

    /**
     * Format records to display tabulator table.
     * @param {*} records 
     * @returns 
     */
    formatRecords(records) {
        //console.log("formatRecords" + JSON.stringify(records));

        return records
            .filter(record => record.current_episode_id !== null && record.last_task_id !== null)
            .map(record => ({
                patient_id: record.patient_id,
                patient_status: (record.patient_status?.charAt(0)?.toUpperCase() + record.patient_status?.slice(1)) || "",
                patient_name: (record.patient_last_name + ', ' + record.patient_first_name).replace(/^,/, ''),
                completed_cnt: record.completed_cnt,
                uncompleted_cnt: record.uncompleted_cnt,
                pending_cnt: record.pending_cnt,
                total_cnt: record.total_cnt
            }));
    }

    async fetchFilterOptions() {
        const alert_definition_request = DataDefinition.getDefinition("alert");
        const patient_definition_request = DataDefinition.getDefinition("patient");
        const alert_rule_descriptions_request = directus.items("alert_rule").readByQuery({
            filter: {
                _and: [
                    {
                        status: {
                            _eq: 'published'
                        }
                    },
                    {
                        _or: [
                            { client_id: { _null: true } },
                            { client_id: getCurrentClientId() },
                        ]
                    }
                ]
            },
            fields: ["description"],
            limit: -1,
        });

        const primary_clinician_request = directus.items("junction_directus_users_client").readByQuery({
            fields: ["*", "directus_users_id.*"],
            filter: adddirectusClientFilter({
                directus_users_id: {
                    role: {
                        name: { _in: ["Clinician", "Client"] }
                    }
                },
            })
        });

        const [
            alert_definition,
            patient_definition,
            alert_rule_descriptions,
            primary_clinician_data
        ] = await Promise.all([
            alert_definition_request,
            patient_definition_request,
            alert_rule_descriptions_request,
            primary_clinician_request
        ]);

        const unique_descriptions = new Set(alert_rule_descriptions?.data?.map(rule => rule.description))

        this.alert_level_filter_options = alert_definition.field_dictionary.level.meta.options.choices.map(
            choice => ({
                value: String(choice.value),
                label: choice.text,
            })
        );
        this.patient_status_filter_options = patient_definition.field_dictionary.status.meta.options.choices
            .filter(choice => choice.value === "active" || choice.value === "inactive")
            .map(
                choice => ({
                    value: choice.value,
                    label: choice.text,
                })
            );
        this.incident_type_filter_options = Array.from(unique_descriptions).map(value => ({
            value,
            label: value,
        }));

        const unique_clinician_ids = new Set();
        this.primary_clinician_options = primary_clinician_data.data
            .map(item => ({
                value: item.directus_users_id.id,
                label: `${item.directus_users_id.first_name || ''} ${item.directus_users_id.last_name || ''}`,
            }))
            .filter(option => {
                if (!unique_clinician_ids.has(option.value)) {
                    unique_clinician_ids.add(option.value);
                    return true;
                }
                return false;
            });

        this.render();
    }

    /**
     * @param {CustomEvent} e AppReportFilter#dateselect event
     */
    handleDateFilterChange(e) {
        const { start, end } = e.detail
        this.filters["cp_date_created_range"] = `${getISODateStringWithoutTime(start)},${getISODateStringWithoutTime(end)}`;
        this._current_page = 1;
        this.fetchReportData();
    }

    /**
     * handler for AppReportFilter#clear
     * @param {string} filter_key one of this.filters
     */
    handleFilterClear(filter_key) {
        delete this.filters[filter_key];
        this._current_page = 1;
        this.fetchReportData();
    }

    /**
     * @param {string} filter_key one of this.filters
     * @param {CustomEvent} e AppReportFilter#optionclick event
     */
    handleFilterChange(filter_key, e) {
        if (e.detail) this.filters[filter_key] = e.detail;
        else delete this.filters[filter_key];
        this._current_page = 1;
        this.fetchReportData();
    }

    /**
     * Initializes an instance of BootstrapTable with the specified options. 
     */
    initTable() {
        //console.log("initTable" + JSON.stringify(this.filters));
        this.data_table = new Tabulator("#patient-cp-report-table", {
            data: this.data,           //load row data from array
            history: true,             //allow undo and redo actions on the table
            pagination: "local",       //paginate the data
            paginationSize: 10,         //allow 7 rows per page of data
            paginationCounter: "rows", //display count of paginated rows in footer
            layout: "fitDataStretch",
            layoutColumnsOnNewData: true, //fit columns to width of table (optional)
            movableColumns: true,      //allow column order to be changed
            initialSort: [             //set the initial sort order of the data
                { column: "patient_status", dir: "asc" },
            ],
            columnDefaults: {
                tooltip: true,         //show tool tips on cells
            },
            columns: [
                { title: "Patient Status", field: "patient_status" },
                { title: "Patient Name", field: "patient_name" },
                { title: "Complted Checkpoints", field: "completed_cnt" },
                { title: "Uncompleted Checkpoints", field: "uncompleted_cnt" },
                { title: "Pending Checkpoints", field: "pending_cnt" },
                { title: "All Checkpoints", field: "total_cnt" },
            ]
        });

        this.data_table.on("rowClick", function (e, row) {
            let report = row.getData();
            navigate(`patients/${report.patient_id}/checkpoints`)
        });
    }

    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }
}

customElements.define("report-patient-cp-reports", ReportPatientCpReports);
