import { html, render } from "lit";
import { navigate } from "shared/lib/lib-router";
import {
  Chart,
  DoughnutController,
  ArcElement,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";
Chart.register(DoughnutController, ArcElement, CategoryScale, Tooltip, Legend);

export default class AppDashboardSatisfactionChart extends HTMLElement {
  constructor() {
    super();
    //this.report_filters = { patient_status: ["active"] };
    this.report_filters = {};
    this._chart = null;
  }

  set metrics(value) {
    if (this._metrics !== value) {
      this._metrics = value;
      this.updateChart();
    }
  }

  get metrics() {
    return this._metrics;
  }

  async formatData() {
    const patient_satisfaction = this.metrics?.patient_satisfaction || [];

    const labels = ["Very satisfied", "Somewhat satisfied", "Unsatisfied", "Very unsatisfied"];
    const backgroundColors = ["#9ac58f", "#7AB3BF", "#F2C777", "#F2AA80"];

    const data = {
      labels: labels,
      datasets: [{
        label: "",
        data: labels.map(label => {
          const item = patient_satisfaction.find(item => item.result_category === label);
          return item ? item.result_percentage : 0;
        }),
        backgroundColor: backgroundColors,
      }],
    };

    const satisfactionCounts = labels.reduce((acc, label) => {
      const item = patient_satisfaction.find(item => item.result_category === label);
      acc[label] = item ? item.result_count : 0;
      return acc;
    }, {});

    return { data, satisfactionCounts };
  }

  async updateChart() {
    if (!this.metrics) return;

    const { data, satisfactionCounts } = await this.formatData();

    if (this._chart) {
      this._chart.data = data;
      this._chart.options.plugins.legend.labels.generateLabels = chart => {
        const data = chart.data;
        return data.labels.map((label, i) => {
          const value = satisfactionCounts[label];
          return {
            text: label + ':  ' + value + '       ',
            fillStyle: data.datasets[0].backgroundColor[i],
            boxWidth: 2,
            index: i,
            strokeStyle: "transparent",
            pointStyleWidth: 2,
          };
        });
      };
      this._chart.update();
    } else {
      const chartEl = this.querySelector("#satisfaction-chart");
      if (!chartEl) {
        console.error("Chart canvas element not found");
        return;
      }

      this._chart = new Chart(chartEl, {
        type: "doughnut",
        data: data,
        options: {
          maintainAspectRatio: false,
          cutout: "50%",
          responsive: true,
          borderRadius: 10,
          animation: {
            animateRotate: true,
            animateScale: false,
          },
          elements: {
            arc: {
              borderWidth: 7,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'left',
              fullSize: true,
              boxWidth: 80,
              onHover: (event, legendItem, legend) => {
                const chart = legend.chart;
                const dataset = chart.getDatasetMeta(0); // assuming you only have one dataset
                const arc = dataset.data[legendItem.index];
                chart.setActiveElements([{ datasetIndex: 0, index: legendItem.index }]);
                chart.update('none');
                chart.tooltip.setActiveElements([{ datasetIndex: 0, index: legendItem.index }], { x: arc.x, y: arc.y });
                chart.tooltip.update(true);
                chart.draw();
              },
              onLeave: (event, legendItem, legend) => {
                const chart = legend.chart;
                chart.setActiveElements([]);
                chart.tooltip.setActiveElements([], {});
                chart.update('none');
              },
              labels: {
                usePointStyle: true,
                padding: 20,
                boxWidth: 25,
                boxHeight: 11,
                pointStyle: 'circle',
                pointStyleWidth: 15,
                pointStyleHeight: 10,
                font: {
                  size: 14,
                  family: "Quicksand",
                  lineHeight: '550px',
                },
                generateLabels: chart => {
                  const data = chart.data;
                  return data.labels.map((label, i) => {
                    const value = satisfactionCounts[label];
                    return {
                      text: label + ':  ' + value + '       ',
                      fillStyle: data.datasets[0].backgroundColor[i],
                      boxWidth: 2,
                      index: i,
                      strokeStyle: "transparent",
                      pointStyleWidth: 2,
                    };
                  });
                },
              },
            },
            tooltip: {
              enabled: true,
              displayColors: false,
              callbacks: {
                title: function () { return ''; },
                label: (tooltipItem, data) => {
                  return `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)}%`;
                },
              },
            },
          },
        },
      });
    }
  }

  connectedCallback() {
    this.render();
    this.updateChart();
  }

  render() {
    this.template = () => {
      return html`
      <div
        id="dashboard-satisfaction"
        style="
          height: 100%; 
          cursor: pointer;
          display: flex;
          flex-direction: column;
        "
        @click=${() =>
          navigate(
            `reports/patient/satisfaction`
          )}
      >
        <h6>Patient's Satisfaction Level</h6>
        <div class="dash-chart">
          <canvas id="satisfaction-chart"></canvas>
        </div>
      </div>
    `;
    };
    Object.assign(this.style, {
      background: "var(--t-color-white)",
      boxShadow: "var(--t-box-shadow)",
      borderRadius: "8px",
      display: "block",
      padding: "24px",
    });
    render(this.template(), this);
  }
}

customElements.define(
  "app-dashboard-satisfaction-chart",
  AppDashboardSatisfactionChart
);
