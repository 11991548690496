import cache from "./lib-cache";
import DataDefinition from "./lib-data-definition";


class FormDesignUtil {
    constructor(form_design_id) {
        this.form_design_id = form_design_id;
    }

    async load() {
        this.form_design = await cache.getFormDesign(this.form_design_id);
        this.flattenComponentsByKey(true);
        this.flattenComponentsFullPath(true);
    }

    /**
     * Get a component by its unique key
     * @param {*} key 
     * @returns 
     */
    getComponent(key) {
        return this.components_by_key[key];
    }

    getFieldLabel(field_name) {
        let component = this.getComponent(field_name);

        if(!component)
            return DataDefinition.formatFieldName(field_name);

        if(component.properties?.report_label)
            return component.properties.report_label;

        return DataDefinition.formatFieldName(field_name);
    }

    async getChoiceLabel(field_name, field_value) {
        let component = this.getComponent(field_name);
        if(!component)
            return '';

        let values = component.data?.values;

        if(!values)
            return '';
        if(!values.length)
            return '';

        let value = values.find(
            value => value.value == field_value
        )

        if(!value)
            return '';

        return value.label

    }

    /**
     * Return an array of keys for components that have the specified parent_field property.
     * This is used to determine the supplemental questions for a given field name
     * @param {*} field_name 
     * @returns 
     */
    getKeysWithParentField(field_name) {
        let keys = [];
        this.eachComponent((component, path) => {
            if(component.properties?.parent_field == field_name)
                keys.push(component.key)
        });
        return keys;
    }

    /**
     * Iterate through each component within a form.
     *
     * @param {Object} components
     *   The components to iterate.
     * @param {Function} fn
     *   The iteration function to invoke for each component.
     * @param {Boolean} includeAll
     *   Whether or not to include layout components.
     * @param {String} path
     *   The current data path of the element. Example: data.user.firstName
     * @param {Object} parent
     *   The parent object.
     */
    eachComponent(fn, includeAll, path, parent, inRecursion, components) {
        if(!components)
            components = this.form_design?.form_schema?.components;
        if (!components) return;
        path = path || '';
        if (inRecursion) {
            if (components.noRecurse) {
                delete components.noRecurse;
                return;
            }
            components.noRecurse = true;
        }
        components.forEach((component) => {
            if (!component) {
                return;
            }
            const hasColumns = component.columns && Array.isArray(component.columns);
            const hasRows = component.rows && Array.isArray(component.rows);
            const hasComps = component.components && Array.isArray(component.components);
            let noRecurse = false;
            const newPath = component.key ? (path ? (`${path}.${component.key}`) : component.key) : '';

            // there's no need to add other layout components here because we expect that those would either have columns, rows or components
            const layoutTypes = ['htmlelement', 'content'];
            const isLayoutComponent = hasColumns || hasRows || (hasComps && !component.input) || layoutTypes.indexOf(component.type) > -1;
            if (includeAll || component.tree || !isLayoutComponent) {
                noRecurse = fn(component, newPath, components);
            }

            const subPath = () => {
                if (
                    component.key &&
                    !['panel', 'table', 'well', 'columns', 'fieldset', 'tabs', 'form'].includes(component.type) &&
                    (
                        ['datagrid', 'container', 'editgrid', 'address', 'dynamicWizard', 'datatable'].includes(component.type) ||
                        component.tree
                    )
                ) {
                    return newPath;
                }
                else if (
                    component.key &&
                    component.type === 'form'
                ) {
                    return `${newPath}.data`;
                }
                return path;
            };

            if (!noRecurse) {
                if (hasColumns) {
                    component.columns.forEach(
                        (column) =>
                            this.eachComponent(
                                fn, 
                                includeAll, 
                                subPath(), 
                                parent ? component : null,
                                true, 
                                column.components)
                    );
                }

                else if (hasRows) {
                    component.rows.forEach((row) => {
                        if (Array.isArray(row)) {
                            row.forEach((column) =>
                                this.eachComponent(fn, includeAll, subPath(), parent ? component : null, true, column.components)
                            );
                        }
                    });
                }

                else if (hasComps) {
                    this.eachComponent(fn, includeAll, subPath(), parent ? component : null, true, component.components);
                }
            }
        });
        if (components.noRecurse) {
            delete components.noRecurse;
        }
    }
    /**
    * Flatten the form components for data manipulation.
    *
    * @param {Object} components
    *   The components to iterate.
    * @param {Boolean} includeAll
    *   Whether or not to include layout components.
    *
    * @returns {Object}
    *   The flattened components map.
    */
    flattenComponentsFullPath(includeAll) {
        const flattened = {};
        this.eachComponent((component, path) => {
            flattened[path] = component;
        }, includeAll);
        this.components_by_path = flattened;
    }

    flattenComponentsByKey(includeAll) {
        const flattened = {};
        this.eachComponent((component, path) => {
            flattened[path] = component;
        }, includeAll);
        this.components_by_key = flattened;
    }

}

export default FormDesignUtil;


