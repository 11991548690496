import DOMPurify from "dompurify";

/**
 * A simple readonly component which parses note html into text.
 * @param {string} content html string corresponding to note.content
 */
export default class AppPatientNoteText extends HTMLElement {
    set content(value) {
        if (!value) this.innerHTML = " ";
        else {
            let markup = DOMPurify.sanitize(value);
            this.innerHTML = markup;
        }
    }

    get content() {
        return DOMPurify.sanitize(this.innerHTML);
    }
    
    connectedCallback() {}
}

customElements.define("app-patient-note-text", AppPatientNoteText);