import { html, render } from "lit";

class AppNavLinks extends HTMLElement {
    /**
     * Should look like [{title: ..., url: ..., page: ..., css_class: ...}]
     */
    set links(value) {
        this._links = value;
        this.render();
    }

    get links() {
        return this._links;
    }

    set css_class(value) {
        this._css_class = value;
        this.render();
    }

    get css_class() {
        return this._css_class;
    }

    set page(value) {
        this._page = value;
        this.render();
    }

    get page() {
        return this._page;
    }

    get current_link() {
        if (!this.links?.length) return { title: "", url: "", page: "" };

        let link = this.links.find((link) => link.page == this.page);

        if (!link) return this.links[0];

        return link;
    }

    get more_links() {
        let links = this.links.filter((link) => link.page !== this.page);
        return links;
    }

    connectedCallback() {
        this.template = () => html`
            <style>
                app-nav-links .nav-link {
                    padding: 15px 0 11px;
                    margin-right: 40px;
                    line-height: 20px;
                    color: var(--t-color-dark);
                    font-size: 14px;
                    width: fit-content;
                }
                app-nav-links .nav-link-active {
                    border-bottom: 2px solid var(--t-color-dark);
                    font-weight: 700;
                }
                @media (max-width: 768px) {
                    #links_full {
                        display: none;
                    }

                    #links_mobile {
                        display: flex;
                    }
                }
                @media (min-width: 768px) {
                    #links_full {
                        display: flex;
                    }

                    #links_mobile {
                        display: none;
                    }
                }
            </style>
            <div
                id="links_mobile"
                style="
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                position: absolute;
                bottom: 0px;
                height: 48px;
                width: 100%;
                box-shadow: 0px -1px 0px #C8D6E4;
                padding-left: 20px;
                background-color: #fff;
            ">
                <a class="nav-link nav-link-active ${this.current_link.css_class || ''}" href=${this.current_link.url}>${this.current_link.title}</a>
                <div class="dropdown" style="position: absolute; right: 20px;">
                    <div
                        class="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        style="
                        font-weight: 400;
                        font-size: 14px;
                        color: #201B3A;
                        display: flex;
                        align-items: center;
                    ">
                        More <span class="caret"></span>
                    </div>
                    <ul
                        class="dropdown-menu"
                        style="
                        padding: 10px">
                        ${this.more_links.map((link) => html` <a class="nav-link ${link.css_class || ''}" href=${link.url}>${link.title}</a> `)}
                    </ul>
                </div>
            </div>
            <div
                id="links_full"
                style="
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                position: absolute;
                bottom: 0px;
                height: 48px;
                width: 100%;
                box-shadow: 0px -1px 0px #C8D6E4;
                padding-left: 20px;
            ">
                ${this.links.map((link) => {

            //console.log("link", link);
            const baseClass = "nav-link" + (link?.css_class ? " " + link.css_class : "");
            if (link.url) {
                return html`
                                <a class="${baseClass} ${link.page == this.page ? "nav-link-active" : ""}" href=${link.url} 
                                    >${link.title}</a
                                >`;
            } else if (link.action && typeof link.action === "function") {
                return html`
                                <a href="javascript:void(0)" class="${baseClass} ${link.page == this.page ? "nav-link-active" : ""}" @click=${(e) => link.action(e)}
                                    >${link.title}</a
                                >`;
            } else {
                return html`<span class="${baseClass}" style="color: var(--bs-secondary);">${link.title}</span>`;
            }
        }
        )}
            </div>
        `;
        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.links) return;
        if (!this.page) return;

        render(this.template(), this);
    }
}

customElements.define("app-nav-links", AppNavLinks);
export default AppNavLinks;
