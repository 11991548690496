import { html, render } from "lit";
import directus from "../../lib/lib-directus";

export default class AppPatientMemo extends HTMLElement {
    get patient() {
        return this._patient;
    }

    set patient(value) {
        //console.log("Setting patient in setter:", value);
        this._patient = value;
        this.render();
        //console.log("Patient set in memo:", this._patient);
    }
    get patient_id() {
        return this._patient_id;
    }

    set patient_id(value) {
        console.log("Setting patientIDID in setter:", value);
        this._patient_id = value;
        this.render();
        console.log("PatientIDID set in memo:", this._patient_id);
    }


    get app() {
        return this._app;
    }

    set app(value) {
        this._app = value;
        this.render();
    }

    constructor() {
        super();
        this._app = "client";
        this.contentEditable = true;
        this.setAttribute("placeholder", "stuff");
        this._patient = null; // Initially null, set later when ready
    }

    connectedCallback() {
        console.log("Connected callback called");

        this.template = () => {
            if (this._app !== "staff") return "";
            return html`
                <div class="patient-memo-container">
                    <span class="memo-label"></span>
                    <textarea placeholder="Add memo for ${this.patient?.first_name || ''} ${this.patient?.last_name || ''} here."
                        class="patient-memo"
                        id="patient-memo"
                        rows="1"
                        .value=${this.patient?.internal_memo || ''}
                        @input=${(e) => this.handleInput(e)}
                    ></textarea>
                </div>
            `;
        };

        this.style = Object.assign({
            border: "1px solid chocolate",
            display: "block",
        }, this.style);

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) {
            console.warn("Render called with no patient set");
            return;
        }

        //console.log("Rendering memo component with patient:", this.patient);
        render(this.template(), this);
    }

    async handleInput(e) {
        let p_id;
        if (this._patient.id) {
            p_id = this._patient.id;
        } else if (this._patient_id) {
            console.info("Patient object not set or missing ID, using patient ID from alternative source:");
            p_id = this._patient_id;
        } else {
            console.error("Patient ID could no be found");
            return;
        }
        clearTimeout(this._debounce);
        this._debounce = setTimeout(async () => {
            const value = e.target.value;
            this.patient.internal_memo = value;
            try {
                await directus.items("patient").updateOne(p_id, {
                    internal_memo: value,
                });
                console.log("Memo updated for patient ID:", p_id);
            } catch (error) {
                console.error("Error updating memo for patient ID:", p_id, error);
            }
        }, 500);
    }
}

customElements.define("app-patient-memo", AppPatientMemo);
