import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
import { getCurrentClientId } from "shared/lib/lib-user";
import DataDefinition from "shared/lib/lib-data-definition";
import ClientSettings from "../../../shared/lib/lib-client-settings";
import AppConfirmationModal from "shared/components/app-confirmation-modal";
import AppSettingsCareTeamNotifications from "shared/components/settings/app-settings-careteam-notifications";
import AppSettingsCareTeamAddModal from "shared/components/settings/app-settings-careteam-add-modal";
import "shared/components/util/app-loader";





const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export default class SceneSettingsCareTeam extends HTMLElement {
    get careteam() {
        return this._careteam || [];
    }

    set careteam(value) {
        this._careteam = value;
        this.render();
    }

    get selected_team_member() {
        return this._selected_team_member;
    }

    set selected_team_member(value) {
        this._selected_team_member = value;
        this.email_value = null;
        this.render();
    }
    static get properties() {
        return {
            careteam: { type: Array },
            filteredUsers: { type: Array },
            filter: { type: Object },
            role_options: { type: Array },
            counts: { type: Object },
        };
    }
    constructor() {
        super();
        this.selected_team_member = null;
        // bind to this for global event listener
        this.handleSidebarClickOutside = this.handleSidebarClickOutside.bind(this);
        this.qualification_level_conditions = [];


        this.careteam = [];
        this.filteredUsers = [];
        this.filter = {
            status: 'all',
            role: 'all',
        };
        this.role_options = [];
        this.counts = {};
        this.loading = true;
    }

    connectedCallback() {
        this.template = () => {
            if (this.loading) {
                return html`<app-loader></app-loader>`;
            }

            return html`
            <style>
                scene-settings-careteam td {
                    text-align: left;
                    vertical-align: middle;
                }
                scene-settings-careteam td:first-child {
                    padding-left: 16px;
                }
                .careteam-table-container {
                    overflow-y: scroll;
                height: calc(100vh - 356px) !important;
                }
                div.scroll-container {
                    height: auto !important;
                    overflow-y: auto !important;
                }

            </style>
            <div style="display: flex; justify-content: space-between">
                <div style="display: flex; align-items: center;">
                   <!-- <span style="font-size: 24px; font-weight: 600;">
                        Care Team
                    </span> -->
                     ${this.renderFilters()}
                   <!-- <ul class="nav nav-pills">
<li class="nav-item careteam-active">
    <button class="nav-link ${this._current_tab === 'active' ? 'active' : ''}" aria-current="active" @click="${(e) => this.handleTabClick(true)}">Active
    <span class="p-count">${this.activeCount}</span>
    </button>
</li>
<li class="nav-item careteam-inactive">
    <button class="nav-link ${this._current_tab === 'inactive' ? 'active' : ''}" aria-current="inactive" @click="${(e) => this.handleTabClick(false)}">Inactive
    <span class="p-count">${this.inactiveCount}</span>
    </button>
</li>
                    </ul> -->

                </div>
                <div class="careteam-actions" style="display: flex;">
                    <div style="display: flex; margin-right: 40px; gap: 10px">
                        <span class="material-symbols-outlined" style="color: grey; font-size: 39px;">search</span>
                        <input class="form-control mr-sm-4 search-input"
                            @input=${(e) => this.handleSearchChange(e)}
                        />
                    </div>
                    <span
                        @click=${_e => this.handleAddClick()}
                        class="material-symbols-outlined"
                        style='
                            font-size: 40px;
                            cursor: pointer;
                            margin-right: 7px;
                            color: var(--t-color-primary);
                            font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                        '
                    >
                        add_circle
                    </span>
                </div>
            </div>
            <hr style="margin: 4px 0 16px 0;" />
            <div class="careteam-table-container row">
                <div class="col">
                    <table class="table">
                        <thead style="font-weight: 600;">
                            <tr>
                                <td>Name</td>
                                <td>Status</td>
                                <td>Role</td>
                           <!-- <td>Permission</td> -->
                                
                            </tr>
                        </thead>
                        <tbody>
                            ${this.filteredUsers.map((team_member) => {
                return html`
                                <tr 
                                    style=${`cursor: pointer; background: ${this.selected_team_member === team_member ? "var(--t-color-light)" : "white"};`}    
                                    @click=${(e) => { e.stopPropagation(); this.selected_team_member = team_member; }}
                                >
                                    <td class="ct-name">${team_member.first_name} ${team_member.last_name}</td>
                                    <td class="ct-status">${team_member.status === "active" ? "active" : "inactive"}</td>
                                    <td class="ct-role">${team_member.role.name === "Client" ? "Manager" : team_member.role.name}</td>
                              <!--  <td class="ct-permission">${team_member.role.name} App</td> -->
                                    

                                </tr>`;
            })}
                        </tbody>

                    </table>
                </div>
                ${this.renderSidebar()}
                ${this.renderPatientsbar()}
               
                ${this.calculateCounts()}
            </div>
            `;
        }

        Object.assign(this.style, {
            display: "block",
        })

        this.init();
    }

    disconnectedCallback() {
        window.addEventListener("click", this.handleSidebarClickOutside);
    }

    renderSidebar() {
        const { selected_team_member } = this
        if (!selected_team_member) return null;
        const client_id = getCurrentClientId();
        const client_access = selected_team_member.client_access.find((r) => r.client_id === client_id);
        let qualification_level_choices = [];
        if (client_access.clinical_role) {
            let conditions = this.qualification_level_conditions.find((i) => i.name === client_access.clinical_role + "_conditions");
            qualification_level_choices = conditions?.options.choices || [];
        }


        return html`
            <div class="col" @click=${e => e.stopPropagation()} style="
                display: flex; 
                flex-direction: column; 
                border: 1px solid var(--bs-border-color); 
                margin: 0 8px 16px 0;
                padding: 16px;
                max-width: 450px;
            ">
                <div style="display: flex; align-items: center; justify-content: center;">
                    <form-input
                        inputid="caregiver-first_name"
                        name="first_name"
                        placeholder="First name",
                        label="First Name"
                        .value="${selected_team_member.first_name}"
                        @input=${(e) => this.debounceInput(() => this.handleUserFieldChange(selected_team_member.id, e))} 
                        style="width: 35%"
                    ></form-input>
                    <form-input type="text"
                        inputid="caregiver-last_name"
                        name="last_name"
                        placeholder="Last name"
                        label="Last Name"
                        .value=${selected_team_member.last_name}
                        @input=${(e) => this.debounceInput(() => this.handleUserFieldChange(selected_team_member.id, e))} 
                        style="width: calc(65% - 64px); margin-left: 16px;"
                    ></form-input>
                    <a 
                        class="btn btn-link text-danger" 
                        style="width: 48px; padding: 0 0 0 16px; align-self: flex-end; margin-left: auto;"
                        @click=${(e) => this.handleDeleteClick(selected_team_member)}
                    >
                        <span class="material-symbols-outlined">delete</span>
                    </a>
                </div>
                <form-select
                    inputid="caregiver_role"
                    name="role"
                    placeholder="Select main role"
                    label="Main Role"
                    .value=${selected_team_member.role?.id || ""}
                    .options=${this.role_options}
                    style="margin-top: 16px;"
                    @change=${(e) => this.handleUserFieldChange(selected_team_member.id, e)}
                ></form-select>
                    ${console.log("role id", selected_team_member.role)}
                <form-input type="tel"
                    label="Phone"
                    placeholder="Phone"
                    inputid="caregiver-phone"
                    name="phone_number"
                    .value=${selected_team_member.phone_number}
                    style="margin-top: 16px;"
                    @input=${(e) => this.debounceInput(() => this.handleUserFieldChange(selected_team_member.id, e))} 
                ></form-input>
                <form-input label="Email"
                    inputid="caregiver-email"
                    type="email"
                    name="email"
                    style="margin-top: 16px;"
                    .errors=${this.email_error}
                    placeholder="Email"
                    .value="${this.email_value || selected_team_member.email}"
                    @input=${(e) => this.debounceInput(() => this.handleUserFieldChange(selected_team_member.id, e))} 
                ></form-input>
                <form-textarea label="Description"
                    inputid="caregiver-description"
                    name="description"
                    placeholder="Helpful information about this care team member..."
                    style="margin-top: 16px;"
                    value="${selected_team_member.description}"
                    @input=${(e) => this.debounceInput(() => this.handleUserFieldChange(selected_team_member.id, e))} 
                ></form-textarea>
                ${selected_team_member.role.name === "Clinician" ? html`
                    <label for="clinician-access-toggle" class="form-label" style="margin-top: 16px;">
                        Clinician App Access
                    </label>
                    <app-toggle-switch 
                        id="clinician-access-toggle"
                        .enabled_text=${"Enabled"}
                        .disabled_text=${"Disabled"}
                        .enabled=${this.isActive(selected_team_member)}
                        style="margin-bottom: 16px;"
                        @click=${() => this.handleActiveUserToggle(selected_team_member)}
                    >
                    </app-toggle-switch>
                    <form-select inputid="caregiver-clinical_role"
                        name="clinical_role"
                        label="Clinical Role"
                        placeholder="Select clinical role"
                        @change=${(e) => this.hanleClientAccessFieldChange(client_access.id, e)}
                        .value=${client_access?.clinical_role}
                        .options=${this.clinical_role_choices}
                    ></form-select>
                    <form-select inputid="caregiver-qualification_level"
                        name="qualification_level"
                        label="Qualification Level"
                        placeholder="Select qualification level"
                        style="margin-top: 16px;"
                        ?disabled=${qualification_level_choices.length === 0}
                        @change=${(e) => this.hanleClientAccessFieldChange(client_access.id, e)}
                        .value=${client_access?.qualification_level}
                        .options=${qualification_level_choices}
                    ></form-select>
                ` : null}
                <div class="careteam-action-btns" style="display: flex; justify-content: space-between; /*gap: 25px;*/">
                    ${selected_team_member.status == "draft" ? html`
                        <app-button
                            .text=${"Activate"}
                            @click=${(e) => this.handleActivate(selected_team_member)}
                            style="font-size: 14px; font-weight: 600; margin-top: 16px; margin-left: 4px;"
                        ></app-button>
                    ` : ""}
                     ${selected_team_member.status == "active" ? html`
                        <app-button
                            .text=${"Deactivate"}
                            @click=${(e) => this.handleDeactivate(selected_team_member)}
                            style="font-size: 14px; font-weight: 600; margin-top: 16px; margin-left: 4px;"
                        ></app-button>
                    ` : ""}
                    <app-button
                        .text=${"Notification Preferences"}
                        @click=${(e) => this.handleNotificationsEditClick(selected_team_member)}
                        style="font-size: 14px; font-weight: 600; margin-top: 16px; margin-left: 4px;"
                    ></app-button>
                     <app-button
    .text=${"Password Reset"}
    @click=${(e) => this.handlePasswordResetClick(selected_team_member)}
    style="font-size: 14px; font-weight: 600; margin-top: 16px; margin-left: 4px;"
></app-button>
                </div>
            </div>
        `;
    }
    async handlePasswordResetClick(user) {
        try {
            let resetPath;

            if (user.role.name == 'Clinician')
                resetPath = "/clinician/resetpassword";
            else if (user.role.name == 'Client' || user.role.name == 'Agency Admin')
                resetPath = "/client/resetpassword";
            else if (user.role.name == 'Engagement Specialist')
                resetPath = "/staff/resetpassword";
            else
                resetPath = "/client/resetpassword"; // Default to client path if role is not recognized

            await directus.auth.password.request(
                user.email,
                window.location.origin + resetPath
            );

            alert("Password reset email sent successfully!");
        } catch (error) {
            console.error("Error sending password reset email:", error);
            alert("Failed to send password reset email. Please try again.");
        }
    }






    renderPatientsbar() {
        const { selected_team_member } = this
        if (!selected_team_member) return null;

        const sortedAvailablePatients = this.availablePatients
            .filter(p => !selected_team_member.patients.some(sp => sp.id === p.id))
            .sort((a, b) => {
                let nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                let nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });

        return html`
            <div class="col" @click=${e => e.stopPropagation()} style="
                display: flex; 
                flex-direction: column; 
                border: 1px solid var(--bs-border-color); 
                margin: 0 8px 16px 0;
                padding: 16px;
                max-width: 300px;
            ">
                <div class="input-group patient-select">
                <select id="patient-select" class="form-control">
                    <option value="" disabled selected>Choose a Patient to add...</option>
                    ${sortedAvailablePatients.map(patient => html`
                        <option value="${patient.id}">${patient.first_name} ${patient.last_name}</option>
                    `)}
                </select>
                    <button type="button"
                            class="btn btn-primary" 
                            @click=${() => this.addPatientToCareTeamMember()}>
                            Add
                    </button>
                </div>
                <p style="margin-top: 18px; font-weight: 500; margin-bottom: -0.5em;">Assigned Patients</p>
                <hr style="border: 0; height: 1px; background-color: #ccc; margin-bottom: 5px;">
                ${selected_team_member.patients.length > 0 ? selected_team_member.patients.map(patient => html`
                    <div style="display: flex; align-items: center; justify-content: space-between;">
                        <span>${patient.first_name} ${patient.last_name}</span>
                        <button @click=${() => this.removePatientFromCareTeamMember(patient)}
                                class=""
                                style="background-color: transparent; border: none; cursor: pointer; color: red;">
                            <span class="material-symbols-outlined text-danger">delete</span>
                        </button>
                    </div>
                `) : html`<span>No Patients Assigned</span>`}
            </div>
        `;
    }

    debounceInput(fn) {
        clearTimeout(this._input_debounce);
        this._input_debounce = setTimeout(() => {
            fn();
        }, 750);
    }

    isActive(user) {
        const client_id = getCurrentClientId();
        const client_access = user.client_access.find((i) => i.client_id === client_id);
        return !!client_access.can_access_app;
    }

    async init() {
        window.addEventListener("click", this.handleSidebarClickOutside);
        const [def] = await Promise.all([
            DataDefinition.getDefinition("junction_directus_users_client"),
            this.loadCareTeam(),
            this.loadRoles(),
            this.loadAvailablePatients()
        ]);
        this.clinical_role_choices = def.field_dictionary.clinical_role.meta.options.choices || [];
        this.qualification_level_conditions = def.field_dictionary.qualification_level.meta.conditions || [];
        this.render();

    }

    async loadCareTeam() {
        try {
            const client_id = getCurrentClientId();
            const res = await directus.users
                .readByQuery({
                    filter: {
                        client_access: {
                            client_id: client_id,
                        },
                        role: {
                            name: {
                                _in: ["Clinician", "Client", "Agency Admin", "Engagement Specialist"],
                            }
                        }
                    },
                    fields: ["*", "role.name", "role.id", "client_access.*"],
                    sort: ["last_name"],
                });
            this.careteam = res.data;

            // Fetch all patient access records and their associated patient details in a single request
            const patientAccessRes = await directus.items("patient_access").readByQuery({
                filter: {
                    user_id: {
                        _in: this.careteam.map(member => member.id)
                    }
                },
                fields: ["id", "patient_id.*", "user_id"],
                limit: -1 // Remove the limit to fetch all records
            });

            // Create a map of user IDs to their associated patient access records
            const userPatientAccessMap = patientAccessRes.data.reduce((map, item) => {
                if (!map[item.user_id]) {
                    map[item.user_id] = [];
                }
                map[item.user_id].push({
                    access_id: item.id,
                    ...item.patient_id
                });
                return map;
            }, {});

            // Assign the patient access records to each care team member
            this.careteam.forEach(member => {
                member.patients = userPatientAccessMap[member.id] || [];
            });
            // console.log("Fetched care team data:", this.careteam);
            this.handleFilterChange();
        } catch (err) {
            console.error(err);
        }
    }


    async loadRoles() {
        const client_id = getCurrentClientId();
        const client_settings = await ClientSettings.getSettings(client_id);
        const selfmanaged = client_settings?.company?.selfmanaged?.value;
        let rolesToFetch = ["Clinician", "Client"];

        if (selfmanaged) {
            rolesToFetch = ["Clinician", "Agency Admin", "Engagement Specialist"];
        }

        const res = await directus.roles.readByQuery({
            filter: {
                name: {
                    _in: rolesToFetch,
                },
            },
        });

        const role_values = res.data.map((i) => {
            let name = i.name;
            if (name === "Client") {
                name = "Manager";
            }
            return { text: name, value: i.id, definition: i };
        });

        this.role_options = role_values;
        this.role_filters = role_values.map((role) => role.text);
    }


    async loadAvailablePatients() {
        const client_id = getCurrentClientId();
        try {
            const allPatientsResponse = await directus.items("patient").readByQuery({
                filter: {
                    client_id,
                    status: "active"
                },
                fields: ["id", "first_name", "last_name"]
            });
            const allPatients = allPatientsResponse.data;

            this.availablePatients = allPatients;

            this.render();
        } catch (error) {
            console.error('Error loading patients:', error);
        }
    }

    async addPatientToCareTeamMember() {
        const selectedPatientId = this.querySelector("#patient-select").value;
        if (!selectedPatientId) return;

        try {
            await directus.items("patient_access").createOne({
                patient_id: selectedPatientId,
                user_id: this.selected_team_member.id
            });

            const updatedPatient = await directus.items("patient").readOne(selectedPatientId);
            this.selected_team_member.patients.push(updatedPatient);

            await this.loadCareTeam();
            this.render();
        } catch (error) {
            console.error('Error adding patient to care team member:', error);
        }
    }

    async removePatientFromCareTeamMember(patient) {
        try {
            await directus.items("patient_access").deleteOne(patient.access_id);

            this.selected_team_member.patients = this.selected_team_member.patients.filter(p => p.id !== patient.id);
            this.render();
        } catch (error) {
            console.error('Error removing patient from care team member:', error);
        }
    }

    async hanleClientAccessFieldChange(client_access_id, e) {
        const { name, value } = e.target;
        if (!client_access_id || !name || !value) return;
        await directus.items("junction_directus_users_client").updateOne(
            client_access_id,
            {
                [name]: value
            }
        );
        this.loadCareTeam();
    }

    async handleUserFieldChange(user_id, e) {
        let { name, value } = e.target;
        if (!user_id || !name || !value) return;

        if (name === "email") {
            this.email_value = value = value?.toLowerCase?.();
            if (EMAIL_REGEX.test(value) === false) {
                this.email_error = "Please provide a valid email";
                this.render()
                return;
            }
            else {
                this.email_error = null;
            }
        }
        await directus.users.updateOne(
            user_id,
            {
                [name]: value
            }
        );
        this.loadCareTeam();
    }

    async handleDeleteClick(user) {
        const client_id = getCurrentClientId();
        const modal = new AppConfirmationModal();
        modal.modal_text = `Are you sure you want to remove ${user.first_name} ${user.last_name} from the care team?`;
        await modal.showModal();
        const res = await modal.onDidDismiss();

        if (res && res.confirmed) {
            try {
                const junction = user.client_access.find((i) => i.directus_users_id === user.id && i.client_id === client_id);
                await directus.items('junction_directus_users_client').deleteOne(junction.id);
                this.loadCareTeam();
            } catch (err) {
                console.error(err);
            }
        }
    }

    async handleNotificationsEditClick(user) {
        const modal = new AppSettingsCareTeamNotifications();
        modal.caregiver = user;
        await modal.showModal();
        await modal.onDidDismiss();
    }

    async handleAddClick() {
        const modal = new AppSettingsCareTeamAddModal();
        await modal.showModal();
        const res = await modal.onDidDismiss();
        await this.loadCareTeam();
        this.render();
    }

    async handleActiveUserToggle(user) {
        const client_id = getCurrentClientId();
        const client_access = user.client_access.find((i) => i.client_id === client_id);
        const res = await directus.transport.post(`/vbh/app_access/${client_id}/${user.id}`, {
            can_access_app: !client_access.can_access_app
        });
        client_access.can_access_app = res.raw.can_access_app;
        this.render();
    }

    async handleActivate(user) {
        await directus.users.updateOne(user.id, { status: "active" });
        this.loadCareTeam();
    }
    async handleDeactivate(user) {
        await directus.users.updateOne(user.id, { status: "draft" });
        this.loadCareTeam();
    }
    handleTabClick(role) {
        this.role_options.forEach((r) => {
            r.active = r.text === role;
        });
        this.render();
    }



    updateFilteredUsers() {
        this.filteredUsers = this.careteam.filter(user => {
            const name = `${user.first_name} ${user.last_name}`.toLowerCase();
            const isActive = user.status === "active";
            return this.filter.active === isActive && name.includes(this.filter.name);
        });
        this.updateCounts();
    }

    handleSearchChange(e) {
        this.filter.name = e.target.value.toLocaleLowerCase();
        this.handleFilterChange();
    }
    calculateCounts() {
        const counts = {
            totalActive: 0,
            totalInactive: 0,
        };

        this.role_options.forEach((role) => {
            const roleName = role.text;
            counts[`${roleName}Active`] = 0;
            counts[`${roleName}Inactive`] = 0;
        });

        this.careteam.forEach((member) => {
            const roleName = member.role.name === 'Client' ? 'Manager' : member.role.name;
            const statusKey = member.status === 'active' ? 'Active' : 'Inactive';
            counts[`${roleName}${statusKey}`]++;
            counts[`total${statusKey}`]++;
        });

        this.counts = counts;
    }

    handleFilterChange(filterUpdate) {
        this.filter = { ...this.filter, ...filterUpdate };

        this.filteredUsers = this.careteam.filter((member) => {
            const statusMatch = this.filter.status === 'all' || (this.filter.status === 'active' ? member.status === 'active' : member.status !== 'active');
            const roleMatch = this.filter.role === 'all' || member.role.name === (this.filter.role === 'Manager' ? 'Client' : this.filter.role);
            return statusMatch && roleMatch;
        });
        this.loading = false;
        this.render();
    }
    renderFilters() {
        const { counts, filter } = this;

        return html`
            <div class="nav nav-pills">
                <button
                    class="nav-link ${filter.status === 'all' ? 'active' : ''}"
                    @click=${() => this.handleFilterChange({ status: 'all' })}
                >
                    All <span class="p-count">${counts.totalActive + counts.totalInactive}</span>
                </button>
                <button
                    class="nav-link ${filter.status === 'active' ? 'active' : ''}"
                    @click=${() => this.handleFilterChange({ status: 'active' })}
                >
                    Active <span class="p-count">${filter.role === 'all' ? counts.totalActive : counts[`${filter.role}Active`]}</span>
                </button>
                <button
                    class="nav-link ${filter.status === 'inactive' ? 'active' : ''}"
                    @click=${() => this.handleFilterChange({ status: 'inactive' })}
                >
                    Inactive <span class="p-count">${filter.role === 'all' ? counts.totalInactive : counts[`${filter.role}Inactive`]}</span>
                </button>
            </div>
            <div class="nav nav-pills">
                <button
                    class="nav-link ${filter.role === 'all' ? 'active' : ''}"
                    @click=${() => this.handleFilterChange({ role: 'all' })}
                >
                    All Roles
                </button>
                ${this.role_options.map(
            (role) => html`
                        <button
                            class="nav-link ${filter.role === role.text ? 'active' : ''}"
                            @click=${() => this.handleFilterChange({ role: role.text })}
                        >
                            ${role.text} <span class="role-count p-count">${filter.status === 'all' ? counts[`${role.text}Active`] + counts[`${role.text}Inactive`] : counts[`${role.text}${filter.status === 'active' ? 'Active' : 'Inactive'}`]}</span>
                        </button>
                    `
        )}
            </div>
        `;
    }
    render() {
        if (!this.template) return;
        render(this.template(), this);
        //this.renderFilters(); 

    }
    handleSidebarClickOutside(e) {
        this.selected_team_member = null;
    }
}

customElements.define("scene-settings-careteam", SceneSettingsCareTeam);
