import { html, render } from "lit";
import ModalBase from "shared/components/util/modal-base";
import DataDefinition from "shared/lib/lib-data-definition";

class AppVisitCallCompleteModal extends ModalBase {
    set visit(value) {
        this._visit = value;
    }

    /** @type {import("../../scenes/scene-visit-confirmation").Visit} */
    get visit() {
        return this._visit;
    }

    set selected_status(value) {
        this._selected_status = value;
        this.render();
    }

    get selected_status() {
        return this._selected_status;
    }

    set unavailable_times(value) {
        this._unavailable_times = value;
    }

    get unavailable_times() {
        return this._unavailable_times;
    }

    set prompt_unavailable(value) {
        this._prompt_unavailable = value;
        this.render();
    }

    get prompt_unavailable() {
        return this._prompt_unavailable;
    }

    constructor() {
        super();
        this._unavailable_times = [];
        this.selected_from_time = "7";
        this.selected_to_time = "8";
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    .modal-confirm-btn {
                        background-color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                    }
                    .modal-confirm-btn:hover {
                        background-color: var(--t-color-danger-darkened);
                        border-color: var(--t-color-danger-darkened);
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Call Complete</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div style="">
                                <h4 style="font-size: 16px; color: var(--t-color-dark);">How did the patient respond?</h4>
                                <div style="
                                    display: flex;
                                    align-items: center;
                                ">
                                    <div
                                        style="display: flex"
                                        @click=${e => {
                    this.selected_status = 'confirmed'
                }}
                                    >
                                        <input type="radio" name="visit-status" ?checked=${this.selected_status == 'confirmed'}>
                                        <app-pill  
                                            .text=${"Confirmed"} 
                                            .color=${"--t-color-primary"}
                                            style="margin-left: 5px; cursor: pointer;"></app-pill>
                                    </div>

                                    <div
                                        style="display: flex; margin-left: 20px;"
                                        @click=${e => {
                    this.selected_status = 'denied'
                }}
                                    >
                                        <input type="radio" name="visit-status" ?checked=${this.selected_status == 'denied'}>
                                        <app-pill  
                                            .text=${"Denied"} 
                                            .color=${"--t-color-danger"}
                                            style="margin-left: 5px; cursor: pointer;"></app-pill>
                                    </div>
                                </div>
                                <h4 style="font-size: 16px; color: var(--t-color-dark); margin-top: 20px;">Did the patient indicate times they were unavailable?</h4>
                                <div style="
                                    display: flex;
                                    align-items: center;
                                ">
                                    <div
                                        style="display: flex"
                                        @click=${e => {
                    this.prompt_unavailable = false
                }}
                                    >
                                        <input type="radio" name="unavailable" ?checked=${this.prompt_unavailable === false}>
                                        <app-pill  
                                            .text=${"No"} 
                                            .color=${"--t-color-primary"}
                                            style="margin-left: 5px; cursor: pointer;"></app-pill>
                                    </div>

                                    <div
                                        style="display: flex; margin-left: 20px;"
                                        @click=${e => {
                    this.prompt_unavailable = true
                }}
                                    >
                                        <input type="radio" name="unavailable" ?checked=${this.prompt_unavailable}>
                                        <app-pill  
                                            .text=${"Yes"} 
                                            .color=${"--t-color-warning"}
                                            style="margin-left: 5px; cursor: pointer;"></app-pill>
                                    </div>
                                </div>
                                ${this.prompt_unavailable ?
                    html`
                                <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 10px;">
                                    <div style="display: block; flex-basis: 100%;">
                                        <div style="width: 100%; display: flex; align-items: center;">
                                            <span style="
                                                font-weight: 600;
                                                font-size: 14px;
                                            ">From: </span>
                                            <select 
                                                style="
                                                    border: none;
                                                    font-size: 14px;
                                                "
                                                value=${this.selected_from_time} 
                                                @change=${e => this.selected_from_time = e.target.value}>
                                                <option value="7">7 AM</option>
                                                <option value="8">8 AM</option>
                                                <option value="9">9 AM</option>
                                                <option value="10">10 AM</option>
                                                <option value="11">11 AM</option>
                                                <option value="12">12 PM</option>
                                                <option value="13">1 PM</option>
                                                <option value="14">2 PM</option>
                                                <option value="15">3 PM</option>
                                                <option value="16">4 PM</option>
                                                <option value="17">5 PM</option>
                                                <option value="18">6 PM</option>
                                            </select>
                                            <span style="
                                                font-weight: 600;
                                                font-size: 14px;
                                                margin-left: 10px;
                                            ">To: </span>
                                            <select 
                                                style="
                                                    border: none;
                                                    font-size: 14px;
                                                "
                                                value=${this.selected_to_time} 
                                                @change=${e => this.selected_to_time = e.target.value}>
                                                <option value="8">8 AM</option>
                                                <option value="9">9 AM</option>
                                                <option value="10">10 AM</option>
                                                <option value="11">11 AM</option>
                                                <option value="12">12 PM</option>
                                                <option value="13">1 PM</option>
                                                <option value="14">2 PM</option>
                                                <option value="15">3 PM</option>
                                                <option value="16">4 PM</option>
                                                <option value="17">5 PM</option>
                                                <option value="18">6 PM</option>
                                                <option value="19">7 PM</option>
                                            </select>
                                            <span style="
                                                font-weight: 600;
                                                font-size: 14px;
                                                margin-left: 10px;
                                            ">Reason: </span>
                                            <select 
                                                style="
                                                    border: none;
                                                    font-size: 14px;
                                                "
                                                value=${this.selected_reason} 
                                                @change=${e => this.selected_reason = e.target.value}>
                                                    <option value="" selected>Select...</option>
                                                ${this.reasons?.map?.(
                        choice => html`
                                                    <option value=${choice.value}>${choice.label}</option>
                                                    `
                    )}
                                            </select>
                                        </div>
                                        <div>
                                            <div
                                                style="
                                                    font-weight: 600;
                                                    font-size: 14px;
                                                "
                                                >Notes:</div>
                                            <textarea 
                                                rows="1"
                                                style="
                                                    font-size: 12px;
                                                    width: 100%;
                                                    border-color: var(--t-color-grey);
                                                "
                                                @change=${e => this.unavailble_notes = e.target.value}></textarea>
                                        </div>
                                    </div>
                                    <div 
                                        @click=${e => {
                            let start_time = new Date(this.visit.visit_date);
                            start_time.setHours(parseInt(this.selected_from_time), 0, 0, 0);
                            let end_time = new Date(this.visit.visit_date);
                            end_time.setHours(parseInt(this.selected_to_time), 0, 0, 0);
                            this.unavailable_times.push(
                                {
                                    client_id: this.visit.client_id,
                                    patient: this.visit.patient_id,
                                    start_time,
                                    end_time,
                                    reason: this.selected_reason,
                                    notes: this.unavailble_notes
                                }
                            )
                            this.render();
                        }}
                                        class="material-symbols-outlined" 
                                        style="
                                            color: var(--t-color-primary);
                                            margin-left: 20px;
                                            margin-right: 20px;
                                            cursor: pointer;
                                        ">add_circle</div>
                                </div>
                                <div>
                                    <ul>
                                    ${this.unavailable_times.map(
                            time => html`
                                        <li style="
                                            font-size: 14px; 
                                            color: var(--t-color-dark); 
                                           ">
                                            <div style="
                                                display: flex;
                                                align-items: center;
                                            " >
                                                <span>Unavailable from 
                                                ${new Date(time.start_time).toLocaleTimeString("en-US", { timeStyle: "short" })} 
                                                to 
                                                ${new Date(time.end_time).toLocaleTimeString("en-US", { timeStyle: "short" })}
                                                ${time.reason ?
                                    html`
                                                because of ${this.data_definition?.getChoiceLabel?.("reason", time.reason)}</span>
                                                `: ''}
                                                <span 
                                                    @click=${e => {
                                    e.preventDefault();
                                    e.stopImmediatePropagation();
                                    this.unavailable_times.splice(
                                        this.unavailable_times.findIndex(item => item == time),
                                        1
                                    );
                                    this.render();
                                }}
                                                    class="material-symbols-outlined" 
                                                    style="margin-left: 10px; font-size: 16px; color: var(--t-color-dark); cursor: pointer;">close</span>
                                            </div>
                                        </li>
                                        `
                        )}
                                    </ul>

                                </div>
                                `: ''}
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                ?disabled=${!this.selected_status}
                                type="button"
                                class="btn btn-primary"
                                style="background-color: var(--t-color-primary);"
                                @click=${(_e) => this.handleSave()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
        this.init();
    }

    async init() {
        let data_definition = new DataDefinition("patient_availability");
        this.data_definition = data_definition;
        await data_definition.load();
        this.reasons = data_definition.getChoices("reason");
        this.render();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    async handleSave() {
        await this.dismiss({ save: true, status: this.selected_status, unavailable_times: this.unavailable_times });
    }
}

customElements.define("app-visit-call-complete-modal", AppVisitCallCompleteModal);
export default AppVisitCallCompleteModal;
