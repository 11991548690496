import { html, render, LitElement } from "lit";
import directus from "shared/lib/lib-directus";
import { getCurrentClientId } from "shared/lib/lib-user";
import ApplicationState from "applicationstate";
import "shared/components/form/form-input";
import ClientSettings from "shared/lib/lib-client-settings";
import { easepick, TimePlugin } from '@easepick/bundle';

export default class SceneSettingsCompany extends LitElement {
    static get properties() {
        return {
            settings: { type: Object },
            isAdmin: { type: Boolean }
        };
    }
    createRenderRoot() {
        return this;
    }
    constructor() {
        super();
        const user = ApplicationState.get('app.user');
        this.isAdmin = user?.role?.name === 'Administrator';
        this.settings = {};
    }

    async firstUpdated() {
        const client_id = getCurrentClientId();
        if (!client_id) {
            throw new Error('Invalid client id');
        }
        await this.loadSettings();
    }

    render() {
        return html`
            <h5>Company Settings</h5>
            <div class="row">
                <div class="col-4 mb-3">
                    <label for="default_timezone">Default timezone</label>
                    <select class="form-select setting" id="default_timezone" @change=${this.markDirty}>
                        ${['America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles', 'America/Anchorage', 'Pacific/Honolulu'].map(timezone => html`
                            <option ?selected=${this.settings?.ALL?.default_timezone?.value == timezone} value=${timezone}>${timezone}</option>
                        `)}
                    </select>
                    <small>This is the timezone we should use to do things like send out a SMS at a certain time</small>
                </div>
            </div>
            
            ${this.isAdmin ? this.renderAdminSettings() : ''}
            
            <div class="row">
                <div class="mb-3">
                    <button @click=${this.save} class="btn btn-primary">Save</button>
                </div>
            </div>
        `;
    }

    renderAdminSettings() {
        return html`
            <div class="row">
                <div class="mb-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input setting" type="checkbox" role="switch" id="selfmanaged" ?checked=${this.settings?.ALL?.selfmanaged?.value} @change=${this.markDirty}>
                        <label for="selfmanaged" class="form-check-label">Self Managed</label>
                    </div>
                </div>
                <div class="col-4 mb-3">
                    <label for="telynx_profile_id" class="form-label">Telynx Profile ID</label>
                    <input type="text" class="form-control setting" id="telynx_profile_id" .value=${this.settings?.ALL?.telynx_profile_id?.value || ''} @change=${this.markDirty} placeholder="Enter Telynx Profile ID">
                </div>
                <div class="col-4 mb-3">
                    <label for="ehr_username" class="form-label">EHR Username</label>
                    <input type="text" class="form-control setting" id="ehr_username" .value=${this.settings?.ALL?.ehr_username?.value || ''} @change=${this.markDirty} placeholder="The username for the EHR">
                </div>
                <div class="col-4 mb-3">
                    <label for="ehr_password" class="form-label">EHR Password</label>
                    <input type="password" class="form-control setting" id="ehr_password" .value=${this.settings?.ALL?.ehr_password?.value || ''} @change=${this.markDirty} placeholder="The password for the EHR">
                </div>
            </div>
        `;
    }

    markDirty(e) {
        e.target.dataset.dirty = true;
    }

    async save() {
        const client_id = getCurrentClientId();
        const settingElements = this.querySelectorAll('.setting');
        for (let settingElement of settingElements) {
            if (settingElement.dataset.dirty) {
                let settingValue;
                if (settingElement.type === 'checkbox') {
                    settingValue = settingElement.checked;
                } else {
                    settingValue = settingElement.value;
                }

                await ClientSettings.setValue(client_id, settingElement.id, settingValue);
                settingElement.dataset.dirty = false;
            }
        }
        await this.loadSettings();
        window.location.reload();
    }

    async loadSettings() {
        const client_id = getCurrentClientId();
        this.settings = await ClientSettings.getSettings(client_id);
    }

}

customElements.define("scene-settings-company", SceneSettingsCompany);
