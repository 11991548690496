import { html, render } from "lit";
import directus from 'shared/lib/lib-directus';
import SurveyDefinition from "shared/lib/lib-survey-definition";
import { adddirectusClientFilter, getCurrentClientId } from "shared/lib/lib-user";
import ApplicationState from "applicationstate";
import "shared/components/app-color-label";
import "../components/dashboard/app-dashboard-header";
import "../components/dashboard/app-dashboard-alert-list";
import AppAlertDetail from "shared/components/alert/app-alert-detail";
import DataDefinition from "shared/lib/lib-data-definition";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";

export default class SceneDashboardAllAlerts extends HTMLElement {
   constructor() {
      super();
      this.alerts = [];
      this.alertsCount = 0;
      this.filters = {
         alerts: {},
         alert_status: ["active"],
         alert_date_created_range: null,
         alert_level: null,
      };
      this.loading = false;
      this.offset = 0;
      this.paginationLimit = 25;  // Set a default value
   }

   set alerts(value) {
      this._alerts = value;
      this.render();
   }

   get alerts() {
      return this._alerts;
   }

   set metrics(value) {
      this._metrics = value;
      this.render();
   }

   get metrics() {
      return this._metrics;
   }

   set alert_metrics(value) {
      this._alert_metrics = value;
      this.render();
   }

   get alert_metrics() {
      return this._alert_metrics;
   }

   connectedCallback() {
      this.template = () => {
         return html`
                

                   
<app-dashboard-header .page=${"all-alerts"} class="reports-header"></app-dashboard-header>
   <div id="patients-scroll-container">
      <div id="main-content" class="container-fluid">
          <div id="widget_wrapper">
          
   <div class="button-list" style="align-items: center;">
      <div class="filter-container" style="align-items: center;">
          <app-report-filter
              .title=${"Alert Status"}
              .options=${this.alert_status_filter_options}
              .value=${this.filters.alert_status}
              @optionclick=${e => this.handleFilterChange("alert_status", e)}
              @clear=${_e => this.handleFilterClear("alert_status")}
          >    
          </app-report-filter>
      </div>
      <div class="filter-container" style="align-items: center;">
          <app-report-filter
              .is_datepicker=${true}
              .required=${true}
              .title=${"Alert Date"}
              .value=${this.filters.alerts.alert_date_created_range}
              @dateselect=${e => this.handleDateFilterChange(e)}
              @clear=${_e => this.handleFilterClear("alert_date")}
          >    
          </app-report-filter>
      </div>
      <div class="filter-container" style="align-items: center;">
          <app-report-filter
              .title=${"Alert Level"}
              .options=${this.alert_level_filter_options}
              .value=${this.filters.alert_level}
              @optionclick=${e => this.handleFilterChange("alert_level", e)}
              @clear=${_e => this.handleFilterClear("alert_level")}
          >    
          </app-report-filter>
      </div>
   </div>
                
      ${this.renderAlertSummaryItems()}
            
      <div class="cp-stats mod-base total-alerts">
          <span class="material-symbols-outlined" style='display: inline; color: #ca463d; margin: 2px 2px 0 0; position:relative; top:2px; font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48; font-size:15px;'>warning</span>
       Total
          <span><strong>${this.alerts.length}</strong><span>
      </div>
        
            
            
            
          </div>
           <app-dashboard-alert-list
                                class="col-12"
                                .alerts=${this.alerts}
                                .alert_metrics=${this.alert_metrics}
                                .show_patient_name=${true}
                                @alertclick=${e => this.showAlert(e.detail)}
                            ></app-dashboard-alert-list>  
               </div>
              </div>
       
          
            `;
      };

      this.init();
      this.render();
   }


   render() {
      ////console.log("render", this.alerts);
      if (!this.template)
         return;

      if (!this.metrics)
         return;

      render(this.template(), this);

   }

   async init() {
      ////console.log("intit", this.alerts);
      this.survey_definition = await SurveyDefinition.getSurveyDefinition();
      await this.loadMetrics();
      await this.loadAlerts();
      this.fetchFilterOptions();
   }

   async loadMetrics() {
      //const user_today_midnight = new Date();
      //user_today_midnight.setHours(0, 0, 0, 0);

      const client_id = getCurrentClientId();
      const result = await directus.transport.get('/vbh/dashboard_metrics', {
         params: {
            client_ids: [client_id],
            //user_today_midnight,
         },
      })
      this.metrics = result.data.dashboard_metrics;
   }

   async loadAlerts() {
      //console.log("loadAlerts Filters: ", JSON.stringify(this.filters));

      const client_id = getCurrentClientId();

      const {
         alerts: {
            status: nested_alert_status = null
         } = {},
         alert_status = null,
         alert_date_created_range = null,
         alert_level = null
      } = this.filters;

      const date_created = Array.isArray(alert_date_created_range) ? alert_date_created_range[0] : alert_date_created_range;

      //console.log("date range: ", date_created);

      // Parse the date range into start and end dates
      let [startDate, endDate] = date_created ? date_created.split(",") : [null, null];

      // Construct the filter object
      let filterObject = {
         client_id: client_id
      };

      if (Array.isArray(alert_status) && alert_status.length > 0) {
         filterObject.status = {
            _in: alert_status
         }
      } else {
         delete filterObject["status"];
      }

      if (Array.isArray(alert_level) && alert_level.length > 0) {
         filterObject.level = {
            _in: alert_level
         }
      } else {
         delete filterObject["level"];
      }

      // If both startDate and endDate are available, add the _between filter
      if (startDate && endDate) {
         filterObject.date_created = {
            _between: [startDate, endDate]
         };
      }

      // Remove undefined or null values from the filter object
      Object.keys(filterObject).forEach(key => (filterObject[key] === undefined || filterObject[key] === null) && delete filterObject[key]);

      try {
         let result = await directus.items("alert").readByQuery({
            fields: [
               "*",
               "patient_id.id",
               "patient_id.first_name",
               "patient_id.last_name",
               "survey_id.*",
               "survey_id.survey_schedule_item_id.day",
               "survey_id.episode_id.start_date",
               "survey_id.episode_id.end_date",
               "survey_id.survey_schedule_item_id.form_design_id",
               "last_view_user_id.first_name",
               "last_view_user_id.last_name",
            ],
            filter: filterObject,
            limit: "-1",
            sort: ["level", "-date_created"]
         });

         //console.log("Directus Fetch Result:", result);

         this.alerts = result.data || [];
         this.alert_metrics = {
            "Level 1": 0,
            "Level 2": 0,
            "Level 3": 0,
         };
         for (let alert of this.alerts) {
            this.alert_metrics["Level " + alert.level]++;
         }

      } catch (error) {
         console.error("Error fetching from Directus:", error);
      }

      //console.log("End of loadAlerts");
   }


   renderAlertSummaryItems() {
      ////console.log("renderAlertSummaryItems", this.alerts);
      if (!this.alert_metrics) return null;
      const alert_colors = {
         "Level 1": "#ca463d",
         "Level 2": "#ECCE48",
         "Level 3": "#dddddd",
      };
      return Object.keys(this.alert_metrics).map(
         (level) => html`
       
          <div class="cp-stats mod-base ${level.replace(' ', '_')}">
            ${level}
            <span>${this.alert_metrics[level]}</span>
          </div>
          
        
      `
      );
   }
   async showAlert(alert) {
      let alert_modal = new AppAlertDetail();
      alert_modal.alert = alert;
      alert_modal.patient = this.patient;
      await alert_modal.showModal();
      let result = await alert_modal.onDidDismiss();
      if (result) await this.loadAlerts();
   }
   async fetchFilterOptions() {
      const alert_definition_request = DataDefinition.getDefinition("alert");

      const [
         alert_definition
      ] = await Promise.all([
         alert_definition_request
      ]);

      this.alert_level_filter_options = alert_definition.field_dictionary.level.meta.options.choices.map(
         choice => ({
            value: String(choice.value),
            label: choice.text,
         })
      );
      this.alert_status_filter_options = alert_definition.field_dictionary.status.meta.options.choices.map(
         choice => ({
            value: choice.value,
            label: choice.text,
         })
      );


      this.alert_date_filter_options = {
         startDate: null,
         endDate: null
      };

      this.render();
   }

   /**
    * @param {CustomEvent} e AppReportFilter#dateselect event
    */
   handleDateFilterChange(e) {
      const {
         start,
         end
      } = e.detail
      this.filters["alert_date_created_range"] = `${getISODateStringWithoutTime(start)},${getISODateStringWithoutTime(end)}`;
      this.loadAlerts();
   }

   /**
    * handler for AppReportFilter#clear
    * @param {string} filter_key one of this.filters
    */
   handleFilterClear(filter_key) {
      delete this.filters[filter_key];
      this.loadAlerts();
   }

   /**
    * @param {string} filter_key one of this.filters
    * @param {CustomEvent} e AppReportFilter#optionclick event
    */
   handleFilterChange(filter_key, e) {
      if (e.detail) this.filters[filter_key] = e.detail;
      else delete this.filters[filter_key];
      this.loadAlerts();
   }

   /**
    * Appends a sort column with default direction asc and re-fetches.
    * @param {Event} e tagify 'add' event
    */
   handleSortColumnAdd(e) {
      const {
         data: tag_data
      } = e.detail;

      this.sort_fields.push({
         column: sort_label_to_column[tag_data.value],
         direction: "asc",
      });
      this._current_page = 1;
      this.loadAlerts();
   }

   /**
    * Removes the selected sort column and re-fetches.
    * @param {Event} e tagify 'remove' event
    */
   handleSortColumnRemove(e) {
      const {
         data: tag_data
      } = e.detail;

      const index_to_remove = this.sort_fields.findIndex(
         (field) => field.column === sort_label_to_column[tag_data.value]
      );
      this.sort_fields.splice(index_to_remove, 1);
      this._current_page = 1;
      this.loadAlerts();
   }

   /**
    * On clicking a sort tag/chip, reverse the sort direction for the given field and refetch patients.
    * @param {CustomEvent} e tagify 'click' event from <app-chip-sort>
    */
   handleTagifyClick(e) {
      const {
         data: tag_data
      } = e.detail;
      e.stopPropagation();
      // change sort direction
      const sort_field = this.sort_fields.find((field) => field.column === sort_label_to_column[tag_data.value]);
      sort_field.direction = sort_field.direction === "asc" ? "desc" : "asc";
      this._current_page = 1;
      this.loadAlerts();
   }

   /**
    * Enables infinite scroll / pagination on the scroll container.
    * @param {Event} e scroll event
    */
   handleScroll(e) {
      const {
         scrollTop,
         scrollHeight,
         clientHeight
      } = e.target;
      if (!this._all_pages_fetched && !this._loading_data && scrollHeight - clientHeight - scrollTop < 300) {
         this._current_page++;
         this.loadAlerts();
      }
   }

   /**
    * Update bootstrap-table height at various breakpoints
    */
   handleWindowResize() {
      if (this.data_table) {
         const table_height = this.data_table.options.height;
         const desired_table_height = this.content_ref.value.clientHeight - this.header_ref.value.clientHeight;
         if (table_height !== desired_table_height) {
            this.data_table.resetView({
               height: desired_table_height
            });
         }
      }
   }
}

customElements.define("scene-dashboard-all-alerts", SceneDashboardAllAlerts);