import { html, render } from "lit"

/**
 * Widget rendering level 3 alerts for the current client. 
 * @fires AppDashboardNonCriticalAlerts#alertclick
 */
export default class AppDashboardNonCriticalAlerts extends HTMLElement {

    set alerts(value) {
        this._alerts = value;
        this.render();
    }

    get alerts() {
        return this._alerts;
    }

    get noncritical_count() {
        return this.alerts.filter((alert) => alert.level == 3 && alert.status === 'active').length;

    }

    get noncritical_alerts() {
        return this._alerts.filter((alert) => alert.level == 3);
    }

    connectedCallback() {
        this.template = () => {
            return html`
                 <div class="alerts-header-cont">
                    <h3 class="critical-alerts-widget-header">
                     <span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.5rem; font-variation-settings: 'FILL' 1; color: #F2C777;">error</span>    
                    Level 3 Alerts</h3>
                   
                        <h3 class="alert-count">
                            ${this.noncritical_count}
                        </h3>
                    
                </div>
                <div class="critical_alerts_block">
                    ${this.noncritical_alerts.map(
                (alert) => html`
                            <div
                                @click=${(_e) => this.handleAlertClick(alert)}
                                class="alerts_overview_critical_alert_item"
                                style="margin-bottom: 15px; flex-shrink: 0;"
                            >
                            ${alert.status != 'active' ? html`<span class="dash-alert-status ${alert.status}">${alert.status}</span>` : ''}
                                <div style="display: flex; align-items: center;">
                                    <span
                                        class="material-symbols-outlined profile ${alert.status}"
                                        style='
                                            margin-right: 7px;
                                            font-size: 17px;
                                            color:var(--t-color-dark);
                                            font-variation-settings: "FILL" 1, "wght" 700;
                                        '
                                    >
                                        account_circle
                                    </span>
                                    <span
                                        style="font-weight: 700; font-size: 14px; line-height: 20px; color: var(--t-color-dark);"
                                        >${alert.patient_id.first_name}
                                        ${alert.patient_id.last_name}
                                    </span>
                                </div>
                                <div style="display: flex; align-items: center; margin-top: 7px;">
                                    <span
                                        class="material-symbols-outlined"
                                        style='
                                            margin-right: 7px;
                                            font-size: 16px;
                                            line-height: 20px;
                                            color: var(--t-color-light-grey);
                                            font-size: 17px;
                                            font-variation-settings: "FILL" 1, "wght" 700;
                                        '
                                    >
                                        report
                                    </span>
                                    <div
                                        style="
                                            font-weight: 700;
                                            font-size: 12px;
                                            line-height: 20px;
                                            color: var(--t-color-light-grey);
                                        "
                                    >
                                        ${alert.subject}
                                    </div>
                                </div>
                            </div>
                        `
            )}
                </div>
            `;
        }
        Object.assign(this.style, {
            display: "block",
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            padding: "24px",
        });

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.alerts) return;
        if (!this.survey_definition) return;

        render(this.template(), this);
    }

    /**
     * @param {Object} alert 
     * @fires AppDashboardCriticalAlerts#alertclick
     */
    handleAlertClick(alert) {
        this.dispatchEvent(new CustomEvent("alertclick", {
            detail: alert
        }))
    }
}

customElements.define("app-dashboard-level-three-alerts", AppDashboardNonCriticalAlerts);