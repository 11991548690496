import { html, render } from "lit";
import { subDays, parseISO, isAfter, format } from "date-fns";
import { navigate } from "shared/lib/lib-router";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import { getCurrentClient } from "shared/lib/lib-user";
import ApexCharts from 'apexcharts';

/**
 * A widget displaying reported unplanned hospital care events.
 * @param {object} metrics response from /dashboard_metrics
 */
export default class AppDashboardOasisTransfers extends HTMLElement {
    constructor() {
        super();
        this.client_id = getCurrentClient();
        this.chart = null;
    }

    set metrics(value) {
        this._metrics = value;
        this.render();
    }

    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            this.thirty_day_summary = this.metrics?.week_over_week_metrics?.thirty_day_summary || {};

            this.total_scheduled_count = this.thirty_day_summary.episodes_with_schedule || 0;
            this.total_unscheduled_count = this.thirty_day_summary.episodes_without_schedule || 0;
            this.total_episodes = this.thirty_day_summary.total_episodes || 0;

            this.total_hospitalizations = this.thirty_day_summary.total_hospitalizations || 0;
            this.hospitalization_with_schedule = this.thirty_day_summary.hospitalizations_with_schedule || 0;
            this.hospitalization_without_schedule = this.thirty_day_summary.hospitalizations_without_schedule || 0;
            this.total_hospitalization_rate = this.thirty_day_summary.hospitalization_rate || 0;
            this.hospitalization_rate_with_schedule = this.thirty_day_summary.hospitalization_rate_with_schedule || 0;
            this.hospitalization_rate_without_schedule = this.thirty_day_summary.hospitalization_rate_without_schedule || 0;

            this.total_comm_only_count = this.thirty_day_summary.unique_patient_communication_count || 0;

            this.hospitalization_rate_with_comm = this.total_comm_only_count > 0
                ? parseFloat(((this.hospitalization_with_schedule / (this.total_comm_only_count + this.total_scheduled_count)) * 100).toFixed(2))
                : this.hospitalization_rate_with_schedule;

            return html`
                <style>
                    .chart-container {
                        width: 100%;
                        height: 300px;
                    }
                </style>
                <div id="dashboard-hospital" @click=${_e => navigate(`reports/patient/hospitalizations`)}>
                    <h6>Hospitalization Rates Per Episoode</h6>
                    <div class="row oasis-metrics">
                        <div class="col col-12 position-absolute ">
                            <div class="inner-widget-header-wrap">
                                <div class="oasis-stats-group">
                                <h5>All Episodes</h5>
                                <div class="hospital-widget-row hosp-counts"><span>${this.total_hospitalizations}<span class="count-total"> / ${this.total_episodes}</span></div>
                                <div class="hospital-widget-row rate"><h5>Rate</h5><span>${this.total_hospitalization_rate.toFixed(2)}</span></div>
                                </div>
                                <div class="oasis-stats-group">
                                <h5><span style="color: #787894; margin: 0 5px 0 0">⬤</span>VB Schedule</h5>
                                <div class="hospital-widget-row hosp-counts"><span>${this.hospitalization_with_schedule}<span class="count-total"> / ${this.total_scheduled_count}</span></div>
                                <div class="hospital-widget-row rate"><h5>Rate</h5><span>${this.hospitalization_rate_with_schedule.toFixed(2)}</span></div>
                                </div>
                                <div class="oasis-stats-group">
                                <h5><span style="color: #199afbb5; margin: 0 5px 0 0">⬤</span>No Schedule</h5>
                                <div class="hospital-widget-row hosp-counts"><span>${this.hospitalization_without_schedule}<span class="count-total"> / ${this.total_unscheduled_count}</span></div>
                                <div class="hospital-widget-row rate"><h5>Rate</h5><span>${this.hospitalization_rate_without_schedule.toFixed(2)}</span></div>
                                </div>
                            </div>
                           <!--  <span class="hosp-percent">*Episodes that have been active within the last 30 days.</span> -->
                        </div>

                        <div class="col col-12 chart-cont">
                            <div id="week-over-week-chart" class="chart-container"></div> 
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px 15px 0 15px",
            //paddingBottom: "12px",
        });
        this.render();
    }

    processWeekOverWeekData(data) {
        const today = new Date();
        const fiveWeeksAgo = subDays(today, 35);

        return data
            .filter(week => {
                const weekStart = parseISO(week.week_start);
                return isAfter(weekStart, fiveWeeksAgo) && !isAfter(weekStart, today);
            })
            .map(week => ({
                week_start: week.week_start,
                with_schedule: week.hospitalizations_with_schedule || 0,
                without_schedule: week.hospitalizations_without_schedule || 0,
                total_episodes: week.total_episodes || 0,
                hospitalization_rate: week.hospitalization_rate || 0,
                episodes_with_schedule: week.episodes_with_schedule || 0,
                episodes_without_schedule: week.episodes_without_schedule || 0,
                hospitalization_rate_with_schedule: week.hospitalization_rate_with_schedule || 0,
                hospitalization_rate_without_schedule: week.hospitalization_rate_without_schedule || 0
            }))
            .slice(0, 4); // Limit to the first 4 weeks
    }

    renderChart() {
        setTimeout(() => {
            try {
                const chartContainer = this.querySelector("#week-over-week-chart");
                if (!chartContainer) {
                    console.error('Chart container not found');
                    return;
                }

                const weekOverWeekData = this.processWeekOverWeekData(this.metrics?.week_over_week_metrics?.weekly_stats || []);

                const options = {
                    series: [{
                        name: 'Without Schedule',
                        data: weekOverWeekData.map(week => week.without_schedule)
                    }, {
                        name: 'With Schedule',
                        data: weekOverWeekData.map(week => week.with_schedule)
                    }],
                    chart: {
                        type: 'area',
                        height: 205,
                        width: '100%',
                        margin: 0,
                        toolbar: {
                            show: false,
                        },
                        sparkline: {
                            enabled: false
                        }
                    },
                    legend: {
                        show: false,
                    },
                    fill: {
                        colors: undefined,
                        opacity: 0.1,
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.8,
                            stops: [0, 90, 100]
                        }
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2,
                    },
                    xaxis: {
                        categories: weekOverWeekData.map(week => format(parseISO(week.week_start), 'MMM d')),
                        labels: {
                            show: false,
                            rotate: 0,
                            style: {
                                fontSize: '10px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                            },
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        },
                        tooltip: {
                            enabled: true
                        },
                    },
                    yaxis: {
                        tooltip: {
                            enabled: false
                        },
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        }
                    },
                    grid: {
                        show: false,

                        left: 0,
                        right: 0

                    },
                    colors: ['#008FFB', '#4e4f7f'],
                    tooltip: {
                        enabled: true,
                        shared: false,
                        intersect: true,
                        //custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        //    const data = weekOverWeekData[dataPointIndex];
                        //    return `
                        //        <div class="custom-tooltip">
                        //            <div>Week of ${format(parseISO(data.week_start), 'MMM d, yyyy')}</div>
                        //            <div>Total Episodes: ${data.total_episodes}</div>
                        //            <div>Hospitalization Rate: ${data.hospitalization_rate.toFixed(2)}%</div>
                        //            <div>With Schedule: ${data.episodes_with_schedule} (${data.hospitalization_rate_with_schedule.toFixed(2)}%)</div>
                        //            <div>Without Schedule: ${data.episodes_without_schedule} (${data.hospitalization_rate_without_schedule.toFixed(2)}%)</div>
                        //        </div>
                        //    `;
                        //}
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                            return val;
                        },
                        tooltip: {
                            enabled: false
                        },
                        style: {
                            fontSize: '10px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 'bold',
                            colors: ['#008FFB', '#4e4f7f']
                        },
                        background: {
                            enabled: true,
                            foreColor: '#fff',
                            padding: 4,
                            borderRadius: 2,
                            borderWidth: 1,
                            borderColor: '#fff',
                            opacity: 0.9,
                        },
                    },
                    markers: {
                        size: 4,
                        colors: ['#008FFB', '#4e4f7f'],
                        strokeColors: '#fff',
                        strokeWidth: 2,
                        hover: {
                            size: 6,
                        }
                    },
                };

                if (this.chart) {
                    this.chart.updateOptions(options);
                } else {
                    this.chart = new ApexCharts(chartContainer, options);
                    this.chart.render();
                }
            } catch (error) {
                console.error('Error rendering chart:', error);
            }
        }, 0);
    }

    render() {
        if (!this.template) {
            console.warn('AppDashboardOasisTransfers - Template not defined');
            return;
        }
        if (!this.metrics) {
            console.warn('AppDashboardOasisTransfers - Metrics not set');
            return;
        }
        render(this.template(), this);
        this.renderChart();
    }
}

customElements.define("app-dashboard-oasis-transfers", AppDashboardOasisTransfers);
