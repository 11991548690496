import { html, render } from "lit";

export default class AppNoClient extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.style.width = "100%";
        this.style.height = "100%";
        this.style.backgroundColor = "var(--t-color-sidebar)";
        this.style.color = "white";
        this.innerHTML = `
        <div style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex-fill:
        ">
            <img src="/client/images/logo-white-1.png" class="mb-4" />
            <h1>Sorry</h1>
            <p>You are not currently associated with an organization.</p>
            <p>If you feel this is an error, please reach out to your manager.</p>
        </div>
        `;
    }
}

customElements.define("app-noclient", AppNoClient);