import DOMPurify from "dompurify";

class TaggingContentEditable extends HTMLElement {
    set content(value) {
        if (!value) this.innerHTML = " ";
        else {
            let markup = DOMPurify.sanitize(value);
            this.innerHTML = markup;
            for (let node of this.childNodes)
                if (node.dataset?.taggingTagType) node.setAttribute("contenteditable", "false");
        }
    }

    get content() {
        return DOMPurify.sanitize(this.innerHTML);
    }

    get selected_result_index() {
        return this._selected_result_index;
    }

    set selected_result_index(value) {
        this.selected_result = null;
        //clamp value to result range
        if (value < 0) value = 0;

        if (!this.search_results) return;
        if (!this.search_results.length) return;
        if (value > this.search_results.length - 1) value = this.search_results.length - 1;

        this._selected_result_index = value;
        this.selected_result = this.search_results[value];

        if (this.selected_result_element) {
            this.selected_result_element.classList.remove("selected_search_result");
        }
        if (this.search_div.childNodes.length) {
            this.selected_result_element = this.search_div.childNodes[value];
            this.selected_result_element.classList.add("selected_search_result");
        }
    }

    set editing(value) {
        this._editing = value;
        if (value) this.setAttribute("contenteditable", true);
        else this.removeAttribute("contenteditable");
    }

    get editing() {
        return this._editing;
    }

    /**
     * Accepts a function with signature async (search) => {....}
     */
    set mention_search_function(value) {
        this._mention_search_function = value;
    }

    get mention_search_function() {
        return this._mention_search_function;
    }

    /**
     * Accepts a function with signature async (search) => {....}
     */
    set tag_search_function(value) {
        this._tag_search_function = value;
    }

    get tag_search_function() {
        return this._tag_search_function;
    }

    /**
     * A function with the signature (result) => {...} that returns a string to render given
     * the indicated search result. This will be wrapped in a DIV for display in the search results.
     */
    set mention_result_template(value) {
        this._mention_result_template = value;
    }

    get mention_result_template() {
        return (
            this._mention_result_template ||
            ((result) => {
                return `${result.first_name} ${result.last_name}`;
            })
        );
    }

    /**
     * A function with the signature (result) => {...} that returns a string to render given
     * the indicated search result. This will be wrapped in a DIV for display in the search results.
     */
    set tag_result_template(value) {
        this._mention_result_template = value;
    }

    get tag_result_template() {
        return (
            this._tag_result_template ||
            ((result) => {
                return `${result.tag}`;
            })
        );
    }

    /**
     * Set a template function to be used to render the mention in the text.
     * Signature looks like (mention) => .... which returns a string to render.
     */
    set mention_template(value) {
        this._mention_template = value;
    }

    get mention_template() {
        return this._mention_template || ((mention) => `@${mention.first_name} ${mention.last_name}`);
    }

    /**
     * Set a template function to be used to render the tag in the text.
     * Signature looks like (tag) => .... which returns a string to render.
     */
    set tag_template(value) {
        this._tag_template = value;
    }

    get tag_template() {
        return this._tag_template || ((tag) => {
            //if this was selected from a search result
            if(typeof tag == 'object')
                return `#${tag.tag}`
            //otherwise it'll be just a string tag
            else
                return tag;
        });
    }

    get mentions() {
        let mentions = [];
        for (let i = 0; i < this.childNodes.length; i++) {
            const node = this.childNodes[i];
            if (node.nodeName == "SPAN" && node.dataset.taggingTagType == "mention")
                if (node.dataset.mention) mentions.push(JSON.parse(node.dataset.mention));
        }
        return mentions;
    }

    get tags() {
        let tags = [];
        for (let i = 0; i < this.childNodes.length; i++) {
            const node = this.childNodes[i];
            if (node.nodeName == "SPAN" && node.dataset.taggingTagType == "tag") 
                if (node.dataset.tag) tags.push(JSON.parse(node.dataset.tag));
        }
        return tags;
    }

    connectedCallback() {
        this.init();
    }

    init() {
        this.current_tag = "";
        this.addEventListener("input", (e) => this.handleInput(e));
        this.addEventListener("keydown", (e) => this.handleKeydown(e));
    }

    async handleKeydown(event) {
        if (this.is_tagging) {
            if (event.key == "Enter") {
                if (this.selected_result) {
                    this.createTag(this.selected_result);
                    return event.preventDefault();
                }
            }

            if (event.key == "ArrowUp") {
                this.selected_result_index--;
                return;
            }
            if (event.key == "ArrowDown") {
                this.selected_result_index++;
                return;
            }
        }
        return true;
    }

    async handleInput(event) {
        let key = event.data;
        this.event = event;
        if (key == "@") {
            return this.startTagging("mention");
        }
        if (key == "#") {
            return this.startTagging("tag");
        }
        if (key == "Escape") {
            return this.finishTagging();
        }

        if (this.is_tagging) {
            let range = this.identifyTag();
            let search_type;
            if (!range) return;

            this.range = range;
            let search = range.toString();
            if (search.startsWith("@")) {
                if (key == " ") return this.finishTagging();
                search = search.replace("@", "");
                search_type = "mention";
            } else if (search.startsWith("#")) {
                if (key == " ") {
                    if (search.length) {
                        this.createTag(search);
                    }
                    return this.finishTagging();
                }
                search = search.replace("#", "");
                search_type = "tag";
            }
            search = search.trim();

            //if there's no search term, and a backspace or delete or something was hit, the tag was deleted
            if (search == "" && !this._start_tagging && !event.shiftKey) return this.hideTagging();

            //deal with weird chrome bug that appends nbsp instead of space to the last space
            search = search.replace(/[^\x00-\x7F]/g, " ");
            if (search) {
                this._start_tagging = false;
                if (search == this._search) return;
                this._search = search;
                await this.updateSearch(search, search_type);
            }
        }
    }

    async updateSearch(search, search_type) {
        let results = [];
        if (search_type == "mention") {
            if (this.mention_search_function) results = await this.mention_search_function(search);
        } else if (search_type == "tag") {
            if (this.tag_search_function) results = await this.tag_search_function(search);
        }
        this.selected_result_index = 0;
        this.search_results = results;
        this.renderSearchResults(search_type);
    }

    renderSearchResults(search_type) {
        if (!this.search_results?.length) return;

        this.updateSearchResultElement();

        if(search_type == "mention") {
            for (let mention of this.search_results) {
                let result = this.mention_result_template(mention);
                let div = document.createElement("div");
                div.innerHTML = result;
                div.mention = mention;
                div.className = "tagging-content-editable-search-result";
                div.addEventListener(
                    "click",
                    () => {
                        this.createTag(mention);
                    },
                    true
                );
                this.search_div.appendChild(div);
            }
        }
        else if(search_type == "tag") {
            for (let tag of this.search_results) {
                let result = this.tag_result_template(tag);
                let div = document.createElement("div");
                div.innerHTML = result;
                div.tag = tag;
                div.className = "tagging-content-editable-search-result";
                div.addEventListener(
                    "click",
                    () => {
                        this.createTag(tag);
                    },
                    true
                );
                this.search_div.appendChild(div);
            }
        }
        this.selected_result_index = 0;
    }

    startTagging(tag_type) {
        this.is_tagging = true;
        this._start_tagging = true;
        this.tag_type = tag_type;
    }

    createSearchResultElement() {
        let search_div = document.createElement("div");
        search_div.style.position = "absolute";
        search_div.style.minWidth = "150px";
        search_div.style.backgroundColor = "white";
        search_div.style.border = "1px solid #77c3e0";
        search_div.style.borderRadius = "5px";
        search_div.style.padding = "5px";
        this.search_div = search_div;
    }

    updateSearchResultElement() {
        if (!this.search_div) {
            this.createSearchResultElement();
        }

        let selection = document.getSelection();
        let range = selection.getRangeAt(0);
        let rect = range.getBoundingClientRect();

        this.search_div.innerHTML = "";
        this.search_div.style.display = "block";
        this.search_div.style.top = rect.bottom + 5 + "px";
        this.search_div.style.left = rect.left - 30 + "px";
        this.search_div.style.zIndex = 10000;

        document.body.appendChild(this.search_div);
    }

    finishTagging() {
        this.is_tagging = false;
        this.current_tag = "";
        this.tag_type = "";
        this.search_results = null;
        this.selected_result_index = 0;
        this.selected_result = null;
        if (this.search_div) {
            this.search_div.innerHTML = "";
            this.search_div.style.display = "none";
            //document.body.removeChild(this.search_div);
        }
        this.range.detach();
    }

    hideTagging() {
        this.search_results = null;
        this.selected_result_index = 0;
        this.selected_result = null;
        if (this.search_div) {
            this.search_div.innerHTML = "";
            this.search_div.style.display = "none";
        }
    }

    createTag(tag) {
        let span;

        if (this.tag_type == "mention") {
            span = this.createMentionSpan(tag);
            this.range.surroundContents(span);
            span.innerHTML = this.mention_template(tag);
        }

        if (this.tag_type == "tag") {
            span = this.createHastagSpan(tag);
            this.range.surroundContents(span);
            span.innerHTML = this.tag_template(tag);
        }

        let text_node = document.createTextNode("\u00A0");
        this.appendChild(text_node);

        let range = document.createRange();
        range.setStart(text_node, 1);
        range.setEnd(text_node, 1);

        let selection = window.getSelection();
        selection.removeAllRanges();
        this.finishTagging();
        selection.addRange(range);
    }

    identifyTag() {
        let selection = document.getSelection();
        let range = selection.getRangeAt(0);
        let text = "";
        let initial_offset = range.startOffset;
        let offset;
        let tag;

        for (let i = 0; i < 100; i++) {
            text = range.toString();
            if (["@", "#"].includes(text[0])) {
                tag = range.cloneRange();
                range.setStart(range.commonAncestorContainer, initial_offset);
                range.detach();
                return tag;
            }
            offset = range.startOffset;
            if (offset == 0) {
                range.setStart(range.commonAncestorContainer, initial_offset);
                range.detach();
                return null;
            }

            range.setStart(range.commonAncestorContainer, range.startOffset - 1);
        }
    }

    measureText(text, font_info) {
        let tester = document.getElementById("measure-text-tester");
        if (!tester) {
            tester = document.createElement("div");
            tester.style.position = "absolute";
            tester.style.visibility = "hidden";
            tester.style.top = "-300px";
            tester.style.whitespace = "no-wrap";
            document.body.appendChild(tester);
        }

        if (font_info.fontSize) tester.style.fontSize = font_info.fontSize;
        if (font_info.fontFamily) tester.style.fontFamilt = font_info.fontFamily;

        tester.innerHTML = text;
        return {
            height: tester.clientHeight + 1,
            width: tester.clientWidth + 1,
        };
    }

    createMentionSpan(mention) {
        let span = document.createElement("span");
        span.style.color = "#3557CF";
        span.dataset.mention = JSON.stringify(mention);
        span.dataset.taggingTagType = "mention";
        span.setAttribute("contenteditable", "false");
        span.className = "tagging-content-editable-mention-wrapper";
        return span;
    }

    createHastagSpan(tag) {
        let span = document.createElement("span");
        span.content_editable_tag_type = "tag";
        span.dataset.tag = JSON.stringify(tag);
        span.dataset.taggingTagType = "tag";
        span.style.color = "#3557CF";
        span.style.boxShadow = "0px 2px 10px rgba(0, 39, 77, 0.08)";
        span.setAttribute("contenteditable", "false");
        span.className = "tagging-content-editable-tag-wrapper";
        return span;
    }
}

customElements.define("app-tagging-contenteditable", TaggingContentEditable);
export default TaggingContentEditable;
