import { html, render, LitElement } from "lit";
import directus from "shared/lib/lib-directus";
import ApplicationState from "applicationstate";
//import BootstrapTable from 'bootstrap-table/dist/bootstrap-table';
import DataDefinition from "shared/lib/lib-data-definition";
import { Dropdown } from 'bootstrap';
import { ref, createRef } from "lit/directives/ref.js";
import { getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";
import { noteCellFormatter } from "../util/lib-report";
import { format, parseISO, differenceInDays, startOfMonth, endOfMonth, addDays, isSameDay, subDays, subMonths, max } from 'date-fns';
import { TabulatorFull as Tabulator } from 'tabulator-tables';

import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";
//import ApexCharts from 'apexcharts';
//import { Calendar } from '@fullcalendar/core';
//import dayGridPlugin from '@fullcalendar/daygrid';
//import multiMonthPlugin from '@fullcalendar/multimonth';
//import bootstrap5Plugin from '@fullcalendar/bootstrap5';
//import interactionPlugin from '@fullcalendar/interaction';
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap-icons/font/bootstrap-icons.css';
export default class ReportPatientHosp extends LitElement {
    static get config() {
        return {
            icon: "personal_injury",
            report: "hospitalizations",
            title: "Hospitalizations",
            description: "Hospitalizations (Oasis Transfers)",
            component: ReportPatientHosp
        };
    }

    static get properties() {
        return {
            filter_state: { type: Object },
            start_date: { type: String },
            end_date: { type: String },
            default_sms_time: { type: String },
            default_timezone: { type: String },
            selected_columns: { type: Array },
            table_columns: { type: Array },
            filterCount: { type: Number },
            schedule_filter: { type: String },
            filters: { type: Object },

        };
    }
    createRenderRoot() {
        return this;
    }

    set filter_state(value) {
        const oldValue = this._filter_state;
        this._filter_state = value;
        this.requestUpdate('filter_state', oldValue);
    }

    get filter_state() {
        return this._filter_state;
    }

    set schedule_filter(value) {
        const oldValue = this._schedule_filter;
        this._schedule_filter = value;
        this.requestUpdate('schedule_filter', oldValue);

    }

    get schedule_filter() {
        return this._schedule_filter;
    }

    set selected_patients(value) {
        this._selected_patients = value;
        this.renderActionsMenu();
        this.updateDropdown();
        //console.log("selected patients setter", value);
    }

    get selected_patients() {
        return this._selected_patients;
    }

    constructor() {
        super();
        this.data = [];
        this.data_table = null;
        this.survey_definition = null;
        this._loading_data = true;
        this.filterCount = 0;
        //this.selected_patients = [];
        //this.filter_state = [];
        //this.fetchEpisodes = this.fetchEpisodes.bind(this);

        const endDate = new Date();
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 30);
        this.start_date = getISODateStringWithoutTime(startDate);
        this.end_date = getISODateStringWithoutTime(endDate);
        this.filters = [];
        const filter_string = new URL(location.href).searchParams?.get("filters")
        this.filter_state = JSON.parse(filter_string) || {
            "start_date_range": `${this.start_date},${this.end_date}`
        };
        // console.log("CONSRTUCTORRRRR episode date range", this.start_date, this.end_date);


        //pagination
        this._current_page = 1;
        this._page_size = 1000000;
        this._all_pages_fetched = false;
        this.schedule_filter = '';


        this.toggle = "";
        this._app_name = ApplicationState.get('app.staff.filter_state');
        //console.log("app name", this._app_name);

        this._options = {
            ajaxURL: "/items/visit",
            ajaxParams: {},
            debugInvalidOptions: false,
            sortMode: "remote",
            filterMode: "remote",
            //pagination: true,
            paginationSize: 100,
            paginationMode: "remote",
            //paginationCounterElement: "#page-count",
            ajaxResponse: function (url, params, response) {
                this.filterCount = response.meta.filter_count;
                const last_page = Math.ceil(response.meta.filter_count / params.limit);
                //console.log("last_page", last_page);
                //console.log("response", response);
                //console.log("params", params);
                return {
                    data: response.data,
                    last_page,
                };
            }.bind(this),

            //paginationSize: 25,
            //paginationCounter: "rows",
            layout: "fitDataFill",
            progressiveLoad: "scroll",
            ajaxProgressiveLoadScrollMargin: 100,
            ajaxProgressiveLoadDelay: 300,
            ajaxParams: { limit: 100 },
            //responsiveLayout: "collapse",
            //responsiveLayoutCollapseUseFormatters: false,
            //responsiveLayoutCollapseStartOpen: this.toggle,
            alignEmptyValues: "bottom",

            initialSort: [{ column: "visit_date", dir: "desc", sorter: "datetime" },],
            columnDefaults: {
                tooltip: false,
            },
            checkbox: false
        };
        this.selected_columns = [
            "patient_id.first_name",
            "patient_id.last_name",
            "patient_id.mrn",
            "patient_id.patient_alerts.date_created",
            "patient_id.patient_alerts.level",
            "patient_id.patient_alerts.subject",
            "episode_id.id",
            "episode_id.diagnosis_description",
            "episode_id.status",
            "episode_id.referral_source",
            "episode_id.ext_referral",
            "episode_id.status_reason",
            "episode_id.start_date",
            "episode_id.end_date",
            "episode_id.survey_schedule_id",
            "episode_id.survey_schedule_id.name",
            //"episode_id.surveys.id",

            "surveys.alerts.level",
            "surveys.alerts.subject",

            "soc",
            "primary_payer",
            "ext_referral",
            "referral_source",
            "careteam_member_id.last_name",
            "careteam_member_id.first_name",
            "patient_id.internal_memo",
            "id",
            "visit_date",
            "visit_description",
            "episode_id",

        ];
        this.table_columns = [


            {
                name: "Patient Name",
                fields: ["patient_id.last_name", "patient_id.first_name"],
                field: "name",
                formatter: (cell) => {
                    const rowData = cell.getRow().getData();
                    const lastName = rowData.patient_id.last_name;
                    const firstName = rowData.patient_id.first_name;
                    const patientId = rowData.patient_id;
                    const mrn = rowData.patient_id.mrn;

                    const wrapper = document.createElement('div');
                    wrapper.style.display = 'flex';
                    wrapper.style.alignItems = 'center';

                    const nameSpan = document.createElement('span');
                    nameSpan.innerHTML = `<strong style="margin: 0 3px 0 0;">${lastName}, ${firstName}</strong> - ${mrn}`;
                    wrapper.appendChild(nameSpan);


                    return wrapper;
                },

                sorter: "string",
                responsive: 0,
                sorterParams: {
                    field: "patient_id.last_name"
                },
                minWidth: 200,
            },
            {
                name: "Alert Before Hospitalization",
                field: "patient_id.patient_alerts",
                formatter: function (cell) {
                    const rowData = cell.getRow().getData();
                    const alerts = cell.getValue();
                    const episodeStartDate = rowData.episode_id.start_date;
                    const visitDate = rowData.visit_date;

                    const relevantAlerts = alerts.filter(alert =>
                        new Date(alert.date_created) >= new Date(episodeStartDate) &&
                        new Date(alert.date_created) <= new Date(visitDate)
                    );

                    if (relevantAlerts.length > 0) {
                        return relevantAlerts.map(alert =>
                            `Level ${alert.level}: ${alert.subject}`
                        ).join('<br>');
                    }
                    return "-";
                }
            }
            ,

            //{
            //    name: "Alert Before Hospitalization",
            //    field: "alert",
            //    formatter: async (cell) => {
            //        const rowData = cell.getRow().getData();
            //        const alerts = await this.getAlerts(rowData.patient_id.id);
            //        if (alerts.data && alerts.data.length > 0) {
            //            const alert = alerts.data[0];
            //            return `Level ${alert.level}: ${alert.subject}`;
            //        }
            //        return "No alerts";
            //    }
            //},
            //{
            //    name: "Last Communication",
            //    field: "last_communication",
            //    formatter: async (cell) => {
            //        const rowData = cell.getRow().getData();
            //        const communications = await this.getLastCommunication(rowData.patient_id.id, rowData.visit_date);
            //        if (communications.data && communications.data.length > 0) {
            //            const communication = communications.data[0];
            //            return format(parseISO(communication.date), "MM/dd/yyyy");
            //        }
            //        return "No communication";
            //    }
            //},

            //{
            //    name: "Primary Payor",
            //    field: "primary_payer",
            //
            //    minWidth: 250
            //},
            {
                title: "External Referral",
                name: "External Referral",
                //minWidth: 280,
                tooltip: true,
                field: "episode_id.ext_referral",
                formatter: function (cell) {
                    const rowData = cell.getRow().getData();
                    const referral = rowData.episode_id.referral_source;
                    const ext_ref = cell.getValue();
                    if (referral && ext_ref) {
                        return `<strong>${referral}</strong> - ${ext_ref}`;
                    } else if (ext_ref) {
                        return ext_ref;
                    } else if (referral) {
                        return referral;
                    }
                    return "";
                }
            },
            {
                name: "Schedule",
                field: "episode_id.survey_schedule_id",
                formatter: function (cell, formatterParams) {
                    // Check if the field has a value and return HTML with span
                    return cell.getValue() ? `
                    <div class="has-schedule"><span id="yes">
                    ${cell.getData().episode_id.survey_schedule_id.name}</span>` :
                        '<div class="has-schedule"><span id="no">No Schedule Found</span></div>';
                },
                //cellClick: function (e, cell) {
                //    const patientData = cell.getRow().getData();
                //    if (!patientData.episode_id.survey_schedule_id) {
                //        this.handleActivateSchedule(patientData);
                //    } else {
                //        this.showWarningModal("Episode already appears to have a schedule.");
                //    }
                //}.bind(this)

            },
            {
                name: "Date",
                fields: ["visit_date", "visit_description"],
                field: "visit_date",
                width: 40,
                formatter: (cell) => {
                    const rowData = cell.getRow().getData();
                    const date = rowData.visit_date;
                    const visit = rowData.visit_description;
                    const formatted_date = format(parseISO(date), "M/d/yy");


                    const wrapper = document.createElement('div');
                    wrapper.style.display = 'flex';
                    wrapper.style.alignItems = 'center';

                    const nameSpan = document.createElement('span');
                    wrapper.innerHTML = `<strong style="margin: 0 auto 0 auto;">${formatted_date}</strong>`;
                    //wrapper.appendChild(nameSpan);


                    return wrapper;
                },

                sorter: "datetime",
                responsive: 0,
                sorterParams: {
                    format: "M/d/yy",
                    field: "visit_date",
                    //alignEmptyValues: "bottom",
                },
                minWidth: 200,
            },
            {
                title: "Primary Diagnosis",
                name: "PDX",
                minWidth: 280,
                tooltip: true,
                field: "episode_id.diagnosis_description",
                formatter: function (cell) {
                    const diagnosis = cell.getValue();
                    if (diagnosis) {
                        return diagnosis;
                    }
                    return "";
                }
            },
            //{
            //
            //    //width: 70, // Slight increase to balance total
            //    //hozAlign: "center",
            //    headerSort: false,
            //    responsive: 0,
            //    //frozen: true,
            //    formatter: (cell) => {
            //        const patient_id = cell.getRow().getData().patient_id;
            //        //console.log("patient_id", patient_id);
            //        // Create a span element
            //        const span = document.createElement("span");
            //        span.classList.add("material-symbols-outlined");
            //        span.style.fontSize = "18px";
            //        span.setAttribute("alt", "Calendar");
            //        span.textContent = "calendar_month";
            //
            //        // Add click event listener
            //        span.addEventListener("click", () => {
            //            this.showCalendar(patient_id);
            //        });
            //
            //        return span; // Return the span as a DOM Node
            //    },
            //},

        ];

        this._filter_config = {
            collection: { name: "visit", auto_configure: true, depth: 2 },
            search_fields: [
                "patient_id.first_name",
                "patient_id.last_name",
                "patient_id.mrn",
                "patient_id.medicare_id",
                "episode_id.referral_source",
                "episode_id.ext_referral",
            ],
            field_rules: {
                patient: {
                    mode: "whitelist",
                    fields: [
                        "first_name",
                        "last_name",
                        "mrn",
                        "medicare_id",
                        "status",
                        "internal_memo",
                    ],
                },
                visit: {
                    mode: "whitelist",
                    fields: [
                        "visit_date",
                        "status",
                        "visit_description",
                        "visit_type",
                        "episode_id",
                        "episode_id.diagnosis_description",
                        "episode_id.survey_schedule_id",
                    ]
                },
                //surveys: {
                //    mode: "whitelist",
                //    fields: [
                //        "scheduled_date",
                //        "status",
                //        "task_type",
                //        "completed_date",
                //    ]
                //},
                episode: {
                    mode: "whitelist",
                    fields: [
                        "survey_schedule_id",
                        "ext_referral",
                        "referral_source",
                        "soc",
                        "start_date",
                        "end_date",
                        "primary_payer",
                        "visits.visit_description",
                        "visits.visit_date",
                        "diagnosis_description",
                        "grouper"

                    ],
                },
            }
        };

        // this._filter_config.field_rules.episode.fields.push("survey_schedule_id");

        this.dropdown = null;
    }

    async showCalendar(patient) {
        const modal = new AppCalendarViewModal(patient);
        await modal.showModal();
    }


    connectedCallback() {
        super.connectedCallback();
        this.init();
    }

    init() {
        // console.log("init episode date range xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", this.start_date, this.end_date);
        let filter_state = ApplicationState.get('app.patient_schedule.filter_state');
        if (!filter_state) {
            filter_state = { user_search: "" };
            filter_state.group = {
                type: "_and",
                filters: [
                    //{
                    //    "field": "status",
                    //    "op": "_in",
                    //    "value": [this.status_filter]
                    //},
                ],
                groups: [],
            };

        }


        let filter_element = this.querySelector('app-filter');
        if (filter_element) {
            filter_element.filter_state = filter_state;
        }

        this.filter_state = filter_state;
        this.render();

    }

    scheduleConfBtns(action) {

        if (action === "navigate") {
            window.history.pushState({}, '', '/dashboard/overview');
        }
    }

    defaultFilterDates() {
        const endDate = new Date();
        const startDate = new Date(endDate);
        startDate.setDate(startDate.getDate() - 30);
        return `${getISODateStringWithoutTime(startDate)},${getISODateStringWithoutTime(endDate)}`;
    }

    handleDateFilterChange(e) {
        const { start, end } = e.detail;
        this.start_date = getISODateStringWithoutTime(start);
        this.end_date = getISODateStringWithoutTime(end);
    }


    async handleFilterChange(filter_state) {
        ApplicationState.set('app.patient_schedule.filter_state', filter_state, { immutable: true });
        this.filter_state = filter_state;
        this.render();
    }



    handleSelect(episodes) {
        this.selected_episodes = episodes;
        this.selected_patients = episodes.map(episode => ({
            id: episode.patient_id.id,
            first_name: episode.patient_id.first_name,
            last_name: episode.patient_id.last_name,
            client_id: episode.client_id,
            episode_id: episode.id
        }));
    }


    async getAlerts(patientId) {
        if (!patientId) {
            return null;
        }
        console.log("patientId", patientId);
        this.alerts = directus.items("alert").readByQuery({
            filter: {
                patient_id: { _eq: patientId },
                //date_created: { _between: [this.start_date, this.end_date] }
            },
            fields: ["level", "subject", "date_created"],
            sort: ["-date_created"],
            limit: 1
        });
        console.log("alerts", this.alerts);
    }

    async getLastCommunication(patientId, beforeDate) {
        const result = await directus.items("patient_communications").readByQuery({
            filter: {
                patient_id: { _eq: patientId },
                _or: [
                    {
                        _and: [
                            { communication_type: { _eq: "sms" } },
                            { scheduled_date: { _lt: beforeDate } }
                        ]
                    },
                    {
                        _and: [
                            { communication_type: { _eq: "phone_voice" } },
                            { date_created: { _lt: beforeDate } }
                        ]
                    }
                ]
            },
            sort: [
                {
                    field: "scheduled_date",
                    order: "desc",
                    filter: {
                        communication_type: { _eq: "sms" }
                    }
                },
                {
                    field: "date_created",
                    order: "desc",
                    filter: {
                        communication_type: { _eq: "phone_voice" }
                    }
                }
            ],
            limit: 1
        });

        return result.data[0] || null;
    }



    adjustHeights() {
        //console.log('Adjusting heights...');
        const mainView = this.querySelector("#app-filter-tabulator-table");
        if (mainView) {
            mainView.style.height = `${100 / this.zoomLevel}vh`;
        }

        const mainRouterOutlet = this.querySelector("#app-filter-tabulator-table");
        if (mainRouterOutlet) {
            mainRouterOutlet.style.height = `calc((100vh - (80px * .8) )`;
        }
    }

    async refreshTableData() {
        await new Promise(resolve => setTimeout(resolve, 250));
        const filterDataViewComponent = this.querySelector('app-filter-data-view-server');
        if (filterDataViewComponent) {
            filterDataViewComponent.refreshTableData();
        }
    }

    additionalFilters() {
        let filters = {
            type: "_and",
            filters: [
                {
                    field: "client_id",
                    op: "_eq",
                    value: getCurrentClientId()
                },
                {
                    field: "visit_date",
                    op: "_between",
                    value: [this.start_date, this.end_date]
                },
                {
                    field: "visit_description",
                    op: "_contains",
                    value: "OASIS-E Transfer"
                }

            ]
        };

        // Now, conditionally add your dynamic filter if it exists
        if (this.schedule_filter) {
            filters.filters.push(this.schedule_filter); // Note: Pushing into the filters array inside the object
        }

        // Store the filters configuration in an instance variable
        this.filters = filters;
        return filters;
    }


    render() {

        return html`

            <div class="container-fluid" style="height: calc(100% * 1.09); padding: 0;">
                <div class="row" style="">
                     <div class="actions-menu schedule-scene">

                        <div class="filter-container" style="align-items: center;">            
                            <app-filter 
                                @filter_change=${e => this.handleFilterChange(e.detail)}
                                style=""
                                .filter_state=${this.filter_state}
                                .config=${this._filter_config}
                                .enable_aggregate=${false}
                                .disable_sort=${false}
                                .show_filters=${false}
                                .expanded=${false}
                            ></app-filter>
                        </div>
                        
                        <div class="filter-container" style="align-items: center;">
                            <app-schedule-filter
                                .title=${"Start Date Range"}
                                .is_datepicker=${true}
                                .value=${this.filter_state?.start_date_range || this.defaultFilterDates()}
                                @dateselect=${e => this.handleDateFilterChange(e)}
                                @clear=${_e => this.handleFilterClear("episode_date_range")}
                            ></app-schedule-filter>
                        </div>

                        <div id="filter-count">
                        <span class="label">Showing:</span>
                        <span class="count"><strong style="margin: 0 10px 0 0;">${this.filterCount} </strong> Records</span>
                        </div>
                        <!--
                        
                        <app-filter-saved-views style="align-items: center; margin-left:auto;"
                            .screen=${"call_insights"}
                            @filter_change=${e => this.handleFilterChange(e.detail)}
                            .collection_name=${"episode"}
                            .filter_state=${this.filter_state}
                        ></app-filter-saved-views>
                         -->

                    </div> 
                </div>
                     
                    <div class="row" style="padding: 0 16px; border-radius: 8px;" id="patients-import-container">
                        <app-filter-data-view-server
                            .config=${this._filter_config}
                            .filter_state=${this.filter_state}
                            .status_filter=${this.status_filter}
                            .selected_columns=${this.selected_columns}
                            .options=${this._options}
                            .table_columns=${this.table_columns}
                            .selected_data=${this.selected_patients}
                            .selection_type=${"multiple"}
                            .additional_filters=${this.additionalFilters()}
                            @select-row=${e => this.handleSelect(e.detail)}
                            @expand-collapse - all=${e => this.handleExpandCollapseAll(e.detail.action)}
                            style = "padding: 0;"
                        ></app-filter-data-view-server>
                    </div>
                </div>
    `;

        //this.render();
        this.init();
        this.adjustHeights();

        console.log("import patients imported connected callback", this.episode_date_range);
        //this.addEventListener('click', event => { // listner for toggle all rows buttons expand/collapse (not being used)
        //    if (event.target.id === 'toggle-all-rows-btn') {
        //        this.expandCollapseAllRows('expand');
        //    }
        //});

    }

}

customElements.define("report-patient-hosp", ReportPatientHosp);
