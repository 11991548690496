import { html, render } from "lit";
import directus from '../../lib/lib-directus';
import { format, parse } from "date-fns";

export default class AppPatientOasis extends HTMLElement {
    constructor() {
        super();
        this._patient = null;
        this._oasis = [];
    }

    get patient() {
        return this._patient;
    }

    set patient(value) {
        const old_id = this.patient?.medicare_id;
        const new_id = value?.medicare_id;
        if (old_id !== new_id) this.fetchPatientFiles(new_id);
        this._patient = value;
        this.render();
    }

    get oasis() {
        return this._oasis;
    }

    set oasis(values) {
        if (!values || !Array.isArray(values)) this._oasis = [];
        else this._oasis = values;
    }

    connectedCallback() {
        this.template = () => html` <style>
                .patient-files {
                    border-radius: 8px;
                    box-shadow: var(--t-box-shadow);
                }

                .patient-files small {
                    color: var(--t-color-grey);
                }
            </style>
            <div class="m-4 p-4 content-background patient-files">
                <div class="row">
                    <div class="col">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Created</th>
                                    <th>Start of Care</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${this._oasis.length
                ? this._oasis.map(
                    (oasis) => html` <tr>
                                              <td class="text-start d-flex d-md-table-cell" style="flex-wrap: wrap;">
                                                  <div class="d-md-none" style="flex: 1; color: #999;">Name</div>
                                                  <a href="patients/${this.patient.id}/oasis/${oasis.id}">${oasis.M0040_PAT_FNAME} ${oasis.M0040_PAT_LNAME}</a>
                                              </td>
                                              <td class="d-flex d-md-table-cell" style="flex-wrap: wrap;">
                                                  <div class="d-md-none text-start" style="flex: 1; color: #999;">
                                                      Created
                                                  </div>
                                                  ${format(new Date(oasis.date_created), "MMM do, yyyy")}
                                              </td>
                                              <td class="d-flex d-md-table-cell" style="flex-wrap: wrap;">
                                                  <div class="d-md-none text-start" style="flex: 1; color: #999;">
                                                      Start of care
                                                  </div>
                                                  ${format(
                        parse(oasis.M0030_START_CARE_DT, "yyyyMMdd", new Date()),
                        "MMM do, yyyy"
                    )}
                                              </td>
                                          </tr>`
                )
                : html` <tr>
                                          <td colspan="4">
                                              <i>no files for ${this._patient.first_name} ${this._patient.last_name}</i>
                                          </td>
                                      </tr>`}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>`;

        this.style = Object.assign(this.style, {
            display: "block",
        });

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        render(this.template(), this);
    }

    async fetchPatientFiles(medicare_id) {
        const res = await directus.items("hh_assessment_oasis").readByQuery({
            filter: {
                M0063_MEDICARE_NUM: {
                    _eq: medicare_id,
                },
            },
            sort: ["-date_created"],
        });

        this.oasis = res.data;
        this.render();
    }
}

customElements.define("app-patient-oasis", AppPatientOasis);
