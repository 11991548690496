import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import { format } from "date-fns";
import directus from "../../lib/lib-directus";
import Cache from "../../lib/lib-cache";
import { getCurrentClientId } from "../../lib/lib-user";
import { parseISODateWithoutTime } from "../../lib/lib-date";
import AppPatientCareTeamModal from "./app-patient-care-team-modal";
import { ref, createRef } from 'lit/directives/ref.js';

export default class AppPatientProfile extends HTMLElement {
    set patient(value) {
        if (value) {
            // Assuming `primary_phone` and `secondary_phone` are the fields you want formatted
            value.primary_phone = this.formatPhoneNumber(value.primary_phone);
            value.secondary_phone = this.formatPhoneNumber(value.secondary_phone);
        }
        this._patient = value;
        this.expandPatient();
        this.render();
    }
    get patient() {
        return this._patient;
    }
    get has_changes() {
        return this._has_changes;
    }

    set has_changes(value) {
        this._has_changes = !!value;
        this.render();
    }

    get editable() {
        return this._editable;
    }

    set editable(value) {
        this._editable = !!value;
        this.render();
    }

    get clinicians() {
        return this._clinicians;
    }

    set clinicians(values) {
        this._clinicians = values;
    }

    get case_managers() {
        return this._case_managers;
    }

    set case_managers(values) {
        this._case_managers = values;
    }

    set care_team(values) {
        this._care_team = values;
        this.render();
    }

    get care_team() {
        return this._care_team;
    }

    constructor() {
        super();
        this._has_changes = false;
        this._editable = false;
        this._clinicians = null;
        this._care_team = null;
        this.changes = {};
        this.patient_access_changes = {};
    }

    connectedCallback() {
        this.template = () => {
            const { changes, patient, has_changes, editable } = this;
            const birth_date = changes.birth_date || patient.birth_date;

            const care_team_ids = this.care_team?.map(team_member => team_member.id) || [];
            const filtered_clinicians = this.clinicians?.filter(clinician => !care_team_ids.includes(clinician.id)) || [];

            return html`
                <style>
                    app-patient-profile .editable_item {
                        position: relative;
                    }
                    app-patient-profile .editable_item_confirm {
                        font-size: 24px;
                        color: var(--t-color-primary);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                    app-patient-profile .edit_icon {
                        margin-left: 10px;
                        font-size: 18px;
                        width: 20px;
                        color: var(--t-color-primary);
                        cursor: pointer;
                    }
                    app-patient-profile .error_message {
                        font-size: 14px;
                        color: var(--t-color-danger);
                    }
                    app-patient-profile .form-label {
                        font-size: 13px;
                        font-weight: 700;
                        color: var(--t-color-dark);
                        margin-bottom: 0px;
                        margin-top: 10px;

                    }

                    app-patient-profile .row {
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    app-patient-profile .accordion-collapse {
                        margin-bottom: 10px;
                    }

                </style>
                <div class="accordion accordion-flush">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panel_contact" aria-expanded="true" aria-controls="panel_contact">
                                Contact Information
                            </button>
                        </h2>
                        ${this.contactInformationPanel(patient)}
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panel_demographics" aria-expanded="false" aria-controls="panel_demographics">
                                Demographics
                            </button>
                        </h2>
                        ${this.demographicsPanel(patient)}
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panel_clinical" aria-expanded="false" aria-controls="panel_clinical">
                                Clinical Information
                            </button>
                        </h2>
                        ${this.clinicalInformationPanel(patient)}
                    </div>
                    
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panel_careteam" aria-expanded="false" aria-controls="panel_careteam">
                                Care Team
                            </button>
                        </h2>
                        ${this.careTeamPanel(patient)}
                    </div>
                </div>
            `}

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;

        render(this.template(), this);
    }

    async init() {
        let elements = this.querySelectorAll(".editable_item");
        for (let element of elements) {
            element.addEventListener("mouseover", () => {
                let edit_icon = element.querySelector(".edit_icon");
                if (!edit_icon)
                    return;
                edit_icon.style.display = "inline";
            });
            element.addEventListener("mouseout", () => {
                let edit_icon = element.querySelector(".edit_icon");
                if (!edit_icon)
                    return;
                edit_icon.style.display = "none";

            });
            element.addEventListener("click", (e) => {
                let edit_prop = e.currentTarget.dataset.editProp;
                this[edit_prop] = true;
                this.render();

                let edit_element = this.querySelector("input,select");
                if (edit_element)
                    edit_element.focus();

            });
            element.addEventListener("edit_complete", e => {
                element.classList.remove("edit_error");
                let error_element = element.querySelector(".error_message");
                error_element.innerHTML = '';
                let edit_prop = e.currentTarget.dataset.editProp;
                this[edit_prop] = false;
                this.render();
            });
            element.addEventListener("edit_error", e => {
                let err = e.detail;
                element.classList.add("error");
                let error_element = element.querySelector(".error_message");
                if (!error_element)
                    return;
                error_element.innerHTML = err.message;
            });
        }
        await this.loadClinicians();
        await this.loadPatientCareTeam();
    }

    demographicsPanel(patient) {
        return html`
                        <div id="panel_demographics" class="accordion-collapse collapse">
                            <div class="row editable_item" 
                                style="margin-top: 0px;"
                                @keydown=${e => this.handleKeypress("name_editing", e)}
                                data-edit-prop="name_editing">
                                <label class="form-label">Name</label>
                            ${this.name_editing ?
                html`
                                    <div class="col-sm-5">
                                        <label for="profile-first_name" class="form-label">First Name</label>
                                        <input type="text"
                                            id="profile-first_name"
                                            class="form-control"
                                            name="first_name"
                                            .value=${patient.first_name || ''}
                                            placeholder="first name"
                                            @input=${e => this.handleChange("first_name", e.target.value)}
                                            />
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="profile-last_name" class="form-label">Last Name</label>
                                        <input type="text"
                                            id="profile-last_name"
                                            class="form-control"
                                            name="last_name"
                                            .value=${patient.last_name || ''}
                                            placeholder="last name"
                                            @input=${e => this.handleChange("last_name", e.target.value)}
                                            />
                                    </div>
                                    <div class="col-sm-1 material-symbols-outlined editable_item_confirm"
                                        @click=${e => this.patch({ first_name: this.changes.first_name, last_name: this.changes.last_name }, e.target)}
                                    >check_circle</div>
                                    <div class="error_message"></div>
                                    `
                :
                html`
                                        <div class="col" style="display: flex; align-items: center">
                                            ${patient.first_name} ${patient.last_name}
                                            <span style="display: none" class="edit_icon material-symbols-outlined">edit</span>
                                        </div>
                                    `
            }
                            </div>
                            <div class="row editable_item" 
                                @keydown=${e => this.handleKeypress("sex_editing", e)}
                                data-edit-prop="sex_editing">
                                <label class="form-label">Sex</label>
                            ${this.sex_editing ?
                html`
                                    <div class="col-sm-11">
                                        <select id="profile-sex" class="form-select" name="sex" @change=${(e) => this.handleChange("sex", e.target.value)}>
                                            <option value="male" ?selected=${patient.sex === "male"}>Male</option>
                                            <option value="female" ?selected=${patient.sex === "female"}>Female</option>
                                            <option value="unknown" ?selected=${patient.sex === "unknown"}>Unknown</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-1 material-symbols-outlined editable_item_confirm"
                                        @click=${e => this.patch({ sex: this.changes.sex }, e.target)}
                                    >check_circle</div>
                                    <div class="error_message"></div>
                                    `
                :
                html`
                                        <div class="col" style="text-transform: capitalize; display: flex; align-items: center;">
                                            ${patient.sex}
                                            <span style="display: none" class="edit_icon material-symbols-outlined">edit</span>
                                        </div>
                                    `
            }
                            </div>
                            <div class="row editable_item" 
                                @keydown=${e => this.handleKeypress("birthdate_editing", e)}
                                data-edit-prop="birthdate_editing">
                                <label class="form-label">Birth date</label>
                            ${this.birthdate_editing ?
                html`
                                    <div class="col-sm-11">
                                        <input type="date"
                                            id="profile-birth_date"
                                            class="form-control"
                                            name="birth_date"
                                            .value=${patient.birth_date instanceof Date ? format(patient.birth_date, "yyyy-MM-dd") : patient.birth_date}
                                            placeholder="first name"
                                            @input=${(e) => this.handleChange("birth_date", e.target.value)} />
                                    </div>
                                    <div class="col-sm-1 material-symbols-outlined editable_item_confirm"
                                        @click=${e => this.patch({ birth_date: this.changes.birth_date }, e.target)}
                                    >check_circle</div>
                                    <div class="error_message"></div>
                                    `
                :
                html`
                                        <div class="col" style="display: flex; align-items: center">
                                            ${format(parseISODateWithoutTime(patient.birth_date), "MM/dd/yyyy")}
                                            <span style="display: none" class="edit_icon material-symbols-outlined">edit</span>
                                        </div>
                                    `
            }
                            </div>
                            <div class="row">
                                <label class="form-label">Medicare ID</label>
                                <div class="col-sm-8">${patient.medicare_id}</div>
                            </div>
                            <div class="row">
                                <label class="form-label">MRN</label>
                                <div class="col-sm-8">${patient.mrn}</div>
                            </div>
                            ${this.patient.address_line_1 ?
                html`
                            <div class="row">
                                <label class="form-label">Address</label>
                                <div>${this.patient.address_line_1}</div>
                                <div>${this.patient.address_line_2}</div> 
                                <div>${this.patient.city}, ${this.patient.state} ${this.patient.zip_code}</div>
                            </div>
                                `: ''
            }
                        </div>
        `;
    }

    clinicalInformationPanel(patient) {
        return html`
                        <div id="panel_clinical" class="accordion-collapse collapse">
                            <div class="row">
                                <label class="form-label">Primary Diagnosis</label>
                                <div class="col-sm-8">${patient.primary_diagnosis || 'Unknown'}</div>
                            </div>
                            <div class="row">
                                <label class="form-label">Secondary Diagnosis</label>
                                <div class="col-sm-8">${patient.secondary_diagnosis || 'Unknown'}</div>
                            </div>
                            ${this.patient.triage ?
                html`
                            <div class="row">
                                <label class="form-label">Triage</label>
                                <div class="col-sm-8">${patient.triage}</div>
                            </div>
                            `: ''}
                            ${this.patient.disaster_code ?
                html`
                            <div class="row">
                                <label class="form-label">Disaster Code</label>
                                <div class="col-sm-8">${patient.disaster_code}</div>
                            </div>
                            `: ''}
                            ${this.patient.pharmacy_name ?
                html`
                            <div class="row">
                                <label class="form-label">Pharmacy Information</label>
                                <div class="col-sm-8">
                                    ${patient.pharmacy_name} 
                                    <a href=${"tel:" + patient.pharmacy_number}>${patient.pharmacy_number}</a>
                                </div>
                            </div>
                            `: ''}
                            ${this.patient.clinical_summary ?
                html`
                            <div class="row">
                                <label class="form-label">Clinical Summary</label>
                                <div class="col-sm-8">${patient.clinical_summary}</div>
                            </div>
                            `: ''}
                        </div>
        `
    }

    toggleEditMode(edit_group) {
        this[edit_group] = !this[edit_group];
        this.render();
    }

    contactInformationPanel(patient) {
        return html`
                    <div id="panel_contact" class="accordion-collapse show">
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                        <div>

                        <!-- Primary Phone -->

                        <div class="row">
                        <label class="form-label">Primary Phone</label>
                        ${this.primary_phone_editing ?
                html`
                                <div class="col-sm-11">
                                    <input type="tel"
                                           id="profile-primary_phone"
                                           class="form-control"
                                           name="primary_phone"
                                           .value=${patient.primary_phone || ''}
                                           pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                           placeholder="Primary phone"
                                            @input=${(e) => this.handleChange("primary_phone", e)} />
                                </div>
                                <div class="error_message"></div>
                            `
                :
                html`
                                <div class="col-sm-8" style="display: flex; align-items: center">
                                    <span class="material-symbols-outlined" style='
                                        font-size: 1rem;
                                        margin-right: 7px;
                                        color: var(--t-color-primary);
                                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                    '>phone</span>
                                   <a href="tel:${patient.primary_phone || ''}">${patient.primary_phone ? this.formatPhoneNumber(patient.primary_phone) : 'none'}</a>

                                </div>
                            `
            }
                    </div>
                    


                            <!-- Secondary Phone  -->

                            <div class="row">
                            <label class="form-label">Secondary Phone</label>
                            ${this.secondary_phone_editing ?
                html`
                                    <div class="col-sm-11">
                                        <input type="tel"
                                            id="profile-secondary_phone"
                                            class="form-control"
                                            name="secondary_phone"
                                            .value=${patient.secondary_phone || ''}
                                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                            placeholder="secondary phone"
                                            @input=${(e) => this.handleChange("secondary_phone", e.target.value)} />
                                    </div>
                                    <div class="error_message"></div>
                                `
                :
                html`
                                    <div class="col-sm-8" style="display: flex; align-items: center">
                                        <span class="material-symbols-outlined" style='
                                            font-size: 1rem;
                                            margin-right: 7px;
                                            color: var(--t-color-primary);
                                            font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                        '>phone</span>
                                        <a href="tel:${patient.secondary_phone || ''}">${patient.secondary_phone ? this.formatPhoneNumber(patient.secondary_phone) : 'none provided'}</a>

                              
                                    </div>
                                `
            }
                        </div>
                        

                            <!-- emergency contact name -->

                            <div class="row">
                            <label class="form-label">Emergency Contact Name</label>
                            ${this.emergency_contact_name_editing ?
                html`
                                    <div class="col-sm-11">
                                        <input type="tel"
                                               id="profile-emergency_contact_name"
                                               class="form-control"
                                               name="emergency_contact_name"
                                               .value=${patient.emergency_contact_name || ''}
                                               placeholder="Emergency contact name"
                                               @input=${(e) => this.handleChange("emergency_contact_name", e.target.value)} />
                                    </div>
                                    <div class="error_message"></div>
                                `
                :
                html`
                                    <div class="col-sm-8" style="display: flex; align-items: center">
                                        <span>${patient.emergency_contact_name}</span>
                                    </div>
                                `
            }
                        </div>
                            
                        <!-- emergency contact relationship -->

                        <div class="row">
                        <label class="form-label">Emergency Contact Relationship</label>
                        ${this.emergency_contact_relationship_editing ?
                html`
                                <div class="col-sm-11">
                                    <input type="text"
                                           id="profile-emergency_contact_relationship"
                                           class="form-control"
                                           name="emergency_contact_relationship"
                                           .value=${patient.emergency_contact_relationship || ''}
                                           placeholder="Emergency contact relationship"
                                           @input=${(e) => this.handleChange("emergency_contact_relationship", e.target.value)} />
                                </div>
                                <div class="error_message"></div>
                            `
                :
                html`
                                <div class="col-sm-8" style="display: flex; align-items: center">
                                    <span>${patient.emergency_contact_relationship}</span>
                                </div>
                            `
            }
                        </div>
                    

                            <!-- emergency contact phone -->

                            <div class="row">
    <label class="form-label">Emergency Contact Phone</label>
    ${this.emergency_contact_phone_number_editing ?
                html`
            <div class="col-sm-11">
                <input type="tel"
                       id="profile-emergency_contact_phone_number"
                       class="form-control"
                       name="emergency_contact_phone_number"
                       .value=${patient.emergency_contact_phone_number || ''}
                       pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                       placeholder="Emergency contact phone"
                       @input=${(e) => this.handleChange("emergency_contact_phone_number", e.target.value)} />
            </div>
            <div class="error_message"></div>
        `
                :
                html`
            <div class="col-sm-8" style="display: flex; align-items: center">
                <span class="material-symbols-outlined" style='
                    font-size: 1rem;
                    margin-right: 7px;
                    color: var(--t-color-primary);
                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                '>phone</span>
                <a href="tel:${patient.emergency_contact_phone_number}">${patient.emergency_contact_phone_number}</a>
            </div>
        `
            }                       
</div>

                        </div>
                        




                                <!-- Edit Button -->
                            <div style="margin-left: auto; color: blue; cursor: pointer; padding: 1rem;">
                                <div class="editable_item"
                                     @keydown=${e => this.handleKeypress("emergency_contact_name_editing, primary_phone_editing, secondary_phone_editing, emergency_contact_relationship_editing, emergency_contact_phone_number_editing", e)}
                                     data-edit-prop="emergency_contact_name_editing, primary_phone_editing, secondary_phone_editing, emergency_contact_relationship_editing, emergency_contact_phone_number_editing">
                                     ${this.emergency_contact_name_editing || this.primary_phone_editing || this.secondary_phone_editing || this.emergency_contact_relationship_editing || this.emergency_contact_phone_number_editing ?
                html`
                                             <div class="col-sm-1"
                                                  @click=${e => {
                        let combinedChanges = {
                            emergency_contact_name: this.changes.emergency_contact_name,
                            primary_phone: this.changes.primary_phone,
                            secondary_phone: this.changes.secondary_phone,
                            emergency_contact_relationship: this.changes.emergency_contact_relationship,
                            emergency_contact_phone_number: this.changes.emergency_contact_phone_number
                        };
                        this.patch(combinedChanges, e.target);
                        this.emergency_contact_name_editing = false;
                        this.primary_phone_editing = false;
                        this.secondary_phone_editing = false;
                        this.emergency_contact_relationship_editing = false;
                        this.emergency_contact_phone_number_editing = false;
                    }}>
                                             Save
                                             </div>
                                             <div class="error_message"></div>
                                        `
                :
                html`
                                             <div class="col-sm-1"
                                                  @click=${() => {
                        this.emergency_contact_name_editing = true;
                        this.primary_phone_editing = true;
                        this.secondary_phone_editing = true;
                        this.emergency_contact_relationship_editing = true;
                        this.emergency_contact_phone_number_editing = true;
                    }}>
                                             Edit
                                             </div>
                                        `
            }
                                </div>
                            </div>
                                <!-- End Edit Button -->             
                    </div>
                </div>
        `
    }

    careTeamPanel(patient) {
        return html`
                        <div id="panel_careteam" class="accordion-collapse collapse">
                            ${patient.prescribing_physician_name ?
                html`
                            <div class="row editable_item">
                                <label class="form-label">Physician</label>
                                <div class="col-sm-4" style="display: flex; align-items: center">
                                    <span>${patient.prescribing_physician_name}</span>
                                </div>
                                <div class="col-sm-8" style="display: flex; align-items: center">
                                    <span class="material-symbols-outlined" style='
                                        font-size: 1rem;
                                        margin-right: 7px;
                                        color: var(--t-color-primary);
                                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                    '>phone</span>
                                    <a href="tel:${patient.prescribing_physician_phone}">${patient.prescribing_physician_phone}</a></div>
                                </div>
                            </div>
                            `: ''}
                            <div class="row editable_item" @click=${e => this.handleEditCareteam(e)} >
                                <label class="form-label col-sm-4">Primary Clinician</label>
                                <div class="col-sm-8" style="display: flex; align-items: center">
                                    ${patient.primary_clinician_id ?
                patient.primary_clinician_id.first_name + " " + patient.primary_clinician_id.last_name
                : "Not set"}
                                    <span style="display: none" class="edit_icon material-symbols-outlined">edit</span>
                                </div>
                            </div>
                            <div class="row editable_item" @click=${e => this.handleEditCareteam(e)} >
                                <label class="form-label col-sm-4">Case Manager</label>
                                <div class="col-sm-8" style="display: flex; align-items: center">
                                    ${patient.case_manager_id ?
                patient.case_manager_id.first_name + " " + patient.case_manager_id.last_name
                : "Not set"}
                                    <div style="display: none" class="edit_icon material-symbols-outlined">edit</div>
                                </div>
                            </div>
                        </div>
        `
    }

    async handleEditCareteam(e) {
        let modal = new AppPatientCareTeamModal();
        modal.patient = this.patient;
        await modal.showModal();
        let { patient, should_update } = await modal.onDidDismiss();
        this.patient = patient;
    }


    handleChange(field, event) {
        const input = event.target;
        const value = input.value;

        if (field.includes("phone")) {
            const digits = value.replace(/\D/g, '');
            const formatted = digits.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            const displayValue = !formatted[2] ? formatted[1] : `(${formatted[1]}) ${formatted[2]}-${formatted[3] ? formatted[3] : ''}`;

            // Directly update the input's value for immediate UI feedback
            input.value = displayValue;

            // Assuming you're storing the changes for eventual submission or further processing
            this.changes[field] = digits;
        } else {
            // Handle non-phone fields normally
            this.changes[field] = value;
        }

        // Since lit is used for templating, no additional steps are needed to ensure reactivity.
        // If there's any other state or UI update needed outside this direct manipulation, handle accordingly.
    }






    /**
     * 
     * @param {string} edit_group 
     * @param {KeyboardEvent} e 
     */
    /* handleKeypress(edit_group, e) {
        if (e.key == 'Escape') {
            e.preventDefault();
            e.stopImmediatePropagation();
            this[edit_group] = false;
            this.render();
        }
    
    } */

    handleKeypress(props, e) {
        const properties = props.split(',');
        if (e.key == 'Escape') {
            e.preventDefault();
            e.stopImmediatePropagation();
            properties.forEach(prop => this[prop.trim()] = false);
            this.render();
        }
    }


    async patch(patch_body, element) {
        try {
            let result = await directus.items("patient").updateOne(this.patient.id, patch_body, {
                fields: [
                    '*',
                    'current_episode_id.*',
                    'client_id.*',
                    'case_manager_id.*',
                    'primary_clinician_id.*',
                ]
            });
            this.patient = result;
        }
        catch (err) {
            console.error(err);
            element.dispatchEvent(
                new CustomEvent("edit_error", { bubbles: true, detail: err })
            );
        }
        element.dispatchEvent(
            new CustomEvent("edit_complete", { bubbles: true })
        );
    }
    async expandPatient() {
        if (
            typeof this.patient.primary_clinician_id !== "object" ||
            typeof this.patient.case_manager_id !== "object"
        ) {
            this._patient = await directus.items("patient").readOne(this._patient.id, {
                fields: [
                    '*',
                    'current_episode_id.*',
                    'client_id.*',
                    'case_manager_id.*',
                    'primary_clinician_id.*',
                ]
            });
            this.render();
        }
    }

    async handleConfirmClick() {
        try {
            if (Object.keys(this.patient_access_changes).length > 0) {
                const to_remove = [];
                const to_add = [];
                for (const id in this.patient_access_changes) {
                    const action = this.patient_access_changes[id];

                    switch (action) {
                        case "remove":
                            to_remove.push(id);
                            break;
                        case "add":
                            const access_record = this.patient_access.find((i) => i.user_id === id);
                            // It already exists, and we've staged and unstaged a remove
                            if (access_record) break;
                            to_add.push({
                                patient_id: this.patient.id,
                                user_id: id,
                            });
                            break;
                    }
                }

                if (to_remove.length) {
                    await directus.items("patient_access").deleteMany(to_remove);
                    for (const id of to_remove) {
                        const pa = this.patient_access.find((i) => i.id === id);
                        if (
                            this.patient.case_manager_id === pa?.user_id?.id ||
                            this.patient.case_manager_id?.id === pa?.user_id?.id
                        ) {
                            this.changes.case_manager_id = null;
                        }
                        if (
                            this.patient.primary_clinician_id === pa?.user_id.id ||
                            this.patient.primary_clinician_id?.id === pa?.user_id.id
                        ) {
                            this.changes.primary_clinician_id = null;
                        }
                    }
                }

                if (to_add.length)
                    await directus.items("patient_access").createMany(to_add);
            }

            // If we have any patient changes, save those.
            if (Object.keys(this.changes).length > 0) {
                if (this.changes?.primary_clinician_id?.id)
                    this.changes.primary_clinician_id = this.changes.primary_clinician_id.id;
                if (this.changes?.case_manager_id?.id)
                    this.changes.case_manager_id = this.changes.case_manager_id.id;

                const res = await directus.items("patient").updateOne(this.patient.id, this.changes, {
                    fields: [
                        '*',
                        'current_episode_id.*',
                        'client_id.*',
                    ]
                });

                this.changes = {};
            }
        } catch (err) {
            console.error(err);
            this.error_message = err.message;
            return;
        }

        // reload the patient to make sure that all
        this.patient = await Cache.getPatient(this.patient.id, true);
        this.dismiss({
            should_update: this.editable,
            patient: this.patient,
        });
    }

    async handleCareTeamAdd() {
        const id_to_add = this.querySelector("#potential-care-team-member").value;
        if (!id_to_add) return;

        this.care_team.push(this.clinicians.find((i) => i.id === id_to_add));
        this.patient_access_changes[id_to_add] = "add";
        this.hasChanges();
    }

    /**
     * Removes the care team member
     * @param member
     * @returns {Promise<void>}
     */
    async handleRemoveCareTeamMember(e, member) {
        e.stopPropagation();
        const to_remove = this.patient_access.find((i) => member.id === i.user_id?.id);
        if (!to_remove) return;
        this.patient_access_changes[to_remove.id] = "remove";
        this.care_team = this.care_team.filter((i) => i.id !== to_remove.user_id?.id);
        this.hasChanges();
    }

    async loadClinicians(force) {
        if (force !== true && this.clinicians) return;
        // only load once unless force is set to true
        try {
            const client_id = typeof this.patient.client_id === "string" ? this.patient.client_id : this.patient.client_id.id;
            const res = await directus.users
                .readByQuery({
                    filter: {
                        client_access: {
                            client_id: client_id,
                        },
                        role: {
                            name: {
                                _in: ["Clinician", "Client"],
                            }
                        }
                    },
                    fields: ["*", "role.*", "client_access.*"],
                });

            const clinicians = [];
            const case_managers = [];

            for (const item of res.data) {
                clinicians.push(item);
                case_managers.push(item);
            }

            this.clinicians = clinicians;
            this.case_managers = case_managers;

            this.render();
        } catch (err) {
            console.error(err);
            this.clinicians = [];
        }
    }

    async loadPatientCareTeam(force) {
        if (!force && this.care_team) return;
        try {
            const res = await directus.items("patient_access").readByQuery({
                filter: {
                    patient_id: this.patient.id,
                },
                fields: ["id", "user_id.*"],
            });

            const client_id = getCurrentClientId();
            this.patient_access = res.data;
            this.care_team = res.data.map((i) => i.user_id);
        } catch (err) {
            console.error(err);
            this.care_team = [];
        }
    }
    formatPhoneNumber(phoneNumber) {
        console.log("formatPhoneNumber", phoneNumber)
        if (!phoneNumber) return '';
        const digits = phoneNumber.replace(/\D/g, '');
        if (digits.length === 10) {
            return digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else {
            return phoneNumber; // Return as-is if not exactly 10 digits
        }
        console.log("formatPhoneNumber bottom", phoneNumber)
    }
}

customElements.define("app-patient-profile", AppPatientProfile);