import { format, startOfMonth, parse } from "date-fns";
import { html, render } from "lit";
import { getCurrentClientId } from "shared/lib/lib-user";
import directus from "shared/lib/lib-directus";
import { convertUtcToLocalTime, averageDate } from "shared/lib/lib-date";
import AppPatientActivityModal from "shared/components/patient/app-patient-activity-modal";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
//import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import { ar } from "date-fns/locale";

export default class ReportClientCalendar extends HTMLElement {
    static get config() {
        return {
            icon: "calendar_month",
            report: "client-calendar",
            title: "Client Calendar",
            description: "Dates patients will not be available for visits.",
            component: ReportClientCalendar
        };
    }

    constructor() {
        super();
        this.start_date = startOfMonth(new Date());
        this.calendar = null;
        this.calendar_items = [];
        this.start = null;
        this.end = null;
        this.type = "Unavailability";
        this.client_id = getCurrentClientId();
    }

    async connectedCallback() {
        this.render();
        // Delay to ensure the DOM is fully ready before initializing FullCalendar
        await new Promise(resolve => setTimeout(resolve, 0));
        this.initializeFullCalendar();

    }

    initializeFullCalendar() {
        if (this.calendar) return;
        const calendarEl = this.querySelector('#calendar');
        const windowHeight = window.innerHeight;
        const calendarTop = calendarEl.getBoundingClientRect().top;
        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        const defaultView = isMobile ? 'listMonth' : 'dayGridMonth';
        const initialCalendarHeight = windowHeight - calendarTop;
        this.calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, listPlugin, multiMonthPlugin],
            initialView: defaultView,
            listDayFormat: { weekday: 'long', month: 'short', day: 'numeric' },
            headerToolbar: {
                left: 'prev today next',
                center: '',
                right: 'multiMonthYear,dayGridMonth,listMonth'
            },
            events: this.calendar_items,
            eventClick: (info) => {
                this.showEventDetails(info.event);
            },
            dayMaxEvents: 5,
            timeZone: 'local',

            datesSet: async (info) => {
                this.start = info.start;
                this.end = info.end;
                await this.updateReport(this.start, this.end);
            },
            windowResize: (arg) => {
                const newWindowHeight = window.innerHeight;
                const newCalendarTop = calendarEl.getBoundingClientRect().top;
                const newCalendarHeight = newWindowHeight - newCalendarTop;
                this.calendar.setOption('height', newCalendarHeight);
            },
            eventContent: (arg) => { // Use an arrow function here
                const titleContainer = document.createElement('div');
                titleContainer.classList.add('custom-event-title');
                // Right part of the title for 'self' checkpoints
                const rightPart = document.createElement('div');
                rightPart.classList.add('title-right');
                // Left part of the title
                const leftPart = document.createElement('div');
                leftPart.classList.add('title-left');
                // 2nd row part of the title
                const secondPart = document.createElement('div');
                secondPart.classList.add('title-second-row');

                leftPart.textContent = arg.event.title;
                rightPart.textContent = arg.event.extendedProps.clinician;
                const formattedTime = this.calendar.formatDate(arg.event.start, {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: true
                });

                if (arg.event.extendedProps.type == "Unavailability") {
                    leftPart.textContent = arg.event.extendedProps.name;
                    rightPart.textContent = formattedTime;
                    secondPart.textContent = arg.event.extendedProps.reason;
                } else {
                    leftPart.textContent = arg.event.extendedProps.visit_type;
                    rightPart.textContent = arg.event.extendedProps.name;
                    secondPart.textContent = arg.event.extendedProps.visit_status;

                }

                titleContainer.appendChild(leftPart);
                titleContainer.appendChild(rightPart);

                if (secondPart.textContent) {
                    titleContainer.classList.add('has-second-part'); // Add class if secondPart has content
                    titleContainer.appendChild(secondPart)
                }

                return { domNodes: [titleContainer] };
            },
            eventDidMount: (info) => {
                if (this.calendar.view.type === 'dayGridMonth') {
                    const monthTitle = parse(this.calendar.view.title.split(' ')[0], 'MMMM', new Date());
                    const month = monthTitle.getMonth();
                    const monthDropdown = this.querySelector('#c-month');
                    monthDropdown.value = month;

                }
                if (this.calendar.view.type === 'listMonth') {
                    const source_record = info.event.extendedProps.source_record;
                    const type = info.event.extendedProps.type;
                    // Modify the day headers to include the new headers
                    const dayHeaders = Array.from(document.querySelectorAll('tr[data-date] th[scope="colgroup"]'));
                    dayHeaders.forEach(header => {
                        if (!header.parentElement.querySelector('.custom-day-header')) {
                            let headers;
                            if (type == "Unavailability") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Name', colspan: 2 },
                                    { class: 'custom-day-header a-reason', text: 'Reason', colspan: 1 },
                                    { class: 'custom-day-header a-doc', text: 'With/Dr. Name', colspan: 1 },
                                    { class: 'custom-day-header a-spec', text: 'Specialty', colspan: 1 },
                                    { class: 'custom-day-header a-appt', text: 'Appointment Time', colspan: 1 },
                                    { class: 'custom-day-header a-notes', text: 'Notes', colspan: 1 }
                                ];
                            } else {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Name', colspan: 2 },
                                    { class: 'custom-day-header a-desc', text: 'Description', colspan: 2 },
                                ];
                            }

                            let lastHeader = header;
                            headers.forEach(h => {
                                const newHeader = document.createElement('th');
                                newHeader.className = h.class;
                                newHeader.textContent = h.text;

                                if (h.colspan && h.colspan > 1) {
                                    newHeader.setAttribute('colspan', h.colspan);
                                }

                                header.parentElement.insertBefore(newHeader, lastHeader.nextSibling);
                                lastHeader = newHeader;
                            });
                        }
                    });

                    const redundantDayLabels = Array.from(document.querySelectorAll('.fc-list-day-side-text'));
                    redundantDayLabels.forEach(label => label.remove());
                    const dateHeaderColgroup = document.querySelector('tr[data-date] th[scope="colgroup"]');
                    if (dateHeaderColgroup) {
                        // Change the scope to just a single column
                        dateHeaderColgroup.setAttribute('scope', 'col');

                        // Remove the colspan attribute if it exists
                        if (dateHeaderColgroup.hasAttribute('colspan')) {
                            dateHeaderColgroup.removeAttribute('colspan');
                        }
                    }

                    // Modify each event row
                    //const dotCell = info.el.querySelector('.fc-list-event-graphic');
                    const titleCell = info.el.querySelector('.fc-list-event-title');

                    if (titleCell) {
                        // Here, you should handle the Dot and Name columns. Since the code isn't present for them, 
                        // I'll proceed directly to Reason

                        if (type == "Unavailability") {
                            const reasonCell = document.createElement('td');
                            if (source_record.reason === "dr_appointment") {
                                reasonCell.innerHTML = "Doctor's Appointment";
                            } else {
                                reasonCell.innerHTML = `${source_record.reason}`;
                            }
                            titleCell.parentElement.insertBefore(reasonCell, titleCell.nextSibling);

                            const doctorCell = document.createElement('td');
                            if (source_record.reason === "dr_appointment") {
                                doctorCell.innerHTML = `${source_record.dr_appt_doctor}`;
                            } else {
                                doctorCell.innerHTML = '';
                            }
                            titleCell.parentElement.insertBefore(doctorCell, reasonCell.nextSibling);

                            const specialtyCell = document.createElement('td');
                            if (source_record.reason === "dr_appointment") {
                                specialtyCell.innerHTML = `${source_record.dr_appt_specialty}`;
                            } else {
                                specialtyCell.innerHTML = '';
                            }
                            titleCell.parentElement.insertBefore(specialtyCell, doctorCell.nextSibling);

                            const timeCell = document.createElement('td');
                            if (source_record.reason === "dr_appointment") {
                                timeCell.innerHTML = format(convertUtcToLocalTime(source_record.dr_appt_datetime), "MMM do, yyyy hh:mm a");
                            } else {
                                timeCell.innerHTML = format(convertUtcToLocalTime(source_record.start_time), "MMM do, yyyy");
                            }
                            titleCell.parentElement.insertBefore(timeCell, specialtyCell.nextSibling);

                            const notesCell = document.createElement('td');
                            notesCell.innerHTML = source_record.notes ? `${source_record.notes}` : '';
                            titleCell.parentElement.insertBefore(notesCell, timeCell.nextSibling);
                        } else {
                            const descCell = document.createElement('td');
                            descCell.innerHTML = `${source_record.visit_description}`;
                            titleCell.parentElement.insertBefore(descCell, titleCell.nextSibling);
                        }
                    }
                }
            }
        });

        this.calendar.render();

        this.calendar.setOption('height', initialCalendarHeight - 70);
    }

    async updateReport() {
        if (this.type == "Unavailability") {
            const res = await directus.items('patient_availability').readByQuery({
                filter: {
                    client_id: this.client_id,
                    start_time: {
                        _gte: this.start  // Use this.start
                    },
                    end_time: {
                        _lte: this.end    // Use this.end
                    },
                    status: "published",
                },
                fields: ["*", "patient.first_name, patient.last_name"]
            })

            this.calendar_items = this.mapToCalendarItems(res.data);
        } else {
            const res = await directus.items('visit').readByQuery({
                filter: {
                    client_id: this.client_id,
                    visit_date: {
                        _gte: this.start,  // Use this.start
                    },
                    visit_date: {
                        _lte: this.end    // Use this.end
                    },
                },
                fields: ["*", "patient_id.first_name, patient_id.last_name", "careteam_member_id.first_name, careteam_member_id.last_name"]
            })

            this.calendar_items = this.mapToCalendarItems(res.data);
        }

        if (this.calendar) {
            this.calendar.removeAllEvents();
            this.calendar.addEventSource(this.calendar_items);
        }

        this.render();
    }

    render() {
        const yearsArray = Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i);

        const template = html`
        <div class="mod-base cal-wrap r-cal">
            <div class="col month-select">
                <div class="btn-group" style="align-items: center;">
                    <select id="c-month" class="form-select" style="width: auto; margin-left: 16px;" @change=${(evt) => this.handleMonthChange(evt)}>
                        ${Array.from({ length: 12 }, (_, i) => html`
                            <option value=${i} ?selected=${averageDate(this.start, this.end).getMonth() === i}>
                                ${new Date(2000, i).toLocaleString('default', { month: 'long' })}
                            </option>
                        `)}
                    </select>

                    <select class="form-select" style="width: auto;" @change=${(evt) => this.handleYearChange(evt)}>
                        ${yearsArray.map(year => html`
                            <option value=${year} ?selected=${averageDate(this.start, this.end).getFullYear() === year}>${year}</option>
                        `)}
                    </select>
                </div>
            </div>
            <div class="col cal-filters">
                <select class="form-select" @change=${(evt) => this.handleTypeChange(evt)}>
                    <option value="Unavailability">Unavailability</option>
                    <option value="Visits">Visits</option>
                </select>
            </div>
            <div id="calendar"></div>
        </div>
    `;

        render(template, this);
    }

    async showEventDetails(event) {
        const { type, source_record, name } = event.extendedProps;
        const modal = new AppPatientActivityModal(source_record, type);
        modal.extraTitle = " - " + name;
        await modal.showModal();
    }

    handleMonthChange(evt) {
        this.start_date.setMonth(evt.target.value);
        this.calendar.gotoDate(this.start_date);
        // Set the dropdown value explicitly after changing the calendar date
        evt.target.value = this.start_date.getMonth();
    }

    handleYearChange(evt) {
        this.start_date.setFullYear(evt.target.value);
        this.calendar.gotoDate(this.start_date);
        // Set the dropdown value explicitly after changing the calendar date
        evt.target.value = this.start_date.getFullYear();
    }

    handleTypeChange(e) {
        if (this.type != e.target.value) {
            this.type = e.target.value;
            this.calendar.gotoDate(averageDate(this.start, this.end));
        }
    }

    mapToCalendarItems(data) {
        return data?.map(item => {
            if (this.type == "Unavailability") {
                if (item.patient == null) {
                    return {};
                }
                let reason = "";

                if (item.reason === "dr_appointment") {
                    reason = "Doctor's Appointment";
                } else if (item.reason === "travel") {
                    reason = "Travel";
                } else if (item.reason === "dialysis") {
                    reason = "Dialysis";
                } else if (item.reason === "other") {
                    reason = `Other: ${item.reason_other}`;
                }

                const calendar_item = {
                    start: item.start_time,
                    name: `${item.patient.first_name?.[0] + '.' || ''} ${item.patient.last_name || ''}`,
                    reason,
                    source_record: item,
                    type: "Unavailability",
                    className: 'cal-avail cp-expander',
                    groupId: 'cal-avail'
                }

                return calendar_item;
            } else {
                if (item.patient_id == null) {
                    return {};
                }
                return {
                    start: convertUtcToLocalTime(item.visit_date),
                    description: item.visit_description,
                    visit_type: item.visit_type === 'SN' ? `${item.visit_type} Visit` : item.visit_type,
                    name: `${item.patient_id?.first_name?.[0] + '.' || ''} ${item.patient_id?.last_name || ''}`,
                    color: "#584e6e",
                    visit_status: item.visit_status,
                    source_record: item,
                    type: "Visits",
                    className: 'cal-visit cp-expander',
                    groupId: 'cal-visit'
                }
            }
        });
    }
}

customElements.define("report-client-calendar", ReportClientCalendar);