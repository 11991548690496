import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
import { getCurrentClientId } from "shared/lib/lib-user";

import AppSettingsMessageModal from "shared/components/settings/app-settings-message-modal";

export default class SceneSettingsSMS extends HTMLElement {
    constructor() {
        super();
    }

    get messages() {
        return this._messages || [];
    }

    set messages(value) {
        this._messages = value;
        this.render();
    }

    async loadMessages() {
        const messages_result = await directus.items("sms_templates").readByQuery({
            filter: {
                client_id: getCurrentClientId()
            },
            fields: ['status', 'category', 'id', 'client_id', 'template_name', 'template_content', 'template_type']
        })
        this.messages = messages_result.data;
        this.render();
    }

    connectedCallback() {
        this.template = () => {

            return html`
            <style>
                #message-table td {
                    text-align: left;
                    vertical-align: middle;
                }
                #message-table td:first-child {
                    padding-left: 16px;
                }
                .dot {
                    height: 10px;
                    width: 10px;
                    background-color: green;
                    border-radius: 50%;
                }
            </style>
            <div class="row">
                <div class="col">
                    <h3 style="font-size: 24px; font-weight: 600; margin-bottom: 0; margin-top: 8px;">
                        SMS Templates
                    </h3>
                </div>
                <div class="col text-end">
                    <span
                        @click=${_e => this.handleAddClick()}
                        class="material-symbols-outlined"
                        style='
                            font-size: 40px;
                            cursor: pointer;
                            margin-right: 7px;
                            color: var(--t-color-primary);
                            font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                        '
                    >
                        add_circle
                    </span>
                </div>
            </div>
           
            <div class="row" style="margin-top:10px;">
                <div class="col">
                    <table class="table" id="message-table">
                        <thead>
                            <tr>
                                <td></td>
                                <td>Name</td>
                                <td>Content</td>
                                <td>Type</td>
                            </tr>
                        </thead>
                        <tbody>
                            ${this.messages.map((message) => this.renderMessage(message))}
                        </tbody>
                    </table>
                </div>
            </div>
            `;
        }

        Object.assign(this.style, {
            display: "block",
        })

        this.render();
        this.loadMessages();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    renderMessage(message) {
        const types = {
            "checkin": "Check-In",
            "education": "Education",
            "general": "General",
            "clinician": "Clinician",
        }

        return html`
            <tr style="cursor: pointer" @click=${(e) => this.handleEdit(message)}>
                <td>
                    <div class="dot" style=${message.status == "draft" ? "background-color: gray;" : ""}></div>
                </td>
                <td>${message.template_name}</td>
                <td>${message.template_content}</td>
                <td>${types[message.template_type]}</td>
            </tr>
        `;
    }

    async handleEdit(message) {
        const modal = new AppSettingsMessageModal();
        modal.message = message;
        modal.isEdit = true;
        await modal.showModal();
        const res = await modal.onDidDismiss();
        if (res?.confirmed) {
            await this.loadMessages();
        }
    }

    async handleAddClick() {
        const modal = new AppSettingsMessageModal();
        await modal.showModal();
        const res = await modal.onDidDismiss();
        if (res?.confirmed) {
            await this.loadMessages();
        }
    }
}

customElements.define("scene-settings-sms", SceneSettingsSMS);
