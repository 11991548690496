import { eachWeekOfInterval, differenceInCalendarDays, addDays, format } from "date-fns";
import DataDefinition from "./lib-data-definition";

/**
 * Format a phone number into a nice string.
 * A nice gift from https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
 * @param phoneNumberString
 * @returns {string|null}
 */
export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
}

/**
 * Adds an ordinal suffix to the given integer.
 * @param {number} int an integer
 * @returns {string} e.g. '1st', '2nd', '3rd'
 */
export function getOrdinalNumber(int) {
    if (!int || !Number.isInteger(int)) {
        return int;
    }
    const suffixes = ["th", "st", "nd", "rd"];
    const last_two_digits = int % 100;
    return int + (suffixes[(last_two_digits - 20) % 10] || suffixes[last_two_digits] || suffixes[0]);
}

/**
 * Given an integer number of seconds, returns a MM:ss time elapsed string.
 * @param {number} seconds integer number of seconds
 * @returns {string} e.g. 00:23, 1:20, etc
 */
export function getTimeElapsedFromSec(seconds) {
    if (!seconds || !Number.isInteger(seconds)) {
        return seconds;
    }
    const date = new Date(0);
    date.setSeconds(seconds);
    return format(date, "MM:ss");
}

/**
 * Given an episode with a start_date and end_date, return the week of the episode that date falls on
 * @param {*} episode
 * @param {*} date
 * @returns
 */
export function getEpisodeWeek(episode, date) {
    let start = new Date(episode.start_date);
    let end = episode.end_date ? new Date(episode.end_date) : addDays(new Date(episode.start_date), 60);
    let _episode_weeks = eachWeekOfInterval({ start, end }, { weekStartsOn: 0 });
    for (let i = 0; i < _episode_weeks.length; i++) {
        let days = differenceInCalendarDays(_episode_weeks[i], new Date(date));
        if (days >= 0) if (days <= 7) return i;
    }
    console.warn(`Date out of range of episode. Episode start date: ${start}. Date: ${date}.`);
    return "Unknown";
}

export function getResponseText(checkpoint, field_name, field_definition) {
    let field_value = checkpoint[field_name];
    let definition = field_definition.find((definition) => definition.field === field_name);
    let choice = definition.meta.options.choices.find((option) => option.value == field_value);
    if (!choice) return field_value;
    return choice.text;
}

/**
 * @param {object} checkpoint_submission from hh_patient_reported_measures.submission
 * @param {string} field_name field key e.g. pr_fall
 * @param {Array} form_choices {label, value} options for field from Form.IO json
 * @returns {string} selected response for the given supplemental question
 */
export function getSupplementalResponseText(checkpoint_submission, question) {
    let field_name = question.field;
    let form_choices = question.choices;
    const field_value = checkpoint_submission[field_name];
    //if this isn't a multiselect field, do our best guess at formatting it
    if (!form_choices)
        return getFormattedResponseText(field_value);

    let choice;
    if (typeof field_value == 'object') {
        let fieldValArr = [];
        function getValueArray(obj, depth) {
            let text_items = [];
            for (let key of Object.keys(obj)) {
                let item_value = obj[key];
                if (typeof item_value == 'object')
                    return getValueArray(item_value, depth + 1)

                if (typeof item_value == 'boolean') {
                    if (!item_value)
                        continue;
                    text_items.push(key);
                }
            }
            return text_items;
        }

        fieldValArr = getValueArray(field_value, 0);

        if (fieldValArr.length > 0)
            choice = form_choices.find((option) => option.value == fieldValArr[0]);
        else
            return "";
    } else {
        choice = form_choices.find((option) => option.value == field_value);
    }

    if (!choice) return field_value;
    return choice.label;
}

function getFormattedResponseText(value, choices) {
    if (!value)
        return '';
    if (typeof value == 'string')
        return value;
    if (typeof value == 'object') {
        function getText(obj, depth) {
            let text_items = [];
            for (let key of Object.keys(obj)) {
                let item_value = obj[key];
                if (typeof item_value == 'object')
                    return getText(item_value, depth + 1)
                if (typeof item_value == 'boolean') {
                    if (!item_value)
                        continue;
                    if (choices) {
                        let choice = choices.find(choice => choice.value == key);
                        if (!choice) {
                            text_items.push(DataDefinition.formatFieldName(key));
                            continue;
                        }
                        text_items.push(choice.label);
                    }
                    else
                        text_items.push(DataDefinition.formatFieldName(key));
                }
                else
                    text_items.push(`${DataDefinition.formatFieldName(key)}: ${JSON.stringify(item_value)}\r\n`);
            }
            return text_items.join(', ');
        }
        return getText(value, 0);
    }
}

export function getRandomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}