import { html, LitElement, render } from "lit";

/**
 * Customizable on/fff toggle switch inspired by https://auroratide.com/posts/making-a-toggle-switch/
 */
export default class AppToggleSwitch extends HTMLElement {
    constructor() {
        super();
        this._enabled_text = null;
        this._disabled_text = null;
    }

    get enabled_text() {
        return this._enabled_text;
    }

    set enabled_text(value) {
        this._enabled_text = value;
        this.render();
    }

    get disabled_text() {
        return this._disabled_text;
    }

    set disabled_text(value) {
        this._disabled_text = value;
        this.render();
    }



    get enabled() {
        return this._enabled;
    }
    set enabled(value) {
        this._enabled = value;
        this.setAttribute("aria-enabled", this._enabled.toString());
        this.dispatchEvent(new CustomEvent("change"));
    }

    connectedCallback() {
        this.setAttribute("role", "switch");
        this.setAttribute("tabindex", "0");
        this.addEventListener("click", this.toggle);
        this.addEventListener("keyup", this.handleKeyUp);

        this.template = () => {
            return html`
                <style>
                    .track {
                        width: 100%;
                        height: 100%;
                        text-align: left;
                    }

                    .slider {
                        width: 65%;
                        height: 100%;
                        background-color: var(--t-color-grey);
                        display: inline-block;
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 14px;
                        text-align: center;
                        color: var(--t-color-white);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 12px;
                        user-select: none;
                        padding-left: 5px;
                        padding-right: 5px;
                        white-space: nowrap;
                    }

                    .track,
                    .slider {
                        transition: all 256ms;
                    }

                    .slider.enabled {
                        transform: translateX(55%);
                        background-color: ${this._is_clinician_app ? "var(--t-color-primary)" : "var(--t-color-sidebar)"};
                    }
                </style>

                <span class="track">
                    <span class="slider${this.enabled ? " enabled" : ""}">
                        
                        ${this.enabled ? this.enabled_text : this.disabled_text}
                    </span>
                </span>
            `;
        };

        Object.assign(this.style, {
            display: "inline-block",
            cursor: "pointer",
            borderRadius: "12px",
            border: "2px solid var(--t-color-white)",
            padding: "1px",
            height: "30px",
            width: "113px",
        });
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);

    }

    handleKeyUp(e) {
        if (e.code === "Space") this.toggle();
    }

    /**
     * Inverts the switch toggle state
     */
    toggle() {
        this.enabled = !this.enabled;
        this.dispatchEvent(new CustomEvent("toggle", { detail: this.enabled }));
        this.render();
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.toggle);
    }
}

customElements.define("app-toggle-switch", AppToggleSwitch);
