import { html, render } from "lit";
import * as bootstrap from "bootstrap";

/**
 * @param {AvoidableEventsMetrics} metrics flat object with all impact metrics
 * A widget displaying metrics around visit conversion loss.
 */
export default class AppAvoidableEventsConversionLoss extends HTMLElement {
    constructor() {
        super();
        this.metrics = {};
    }

    set metrics(value) {
        this._metrics = value || {};
        this.render();
    }
    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #avoidable-events-conversions h6 {
                        margin: unset;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 26px;
                        color: var(--t-color-dark);
                    }
                    #avoidable-events-conversions .widget-row {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    #avoidable-events-conversions .widget-metric {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                        width: 126px;
                        height: 84px;
                        position: relative;
                    }
                    #avoidable-events-conversions .widget-metric__value {
                        font-weight: 700;
                        line-height: 56px;
                        font-size: 40px;
                        color: var(--t-color-dark);
                        letter-spacing: 0.5px;
                    }
                    #avoidable-events-conversions .widget-metric__label {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: var(--t-color-grey);
                        text-align: center;
                        white-space: pre-line;
                    }
                </style>
                <div 
                    id="avoidable-events-conversions" 
                    style="
                        height: 100%; 
                        display: flex; 
                        flex-direction: column;
                    "
                >
                    <h6 style="margin: 0 -8px; white-space: pre-line;">Total Time &\nVisit Conversion Loss</h6>
                    <div style="margin-top: 8px;">
                        <div class="widget-row">
                            ${this.renderMetric("Lost Staff Time", "Minutes", "total_lost_staff_time", "Sum of Lost Staff Time from Missed Visits w/ Travel, Missed Visits w/o Travel, Readmissions, and Excessive Visit Utilization")}
                        </div>
                        <div class="widget-row">
                            ${this.renderMetric("Lost Time Converted to Visits", "Visits", "visit_conversion_lost_staff_time", "Total Lost Staff Time<hr />Avg Time Spent Per Visit")}
                        </div>
                        <div class="widget-row">
                            ${this.renderMetric("Visits to New/Additional Patient Conversion", "Patients", "visits_new_patient_conversion", "Lost Time Converted to Visits<hr />Avg Visits per Patient", true)}
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
            paddingBottom: "12px",
        });

        this.render();
        [...this.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((el) => new bootstrap.Tooltip(el));
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    renderMetric(label, units, metric_key, formula_text) {
        return html`
            <span class="widget-metric__label" style="width: 164px;">
                ${label}
            </span>
            <div 
                class="widget-metric"
                title=${formula_text}
                data-bs-html="true"
                data-bs-toggle=${formula_text ? "tooltip" : ""}
                data-bs-placement="right"
                data-bs-custom-class="widget-formula-tooltip"
            >
                <span class="widget-metric__value" >
                    ${(Math.round(this.metrics[metric_key]) || 0).toLocaleString()}
                </span>
                <span class="widget-metric__label">${units}</span>
            </div>
        `
    }

}

customElements.define("app-avoidable-events-conversion-loss", AppAvoidableEventsConversionLoss);
