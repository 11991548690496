export default class AppFilterUtil {

    /**
     * Find the directus field instance for the given path
     * @param {import("../app-filter").CollectionConfig} config 
     * @param {string} path 
     * @returns 
     */
    static findField(config, path) {
        let parts = path.split(".");
        let root = config;
        let field;
        for (let part of parts) {
            field = root.fields.find(search_field => search_field.field == part);
            if (!field)
                return
            if (field.related_collection) {
                root = field.related_collection;
                continue;
            }
        }
        return field;
    }

    /**
     * Get the display label for a field path
     * @param {import("../app-filter").CollectionConfig} config 
     * @param {string} path - The name or dotted path for the field
     * @returns {String}
     */
    static getDisplayField(config, path) {
        if (!path)
            return;
        let display = "";
        if (!path.includes(".")) {
            const field = this.findField(config, path);
            return this.getTranslationOrLabel(field);
        }

        let parts = path.split(".");
        let i;
        for (i = 0; i < parts.length - 1; i++) {
            let field = this.findField(config, parts.slice(0, i + 1).join("."));
            display += this.getTranslationOrLabel(field) + ".";
        }
        i++;
        display += this.getTranslationOrLabel(
            this.findField(config, parts.slice(0, i).join("."))
        );
        return display;
    }

    /**
     * Recursively fetch all paths to columns
     * @param {import("../app-filter").CollectionConfig} config 
     * @param {string} path
     * @param {import("../app-filter").FieldConfig[]} fields 
     */
    static getVisibleColumnPaths(path, fields) {
        let paths = [];
        if (!fields)
            return [];
        for (let field of fields) {
            if (field.meta?.hidden)
                continue;
            let field_path;
            if (path)
                field_path = path + "." + field.field;
            else
                field_path = field.field;

            paths.push(field_path);
            if (field.related_collection && field.related_collection.depth > 0)
                paths = paths.concat(this.getVisibleColumnPaths(field_path, field.related_collection.fields));
        }
        return paths;
    }

    /**
     * Convert internal filter_state to a directus query
     * @param {import("../app-filter").AppFilterConfig} config 
     * @param {import("../app-filter").FilterState} filter_state 
     */
    static getQuery(config, filter_state) {
        if (!filter_state)
            filter_state = { group: {}, user_search: '' };
        let query = {};
        let filter = AppFilterUtil.getFilters(filter_state.group);
        if (filter_state.user_search) {
            let search_fields = config.search_fields;
            //if search fields are configured,, use them
            if (search_fields?.length) {
                let search_query = AppFilterUtil.getSearchQuery(config, filter_state);
                if (filter)
                    filter._and.push(search_query);
                else
                    filter = search_query;
            }
            //if search fields aren't configured, search the 'top' level fields
            else {
                query.search = filter_state.user_search;
            }
        }
        if (filter_state.sort)
            query.sort = AppFilterUtil.getSortQuery(filter_state.sort)

        if (filter_state.aggregate?.length) {
            query.aggregate = AppFilterUtil.getAggregateQuery(filter_state.aggregate);
        }

        if (filter_state.aggregate_group) {
            query.groupBy = AppFilterUtil.getAggretateGroupQuery(filter_state.aggregate_group);
        }

        query.filter = filter;
        return query;

    }

    static getFilters(group) {
        try {
            let filters = [];
            if (!group)
                return { _and: [] };
            if ((!group.filters?.length) && (!group.groups?.length))
                return { _and: [] };

            if (group.filters) {
                for (let filter_item of group.filters) {
                    if (filter_item && filter_item.field) {
                        if (filter_item.field.includes(".")) {
                            let parts = filter_item.field.split(".");
                            let root = {};
                            let current = root;
                            for (let i = 0; i <= parts.length; i++) {
                                if (i == parts.length) {
                                    current[filter_item.op] = filter_item.value;
                                    break;
                                }
                                let part = parts[i];
                                let next = {};
                                current[part] = next;
                                current = next;
                            }
                            filters.push(root);
                        }
                        else {
                            filters.push({
                                [filter_item.field]: {
                                    [filter_item.op]: filter_item.value
                                }
                            });
                        }
                    }
                }
            }

            if (group && Array.isArray(group.groups)) {
                for (let filter_group of group.groups) {
                    filters.push(AppFilterUtil.getFilters(filter_group));
                }
            }
            let result = {
                [group.type]: filters
            };
            return result;
        } catch (error) {
            console.error('Get Filters Error:', error);
            return {
                _and: []
            };
        }
    }

    /**
     * Get a directus-compatible search query based on the configured search fields
     * @param {import("../app-filter").AppFilterConfig} config 
     * @param {import("../app-filter").FilterState} filter_state
     * @returns 
     */
    static getSearchQuery(config, filter_state) {
        let filters = [];
        let search_fields = config.search_fields;
        for (let field of search_fields) {
            if (field.includes(".")) {
                let parts = field.split(".");
                let root = {};
                let current = root;
                for (let i = 0; i <= parts.length; i++) {
                    if (i == parts.length) {
                        current._icontains = filter_state.user_search;
                        break;
                    }
                    let part = parts[i];
                    let next = {};
                    current[part] = next;
                    current = next;
                }
                filters.push(root);
            }
            else
                filters.push({
                    [field]: {
                        _icontains: filter_state.user_search
                    }
                })
        }
        return {
            _or: filters
        }
    }

    /**
     * Return a directus compatible sort query given an array of field columns
     * @param {import("../app-filter-sort").SortColumn[]} sort 
     * @returns 
     */
    static getSortQuery(sort) {
        let query = [];

        for (let column of sort) {
            query.push(`${column.direction}${column.name}`);
        }
        return query;
    }
    /**
     * Return a directus compatible aggregate query
     * @param {import("../app-filter-aggregate").AggregateColumn} aggregate 
     */
    static getAggregateQuery(aggregate) {
        if (!aggregate)
            return;
        if (!aggregate.length)
            return;
        let query = {
            [aggregate[0].aggregate_function]: aggregate[0].name || "*"
        };
        return query;
    }

    /**
     * Return a directus compatible group-by query
     * @param {import("../app-filter-aggregate-group").AggregateGroupColumn[]} aggregate_group
     */
    static getAggretateGroupQuery(aggregate_group) {
        let query = [];

        for (let column of aggregate_group) {
            query.push(column.name);
        }
        return query;
    }

    /**
     * Get a value based on a dotted path from a nested directus row object
     * @param {string} path 
     * @param {object} row 
     */
    static getValue(path, row) {
        if (typeof path !== 'string' || !path) {
            console.error('Invalid path:', path);
            return null;
        }

        if (!path.includes(".")) {
            return row[path];
        }

        let parts = path.split(".");
        let value = row;
        for (let part of parts) {
            // Check if the value is null or the part is not a property of the value
            if (value == null || !Object.hasOwnProperty.call(value, part)) {
                console.warn('Missing data for path:', path, 'at part:', part);
                return null;
            }
            value = value[part];
        }
        return value;
    }




    /**
     * Format a value according to the datatype
     * @param {import("../app-filter").CollectionConfig} collection_config 
     * @param {string} path 
     * @param {any} value 
     */
    static formatValue(collection_config, path, value) {
        if (typeof value == "undefined")
            return '';
        if (value == null)
            return '';

        let field = AppFilterUtil.findField(collection_config, path);

        //if this is a lookup, return the lookup value
        if (field.meta?.options?.choices?.length) {
            let choice = field.meta.options.choices.find(
                choice => choice.value == value
            );
            if (choice)
                return choice.text
        }

        switch (field.type) {
            case "string":
            case "text":
                return value;
            case "boolean":
                return value.toString();
            case "integer":
            case "bigInteger":
            case "float":
            case "decimal":
                return new Intl.NumberFormat().format(value);
            case "geometry":
                return value.toString();
            case "timestamp":
            case "dateTime":
            case "date":
                return new Intl.DateTimeFormat('en-US', { dateStyle: "short" }).format(new Date(value));
            case "time":
                return new Intl.DateTimeFormat('en-US', { timeStyle: "short" }).format(new Date(value));
            case "uuid":
                return value;
                break;
        }

    }

    static getTranslationOrLabel(field, locale) {
        if (!field) return "";
        if (!locale) locale = navigator.language;
        if (field?.meta?.translations && Array.isArray(field.meta.translations)) {
            const translation = field.meta.translations.find((i) => i.language === locale);
            if (!translation) return field.label;
            else return translation.translation;
        } else {
            return field.label;
        }
    }
}