import { html, render } from "lit";
import { ref, createRef } from "lit/directives/ref.js";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"

import directus from "shared/lib/lib-directus";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import { getCurrentClientId } from "shared/lib/lib-user";

import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";

window.luxon = { DateTime };

export default class ReportClinicianCalls extends HTMLElement {
    static get config() {
        return {
            icon: "local_hospital",
            report: "clinician-calls",
            title: "Clinician Calls",
            description: "Calls made by clinicians within the last 7 days.",
            component: ReportClinicianCalls
        };
    }

    constructor() {
        super();
        this.data = [];
        this.data_table = null;
        this._loading_data = true;

        this.end_date = new Date();
        this.end_date.setHours(23, 59, 59, 999);
        this.start_date = new Date(this.end_date);
        this.start_date.setDate(this.start_date.getDate() - 7);

        this._clinicians = [];

        this.header_ref = createRef();
        this.content_ref = createRef();
    }

    get filters_expanded() {
        return this._filters_expanded;
    }

    set filters_expanded(value) {
        this._filters_expanded = value;
        this.render();
    }

    get clinicians() {
        return this._clinicians;
    }

    set clinicians(values) {
        this._clinicians = values;
        this.render();
    }

    get clinician_ids() {
        return this._clinician_ids;
    }

    set clinician_ids(value) {
        this._clinician_ids = value;
        this.fetchClinicianCalls();
    }

    connectedCallback() {
        // This alows showing/hiding the filter dropdown through css and avoid multiple filter instances
        // const filters_breakpoint_query = window.matchMedia("(min-width: 1275px)");
        // this.filters_expanded = filters_breakpoint_query.matches
        // filters_breakpoint_query.addEventListener("change", (evt) => {
        //     this.filters_expanded = evt.matches
        //     if (!evt.matches) {
        //         this.initFilterDropdown();
        //     }
        // });

        this.template = () => {
            return html`
                <style>
                    report-clinician-calls #patient-hospitalization-table .report-note:not(:first-child) {
                        margin-top: 12px;
                    }
                    report-clinician-calls #main-content .fixed-table-border, report-patient-hospitalization #main-content .fixed-table-container {
                        border: none;
                    }
                    report-clinician-calls thead {
                        /* bootstrap-table dynamically adds inline styles for this */
                        display: none !important;
                    }
                    report-clinician-calls .filter-dropdown-toggle {
                        display: flex;
                    }
                    report-clinician-calls .filter-dropdown-menu {
                        padding: 8px 16px 24px 16px;
                    }
                    @media (min-width: 600px) {
                        report-clinician-calls thead {
                            display: table-header-group !important;
                        }
                    }
                    @media (min-width: 1275px) {
                        report-clinician-calls app-report-filter {
                            margin-left: 16px;
                        }
                        report-clinician-calls .report-filters {
                            justify-content: space-around;
                        }
                        report-clinician-calls .filter-dropdown-toggle {
                            display: none;
                        }
                        report-clinician-calls .filter-dropdown-menu {
                            padding: unset;
                            display: flex;
                        } 
                    }
                </style>
                <div id="main-content" style="
                    margin: 0 32px;
                    box-shadow: var(--t-box-shadow);
                    padding: 16px;
                    border-radius: 8px;
                    background-color: white;
                ">
                    <div ${ref(this.content_ref)}>
                        <div ${ref(this.header_ref)} style="padding-bottom: 16px;">
                            <div id="widget_wrapper">
                                <div id="patient-search-wrapper" style="width: 400px">
                                    <label class="form-label"><i>Patient first or last name</i></label>
                                    <input
                                        type="text"
                                        name="patient_search"
                                        class="form-control"
                                        placeholder="Search patient first or last name"
                                        @input=${(e) => this.handlePatientSearchFilter(e)}
                                    />
                                </div>
                                <div class="button-list">
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Clinician"}
                                            .value=${this.search_term}
                                            .options=${this.clinicians || []}
                                            @optionclick=${(e) => this.handleClinicianFilter(e)}
                                            @clear=${_e => this.handleClinicianFilterClear()}
                                        ></app-report-filter>
                                    </div>
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .is_datepicker=${true}
                                            .required=${true}
                                            .title=${"Date Range"}
                                            .value=${`${getISODateStringWithoutTime(this.start_date)},${getISODateStringWithoutTime(this.end_date)}`}
                                            @dateselect=${e => this.handleDateRangeChange(e)}
                                        >    
                                        </app-report-filter>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div 
                            id="clinician-calls-table"
                            data-toggle="table"
                        ></div>
                    </div>
                </div>
            `
        }

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Initialize the event handlers, fetch required data, and initialize the data table.
     */
    async init() {
        await this.fetchClinicianCalls();
        await this.fetchClinicians();
        this.initTable();
        this.setTableHeight();
    }

    /**
     * Query directus for the client and clinician role ids and cache them.
     * @returns {string[]}
     */
    async getRoleIDs() {
        if (this._role_ids) return this._role_ids;
        const role_res = await directus.items("directus_roles").readByQuery({
            filter: {
                name: {
                    _in: ["Client", "Clinician"],
                }
            },
            fields: ["id"],
        });

        const ids = role_res.data.map((i) => i.id);
        this._role_ids = ids;
        return ids;
    }

    /**
     * Fetch the clinician calls.
     * 
     * Optional class based filters:
     *  - start_date {Date} & end_date {Date}: establish the date range of the report
     *  - clinician_ids {string[]}: an array of clinician identifier UUIDs
     *  - patient_search {string}: text that will be matched to patient first name or patient last name.
     * 
     * If the data-table has been built, it updates the data contained within.
     */
    async fetchClinicianCalls() {
        let filter = {
            client_id: {
                _eq: getCurrentClientId(),
            },
            user_created: {
                role: {
                    _in: await this.getRoleIDs(),
                }
            },
            client_id: {
                _eq: getCurrentClientId(),
            },
            status: {
                _eq: "complete",
            },
            communication_type: {
                _eq: "phone_voice",
            },
            date_created: {
                _between: [this.start_date, this.end_date],
            }
        };

        if (this.clinician_ids) {
            filter.user_created = {
                _in: this.clinician_ids,
            };
        }

        if (this.patient_search) {
            filter._or = [{
                patient_id: {
                    first_name: {
                        _contains: this.patient_search,
                    }
                },
            }, {
                patient_id: {
                    last_name: {
                        _contains: this.patient_search,
                    }
                }
            }];
        }

        const res = await directus.items("patient_communication").readByQuery({
            filter,
            fields: [
                "*",
                "user_created.*",
                "patient_id.*",
            ],
            sort: ["-date_created"],
        });

        // Tabulator Table
        this.data = this.formatRecords(res.data);
        this.data_table?.setData(this.data);
    }

    /**
     * Format records to display tabulator table.
     * @param {*} records 
     * @returns 
     */
    formatRecords(records) {
        //console.log("formatRecords" + JSON.stringify(records));

        return records
            .filter(record => record.current_episode_id !== null && record.last_task_id !== null)
            .map(record => ({
                patient_id: record.patient_id,
                survey_id: record.survey_id,
                user_created: (record.user_created?.first_name || "") + (record.user_created?.last_name || ""),
                patient_id: (record.patient_id?.first_name || "") + (record.patient_id?.last_name || ""),
                date_created: record.date_created,
                call_duration: this.formatCallDuration(record.call_duration)
            }));
    }

    formatCallDuration(value) {
        if (!value) return "no duration";
        if (value < 90) return value + " seconds";
        else return Math.round(value / 60) + " minutes";
    }

    /**
     * Fetches a list of clinicians, used by the "Clinicians" multi-select filter.
     */
    async fetchClinicians() {
        const res = await directus.users.readByQuery({
            filter: {
                role: {
                    _in: await this.getRoleIDs(),
                }
            },
            fields: ["first_name", "last_name", "id"],
        });

        const clinicians = [] // { label: "All", value: "" }];
        for (const clinician of res.data) {
            clinicians.push({
                label: `${clinician.first_name} ${clinician.last_name}`,
                value: clinician.id,
            });
        }

        this.clinicians = clinicians;
    }

    /**
     * Handles a change in the date range, triggered by the "Date Range" date picker.
     * @param {CustomEvent} e The custom event contianing the date range
     */
    handleDateRangeChange(e) {
        const { start, end } = e.detail;
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        this.start_date = start;
        this.end_date = end;
        this.fetchClinicianCalls();
    }

    /**
     * Handles a change in the clinicians selection, triggered by the "Clinician" multi-select.
     * @param {CustomEvent} e The custom event containing the list of clinician IDs to match against.
     */
    handleClinicianFilter(e) {
        this.clinician_ids = e.detail || null;
        this.fetchClinicianCalls();
    }

    handleClinicianFilterClear() {
        this.clinician_ids = null;
        this.fetchClinicianCalls();
    }

    /**
     * Handles a change in the patient search box, debounced by 750ms.
     * @param {Event} e The input event for the patient search box
     */
    handlePatientSearchFilter(e) {
        clearTimeout(this._patient_search_debounce);
        this._patient_search_debounce = setTimeout(() => {
            this.patient_search = e.target.value;
            this.fetchClinicianCalls();
        }, 750);
    }

    /**
     * Initializes an instance of BootstrapTable with the specified options. 
     */
    initTable() {
        //console.log("initTable" + JSON.stringify(this.filters));
        this.data_table = new Tabulator("#clinician-calls-table", {
            data: this.data,           //load row data from array
            history: true,             //allow undo and redo actions on the table
            pagination: "local",       //paginate the data
            paginationSize: 10,         //allow 7 rows per page of data
            paginationCounter: "rows", //display count of paginated rows in footer
            layout: "fitDataStretch",     //fit columns to width of table (optional)
            movableColumns: true,      //allow column order to be changed
            initialSort: [             //set the initial sort order of the data
                { column: "date_created", dir: "desc" },
            ],
            columnDefaults: {
                tooltip: true,         //show tool tips on cells
            },
            columns: [
                { title: "Clinician", field: "user_created" },
                { title: "Patient", field: "patient_id" },
                { title: "Call Date", field: "date_created", formatter: this.dateFormatter },
                { title: "Duration", field: "call_duration" },
            ]
        });
    }

    dateFormatter(cell) {
        const time = cell.getValue();

        if (time && time != "")
            return DateTime.fromISO(time).toFormat('MM/dd/yyyy');
        return "";
    }

    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }
}

customElements.define("report-clinician-calls", ReportClinicianCalls);
