import { html, render } from 'lit';
import { map } from 'lit/directives/map.js';
import ApplicationState from 'applicationstate';
import { Dropdown } from 'bootstrap';
import { AGGREGATE_FUNCTIONS, TYPE_FUNCTIONS } from './app-filter';
import AppFilterUtil from './util/app-filter-util';


/**
 * @typedef {Object} AggregateColumn
 * @property {string} name - The field, if any, that will be aggregated. Blank implies "*"
 * @property {string} aggregate_function - The aggregate function
 * @property {import('./app-filter').FieldConfig} field_config - The field configuration object
 */
class AppFilterAggregate extends HTMLElement {
    set collection_config(value) {
        this._collection_config = value;
    }

    /** @type {import('./app-filter').CollectionConfig} */
    get collection_config() {
        return this._collection_config;
    }

    set aggregate(value) {
        this._aggregate = value;
        this.render();
    }

    /**
     * @type {AggregateColumn[]}
     */
    get aggregate() {
        return this._aggregate;
    }

    set aggregate_query(value) {
        this.parseAggregateQuery(value);
        this.render();
    }

    get aggregate_query() {
        return this.getAggregateQuery();
    }

    set max_aggregates(value) {
        this._max_aggregates = value;
    }

    get max_aggregates() {
        return this._max_aggregates || 1;
    }

    constructor() {
        super();
        this._aggregate = [];
    }

    connectedCallback() {
        this.template = () => html`
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
        ">
            ${map(this.aggregate,
            /**
             * 
             * @param {AggregateColumn} column 
             * @param {number} index 
             * @returns 
             */
            (column, index) => html`
                    <div 
                        class="rounded-pill"
                        style="
                            margin-right: 5px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            cursor: pointer;
                            font-size: 12px;
                            width: fit-content;
                            padding-top: 3px;
                            padding-bottom: 3px;
                            padding-left: 5px;
                            padding-right: 5px;
                            border: 1px solid var(--t-color-grey);
                        "
                    >
                        <div class="dropdown">
                            <button style="font-size: 12px; padding: 0px; margin-right: 5px;" class="btn dropdown-toggle app-filter-item-field-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            ${AGGREGATE_FUNCTIONS[column.aggregate_function].label}
                            </button>
                            <ul style="font-size: 12px;" class="dropdown-menu">
                                ${TYPE_FUNCTIONS[column.field_config.type].map(
                (function_name) => html`
                                    <li>
                                        <a
                                            @click=${e => {
                        e.preventDefault();
                        e.stopImmediatePropagation();

                        column.aggregate_function = function_name;

                        let elements = this.querySelectorAll('.dropdown-toggle');
                        let element = elements[elements.length - 1];
                        let dropdown = new Dropdown(element);
                        element = document.querySelector('.app-filters-search');
                        dropdown.hide();
                        element.focus();

                        this.render();
                        this.dispatchEvent(new CustomEvent('item-change', { bubbles: true }))
                    }}
                                            class="dropdown-item" 
                                            href="#">${AGGREGATE_FUNCTIONS[function_name].label}</a>
                                    </li>
                                    `
            )}
                            </ul>
                        </div>
                        of 
                        <span style="margin-left: 5px; font-weight: 600;">
                            ${column.field_config.label}
                        </span>
                        <span
                            @click=${(e) => {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    this.handleDeleteColumn(column);
                }}
                            class="material-symbols-outlined"
                            style='
                                font-size: 14px;
                                margin-left: 5px;
                                color: var(--t-color-grey);
                                font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                            '>cancel</span>
                    </div>
                `)}
            ${this.aggregate?.length < this.max_aggregates ?
                html`
            <div class="dropdown">
                <button style="font-size: 12px; padding: 5px;" class="btn dropdown-toggle app-filter-item-field-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    + Add
                </button>
                <ul style="font-size: 12px;" class="dropdown-menu">
                    ${this.collection_config.fields.map(
                    (field) => html`
                        <li>
                            <a
                                @click=${e => this.handleAddColumn(e, field)}
                                class="dropdown-item" 
                                href="#">${field.label}</a>
                        </li>
                        `
                )}
                </ul>
            </div>
            `: ''}
        </div>
        `;
        this.init();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    async init() {

        this.render();
    }

    /**
     * Add a column to the aggregate list
     * @param {Event} e 
     * @param {import('./app-filter').FieldConfig} column 
     */
    handleAddColumn(e, column) {
        e.preventDefault();
        e.stopImmediatePropagation();

        let elements = this.querySelectorAll('.dropdown-toggle');
        let element = elements[elements.length - 1];
        let dropdown = new Dropdown(element);
        dropdown.hide();

        if (!this.aggregate)
            this.aggregate = [];
        this.aggregate.push(
            {
                name: column.field,
                aggregate_function: 'count',
                field_config: column
            }
        );

        this.render();
        element.focus();
        this.dispatchEvent(new CustomEvent('item-change', { bubbles: true, composed: true }));
    }

    /**
     * Handle removing a column
     * @param {AggregateColumn} column - The column to remove
     */
    handleDeleteColumn(column) {
        this.aggregate.splice(
            this.aggregate.findIndex(
                aggregate_column => aggregate_column.name == column.name
            ), 1
        );
        this.render();
        this.dispatchEvent(new CustomEvent('item-change', { bubbles: true, composed: true }));
    }

    /**
     * Convert directus syntax to internal state
     * @param {Object} value 
     */
    parseAggregateQuery(value) {

    }

    /**
     * @param {AggregateColumn[]} aggregate 
     */
    getAggregateQuery(aggregate) {
        if (!aggregate)
            aggregate = this.aggregate;

        return AppFilterUtil.getAggregateQuery(aggregate);
    }
}

customElements.define('app-filter-aggregate', AppFilterAggregate)
export default AppFilterAggregate;
