import * as uuid from "uuid";
import directus from "./lib-directus";

class directusWebsocket {
    constructor() {
        this._connected = false;
        this.ws = null;
    }

    async connect() {
        const token = await directus.auth.token;
        const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
        const url = `${protocol}//${window.location.hostname}${window.location.port && ":8055"}/websocket?access_token=${token}`;
        this.ws = new WebSocket(url);
        this.ws.addEventListener("open", (event) => {
            console.log("WS Connection open");
            this._connected = true;
        });
        this.ws.addEventListener("close", (event) => {
            console.log("WS Connection closed");
            this._connected = false;
        });
        this.ws.addEventListener("error", (event) => {
        console.error("WS Connection error");
        });
        this.ws.addEventListener("message", (event) => {
            console.log( "Received WS message: " + JSON.stringify(JSON.parse(event.data), null, 2));
            // TODO: handle subscriptions and responses here
        });
    }

    sendMessage(msg) {
        if (this.ws && this._connected) {
            // can be used to verify response is from same message sent
            msg.uid = uuid.v4();
            console.log('Sending WS message: '+JSON.stringify(msg, null, 2));
            this.ws.send(JSON.stringify(msg));
        }
    }

    /**
     * @param {string} collection directus collection name
     * @param {object|object[]} query directus query - follows the options of ItemService.readByQuery.
     */
    get(collection, query = {}) {
        const message = {
            type: "get",
            collection,
            query,
        }
        return this.sendMessage(message);
    }

    /**
     * @param {string} collection directus collection name
     * @param {object|object[]} data either a single item or an array of items. Follows 
     *   the options of ItemService.createOne or ItemService.createMany
     */
    post(collection, data) {
        const message = {
            type: "post",
            collection,
            data,
        };
        return this.sendMessage(message);
    }

    /**
     * @param {string} collection directus collection name
     * @param {string|string[]} uuids id or ids to update
     * @param {object|object[]} data either a single item or an array of items. Follows 
     *   the options of ItemService.updateOne or ItemService.updateMany
     */
    patch(collection, uuids, data) {
        const message = {
            type: "patch",
            collection,
            data,
        }
        if (typeof uuids === "string") {
            message.id = uuids;
        }
        else message.ids = uuids;
        return this.sendMessage(message);
    }

    /**
     * @param {string} collection directus collection name
     * @param {string|string[]} uuids id or ids to delete
     */
    delete(collection, uuids) {
        const message = {
            type: "delete",
            collection,
        }
        if (typeof uuids === "string") {
            message.id = uuids;
        }
        else message.ids = uuids;
        return this.sendMessage(message);
    }

    /**
     * 
     * @param {string} collection directus collection name
     * @param {string|string[]} uuids (optional) id or ids to subscribe
     * @param {object} query directus query - follows the options of ItemService.readMany
     * @TODO audit security (PHI) before un-commenting
     */
    // subscribe(collection, uuids, query = {}) {
    //     const message = {
    //         type: "subscribe",
    //         collection,
    //         query
    //     };
    //     if (uuids) {
    //         if (typeof uuids === "string") {
    //             message.id = uuids;
    //         }
    //         else message.ids = uuids;
    //     }
    //     return this.sendMessage(message);
    // }
}

export default new directusWebsocket();
