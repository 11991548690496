import { html, render } from "lit";
import { format } from "date-fns";
import ApplicationState from "applicationstate";
import "../app-button"
import "./app-patient-actions"
import "./app-patient-memo";
import cache from "../../lib/lib-cache";
import { parseISODateWithoutTime } from "../../lib/lib-date";

class ComponentPatientHeader extends HTMLElement {
    set patient(value) {
        if (value) {
            // Assuming `primary_phone` and `secondary_phone` are the fields you want formatted
            value.primary_phone = this.formatPhoneNumber(value.primary_phone);
            value.secondary_phone = this.formatPhoneNumber(value.secondary_phone);
        }
        this._patient = value;
        this.loadClient();
    }

    get patient() {
        return this._patient;
    }

    set page(value) {
        this._page = value;
        this.render();
    }

    get page() {
        return this._page;
    }

    set client(value) {
        this._client = value;
    }

    get client() {
        return this._client;
    }

    get links() {
        const links = [
            { page: "activity", url: `patients/${this.patient.id}/activity`, title: "Calendar" },
            { page: "checkpoints", url: `patients/${this.patient.id}/checkpoints`, title: "Checkpoints" },
            { page: "alerts", url: `patients/${this.patient.id}/alerts`, title: "Alerts" },
            //{ page: "notes", url: `patients/${this.patient.id}/notes`, title: "Notes" },
            // { page: "availability", url: `patients/${this.patient.id}/client-calendar`, title: "Availability" },
            //{ page: "communication", url: `patients/${this.patient.id}/communication`, title: "Communication" },

            //{ page: "trends", url: `patients/${this.patient.id}/trends`, title: "Trends" },
            //{ page: "oasis", url: `patients/${this.patient.id}/oasis`, title: "Oasis" },
            //{ page: "files", url: `patients/${this.patient.id}/files`, title: "Summaries" },
        ]

        if (this._app_name === "staff") {
            links.push({ page: "tasks", url: `patients/${this.patient.id}/tasks`, title: "Tasks" });
        }
        return links;
    }

    constructor() {
        super();
        this._app_name = ApplicationState.get('app.name');
        this.link_styles = {
            display: "flex",
            alignItems: "center",
            marginLeft: "24px",
            marginRight: "15px",
            height: "46px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            textDecoration: "none",
            color: "#131313",
        };
        this.selected_link_styles = Object.assign({}, this.link_styles, {
            borderBottom: "2px solid #201B3A",
            fontWeight: "700",
            color: "#201B3A",
        });
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    @media (max-width: 768px) {
                        #patient-header #links_full {
                            display: none;
                        }

                        #patient-header #links_mobile {
                            display: flex;
                        }
                    }
                    @media (min-width: 768px) {
                        #patient-header #links_full {
                            display: flex;
                        }

                        #patient-header #links_mobile {
                            display: none;
                        }
                    }
                </style>
                <div class="mod-base" id="patient-header">
                    <div class="patient-header-inner">
                        <div class="row">
                            <div class="col-md">
                                <app-patient-actions
                                    .patient=${this.patient}
                                    @patientupdate=${e => {
                    this.patient = e.detail;
                }}
                                ></app-patient-actions>
                                <div class="p-prn no-mobile">MRN: ${this.patient.mrn}</div>
                                ${this._app_name === "staff" ? html`
                                <div style="font-size: 1.1rem; font-weight: normal;">
                                <strong>${this.patient.client_id.name}</strong>
                                    
                                    ${this.patient.case_manager_id?.id ? " - " + this.patient.case_manager_id.first_name + " " + this.patient.case_manager_id.last_name : ""}
                                </div>
                                ` : ""}
                                <div class="p-dob no-mobile">
                                    DOB: ${format(parseISODateWithoutTime(this.patient.birth_date), "MM/dd/yyyy")}
                                </div>
                            </div>
                            <div class="col-md text-md-end">
                                <div class="p-phone no-mobile">
                                    ${this.patient.secondary_phone ? "Primary: " : ""}
                                    <a @click=${e => this.handlePhoneClick(e)} href="tel: ${this.patient.primary_phone}">
                                        <span class="material-symbols-outlined" style='
                                            font-size: 1rem;
                                            margin-right: 7px;
                                            color: var(--t-color-primary);
                                            font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                        '>phone</span>
                                        <span>${this.patient.primary_phone}</span>
                                    </a>
                                </div>
                                ${this.patient.secondary_phone ? html`
                                <div class="p-phone no-mobile">
                                    Alt: 
                                    <a href="tel: ${this.patient.secondary_phone}">
                                        <span class="material-symbols-outlined" style='
                                            font-size: 1rem;
                                            margin-right: 7px;
                                            color: var(--t-color-primary);
                                            font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                        '>phone</span>
                                        <span>${this.patient.secondary_phone}</span>
                                    </a>
                                </div>
                                ` : ""}
                                <div class="p-diag no-mobile">
                            <strong>Primary Diagnosis:</strong> ${this.patient.primary_diagnosis || "Not specified"}
                        </div>
                            </div>
                        </div>
                        
                        ${this._app_name === "staff" ? html`
                        <div class="row patient-memo-cont" style="margin-top: 8px;">
                            <div class="col">
                                <app-patient-memo .app=${this._app_name} .patient=${this.patient}></app-patient-memo>
                            </div>
                        </div>` : ""}
                    </div>
                    <app-nav-links .links=${this.links} .page=${this.page}></app-nav-links>
                </div>
            `;
        }

        Object.assign(this.style, {
            display: "block",
            margin: "0 32px",
        });

        this.render();
    }

    render() {
        if (!this.template) return;

        if (!this.patient) return;

        if (!this.page) return;

        render(this.template(), this);
    }

    async handlePhoneClick(e) {
        if (this.client?.enable_softphone) {
            e.preventDefault();
            e.stopImmediatePropagation();
            let softphone_container_element = document.querySelector("app-softphone-container");
            await softphone_container_element.dial(this.patient.id);
        }

    }
    formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const digits = phoneNumber.replace(/\D/g, '');
        if (digits.length === 10) {
            return digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else {
            return phoneNumber; // Return as-is if not exactly 10 digits
        }
    }
    async loadClient() {
        let client_id;
        if (!this.patient)
            return;
        if (typeof this.patient.client_id == "object")
            client_id = this.patient.client_id.id;
        else
            client_id = this.patient.client_id;
        this.client = await cache.getClient(client_id);
        this.render();
    }

}

customElements.define("app-patient-header", ComponentPatientHeader);
export default ComponentPatientHeader;