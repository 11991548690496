import { html, render } from "lit";
import SurveyDefinition from "../../lib/lib-survey-definition";

export default class AppPatientCheckpointChanges extends HTMLElement {
    constructor() {
        super();
        this._patient = null;
        this._episodes = null;
        this._checkpoints = null;
    }

    get patient() {
        return this._patient;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get episodes() {
        return this._episodes;
    }

    set episodes(values) {
        this._episodes = values;
        this.render();
    }

    get checkpoints() {
        return this._checkpoints;
    }

    set checkpoints(values) {
        if (Array.isArray(values) === false) this._checkpoints = [];
        else this._checkpoints = values;

        this.findChanges();
    }

    connectedCallback() {
        this.template = () => html`<div class="row">
            <div class="col-md mb-2">
                <strong>Improved</strong>
                <div>
                    ${this.changes.improved.length
                ? this.changes.improved.map((d) => html`<div>${this.answerTemplate(d)} ${d.label}</div>`)
                : html`<i>Nothing improved</i>`}
                </div>
            </div>
            <div class="col-md">
                <strong>Declined</strong>
                <div>
                    ${this.changes.declined.length
                ? this.changes.declined.map((d) => html`<div>${this.answerTemplate(d)} ${d.label}</div>`)
                : html`<i>Nothing declined</i>`}
                </div>
            </div>
        </div>`;

        this.answerTemplate = (field) => {
            return this.checkpoints.map((checkpoint) => {
                const delta = checkpoint["delta_" + field.field];
                if (delta < 0) {
                    return html` <span
                        class="material-symbols-outlined"
                        style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #a4d394"
                        >arrow_circle_up</span
                    >`;
                } else if (delta > 0) {
                    return html`<span
                        class="material-symbols-outlined"
                        style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #ca463d"
                        >arrow_circle_down</span
                    >`;
                } else {
                    return html`<span
                        class="material-symbols-outlined"
                        style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #74AAB7"
                        >circle</span
                    >`;
                }
            });
        };

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        if (!this.episodes) return;
        if (!this.checkpoints) return;
        if (!this.changes) return;
        if (!this.survey_definition)
            return;
        render(this.template(), this);
    }

    /**
     * Run the checkpoints and find any fields that have improved or declined and filter them into separate lists
     */
    async findChanges() {
        this.survey_definition = await SurveyDefinition.getSurveyDefinition();
        this._field_dictionary = this.survey_definition.getFieldDictionary();
        this.changes = { improved: [], declined: [] };
        if (!this.checkpoints || !this.fields) return;
        this.baseline = this.checkpoints[0];
        this.checkpoint = this.checkpoints[this.checkpoints.length - 1];
        for (const key in this.checkpoint) {
            if (key.substring(0, 6) === "delta_") {
                if (this.baseline[key] != this.checkpoint[key]) {
                    if (this.baseline[key] > this.checkpoint[key]) {
                        this.changes.improved.push(this._field_dictionary[key.substring(6)]);
                    }
                    if (this.baseline[key] < this.checkpoint[key]) {
                        this.changes.declined.push(this._field_dictionary[key.substring(6)]);
                    }
                }
            }
        }
        this.render();
    }
}

customElements.define("app-patient-checkpoint-changes", AppPatientCheckpointChanges);
