import { html, render } from "lit";
import SurveyDefinition from "../../lib/lib-survey-definition";
import { format, startOfWeek, parse } from "date-fns";
import { filter } from "jszip";


/**
 * @param {object[]} task
 */
export default class AppPatientCheckpointCard extends HTMLElement {
    constructor() {
        super();

        // Initializing the arrays here.
        this._taskDetails = [];
        this._notes = [];
        this._alerts = [];
        this.noteCnt = 0;
        this.alertCnt = 0;
        this.checkpoint = null;
        this.submission = [];

    }

    get task() {
        return this._task;
    }

    set task(value) {
        if (value?.survey_id !== this.task?.survey_id) {
            this._task = value;

            // Filter the incoming arrays for the current task
            this._notes = this.notes.filter(note => note.survey_id === this.task.survey_id);
            this._alerts = this.alerts.filter(alert => alert.survey_id === this.task.survey_id);
        }
    }

    get taskDetails() {
        return this._taskDetails;
    }

    set taskDetails(value) {
        if (value) {
            this._taskDetails = value.filter(taskDetail => taskDetail.survey_id === this.task.survey_id);
            this.checkpoint = (this._taskDetails.length > 0) ? this._taskDetails[0] : undefined;
        }
    }

    get notes() {
        return this._notes;
    }

    set notes(value) {
        if (value) {
            this._notes = value.filter(note => note.survey_id === this.task.survey_id);
        }
    }

    get alerts() {
        return this._alerts;
    }

    set alerts(value) {
        if (value) {
            this._alerts = value.filter(alert => alert.survey_id === this.task.survey_id);
        }
    }

    connectedCallback() {
        this.lg_query = window.matchMedia("(min-width: 992px)");

        this.lg_query.addEventListener("change", _e => {
            this.render();
        });

        this.template = () => {
            const items = [];
            // const checkpoint_base_uri = `patients/${this.patient.id}/checkpoints/`;

            return html`
                <a class="col-md-10" href="patients/${this.task.patient_id}/checkpoints/${this.task.survey_id}/?${this.task.task_type}"> 
                <section class="checkpoint-card-inner ${this.task.task_type} ${this.task.status}">
                    <header class="checkpoint-card-header container-fluid">
                        <div class="header-inner">
                            <div class="header-status">
                           ${this.checkpointStatus()}
                            </div>
                            <div class="p-cards-alerts">
                            <div class="">${this.checkpointNoteIcon(this.noteCnt)}</div>
                            <div class="">${this.checkpointAlertIcon(this.alertCnt)}</div>
                            </div>
                        </div>
                       
                    </header>
                    <div class="checkpoint-card-categories-wrap">
                        <ul class="checkpoint-card-categories container-fluid">
                            ${this.task?.task_type == "legacy" ? this._field_groups.map(field_group => html`
                                <li class="row" style="margin: 0;">
                                    <div class="col-md-1">${this.fieldGroupIcon(field_group)}</div>
                                    <div class="col-md-9" title="${field_group.label}">${field_group.label}</div>
                                    <div class="col-md-1 card-list-alerts">${this.fieldGroupAlertIcon(field_group)}</div>
                                    <div class="col-md-1 card-list-notes">${this.fieldGroupNoteIcon(field_group)}</div>
                                </li>
                            `) : this.submission.map(item => html`
                                <li class="row cp-card-row" style="margin: 0;">
                                    <div class="col-md-1 cp-card-bullet">${this.fieldGroupIcon(item)}</div>
                                    <div class="col-md-9 cp-card-path" style="font-weight: 600;">${item.title}</div>
                                    <div class="col-md-1 card-list-alerts">${this.fieldGroupAlertIcon(item)}</div>
                                    <div class="col-md-1 card-list-notes">${this.fieldGroupNoteIcon(item)}</div>
                                </li>
                            `)}
                        </ul>
                    </div>
                </section></a>`;
        };

        //this.render(); //performance testing
        this.init();
    }

    render() {
        if (!this.task) return;
        if (!this._field_groups && !this.submission) return;
        render(this.template(), this);
    }

    async init() {
        if (this.task?.task_type != "legacy") {
            if (this.checkpoint?.submission) {
                const submission = JSON.parse(this.checkpoint.submission);
                this.submission = submission?.array_data || [];
                this.submission.forEach(item => {
                    item.notes = [];
                    item.alerts = [];
                    item.components.forEach(component => {
                        const alerts = this.alerts.filter(alert => alert.question === component.key)
                        const notes = this.notes.filter(note => note.question === component.key);
                        item.alerts = item.alerts.concat(alerts);
                        item.notes = item.notes.concat(notes);
                    })
                    this.alertCnt += item.alerts.length;
                    this.noteCnt += item.notes.length;
                })
            }
        }
        else if (this.task?.survey_id) {
            this._survey_definition = await SurveyDefinition.getSurveyDefinition(this.task.survey_id, false);
            this._field_groups = this._survey_definition.getFieldGroups();

            this._alerts = this.alerts;
            this._notes = this.notes;

            this._field_groups = this._field_groups.filter((group) => this.hasBeenAsked(group, this.checkpoint));

            this._field_groups.forEach(field_group => {
                field_group.alerts = [];
                field_group.notes = [];

                field_group.fields.forEach(field => {
                    const alert = this._alerts.find(alert => alert.question === field.field);
                    if (alert) {
                        field_group.alerts.push(alert);
                        this.alertCnt++;
                    }

                    const note = this._notes.find(notes => notes.question === field.field);
                    if (note) {
                        field_group.notes.push(note);
                        this.noteCnt++;
                    }
                });

            });
        }
        this.render();
    }

    /**
     * Determine if the group was ever used in this checkpoint
     * @param {array} group
     * @param {array} checkpoint 
     * @returns {boolean}
     */
    hasBeenAsked(group, checkpoint) {
        if (!checkpoint)
            return false;

        for (const field of group.fields) {
            const value = checkpoint[field.field];
            if (value !== null && value !== undefined) {
                return true;
            }
        }

        return false;
    }

    checkpointStatus() {

        let survey_date = this.task.completed_date ? `${format(new Date(this.task.completed_date), 'PPPP')}` : '';
        const scheduledDate = parse(this.task.scheduled_date, "yyyy-MM-dd'T'HH:mm:ss", new Date());
        const formattedStartDate = format(startOfWeek(scheduledDate, { weekStartsOn: 0 }), "'Week of' MMM do");
        let icon = '';
        let color = '';
        let statusText = '';
        let statusReason = '';

        if (this.task.status == 'complete') {
            color = '#9AC58F';
            statusText = 'complete';
        } else if (this.task.status == 'unable_to_complete') {
            color = '#f29188';
            statusText = 'Unsuccessful';
            statusReason = ` - ${this.task.status_reason.replace(/_/g, ' ')}`;
        } else if (this.task.status == 'active' || this.task.status == 'ready') {
            color = '#8eb8c7';
            statusText = 'Pending';
        } else if (this.task.status == 'archived' || this.task.status == 'blocked') {
            color = '#cccccc';
            statusText = 'Archived';
        }

        if (this.task.task_type == 'survey') {
            icon = 'phone_in_talk';
        } else if (this.task.task_type == 'self') {
            icon = 'stream_apps';
        } else if (this.task.task_type == 'in_person') {
            icon = 'person';
        } else {
            icon = 'phone_in_talk'
        }

        const title = this.task.title.replace("Checkpoint ", "");

        return html`
        <span aria-label="${this.task.status_reason_notes}" class="material-symbols-outlined" style="color:white ; background-color:${color}; padding:6px; border-radius:10px; font-variation-settings: 'FILL' 1;font-size: 18px;font-weight: 400;top: -3px;position: relative;margin-right: 11px; height:30px">
            ${icon}
        </span>
            <div class="status-wrap">
                <span style="display: flex; gap: 12px;">
                    <h5>${title}</h5>
                    <h5>${statusText}${statusReason}</h5>
                </span>
                <span class="checkpoint-card-complete-date">${survey_date ? survey_date : formattedStartDate}</span>
            </div>           
        `;
    }

    checkpointIcon() {

        return html`<h5><span class="material-symbols-outlined" style="font-variation-settings: 'FILL' 1;font-size: 18px;font-weight: 300;top: 3px;position: relative;margin-right: 5px;">phone_in_talk</span></h5>`;
    }

    checkpointNoteIcon(count) {
        if (count) {
            return html`
                <span class="material-symbols-outlined" style="display: inline-block; margin-right: 10px; font-size: 1.6rem; font-variation-settings: 'FILL' 1; color: #555; mix-blend-mode: color-burn;">description</span>
                <span
                    class="position-absolute translate-middle badge rounded-pill"
                    style="background-color: #74AAB7;"
                    id="notifications-badge">
                    ${count}
                </span>
            `;
        }

        return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 10px; font-size: 1.6rem; font-variation-settings: 'FILL' 1; color: #999; mix-blend-mode: color-burn;">description</span>`
    }

    checkpointAlertIcon(count) {
        if (count) {
            return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 10px; font-size: 1.8rem; font-variation-settings: 'FILL' 1; color: #555; mix-blend-mode: color-burn;">notifications</span>
                <span
                    class="position-absolute translate-middle badge rounded-pill bg-danger"
                    id="notifications-badge"
                    style="background-color: #F29188 !important;">
                    ${count}
                </span>
            `;
        }
        return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 10px; font-size: 1.8rem; font-variation-settings: 'FILL' 1; color: #999; mix-blend-mode: color-burn;">notifications</span>`
    }

    fieldGroupIcon(field_group) {
        if (field_group.alerts.length) {
            return html`<span
                            class="material-symbols-outlined"
                            style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #F29188"
                            >arrow_circle_down
                        </span>`
        }

        // Option 1
        if (field_group.fields != null && field_group.fields.length > 0) {
            const delta = this.checkpoint["delta_" + field_group.fields[0].field];

            if (!delta || delta == 0) {
                return html`<span
                    class="material-symbols-outlined"
                    style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #74AAB7"
                    >check_circle
                </span>`;
            } else if (delta > 0) {
                return html`<span
                    class="material-symbols-outlined"
                    style="font-size: 1rem; font-variation-settings: 'FILL' 1; color:#F29188"
                    >arrow_circle_down
                </span>`;
            } else {
                return html`<span
                    class="material-symbols-outlined"
                    style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #9ac58f"
                    >arrow_circle_up
                </span>`;
            }
        }

        // Option2
        // if (field_group.alerts.length) {
        //     return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.3rem; font-variation-settings: 'FILL' 1; color: #CA463D;">error</span>`
        // }

        // return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.3rem; font-variation-settings: 'FILL' 1; color: #9AC58F; ">check_circle</span>`

        // Option3

        return html`<span
                        class="material-symbols-outlined"
                        style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #74AAB7"
                        >check_circle
                    </span>`
    }

    fieldGroupAlertIcon(field_group) {
        if (!field_group.alerts?.length) return '';

        // if (field_group.alerts.filter(alert => alert.level === 1).length > 0) {
        //     return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.3rem; font-variation-settings: 'FILL' 1; color: #F29188;">error</span>`
        // }

        // return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.3rem; font-variation-settings: 'FILL' 1; color: #D89233;">warning</span>`

        if (field_group.alerts.filter(alert => alert.level === 1).length > 0) {
            return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.3rem; font-variation-settings: 'FILL' 1; color: #F29188;">error</span>`
        }

        if (field_group.alerts.filter(alert => alert.level === 2).length > 0) {
            return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.3rem; font-variation-settings: 'FILL' 1; color: #E4A00F;">warning</span>`
        }

        return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.3rem; font-variation-settings: 'FILL' 1; color: #201B3A;">warning</span>`
    }

    fieldGroupNoteIcon(field_group) {
        if (!field_group.notes?.length) return '';

        return html`<span class="material-symbols-outlined" style="display: inline-block; margin-right: 5px; font-size: 1.3rem; font-variation-settings: 'FILL' 1; color: #7d9fa9;">description</span>`

    }
}

customElements.define("app-patient-checkpoint-card", AppPatientCheckpointCard);