import { html, render } from 'lit';
import AppConfirmationModal from '../app-confirmation-modal';
import directus from '../../lib/lib-directus';
import { TelnyxRTC } from '@telnyx/webrtc';
import directusWebsocket from "../../lib/lib-directus-websocket";
import AppSoftphoneSettingsModal from './app-softphone-settings-modal';
import { Alert, Dropdown } from 'bootstrap';
import ApplicationState from 'applicationstate';
import { serialize } from '@ungap/structured-clone';
import AppCommunicationCreateModal from './app-communication-create-modal';
import AppSoftphoneCallLogModal from './app-softphone-call-log-modal';

/** 
 * @typedef {object} PatientCommunicationLog
 * @property {Date} date_created - The datetime of the event
 * @property {string} event - A short string describing the call event
 * @property {string} message - A longer string with a detailed message
 * @property {string} patient_communication_id
 */

/**
 * Utility class for softphone implementation
 */
class AppSoftphone extends HTMLElement {

    set display_mode(value) {
        if (!['dialing', 'connected', 'complete'].includes(value))
            value = 'icon';
        this._display_mode = value;
        this.render();
    }

    get display_mode() {
        return this._display_mode || 'icon';
    }

    get call_mode() {
        return ApplicationState.get('app.name');
    }

    set patient_id(value) {
        if (!value)
            return;
        this._patient_id = value;
        this._loading_promise = this.loadPatient();
    }

    get patient_id() {
        return this._patient_id;
    }

    set patient(value) {
        this._patient = value;
    }

    get patient() {
        return this._patient;
    }

    set client(value) {
        this._client = value;
    }

    get client() {
        return this._client;
    }

    set selected_phone_number(value) {
        if (value == this._phone_number)
            return;
        if (!value)
            return;
        this._selected_phone_number = value;
    }

    get selected_phone_number() {
        if (!this._selected_phone_number)
            this._selected_phone_number = this.patient?.primary_phone;
        return this._selected_phone_number;
    }

    set call_log(value) {
        this._call_log = value;
    }

    /** @type {PatientCommunicationLog[]} */
    get call_log() {
        return this._call_log;
    }

    set communication(value) {
        this._communication = value;
    }

    /**
     * @type {object} - The communication_log entry that's created when the number is dialed
     */
    get communication() {
        return this._communication;
    }

    constructor() {
        super();
        this.call_log = [];
    }

    connectedCallback() {
        this.template = () => html`
        <style>
            app-softphone .select-device {
                border: none;
                padding: 0px;
                padding: 0 5px;
                font-size: 12px;
                font-weight: 600;
                max-width: 161px;
                white-space: nowrap;
                text-overflow: ellipsis;
                background:white;
            }
            app-softphone .input-group .material-symbols-outlined {
                /*background-color: #f4e4ba;*/
                cursor: pointer;
                font-variation-settings: 'FILL' 1;
            }
            app-softphone .input-group .material-symbols-outlined:hover {
                /*background-color: #f4e4ba;*/
            }
            app-softphone .dropdown-menu li {
                cursor: pointer;
            }
        </style>
        <audio id="remote-audio"></audio>
        
        <div class="softphone">
            <div class="input-group" style="
                /*width: 200px; */
                /*margin-right: 22px; */
                /*border-bottom-left-radius: 8px;*/
                /*border-bottom-right-radius: 8px;*/
                /*background-color: #f4e4ba;*/
                ">
                ${this.display_mode == 'icon' ?
                html`
                <span 
                    ?disabled=${this.selected_phone_numner && this.client?.outbound_phone_number}
                    @click=${e => this.dial()}
                    style="
                        color: var(--t-color-primary); 
                        border-top-left-radius: 0px;
                        "
                    class="input-group-text material-symbols-outlined">call</span>
                `: ''
            }
                ${['dialing', 'connected'].includes(this.display_mode) ?
                html`
                <span 
                    ?disabled=${this.selected_phone_numner && this.client?.outbound_phone_number}
                    @click=${e => this.hangup()}
                    style="color: var(--t-color-danger); top: 0px;"
                    class="input-group-text material-symbols-outlined">call_end</span>
                `: ''
            }
                <input type="phone" 
                    @click=${e => this.dial()}
                    @keydown=${e => e.preventDefault()}
                    style="
                        cursor: pointer;
                        font-size: 12px;
                        font-weight: 600;
                        color: var(--t-color-primary);
                        /*background-color: #f4e4ba;*/
                        border-right: none;
                    "
                    class="form-control phone-status" 
                    value=${this.display_mode == 'icon' ? this.selected_phone_number
                : this.display_mode == 'dialing' ? 'Dialing...'
                    : this.display_mode == 'connected' ? `Connected: ${this.call_duration}s `
                        : this.display_mode == 'complete' ? 'Complete'
                            : ''}>
                <span 
                    @click=${e => this.dispatchEvent(new CustomEvent('hide'))}
                    style="
                        cursor: pointer;
                        border-top-right-radius: 0px;
                        top: 0px;
                    "
                    class="input-group-text material-symbols-outlined" 
                    data-bs-toggle="dropdown" 
                    aria-expanded="false">
                    close
                </span>


<div class="softphone-setting form-control"
                style="
                    /*transition: all .35s;*/
                    overflow: hidden;

                    /* height: ${['dialing', 'connected'].includes(this.display_mode) ? '50px' : '0px'}; */
                "
            >
                <div style="display: flex; flex-direction: row; align-items: center;" style="height: 20px;">
                    <label 
                        style="
                            border: none;
                           
                            color: var(--t-color-dark);
                            font-weight: 300;
                            font-size: 16px;
                            padding: 0px;
                            padding-left: 5px;
                            padding-right: 5px;
                        " 
                        class="material-symbols-outlined" 
                        for="select_input_device">settings_voice</label>
                    <select 
                        @change=${e =>
                this.handleSwitchInputDevice(e.target.value)
            }
                        class="select-device"
                        id="select_input_device">
                        ${(this.input_devices || [{ label: "Loading..." }]).map(
                device => html`
                            <option ?selected=${this.selected_input_device_id == device.deviceId} value=${device.deviceId}>${device.label}</option>
                            `
            )}
                    </select>
                </div>
                <div style="display: flex; flex-direction: row; align-items: center;" style="height: 20px;">
                    <label 
                        style="
                            border: none;
                            
                            color: var(--t-color-dark);
                            font-weight: 300;
                            font-size: 16px;
                            padding: 0px;
                            padding-left: 5px;
                            padding-right: 5px;
                        " 
                        class="material-symbols-outlined" 
                        for="select_input_device">speaker_group</label>
                    <select 
                        @change=${e => this.handleSwitchOutputDevice(e.target.value)}
                        class="select-device"
                        id="select_output_device">
                        ${(this.output_devices || [{ label: "Loading..." }]).map(
                device => html`
                            <option ?selected=${this.selected_output_device_id == device.deviceId} value=${device.deviceId}>${device.label}</option>
                            `
            )}
                    </select>
                </div>
            </div>


            </div>
            
        </div>
        `;
        this.render();
    }

    disconnectedCallback() {
        if (this.call)
            this.call.hangup();
        this.destroy();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    async loadPatient() {
        let patient = await directus.items('patient').readOne(this.patient_id,
            {
                fields: [
                    "id",
                    "client_id",
                    "primary_phone",
                    "secondary_phone",
                    "first_name",
                    "last_name"
                ]
            }
        );
        this.patient = patient;

        let client = await directus.items("client").readOne(patient.client_id,
            {
                fields: [
                    'name',
                    'outbound_phone_number',
                    'sip_username',
                    'sip_password'
                ]
            });
        this.client = client;
        let result = await directus.transport.get('/vbh/sip/config');
        this.sip_config = result.data;
        this.render();
    }

    async initDevice() {
        console.log("Initializing webrtc device");

        if (this.device)
            return;

        let stream;

        try {
            stream = await navigator.mediaDevices.getUserMedia(
                {
                    audio: true
                }
            );
        }
        catch (err) {
            alert("You must grant browser audio permission in order to place calls.");
            return;
        }

        navigator.mediaDevices.addEventListener("devicechange", async e => await this.loadDeviceList());

        await this.loadDeviceList();
        await this.setInputDevice();
        await this.setOutputDevice();

        //close all the tracks so they can be used by the dialer
        for (let track of stream.getTracks()) {
            track.stop();
        }

        this._sip_ready_promise = new Promise(
            (resolve, reject) => {
                this._sip_ready_resolve = resolve;
            }
        );

        let sip_username = this.client.sip_username || this.sip_config.sip_default_username;
        let sip_password = this.client.sip_password || this.sip_config.sip_default_username;
        let telnyx_client = new TelnyxRTC({
            login: sip_username,
            password: sip_password
        });

        this.device = telnyx_client;

        this.initEvents();

        telnyx_client.connect();
    }

    async destroy() {
        if (this.call)
            await this.call.hangup();
        if (this.device?.disconnect)
            await this.device.disconnect();
        this.destroyEvents();
        this.device = null;
        this.call = null;
    }

    async setInputDevice(device_id) {
        if (!device_id)
            device_id = ApplicationState.get('app.selected_input_device_id');
        if (!device_id)
            device_id = this.input_devices[0].deviceId;
        if (!device_id)
            throw new Error("Unable to set input audio device");

        ApplicationState.set('app.selected_input_device_id', device_id);
        this.selected_input_device_id = device_id;
        if (this.call)
            await this.call.setAudioInDevice(device_id);
        this.render();
    }

    async setOutputDevice(device_id) {
        if (!device_id)
            device_id = ApplicationState.get('app.selected_output_device_id');
        if (!device_id)
            device_id = this.output_devices[0].deviceId;
        if (!device_id)
            throw new Error("Unable to set output audio device");

        ApplicationState.set('app.selected_output_device_id', device_id);
        this.selected_output_device_id = device_id;
        if (this.call)
            await this.call.setAudioOutDevice(device_id);
        this.render();
    }

    async loadDeviceList() {
        let devices = await navigator.mediaDevices.enumerateDevices();
        this.input_devices = devices.filter(device => device.kind == 'audioinput');
        this.output_devices = devices.filter(device => device.kind == 'audiooutput');
        this.camera_devices = devices.filter(device => device.kind == 'videoinput');
        this.render();
    }

    async handleSwitchInputDevice(device_id) {
        await this.setInputDevice(device_id);
    }

    async handleSwitchOutputDevice(device_id) {
        await this.setOutputDevice(device_id);
    }

    async handleShowCallLog() {
        const call_log_modal = new AppSoftphoneCallLogModal();
        call_log_modal.call_log = this.call_log.reverse();
        await call_log_modal.showModal();
    }

    async dial() {
        await this._loading_promise;
        this.display_mode = 'dialing';
        await this.initDevice();
        console.log('waiting for ready..');
        await this._sip_ready_promise;
        //wait 1s to help deal with strange race condition that happens with gateway registration
        await new Promise((resolve => setTimeout(resolve, 1000)));
        console.log('dialing..');

        let outbound_phone_number = this.client.outbound_phone_number;
        let sip_username = this.client.sip_username;
        let sip_password = this.client.sip_password;

        if (!(outbound_phone_number && sip_username && sip_password)) {
            let modal = new AppConfirmationModal();

            modal.modal_text = `${this.client.name} does not have a verified caller id number set up, the patient will see a number they may not recognize. Do you want to continue?`;
            await modal.showModal();

            let ok = await modal.onDidDismiss();

            if (!ok?.confirmed)
                return;

            if (!
                (
                    this.sip_config?.sip_default_outbound_phone_number &&
                    this.sip_config?.sip_default_username &&
                    this.sip_config?.sip_default_password
                )
            ) {
                const message = "Unable to place call, missing default config";
                this.log("call.error", message);
                return document.querySelector('app-toaster').toast(message, { header: "Call Error" })
            }


            outbound_phone_number = this.sip_config.sip_default_outbound_phone_number;
        }

        try {
            this.patient_communication = await directus.items('patient_communication').createOne(
                {
                    client_id: this.patient.client_id,
                    patient_id: this.patient_id,
                    task_id: this.task?.id,
                    communication_type: 'phone_voice',
                    caller_type: this.call_mode,
                    status: "new"
                }
            );

            //normalize the phone number to a standard string that Telnyx supports
            let destination_number = this.selected_phone_number;
            destination_number = destination_number.replace(/[^\d+]+/g, '');
            destination_number = destination_number.replace(/^00/, '+');
            if (destination_number.match(/^1/)) destination_number = '+' + destination_number;
            if (!destination_number.match(/^\+/)) destination_number = '+1' + destination_number;

            this.call = this.device.newCall({
                callerName: this.client.name,
                callerNumber: outbound_phone_number,
                destinationNumber: destination_number,
                micId: this.selected_input_device_id,
                speakerId: this.selected_output_device_id,
                remoteElement: 'remote-audio'

            });
            /* SIP
            let inviter = new Inviter(this.user_agent, UserAgent.makeURI('sip:3023837042@sip.telnyx.com'));
            await inviter.invite();
            */
        }
        catch (err) {
            console.error(err);
            alert("Unable to place outgoing call: " + err.message);
        }

        this.render();
    }

    async hangup() {
        if (!this.call)
            return;

        await this.call.hangup();
        await this.device.disconnect();
    }

    /**
     * telnyx.ready	The client is authenticated and available to use
     * telnyx.error	An error occurred at the session level
     * telnyx.notification	An update to the call or session
     * telnyx.socket.open	The WebSocket connection has been made
     * telnyx.socket.close	The WebSocket connection is set to close
     * telnyx.socket.error	An error occurred at the WebSocket level
     * telnyx.socket.message	The client has received a message through WebSockets
     */
    initEvents() {
        const deepStringify = (obj) => {
            return JSON.stringify(serialize(obj, { json: true, lossy: true }));
        }

        this.device.on('telnyx.ready', this.on_telnx_ready = (client) => {
            console.warn(JSON.stringify(client));
            let message = "softphone: telnyx client authenticated, ready to make a call";
            if (this._sip_ready_resolve) {
                this._sip_ready_resolve();
            }
            console.info(message);
            this.log("telnyx.ready", message);
            this.render();
        });

        this.device.on('telnyx.error', this.on_telnyx_error = async (error) => {
            let message = "softphone: ERROR - " + JSON.stringify(error, null, 2);
            console.error(message);
            this.log("telnyx.error", message);
            document.querySelector('app-toaster').toast(message, { header: 'Call Error' });
            await this.device.disconnect();
            this.render();
        });

        this.device.on('telnyx.notification', this.on_telnyx_notification = (notification) => {
            let message;
            switch (notification.type) {
                case 'callUpdate':
                    message = "softphone: notification - " + notification.type + " - call state: " + notification.call?.state;
                    console.info(message);
                    this.log("telnyx.notification", message);
                    this.handleCallStateChange(notification.call);
                    break;
                default:
                    message = "softphone: notification - " + notification.type + " - call state: " + notification.call?.state;
                    console.info(message);
                    this.log("telnyx.notification", message);

            }
            this.render();
        });

        this.device.on('telnyx.socket.open', this.on_telnyx_socket_open = () => {
            let message = "softphone: websocket connected";
            console.debug(message);
            this.log("telnyx.socket.open", message);
            this.render();
        });

        this.device.on('telnyx.socket.close', this.on_telnyx_socket_close = async () => {
            let message = "softphone: websocket closed";
            console.debug(message);
            this.log("telnyx.socket.close", message);
            await this.device.disconnect();
            this.render();
        });

        this.device.on('telnyx.socket.error', this.on_telnyx_socket_error = async (error) => {
            let message = "softphone: websocket error: " + JSON.stringify(error, null, 2);
            console.error(message);
            this.log("telnyx.socket.error", message);
            document.querySelector('app-toaster').toast(message, { header: 'Call Error' });
            await this.device.disconnect();
            this.render();
        });

        this.device.on('telnyx.socket.message', this.on_telnyx_socket_message = (socket_message) => {
            let message = "softphone: websocket message: \n" + deepStringify(socket_message, null, 2);
            console.debug(message);
            // TODO: is this excessive?
            this.log("telnyx.socket.message", message);
            this.render();
        });
    }

    destroyEvents() {
        if (!this.device)
            return;
        this.device.off('telnyx.ready', this.on_telnyx_ready);
        this.device.off('telnyx.error', this.on_telnyx_error);
        this.device.off('telnyx.notification', this.on_telnyx_notification);
        this.device.off('telnyx.socket.open', this.on_telnyx_socket_open);
        this.device.off('telnyx.socket.close', this.on_telnyx_socket_close);
        this.device.off('telnyx.socket.error', this.on_telnyx_socket_error);
        this.device.off('telnyx.socket.message', this.on_telnyx_socket_message);
    }

    handleCallStateChange(call) {
        switch (call.state) {
            case 'new':
            case 'requesting':
            case 'trying':
                this.display_mode = 'dialing';
                break;
            case 'active':
                this.display_mode = 'connected';
                this.call_start_time = new Date();
                this.handleCallConnected();
                break;
            case 'hangup':
                this.handleCallHangup();
                break;
            default:
                break;
        }
    }

    async handleCallConnected() {
        //set a display timer to show call duration
        this.timerInterval = setInterval(
            () => {
                this.call_duration = Math.floor((new Date().getTime() - this.call_start_time.getTime()) / 1000);
                this.render();
            },
            1000
        );
    }

    async handleCallHangup() {
        //guard for dealing with redundant hangup/destroy calls
        if (this._hangup_handled)
            return;
        this._hangup_handled = true;

        if (this.call_start_time) {
            let call_duration = Math.floor(((new Date()).getTime() - this.call_start_time.getTime()) / 1000);
            let update = { call_duration };
            //if this is a client or clinician call, don't prompt for status and notes, just set to complete
            if (this.call_mode !== "staff")
                update.status = "complete"
            await directus.items('patient_communication').updateOne(this.patient_communication?.id, update);
        }
        this.display_mode = 'icon';
        this.dispatchEvent(new CustomEvent("hangup", {
            bubbles: true,
            composed: true
        }));

        if (this.call_mode == "staff") {
            let modal = new AppCommunicationCreateModal();
            modal.patient_communication = this.patient_communication;
            modal.task = this.task;
            await modal.showModal();
            const result = await modal.onDidDismiss();
            if (result?.success) {
                document.querySelector('app-toaster').toast('Call log saved successfully', { header: 'Call Complete' });
            }
        }
    }

    /**
     * Adds a log entry, posting to patient_communication_log via WS 
     * if this.patient_communication exists.
     * @param {string} event 
     * @param {string} message 
     */
    log(event, message) {
        const log_entry = {
            date_created: new Date(),
            event,
            message,
        }
        this.call_log.push(log_entry);
        if (this.patient_communication) {
            directusWebsocket.post("patient_communication_log", {
                ...log_entry,
                patient_communication_id: this.patient_communication.id
            });
        }
    }
}

customElements.define('app-softphone', AppSoftphone);
export default AppSoftphone;
