import { html, render } from "lit";
import { styleMap } from "lit/directives/style-map.js";
import { getResponseText } from "../lib/lib-util";

/**
 * @fires ComponentQuestionHistoryChart#checkpointmouseover on checkpoint bubble mousover
 * @fires ComponentQuestionHistoryChart#checkpointmouseleave on checkpoint bubble mouseleave
 */
class ComponentQuestionHistoryChart extends HTMLElement {
    get checkpoints() {
        return this._checkpoints;
    }

    set checkpoints(value) {
        if (!value) this._checkpoints = [];
        else {
            let sorted = [];
            sorted = sorted.concat(value);
            sorted.sort((a, b) => {
                return a.day - b.day;
            });
            this._checkpoints = sorted;
        }
        this.render();
    }

    get episode() {
        return this._episode;
    }

    set episode(value) {
        this._episode = value;
        this.render();
    }

    get selected_question() {
        return this._selected_question;
    }

    set selected_question(value) {
        this._selected_question = value;
        this.render();
    }

    get field_definition() {
        return this._field_definition;
    }

    set field_definition(value) {
        this._field_definition = value;
        this.render();
    }

    get last_delta() {
        if (!this._selected_question) return 0;
        if (!this._checkpoints) return 0;
        if (!this._checkpoints.length) return 0;

        //checkpoints are sorted descending, so 0 will be the most recent
        return this.checkpoints[this.checkpoints.length - 1]["delta_" + this.selected_question.field];
    }

    constructor() {
        super();
    }

    connectedCallback() {
        this.template = () => html`
            <div id="question_chart_header" style="display: flex; flex-direction: row; justify-content: space-between;">
                <div
                    style="
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #201B3A;
                display: flex;
                align-items: center;
                max-width: 250px;
            ">
                    <span style="margin-right: 10px;" class="material-symbols-outlined"> help </span>
                    ${this.selected_question.meta.note}
                </div>
                ${this.last_delta < 0
                ? //patient improved
                html`
                          <div
                              style="
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                align-items: center;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #a4d394;
            ">
                              <span style="margin-right: 10px;" class="material-symbols-outlined"> check_circle </span>
                              CONDITION IMPROVED
                          </div>
                      `
                : this.last_delta > 0
                    ? //patient got worse
                    html`
                          <div
                              style="
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: start;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #ca463d;
            ">
                              <span style="margin-right: 10px;" class="material-symbols-outlined"> trending_down </span>
                              CONDITION DECLINED
                          </div>
                      `
                    : //no change
                    html``}
            </div>
            <div id="scorecard_chart_wrapper" style="display: flex; height: 200px; align-items: center;">
                <div
                    id="scorecard_baseline"
                    style="
                height: 0px;
                width: 100%;
                border-top: 4px solid #a6a5ad;
                border-bottom: 4px solid #a6a5ad;
                display: block;
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
            ">
                    <div
                        style="
                position: relative;
                height: 200px;
                width: 100px;
                top: -100px;
                display: flex;
                align-items: center;
            ">
                        <div
                            style="
                    width: 71px;
                    height: 22px;
                    background: #6E6C7B;
                    border-radius: 12px;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 14px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: -3px;
                ">
                            BASELINE
                        </div>
                    </div>
                    ${this.checkpoints
                .filter((checkpoint) => checkpoint.source !== "baseline")
                .map((checkpoint, _index) => {
                    const tooltip_title = getResponseText(
                        checkpoint,
                        this.selected_question.field,
                        this.field_definition
                    );
                    return html`
                                <div
                                    style="
                    position: relative;
                    height: 200px;
                    width: 100px;
                    top: -100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                ">
                                    <div style="position: relative">
                                        <div
                                            @mouseover=${(e) => this.handleCheckpointMouseover(e, checkpoint.survey_id)}
                                            @mouseleave=${(e) => this.handleCheckpointMouseleave(e)}
                                            title=${tooltip_title}
                                            data-bs-title=${tooltip_title}
                                            style=${styleMap(this.getCheckpointStyle(checkpoint))}
                                            data-bs-toggle=${tooltip_title ? "tooltip" : ""}
                                            data-bs-placement="right"></div>
                                    </div>
                                    <div
                                        style="
                        height: 40px;
                        position: absolute;
                        bottom: 1px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #000000;
                    ">
                                        <div
                                            style="display: flex; flex-direction: column; justify-content: center; align-items: center">
                                            Week ${checkpoint.week} <br />
                                            <span style="font-size: 10px"
                                                >(${new Date(
                        checkpoint.survey_completion_date
                    ).toLocaleDateString()})</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            `;
                })}
                </div>
            </div>
            <div id="scorecard_legend" style="display: flex; align-items: center; justify-content: center; flex-wrap: wrap; margin-top: 8px;">
                <div
                    style="
            display: flex; 
            align-items: center; 
            justify-content: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #201B3A;">
                    <div
                        style="
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #a4d394;
                    margin-right: 7px;
                "></div>
                    Improved
                </div>

                <div
                    style="
            display: flex; 
            align-items: center; 
            justify-content: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            margin-left: 20px;
            color: #201B3A;">
                    <div
                        style="
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #ca463d;
                    margin-right: 7px;
                "></div>
                    Declined
                </div>

                <div
                    style="
            display: flex; 
            align-items: center; 
            justify-content: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            margin-left: 20px;
            color: #201B3A;">
                    <div
                        style="
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #74AAB7;
                    margin-right: 7px;
                "></div>
                    No change
                </div>

                <div
                    style="
            display: flex; 
            align-items: center; 
            justify-content: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            margin-left: 20px;
            color: #201B3A;">
                    <div
                        style="
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 2px solid #A6A5AD;
                    background-color: #fff;
                    margin-right: 7px;
                "></div>
                    Not surveyed
                </div>
            </div>
        `;

        this.render();
    }

    render() {
        if (!this.template) return;

        if (!this.selected_question) return;

        if (!this.checkpoints) return;

        if (!this.episode) return;

        if (!this.field_definition) return;

        render(this.template(), this);

        // Initialize boostrap tooltips for this component
        //[...this.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((el) => new bootstrap.Tooltip(el));
    }

    getCheckpointStyle(checkpoint) {
        let style = {
            position: "absolute",
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            top: checkpoint["delta_" + this.selected_question.field] * 20 - 16 + "px",
            left: "-16px",
        };

        if (checkpoint[this.selected_question.field] == null) {
            style.backgroundColor = "#fff";
            style.border = "2px solid #A6A5AD";
            return style;
        }

        let delta = checkpoint["delta_" + this.selected_question.field];
        if (delta > 0) style.backgroundColor = "#ca463d";
        else if (delta < 0) style.backgroundColor = "#a4d394";
        else if (!delta) style.backgroundColor = "#74AAB7";

        return style;
    }

    /**
     * @param {Event} e mouseover event from checkpoint bubble
     * @param {*} checkpoint_survey_id vw_measures_delta.survey_id
     * @fires ComponentQuestionHistoryChart#checkpointmouseover
     */
    handleCheckpointMouseover(e, checkpoint_survey_id) {
        e.stopPropagation();
        this.dispatchEvent(
            new CustomEvent("checkpointmouseover", {
                detail: { checkpoint_survey_id },
            })
        );
    }

    /**
     * @param {Event} e mouseleave event from checkpoint bubble
     * @fires ComponentQuestionHistoryChart#checkpointmouseleave
     */
    handleCheckpointMouseleave(e) {
        e.stopPropagation();
        this.dispatchEvent(new CustomEvent("checkpointmouseleave"));
    }
}

customElements.define("app-question-history-chart", ComponentQuestionHistoryChart);
export default ComponentQuestionHistoryChart;
