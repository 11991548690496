import cache from "./lib-cache";

class FormsDataDictionary {
    /**
     * For given form design, recursively loads a map of parent to supplemental questions
     * into this.form_supplemental_questions[form_design_id].
     * 
     * Does nothing if fields for the form_design are already loaded.
     * @param {string} form_design_id
     * @returns {object} this.form_supplemental_questions[form_design_id]
     */
    static async loadFormSupplementalQuestions(form_design_id) {
        if (!this.form_supplemental_questions) {
            this.form_supplemental_questions = {};
        }
        if (!form_design_id) {
            return null;
        }
        if (!this.form_supplemental_questions[form_design_id]) {
            const form_design = await cache.getFormDesign(form_design_id);
            const form_schema = form_design.form_schema;
            this.form_supplemental_questions[form_design_id] = FormsDataDictionary.#getFormSupplementalQuestions(form_schema)
        }
        return this.form_supplemental_questions[form_design_id];
    }

    /**
     * For each form design id, recursively loads a map of parent to supplemental questions
     * into this.form_supplemental_questions[form_design_id].
     * 
     * Skips if fields for the form_design are already loaded.
     * @param {string[]} form_design_ids
     * @returns {object} this.form_supplemental_questions filtered for form_design_ids
     */
    static async loadFormsSupplementalQuestions(form_design_ids) {
        if (!this.form_supplemental_questions) {
            this.form_supplemental_questions = {}
        }

        const forms_supplemental_questions = {}
        const form_designs = await cache.getFormDesigns(form_design_ids)
        for (let form_design of form_designs) {
            const { id } = form_design
            if (!this.form_supplemental_questions[id]) {
                const form_schema = form_design.form_schema;
                this.form_supplemental_questions[id] = FormsDataDictionary.#getFormSupplementalQuestions(form_schema)
            }
            forms_supplemental_questions[id] = this.form_supplemental_questions[id];
        }
        return forms_supplemental_questions
    }

    static getFormSupplementalQuestions(form_design_id) {
        return this.form_supplemental_questions[form_design_id];
    }

    /**
     * For the given form, recursively builds a map of parent to supplemental/child questions.
     * @param {Object} form_schema Form.IO json form schema
     * @param {Object} questions_map used for recursion, not intended to be passed by the caller
     * @returns {Object} flat map of parent field name/key to all child fields
     */
    static #getFormSupplementalQuestions(form_schema, questions_map = {}) {
        if (!form_schema?.components) return {};
        for (let component of form_schema.components) {
            const parent_field_name = component.properties.parent_field;
            if (parent_field_name) {
                if (!questions_map[parent_field_name]) {
                    questions_map[parent_field_name] = [];
                }
                questions_map[parent_field_name].push({
                    choices: component.data?.values || component?.values,
                    field: component.key,
                    label: component.properties?.report_label || FormsDataDictionary.#formatFieldLabel(component.key),
                });
            }
            if (component.components?.length) {
                // recursively add nested fields to the dictionary
                FormsDataDictionary.#getFormSupplementalQuestions(component, questions_map);
            }
        }
        return questions_map;
    }

    static #formatFieldLabel(field_name) {
        field_name = field_name.replace('pr_', '');
        field_name = field_name.replaceAll('_', ' ');
        field_name = field_name[0].toUpperCase() + field_name.substr(1);
        return field_name;
    }
}

export default FormsDataDictionary;
