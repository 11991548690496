import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import { format } from "date-fns";
import directus from "../../lib/lib-directus";
import Cache from "../../lib/lib-cache";
import { getCurrentClientId } from "../../lib/lib-user";
import { parseISODateWithoutTime } from "../../lib/lib-date";

export default class AppPatientCareTeamModal extends ModalBase {
    get patient() {
        return this._patient;
    }

    set patient(value) {
        this._patient = value;
        this.expandPatient();
        this.render();
    }

    get has_changes() {
        return this._has_changes;
    }

    set has_changes(value) {
        this._has_changes = !!value;
        this.render();
    }

    get editable() {
        return true;
    }

    get clinicians() {
        return this._clinicians;
    }

    set clinicians(values) {
        this._clinicians = values;
    }

    get case_managers() {
        return this._case_managers;
    }

    set case_managers(values) {
        this._case_managers = values;
    }

    set care_team(values) {
        this._care_team = values;
        this.render();
    }

    get care_team() {
        return this._care_team;
    }

    constructor() {
        super();
        this._has_changes = false;
        this._editable = false;
        this._clinicians = null;
        this._care_team = null;
        this.changes = {};
        this.patient_access_changes = {};
    }

    connectedCallback() {
        this.template = () => {
            const { has_changes } = this;

            const care_team_ids = this.care_team?.map(team_member => team_member.id) || [];
            const filtered_clinicians = this.clinicians?.filter(clinician => !care_team_ids.includes(clinician.id)) || [];

            return html`
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            ${this.patient?.first_name} ${this.patient?.last_name}'s Care Team
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col text-end">
                            </div>
                        </div>
                        <form class="needs-validation" novalidate @submit=${(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                            <div class="row">
                                <div class="col">
                                    <div class="input-group">
                                        <select id="potential-care-team-member" class="form-control">
                                            <option value="" disabled selected>Choose a clinician to add...</option>
                                            ${filtered_clinicians.length ? filtered_clinicians.map((clinician) => html`
                                            <option value="${clinician.id}">${clinician.first_name} ${clinician.last_name}</option>
                                            `) : ""}
                                        </select>
                                        <button
                                            type="button"
                                            class="btn btn-primary light-purple-btn"
                                            @click=${(e) => this.handleCareTeamAdd()}
                                        >Add</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th title="Primary Clinician">PC</th>
                                                <th title="Case Manager">CM</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        ${this.care_team?.length ? this.care_team.map((member) => html`
                                            <tr>
                                                <td>${member.first_name} ${member.last_name}</td>
                                                <td>
                                                    <input
                                                        type="radio"
                                                        name="primary_clinician"
                                                        value="${member.id}"
                                                        ?checked="${this.patient?.primary_clinician_id?.id === member.id}"
                                                        @change="${(e) => this.handlePrimaryClinicianChange(member)}"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="radio"
                                                        name="case_manager"
                                                        value="${member.id}"
                                                        ?checked="${this.patient?.case_manager_id?.id === member.id}"
                                                        @change="${(e) => this.handleCaseManagerChange(member)}"
                                                    />
                                                </td>
                                                <td>
                                                    <a href="javascript:void(0);" @click="${(e) => this.handleRemoveCareTeamMember(e, member)}">
                                                        <span class="material-symbols-outlined text-danger">delete</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        `) : ""}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary modal-cancel-btn light-purple-btn" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            ?disabled=${!has_changes}
                            type="button"
                            class="btn btn-primary btn-danger modal-confirm-btn light-purple-btn"
                            @click=${(_e) => this.handleConfirmClick()}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>`;
        }

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        this.loadClinicians();
        this.loadPatientCareTeam();

        render(this.template(), this);
    }

    async expandPatient() {
        if (
            typeof this.patient.primary_clinician_id !== "object" ||
            typeof this.patient.case_manager_id !== "object"
        ) {
            this._patient = await directus.items("patient").readOne(this._patient.id, {
                fields: [
                    '*',
                    'current_episode_id.*',
                    'client_id.*',
                    'case_manager_id.*',
                    'primary_clinician_id.*',
                ]
            });
            this.render();
        }
    }

    async handleConfirmClick() {
        try {
            if (Object.keys(this.patient_access_changes).length > 0) {
                const to_remove = [];
                const to_add = [];
                for (const id in this.patient_access_changes) {
                    const action = this.patient_access_changes[id];

                    switch (action) {
                        case "remove":
                            to_remove.push(id);
                            break;
                        case "add":
                            const access_record = this.patient_access.find((i) => i.user_id === id);
                            // It already exists, and we've staged and unstaged a remove
                            if (access_record) break;
                            to_add.push({
                                patient_id: this.patient.id,
                                user_id: id,
                            });
                            break;
                    }
                }

                if (to_remove.length) {
                    await directus.items("patient_access").deleteMany(to_remove);
                    for (const id of to_remove) {
                        const pa = this.patient_access.find((i) => i.id === id);
                        if (
                            this.patient.case_manager_id === pa?.user_id?.id ||
                            this.patient.case_manager_id?.id === pa?.user_id?.id
                        ) {
                            this.changes.case_manager_id = null;
                        }
                        if (
                            this.patient.primary_clinician_id === pa?.user_id.id ||
                            this.patient.primary_clinician_id?.id === pa?.user_id.id
                        ) {
                            this.changes.primary_clinician_id = null;
                        }
                    }
                }

                if (to_add.length)
                    await directus.items("patient_access").createMany(to_add);
            }

            // If we have any patient changes, save those.
            if (Object.keys(this.changes).length > 0) {
                if (this.changes?.primary_clinician_id?.id)
                    this.changes.primary_clinician_id = this.changes.primary_clinician_id.id;
                if (this.changes?.case_manager_id?.id)
                    this.changes.case_manager_id = this.changes.case_manager_id.id;

                const res = await directus.items("patient").updateOne(this.patient.id, this.changes, {
                    fields: [
                        '*',
                        'current_episode_id.*',
                        'client_id.*',
                    ]
                });

                this.changes = {};
            }
        } catch (err) {
            console.error(err);
            this.error_message = err.message;
            return;
        }

        // reload the patient to make sure that all
        this.patient = await Cache.getPatient(this.patient.id, true);
        this.dismiss({
            should_update: this.editable,
            patient: this.patient,
        });
    }

    async handleCareTeamAdd() {
        const id_to_add = this.querySelector("#potential-care-team-member").value;
        if (!id_to_add) return;

        this.care_team.push(this.clinicians.find((i) => i.id === id_to_add));
        this.patient_access_changes[id_to_add] = "add";
        this.hasChanges();
    }

    async handlePrimaryClinicianChange(member) {
        this.changes.primary_clinician_id = member;
        this.hasChanges();
    }

    async handleCaseManagerChange(member) {
        this.changes.case_manager_id = member;
        this.hasChanges();
    }

    /**
     * Removes the care team member
     * @param member
     * @returns {Promise<void>}
     */
    async handleRemoveCareTeamMember(e, member) {
        e.stopPropagation();
        const to_remove = this.patient_access.find((i) => member.id === i.user_id?.id);
        if (!to_remove) return;
        this.patient_access_changes[to_remove.id] = "remove";
        this.care_team = this.care_team.filter((i) => i.id !== to_remove.user_id?.id);
        this.hasChanges();
    }

    hasChanges() {
        if (
            Object.keys(this.changes).length > 0 ||
            Object.keys(this.patient_access_changes).length > 0
        ) {
            this.has_changes = true;
        } else {
            this.has_changes = false;
        }
    }

    async loadClinicians(force) {
        if (force !== true && this.clinicians) return;
        // only load once unless force is set to true
        try {
            const client_id = typeof this.patient.client_id === "string" ? this.patient.client_id : this.patient.client_id.id;
            const res = await directus.users.readByQuery({
                filter: {
                    client_access: {
                        client_id: client_id,
                    },
                    role: {
                        name: {
                            _in: ["Clinician", "Client"],
                        }
                    }
                },
                fields: ["*", "role.*", "client_access.*"],
            });

            const clinicians = [];
            const case_managers = [];

            for (const item of res.data) {
                clinicians.push(item);
                case_managers.push(item);
            }

            this.clinicians = clinicians;
            this.case_managers = case_managers;

            this.render();
        } catch (err) {
            console.error(err);
            this.clinicians = [];
        }
    }

    async loadPatientCareTeam(force) {
        if (!force && this.care_team) return;
        try {
            const res = await directus.items("patient_access").readByQuery({
                filter: {
                    patient_id: this.patient.id,
                },
                fields: ["id", "user_id.*"],
            });

            const client_id = getCurrentClientId();
            this.patient_access = res.data;
            this.care_team = res.data.map((i) => i.user_id);
        } catch (err) {
            console.error(err);
            this.care_team = [];
        }
    }
}

customElements.define("app-patient-care-team-modal", AppPatientCareTeamModal);
