import { html, render } from 'lit';
import AppFilterUtil from './util/app-filter-util';
import directus from '../../lib/lib-directus';
import './app-filter-table';
import './app-filter-bar-graph';
import './app-filter-big-number';
import ApplicationState from 'applicationstate';

const PAGE_SIZE = 100000;

class AppFilterDataView extends HTMLElement {
    constructor() {
        super();
        this.page = 0;
        this._filter_state = {
            group: { type: "_and", groups: [] },
            user_search: ''
        };
        this.eventHandlerMap = {
            "drilldown": this.handleDrilldown.bind(this),
            "page-change": this.handlePageChange.bind(this),
            "add-column": this.handleColumnChange.bind(this),
            "remove-column": this.handleColumnChange.bind(this),
            "reorder-column": this.handleColumnChange.bind(this),
        };
    }
    get status_filter() {
        return this._status_filter;
        //console.log('status_filter ggetter', value);
    }

    set status_filter(value) {
        this._status_filter = value;
        //console.log('status_filter setter', value);
    }
    set filter_state(value) {
        this._filter_state = value;
        this.load();

    }

    get filter_state() {
        if (!this._filter_state) {
            this._filter_state = { group: { type: "_and", groups: [] }, user_search: '' };
        }

        if (this.additional_filters) {
            let result = structuredClone(this._filter_state);
            result.group.groups.push(this.additional_filters);
            return result;
        }

        return this._filter_state;
    }

    set result(value) {
        this._result = value;
    }

    get result() {
        return this._result || {};
    }

    set additional_filters(value) {
        this._additional_filters = value;
    }

    get additional_filters() {
        return this._additional_filters;
    }

    get data() {
        return this._result?.data || [];
    }

    set config(value) {
        this._config = value;
    }

    get config() {
        return this._config;
    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    get selected_columns() {
        return ApplicationState.get(`app.${this.config.collection.name}_selected_columns`) || this._selected_columns;
    }

    set table_columns(value) {
        this._table_columns = value;
    }

    get table_columns() {
        return ApplicationState.get(`app.${this.config.collection.name}_table_columns`) || this._table_columns;
    }

    set options(value) {
        this._options = value;
    }

    get options() {
        return this._options;
    }

    set selected_data(value) {
        this._selected_data = value;
        this.render();
    }

    get selected_data() {
        return this._selected_data;
    }

    set selection_type(value) {
        this._selection_type = ['none', 'single', 'multiple'].includes(value) ? value : 'none';
    }

    get selection_type() {
        return this._selection_type;
    }

    connectedCallback() {
        this.template = () => {
            const { drilldown, filter_state, data } = this;
            const aggregate = filter_state?.aggregate;

            if (!drilldown && aggregate?.length) {
                return aggregate?.aggregate_group?.length ?
                    this.renderBarGraph(data, filter_state) :
                    this.renderBigNumber(data, filter_state);
            } else {
                return this.renderDetailsTable();
            }
        };
    }

    renderBarGraph(data, filter_state) {
        return html`
            <div style="display: flex; align-items: center; justify-content: center">
                <app-filter-bar-graph 
                    style="height: 400px; width: 650px;" 
                    @drilldown=${e => this.handleEvent('drilldown', e.detail)}
                    .data=${data} .filter_state=${filter_state}
                ></app-filter-bar-graph>
            </div>
        `;
    }

    renderBigNumber(data, filter_state) {
        return html`
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
                <app-filter-big-number 
                    style="height: 250px; width: 350px;" 
                    @drilldown=${e => this.handleEvent('drilldown', e.detail)}
                    .data=${data}
                    .filter_state=${filter_state}
                ></app-filter-big-number>
            </div>
        `;
    }

    renderDetailsTable() {
        return html`
            ${this.drilldown ? html`
                <div class="badge rounded-pill" style="
                    border: 1px solid var(--t-color-primary);
                    padding: 5px;
                    width: fit-content;
                    display: flex; 
                    flex-direction: row; 
                    margin-bottom: 10px;
                    align-items: center;">
                    <span
                        @click=${async (e) => {
                    this.drilldown = false;
                    this.drilldown_filter_state = null;
                    await this.load();
                }}
                        class="material-symbols-outlined"
                        style='
                            font-size: 20px;
                            cursor: pointer;
                            margin-right: 20px;
                            color: var(--t-color-primary);
                            font-variation-settings: "wght" 700, "GRAD" 0, "opsz" 48;
                        '>arrow_back</span>
                        <span style="color: var(--t-color-dark); font-size: 16px; font-weight: 700; margin-right: 10px;">Drilldown</span>
                        <span style="color: var(--t-color-dark); font-size: 14px; font-weight: 500;">
                            ${this.filter_state.aggregate[0].aggregate_function} of ${this.filter_state.aggregate[0].field_config.label}
                        </span>
                </div>
            ` : ''}
    
            <app-filter-table
                @page-change=${e => this.handleEvent('page-change', e.detail)}
                @add-column=${e => this.handleEvent('add-column', e.detail)}
                @remove-column=${e => this.handleEvent('remove-column', e.detail)}
                @reorder-column=${e => this.handleEvent('reorder-column', e.detail)}
                .page=${this.page}
                .collection_config=${this.config.collection}
                .selected_columns=${this.selected_columns}
                .filter_state=${this.filter_state}
                .table_columns=${this.table_columns}
                .status_filter=${this.status_filter}
                .selected_data=${this.selected_data}
                .selection_type=${this.selection_type}
                .result=${this.result}
                .options=${this.options}
            ></app-filter-table>
            
        `;
    }

    async handleEvent(eventType, detail) {
        const handler = this.eventHandlerMap[eventType];
        if (handler) {
            await handler.call(this, detail);
        } else {
            switch (eventType) {
                case "drilldown":
                    await this.handleDrilldown(detail);
                    break;
                case "page-change":
                    await this.handlePageChange(detail);
                    break;
                case "add-column":
                case "remove-column":
                case "reorder-column":
                    ApplicationState.set(`app.${this.config.collection.name}_selected_columns`, detail, { immutable: true });
                    await this.load();
                    break;
            }
        }
    }

    async handleDrilldown(filter_state) {
        this.drilldown = true;
        this.drilldown_filter_state = filter_state;
        await this.config.collection.configured;
        let query = AppFilterUtil.getQuery(this.config, filter_state);
        await this.load(query);
    }

    async handlePageChange(page) {
        this.page = page;
        await this.load();

        const filterTableObj = document.querySelector('app-filter-table');
        filterTableObj?.refreshTableData();
    }

    async handleColumnChange(detail) {
        const { collection } = this.config;
        ApplicationState.set(`app.${collection.name}_selected_columns`, detail, { immutable: true });
        await this.load();
    }

    async load(query) {
        if (this._loading)
            return;

        this._loading = false;

        await this.config.collection.configured;

        if (!query)
            query = AppFilterUtil.getQuery(this.config, this.filter_state);

        //for each path type column, make sure the id of the related entity is always present
        let id_columns = [];
        for (let column of this.selected_columns) {
            if (!column.includes('.'))
                continue;
            let parts = column.split('.');
            parts.pop();
            let id_column = parts.join('.') + ".id";
            if (!this.selected_columns.includes(id_column))
                id_columns.push(id_column);
        }
        query = Object.assign(query,
            {
                //ensure id is always selected
                fields: [...new Set(['id', 'client_id.id', ...id_columns, ...this.selected_columns])],
                meta: "filter_count",
                offset: this.page ? (PAGE_SIZE * this.page) : 0,
                limit: PAGE_SIZE,
            });

        ApplicationState.set(`app.${this.config.collection.name}_relation_query`, query, { immutable: true });
        let result;
        try {
            result = await directus.items(this.config.collection.name).readByQuery(query);
        }
        catch (err) {
            console.error(err);
        }
        this.dispatchEvent(new CustomEvent("data", { composed: true, bubbles: true, detail: result.data }));
        this.result = result;
        this.filter_count = result?.meta?.filter_count || 0;
        this._loading = false;

        this.render();
    }

    refreshTableData() {
        const filterTableComponent = this.querySelector('app-filter-table');
        if (filterTableComponent) {
            filterTableComponent?.refreshTableData();
        }
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

}

customElements.define('app-filter-data-view', AppFilterDataView);
export default AppFilterDataView;
