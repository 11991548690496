import { html, render } from "lit";
import '../util/app-nav-links';

/**
 * Shared header for the 'Avoidable Events' sidebar nav item.
 * Auto-selects the appropriate sub-nav item for the current route.
 */
export default class AppAvoidableEventsHeader extends HTMLElement {
    set page(value) {
        this._page = value;
        this.render();
    }

    get page() {
        return this._page;
    }

    get links() {
        return [
            { page: "overview", url: "avoidable-events/overview", title: "Impact Overview" },
            { page: "metrics", url: "avoidable-events/metrics", title: "Event Metrics" },
        ];
    }

    constructor() {
        super();
        // re-render when location changes for nav highlight
        window.addEventListener("popstate", this.render.bind(this));
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    app-avoidable-events-header .settings-label {
                        font-weight: 600; 
                        margin-left: 4px;
                        height: 22px;
                        padding-top: 2px;
                    }
                    app-avoidable-events-header .settings-link {
                        display: flex;
                    }
                    app-avoidable-events-header .nav-link:hover .settings-label {
                        border-bottom: 2px solid var(--t-color-primary);
                    }

                    @media (max-width: 768px) {
                        #links_full {
                            display: none;
                        }

                        #links_mobile {
                            display: flex;
                        }
                        app-avoidable-events-header .settings-link {
                            display: none;
                        }
                        app-avoidable-events-header h1 {
                            overflow: hidden;
                            text-overflow: clip;
                            white-space: nowrap;
                            word-break: keep-all;
                            width: 272px;
                        }
                    }
                    @media (min-width: 768px) {
                        #links_full {
                            display: flex;
                        }

                        #links_mobile {
                            display: none;
                        }
                    }
                </style>
                <div
                    style="
                    position: relative; 
                    background-color: #fff; 
                    height: 125px; 
                    box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                    border-radius: 8px;"
                >
                    <div style="display: flex; justify-content: space-between;">
                        <h1
                            style="
                            font-style: normal;
                            font-weight: 700;
                            font-size: 30px;
                            line-height: 32px;
                            letter-spacing: 0.5px;
                            color: #131313;
                            margin: 24px 0 0 24px;
                        ">
                            <span class="material-symbols-outlined" style="margin-right: 5px">attach_money</span>
                            Avoidable Events Impact Dashboard
                        </h1>
                        <a
                            href=${"settings/financial-impact"}
                            class="nav-link settings-link"
                            style="
                                align-items: center;
                                color: var(--t-color-primary);
                                font-variation-settings: 'opsz' 16;
                                font-size: 14px;
                                border-radius: 0;
                                height: 48px;
                                padding: 14px 24px;
                                margin: unset;
                                position: absolute;
                                right: 0;
                            "
                        >
                            <span class="material-symbols-outlined" style="width: 24px;">
                                settings
                            </span>
                            <span class="settings-label">
                                Impact Settings
                            </span>
                        </a>
                    </div>
                    <app-nav-links .links=${this.links} .page=${this.page}></app-nav-links>
                </div>
            `;
        };

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-avoidable-events-header", AppAvoidableEventsHeader);
