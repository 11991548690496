import { addHours, format, isValid, parseISO, formatISO } from "date-fns";
import { DateTime } from "luxon";
import { toZonedTime } from 'date-fns-tz';
//import { toZonedTime, fromZonedTime } from 'date-fns-tz';
//*PA removd superflous "sun" from abbreviated_day_text
const abbreviated_day_text = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
window.luxon = { DateTime };

/**
 * Parses a date from an ISO string, ignoring time and zone.
 * @param {string} date_string an ISO date string e.g. '2022-09-09T00:00:00.000Z'
 * @returns {Date} a Date in the user's timezone with the same day as date_string
 */
export function parseISODateWithoutTime(date_string) {
    const year = date_string.substring(0, 4);
    const month = date_string.substring(5, 7);
    const day = date_string.substring(8, 10);
    return new Date(year, month - 1, day);
}
/* export function parseISODateWithoutTime(date_string) {
    const parts = date_string.split('-');
    if (parts.length !== 3) return null;

    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-based
    const day = parseInt(parts[2], 10);

    return new Date(year, month, day);
} */
/**
 * Parses a date from an ISO string, ignoring time and zone.
 * @param {Date} date 
 * @returns {string} a shortened ISO date string e.g. '2022-09-09'
 */
//*PA commented out to make intended date as appointments scheduled at midnight are technically the previous day
/* export function getISODateStringWithoutTime(date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const month_string = month < 10 ? "0" + month : "" + month;
    const day_string = day < 10 ? "0" + day : "" + day;

    return `${date.getFullYear()}-${month_string}-${day_string}`
} */
//export function getISODateStringWithoutTime(date) {
//    return date.toISOString().split('T')[0];
//}
export function getISODateStringWithoutTime(dateInput) {
    const date = new Date(dateInput);
    if (isNaN(date.getTime())) {
        return "(Invalid date)";  // Return this if the input is not a valid date
    }
    return date.toISOString().split('T')[0];  // Returns the YYYY-MM-DD part only
}
/**
 * @param {Date} date 
 * @returns {string} abbreviated day of the week for the given day e.g. Mon
 */
export function getAbbreviatedDayText(date) {
    return abbreviated_day_text[date.getDay()];
}

/**
 * @param {Date} date 
 * @returns {Date} date with timezone offset removed, which will yield UTC time for the given date
 */
export function unsetTimezone(date) {
    return date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}
/* export function dateSorter(a, b) {

  const valA = a.getValue(); // "03/12/2023 12:00 AM"
  const valB = b.getValue();

  const dtA = DateTime.fromFormat(valA, 'MM/dd/yyyy h:mm a');
  const dtB = DateTime.fromFormat(valB, 'MM/dd/yyyy h:mm a');

  return dtA - dtB;

} */
export function formatDateOrNA(dateString) {
    if (!dateString) return 'N/A';

    let date;

    // Check if the dateString is date-only or time is 00:00:000
    const hasTime = dateString.includes('T') || dateString.includes(' ');
    const isMidnight = dateString.includes('T00:00:00') || dateString.endsWith(' 00:00:00');

    if (!hasTime || isMidnight) {
        // Handle date-only string or midnight time (assumes YYYY-MM-DD format for date-only)
        const datePart = dateString.split('T')[0].split(' ')[0];
        const [year, month, day] = datePart.split('-');
        date = new Date(year, month - 1, day);
    } else {
        // Handle full date-time string
        date = new Date(dateString);
    }

    return isValid(date) ? format(date, 'yyyy-MM-dd HH:mm:ss') : 'N/A';
}

/** Returns sortable dates for tabulator */
export function dateFormatter(cell) {
    const rawTime = cell.getValue();
    if (!rawTime) {
        return "(no date provided)";
    }

    let dt = luxon.DateTime.fromISO(rawTime);  // initialize dt using fromISO as an example

    const formats = [
        "yyyy-MM-dd'T'HH:mm:ss.SSSZZ",
        "yyyy-MM-dd'T'HH:mm:ss.SSZZ",
        "yyyy-MM-dd'T'HH:mm:ss.SZZ",
        "yyyy-MM-dd'T'HH:mm:ssZZ",
        "yyyy-MM-dd'T'HH:mm:ss",
    ];

    if (!dt.isValid) {
        for (let format of formats) {
            dt = luxon.DateTime.fromFormat(rawTime, format);
            if (dt.isValid) break;
        }
    }

    if (dt.isValid) {
        // Check if the time is 00:00:000
        if (dt.hour === 0 && dt.minute === 0 && dt.second === 0 && dt.millisecond === 0) {
            return dt.toFormat('MM/dd/yyyy');  // Only display the date
        } else {
            return dt.toFormat('MM/dd/yyyy h:mm a');  // Display both date and time
        }
    } else {
        return "(invalid date)";
    }
}

export function formatToLocalTime(utcDate) {
    const localDate = new Date(utcDate + 'Z'); // Ensure UTC format
    return format(localDate, "yyyy-MM-dd HH:mm:ss");
}


export function convertUtcToLocalTime(dateString) {
    const d = new Date(dateString);
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
}

//*PA added to convert UTC to local time *** This seems to be the most consistent ///

export function convertUtcToLocal(dateString) {
    const utcDate = new Date(dateString);
    // Get the local time zone offset in minutes
    const timezoneOffset = utcDate.getTimezoneOffset();
    // Convert the offset to milliseconds and subtract it from the UTC time
    const localDate = new Date(utcDate.getTime() - (timezoneOffset * 60000));
    return localDate;
}

export function averageDate(dateStr1, dateStr2) {
    // Parse the dates
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    // Convert to milliseconds
    const time1 = date1.getTime();
    const time2 = date2.getTime();

    // Calculate the average
    const avgTime = (time1 + time2) / 2;

    // Convert back to a date
    const avgDate = new Date(avgTime);

    return avgDate;
}

export default {
    parseISODateWithoutTime,
    getISODateStringWithoutTime,
    unsetTimezone,

}