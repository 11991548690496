import { Modal } from 'bootstrap';

/**
 * A base class that wraps basic boostrap functionality around a modal
 * As this adds css class="modal", the outer div in Bootstrap modal examples must be
 * omitted for this to work.
 */
class CommModalBase extends HTMLElement {
    /**
     * Set up promises and such...
     */
    constructor() {
        super();

        this.classList.add("modal");
        this.classList.add("fade");
        this.setAttribute("aria-hidden", "true");
        this.setAttribute("tabindex", "-1");

        this.addEventListener("shown.bs.modal", () => {
            if (this._showing_promise) this._resolveShowing();
        });

        this.addEventListener("hidden.bs.modal", () => {
            if (this._bootstrap_modal_instance) this._bootstrap_modal_instance.dispose();

            document.body.removeChild(this);

            if (this._on_did_dismiss_promise) this._resolveDismiss(this._data);
        });
    }

    /**
     * Show the modal, returning a promise when all animation has completed
     * @param {*} modal_options
     * @returns
     */
    async showModal() {
        if (this._showing_promise) return;

        document.body.appendChild(this);

        // Set the options directly here
        const modalOptions = {
            backdrop: 'static', // prevent modal from closing when clicking outside
            keyboard: false     // prevent modal from closing with the ESC key
        };

        this._bootstrap_modal_instance = new Modal(this, modalOptions);
        this._showing_promise = new Promise((resolve, reject) => {
            this._resolveShowing = resolve;
            this._rejectShowing = reject;
            this._bootstrap_modal_instance.show();
        });

        return this._showing_promise;
    }


    /**
     * Hide the modal, returning a promise that resolves with optional data
     * @param {*} data
     * @returns
     */
    async dismiss(data) {
        this._data = data;
        this._bootstrap_modal_instance.hide();

        return this._on_did_dismiss_promise;
    }

    /**
     * Returns a promise that can be awaited from a parent component waiting for the modal to close
     * Resolves with optional data
     */
    onDidDismiss() {
        this._on_did_dismiss_promise = new Promise((resolve, reject) => {
            this._resolveDismiss = resolve;
            this._rejectDismiss = reject;
        });
        return this._on_did_dismiss_promise;
    }
}

export default CommModalBase;
