import { html, render } from "lit";
import { easepick, LockPlugin } from '@easepick/bundle';
import ModalBase from './util/modal-base';
import { getISODateStringWithoutTime } from "../lib/lib-date";
import { getCurrentClientId } from "../lib/lib-user";
import directus from "../lib/lib-directus";

/**
 * @param {object} patient
 */
export default class AppTaskAdHocModal extends ModalBase {
    constructor() {
        super();
        this.form_design_options = [];
        this.form_design_id = null;
        this.description = null;
        this.scheduled_date = null;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #deactivate-patient-modal .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    #deactivate-patient-modal .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    #deactivate-patient-modal select {
                        cursor: pointer;
                    }
                    #deactivate-patient-modal .input-group:last-of-type {
                        margin-top: 12px;
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered" id="deactivate-patient-modal">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Schedule Ad Hoc Task</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="deactivate_patient_form" novalidate>
                            <div class="modal-body">
                                <div class="input-wrapper mb-3">
                                    <label for="title_input" class="form-label">Task Title</label>
                                    <input readonly disabled value="PRN" id="title_input" class="form-control" aria-label="Task Title"/>
                                </div>
                                <div class="input-wrapper mb-3">
                                    <label for="date_input" class="form-label">Scheduled Date</label>
                                    <input @focus=${e => e.target.blur()} id="date_input" class="form-control" style="cursor: pointer;" aria-label="Scheduled Date" required/>
                                    <div class="invalid-feedback">
                                        Please select a date.
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="form_select" class="form-label">Path Selection</label>
                                    <select @change=${e => this.handleFormDesignChange(e)} id="form_select" class="form-select" aria-label="Form Design" required>
                                        <option selected></option>
                                        ${this.form_design_options.map(form_design => html`
                                            <option value=${form_design.id}>${form_design.title}</option>
                                        `)}
                                    </select>
                                    <div class="invalid-feedback">
                                        Please select a form design.
                                    </div>
                                </div>
                                <div class="input-wrapper">
                                    <label for="description_textarea" class="form-label">Description</label>
                                    <textarea @change=${e => this.handleDescriptionChange(e)} id="description_textarea" class="form-control" aria-label="Description" required></textarea>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary modal-confirm-btn"
                                    style="color: white;"
                                    id="confirm-button"
                                    @click=${(_e) => this.handleConfirmClick()}>
                                    Confirm
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            `;
        };
        this.render();
        this.addEventListener("shown.bs.modal", this.init);
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    async init() {
        this.datepicker = new easepick.create({
            element: this.querySelector('#date_input'),
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            ],
            readonly: false,
            firstDay: 0,
            date: this.date,
            plugins: [LockPlugin],
            LockPlugin: {
                minDate: new Date(),
            }
        });
        this.datepicker.on("select", this.handleDateChange.bind(this));

        if (this.date) {
            this.scheduled_date = getISODateStringWithoutTime(this.date) + " 12:00:00";
        }

        const forms_result = await directus.items("form_design").readByQuery({
            filter: {
                category: "prn",
                _or: [
                    { client_id: { _null: true } },
                    { client_id: getCurrentClientId() }
                ],
                status: "published",
            }
        })
        this.form_design_options = forms_result.data;
        this.render();
    }

    async handleConfirmClick() {
        const form_element = this.querySelector('#deactivate_patient_form');
        form_element.classList.add('was-validated');
        const valid = form_element.checkValidity();
        if (valid) {
            const survey = await directus.items("hh_patient_reported_measures").createOne({
                client_id: this.patient.client_id,
                patient_id: this.patient.id,
                episode_id: this.patient.current_episode_id,
                status: "draft",
                survey_date: this.scheduled_date,
            });

            const task = await directus.items("task").createOne({
                assigned_user_id: this.patient.engagement_specialist,
                client_id: this.patient.client_id,
                episode_id: this.patient.current_episode_id,
                patient_id: this.patient.id,
                survey_id: survey.id,
                form_design_id: this.form_design_id,
                scheduled_date: this.scheduled_date,
                completion_risk: 5,
                status: "active",
                survey_type: "hh_patient_reported_measures",
                task_type: "prn",
                title: "PRN",
                description: this.description,
                is_prn_checkpoint: true,
            });
            this.dismiss({ confirmed: true, task });
            document.querySelector("app-toaster").toast("Task created successfully.")
        }
    }

    handleDateChange(e) {
        const date = e.detail.date;
        // task scheduled dates do not have timezone
        this.scheduled_date = getISODateStringWithoutTime(date) + " 12:00:00";
        console.log(this.scheduled_date);
    }

    handleDescriptionChange(e) {
        this.description = e.target.value;
    }

    handleFormDesignChange(e) {
        this.form_design_id = e.target.value;
    }
}

customElements.define("app-task-ad-hoc-modal", AppTaskAdHocModal);
