import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import AppFilterGroup from './app-filter-group';
import AppFilterSort from './app-filter-sort';

export default class AppFilterAdvancedModal extends ModalBase {

    set config(value) {
        this._config = value;
        this.render();
    }

    get config() {
        return this._config;
    }

    set sort(value) {
        this._sort = value;
        this.render();
    }

    get sort() {
        return this._sort;
    }

    set group(value) {
        this._group = value;
        this.render();
    }

    get group() {
        return this._group;
    }

    set disable_sort(value) {
        this._disable_sort = value;
        this.render();
    }

    get disable_sort() {
        return this._disable_sort;
    }

    connectedCallback() {
        // Add event listener for Bootstrap modal hidden event
        this.addEventListener('hidden.bs.modal', () => {
            // If modal was closed without calling dismiss (i.e. via close button or Cancel),
            // call dismiss with current values to prevent undefined
            if (!this._data) {
                this.dismiss({ sort: this._sort, group: this._group });
            }
        });

        this.template = () => {
            return html`
                <style>
                </style>
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Advanced Filters</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            
                            <div class="app-filters-container">
                                ${this.disable_sort ? null : html`
                                    <div class="app-filters-container-label app-sort">SORT</div>
                                    <app-filter-sort .collection_config=${this.config.collection} .sort=${this.sort}></app-filter-sort>
                                `}
                                
                                <app-filter-group .show_group_label=${false} .collection_config=${this.config.collection} .group=${this.group} .show_group_delete=${false}></app-filter-group>
                                    
                            </div>
                        </div>
                        <div class="modal-footer" style="justify-content: space-between;">
                            <button id="app-filters-clear" @click=${e => {
                    this.resetFilters();
                    this.render();
                }}
                                class="btn clear-filters dark-red-btn" style="">
                               
                                    <span style="font-size: 11px; color: #fff; align-self: left">
                                    CLEAR FILTERS
                                    </span>
                            </button>
                            <button type="button" class="btn btn-secondary light-gray-btn modal-cancel-btn" @click=${() => this.handleCancelClick()} style="margin-left:auto;">
                                Cancel
                            </button>
                            <button
                                type="button"
                                class="btn btn-secondary light-purple-btn modal-confirm-btn"
                                @click=${() => this.handleConfirmClick()}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    resetFilters() {
        this._group = {
            type: "_and",
            filters: [],
            groups: []
        }
        this._sort = [];
        this.render();
    }

    /**
     * Click handler for 'Cancel' button.
     * Dismisses with current values to prevent undefined
     */
    async handleCancelClick() {
        this.dismiss({ sort: this._sort, group: this._group });
    }

    /**
     * Click handler for 'Confirm' button.
     * Gets current values from elements and dismisses with them
     */
    async handleConfirmClick() {
        let sort_element = this.querySelector("app-filter-sort");
        let group_element = this.querySelector("app-filter-group");
        this.dismiss({ sort: sort_element.sort, group: group_element.group });
    }
}

customElements.define("app-filter-advanced-modal", AppFilterAdvancedModal);
