import { html, render } from "lit";
import { Directus } from '@wbce-d9/sdk';
import directus from "../lib/lib-directus";
import ModalBase from "./util/modal-base";
import AppScheduleSelect from "./app-schedule-select";
import { getISODateStringWithoutTime } from "../lib/lib-date";
import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";


export default class AppActivateScheduleModal extends ModalBase {
    get patients() {
        return this._patients;
    }

    set patients(value) {
        if (value !== this._patients) {
            this._patients = value;
            console.log("Patients set in patient schedule modal", value);
            this.init();
        }
    }

    get default_sms_time() {
        return this._default_sms_time;
    }

    set default_sms_time(value) {
        if (value !== this._default_sms_time) {
            this._default_sms_time = value;
            this.init();
        }
    }

    get default_timezone() {
        return this._default_timezone;
    }

    set default_timezone(value) {
        if (value !== this._default_timezone) {
            this._default_timezone = value;
            this.init();
        }
    }

    constructor() {
        super();
        this._patient_clients = new Set();
        this.engagement_specialists = [];
        this._selectedScheduleId = '';
        this._selectedScheduleType = '';
        this.survey_schedules = [];
        this.scheduleStartOptions = [
            { value: 'episode_start', label: 'From Episode Start' },
            { value: 'episode_end', label: 'From Episode End' },
            { value: 'today', label: 'From Today' },
            { value: 'birth_date', label: 'From DOB 🎂' }
        ];
        this.scheduleStartOption = 'episode_start';
        this.error_message = '';
        this.calculatedDates = new Map(); // Store calculated dates for each patient
    }

    renderScheduleTypeBadge(type) {
        const typeColors = {
            'episode': 'primary',
            'patient': 'success'
        };
        const color = typeColors[type] || 'secondary';
        return html`
            <span class="badge bg-${color} schedule-type-badge">
                ${type}
            </span>
        `;
    }

    handleScheduleSelect(schedule, patientId) {
        this._selectedScheduleId = schedule.id;
        this._selectedScheduleType = schedule.type || 'episode';
        const input = this.querySelector(`#schedule-${patientId}`);
        if (input) {
            input.value = schedule.id;
            this.updateCalculatedDates();
            this.render();
        }
    }

    async validateScheduleStart(patient, startType) {
        switch (startType) {
            case 'birth_date':
                const patientData = await directus.items("patient").readOne(patient.id, {
                    fields: ['birth_date']
                });
                if (!patientData?.birth_date) {
                    throw new Error(`Cannot schedule from birth date: Birth date not available for patient ${patient.first_name} ${patient.last_name}`);
                }
                return patientData.birth_date;

            case 'episode_start':
            case 'episode_end':
                const episode = await directus.items("episode").readByQuery({
                    filter: {
                        patient_id: patient.id,
                        status: 'current'
                    },
                    fields: ['id', 'start_date', 'end_date']
                });

                if (!episode.data || episode.data.length === 0) {
                    throw new Error(`Cannot schedule from episode: No current episode found for patient ${patient.first_name} ${patient.last_name}`);
                }

                const dateField = startType === 'episode_start' ? 'start_date' : 'end_date';
                if (!episode.data[0][dateField]) {
                    throw new Error(`Cannot schedule from episode: ${dateField.replace('_', ' ')} not available for patient ${patient.first_name} ${patient.last_name}`);
                }

                // Store episode ID for later use
                patient.current_episode_id = episode.data[0].id;
                return episode.data[0][dateField];

            case 'today':
                return new Date().toISOString();

            default:
                throw new Error('Invalid start type selected');
        }
    }

    async calculateScheduleDates(patient, startType) {
        try {
            // Get the start date based on the selected type
            const startDate = await this.validateScheduleStart(patient, startType);

            // Get schedule items to calculate end date
            const scheduleItems = await directus.items("survey_schedule_item")
                .readByQuery({
                    filter: { survey_schedule_id: this._selectedScheduleId },
                    fields: ['day']
                });

            if (!scheduleItems?.data?.length) {
                throw new Error('No schedule items found for selected schedule');
            }

            // Calculate end date based on the last schedule item
            const maxDays = Math.max(...scheduleItems.data.map(item => item.day));
            const endDate = DateTime.fromISO(startDate).plus({ days: maxDays }).toISO();

            return {
                startDate,
                endDate,
                episodeId: patient.current_episode_id
            };
        } catch (error) {
            console.error('Error calculating schedule dates:', error);
            throw error;
        }
    }

    async updateCalculatedDates() {
        this.calculatedDates.clear();

        for (const patient of this.patients) {
            const startTypeInput = this.querySelector(`#schedule-start-${patient.id}`);
            if (startTypeInput && this._selectedScheduleId) {
                try {
                    const dates = await this.calculateScheduleDates(patient, startTypeInput.value);
                    this.calculatedDates.set(patient.id, dates);
                } catch (error) {
                    console.error(`Error calculating dates for patient ${patient.id}:`, error);
                    this.calculatedDates.set(patient.id, { error: error.message });
                }
            }
        }

        this.render();
    }

    async handleActivateClick() {
        try {
            this.error_message = '';
            this.querySelector(".modal-confirm-btn").disabled = true;

            const results = [];
            for (const patient of this.patients) {
                try {
                    const specialist_id = this.querySelector("#specialist-" + patient.id).value;
                    const selectedScheduleId = this._selectedScheduleId;
                    const startType = this.querySelector("#schedule-start-" + patient.id).value;

                    if (!specialist_id || !selectedScheduleId || !startType) {
                        throw new Error(`Please select all required fields for patient ${patient.first_name} ${patient.last_name}`);
                    }

                    // Get calculated dates
                    const dates = this.calculatedDates.get(patient.id);
                    if (!dates || dates.error) {
                        throw new Error(dates?.error || 'Failed to calculate schedule dates');
                    }

                    // Get selected schedule for tags
                    const selectedSchedule = this.survey_schedules.find(s => s.id === selectedScheduleId);
                    if (!selectedSchedule) {
                        throw new Error('Selected schedule not found');
                    }

                    // Update engagement specialist
                    await directus.items('patient').updateOne(patient.id, {
                        engagement_specialist: specialist_id
                    });

                    // Generate UUID for patient schedule
                    const scheduleId = uuid();

                    // Create patient schedule record
                    const scheduleData = {
                        id: scheduleId,  // Include the generated UUID
                        patient_id: patient.id,
                        status: 'active',
                        client_id: patient.client_id,
                        survey_schedule_id: selectedScheduleId,
                        type: selectedSchedule.type || 'episode',
                        tags: selectedSchedule.tags,
                        start_point: startType,
                        episode_id: dates.episodeId,
                        calculated_start_date: dates.startDate,
                        calculated_end_date: dates.endDate,
                        default_timezone: this.default_timezone,
                        default_sms_time: this.default_sms_time
                    };

                    // Log schedule creation details
                    console.log('Creating schedule with data:', {
                        id: scheduleId,
                        schedule_id: selectedScheduleId,
                        type: selectedSchedule.type,
                        tags: selectedSchedule.tags,
                        episode_id: dates.episodeId
                    });

                    await directus.items('patient_schedules').createOne(scheduleData);

                    results.push({
                        patient_id: patient.id,
                        success: true,
                        schedule_type: selectedSchedule.type,
                        message: `Schedule assigned successfully`
                    });

                } catch (error) {
                    console.error(`Error processing patient ${patient.id}:`, error);
                    results.push({
                        patient_id: patient.id,
                        success: false,
                        error: error.message,
                        schedule_type: 'unknown'
                    });
                }
            }

            const total = results.length;
            const success = results.filter(r => r.success).length;
            const error = total - success;

            if (error > 0) {
                this.message = `Schedules activated: ${success} of ${total} successful, ${error} failed`;
                document.querySelector("app-toaster").toast(this.message, "warning");
                console.error('Errors in schedule activation:', results.filter(r => !r.success));
            } else {
                this.message = html`✅ Schedule Applied Successfully <span>${total} patients updated</span>`;
                document.querySelector("app-toaster").toast(
                    `✅ Schedule applied successfully! ${total} patients updated`,
                    "success",
                    {
                        duration: 400,
                        position: "middle-center",
                        className: "schedule-toast animate-in"
                    }
                );
            }

            this.dismiss({ confirmed: true, message: this.message, results });

        } catch (error) {
            console.error('Error in schedule activation:', error);
            this.error_message = error.message;
            document.querySelector("app-toaster").toast(error.message, "error");
            this.querySelector(".modal-confirm-btn").disabled = false;
            this.render();
        }
    }

    handleEngagementSpecialistBulkAssign(e) {
        const specialist_id = e.target.value;
        const specialist = this._engagement_specialist_dictionary[specialist_id];
        const specialist_client_ids = new Set(specialist.client_access.map(item => item.client_id));
        this.patients.forEach(patient => {
            const patient_client_id = patient.client_id?.id || patient.client_id;
            const patient_specialist_input = this.querySelector("#specialist-" + patient.id);
            if (specialist_client_ids.has(patient_client_id)) {
                patient_specialist_input.value = specialist_id;
            }
        });
        this.render();
    }

    handleScheduleStartBulkAssign(e) {
        const startType = e.target.value;
        this.scheduleStartOption = startType;
        this.patients.forEach(patient => {
            const patientScheduleStartInput = this.querySelector("#schedule-start-" + patient.id);
            if (patientScheduleStartInput) {
                patientScheduleStartInput.value = startType;
            }
        });
        this.updateCalculatedDates();
        this.render();
    }

    formatDate(date) {
        if (!date) return '';
        try {
            return DateTime.fromISO(date).toFormat('MM/dd/yyyy');
        } catch (error) {
            console.error('Error formatting date:', error);
            return '';
        }
    }

    async init() {
        this._patient_clients = new Set();
        this.patients.forEach(patient => this._patient_clients.add(patient.client_id?.id || patient.client_id));

        const engagement_specialist_data = await directus.items("directus_users").readByQuery({
            fields: ["id", "first_name", "last_name", "client_access.client_id"],
            filter: {
                client_access: {
                    client_id: {
                        _in: Array.from(this._patient_clients)
                    }
                },
                role: {
                    name: {
                        _in: ["Survey Manager", "Survey Team", "Agency Admin", "Engagement Specialist"]
                    }
                }
            },
            sort: "last_name"
        });

        const survey_schedule_data = await directus.items("survey_schedule").readByQuery({
            fields: ["id", "name", "client_id", "type", "tags"],
            filter: {
                status: "published",
                _or: [
                    { client_id: { _in: Array.from(this._patient_clients) } },
                    { client_id: { _null: true } },
                ]
            },
            sort: "name",
        });

        this.engagement_specialists = engagement_specialist_data.data;
        this.survey_schedules = survey_schedule_data.data;

        this._engagement_specialist_dictionary = {};
        this._survey_schedule_dictionary = {};
        this._client_engagement_specialist_ids = {};

        this.engagement_specialists.forEach(specialist => {
            this._engagement_specialist_dictionary[specialist.id] = specialist;
            specialist.client_access.forEach(access => {
                if (!this._client_engagement_specialist_ids[access.client_id]) {
                    this._client_engagement_specialist_ids[access.client_id] = [];
                }
                this._client_engagement_specialist_ids[access.client_id].push(specialist.id);
            });
        });

        this.survey_schedules.forEach(schedule => {
            this._survey_schedule_dictionary[schedule.id] = schedule;
        });

        this.render();
    }

    connectedCallback() {
        this.template = () => {
            return html`
            <style>
                .schedule-type-badge {
                    margin-left: 8px;
                    font-size: 0.8em;
                }
                .schedule-badge {
                    display: inline-block;
                    padding: 2px 6px;
                    margin: 0 4px;
                    border-radius: 4px;
                    font-size: 0.8em;
                    font-weight: bold;
                }
                .type-badge {
                    background-color: #e3f2fd;
                    color: #1976d2;
                }
                .tag-badge {
                    background-color: #f5f5f5;
                    color: #616161;
                }
                .client-settings {
                    display: flex;
                    gap: 20px;
                    margin-top: 10px;
                    font-size: 0.9em;
                    color: #666;
                }
                .client-settings .label {
                    font-weight: 600;
                    margin-right: 5px;
                }
                .date-cell {
                    color: #666;
                    font-size: 0.9em;
                }
                .date-error {
                    color: #dc3545;
                    font-size: 0.8em;
                    font-style: italic;
                }
            </style>
            <div class="modal-dialog modal-dialog-centered modal-xl activate-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Apply Schedule
                            <!-- ${this._selectedScheduleType ? this.renderScheduleTypeBadge(this._selectedScheduleType) : ''} -->
                        </h5>
                        <div class="client-settings">
                            <div>
                                <span class="label">Default Timezone:</span>
                                <span class="value">${(this.default_timezone || "not set").replace(/_/g, " ")}</span>
                            </div>
                            <div>
                                <span class="label">Default SMS Time:</span>
                                <span class="value">${this.default_sms_time || "not set"}</span>
                            </div>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ${this.error_message ? html`
                        <div class="alert alert-danger">
                            ${this.error_message}
                        </div>
                        ` : ''}
                        <form>
                            <table style="vertical-align: middle;" class="table activation-table">
                                <thead>
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>Primary DX</th>
                                        <th>Engagement Specialist</th>
                                        <th>Survey Schedule</th>
                                        <th>Start From</th>
                                        <th>Calc. Start</th>
                                        <th>Calc. End</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="mass-schedule-row" style="font-weight: 600; border-bottom-width: 2px;">
                                        <td style="color:#bbb9b9; vertical-align:middle;">ALL PATIENTS</td>
                                        <td></td>
                                        <td>
                                            <select @change=${e => this.handleEngagementSpecialistBulkAssign(e)} class="form-select">
                                                <option disabled selected value="">---</option>
                                                ${this.engagement_specialists.map(specialist => html`
                                                    <option value=${specialist.id}>${specialist.first_name} ${specialist.last_name}</option>
                                                `)}
                                            </select>
                                        </td>
                                        <td>
                                            <app-schedule-select
                                                .schedules=${this.survey_schedules}
                                                .value=${this._selectedScheduleId}
                                                @change=${(e) => {
                    const schedule = this.survey_schedules.find(s => s.id === e.detail.value);
                    this._selectedScheduleId = e.detail.value;
                    this._selectedScheduleType = schedule?.type || 'episode';
                    this.updateCalculatedDates();
                    this.render();
                }}
                                            ></app-schedule-select>
                                        </td>
                                        <td>
                                            <select @change=${e => this.handleScheduleStartBulkAssign(e)} class="form-select">
                                                <option disabled selected value="">---</option>
                                                ${this.scheduleStartOptions.map(option => html`
                                                    <option value=${option.value}>${option.label}</option>
                                                `)}
                                            </select>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    ${this.patients.map(patient => {
                    const client_id = patient.client_id?.id || patient.client_id;
                    const dates = this.calculatedDates.get(patient.id);
                    return html`
                                            <tr>
                                                <td>${patient.first_name} ${patient.last_name}</td>
                                                <td>${patient?.primary_diagnosis}</td>
                                                <td>
                                                    <select class="form-select" id=${"specialist-" + patient.id} required>
                                                        ${this._client_engagement_specialist_ids?.[client_id]?.map(specialist_id => {
                        const specialist = this._engagement_specialist_dictionary[specialist_id];
                        return html`
                                                                <option value=${specialist.id}>${specialist.first_name} ${specialist.last_name}</option>
                                                            `;
                    })}
                                                    </select>
                                                </td>
                                                <td>
                                                    <app-schedule-select
                                                        .schedules=${this.survey_schedules}
                                                        .value=${this._selectedScheduleId}
                                                        @change=${(e) => {
                            const schedule = this.survey_schedules.find(s => s.id === e.detail.value);
                            this._selectedScheduleId = e.detail.value;
                            this._selectedScheduleType = schedule?.type || 'episode';
                            this.updateCalculatedDates();
                            this.render();
                        }}
                                                    ></app-schedule-select>
                                                </td>
                                                <td>
                                                    <select 
                                                        class="form-select" 
                                                        id=${"schedule-start-" + patient.id} 
                                                        required
                                                        @change=${() => this.updateCalculatedDates()}
                                                    >
                                                        ${this.scheduleStartOptions.map(option => html`
                                                            <option value=${option.value}>${option.label}</option>
                                                        `)}
                                                    </select>
                                                </td>
                                                <td class="date-cell">
                                                    ${dates?.error ? html`
                                                        <span class="date-error">${dates.error}</span>
                                                    ` : html`
                                                        ${this.formatDate(dates?.startDate)}
                                                    `}
                                                </td>
                                                <td class="date-cell">
                                                    ${dates?.error ? '' : this.formatDate(dates?.endDate)}
                                                </td>
                                            </tr>
                                        `;
                })}
                                </tbody>
                            </table>
                        </form>
                    </div>
                    <div class="modal-footer" style="display: flex; justify-content: space-between;">
                        <div class="modal-btns">
                            <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                ?disabled=${!this.querySelector("form")?.checkValidity()}
                                type="button"
                                class="btn btn-primary modal-confirm-btn light-purple-btn"
                                @click=${() => this.handleActivateClick()}>
                                Apply Schedule
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            `;
        };

        this.render();
        this.addEventListener("shown.bs.modal", this.render);
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-activate-schedule-modal", AppActivateScheduleModal);


