import { html, render } from "lit";
import * as bootstrap from "bootstrap";

/**
 * A reusable schedule selection dropdown component that displays schedule type and tags as badges
 */
export default class AppScheduleSelect extends HTMLElement {
    static get observedAttributes() {
        return ['value', 'disabled'];
    }

    constructor() {
        super();
        this._schedules = [];
        this._value = '';
        this._disabled = false;
        this._onChange = null;
    }

    get value() {
        return this._value;
    }

    set value(val) {
        if (this._value !== val) {
            this._value = val;
            this.render();
        }
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(val) {
        if (this._disabled !== val) {
            this._disabled = val;
            this.render();
        }
    }

    get schedules() {
        return this._schedules;
    }

    set schedules(val) {
        this._schedules = val;
        this.render();
    }

    connectedCallback() {
        this.template = () => {
            const selectedSchedule = this._schedules.find(s => s.id === this._value);

            return html`
            <style>
                .schedule-badge {
                    display: inline-block;
                    padding: 2px 6px;
                    margin: -6px 0 0 1px;
                    border-radius: 4px;
                    font-size: 0.8em;
                    font-weight: bold;
                }
                

                .type-badge {
                    background-color: #e3f2fd;
                    color: #1976d2;
                }

                .tag-badge {
                    background-color: #f5f5f5;
                    color: #616161;
                }

                .schedule-info {
                    min-width: 200px;
                }

                .dropdown-menu {
                    max-width: 300px;
                }

                .schedule-badges {
                    display: flex;
                    flex-wrap: nowrap;
                    gap: 4px;
                    margin-top: 4px;
                }

                .schedule-item {
                     display: flex;
                     flex-direction: row;
                     flex-wrap: nowrap;
                     padding: 12px 0 10px 0;
                     align-items: center;
                     gap: 10px;
                     border-bottom: 1px solid #ededed;
                }

                .schedule-item:hover {
                    background-color: #f8f9fa;
                    cursor: pointer;
                }

                .schedule-name {
                    font-weight: 600;
                    white-space: nowrap;
                    font-size: 15px;
                    text-transform: capitalize;
                }
                ul.dropdown-menu.schedule-select {
                    max-width: 600px;
                }
                div.dropdown.select-schedule-dropdown ul { padding: 0 10px 5px 10px;}
            </style>
            <div class="dropdown select-schedule-dropdown">
                <a href="#" class="btn btn-secondary dropdown-toggle custom-dropdown-btn" 
                        type="button" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                        ?disabled=${this._disabled}>
                    ${selectedSchedule ? html`
    <span class="schedule-name">${selectedSchedule.name}</span>
    <div class="schedule-badges">
        ${selectedSchedule.type ? html`
            <span class="schedule-badge type-badge">${selectedSchedule.type}</span>
        ` : ''}
        ${(selectedSchedule.tags || []).map(tag => html`
            <span class="schedule-badge tag-badge">${tag}</span>
        `)}
    </div>
` : 'Select Schedule'}
            </a>
                <ul class="dropdown-menu schedule-select">
                    ${this._schedules.map(schedule => html`
                        <li>
                            <div class="schedule-item" @click=${() => this.handleSelect(schedule.id)}>
                                <span class="schedule-name">${schedule.name}:</span>
                                <div class="schedule-badges">
                                    ${schedule.type ? html`
                                        <span class="schedule-badge type-badge">${schedule.type}</span>
                                    ` : ''}
                                    ${(schedule.tags || []).map(tag => html`
                                        <span class="schedule-badge tag-badge">${tag}</span>
                                    `)}
                                </div>
                            </div>
                        </li>
                    `)}
                </ul>
            </div>
            `;
        };

        this.render();
        this.initializeDropdown();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'value' && oldValue !== newValue) {
            this._value = newValue;
            this.render();
        }
        if (name === 'disabled' && oldValue !== newValue) {
            this._disabled = newValue !== null;
            this.render();
        }
    }

    handleSelect(scheduleId) {
        this._value = scheduleId;
        this.dispatchEvent(new CustomEvent('change', {
            detail: { value: scheduleId },
            bubbles: true,
            composed: true
        }));
        this.render();
    }

    initializeDropdown() {
        const dropdownElement = this.querySelector('[data-bs-toggle="dropdown"]');
        if (dropdownElement && !bootstrap.Dropdown.getInstance(dropdownElement)) {
            new bootstrap.Dropdown(dropdownElement);
        }
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
        this.initializeDropdown();
    }
}

customElements.define("app-schedule-select", AppScheduleSelect);
