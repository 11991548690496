import { html, render } from "lit";
import cache from "../lib/lib-cache";

import "../components/patient/app-patient-header";
import "../components/availability/app-calendar-view";

export default class ScenePatientCalendar extends HTMLElement {
    constructor() {
        super();
        this.boundResizeHandler = this.handleWindowResize.bind(this);
    }
    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
        this.availability_id = value.params.availability_id;
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        if (!(this.patient?.id == value)) this.loadPatient();
    }

    get patient_id() {
        return this._patient_id;
    }

    set availability_id(value) {
        this._availability_id = value;
        this.render();
    }

    get availability_id() {
        return this._availability_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }
    set window_height(value) {
        this._window_height = value;
        //this.render();
    }

    get window_height() {
        return this._window_height;
    }


    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    app-availability-view > div {
                        box-shadow: var(--t-box-shadow); 
                    }
                    app-availability-view app-calendar-grid {
                        height: calc(100% - 52px); 
                        overflow-y: auto; 
                        display: block;
                    }
                </style>
              
                <app-patient-header
                    .page=${"client-calendar"}
                    .patient=${this.patient}>
                </app-patient-header>
                
                <app-calendar-view 
                    .patient=${this.patient}
                    .window_height=${this.window_height} 
                ></app-calendar-view>
                
            `;
        }

        this.style.display = "block";
        this.style.height = "100%";
        this.style.overflowY = "auto";

        this.render();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        render(this.template(), this);
    }

    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = document.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

    async loadPatient() {
        this.patient = await cache.getPatient(this.patient_id);
        if (!this._patient_header_height) {
            this._patient_header_height = this.querySelector('app-patient-header')?.clientHeight;
        }
        this.render();
    }
}

customElements.define("scene-patient-calendar", ScenePatientCalendar);