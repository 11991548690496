import { html, render } from "lit";
import ApexCharts from 'apexcharts';
import { format, parseISO } from 'date-fns';

/**
 * @param {object} metrics response from /dashboard_metrics
 * A widget with weekly communication metrics for the current client.
 */
export default class AppDashboardCommunicationWeekly extends HTMLElement {
    constructor() {
        super();
        this.chart = null;
        this._metrics = null;
    }

    set metrics(value) {
        console.log('Received metrics:', value);
        this._metrics = value;
        this.requestUpdate();
    }

    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.render();
    }

    disconnectedCallback() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    requestUpdate() {
        if (!this.updateRequested) {
            this.updateRequested = true;
            Promise.resolve().then(() => {
                this.updateRequested = false;
                this.render();
            });
        }
    }

    render() {
        const template = html`
            <style>
                #dashboard-communication {
                    height: 100%; 
                    display: flex; 
                    flex-direction: column;
                    position: relative;
                }
                #communicationChart {
                    width: 100%;
                    height: 350px;
                    margin: 0 auto;
                    position: absolute;
                    top: 34px;
                }
            </style>
            <div id="dashboard-communication">
                <h6>Week Over Week Communications</h6>
                <div id="communicationChart"></div>
            </div>
        `;
        Object.assign(this.style, {
            display: "block",
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            padding: "24px",
        });
        render(template, this);

        requestAnimationFrame(() => {
            const chartElement = this.querySelector("#communicationChart");
            if (chartElement && chartElement.offsetHeight > 0 && chartElement.offsetWidth > 0) {
                this.updateChart();
            } else {
                console.error('Chart element not ready:', chartElement);
            }
        });
    }

    updateChart() {
        console.log('Updating chart, metrics:', this.metrics);
        if (!this.metrics || !this.metrics.patient_communication_weekly) {
            console.error('No metrics data available');
            return;
        }

        const m = this.metrics.patient_communication_weekly;
        const chartElement = this.querySelector("#communicationChart");

        if (!chartElement) {
            console.error('Chart element not found');
            return;
        }

        const periods = ['period_1', 'period_2', 'period_3', 'period_4'];
        const metrics = ['sent_sms', 'received_sms', 'phone_voice', 'self_checks'];

        const series = metrics.map(metric => ({
            name: metric.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            data: periods.map(period => m[`${period}_${metric}`] || 0)
        }));

        const categories = periods.map(period => {
            const startDate = parseISO(m[`${period}_start`]);
            const endDate = parseISO(m[`${period}_end`]);
            return `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`;
        });

        const options = {
            chart: {
                type: 'line',
                height: 300,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                fontFamily: 'Quicksand',
                dropShadow: {
                    enabled: true,
                    top: 5,
                    left: 3,
                    blur: 3,
                    opacity: 0.2
                }
            },
            colors: ['#4e73df', '#663781', '#36b9cc', '#f6c23e'],
            series: series,
            xaxis: {
                categories: categories,
                labels: {
                    show: false,
                    rotate: -45,
                    rotateAlways: true,
                    style: {
                        fontSize: '12px',
                        fontWeight: 600
                    }
                },
            },
            yaxis: {
                title: {
                    show: false
                },
                labels: {
                    show: false
                }
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left',
                floating: false,
                offsetY: -8,
                offsetX: -22
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },
            tooltip: {
                shared: true,
                intersect: false
            },
            grid: {
                show: false
            },
            markers: {
                size: 1
            }
        };

        console.log('Chart options:', options);

        try {
            if (this.chart) {
                console.log('Updating existing chart');
                this.chart.updateOptions(options);
            } else {
                console.log('Creating new chart');
                this.chart = new ApexCharts(chartElement, options);
                this.chart.render();
            }
        } catch (error) {
            console.error('Error creating/updating chart:', error);
        }
    }
}

customElements.define("app-dashboard-communication-weekly", AppDashboardCommunicationWeekly);
