import { html, render, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { Dropdown } from 'bootstrap';
import { easepick, RangePlugin } from '@easepick/bundle';
import { parseISODateWithoutTime } from "../lib/lib-date";

/**
 * @typedef {Object} FilterOption
 * @property {string} label - The display label for the option
 * @property {any} value - The value to store for the option
 */

/**
 * A filter for client reports, suports multi-select dropdown and date range.
 * @param {string} title displays in the main dropdown
 * @param {FilterOption[]} options
 * @param {string|Array} value for datepickers should be a string like '2022-01-01,2022-01-02' and for multi-selects a list of values.
 * @param {boolean} is_datepicker if true, a date range selector will be used rather than a multi-select, and options is not required
 * @param {boolean} has_typeahead if true, an inline <input> will be added to multi-selects for option filtering
 * @param {boolean} required if true, disallows clearing selections
 * @fires AppReportFilter#dateselect upon a date range selection
 * @fires AppReportFilter#optionclick upon clicking a dropdown option
 * @fires AppReportFilter#clear upon clearing the filter with (x)
 */
export default class AppReportFilter extends HTMLElement {
    constructor() {
        super();
        this.selected_values = new Set();
        this.dropdown = null;
        this.selected_dates = null;
        this.filtered_options = [];
        this._multiselect = true;
    }

    set multiselect(value) {
        this._multiselect = value;
    }

    get multiselect() {
        return this._multiselect;
    }

    get options() {
        return this._options;
    }

    set options(values) {
        this._options = values;
        this.filtered_options = values;
        this.render();
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
        this.render();
    }

    get value() {
        return this._value;
    }

    set value(val) {
        this._value = val;
        this.updateSelections();
        this.render();
    }

    connectedCallback() {
        this.template = () => {
            return html`
<style>
    app-report-filter #btn-dropdown {
        background: white; 
        border-color: white;
        position: relative;
        height: 40px;
    }
    app-report-filter #btn-dropdown:hover {
        background: var(--t-color-primary-darkened); 
        border-color: var(--t-color-primary-darkened);
    }

    app-report-filter #btn-dropdown:active,
    app-report-filter #btn-dropdown:focus {
        border-color: var(--t-color-primary-darkened) !important;
        color: white !important;
    }
    app-report-filter .datepicker-input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
</style>

            ${this.renderSelections()}
            <div class="dropdown">
                <button 
                    class="btn btn-primary dropdown-toggle" 
                    id="btn-dropdown"
                    type="button" 
                    data-bs-toggle=${this.is_datepicker ? nothing : "dropdown"}
                    aria-expanded="false"
                    style="width: 100%;"
                >
                    ${this.title}
                    ${this.is_datepicker ?
                    html`<input name="date-picker" id="date-picker"
                            class="datepicker-input test"/>`
                    : ''
                }
                </button>
                ${!this.is_datepicker ? html`
                    <div class="dropdown-menu" style=${`width: ${this.has_typeahead ? "200px" : "auto"};`}>
                        ${this.has_typeahead ? html`
                            <input 
                                class="form-control"
                                style="width: calc(100% - 24px); margin: 0 12px;"
                                placeholder="Search"
                                @input=${e => this.handleTypeaheadInput(e)}
                            />
                            <div class="dropdown-divider"></div>
                        `: null}
                        <ul style="padding: 0; margin: 0;">
                            ${this.options ? html`
                                ${this.options.length && !this.filtered_options.length ? html`
                                    <span style="margin-left: 16px;">
                                        No matching options.
                                    </span>
                                `
                            : (this.filtered_options.map(option => html`
                                    <li 
                                        class=${classMap({
                                "active": this.selected_values.has(option.value),
                                "dropdown-item": true
                            })}
                                        style="cursor: pointer;"
                                        @click=${_e => this.handleOptionClick(option)}
                                    >
                                        ${option.label}
                                    </li>
                                `))}
                            ` : ``}                            
                        </ul>
                    </div>
                `: ""}
            </div>
            `
        }

        Object.assign(this.style, {
            //display: "flex",
            //flexDirection: "column",
            //width: "fit-content",
            //height: "69px"
        })

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    renderSelections() {
        if (!this.selected_dates && (
            !this.selected_values.size || !this.options?.length
        )) return;

        let selection_text = ''
        if (this.selected_dates) {
            const { start, end } = this.selected_dates;
            selection_text = `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
        }
        else {
            selection_text = this.selected_values.size === 1 ?
                this.options.find(option => option?.value === Array.from(this.selected_values)[0])?.label
                : "Multiple Selected";
        }
        return html`
            <div class="filter-label" style="
                display: flex; 
                align-items: center; 
                width: 100%; 
                padding: 4px;
                font-size: 14px;
                font-style: italic;
            ">
                ${this.required ? null : html`
                    <span 
                        @click=${_e => this.handleXClick()} 
                        class="material-symbols-outlined"
                        style="
                            cursor: pointer; 
                            margin-right: 2px;
                            font-variation-settings: 'FILL' 1;
                            font-size: 16px;
                            color: var(--t-color-grey);
                        "
                    >
                        cancel
                    </span>
                `}
                <span class="filter-selection-label">
                    ${selection_text}
                </span>
            </div>
        `
    }

    init() {
        // Bootstrap dropdown
        if (this.multiselect)
            this.dropdown = new Dropdown(this.querySelector(".dropdown-toggle"), {
                autoClose: "outside"
            });
        else
            this.dropdown = new Dropdown(this.querySelector(".dropdown-toggle"));

        // easepick date range picker
        if (this.is_datepicker) {
            this.datepicker = new easepick.create({
                element: this.querySelector('.datepicker-input'),
                css: [
                    'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
                    '/client/css/calendar.css',
                ],
                plugins: [RangePlugin],
                firstDay: 0,
                zIndex: 10,
                setup(picker) {
                    picker.on('show', () => {
                        const pickerElement = picker.ui.container; // Get the picker element
                        const bounding = pickerElement.getBoundingClientRect();
                        //pickerElement.style.left = `-275px`;
                        // Check if the picker is going off-screen
                        if (bounding.right > window.innerWidth) {
                            // Adjust position to prevent it from going off-screen
                            // pickerElement.style.left = `${window.innerWidth - bounding.width}px`;
                            pickerElement.style.left = `-275px`;
                        }

                        if (bounding.bottom > window.innerHeight) {
                            // Adjust top position to pop the picker upwards
                            //pickerElement.style.top = `${window.innerHeight - bounding.height}px`;
                            // pickerElement.style.top = `200px`;
                        }
                    });
                }
            });
            this.datepicker.on("select", this.handleDateSelect.bind(this));
        }

        this.updateSelections();
        this.render();
    }

    updateSelections() {
        if (this.value) {
            if (this.is_datepicker && this.datepicker) {
                const [start_iso, end_iso] = this.value.split(",");
                const start_date = parseISODateWithoutTime(start_iso);
                const end_date = parseISODateWithoutTime(end_iso);

                this.selected_dates = {
                    start: start_date,
                    end: end_date,
                };
                this.datepicker.setDateRange(start_date, end_date);
            }
            else this.selected_values = new Set(this.value);
        }
    }

    /**
     * @param {CustomEvent} e easepick 'select' event, 
     * @fires AppReportFilter#dateselect
     * detail contains {start: Date, end: Date}
     */
    handleDateSelect(e) {
        this.selected_dates = e.detail;
        this.dispatchEvent(new CustomEvent("dateselect", {
            detail: e.detail
        }));
        this.render();
    }

    /**
     * @param {FilterOption} option 
     * @fires AppReportFilter#optionclick
     */
    handleOptionClick(option) {
        if (this.multiselect) {
            if (this.selected_values.has(option.value)) {
                this.selected_values.delete(option.value);
            }
            else this.selected_values.add(option.value);
        }
        else
            this.selected_values = new Set([option.value]);

        this.dispatchEvent(new CustomEvent("optionclick", {
            detail: this.selected_values.size ? Array.from(this.selected_values) : undefined,
        }));

        this.render();
    }

    /**
     * Debounces a filter on labels of this.options
     * @param {Event} e input event from typeahead
     */
    handleTypeaheadInput(e) {
        e.stopPropagation();
        const search_term = e.target.value;
        if (search_term && search_term.length < 3) return;
        clearTimeout(this._debounce);
        this._debounce = setTimeout(() => {
            this.filtered_options = this.options.filter(option =>
                (option.label.toLowerCase().indexOf(search_term.toLowerCase()) !== -1)
            );
            this.render();
        }, 500);
    }

    /**
     * Clears the filter
     * @fires AppReportFilter#clear
     */
    handleXClick() {
        if (this.datepicker) {
            this.datepicker.clear();
        }
        this.selected_dates = null;
        this.selected_values.clear();
        this.dispatchEvent(new CustomEvent("clear"));
        this.render();
    }
}

customElements.define("app-report-filter", AppReportFilter);