import cache from "./lib-cache";
import DataDefinition from "./lib-data-definition";
import FormDesignUtil from "./lib-form_design-util";

class SurveyDefinition {
    static async getSurveyDefinition(survey_id, needFormDesign = true) {
        let survey_definition = new SurveyDefinition(survey_id, needFormDesign);
        await survey_definition.load();
        return survey_definition;

    }

    /**
     * The survey id for the task pointing to a form_design to load, if any
     * @param {*} survey_id Optional survey id. If this is provided, the form_design labels and choices will be available
     */
    constructor(survey_id, needFormDesign) {
        this.survey_id = survey_id;
        this.needFormDesign = needFormDesign;
        console.log("survey_definition", this.survey_id);
        console.log("needFormDesign", this.needFormDesign);
    }

    async load() {
        this.data_definition = await DataDefinition.getDefinition('hh_patient_reported_measures');
        if (this.survey_id && this.needFormDesign) {
            let form_design_id = await cache.getFormDesignForSurvey(this.survey_id);
            this.form_design_util = new FormDesignUtil(form_design_id);
            await this.form_design_util.load();
        }
    }

    isPrimaryField(field_name) {
        let field_dictionary = this.data_definition.getFieldDictionary();
        return field_dictionary.hasOwnProperty(field_name);
    }

    getFieldGroups() {
        return this.data_definition.getFieldGroups();
    }

    getFieldDefinition() {
        let field_defition = this.data_definition.getFieldDefinition();
        field_defition = field_defition.filter(
            field =>
                field.field.startsWith('pr_')
        );
        return field_defition;
    }

    getFieldDictionary() {
        return this.data_definition.getFieldDictionary();
    }

    getFieldLabel(field_name) {
        if (this.isPrimaryField(field_name)) {
            let field_dictionary = this.data_definition.getFieldDictionary();
            return field_dictionary[field_name]?.label;
        }

        return this.form_design_util?.getFieldLabel(field_name) || SurveyDefinition.formatFieldName(field_name);
    }

    getChoiceLabel(field_name, field_value) {
        //if this is a primary field, get the value from directus schema data
        if (this.isPrimaryField(field_name))
            return this.data_definition.getChoiceLabel(field_name, field_value);

        //get value from form design
        return this.form_design_util?.getChoiceLabel(field_name, field_value) || field_value;
    }

    static formatFieldName(field_name) {
        if (!field_name)
            return '';
        field_name = field_name.replace('pr_', '');
        return DataDefinition.formatFieldName(field_name);
    }

    async getSupplementalQuestionKeys(field_name) {
        let keys = this.form_design_util.getKeysWithParentField(field_name);
        return keys;
    }
}

export default SurveyDefinition;
