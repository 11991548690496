import { html, render } from "lit";

export default class AppLoader extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.template = () => html`
            <style>

            </style>
            <div class="loader">
                <img src="images/integration_running.gif" alt="Loading...">
            </div>
        `;

        this.render();
    }

    render() {
        render(this.template(), this);
    }
}

customElements.define("app-loader", AppLoader);
