import { html, render } from 'lit';
import ApplicationState from 'applicationstate';

class AppLiveBadge extends HTMLElement {

    /**
     * The ApplicationState path to listen for updates
     */
    set state_path(value) {
        this._state_path = value;
        ApplicationState.listen(value, (new_value) => {
            this.value = new_value
        });
    }

    get state_path() {
        return this._state_path;
    }

    /**
     * The value that will be rendered in the badge
     */
    set value(value) {
        this._value = value;
        this.render();
    }

    get value() {
        return this._value;
    }

    connectedCallback() {
        this.template = () => html`
            <span style="bottom: -7px; right: 5px;" class="position-absolute badge rounded-pill bg-danger">
                ${this.value}
            </span>
        `;
        this.render();
    }

    render() {
        if (!this.template)
            return;

        if (!this.value)
            return;

        render(this.template(), this);
    }
}

customElements.define("app-live-badge", AppLiveBadge);
export default AppLiveBadge;
