import { html, render } from "lit";

import "./util/app-nav-links";

class AppPageHeader extends HTMLElement {
    set title(value) {
        this._title = value;
    }

    get title() {
        return this._title;
    }

    set icon(value) {
        this._icon = value;
    }

    get icon() {
        return this._icon;
    }

    set css_class(value) {
        this._css_class = value;
        console.log("css_class set:", this._css_class);
    }

    get css_class() {
        return this._css_class;
    }

    set sub_title(value) {
        this._sub_title = value;
    }

    get sub_title() {
        return this._sub_title;
    }

    set page(value) {
        this._page = value;
        this.link = this.links.find((l) => l.page === value);
        this.render();
    }

    get page() {
        return this._page;
    }

    set links(values) {
        this._links = values;
        this.render();
    }

    get links() {
        return this._links;
    }

    set link(value) {
        this._link = value;
        if (value) {
            this.sub_title = value.title;
        }
        this.render();
    }

    get link() {
        return this._link;
    }

    constructor() {
        super();
        this.link_styles = {
            display: "flex",
            alignItems: "center",
            marginLeft: "24px",
            marginRight: "15px",
            height: "46px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            textDecoration: "none",
            color: "#131313",
        };
        this.selected_link_styles = Object.assign({}, this.link_styles, {
            borderBottom: "2px solid #201B3A",
            fontWeight: "700",
            color: "#201B3A",
        });
    }

    connectedCallback() {
        this.template = () => html`
            <style>
                @media (max-width: 768px) {
                    #links_full {
                        display: none;
                    }

                    #links_mobile {
                        display: flex;
                    }
                }
                @media (min-width: 768px) {
                    #links_full {
                        display: flex;
                    }

                    #links_mobile {
                        display: none;
                    }
                }
            </style>
            <div style="
                position: relative; 
                background-color: #3e3f67; 
                height: 100px; 
                box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                border-radius: 8px;
                box-sizing: border-box;
            ">
            <div style="display: flex">
            
                <h2 class="header-title">
        
                ${this.icon ?
                html`
                    <span class="material-symbols-outlined ${this?.css_class}" style="margin: 0 10px 0 0; position:relative; top:4px">${this.icon}</span>
                `: ''}
                ${this.title || ""}</h2>
                </div>
                <!--
                <h3 style="
                    padding-left: 24px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                    color: #131313;
                ">${this.sub_title || ""}</h3>
                -->
                <app-nav-links
                    .links=${this.links}
                    .page=${this.page}
                    .css_class=${this.css_class}></app-nav-links>
            </div>
        `;

        Object.assign(this.style, {
            display: "block",
        });

        this.render();
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }
}

customElements.define("app-page-header", AppPageHeader);
export default AppPageHeader;
