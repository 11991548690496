import { html, render } from "lit";
import { format, parse } from "date-fns";
import { formatPhoneNumber } from "../lib/lib-util";
import { getQueryParams } from "../lib/lib-location";
import directus from "../lib/lib-directus";

import "../components/patient/app-patient-checkpoint-changes";
import "../components/patient/app-patient-checkpoint-summary";

export default class ScenePatientSummary extends HTMLElement {
    constructor() {
        super();
        this.patient = null;
        this.display_error = false;
    }

    connectedCallback() {
        this.template = () => {
            const p = this.patient;
            return html` <style>
                    .patient-summary {
                        border-radius: 8px;
                        box-shadow: var(--t-box-shadow);
                    }

                    .patient-summary small {
                        color: var(--t-color-grey);
                    }

                    @media print {
                        .patient-summary p-4,
                        .patient-summary mb-2,
                        .patient-summary mt-2,
                        .patient-summary m-4 {
                            margin: 0;
                            padding: 0;
                        }
                    }
                </style>
                <div class="m-4 p-4 content-background patient-summary" id="patient-summary">
                    <div class="row">
                        <div class="col">
                            <h1 style="font-size: 1.5rem"><small>Name:</small> ${p.last_name}, ${p.first_name}</h1>
                            <div><small>Case Manager:</small> ${p.case_manager || "none"}</div>
                        </div>
                        <div class="col">
                            <div class="mb-2">
                                <small>Birthday:</small> ${format(
                parse(p.birth_date, "yyyy-MM-dd", new Date()),
                "MMMM d, yyyy"
            )}
                            </div>
                            <div><small>Created:</small> ${format(new Date(p.date_created), "MMMM d, yyyy")}</div>
                            <div><small>Last Updated:</small> ${format(new Date(p.date_updated), "MMMM d, yyyy")}</div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <div class="mb-1"><small>Address:</small></div>
                            ${this.addressTemplate()}
                        </div>
                        <div class="col">
                            <div><small>Phone:</small> ${formatPhoneNumber(p.primary_phone)}</div>
                        </div>
                    </div>
                </div>

                <div class="m-4 p-4 content-background patient-summary">
                    <app-patient-checkpoint-changes
                        .patient=${this.patient}
                        .episodes=${this.episodes}
                        .checkpoints=${this.checkpoints}
                        .fields=${this.fields}></app-patient-checkpoint-changes>
                </div>

                <div class="m-4 p-4 content-background patient-summary">
                    <app-patient-checkpoint-summary
                        .patient=${this.patient}
                        .episode=${this.selected_episode}
                        .checkpoints=${this.checkpoints}
                        .fields=${this.fields}></app-patient-checkpoint-summary>
                </div>`;
        };

        this.errorTemplate = () => html`<div class="alert alert-danger">
            There was a problem with your request, please reload the page. If the problem persists please contact
            support.
        </div>`;

        this.addressTemplate = () => {
            if (!this.patient.address || this.patient.address.length < 1) {
                return html`<div>This patient has no addresses listed.</div>`;
            }

            let primary_address = this.patient.address.find((a) => a.primary === true);
            if (!primary_address) {
                primary_address = this.patient.address[0];
            }

            return html`<div>
                ${primary_address.line_1}<br />
                ${primary_address.line_2 ? html`${primary_address.line2}<br />` : ""} ${primary_address.city},
                ${primary_address.state} ${primary_address.zip_code}
            </div>`;
        };

        this.init().catch((err) => {
            console.error(err);
            this.display_error = true;
            this.render();
        });

        this.render();
    }

    /**
     * Load all required data from directus in proper order
     */
    async init() {
        const query_params = getQueryParams();
        let run_date;
        if (query_params.run_date) {
            run_date = new Date(query_params.run_date);
        } else {
            run_date = new Date();
        }

        await this.fetchPatient(this.location?.params?.patient_id, this.location?.params?.episode_id);
        if (this.selected_episode) {
            await this.fetchFields();
            await this.fetchCheckpointData(this.patient.id, this.selected_episode, run_date);
        }
        this.render();
    }

    render() {
        if (!this.template) return;
        if (this.display_error) return render(this.errorTemplate(), this);
        if (!this.patient) return;
        if (!this.episodes) return;
        if (!this.checkpoints) return;

        render(this.template(), this);
    }

    /**
     * Loads a patient and related episodes.
     * @param {string} patient_id The patient ID to load from directus
     */
    async fetchPatient(patient_id, episode_id) {
        if (!patient_id || !episode_id) return;
        this.patient = await directus.items("patient").readOne(patient_id, {
            fields: [
                "*",
                "address.*",
                "patient_reported_measures.*",
                "episodes.*",
                "user_created.*",
                "user_updated.*",
                "tasks.*",
                "engagement_specialist.*",
                "checkpoint_summaries.*",
            ],
        });
        this.episodes = this.patient.episodes || [];
        this.selected_episode = this.patient.episodes.find((e) => e.id == episode_id);
    }

    /**
     * Fetch all the checkpoint data and sort it by day
     * @param {string} patient_id The patient id to get checkpoint data for
     * @param {string|number} selected_episode the selected episode for the paitent
     */
    async fetchCheckpointData(patient_id, selected_episode, run_date) {
        try {
            let result = await directus.transport.get(
                `/vbh/measures_delta/${patient_id}/${selected_episode.id}?run_date=${run_date.toISOString()}`
            );
            this.checkpoints = result.data;
            this.checkpoints.sort((a, b) => a.day - b.day);
            this.render();
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * Fetch field data from directus
     */
    async fetchFields() {
        this.fields = await directus.fields.readMany("hh_patient_reported_measures");
    }
}

customElements.define("scene-patient-summary", ScenePatientSummary);
