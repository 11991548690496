import { html, render } from "lit";
import { navigate } from "shared/lib/lib-router";

/**
 * @param {object} metrics reponse from /dashboard_metrics
 * A widget with availability metrics for the current client.
 */
export default class AppDashboardAvailability extends HTMLElement {
    set metrics(value) {
        this._metrics = value;
        this.render();
    }

    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            const { availability_conflicts } = this.metrics || {}
            return html`
                <style>
                    
                    #dashboard-availability .widget-key-value {
                        display: flex;
                        justify-content: space-between;
                        width: 124px;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    #dashboard-availability .widget-key-value span:last-child {
                        margin-left: 8px;
                    }
                </style>
                <div 
                    id="dashboard-availability" 
                    style="height: 100%; cursor: pointer;"
                    @click=${_e => navigate(`reports/patient/client-calendar`)}
                >
                    <h6>Potential Visit Conflicts</h6>
                    <div style="
                        display: flex; 
                        flex-direction: column; 
                        align-items: center; 
                        justify-content: center;
                        height: calc(100% - 26px);
                    ">
                        <div style="
                            display: flex; 
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: 32px;
                        " >
                            <span style="
                                font-weight: 700;
                                font-size: 50px;
                                line-height: 56px;
                                letter-spacing: 0.5px;
                                color: var(--t-color-dark);
                            "
                            >
                                ${availability_conflicts?.today || 0}
                            </span>
                            <span style="
                                font-size: 14px;
                                font-weight: 700;
                                color: var(--t-color-grey);
                            ">
                                Today
                            </span>
                        </div>
                        <span class="widget-key-value">
                            <span>Tomorrow</span>
                            <span>${availability_conflicts?.tomorrow || 0}</span>
                        </span>
                        <span class="widget-key-value">
                            <span>This Week</span>
                            <span>${availability_conflicts?.this_week || 0}</span>
                        </span>
                        <span class="widget-key-value">
                            <span>Next Week</span>
                            <span>${availability_conflicts?.next_week || 0}</span>
                        </span>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
        });
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-dashboard-availability", AppDashboardAvailability);
