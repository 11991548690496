import { html, render } from "lit";
import { format, parse, differenceInHours } from "date-fns";
import "shared/components/app-pill";
import directus from "shared/lib/lib-directus";
import { navigate } from "shared/lib/lib-router";
import AppVisitCallCompleteModal from "./app-visit-call-complete-modal";
import AppVisitActivityModal from "./app-visit-activity-modal";


/**
 * A compact visit card used in visit confirmation.
 */
class AppVisitCard extends HTMLElement {
    constructor() {
        super();
    }

    /** @type {import("../../scenes/scene-visit-confirmation").Visit} */
    get visit() {
        return this._visit;
    }

    set visit(value) {
        this._visit = value;
        this.render();
    }


    connectedCallback() {
        this.template = () =>
            html`
                <style>
                    .card-text {
                        height: 20px;
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }
                    app-pill.dropdown-toggle::after {
                        display: none;
                    }
                    .app-visit-action-button { 
                        display: flex; 
                        padding: 3px;
                        flex-direction: column; 
                        align-items: center; 
                        border: 1px solid var(--t-color-primary); 
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    .app-visit-action-button:hover {
                        background-color: var(--t-color-lightest-grey);
                    }
                    .app-visit-patient-link {
                        font-weight: 700;
                        font-size: 17px;
                        line-height: 20px;
                        margin-bottom: 0px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        text-decoration: underline;
                        cursor: pointer;
                        overflow: hidden;
                    }
                    .app-visit-patient-link:hover {
                        color: var(--t-color-primary);
                    }
                    .visit-card-line-item {
                        font-size: 10px;
                    }

                    .visit-card-address:hover {
                        text-decoration: underline;
                    }
                </style>
                <div
                    class="card"
                    style="
                        height: 133px; 
                        border: unset; 
                        border-radius: 8px;
                        box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                    ">
                    <div class="patient-list-card" style="padding: 19px 13px 10px 24px; position: relative;">
                        <div style="
                            position: absolute;
                            top: -10px;
                            left: 10px;
                        ">
                            <!--status pill-->
                            <div 
                                class="dropdown report-filters" 
                                style="margin-left: auto; display: flex;">
                                <app-pill  
                                    class="dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    .show_edit=${true} 
                                    .text=${this.statusToLabelText(this.visit.status)} 
                                    .color=${this.statusToColor(this.visit.status)}
                                    style="margin-left: auto; cursor: pointer;"></app-pill>
                                <div class="dropdown-menu">
                                    <app-pill  
                                        class="dropdown-item"
                                        .show_edit=${true} 
                                        @click=${e => this.handleSelectStatus("imported")}
                                        .text=${this.statusToLabelText("imported")} 
                                        .color=${this.statusToColor("imported")}
                                        style="margin-left: auto; cursor: pointer;"></app-pill>
                                    <app-pill  
                                        class="dropdown-item"
                                        .show_edit=${true} 
                                        @click=${e => this.handleSelectStatus("confirmed")}
                                        .text=${this.statusToLabelText("confirmed")} 
                                        .color=${this.statusToColor("confirmed")}
                                        style="margin-left: auto; cursor: pointer;"></app-pill>
                                    <app-pill  
                                        class="dropdown-item"
                                        .show_edit=${true} 
                                        @click=${e => this.handleSelectStatus("denied")}
                                        .text=${this.statusToLabelText("denied")} 
                                        .color=${this.statusToColor("denied")}
                                        style="margin-left: auto; cursor: pointer;"></app-pill>
                                </div>
                            </div>
                        </div>
                        <!-- flex layout wrapper for left info block and right action block -->
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">

                            <!-- left info block -->
                            <div>
                                <div class="d-flex" style="align-items: center;">
                                    <h5
                                        @click=${async e => {
                    await navigate(`patients/${this.visit.patient_id}/checkpoints`);
                }}
                                        class="card-title app-visit-patient-link" >
                                        ${this.visit.patient.first_name + " " + this.visit.patient.last_name}
                                    </h5>
                                </div>
                                <div
                                    style="height: 14px;
                                        display: flex;
                                        position: relative;
                                        font-size: 12px;
                                    ">
                                    <span class="patient-card__date">
                                        <span style="font-weight: 600">MRN:</span> ${this.visit.patient.mrn}
                                    </span>
                                </div>
                                <div
                                    style="height: 14px;
                                        display: flex;
                                        position: relative;
                                        font-size: 12px;
                                    ">
                                    <span class="patient-card__date">
                                        <span style="font-weight: 600">Clinician:</span> ${this.visit.careteam_member_id.first_name} ${this.visit.careteam_member_id.last_name}
                                    </span>
                                </div>
                                <div
                                    style="margin-top: 10px; font-size: 13px;">
                                    <div class="visit-card-line-item">
                                        <span style="font-weight: 600">${this.visit.visit_description}</span>
                                    </div>
                                    <a style="text-decoration: none; color: var(--t-color-primary);" target="_blank" href=${"https://www.google.com/maps/dir//" + encodeURI(`${this.visit.address} ${this.visit.city} ${this.visit.state}, ${this.visit.zip_code}`)}>
                                        <div style="text-decoration: none; display: flex; flex-direction: row; align-items: center">
                                            <span style="text-decoration: none; font-size: 16px; margin-right: 5px; color: var(--t-color-primary);" class="material-symbols-outlined">location_on</span>
                                            <div class="visit-card-line-item visit-card-address">
                                                    <div style="white-space: nowrap;">
                                                        ${this.visit.address}
                                                    </div>
                                                    <div>
                                                        <span style="">${this.visit.city},</span> 
                                                        <span style="">${this.visit.state}</span>
                                                        <span style="">${this.visit.zip_code}</span>
                                                    </div>
                                            </div>
                                        </div>
                                    </a>
                                    <!--
                                    <div class="visit-card-line-item">
                                        Last Visit: ${this.visit.patient.last_visit_date}
                                    </div>
                                    <div class="visit-card-line-item">
                                        Next Visit: ${this.visit.patient.next_visit_date}
                                    </div>
                                    -->
                                </div>

                            </div>
                            <!-- right action block -->
                            <div style="display: flex; flex-direction: column; align-items: center; justify-content: space-between">
                                <div class="app-visit-action-button" @click=${e => this.handleCallPatient()}>
                                    <div class="material-symbols-outlined" style="color: var(--t-color-primary);">call</div>
                                    <div style="white-space: nowrap; font-size: 8px; text-transform: uppercase; font-weight: bold; color: var(--t-color-primary);">Call Patient</div>
                                </div>
                                <div class="app-visit-action-button" @click=${e => this.handleShowActivity()}>
                                    <div class="material-symbols-outlined" style="color: var(--t-color-primary);">event_note</div>
                                    <div style="font-size: 8px; text-transform: uppercase; font-weight: bold; color: var(--t-color-primary);">Activity</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `;

        this.render();
    }

    statusToLabelText(status) {
        const status_map = { imported: "Unconfirmed", confirmed: "Confirmed", denied: "Denied" };
        return status_map[status] || '';
    }

    statusToColor(status) {
        const status_map = { imported: "--t-color-light-grey", confirmed: "--t-color-primary", denied: "--t-color-danger" };
        return status_map[status] || '';
    }

    render() {
        if (!this.template)
            return;
        if (!this.visit)
            return;
        render(this.template(), this);
    }

    async handleSelectStatus(status) {
        await directus.items("visit").updateOne(this.visit.id, { status });
        this.visit.status = status;
        this.render();
        this.dispatchEvent(new CustomEvent("visit-edited", { bubbles: true, composed: true }));
    }

    async handleCallPatient() {
        let softphone_container_element = document.querySelector("app-softphone-container");
        await softphone_container_element.dial(this.visit.patient_id, null, () => {
            this.handleCallComplete()
        });
    }

    async handleCallComplete() {
        let modal = new AppVisitCallCompleteModal();
        modal.visit = this.visit;
        await modal.showModal();
        let response = await modal.onDidDismiss();
        if (response.save) {
            await this.handleSelectStatus(response.status);
            if (response.unavailable_times?.length) {
                await directus.items("patient_availability").createMany(response.unavailable_times);
            }
        }
        let toaster = document.querySelector("app-toaster");
        await toaster.toast("Call result saved successfully", { header: "Success" });
    }

    async handleShowActivity() {
        let modal = new AppVisitActivityModal();
        modal.visit = this.visit;
        await modal.showModal();
    }

}

customElements.define("app-visit-card", AppVisitCard);
export default AppVisitCard;
