import { html, render } from "lit";

/**
 * Controlled component that renders a selectable list of measures for dashboard pages.
 * @param {Array} measures selectable measure labels for the list
 * @param {string} selected_measure currently selected member of measures
 * @fires AppDashboardMeasures#measureclick when one of the measure list items is selected
 */
export default class AppDashboardMeasures extends HTMLElement {
    constructor() {
        super();
        this._measures = [];
        this._selected_measure = null;
    }

    get measures() {
        return this._measures;
    }

    set measures(val) {
        this._measures = val;
        this.render();
    }

    get selected_measure() {
        return this._selected_measure;
    }

    set selected_measure(val) {
        this._selected_measure = val;
        this.render();
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #measures-container {
                        padding-top: 16px;
                    }
                    #measures-container h6 {
                        width: 100%;
                        padding-left: 24px;
                        color: var(--t-color-dark);
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.5px;
                    }
                    #measures-container .list-group {
                        width: 100%;
                        border-top-right-radius: unset;
                        border-top-left-radius: unset;
                    }
                    #measures-container .list-group-item-action {
                        padding-left: 24px;
                        border: none;
                        color: var(--t-color-dark);
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                    }
                    #measures-container .list-group-item-action:hover {
                        background-color: var(--t-color-hover);
                    }
                    #measures-container .list-group-item-action--selected {
                        background-color: var(--t-color-hover);
                        font-weight: 600;
                    }
                    #measures-container .list-group-item-action--selected .material-symbols-outlined {
                        font-variation-settings: "FILL" 1;
                    }
                    #measures-container .material-symbols-outlined {
                        margin-left: auto;
                        font-size: 16px;
                    }
                </style>
                <div id="measures-container">
                    <h6>MEASURES</h6>
                    <div class="list-group">
                        ${Object.values(this._measures).map((measure) => {
                return html`
                                <a
                                    href="#"
                                    @click=${(e) => this.handleMeasureClick(e, measure)}
                                    class="list-group-item list-group-item-action ${measure ===
                        this._selected_measure
                        ? "list-group-item-action--selected"
                        : ""}">
                                    ${measure.title}<span class="material-symbols-outlined"> play_circle </span>
                                </a>
                            `;
            })}
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "unset",
            height: "fit-content",
        });

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * @param {Event} e <a> click event
     * @param {string} label clicked measure label, one of this._measures
     * @fires AppDashboardMeasures#measureclick
     */
    handleMeasureClick(e, measure) {
        e.preventDefault();
        this.dispatchEvent(
            new CustomEvent("measureclick", {
                detail: { measure },
            })
        );
    }
}

customElements.define("app-dashboard-measures", AppDashboardMeasures);
