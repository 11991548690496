import { html, render } from 'lit';
import ApplicationState from 'applicationstate';
import directus from '../../lib/lib-directus';
import hash from 'object-hash';
import { getCurrentClientId, getCurrentUserId } from '../../lib/lib-user';
import AppConfirmationModal from '../app-confirmation-modal';

class AppFilterSavedViews extends HTMLElement {

    set selected_view(value) {
        this._selected_view = value;
        this.render();
    }

    get selected_view() {
        return this._selected_view;
    }

    set collection_name(value) {
        this._collection_name = value;
        this.init();
    }

    get collection_name() {
        return this._collection_name;
    }

    set screen(value) {
        this._screen = value;
    }

    get screen() {
        return this._screen;
    }

    get app_name() {
        return ApplicationState.get("app.name");
    }

    set filter_state(value) {
        if (!value)
            return;
        //the nice thing about the object-hash library is that it will normalize object keys to alphabetical order.
        //this makes small changes like the order of keys resolve to the same hash --ccg
        let new_hash = hash(value);
        if (new_hash == this._current_filter_state_hash)
            return this.dirty = false;

        if (this.selected_view)
            this.dirty = true;
        this._filter_state = value;
        this._current_filter_state_hash = new_hash;
        this.render();
    }

    get filter_state() {
        return this._filter_state;
    }

    set dirty(value) {
        this._dirty = value;
    }

    get dirty() {
        return this._dirty;
    }

    set data(value) {
        this._data = value;
    }

    get data() {
        return this._data || [];
    }

    get copy_disabled() {
        return !this.selected_view;
    }

    get save_disabled() {
        //if this is a new view, enable save button
        if (!this.selected_view && this.dirty)
            return false;

        //disable if it's not new and nothing's selected
        if (!this.selected_view)
            return true;

        //disable save if this a system global view
        if (!this.selected_view.client_id)
            return true;

        //disable save if this isn't a user-level view
        if (!this.selected_view.user_id)
            return true;

        //disable the save if this view isn't assigned to the current user
        if (!this.selected_view.user_id == getCurrentUserId())
            return true;

        if (this.selected_view && !this.dirty)
            return true;

        if (this.dirty)
            return false;

        return true;
    }

    get delete_disabled() {
        if (!this.selected_view)
            return true;
        if (!this.selected_view.user_id)
            return true;
        if (!this.selected_view.user_id == getCurrentUserId())
            return true;

        return false;
    }

    constructor() {
        super();
    }

    connectedCallback() {
        this.template = () => html`
        <style>
            .app-filter-saved-view-button {
                border: none;
                 background-color: transparent !important;
                border-radius: 3px;
            }
            .app-filter-saved-view-button > span {
                color: var(--t-color-light-grey);
                font-size: 18px;
                font-weight: 400;
                background-color: transparent !important;
            }
            .app-filter-saved-view-button:enabled > span {
                color: var(--t-color-dark);
            }
            .app-filter-saved-view-button:hover:enabled {
                /*box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.2);*/
            }

            .app-filter-saved-view-button:hover:enabled > span {
                color: var(--t-color-primary);
            }
        </style>
        <div 
            style="
            display: flex;
            flex-direction: row;
            align-items: center;
            "
        >
            <div class="input-group">
                <span 
                    style="
                    cursor: pointer;
                    font-size: 12px;
                    color: var(--t-color-dark);
                    "
                    data-bs-toggle="dropdown" 
                    class="input-group-text"
                >
                    <span style="
                    font-size: 20px;
                    color: var(--t-color-dark);
                    " class="material-symbols-outlined">expand_more</span>
                </span>
                ${this.data?.length ?
                html`
                <ul style="font-size: 12px;" class="dropdown-menu">
                    ${this.data.map(
                    view =>
                        html`
                            <li
                                class="dropdown-item"
                                style="cursor: pointer"
                                @click=${e => this.handleSelectView(view)}
                            >
                                ${(!view.user_id) ?
                                html`
                                <span style="font-weight: bold">
                                ${view.title}
                                </span>
                                `
                                :
                                html`
                                ${view.title}
                                ` }
                            </li>
                            `
                )}
                </ul>
                `:
                html`
                <div class="dropdown-menu" style=" padding: 10px; font-size: 14px; color: var(--t-color-grey);">
                    You don't have any saved views. To add one, change some filters, type in the name of the view you'd like to save, and click the save button.
                </div>
                `}
                <input type="text" 
                    style="font-size: 14px; width: 140px;"
                    class="form-control" 
                    placeholder="save view as..."
                    .value=${this.selected_view?.title || ''}
                    @input=${e => {
                this.dirty = true;
                this._selected_view_name = e.target.value;
                this.render();
            }}
                    >
                ${this.selected_view ?
                html`
                <span 
                    style="
                    cursor: pointer;
                    "
                    class="input-group-text"
                    @click=${e => this.handleSelectView(null)}
                >
                    <span style="
                    font-size: 18px;
                    color: var(--t-color-dark);
                    " class="material-symbols-outlined">close</span>
                </span>
                `: ''
            }
           
            <div class="btn-group">
                <button 
                    ?disabled=${this.save_disabled} 
                    class="app-filter-saved-view-button"
                    @click=${e => this.handleSaveView()}
                    >
                    <span class="material-symbols-outlined" style="">save</span>
                </button>
                <button 
                    ?disabled=${this.copy_disabled} 
                    class="app-filter-saved-view-button"
                    @click=${e => this.handleCopyView()}
                    >
                    <span class="material-symbols-outlined" style="">content_copy</span>
                </button>
                <button 
                    ?disabled=${this.delete_disabled} 
                    class="app-filter-saved-view-button"
                    @click=${e => this.handleDeleteView()}
                    >
                    <span class="material-symbols-outlined" style="">delete</span>
                </button>
 </div>
            </div>
        </div>
        `;
        this.init();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    async init() {
        await this.load();
        let selected_view_id = ApplicationState.get(`app.${this.collection_name}_selected_view_id`);
        let selected_view = this.data.find(
            view => view.id == selected_view_id
        );
        if (selected_view) {
            //this will call render()
            this.selected_view = selected_view;
            this.dispatchEvent(new CustomEvent('filter_change', {
                bubbles: true,
                composed: true,
                detail: selected_view.view
            }));
        }
    }

    async handleSelectView(view) {
        if (!this.collection_name)
            return;


        if (this.dirty) {
            let discard = await this.promptDiscardChanges();
            if (!discard)
                return;
        }

        if (!view) {
            this.selected_view = null;
            ApplicationState.rm(`app.${this.collection_name}_selected_view_id`);
            this.render();
            return this.dispatchEvent(new CustomEvent('filter_change', {
                bubbles: true,
                composed: true,
                detail: {
                    user_search: '',
                    group: { type: "_and", filters: [], groups: [] },
                }
            }));
        }

        ApplicationState.set(`app.${this.collection_name}_selected_view_id`, view.id);
        this._current_filter_state_hash = hash(view.view);
        this.dispatchEvent(new CustomEvent('filter_change', {
            bubbles: true,
            composed: true,
            detail: view.view
        }));
        this.selected_view = view;
    }

    async handleSaveView() {
        if (!this.selected_view)
            return await this.handleAddView();

        if (!this.selected_view.user_id)
            return;

        if (!this.selected_view.client_id)
            return;

        let title = this.selected_view?.title;
        title = title || 'your view';
        title = title.trim();

        let modal = new AppConfirmationModal();
        modal.modal_text = "You're about to save changes to " + title + ", proceed?";
        await modal.showModal();
        let result = await modal.onDidDismiss();
        if (!result?.confirmed)
            return;

        await directus.items('saved_view').updateOne(this.selected_view.id, {
            title: this._selected_view_name || this.selected_view.title,
            view: this.filter_state
        });
        this.dirty = false;
        await this.load();
        document.querySelector('app-toaster')
            .toast(`${this.selected_view.title} saved successfully.`, { header: 'Save View' });

    }

    async handleCopyView() {
        if (!this.selected_view)
            return;

        let user = ApplicationState.get('app.user');
        let title = "Copy of " + this.selected_view?.title;
        title = title.trim();

        let result = await directus.items('saved_view').createOne({
            user_id: user.id,
            title,
            view: this.selected_view.view,
            collection: this.collection_name,
            status: 'published'
        });

        this.dirty = false;
        await this.load();
        document.querySelector('app-toaster')
            .toast(`${this.selected_view.title} copied successfully.`, { header: 'Copy View' });

        //just doing this to make sure it saved ok and was loaded above
        let new_view = this.data.find(view => view.id == result.id);
        this.selected_view = new_view;

    }

    async handleAddView() {
        let user = ApplicationState.get('app.user');
        let result = await directus.items('saved_view').createOne({
            user_id: user.id,
            screen: this.screen,
            title: this._selected_view_name || 'Default View',
            view: this.filter_state,
            collection: this.collection_name,
            status: 'published'
        });
        this.dirty = false;
        await this.load();
        document.querySelector('app-toaster')
            .toast(`${this._selected_view_name} created successfully.`, { header: 'Create View' });

        //just doing this to make sure it saved ok and was loaded above
        let new_view = this.data.find(view => view.id == result.id);
        this.handleSelectView(new_view);
    }

    async handleDeleteView() {
        let modal = new AppConfirmationModal();
        modal.modal_text = "Are you sure you want to delete this saved view?";
        await modal.showModal();
        let result = await modal.onDidDismiss();
        if (!result?.confirmed)
            return;
        await directus.items('saved_view').deleteOne(this.selected_view.id);
        ApplicationState.rm(`app.${this.collection_name}_selected_view_id`);
        this.selected_view = null;
        await this.load();
        return this.dispatchEvent(new CustomEvent('filter_change', {
            bubbles: true,
            composed: true,
            detail: {}
        }));

    }

    async promptDiscardChanges() {
        let modal = new AppConfirmationModal();
        modal.modal_text = "Your filters have changed, are you sure you want to discard your changes?";
        await modal.showModal();
        return await modal.onDidDismiss();
    }

    async load() {
        let client_id = getCurrentClientId();
        let user_id = getCurrentUserId();

        let filters = {
            _and: [
                {
                    collection: this.collection_name,
                    status: 'published'
                },
                //get views assigned to this app, or ones assigned to all apps (null)
                {
                    _or: [
                        {
                            app: this.app_name
                        },
                        {
                            app: {
                                _null: true
                            }
                        }
                    ]
                },
                {
                    //get all views that are assigned to this user, 
                    //or the user is null and the client_id is assigned (client global views), 
                    //or the user is null and the client_id is null (global views)
                    _or: [
                        //views for this user
                        {
                            user_id: {
                                _eq: user_id
                            }
                        },
                        //client global views
                        {
                            _and: [
                                {
                                    user_id: {
                                        _null: true
                                    }
                                },
                                {
                                    client_id: {
                                        _eq: client_id
                                    }
                                }
                            ]
                        },
                        //system global views
                        {
                            _and: [
                                {
                                    user_id: {
                                        _null: true
                                    }
                                },
                                {
                                    client_id: {
                                        _null: true
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        };

        //if a screen is specified, limit to that screen
        if (this.screen)
            filters._and.push({
                _or: [
                    {
                        screen: this.screen
                    },
                    {
                        screen: {
                            _null: true
                        }
                    }
                ]
            })

        let result = await directus.items('saved_view').readByQuery(
            {
                fields: ['*'],
                filter: filters,
                sort: ['sort']
            }
        );

        //sort so that gobal views are first, in sort order, then client view, then user views
        let data = result.data.sort(
            (a, b) => {
                function rank(view) {
                    let score = view.sort;
                    if (view.user_id)
                        return score + 2000;
                    if (view.client_id)
                        return score + 1000;
                    return score;
                }
                return rank(a) - rank(b);
            }
        )

        this.data = result.data;
        this.render();
    }
}

customElements.define('app-filter-saved-views', AppFilterSavedViews)
export default AppFilterSavedViews;
