import {html, render} from "lit";
import {format} from "date-fns";
import directus from "../lib/lib-directus";
import cache from "../lib/lib-cache";
import DataDefinition from "../lib/lib-data-definition";
import "../components/patient/app-patient-note";
import { adddirectusClientFilter } from "../lib/lib-user";

export default class ScenePatientCommunication extends HTMLElement {
    constructor() {
        super();
        this.patient_communications = [];
        this._patient_header_height = 213;
        this.boundResizeHandler = this.handleWindowResize.bind(this);
        this._current_tab = "communication";
    }

    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        if (!(this.patient?.id == value)) this.init();
    }

    get patient_id() {
        return this._patient_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    

    connectedCallback() {
        this.template = () => html`
            <style>
                scene-patient-communication .note-header {
                    background-color: var(--t-color-primary);
                    color: white;
                    padding: 5px;
                    font-size: 12px;
                    font-weight: 700;
                    display: block;
                }
                scene-patient-communication .nav-pills .nav-link {
                    font-size: 14px;
                    color: var(--t-color-dark);
                    border: 1px solid #fff;
                    margin-right: 4px;
                }
                scene-patient-communication .nav-pills .nav-link:hover {
                    border: 1px solid var(--t-color-dark);
                }
                scene-patient-communication .nav-pills .nav-link.active {
                    font-weight: 600;
                    background-color: var(--t-color-primary);
                }
                scene-patient-communication .nav-pills .nav-link.active:hover {
                    border: 1px solid #fff;
                }
                scene-patient-communication .list-group-item {
                    border: none;
                    border-radius: 8px;
                    box-shadow: var(--t-box-shadow);
                    margin-bottom: 8px;
                }
                @media (max-width: 768px) {
                }
                @media (min-width: 768px) {
                }
            </style>
            <app-patient-header .page=${"communication"} .patient=${this.patient}></app-patient-header>
            <div style="height: calc(100% - 40px - ${this._patient_header_height}px); padding-top: 16px;">
            <div style="margin: 0 32px 16px 32px; border-radius: 8px; height: 100%;">
            <ul class="nav nav-pills" style="width: 100%; border-radius: 8px; background: #fff; box-shadow: var(--t-box-shadow); padding: 8px; position: relative; z-index: 1;">
            ${this.links.map(link => html`
                <li class="nav-item">
                    <a href=${link.url} class="nav-link${this._current_tab === link.page ? " active" : ""}">${link.title}</a>
                </li>
            `)}
            </ul>
            ${this.renderContent()}
            </div>
        
            </div>
        </div>
        `;

        this.loadDefinition();
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    renderNavItem(tab_name) {
        const capitalized_label = tab_name.charAt(0).toUpperCase() + tab_name.slice(1);
        return html`
            <li class="nav-item">
                <button 
                    @click=${_e => this.handleTabClick(tab_name)}
                    class=${"nav-link" + (this._current_tab === tab_name ? " active" : "")}
                >
                    ${capitalized_label}
                </button>
            </li>
        `
    }

    handleTabClick(tab_name) {
        this._current_tab = tab_name;
        this.render();
    }

    renderContent() {
        if (this._current_tab === "communication") {
            return html`
                <div style="
                    height: calc(100% - 40px - ${this._patient_header_height}px); 
                    padding: 16px;
                    background: var(--t-color-white);
                    border-radius: 8px;
                    margin: 16px 0px 0 0px;
                ">
                    <h5 style="
                        margin-bottom: 16px;
                        padding-left: 8px;
                        color: var(--t-color-dark);
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 26px;
                    ">Patient Communication History</h5>
                    <table table table-responsive>
                        <thead>
                            <tr>
                                <th>USER NAME</th>  
                                <th>DATE/TIME</th>
                                <th>TYPE</th>    
                                <th>CALL DURATION</th>
                            </tr>
                        </thead>
                        ${this.patient_communications?.length ? html`
                            <tbody>
                                ${this.patient_communications.map(communication => html`
                                    <tr>
                                        <td data-label="USER NAME">
                                            ${communication.user_created?.first_name + " " + communication.user_created?.last_name}
                                        </td>
                                        <td data-label="DATE/TIME">
                                            ${format(new Date(communication.date_created), "MM/dd/y 'at' h:mm aaa")}
                                        </td>
                                        <td data-label="TYPE">
                                            ${this.patient_communication_definition.getChoiceLabel('communication_type', communication.communication_type)}
                                        </td>
                                        <td data-label="CALL DURATION">
                                            ${communication.call_duration >= 120 ? (Math.round(communication.call_duration / 60) +  " Minutes") : (communication.call_duration + " Seconds")}
                                        </td>
                                    </tr>
                                `)}
                            </tbody>
                        ` : html`
                            <div style="padding: 16px; font-style: italic; color: var(--t-color-grey);">No Communications</div>
                        `}
                        
                    </table>
                </div>
            `;
        }
    }

    get links() {
        const links = [
            { page: "all", url: `patients/${this.patient.id}/activity`, title: "All" },
            // { page: "alerts", url: `patients/${this.patient.id}/alerts`, title: "Alerts" },
            { page: "notes", url: `patients/${this.patient.id}/activity/notes`, title: "Memos" },
            { page: "communication", url: `patients/${this.patient.id}/activity/communication`, title: "Communication" },
            { page: "calls", url: `patients/${this.patient.id}/activity`, title: "Calls" },
            { page: "checkpoints", url: `patients/${this.patient.id}/activity`, title: "Checkpoints" },
            { page: "activity", url: `patients/${this.patient.id}/activity`, title: "Notes" },
            { page: "availability", url: `patients/${this.patient.id}/activity`, title: "Availability" },
        ];
        return links;
    }    


    render() {
        if (!this.template) return;
        if (!this.patient) return;
        render(this.template(), this);
    }

    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = this.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

    async init() {
        if (this._loading) return;
        //vaadin router will set the patient_id multiple times, so add a guard to prevent redundant loads
        this._loading = true;
        this.patient = await cache.getPatient(this.patient_id);
        await this.loadPatientCommunications();
        this._loading = false;
        // Set up responsive scroll container height
        window.addEventListener("resize", this.boundResizeHandler);
        this._patient_header_height = this.querySelector('app-patient-header').clientHeight;
        this.render();
    }

    /**
     * Load communication enum/dropdown interface labels from directus
     */
    async loadDefinition() {
        this.patient_communication_definition = await DataDefinition.getDefinition("patient_communication");
        this.render();
    }

    async loadPatientCommunications() {
        const result = await directus.items("patient_communication").readByQuery({
            fields: [
                "communication_type", 
                "call_duration", 
                "user_created.id", 
                "user_created.first_name", 
                "user_created.last_name", 
                "date_created"
            ],
            filter: adddirectusClientFilter({           
                caller_type: { _in: ["client", "clinician"] },
                patient_id: this.patient_id,
            }),
            sort: "-date_created"
        });
        this.patient_communications = result.data;
    }
}

customElements.define("scene-patient-communication", ScenePatientCommunication);
