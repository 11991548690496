import { html, render } from 'lit';
import { createRef, ref } from 'lit/directives/ref.js';
import './app-filter-item';
import AppFilterItem from './app-filter-item';
import AppFilterUtil from './util/app-filter-util';

/**
 * @typedef {Object} FilterGroup
 * @property {string} type
 * @property {import('./app-filter-item').FilterItem[]} filters
 * @property {FilterGroup[]} groups
 */

class AppFilterGroup extends HTMLElement {

    set group(value) {
        this._group = value;
        this.render();
    }

    /** @type {FilterGroup} */
    get group() {
        return this._group;
    }

    set filters(value) {
        if (typeof value == 'undefined')
            value = [];
        this._group.filters = value;
    }

    /** @type {import('./app-filter-item').FilterItem[]} */
    get filters() {
        if (!this._group.filters)
            this._group.filters = [];
        return this._group.filters;
    }

    set group_type(value) {
        this._group.type = value;
    }

    get group_type() {
        return this._group.type;
    }

    set groups(value) {
        this._group.groups = value;
        this.render();
    }

    get groups() {
        return this._group.groups;
    }

    set collection_config(value) {
        this._collection_config = value;
    }

    /** @type {import('./app-filter').CollectionConfig} */
    get collection_config() {
        return this._collection_config;
    }

    set show_group_label(value) {
        this._show_group_label = value;
    }

    get show_group_label() {
        return this._show_group_label;
    }

    set show_group_delete(value) {
        this._show_group_delete = value;
    }

    get show_group_delete() {
        return this._show_group_delete;
    }

    constructor() {
        super();
        this._group = {
            type: "_and",
            filters: [],
            groups: []
        }
        this._show_group_label = true;
        this._show_group_delete = true;
    }

    connectedCallback() {
        this.template = () => html`
            <div @mouseenter=${e => this.showDelete(e)} @mouseleave=${e => this.hideDelete(e)} class="app-filters-group-container">
                ${this.show_group_label ?
                html`
                <div class="app-filters-group-label">
                    <select @change=${e => this.group_type = e.target.value}>
                        <option ${this.group_type == '_and' ? 'selected' : ''} value="_and">and</option>
                        <option ${this.group_type == '_or' ? 'selected' : ''} value="_or">or</option>
                    </select>
                </div>
                `: ''}
                <div class="app-filters-group-content">
                   
                    <div 
                        @click=${e => this.handleAddFilter()}
                        class="app-filters-group-prompt-filter" 
                        style="width: fit-content;
                                background: #4e4f7f;
                                color: white;
                                padding: 3px 15px 3px 9px;
                                cursor: pointer;
                                font-size: 12px;
                                font-weight: 600;
                                border-radius: 3PX;
                                "
                        >
                        + ADD FILTER 
                            <!-- <span
                                class="material-symbols-outlined"
                                style='
                                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                '
                            >filter_list</span> -->
                    </div>
                     ${this.filters.map(
                    filter => html`
                        <app-filter-item 
                            .collection_config=${this.collection_config} 
                            @delete=${e => this.handleDeleteFilter(e, filter)} 
                            .filter=${filter}></app-filter-item>
                        `)}
                    ${this.group.groups?.map(
                        group =>
                            html`<app-filter-group ${ref(group.ref)} .collection_config=${this.collection_config} @delete=${e => this.handleDeleteGroup(e, group)} .group=${group}></app-filter-group>`
                    )
            }
                    <div
                        @click=${e => this.handleAddGroup()}
                        class="app-filters-group-prompt">
                        Add a logic group (
                            <span
                                class="material-symbols-outlined"
                                style='
                                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                '
                            >data_object</span>)
                    </div>
                    
                    ${this.show_group_delete ? html`
                    <div 
                        @click=${(e) => {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    this.dispatchEvent(
                        new CustomEvent('delete', { composed: true, bubbles: true })
                    );
                }}
                        class="app-filters-group-delete app-filters-group-prompt">
                        <div
                            class="material-symbols-outlined"
                            style='
                                font-size: 15px;
                                margin-right: 5px;
                                cursor: pointer;
                                color: var(--t-color-danger);
                                font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                            '
                        >cancel</div> 
                        Delete this group
                    </div>
                    `: ''}
                </div>
            </div>
        `;
        this.render();
    }

    render() {
        if (!this.template)
            return;
        render(this.template(), this);
    }

    getFilters(group) {
        if (!group)
            group = this.group;
        return AppFilterUtil.getFilters(group);
    }

    showDelete(e) {
        if (e)
            e.stopImmediatePropagation();
        if (!this.show_group_delete)
            return;
        let element = this.querySelector('.app-filters-group-delete');
        if (!element)
            return;
        element.style.display = 'flex';
    }

    hideDelete(e) {
        if (e)
            e.stopImmediatePropagation();
        if (!this.show_group_delete)
            return;
        let element = this.querySelector('.app-filters-group-delete');
        if (!element)
            return;
        element.style.display = 'none';
    }

    handleAddGroup() {
        this.hideDelete();
        if (!this.group.groups)
            this.group.groups = [];

        this.group.groups.push({ groups: [], filters: [], ref: createRef() });
        this.render();
    }

    handleAddFilter() {
        if (!this.group.filters)
            this.group.filters = [];

        this.group.filters.push({});
        this.render();
    }

    handleDeleteGroup(e, group) {
        e.stopImmediatePropagation();
        let index = this.group.groups.findIndex(search_group => search_group == group);
        this.group.groups.splice(index, 1);
        this.render();
    }

    handleDeleteFilter(e, filter) {
        if (e)
            e.stopImmediatePropagation();
        let index = this.group.filters.findIndex(search_filter => search_filter == filter);
        this.group.filters.splice(index, 1);
        this.render();
        this.dispatchEvent(new CustomEvent('item-change', { composed: true, bubbles: true }));
    }
}

customElements.define('app-filter-group', AppFilterGroup);
export default AppFilterGroup;