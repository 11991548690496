import { html, render } from "lit";
import { navigate } from "shared/lib/lib-router";

/**
 * @param {object} visitData response from /dashboard_visit_metrics
 * A widget with visit metrics for the current client.
 */
export default class AppDashboardVisits extends HTMLElement {
    set visitData(value) {
        this._visitData = value;
        this.render();
    }

    get visitData() {
        return this._visitData;
    }

    connectedCallback() {
        this.template = () => {
            const { total_visits, next_visit, visit_types } = this.visitData || {}
            return html`
                <style>
                    #dashboard-visits .widget-key-value {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    #dashboard-visits .widget-key-value span:last-child {
                        margin-left: 8px;
                    }
                </style>
                <div 
                    id="dashboard-visits" 
                    style="height: 100%; cursor: pointer;"
                    @click=${_e => navigate(`reports/patient/client-calendar`)}
                >
                    <h6>Upcoming Visits</h6>
                    <div style="
                        display: flex; 
                        flex-direction: column; 
                        align-items: center; 
                        justify-content: center;
                        height: calc(100% - 26px);
                    ">
                        <div style="
                            display: flex; 
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: 32px;
                        " >
                            <span style="
                                font-weight: 700;
                                font-size: 50px;
                                line-height: 56px;
                                letter-spacing: 0.5px;
                                color: var(--t-color-dark);
                            "
                            >
                                ${total_visits || 0}
                            </span>
                            <span style="
                                font-size: 14px;
                                font-weight: 700;
                                color: var(--t-color-grey);
                            ">
                                Total Upcoming Visits
                            </span>
                        </div>
                        ${next_visit ? html`
                            <div class="widget-key-value">
                                <span>Next Visit:</span>
                                <span>${new Date(next_visit.visit_date).toLocaleDateString()}</span>
                            </div>
                            <div class="widget-key-value">
                                <span>Caregiver:</span>
                                <span>${next_visit.careteam_member.first_name} ${next_visit.careteam_member.last_name}</span>
                            </div>
                        ` : ''}
                        <h6 style="margin-top: 16px; margin-bottom: 8px;">Visit Types</h6>
                        ${visit_types ? Object.entries(visit_types).map(([type, count]) => html`
                            <span class="widget-key-value">
                                <span>${type}</span>
                                <span>${count}</span>
                            </span>
                        `) : ''}
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
        });
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-dashboard-visits", AppDashboardVisits);
