import { html, render } from "lit";
import "shared/components/app-page-header";
import ApplicationState from "applicationstate";

//import ReportPatientAvailability from "../reports/patient/report-patient-availability";
import ReportPatientHighRiskEvents from "../reports/patient/report-patient-high-risk-events";
import ReportPatientHighRiskIncidentBreakout from "../reports/patient/report-patient-high-risk-incident-breakout";
import ReportPatientSatisfaction from "../reports/patient/report-patient-satisfaction";
import ReportPatientHospitalization from "../reports/patient/report-patient-hospitalization";
import ReportClinicianCalls from "../reports/clinician/report-clinician-calls";
//import ReportPatientFalls from "../reports/patient/report-patient-falls";
//import ReportPatientSideEffects from "../reports/patient/report-patient-side-effects";
import ReportPatientCpReports from "../reports/patient/report-patient-cp-report";
import ReportClientCalendar from "../reports/patient/report-client-calendar";
import ReportPatientEpisodes from "../reports/patient/report-patient-episodes";
import ReportPatientCharts from "../reports/patient/report-patient-charts";
import ReportPatientVisits from "../reports/patient/report-patient-visits";
import ReportPatientOasis from "../reports/patient/report-patient-oasis";
import ReportPatientOasisAlt from "../reports/patient/report-patient-oasis-alt";
import ReportPatientHosp from "../reports/patient/report-patient-hosp";

const available_reports = {
    patient: [
        //ReportPatientAvailability.config,
        ReportClientCalendar.config,
        ReportPatientHighRiskEvents.config,
        //ReportPatientHospitalization.config,
        ReportPatientSatisfaction.config,
        // ReportPatientFalls.config,
        // ReportPatientSideEffects.config,
        ReportPatientHighRiskIncidentBreakout.config,
        ReportPatientCpReports.config,
        ReportPatientOasis.config,
        ReportPatientHospitalization.config,
        ReportPatientHosp.config,


    ],
    clinician: [
        ReportClinicianCalls.config,
    ],
    admin: [
        ReportPatientEpisodes.config,
        ReportPatientCharts.config,

        ReportPatientVisits.config,
        ReportPatientOasisAlt.config,


    ]
};

available_reports.all = [];
for (const key in available_reports) {
    if (key === "all") continue;
    available_reports[key].forEach((r) => r.category = key);
    available_reports.all = available_reports.all.concat(available_reports[key]);
}

export default class SceneReports extends HTMLElement {
    get links() {
        const user = ApplicationState.get("app.user");
        const isAdmin = user?.role?.name === "Administrator";

        const baseLinks = [
            { page: "all", url: "reports", title: "All" },
            { page: "patient", url: "reports/patient", title: "Patient" },
            { page: "clinician", url: "reports/clinician", title: "Clinician" },
        ];

        if (isAdmin) {
            baseLinks.push({ page: "admin", url: "reports/admin", title: "Admin" });
        }

        return baseLinks;

    }

    get location() {
        return this._location;
    }

    set location(value) {
        this._location = value;
        if (value.params && value.params.category) {
            this.category = value.params.category;
            if (value.params.report) {
                this.report = available_reports[this.category].find((r) => r.report === value.params.report);
            }
        }
        this.render();
    }


    set category(value) {
        this._category = value;
        this.render();
    }

    get category() {
        return this._category;
    }

    set report(value) {
        this._report = value;
        this.render();
    }

    get report() {
        return this._report;
    }

    constructor() {
        super();
        this.category = "all";

        this.print_media_query = window.matchMedia("print");
    }

    connectedCallback() {


        this.template = () => {
            return html`
                <style>
                    scene-reports th, scene-reports td {
                        text-align: left;
                    }
                </style>
                <app-page-header 
                    .icon=${"trending_up"}
                    .links=${this.links} 
                    .page=${this.category} 
                    .title=${this.report ? `Reports - ${this.report.title}` : 'Reports'}
                    .sub_title=${this.report?.title || ""}
                    class="reports-header"
                ></app-page-header>
                <div id="patients-report-container">
                    ${!this.report ? html`
                    <div class="mod-base" style="margin:0px 12px;">
                        <table class="table reports-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${available_reports[this.category].map((report) => html`<tr>
                                    <td>
                                        ${report.icon ? html`
                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                <span class="material-symbols-outlined">${report.icon}</span>
                                            </div>
                                        ` : ""}
                                    </td>
                                    <td><a href=${`/client/reports/${report.category}/${report.report}`}>${report.title}</a></td>
                                    <td>${report.description}</td>
                                </tr>`)}
                            </tbody>
                        </table>
                    </div>` : new this.report.component()}
                </div>
            `;
        }

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        })

        this.render();
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }
}

customElements.define("scene-reports", SceneReports);
