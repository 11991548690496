import { html, render } from 'lit';
import directus from '../../lib/lib-directus';
import '../app-pill';
import CommModalBase from '../util/comm-modal-base';
import DataDefinition from '../../lib/lib-data-definition';

export default class AppCommunicationCreateModal extends CommModalBase {
    constructor() {
        super();
        this._task = null;

        this.communication_type = null;
        this.status = null;
        this.status_reason = null;
    }

    set patient_communication(value) {
        this._patient_communication = value;
        this._patient_id = value.patient_id;
        this._client_id = value.client_id;
    }

    get patient_communication() {
        return this._patient_communication;
    }

    set client_id(value) {
        this._client_id = value;
    }

    get client_id() {
        return this._client_id;
    }

    set patient_id(value) {
        this._patient_id = value;
    }

    get patient_id() {
        return this._patient_id;
    }

    set task(value) {
        this._task = value;
        this.render();
    }

    get task() {
        return this._task;
    }

    set patient_communication_definition(value) {
        this._patient_communication_definition = value;
    }

    get patient_communication_definition() {
        return this._patient_communication_definition;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #create-communication-modal select {
                        cursor: pointer;
                    }
                </style>
                <div id="create-communication-modal" class="modal-dialog modal-dialog-centered modal-fullscreen-md-down" data-bs-backdrop="static" data-bs-keyboard="false">

                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Create Communication</h5>
                            
                        </div>
                        <form id="create_alert_form" novalidate>
                            <div class="modal-body">
                                    <div class="mb-3">
                                        <label for="communication_type" class="form-label">Communication Type</label>
                                        <select @change=${e => this.communication_type = e.target.value} class="form-select" id="communication_type" aria-label="Communication Type" required>
                                            ${this.getDropdownOptions("communication_type")}
                                        </select>
                                        <div class="invalid-feedback">
                                            Please select the type of communication.
                                        </div>
                                    </div>
                                    <div class="mb-3 status_select_label">
                                        <label for="status_select" class="form-label">Status</label>
                                        <select @change=${e => this.status = e.target.value} class="form-select" id="status_select" aria-label="Status">
                                            ${this.getDropdownOptions("status")}
                                        </select>
                                        <div class="invalid-feedback">
                                            Please select a communication status.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label for="status_reason_select" class="form-label">Outcome</label>
                                        <select @change=${e => this.status_reason = e.target.value} class="form-select" id="status_reason_select" aria-label="status_reason_select" required>
                                            ${this.getDropdownOptions("status_reason")}
                                        </select>
                                        <div id="status_reason_help" class="form-text">Select if you were unable to complete the checkpoint</div>
                                    </div>
                                    <div class="mb-3">
                                        <label for="notes_textarea" class="form-label">Notes</label>
                                        <textarea @change=${e => this.notes = e.target.value} class="form-control" id="notes_textarea" aria-label="Notes"></textarea>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                
                                <button 
                                    type="button" 
                                    @click=${e => this.handleSubmit()}
                                    class="btn btn-primary"
                                    style="color: white;"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            `;
        }
        this.render();
        this.init();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    async init() {
        await this.loadDefinition();
        this.render();
    }

    /**
     * @param {string} field_name a dropdown/enum field
     * @returns a list of <option>s, based on the directus field interface
     */
    getDropdownOptions(field_name) {
        const options = [html`<option selected></option>`]
        let selected_option;
        if (this.patient_communication)
            selected_option = this.patient_communication[field_name];
        let choices = this.patient_communication_definition?.getChoices(field_name) || [];
        for (let { value, label } of choices) {
            options.push(
                html`<option ?selected=${selected_option === value} value=${value}>${label}</option>`
            )
        }
        return options;
    }

    /**
     * Load communication enum/dropdown interface labels from directus
     */
    async loadDefinition() {
        this.patient_communication_definition = await DataDefinition.getDefinition("patient_communication");
    }

    async handleSubmit() {
        const form_element = this.querySelector('#create_alert_form');
        const statusReasonSelect = this.querySelector('#status_reason_select');

        // Check if the status_reason is selected before proceeding
        if (!this.status_reason) {
            // If not selected, add 'is-invalid' class and shake the select element
            statusReasonSelect.classList.add('is-invalid', 'shake');
            setTimeout(() => statusReasonSelect.classList.remove('shake'), 500);
            return; // Stop the function here as the form is invalid
        } else {
            // If selected, remove any previous error state
            statusReasonSelect.classList.remove('is-invalid');
        }

        // If status_reason is valid, proceed with the update or create logic
        if (this.patient_communication) {
            return await this.handleUpdateCommunication();
        } else {
            return await this.handleCreateCommunication();
        }
    }



    /**
     * If we're updating an existing communication
     */
    async handleUpdateCommunication() {
        const form_element = this.querySelector('#create_alert_form');
        form_element.classList.add('was-validated');
        let valid = form_element.checkValidity();
        if (!valid)
            return;

        this.patient_communication = await directus.items('patient_communication').updateOne(this.patient_communication.id,
            {
                status: this.status,
                status_reason: this.status_reason,
                notes: this.notes,
            });
        this.dismiss({ success: true, communication: this.patient_communication });

    }

    /**
     * If required fields are filled, create a new communication on the server.
     */
    async handleCreateCommunication() {
        const form_element = this.querySelector('#create_alert_form');
        form_element.classList.add('was-validated');
        const valid = form_element.checkValidity();
        if (valid) {
            this.patient_communication = await directus.items('patient_communication').createOne(
                {
                    client_id: this.client_id,
                    patient_id: this.patient_id,
                    task_id: this.task?.id,
                    communication_type: this.communication_type,
                    status: this.status,
                    status_reason: this.status_reason,
                    notes: this.notes,
                }
            );
            this.dismiss({ success: true, communication: this.patient_communication });
        }
    }
}

customElements.define('app-communication-create-modal', AppCommunicationCreateModal);
