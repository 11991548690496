import { addDays } from "date-fns";
import { html, render } from "lit";
import { easepick } from '@easepick/bundle';

import directus from "shared/lib/lib-directus";
import { getCurrentClientId, getCurrentUserId } from "shared/lib/lib-user";

import "../components/visit-confirmation/app-visit-card";

/**
 * @typedef {object} VisitConfirmationPatient
 * @property {string} id
 * @property {string} first_name
 * @property {string} last_name
 * @property {Date} birth_date
 * @property {Date} last_visit_date
 * @property {Date} next_visit_date
 * 
 */

/**
 * @typedef {object} Visit
 * @property {string} id
 * @property {string} client_id
 * @property {string} patient_id
 * @property {string} careteam_member_id
 * @property {Date} visit_date
 * @property {string} address
 * @property {string} city
 * @property {string} state
 * @property {string} zip_code 
 * @property {string} visit_status - The status of the visit from the source EHR
 * @property {string} visit_type
 * @property {string} visit_description
 * @property {string} status - The status of the visit record (imported, etc...)
 * @property {string} user_created
 * @property {string} user_updated
 * @property {Date} date_created
 * @property {string} date_updated
 * @property {object} source_data - The original record from the EHR. Excluded by default in queries
 * @property {VisitConfirmationPatient} patient
 */

class SceneVisitConfirmation extends HTMLElement {
    set visits(value) {
        this._visits = value;
        this.render();
    }

    /**
     * @type {Visit[]}
     */
    get visits() {
        return this._visits || [];
    }

    set selected_date(value) {
        this._selected_date = value;
    }

    /**
     * @type {Date}
     */
    get selected_date() {
        if (!this._selected_date) {
            let default_date = new Date();
            //if it's after 3pm, default to next day's visit
            if (default_date.getHours() >= 15)
                default_date = addDays(default_date, 1);

            this._selected_date = default_date;
        }

        return this._selected_date;
    }

    get confirmed_visits() {
        return this._visits.filter(
            visit => visit.status == "confirmed"
        )
    }

    get unconfirmed_visits() {
        return this._visits.filter(
            visit => visit.status == "imported"
        )
    }

    get denied_visits() {
        return this._visits.filter(
            visit => visit.status == "denied"
        )
    }

    set location(value) {
        this._location = value;
    }

    get location() {
        return this._location;
    }

    constructor() {
        super();
    }

    connectedCallback() {
        this.template = () => html`
            <style>
                .visit-confirmation-header-label {
                    font-weight: bold;
                    color: var(--t-color-dark);
                }

            </style>
            <div style="
                margin: 0 32px;  
                padding: 12px; 
                position: relative; 
                background-color: #fff; 
                height: 100px; 
                box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                border-radius: 8px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            ">
                <div>
                    <small style="display: flex; align-items: center;">
                    <span class="material-symbols-outlined" style="margin-right: 5px; ">free_cancellation</span>
                    Visit Confirmation</small>
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        <h1 style="
                            font-style: normal;
                            font-weight: 700;
                            font-size: 30px;
                            letter-spacing: 0.5px;
                            color: #131313;
                            margin-bottom: 0px;
                        ">${this.selected_date.toLocaleDateString()}</h1>
                        <div style="
                            position: relative; 
                            width: 30px; 
                            margin-left: 20px; 
                            height: 30px;">
                            <div 
                                class="material-symbols-outlined" 
                                style="
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;
                                    font-size: 30px;">calendar_month</div>
                            <!--hidden layer for datepicker click-->
                            <div
                                id="visit_confirmation_date" 
                                style="
                                    cursor: pointer;
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;
                                    height: 100%;
                                    width: 100%;
                                    z-index: 100;
                                    color: transparent;
                                    font-size: 0px;
                                "
                            ></div>

                        </div>

                    </div>
                </div>
                <div style="font-size: 13px">
                    <div><span class="visit-confirmation-header-label">Visits scheduled:</span> ${this.visits?.length}</div>
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 10px;">
                        <span style="margin-right: 10px;"><span class="visit-confirmation-header-label">Confirmed:</span> ${this.confirmed_visits?.length} </span>
                        <span style="margin-right: 10px;"><span class="visit-confirmation-header-label">Unconfirmed:</span> ${this.unconfirmed_visits?.length}</span>
                        <span><span class="visit-confirmation-header-label">Denied:</span> ${this.denied_visits?.length}</span>
                    </div>
                </div>
            </div>
            <div class="row px-3 pt-2" id="patients-scroll-container">
                    ${this.visits.length ?
                html`
                    ${this.visits.map(
                    visit => html`
                        <div class="col-sm-12 col-md-6 col-lg-4 px-3" style="margin-top: 20px; height: 128px;">
                            <app-visit-card .visit=${visit} @visit-edited=${e => this.loadVisits()}></app-visit-card>
                        </div>
                        `
                )}
                    `:
                html`
                    <div>There are no visits imported for the specified date</div>
                    `}

                
            </div>`;

        this.init();
    }

    render() {
        if (!this.template)
            return;
        if (!this.visits)
            return;

        render(this.template(), this);
    }

    async init() {
        //this renders when calling set visits
        await this.loadVisits();
        //this needs to be initialized after render
        this.initDateSelector();
    }

    initDateSelector() {
        this.datepicker = new easepick.create({
            element: this.querySelector('#visit_confirmation_date'),
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            ],
            firstDay: 0,
            zIndex: 10
        });
        this.datepicker.on("select", (e) => this.handleDateSelect(e));

    }

    async loadVisits() {
        let start_date = this.selected_date;
        start_date.setHours(0, 0, 0, 0);
        let end_date = new Date(start_date);
        end_date.setHours(23, 59, 59, 999);

        let result = await directus.items("visit").readByQuery(
            {
                filter: {
                    client_id: getCurrentClientId(),
                    visit_date: {
                        _between: [start_date, end_date]
                    },
                    /*
                    patient_id: {
                        visits: {
                            _sort: "visit_date",
                            _limit: 1,
                            _filter: {
                                visit_date: {
                                    _lt: new Date()
                                }
                            }
                        }
                    }
                    */
                },
                fields: [
                    "id",
                    "client_id",
                    "patient_id",
                    "careteam_member_id",
                    "careteam_member_id.first_name",
                    "careteam_member_id.last_name",
                    "careteam_member_id.phone_number",
                    "visit_date",
                    "address",
                    "city",
                    "state",
                    "zip_code",
                    "visit_status",
                    "visit_type",
                    "visit_description",
                    "status",
                    "user_created",
                    "user_updated",
                    "date_created",
                    "date_updated",
                    "patient_id.id",
                    "patient_id.mrn",
                    "patient_id.birth_date",
                    "patient_id.first_name",
                    "patient_id.last_name",
                    "patient_id.primary_phone",
                    "patient_id.visits.id",
                    "patient_id.visits.visit_date",
                ],

            }
        );
        /** @type {Visit[]} */
        let visits = result.data;

        if (visits.length == 0) {
            this.visits = [];
            return;
        }

        let patient_ids = new Set();
        for (let visit of visits)
            patient_ids.add(visit.patient_id.id);

        /* -- last and next visits not currently being used, but might soon. remove if not used in a month --ccg 2023-02-28
        //load the most recent visits for the displayed patients
        result = await directus.items("visit").readByQuery(
            {
                filter: {
                    
                    patient_id: {
                        _in: Array.from(patient_ids)
                    },
                    visit_date: {
                        _lt: start_date
                    }

                },
                aggregate: {
                    max: "visit_date"
                },
                groupBy: "patient_id"
            },
        );
        let last_visits = result.data;

        //load the next visits
        result = await directus.items("visit").readByQuery(
            {
                filter: {
                    
                    patient_id: {
                        _in: Array.from(patient_ids)
                    },
                    visit_date: {
                        _gt: new Date()
                    }

                },
                aggregate: {
                    min: "visit_date"
                },
                groupBy: "patient_id"
            },
        );
        let next_visits = result.data;
        */

        for (let visit of visits) {
            //clean up the model a bit
            let patient = visit.patient_id;
            let patient_id = patient.id;
            visit.patient = patient;
            visit.patient_id = patient_id;
            //visit.patient.last_visit_date = getLastVisitDateForPatient(visit.patient_id);
            //visit.patient.next_visit_date = getNextVisitDateForPatient(visit.patient_id);
        }

        this.visits = visits;

        /*
        function getLastVisitDateForPatient(patient_id) {
            let row = last_visits.find(visit => visit.patient_id == patient_id );
            if(!row)
                return "Unknown";
            return new Date(row.max.visit_date).toLocaleDateString();
        }

        function getNextVisitDateForPatient(patient_id) {
            let row = next_visits.find(visit => visit.patient_id == patient_id );
            if(!row)
                return "Unknown";
            return new Date(row.min.visit_date).toLocaleDateString();
        }
        */
    }

    async handleDateSelect(e) {
        this.selected_date = e.detail.date;
        await this.loadVisits();
    }
}

customElements.define("scene-visit-confirmation", SceneVisitConfirmation);
export default SceneVisitConfirmation;
