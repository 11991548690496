import { html, render } from "lit";
import { login, checkAppAccess } from "../lib/lib-session";

export default class AppLogin extends HTMLElement {
    constructor() {
        super();
        this._error = null;
    }

    get error() {
        return this._error;
    }

    set error(value) {
        this._error = value;
        this.render();
    }

    get appname() {
        return this._app_name;
    }

    set appname(value) {
        this._app_name = value;
        this.render();
    }

    connectedCallback() {
        this.template = () => html` <style>
                .form-signin {
                    background: var(--bs-white);
                    width: 100%;
                    max-width: 330px;
                    padding: 0;
                    margin: auto;
                    margin-top: 10vh;
                    border-radius: 8px;
                    box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                }

                .form-signin-logo {
                    
                    border-radius: 8px 8px 0 0;
                    padding: 15px 15px 0 15px;
                }

                .form-signin-controls {
                    /*padding: 15px;*/
                }

                .form-signin-controls .checkbox {
                    font-weight: 400;
                }

                .form-signin-controls .form-floating:focus-within {
                    z-index: 2;
                }

                .form-signin-controls input[type="email"] {
                    margin-bottom: -1px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }

                .form-signin-controls input[type="password"] {
                    margin-bottom: 10px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            </style>
            <main class="form-signin" id="form-signin">
                <div class="form-signin-logo"><img class="mb-4" src="images/vb-h-logo-white-alpha.svg" alt="" /></div>
                <div class="form-signin-controls">
                    ${this.error ? html`<div class="alert alert-danger error-message">${this.error}</div>` : ""}
                    <form onsubmit="return false" @submit=${(e) => this.handleLoginSubmit(e)}>
                        <!-- <h1 class="h3 mb-3 fw-normal">Please sign in</h1> -->

                        <div class="form-floating">
                            <input type="email" class="form-control" id="login-email" placeholder="name@example.com" />
                            <label for="login-email">Email address</label>
                        </div>
                        <div class="form-floating">
                            <input type="password" class="form-control" id="login-password" placeholder="Password" />
                            <label for="login-password">Password</label>
                        </div>
<div class="login-group">
                        <button 
                            class="mt-3 btn btn-lg btn-primary" 
                            id="login-submit" 
                            type="submit">Login</button>

                        <div class="pass-reset">
                            <a style="color: ${this._is_clinician_app ? "var(--t-color-primary)" : "var(--t-color-sidebar)"};" href="/${this.appname}/resetpassword">Forgot your password?</a>
                        </div>
</div>
                        <p class="copyright text-muted">&copy; 2024 - VisitBridge Health</p>
                    </form>
                </div>
            </main>`;

        Object.assign(this.style, {
            flex: 1,
        });

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Respond to a submit event on the login form.
     * @todo Add better form validation and log in error handling
     * @param {Event} e
     * @returns {Promise<void>}
     */
    async handleLoginSubmit(e) {
        e.preventDefault();
        this.error = null;
        this.render();

        const email = document.getElementById("login-email").value;
        const password = document.getElementById("login-password").value;

        if (!email || !password) {
            return;
        }

        try {
            const user = await login(email, password);
            checkAppAccess(user);
            const evt = new Event("change");
            evt.user = user;
            this.dispatchEvent(evt);
        } catch (err) {
            this.error = err.message;
            this.render();
        }

        return false;
    }
}

customElements.define("app-login", AppLogin);
