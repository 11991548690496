import { html, render } from "lit";

import SceneInsightsCheckpoints from "./scene-insights-checkpoints";

import "../components/app-page-header";
import SceneInsightsPatients from "./scene-insights-patients";
import SceneInsightsAlerts from "./scene-insights-alerts";
import SceneInsightsCalls from "./scene-insights-calls";
import SceneInsightsPatientsPlus from "./scene-insights-patients-plus";

export default class SceneInsights extends HTMLElement {
    get links() {
        return [
            { title: "Checkpoints", page: "checkpoints", url: "insights/checkpoints", component: SceneInsightsCheckpoints, },
            { title: "Patients", page: "patients", url: "insights/patients", component: SceneInsightsPatients, },
            { title: "Alerts", page: "alerts", url: "insights/alerts", component: SceneInsightsAlerts, },
            { title: "Calls", page: "calls", url: "insights/calls", component: SceneInsightsCalls, },
            { title: "Patient +", page: "patients_plus", url: "insights/patients-plus", component: SceneInsightsPatientsPlus, }
        ];
    }

    get page() {
        return this._page;
    }

    set page(value) {
        this._page = value;
        this.render();
    }

    get error_message() {
        return this._error_message;
    }

    set error_message(value) {
        this._error_message = value;
    }

    set location(value) {
        // check if the path is the same, don't re-render if so.
        if (this._location && this._location.path === value.path) return;
        this._location = value;
        for (const link of this.links) {
            if (value.route.path === "/" + link.url) {
                this.component = new link.component();
                this.page = link.page;
            }
        }
    }

    constructor() {
        super();
        this._page = "checkpoints";
        this.component = new SceneInsightsCheckpoints();
    }

    connectedCallback() {
        this.template = () => {
            return html`
            <app-page-header
                style="margin: 0 32px 20px 32px;"
                .icon=${"insights"}
                .title=${"Insights"}
                .links=${this.links}
                .page=${this.page}
            ></app-page-header>
            <div class="alert alert-danger" style="display: ${this.error_message ? "block" : "none"}">
                ${this.error_message}
            </div>
            <div>
                ${this.component}
            </div>
           `;
        };

        this.render();
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }
}

customElements.define("scene-insights", SceneInsights);