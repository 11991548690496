import { html, render } from "lit";
import ModalBase from './util/modal-base';

/**
 * @param modal_text
 */
export default class AppCalendarModal extends ModalBase {
    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    .modal-body div {
                        margin: 10px 0;
                    }
                    .modal-body button {
                        color: #fff;
                        width: 300px;
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered" style="width: 400px">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 style="display:contents;">Schedule Item</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body text-center modal-sched">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click=${(_e) => this.handleClick('unavailability')}>
                                    Add Unavailability
                                </button>
                            </div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click=${(_e) => this.handleClick('prn')}>
                                    Schedule PRN
                                </button>
                            <div>
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click=${(_e) => this.handleClick('self')}>
                                    Schedule Self Check
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click=${(_e) => this.handleClick('education')}>
                                    Schedule Education SMS
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click=${(_e) => this.handleClick('general')}>
                                    Schedule General SMS
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Click handler for 'Confirm' button.
     * Resolves the ModalBase promise by calling dismiss()
     */
    async handleClick(type) {
        this.dismiss({
            clicked: true,
            type
        });
    }
}

customElements.define("app-calendar-modal", AppCalendarModal);
