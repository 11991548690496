import ApplicationState from 'applicationstate';
import { html, render } from 'lit';
import directus from '../../lib/lib-directus';
import AppFilterUtil from './util/app-filter-util';

class AppFilterCsvExport extends HTMLElement {

    set filter_state(value) {
        this._filter_state = value;
    }

    get filter_state() {
        if (!this._filter_state) {
            this._filter_state = { group: { type: "_and", groups: [] }, user_search: '' };
        }

        if (this.additional_filters) {
            let result = structuredClone(this._filter_state);
            result.group.groups.push(this.additional_filters);
            return result;
        }
        return this._filter_state;
    }

    /**
     * Additional "hidden" filters that will be applied to any query. For example, {type: "_and", filters: [{field: "client_id", op: "_eq", value: "abc-healthcare"}] would restrict
     * all queries to only matching on that specifiec client_id. This will not be shown in the UI.
     * 
     * This works by adding the filters to the top level _and filter group
     * 
     * @param {FilterGroup} value - A filter group that has additional filters to add to the query
     */
    set additional_filters(value) {
        this._additional_filters = value;
    }

    /** @type {FilterGroup} */
    get additional_filters() {
        return this._additional_filters;
    }

    /**
     * This should be a flat array of objects that will be exported to csv
     */
    set rows(value) {
        this._rows = value;
    }

    get rows() {
        return this._rows;
    }

    set config(value) {
        this._config = value;
    }

    /** @type {import('./app-filter').AppFilterConfig} */
    get config() {
        return this._config;
    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    /** @type {string[]} The default columns to render in table view */
    get selected_columns() {
        return ApplicationState.get(`app.${this.config.collection.name}_selected_columns`) || this._selected_columns;
    }

    set loading(value) {
        this._loading = value;
        this.render();
    }

    get loading() {
        return this._loading
    }

    constructor() {
        super();
    }

    connectedCallback() {
        this.template = () => html`
        <div 
            @click=${e => this.handleClick(e)}
            style="
            cursor: pointer;
            color: var(--t-color-primary); 
            display: flex; 
            font-size: 14px;
            align-items: center;">
            <div>
            ${this.loading ?
                html`
                <img src="images/integration_running.gif" style="width: 26px; height: 26px";> Loading...
            `: 'Export CSV'}
            </div>
            <div class="material-symbols-outlined" style="font-size: 20px">download</div>
        </div>
        `;
        this.render();
    }

    render() {
        if (!this.template)
            return;
        render(this.template(), this);
    }


    async load() {
        if (this._loading)
            return;

        this.loading = true;

        await this.config.collection.configured;

        let query = AppFilterUtil.getQuery(this.config, this.filter_state);

        //for each path type column, make sure the id of the related entity is always present
        let id_columns = [];
        for (let column of this.selected_columns) {
            if (!column.includes('.'))
                continue;
            let parts = column.split('.');
            parts.pop();
            let id_column = parts.join('.') + ".id";
            if (!this.selected_columns.includes(id_column))
                id_columns.push(id_column);
        }
        query = Object.assign(query,
            {
                //ensure id is always selected
                fields: [...new Set(['id', 'client_id.id', ...id_columns, ...this.selected_columns])],
                meta: "filter_count",
                offset: 0,
                limit: -1,
            });

        let result;
        try {
            result = await directus.items(this.config.collection.name).readByQuery(query);
        }
        catch (err) {
            console.error(err);
        }
        this.rows = result.data;
        this.loading = false;

        this.render();
    }

    async handleClick(e) {
        e.stopImmediatePropagation();
        if (this.loading)
            return;
        await this.load();
        let csv = this.createCSV();
        this.download(csv);
    }

    createCSV() {
        const replacer = (key, value) => value === null ? '' : value;
        let header_columns = this.selected_columns.map(
            column => AppFilterUtil.getDisplayField(this.config.collection, column)
        );
        let header_row = header_columns.join(',');
        let csv_rows = this.rows.map(
            row => {
                return this.selected_columns.map(
                    column => {
                        let value = AppFilterUtil.formatValue(
                            this.config.collection,
                            column,
                            AppFilterUtil.getValue(column, row)
                        );
                        return JSON.stringify(value, replacer).replace(/\\"/g, '""')
                    }
                ).join(',')
            }
        );
        const csv = [
            header_row, // header row first
            ...csv_rows
        ].join('\r\n');
        return csv;
    }

    download(csv) {
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'download.csv');
        a.click();
    }
}

customElements.define("app-filter-csv-export", AppFilterCsvExport);
export default AppFilterCsvExport;
