import cache from "./lib-cache";
import directus from "./lib-directus";

class DataDefinition {
    /**
     * Basic factory function
     * @param {*} collection_name 
     * @returns 
     */
    static async getDefinition(collection_name) {
        let data_definition = new DataDefinition(collection_name);
        await data_definition.load();
        return data_definition;
    }

    /**
     * Get relations for a specific field in a collection
     * @param {string} collection_name - The collection containing the field
     * @param {string} field_name - The field to get relations for
     * @returns {Promise<Array>} Array of relations for the field
     */
    static async getRelationsForField(collection_name, field_name) {
        try {
            // Use Directus SDK to get relations
            const response = await directus.items('directus_relations').readByQuery({
                filter: {
                    _and: [
                        { collection: collection_name },
                        { field: field_name }
                    ]
                }
            });
            // Handle case where response or data is undefined
            return (response && response.data) ? response.data : [];
        } catch (err) {
            console.error(`Error getting relations for ${collection_name}.${field_name}:`, err);
            return [];
        }
    }

    /**
     * Get field definition from a collection
     * @param {string} collection_name - The collection containing the field
     * @param {string} field_name - The field to get
     * @returns {Promise<Object>} Field definition object
     */
    static async getField(collection_name, field_name) {
        try {
            // Get collection fields from cache
            const collection = await cache.getCollection(collection_name);
            // Find the specific field
            const field = collection.fields.find(f => f.field === field_name);
            if (!field) {
                console.warn(`Field ${field_name} not found in collection ${collection_name}`);
            }
            return field;
        } catch (err) {
            console.error(`Error getting field ${collection_name}.${field_name}:`, err);
            return null;
        }
    }

    /**
     * Get the actual type for a field, resolving alias fields to their target type
     * @param {string} collection_name - The collection containing the field
     * @param {string} field_name - The field to get the type for
     * @returns {Promise<string>} The resolved field type
     */
    static async getFieldType(collection_name, field_name) {
        try {
            const field = await this.getField(collection_name, field_name);
            if (!field) return 'unknown';

            // If it's not an alias field, return its type directly
            if (field.type !== 'alias') return field.type;

            // For alias fields, look up the relation and get the target field's type
            const relations = await this.getRelationsForField(collection_name, field_name);
            if (!relations[0]) return 'unknown';

            const targetField = await this.getField(relations[0].collection, relations[0].field);
            return targetField?.type || 'unknown';
        } catch (err) {
            console.error(`Error getting field type for ${collection_name}.${field_name}:`, err);
            return 'unknown';
        }
    }

    constructor(collection_name) {
        this.collection_name = collection_name;
        // Cache for resolved field types
        this._fieldTypeCache = new Map();
    }

    /**
     * Load the directus field schema and enhance it a bit for usability
     */
    async load() {
        let collection = await cache.getCollection(this.collection_name);
        this.field_definition = collection.fields;
        this.field_definition = this.field_definition.map((field) => {
            return {
                ...field,
                label:
                    field.meta?.translations?.find((translation) => translation.language == "en-US")?.translation ||
                    DataDefinition.formatFieldName(field.field),
            };
        });
        this.field_definition.sort((a, b) => a?.meta?.sort - b?.meta?.sort);

        //build field groups
        this.field_groups = this.field_definition.filter(
            field => field.meta?.special?.includes?.('group')
        );
        this.field_groups.sort(
            (a, b) => a.sort - b.sort
        );
        for (let group of this.field_groups) {
            group.fields = [];
        }

        for (let field of this.field_definition) {
            if (!field.meta?.group)
                continue;
            let group = this.field_groups.find(item => item.field == field.meta.group);
            if (!group) {
                console.error("Missing group: " + field.group);
            }
            group.fields.push(field);
        }

        //build the dictionary for fast field access
        this.field_dictionary = {};
        for (let field of this.field_definition) {
            this.field_dictionary[field.field] = field;
        }
    }

    /**
     * Get the type for a field, using cached value if available
     * @param {string} field_name - The field to get the type for
     * @returns {Promise<string>} The resolved field type
     */
    async getResolvedFieldType(field_name) {
        // Check cache first
        if (this._fieldTypeCache.has(field_name)) {
            return this._fieldTypeCache.get(field_name);
        }

        // Get and cache the resolved type
        const type = await DataDefinition.getFieldType(this.collection_name, field_name);
        this._fieldTypeCache.set(field_name, type);
        return type;
    }

    static formatFieldName(field_name) {
        if (!field_name) return "";

        let result = field_name;
        result = result.replaceAll("_", " ");
        result = result[0].toUpperCase() + result.substr(1);
        return result;
    }

    getFieldGroups() {
        return this.field_groups;
    }

    getFieldDefinition() {
        return this.field_definition;
    }

    getFieldDictionary() {
        return this.field_dictionary;
    }

    getFieldLabel(field_name) {
        return this.field_dictionary[field_name]?.label;
    }

    getChoiceLabel(field_name, field_value) {
        let definition = this.field_dictionary[field_name];
        if (!definition)
            return field_value;

        let choice = definition.meta?.options?.choices?.find((option) => option.value == field_value);
        if (!choice)
            return field_value;

        return choice.text;
    }

    getChoices(field_name) {
        let definition = this.field_dictionary[field_name];
        let choices = definition.meta?.options?.choices;
        //transform to label/value
        choices = choices?.map(({ text, value }) => { return { label: text, value } }) || [];
        return choices;
    }
}

export default DataDefinition;
