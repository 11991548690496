import { html, render, nothing } from "lit";
import { format, differenceInMinutes, differenceInHours, differenceInCalendarDays } from "date-fns";
import { getEpisodeWeek, getResponseText } from "../lib/lib-util";
import directus from "../lib/lib-directus";
import cache from "../lib/lib-cache";
import "../components/patient/app-patient-note";
import "../components/patient/app-patient-notes.js";
import "../components/app-patient-activity-calendar.js";
import DataDefinition from "../lib/lib-data-definition";
import ApplicationState from "applicationstate";
import { adddirectusClientFilter } from "../lib/lib-user";

const care_event_type_to_heading = {
    completed_checkpoint: "Completed Checkpoint",
    patient_call: "Patient Call",
    note: "Patient Note",
    unavailability: "Unavailability Reported",
}

const MAX_ROW_HEIGHT = 112;

export default class ScenePatientActivity extends HTMLElement {
    constructor() {
        super();
        this.patient_communications = [];
        this._current_tab = "all";
        this._app_name = ApplicationState.get("app.name");
        this.notes_by_checkpoint = {};
        this.patient_profile_notes = [];
        // Values: all, calls, checkpoints, notes, availability
        this._care_events = [];
        // pagination
        this._current_page = 1;
        this._page_size = null;
        this._loading = false;
        // dynamic css
        this._patient_header_height = 213;
        this.boundResizeHandler = this.handleWindowResize.bind(this);
    }

    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        this.init();
    }

    get patient_id() {
        return this._patient_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
        //this.loadClient();
    }

    get patient() {
        return this._patient;
    }

    set page(value) {
        this._page = value;
        this.render();
    }

    get page() {
        return this._page;
    }

    set care_events(value) {
        this._care_events = value;
        this.render();
    }

    get care_events() {
        return this._care_events;
    }

    set current_tab(value) {
        this._current_tab = value;
        // this.loadCareEvents();
        this.render();
        const calendarItem = document.querySelector("app-patient-activity-calendar");
        if (calendarItem) {
            calendarItem.updateReport();
        }
    }

    get current_tab() {
        return this._current_tab;
    }

    set client(value) {
        this._client = value;
    }

    get client() {
        return this._client;
    }

    connectedCallback() {
        this.link_styles = {
            display: "flex",
            alignItems: "center",
            marginLeft: "24px",
            marginRight: "15px",
            height: "46px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            textDecoration: "none",
            color: "#131313",
        };
        this.selected_link_styles = Object.assign({}, this.link_styles, {
            borderBottom: "2px solid #201B3A",
            fontWeight: "700",
            color: "#201B3A",
        });
        this.template = () => html`
            <style>
                scene-patient-activity .note-header {
                    background-color: var(--t-color-primary);
                    color: white;
                    padding: 5px;
                    font-size: 12px;
                    font-weight: 700;
                    display: block;
                }
                scene-patient-activity .nav-pills .nav-link {
                    font-size: 14px;
                    color: var(--t-color-dark);
                    border: 1px solid #fff;
                    margin-right: 4px;
                }
                scene-patient-activity .nav-pills .nav-link:hover {
                    border: 1px solid var(--t-color-dark);
                }
                scene-patient-activity .nav-pills .nav-link.active {
                    font-weight: 600;
                    background-color: var(--t-color-primary);
                }
                scene-patient-activity .nav-pills .nav-link.active:hover {
                    border: 1px solid #fff;
                }
                scene-patient-activity .list-group-item {
                    border: none;
                    border-radius: 8px;
                    box-shadow: var(--t-box-shadow);
                    margin-bottom: 8px;
                }
                @media (max-width: 768px) {
                }
                @media (min-width: 768px) {
                }
            </style>
            <app-patient-header .page=${"activity"} .patient=${this.patient}></app-patient-header>
            <div style="height: calc(100% - 40px - ${this._patient_header_height}px); padding-top: 16px;">
            <div style="margin: 0 32px 16px 32px; border-radius: 8px; height: 100%;">
            <ul class="nav nav-pills" style="width: 100%; border-radius: 8px; background: #fff; box-shadow: var(--t-box-shadow); padding: 8px; position: relative; z-index: 1;">
                ${this.renderNavItem("all")}
                ${this.renderNavItem("communication")}
                ${this.renderNavItem("calls")}
                ${this.renderNavItem("checkpoints")}
                ${this.renderNavItem("notes")}
                ${this.renderNavItem("availability")}
                ${this.renderNavItem("visits")}
            </ul>
            <div id="activity-scroll-container" style="padding-top: 16px; /*height: calc(100% - 55px);*/ overflow-y: auto;">
                ${this.renderCareEvents()}
            </div>
            </div>
        </div>
        
        `;

        Object.assign(this.style, {
            height: "100%",
            display: "block"
        })

        this.render();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;

        render(this.template(), this);
    }

    renderCareEvents() {
        //console.log("this.current_tab", this.current_tab);
        return html`
            <div>
                <app-patient-activity-calendar .patient_id=${this.patient_id} .type=${this.current_tab} .patient_activities=${this.care_events}></app-patient-activity-calendar>
            </div>
        `;
    }

    renderNavItem(tab_name) {
        const capitalized_label = tab_name.charAt(0).toUpperCase() + tab_name.slice(1);
        return html`
            <li class="nav-item">
                <button 
                    @click=${_e => this.handleTabClick(tab_name)}
                    class=${"nav-link" + (this.current_tab === tab_name ? " active" : "")}
                >
                    ${capitalized_label}
                </button>
            </li>
        `
    }

    renderLinkItem(link) {
        return html`
            <li class="nav-item">
                <a 
                    href=${link.url}
                    class="nav-link${this.page === link.page ? " active" : ""}"
                    style=${this.page === link.page ? this.selected_link_styles : this.link_styles};
                >
                    ${link.title}
                </a>
            </li>
        `;
    }

    /**
     * Returns a human readable time of the care_event.
     * @param {string} timestamp
     * @returns {string} formatted time e.g. 11 hours ago.
     */
    formatCareEventTime(timestamp) {
        const now = new Date();
        const timestamp_date = new Date(timestamp);
        const time_difference_mins = differenceInMinutes(now, timestamp_date);
        const time_difference_hrs = differenceInHours(now, timestamp_date);
        const time_difference_days = differenceInCalendarDays(now, timestamp_date);
        const time_difference_weeks = Math.round(time_difference_days / 7);

        if (time_difference_mins < 120) return time_difference_mins + " minute"
            + (time_difference_mins === 1 ? "" : "s") + " ago";
        else if (time_difference_hrs < 48) return time_difference_hrs + " hours ago";
        else if (time_difference_days < 30) return time_difference_days + " days ago";
        else return time_difference_weeks + " weeks ago";
    }

    handleTabClick(tab_name) {
        this._current_page = 1;
        this.current_tab = tab_name;
    }

    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = this.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

    async init() {
        if (this._loading) return;
        //vaadin router will set the patient_id multiple times, so add a guard to prevent redundant loads
        this._loading = true;
        this.patient = await cache.getPatient(this.patient_id);
        await this.loadCareEvents();
        this._loading = false;
        // Set up responsive scroll container height
        this._patient_header_height = this.querySelector('app-patient-header').clientHeight;
        await this.loadDefinition();
        this.render();
    }

    async loadDefinition() {
        this.patient_communication_definition = await DataDefinition.getDefinition("patient_communication");
        //console.log('Patient Communication Definition:', this.patient_communication_definition);
        this.render();
    }

    async loadCareEvents() {
        console.log("loadCareEvents");
        const activityResult = await directus.transport.get("/vbh/patient/activities", {
            params: {
                filters: {
                    patient_id: this.patient_id
                }
            }
        });

        this.care_events = activityResult.data;
    }
}

customElements.define("scene-patient-activity", ScenePatientActivity);