import ApplicationState from "applicationstate";
import { html, render } from "lit";
import '../../../shared/components/filter/app-filter-data-view';
import "../../../shared/components/app-page-header";
import ImportPendingPatients from "../components/import/import-pending-patients";

export default class SceneImport extends HTMLElement {
    get links() {
        return [
            { title: "Patients Pending Action", page: "patients_pending", url: "imports/patients-pending" },
            { title: "Declined", page: "patients_declined", url: "imports/patients-declined" },
        ];
    }

    get location() {
        return this._location;
    }

    set location(value) {
        // check if the path is the same, don't re-render if so.
        if (this._location && this._location.path === value.path) return;
        this._location = value;
        switch (value.route.path) {
            case "/imports":
            case "/imports/patients-pending":
                this.page = "patients_pending";
                this.view = "patients_pending";
                this.status_filter = "import_approval_requested"
                this.status_title = "Pending";
                break;
            case "/imports/patients-declined":
                this.page = "patients_declined";
                this.view = "patients_declined";
                this.status_filter = "import_declined"
                this.status_title = "Declined";
                break;
        }
    }

    get page() {
        return this._page;
    }

    set page(value) {
        this._page = value;
        this.render();
    }

    get view() {
        return this._view;
    }

    set view(value) {
        this._view = value;
        this.render();
    }
    get status_filter() {
        return this._status_filter;
        //console.log("status get", this._status_filter);
    }

    set status_filter(value) {
        this._status_filter = value;
        //console.log("status set", this._status_filter);
    }

    constructor() {
        super();
        this._app_name = ApplicationState.get('app.name');
    }

    connectedCallback() {
        this.template = () => {
            let component;
            switch (this.view) {
                case "pedning":
                default:
                    component = new ImportPendingPatients();
                    component.status_filter = this.status_filter;
            }






            return html`
            <app-page-header
                .title=${"Patient Approval Workflow"}
                .icon=${"install_desktop"}
                .
                .links=${this.links}
                .page=${this.page}
                style="margin: 0 32px 20px 32px"
            ></app-page-header>
            
                ${component}
            `;
        }

        this.render();
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }
}

customElements.define("scene-import", SceneImport);