import { html, render } from "lit";
import { format, parse, startOfDay, differenceInHours, isValid, startOfWeek, endOfWeek, isWithinInterval, parseISO } from "date-fns";
import "../app-pill";

/**
 * A compact patient card used in search views.
 * Displays DOB, SOC, and status.
 */
export default class AppPatientCard extends HTMLElement {
    constructor() {
        super();
    }

    get patient() {
        return this._patient;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    /**
     * Renders a colored patient status pill.
     * @param {string} status
     * @returns lit html
     */
    isCurrentWeek(dateString) {
        const date = parseISO(dateString);

        const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 0 }); // Week starts on Sunday
        const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 0 }); // Week ends on Saturday
        return isWithinInterval(date, { start: startOfThisWeek, end: endOfThisWeek });
    }

    formatDateOrNA(dateString) {
        if (!dateString) return 'N/A';

        let date;

        // Check if the dateString is date-only or time is 00:00:000
        const hasTime = dateString.includes('T') || dateString.includes(' ');
        const isMidnight = dateString.includes('T00:00:00') || dateString.endsWith(' 00:00:00');

        if (!hasTime || isMidnight) {
            // Handle date-only string or midnight time (assumes YYYY-MM-DD format for date-only)
            const datePart = dateString.split('T')[0].split(' ')[0];
            const [year, month, day] = datePart.split('-');
            date = new Date(year, month - 1, day);
        } else {
            // Handle full date-time string
            date = new Date(dateString);
        }

        return isValid(date) ? format(date, 'MM/dd/yyyy') : 'N/A';
    }

    connectedCallback() {

        this.template = () => {

            // Filter for the current week's checkpoint
            const activeTask = this.patient.tasks

                .filter(task => {
                    // Log the scheduled date of each task for debugging purposes
                    //console.log(`Checking task with date: ${task.scheduled_date}`);
                    return this.isCurrentWeek(task.scheduled_date);
                })
                .sort((a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date))[0];

            const totalAttempts = activeTask ? activeTask.total_attempts : 'N/A';
            const currentCP = activeTask ? activeTask.title : 'N/A';
            const statusMapping = {
                'unable_to_complete': 'unsuccessful',
                'active': 'pending',
                'complete': 'complete'
            };

            const currentCPStatus = activeTask ? statusMapping[activeTask.status] || 'N/A' : 'N/A';
            //const soc_date = this.patient.current_episode_id?.start_date;
            //const soc_date = this.patient.current_soc;
            let last_survey_date_str = this.patient.last_survey_date;
            const last_survey_date = new Date(this.patient.last_survey_date);
            const now = new Date();
            let hours_diff = differenceInHours(now, last_survey_date);
            let last_survey_status;

            let current_start;
            try {
                const parsed = parseISO(this.patient.current_episode_start);

                current_start = format(parsed, "MM/dd/yyyy");
            } catch (e) {
                current_start = "N/A";
            }


            return html`
            <div class="card mod-base" style="overflow:hidden">
                <div class="patient-list-card">
                    <div class="d-flex p-name" style="align-items: center;">
                        <h5>
                            ${this.patient.first_name + " " + this.patient.last_name}
                        </h5>
                    </div>
            <div class="p-dob p-card-info">
                        <span class="p-card-label">DOB:</span>
                        <span class="p-card-date">
                          ${this.formatDateOrNA(this.patient.birth_date)}
                        </span>
                    </div>

                    <div class="p-soc p-card-info">
                        <span class="p-card-label">SOC:</span>
                        <span class="p-card-date">
                            ${this.formatDateOrNA(this.patient.current_soc)}
                        </span>
                    </div>

                    <div class="p-episode p-card-info">
                        <span class="p-card-label">Episode:</span>
                        <span class="p-card-date">
                            ${current_start}
                                                    
                        </span>
                    </div>
                     <div class="p-last-check p-card-info">
                        <span class="p-card-label">CP ${currentCP.split(" ")[1]}</span>
                        <span class="p-card-date ${currentCPStatus} ">
                            ${currentCPStatus} 
                        </span>
                    </div>
                    <div class="p-total-attempts p-card-info">
                        <span class="p-card-label"># Attempts:</span>
                        <span class="p-card-date ${totalAttempts}">
                             ${totalAttempts}
                        </span>
                    </div>
                    <!-- <div class="p-last-check p-card-info">
                        <span class="p-card-label">Last Checkpoint:</span>
                        <span class="p-card-date">
                            ${this.formatDateOrNA(this.patient.last_survey_date)}
                        </span>
                    </div> -->
                   
                    <div class="p-last-comm p-card-info">
                        <span class="p-card-label">Last Attempt:</span>
                        <span class="p-card-date">
                            ${this.formatDateOrNA(this.patient.last_communication_date)}
                        </span>
                    </div>
                                        <div class="p-mrn p-card-info">
                        <span class="p-card-label">MRN:</span>
                        <span class="p-card-date">
                            ${this.patient.mrn}
                        </span>
                    </div>
                </div>
            </div>
        `;

        };

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);

    }
}

customElements.define("app-patient-card", AppPatientCard);
