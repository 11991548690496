import ApplicationState from "applicationstate";
import { init } from "applicationstate/plugins/indexeddb";
import MainApp from "./components/main-app";
import { checkSession, checkAppAccess } from "shared/lib/lib-session";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-symbols';


// Initiallize the main app component and attach to the DOM.
const app = new MainApp();
document.body.appendChild(app);

init(ApplicationState, "vbh-client-app")
    // Attempt to re-authenticate the user if previously logged in.
    .then(async () => {
        try {
            ApplicationState.set("app.name", "client", { persist: false });
            app.user = await checkSession();
            if (!app.user)
                return;
            checkAppAccess(app.user);
            await app.initApp();
        } catch (err) {
            app.user = null;
        }
    })
    // Indicate that loading is complete.
    .then(() => {
        app.is_loading = false;
    })
    // this will probably end up as a no-op.
    .catch((e) => {
        console.error(e);
    });
