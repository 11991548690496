import { html, render } from "lit";
import { addDays, format, previousSunday } from "date-fns";
import directus from 'shared/lib/lib-directus';
import SurveyDefinition from "shared/lib/lib-survey-definition";
import { getCurrentClientId } from "shared/lib/lib-user";
import { parseISODateWithoutTime } from "shared/lib/lib-date";
import { navigate } from "shared/lib/lib-router";

import "../components/dashboard/app-dashboard-header";
import "../components/dashboard/app-dashboard-measures";
import "shared/components/patient/app-patient-card";
import "shared/components/dashboard/app-dashboard-graph";


/**
 * An overview of critical patient issues for the current client.
 * Exposes a graph and list view with changeable time intervals.
 */
export default class SceneDashboardCriticalIssues extends HTMLElement {
    constructor() {
        super();
        this._selected_measure = measures.SHORTNESS_OF_BREATH;
        this._selected_bar_data = {}
        // Default view is daily
        this._is_weekly = false;
        // Formatted data for app-dashboard-graph.data
        this._measures_data = {
            [measures.SHORTNESS_OF_BREATH.column]: [],
            [measures.MEDICATION_REFILLS.column]: [],
            [measures.SIDE_EFFECTS.column]: [],
            [measures.SATISFACTION.column]: [],
            [measures.FALLS.column]: [],
            [measures.REPORTED_FALLS.column]: [],
        };

        this._current_user_client = getCurrentClientId();
    }

    get selected_measure() {
        return this._selected_measure;
    }

    set selected_measure(val) {
        this._selected_measure = val;
        this.render();
    }

    get selected_bar_data() {
        return this._selected_bar_data;
    }

    set selected_bar_data(val) {
        this._selected_bar_data = val;
        this.render();
    }

    /**
     * Queries the server for critical issues data and formats for app-dashboard-graph.data,
     * storing the result in this._measures_data.
     * @todo break into multiple functions and potentially share logic with scene-dashboard-patient-trends
     */
    async loadCriticalIssuesData() {
        const result = await directus.transport.get(
            `/vbh/patient_metrics/${this._current_user_client}/${this._is_weekly ? "weekly" : "daily"}`
        );

        const item_date_key = this._is_weekly ? "week" : "day";
        // sort and parse numbers from string values
        const date_sorted_data = result.data
            .map((val) => ({
                ...val,
                running_pr_shortnessofbreath: Number(val.running_pr_shortnessofbreath),
                running_pr_med_refill: Number(val.running_pr_med_refill),
                running_pr_side_effects: Number(val.running_pr_side_effects),
                running_pr_ct_satisfaction: Number(val.running_pr_ct_satisfaction),
                running_pr_fall: Number(val.running_pr_fall),
                running_pr_fall_report: Number(val.running_pr_fall_report),
                total_patients_surveyed: Number(val.total_patients_surveyed),
            }))
            .sort((a, b) => new Date(a[item_date_key]) - new Date(b[item_date_key]));

        // clear previous data
        for (let measure of Object.keys(this._measures_data)) {
            this._measures_data[measure] = [];
        }

        const today = new Date();
        // Add up measures for all survey items on the same day.
        // This is possible if the user has access to multiple clients
        const aggregated_data = date_sorted_data.reduce((acc, survey_item) => {
            const prev_item = acc[acc.length - 1];
            if (prev_item?.[item_date_key] === survey_item[item_date_key]) {
                prev_item.running_pr_shortnessofbreath += survey_item.running_pr_shortnessofbreath;
                prev_item.running_pr_med_refill += survey_item.running_pr_med_refill;
                prev_item.running_pr_side_effects += survey_item.running_pr_side_effects;
                prev_item.running_pr_ct_satisfaction += survey_item.running_pr_ct_satisfaction;
                prev_item.running_pr_fall += survey_item.running_pr_fall;
                prev_item.running_pr_fall_report += survey_item.running_pr_fall_report;
                prev_item.total_patients_surveyed += survey_item.total_patients_surveyed;
            } else {
                acc.push({ ...survey_item });
            }
            return acc;
        }, []);

        for (let bar_data of aggregated_data) {
            const {
                running_pr_shortnessofbreath,
                running_pr_med_refill,
                running_pr_side_effects,
                running_pr_ct_satisfaction,
                running_pr_fall,
                running_pr_fall_report,
                total_patients_surveyed,
            } = bar_data;
            const label_date = parseISODateWithoutTime(bar_data[item_date_key])
            let label = format(label_date, "MM/dd");
            if (!this._is_weekly && label === format(today, "MM/dd")) {
                label = "Today";
            } else if (this._is_weekly && label === format(previousSunday(today), "MM/dd")) {
                label = "Current";
            }
            const common_measures_data = { label, label_date }

            this._measures_data[measures.SHORTNESS_OF_BREATH.column].push({
                ...common_measures_data,
                count: running_pr_shortnessofbreath,
                value: running_pr_shortnessofbreath / total_patients_surveyed || 0,
            });
            this._measures_data[measures.MEDICATION_REFILLS.column].push({
                ...common_measures_data,
                count: running_pr_med_refill,
                value: running_pr_med_refill / total_patients_surveyed || 0,
            });
            this._measures_data[measures.SIDE_EFFECTS.column].push({
                ...common_measures_data,
                count: running_pr_side_effects,
                value: running_pr_side_effects / total_patients_surveyed || 0,
            });
            this._measures_data[measures.SATISFACTION.column].push({
                ...common_measures_data,
                count: running_pr_ct_satisfaction,
                value: running_pr_ct_satisfaction / total_patients_surveyed || 0,
            });
            this._measures_data[measures.FALLS.column].push({
                ...common_measures_data,
                count: running_pr_fall,
                value: running_pr_fall / total_patients_surveyed || 0,
            });
            this._measures_data[measures.REPORTED_FALLS.column].push({
                ...common_measures_data,
                count: running_pr_fall_report,
                value: running_pr_fall_report / total_patients_surveyed || 0,
            });
        }
        this.render();
    }

    connectedCallback() {
        this.template = () => {
            const { subtitle, graph_x_label } = this.selected_measure;
            const { patients = [], patient_id_to_survey_id, bar_label } = this.selected_bar_data
            const selected_patient_cards = patients.map(
                (patient) =>
                    html`
                        <div class="col-sm-12 col-md-12 col-lg-6" style="margin-top: 8px;">
                            <app-patient-card
                                @click=${(_e) => {
                            navigate(`patients/${patient.id}/checkpoints/${patient_id_to_survey_id[patient.id]}`);
                        }}
                                .patient=${patient}
                                style="cursor: pointer;"></app-patient-card>
                        </div>
                    `
            );

            return html`
                <style>
                    #dashboard-critical-issues {
                        height: 100%;
                    }
                    #dashboard-critical-issues .row {
                        height: 100%;
                    }
                    #dashboard-critical-issues .scroll-container {
                        padding: 0 32px 16px 32px;
                        overflow-y: scroll;
                        height: calc(100% - 141px);
                    }
                    @media (min-width: 768px) {
                        #dashboard-critical-issues .scroll-container {
                            height: calc(100% - 160px);
                            display: flex;
                            margin-top: 16px;
                        }
                        #dashboard-critical-issues #right-wrapper {
                            padding-left: 32px;
                            display: flex; 
                            flex-direction: column;
                        }
                    }
                </style>
                <div id="dashboard-critical-issues" class="container-fluid">
                    <div class="row">
                        <app-dashboard-header
                            .page=${"critical-issues"}
                            style="padding: 0 32px; margin-bottom: 16px;"
                            class="col-12"></app-dashboard-header>
                        <div class="scroll-container">
                            <app-dashboard-measures
                                class="col-12 col-md-4 col-lg-3"
                                .selected_measure=${this._selected_measure}
                                .measures=${measures}
                                @measureclick=${(e) => this.handleMeasureClick(e)}>
                            </app-dashboard-measures>
                            <div 
                                id="right-wrapper"
                                class="col-12 col-md-8 col-lg-9"
                            >
                                <app-dashboard-graph
                                    .title=${this._selected_measure.title}
                                    .subtitle=${this.getSelectedMeasureNote()}
                                    .graph_x_label=${graph_x_label}
                                    .data=${this._measures_data[this._selected_measure.column]}
                                    .selected_bar_label=${bar_label}
                                    @barclick=${e => this.handleBarClick(e)}
                                    @weeklytoggle=${e => this.handleWeeklyToggle(e)}>
                                </app-dashboard-graph>
                                <h4 style="margin: 8px 0 0 8px; font-style: italic;">
                                    ${this.getSubheadingText()}        
                                </h4>
                                <div class="row" style="height: fit-content;">
                                    ${selected_patient_cards}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Get text to display below the graph, changes with bar selection.
     * @returns {string}
     */
    getSubheadingText() {
        const { bar_label } = this.selected_bar_data
        // no bar selected
        if (!bar_label) return ""
        const base_text = "Patients experiencing the issue as of "
        if (!this._is_weekly) return base_text + bar_label.toLowerCase() + ":"
        else if (bar_label === 'Current') return base_text + "the current week:"
        else return base_text + "week " + bar_label
    }

    getSelectedMeasureNote() {
        const measure_definition = this.survey_definition?.data_definition.field_dictionary[this.selected_measure?.column];
        return measure_definition?.meta?.note;
    }

    /**
     * Fetches and displays patients with issues for the selected bar.
     * @param {Event} e <app-dashboard-graph> barclick event
     */
    async handleBarClick(e) {
        const { label, label_date } = e.detail

        let end_date = label_date
        if (label === 'Current') {
            end_date = new Date()
        }
        else if (this._is_weekly) {
            end_date = addDays(label_date, 6)
        }
        const formatted_end_date = format(end_date, 'yyyy-MM-dd')

        const { data } = await directus.transport.get(
            `/vbh/patients_for_metric/${this.selected_measure.view_column}/${this._current_user_client}/${formatted_end_date}`
        );
        this.selected_bar_data = {
            ...data,
            bar_label: label
        }
    }

    /**
     * Updates the daily/weekly toggle selection and re-loads graph data.
     * @param {Event} e <app-dashboard-graph> weeklytoggle event
     */
    handleWeeklyToggle(e) {
        e.stopPropagation();
        e.preventDefault();
        this._is_weekly = e.detail.is_weekly;
        this.selected_bar_data = {}
        this.loadCriticalIssuesData();
    }

    /**
     * Update the selected measure
     * @param {CustomEvent} e measureclick event from <app-dashboard-measures>
     */
    handleMeasureClick(e) {
        this.selected_bar_data = {}
        this.selected_measure = e.detail.measure;
    }

    async init() {
        this.survey_definition = await SurveyDefinition.getSurveyDefinition();
        this.loadCriticalIssuesData();
    }
}

customElements.define("scene-dashboard-critical-issues", SceneDashboardCriticalIssues);

const measures = {
    SHORTNESS_OF_BREATH: {
        title: "Shortness of Breath",
        graph_x_label: 'Percentage of total population currently reporting "Always"',
        column: "pr_shortnessofbreath",
        view_column: "running_pr_shortnessofbreath",
        detail_query: {},
    },
    MEDICATION_REFILLS: {
        title: "Medication Refills",
        graph_x_label: "Percentage of total population currently reporting they need a medication refill",
        column: "pr_med_refill",
        view_column: "running_pr_med_refill",
        detail_query: {},
    },
    SIDE_EFFECTS: {
        title: "Side Effects",
        graph_x_label: "Percentage of total population currently reporting medication side effects",
        column: "pr_side_effects",
        view_column: "running_pr_side_effects",
        detail_query: {},
    },
    SATISFACTION: {
        title: "Satisfaction",
        graph_x_label: 'Percentage of total population currently reporting "Very Unsatisfied"',
        column: "pr_ct_satisfaction",
        view_column: "running_pr_ct_satisfaction",
        detail_query: {},
    },
    FALLS: {
        title: "Recent Falls",
        graph_x_label: "Percentage of total population having recent falls",
        column: "pr_fall",
        view_column: "running_pr_fall",
        detail_query: {},
    },
    REPORTED_FALLS: {
        title: "Recently Reported Falls",
        graph_x_label: "Percentage of total population reporting recent falls to HHA",
        column: "pr_fall_report",
        view_column: "running_pr_fall_report",
        detail_query: {},
    },
};
