import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import AppFilterAggregate from './app-filter-aggregate';
import AppFilterAggregateGroup from './app-filter-aggregate-group';

export default class AppFilterAggregateModal extends ModalBase {
    set config(value) {
        this._config = value;
        this.render();
    }

    get config() {
        return this._config;
    }

    set aggregate(value) {
        if (!value)
            return this._aggregate = [];
        this._aggregate = value;
        this.render();
    }

    get aggregate() {
        if (!this._aggregate)
            this._aggregate = [];
        return this._aggregate;
    }

    set aggregate_group(value) {
        if (!value)
            return this._aggregate_group = [];
        this._aggregate_group = value;
        this.render();
    }

    get aggregate_group() {
        if (!this._aggregate_group)
            this._aggregate_group = [];
        return this._aggregate_group;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    .modal-confirm-btn {
                        background-color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                    }
                    .modal-confirm-btn:hover {
                        background-color: var(--t-color-danger-darkened);
                        border-color: var(--t-color-danger-darkened);
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Calculation and Grouping</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <button 
                                id="app-filters-clear"
                                @click=${e => {
                    this.resetFilters();
                    this.render();
                }}
                                class="btn btn-outline-primary btn-sm" 
                                style="margin-top: 10px; display: flex; align-items: center; padding-top: 3px; padding-bottom: 3px;">
                                <span
                                    class="material-symbols-outlined"
                                    style='
                                        font-size: 12px;
                                        cursor: pointer;
                                        margin-right: 3px;
                                        color: var(--t-color-primary);
                                        font-variation-settings: "wght" 700, "GRAD" 0, "opsz" 48;
                                    '>filter_list_off</span>
                                    <span style="font-size: 12px">
                                    Clear All
                                    </span>
                            </button>
                            <div class="app-aggregate-container">
                                <div class="app-filters-container-label">CALCULATION</div>
                                <app-filter-aggregate .collection_config=${this.config.collection} .aggregate=${this.aggregate}></app-filter-aggregate>
                                <div class="app-filters-container-label">GROUPING</div>
                                <app-filter-aggregate-group .collection_config=${this.config.collection} .aggregate_group=${this.aggregate_group}></app-filter-aggregate-group>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary btn-danger modal-confirm-btn"
                                @click=${(_e) => this.handleConfirmClick()}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    resetFilters() {
        this._aggregate = [];
        this._aggregate_group = [];
        this.render();
    }

    /**
     * Click handler for 'Confirm' button.
     * Resolves the ModalBase promise by calling dismiss()
     */
    async handleConfirmClick() {
        let aggregate_element = this.querySelector("app-filter-aggregate");
        let aggregate_group_element = this.querySelector("app-filter-aggregate-group");
        this.dismiss({ aggregate: aggregate_element.aggregate, aggregate_group: aggregate_group_element.aggregate_group });
    }
}

customElements.define("app-filter-aggregate-modal", AppFilterAggregateModal);
