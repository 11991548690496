import { html, render } from 'lit';
import { range } from 'lit/directives/range.js';
import { map } from 'lit/directives/map.js';

/*
Filter data looks like:
[
    {
        [group field name]: [group field value],
        [aggregate function]: {
            [aggregate function column name]: [aggrgate value]
        }
    }
]
 */

class AppFilterBarGraph extends HTMLElement {
    set data(value) {
        this._data = value;
        this.render();
    }

    get data() {
        return this._data;
    }

    set filter_state(value) {
        this._filter_state = value;
        this.render();
    }

    /** @type {import('./app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;
    }

    set num_ticks(value) {
        this._num_ticks = value;
    }

    get num_ticks() {
        if (this._num_ticks > this.max_value)
            return this.max_value;
        return this._num_ticks || 5;
    }

    get max_value() {
        let max = 0;
        for (let row of this.data) {
            let value = parseInt(row[this.filter_state.aggregate[0].aggregate_function][this.filter_state.aggregate[0].name]);
            if (value > max)
                max = value;
        }
        if (!max)
            return 100;
        return max;
    }

    connectedCallback() {
        this.template = () => html`
        <style>
            #filter-bar-graph {
                padding: 30px;
                border-radius: 8px;
                background-color: white;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                height: 100%;
                width: 100%;
                box-shadow: 0px 2px 10px rgb(0 39 77 / 8%);
            }

            #filter-bar-graph-y-axis {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                border-right: 1px solid var(--t-color-dark);
                height: 100%;
            }

            .filter-bar-graph-tick {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 12px;
                color: var(--t-color-dark);
                margin-top: -10px;
            }

            .filter-bar-graph-tick-mark {
                margin-left: 3px;
                border-top: 1px solid var(--t-color-dark);
                width: 7px;
            }

            #filter-bar-graph-inner {
                height: 100%; 
                width: fit-content;
                position: relative; 
                display: flex; 
                flex-direction: column; 
                align-items: flex-start; 
                overflow-x: auto;
                overflow-y: visible;
                justify-content: flex-end;
                padding-left: 20px;
            }

            #filter-bar-graph-body {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-around;
                height: 100%;
                width: fit-content;
                flex-basis: 100%;
                border-bottom: 1px solid var(--t-color-grey);
            }

            .filter-bar-graph-bar {
                color: var(--t-color-primary);
                border: 1px solid var(--t-color-dark);
                width: 50px;
                background-color: var(--t-color-primary);
                cursor: pointer;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                position: relative;
            }
            .filter-bar-graph-bar::before {
                content: attr(data-bar-value);
                position: absolute;
                top: -23px;
                color: var(--t-color-dark);
                font-weight: 700;
                opacity: 0;
                transition: all 250ms ease-in-out;
            }
            .filter-bar-graph-bar:hover::before {
                opacity: 1;
            }

            .filter-bar-graph-bar:hover {
                background-color: var(--t-color-primary-darkened);
            }
            .filter-bar-graph-bar:hover .filter-bar-graph-bar-inner {
                opacity: 1;
            }

            #filter-bar-graph-x-axis {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                width: fit-content;
                height: 50px;
            }

            .filter-bar-graph-bar-label {
                cursor: pointer;
                margin-left: -25px;
                width: 50px;
                transform: rotate(-45deg);
                font-size: 12px;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
                font-weight: 600;
            }

            .filter-bar-graph-bar-inner {
                transform: rotate(-90deg);
                transform-origin: left;
                height: 20px;
                color: var(--t-color-light-grey);
                white-space: nowrap;
                opacity: 0;
                transition: all 250ms ease-in-out;
                position: absolute;
                left: 50%;
            }

            .filter-bar-graph-bar-inner-label {
                transition: all 250ms ease-in-out;
                font-size: 16px;
            }
            .filter-bar-graph-bar-inner-value{
                font-size: 16px;
                font-weight: 600;
            }

        </style>
        <div id="filter-bar-graph">
            <div style="height: 100%; display: flex; flex-direction: column;">
                <div id="filter-bar-graph-y-axis">
                    ${map(
            range(this.max_value, 0, (Math.ceil(this.max_value / this.num_ticks)) * -1),
            (value, index) => html`
                        <div style="flex-grow: 1; position: relative;">
                            <div class="filter-bar-graph-tick">
                                <div class="filter-bar-graph-tick-label">${value}</div>
                                <div class="filter-bar-graph-tick-mark"></div>
                            </div>

                        </div>
                        `
        )
            }
                </div>
                <div style="height: 50px;"></div>
            </div>
            <div id="filter-bar-graph-inner">
                <div id="filter-bar-graph-body">
                    ${this.data.map(
                row => html`
                        <div 
                            @click = ${e => this.drilldown(row)}
                            class="filter-bar-graph-bar"
                            data-bar-value=${this.getValue(row)}
                            style="height: ${this.getBarHeight(row)}">
                            <div class="filter-bar-graph-bar-inner">
                                <span class="filter-bar-graph-bar-inner-label">${row[this.filter_state.aggregate_group[0].name]}: </span>
                                <span class="filter-bar-graph-bar-inner-value">${this.getValue(row)}</span>
                            </div>
                        </div>
                        `
            )}

                </div>

                <div id="filter-bar-graph-x-axis">
                    ${this.data.map(
                row => html`
                        <div style="width: 50px;">
                            <div class="filter-bar-graph-bar-label">${row[this.filter_state.aggregate_group[0].name]}</div>

                        </div>
                        `
            )}
                </div>
            </div>

        </div>
        `;
        this.render();
    }

    render() {
        if (!this.template)
            return;

        if (!this.data)
            return;

        if (!this.filter_state)
            return;

        render(this.template(), this);
    }

    getBarHeight(row) {
        let max = this.max_value;
        let value = this.getValue(row);
        let percentage = (value / max) * 100;
        return `${percentage}%`;
    }

    getValue(row) {
        let value = row[this.filter_state.aggregate[0].aggregate_function][this.filter_state.aggregate[0].name];
        return value;
    }

    drilldown(row) {
        let filter_state = structuredClone(this.filter_state);
        filter_state.aggregate = [];
        filter_state.aggregate_group = [];
        let group_field_name = this.filter_state.aggregate_group[0].name;
        filter_state.group.filters.push(
            {
                field: group_field_name,
                op: '_eq',
                value: row[group_field_name]
            }
        );
        this.dispatchEvent(new CustomEvent('drilldown', { composed: true, bubbles: true, detail: filter_state }));
    }

}

customElements.define('app-filter-bar-graph', AppFilterBarGraph);
export default AppFilterBarGraph;