import { html, render } from "lit";
import "../components/dashboard/app-dashboard-header";

export default class SceneDashboardCareMetrics extends HTMLElement {
    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #dashboard-care-metrics {
                        height: 100%;
                    }
                    #dashboard-care-metrics .row {
                        height: 100%;
                    }
                    #dashboard-care-metrics .scroll-container {
                        padding: 0 32px 16px 32px;
                        overflow-y: scroll;
                        height: calc(100% - 247px);
                    }
                    @media (min-width: 768px) {
                        #dashboard-care-metrics .scroll-container {
                            height: calc(100% - 125px);
                            display: flex;
                            margin-top: 16px;
                        }
                    }
                </style>
                <div id="dashboard-care-metrics" class="container-fluid">
                    <div class="row">
                            <app-dashboard-header style="padding: 0 32px; margin-bottom: 16px;" class="col-12"></app-dashboard-header>
                            <div class="scroll-container">
                                Care Team Metrics
                            </div>
                        </div>
                    </div>
                </div>
            `;
        };

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("scene-dashboard-care-metrics", SceneDashboardCareMetrics);
