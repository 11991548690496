import { html, render } from "lit";
import * as bootstrap from "bootstrap";

/**
 * @param {AvoidableEventsMetrics} metrics flat object with all impact metrics
 * A widget displaying metrics around cost/revenue opportunity loss.
 */
export default class AppAvoidableEventsCostRevenue extends HTMLElement {
    constructor() {
        super();
        this.metrics = {};
    }

    set metrics(value) {
        this._metrics = value || {};
        this.render();
    }
    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #avoidable-events-cost h6 {
                        margin: unset;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 26px;
                        color: var(--t-color-dark);
                    }
                    #avoidable-events-cost .widget-row {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        font-size: 14px;
                        margin-bottom: 24px;
                    }
                    #avoidable-events-cost .widget-metric {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                        width: 126px;
                        height: 84px;
                        position: relative;
                    }
                    #avoidable-events-cost .widget-metric__value {
                        font-weight: 700;
                        line-height: 56px;
                        font-size: 40px;
                        color: var(--t-color-danger);
                        letter-spacing: 0.5px;
                    }
                    #avoidable-events-cost .widget-metric__label {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: var(--t-color-grey);
                        text-align: center;
                        white-space: pre-line;
                    }
                </style>
                <div 
                    id="avoidable-events-cost" 
                    style="
                        height: 100%; 
                        display: flex; 
                        flex-direction: column;
                    "
                >
                    <h6 style="margin: 0 -8px; white-space: pre-line;">Direct Cost & New Revenue\nOpportunity Loss</h6>
                    <div style="margin-top: 32px;">
                        <div class="widget-row">
                            ${this.renderMetric("Direct Costs of Lost Time", "direct_costs_lost_time", "Total Lost Staff Time\n*\nAvg Cost per Minute")}
                        </div>
                        <div class="widget-row">
                            ${this.renderMetric("New Revenue Opportunity Lost", "revenue_opportunity_lost", "Lost Time Converted to Visits\n*\nAvg Reimbursement per Visit")}
                        </div>
                        <div class="widget-row">
                            ${this.renderMetric("Total Loss", "total_lost_revenue", "Sum of Direct Cost of Lost Time & New Revenue Opportunity Lost")}
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
            paddingBottom: "12px",
        });

        this.render();
        [...this.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((el) => new bootstrap.Tooltip(el));
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    renderMetric(label, metric_key, formula_text) {
        return html`
            <span class="widget-metric__label" style="width: 100px;">
                ${label}
            </span>
            <span 
                class="widget-metric__value"
                title=${formula_text}
                data-bs-html="true"
                data-bs-toggle=${formula_text ? "tooltip" : ""}
                data-bs-placement="right"
                data-bs-custom-class="widget-formula-tooltip"
            >
                ${"$" + (Math.round(this.metrics[metric_key]) || 0).toLocaleString()}
            </span>
        `
    }

}

customElements.define("app-avoidable-events-cost-revenue", AppAvoidableEventsCostRevenue);
