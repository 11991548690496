import { html, render } from "lit";
import { ref, createRef } from "lit/directives/ref.js";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"
import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import directus from "shared/lib/lib-directus";
import SurveyDefinition from 'shared/lib/lib-survey-definition';
import DataDefinition from "shared/lib/lib-data-definition";
import { adddirectusClientFilter, getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";

import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";

import { noteCellFormatter } from "../util/lib-report";

window.luxon = { DateTime };

export default class ReportPatientSatisfaction extends HTMLElement {
    static get config() {
        return {
            icon: "mood",
            report: "satisfaction",
            title: "Patient Satisfaction",
            description: "Last reported satisfaction level.",
            component: ReportPatientSatisfaction
        };
    }

    constructor() {
        super();
        this.data = [];
        this.data_table = null;
        this.survey_definition = null;
        this._loading_data = true;

        this.patient_status_filter_options = [];
        this.improve_opportunity_filter_options = [];
        this.satisfaction_filter_options = [];
        const filter_string = new URL(location.href).searchParams?.get("filters")

        const { fromDate, toDate } = this.defaultFilterDates();

        this.filters = JSON.parse(filter_string) || {
            "cp_date_created_range": `${fromDate},${toDate}`
        };

        this._current_page = 1;
        this._page_size = 1000000;
        this._all_pages_fetched = false;
        this.sort_fields = [{ column: "patient_last_name", direction: "asc" }];

        this.header_ref = createRef();
        this.content_ref = createRef();
    }

    connectedCallback() {
        this.render();
        this.init();
    }

    async init() {
        this.survey_definition = await SurveyDefinition.getSurveyDefinition();
        await this.fetchFilterOptions();
        await this.fetchSatisfactionData();
        this.initTable();
        this.setTableHeight();
    }

    async fetchFilterOptions() {
        const patient_definition = await DataDefinition.getDefinition("patient");
        this.patient_status_filter_options = patient_definition.field_dictionary.status.meta.options.choices
            .filter(choice => choice.value === "active" || choice.value === "inactive")
            .map(choice => ({
                value: choice.value,
                label: choice.text,
            }));

        const satisfaction_definition = this.survey_definition.getFieldDictionary().pr_ct_satisfaction;


        this.satisfaction_filter_options = [
            { value: "Very satisfied", label: "Very satisfied" },
            { value: "Somewhat satisfied", label: "Somewhat satisfied" },
            { value: "Unsatisfied", label: "Unsatisfied" },
            { value: "Very unsatisfied", label: "Very unsatisfied" },
            { value: "", label: "Not Yet Asked" }
        ];

        // TODO: track this down and fix it

        //his.satisfaction_filter_options = satisfaction_definition.meta.options.choices.map(
        //   choice => ({
        //       value: choice.value,
        //       label: choice.text,
        //   })
        //;

        this.improve_opportunity_filter_options = [
            { value: "no", label: "No" },
            { value: "yes", label: "Yes" }
        ];

        this.render();
    }

    async fetchSatisfactionData() {
        if (!this._page_size) {
            return;
        }

        this._loading_data = true;
        const client_id = getCurrentClientId();
        const {
            patient_status = ['active', 'inactive'],
            pr_ct_satisfaction_opportunity,
            pr_ct_satisfaction,
            cp_date_created_range
        } = this.filters;

        const res = await directus.transport.get('/vbh/reports/patient_satisfaction', {
            params: {
                filters: {
                    client_ids: [client_id],
                    patient_status,
                    pr_ct_satisfaction_opportunity,
                    pr_ct_satisfaction,
                    survey_completion_date: cp_date_created_range
                },
                page_size: this._page_size,
                page: this._current_page,
                sort: JSON.stringify(this.sort_fields)
            }
        });

        this.data = this.formatRecords(res.data);
        this.data_table?.setData(this.data);

        this._all_pages_fetched = res.data.length < this._page_size;

        this.render();
        this._loading_data = false;
    }

    formatRecords(records) {
        return records.map(record => ({
            patient_id: record.patient_id,
            patient_status: record.patient_status?.charAt(0)?.toUpperCase() + record.patient_status?.slice(1) || "",
            patient_name: `${record.patient_first_name || ""} ${record.patient_last_name || ""}`,
            survey_completion_date: record.surveys[0]?.survey_completion_date,
            pr_ct_satisfaction_opportunity: record.surveys[0]?.pr_ct_satisfaction_opportunity,
            pr_ct_satisfaction: record.surveys[0]?.pr_ct_satisfaction,
            care_team_member: `${record.careteam_first_name || ""} ${record.careteam_last_name || ""}`,
            survey_id: record.surveys[0]?.survey_id
        }));
    }

    handleFilterChange(filter_key, e) {
        this._current_page = 1;
        this._all_pages_fetched = false;
        if (e.detail) {
            this.filters[filter_key] = Array.isArray(e.detail) ? e.detail : [e.detail];
        } else {
            delete this.filters[filter_key];
        }
        this.fetchSatisfactionData();
    }

    handleFilterClear(filter_key) {
        this._current_page = 1;
        this._all_pages_fetched = false
        delete this.filters[filter_key];
        this.fetchSatisfactionData();
    }
    defaultFilterDates() {
        const curDate = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(curDate.getDate() - 30);

        const fromDate = `${thirtyDaysAgo.getFullYear()}-${String(thirtyDaysAgo.getMonth() + 1).padStart(2, '0')}-${String(thirtyDaysAgo.getDate()).padStart(2, '0')}`;
        const toDate = `${curDate.getFullYear()}-${String(curDate.getMonth() + 1).padStart(2, '0')}-${String(curDate.getDate()).padStart(2, '0')}`;

        return {
            "fromDate": fromDate,
            "toDate": toDate
        }
    }

    handleDateFilterChange(e) {
        const { start, end } = e.detail;
        this.filters["cp_date_created_range"] = `${start.toISOString()},${end.toISOString()}`;
        this._current_page = 1;
        this.fetchSatisfactionData();
    }


    initTable() {
        this.data_table = new Tabulator("#patient-satisfaction-table", {
            data: this.data,
            pagination: "local",
            paginationSize: 200,
            paginationCounter: "rows",
            layout: "fitColumns",
            layoutColumnsOnNewData: true,
            movableColumns: true,
            initialSort: [
                { column: "patient_name", dir: "asc" },
            ],
            columnDefaults: {
                tooltip: false,
            },
            columns: [
                { title: "Patient Status", field: "patient_status" },
                { title: "Patient Name", field: "patient_name" },
                { title: "Date Reported", field: "survey_completion_date", formatter: this.dateFormatter },
                {
                    title: "Care Team Member",
                    field: "care_team_member",
                    formatter: function (cell, formatterParams, onRendered) {
                        var value = cell.getValue();
                        if (value.includes("service") || value.includes("api")) {
                            return "Automated";
                        }
                        return value;
                    }
                },
                {
                    title: "Opportunity for Improvement",
                    field: "pr_ct_satisfaction_opportunity",
                    align: "center",
                    cssClass: "opportunity-cell",
                    formatter: function (cell) {
                        var value = cell.getValue();
                        if (value === 'yes') {
                            return "<span class='op-yes'>Improvement Opportunity</span>";
                        } else if (value === 'no') {
                            return "<span class='op-no'>No</span>";
                        } else if (value === null || value === "") {
                            return "<span>-</span>";
                        } else {
                            return "<span>" + value + "</span>";
                        }
                    }
                },
                {
                    title: "Satisfaction Level",
                    field: "pr_ct_satisfaction",
                    cssClass: "satisfaction-cell",
                    formatter: function (cell, formatterParams, onRendered) {
                        var value = cell.getValue();
                        var element = cell.getElement();

                        switch (value) {
                            case "Very satisfied":
                                element.style.backgroundColor = "rgb(216 239 196 / 40%)"; // pastel aqua green
                                break;
                            case "Somewhat satisfied":
                                element.style.backgroundColor = "aliceblue"; // pastel aqua blue
                                break;
                            case "Unsatisfied":
                                element.style.backgroundColor = "rgb(255 251 226)"; // pastel orange
                                break;
                            case "Very unsatisfied":
                                element.style.backgroundColor = "#ffecec"; // pastel red
                                break;
                            case null:
                            case "":
                                return "-"; // Text for empty/null values
                            default:
                                element.style.backgroundColor = ""; // Default to no color if not matched
                                break;
                        }

                        return value; // Return the original value to be displayed
                    }
                },
                {
                    title: "Notes",
                    field: "notes",
                },
            ]
        });

        this.data_table.on("rowClick", (e, row) => {
            let satisfaction = row.getData();
            navigate(`patients/${satisfaction.patient_id}/checkpoints/${satisfaction.survey_id}`);
        });
    }

    dateFormatter(cell) {
        const time = cell.getValue();
        return time ? DateTime.fromISO(time).toFormat('MM/dd/yyyy') : "";
    }

    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }

    render() {
        render(this.template(), this);
    }

    template() {
        return html`
            <div id="main-content" class="container-fluid">
                <div id="widget_wrapper">
                    <div class="button-list">
                        <div class="filter-container" style="align-items: center;">
                            <app-report-filter
                                .title=${"Date Reported"}
                                .is_datepicker=${true}
                                .value=${this.filters.cp_date_created_range}
                                @dateselect=${e => this.handleDateFilterChange(e)}
                                @clear=${_e => this.handleFilterClear("cp_date_created_range")}
                            ></app-report-filter>
                            </div>
                                         <div class="filter-container" style="align-items: center;">
                            <app-report-filter
                                .title=${"Patient Status"}
                                .options=${this.patient_status_filter_options}
                                .value=${this.filters.patient_status}
                                @optionclick=${e => this.handleFilterChange("patient_status", e)}
                                @clear=${_e => this.handleFilterClear("patient_status")}
                            ></app-report-filter>
                        </div>
                        <div class="filter-container" style="align-items: center;">
                            <app-report-filter
                                .title=${"Improvement Opportunity"}
                                .options=${this.improve_opportunity_filter_options}
                                .value=${this.filters.pr_ct_satisfaction_opportunity}
                                @optionclick=${e => this.handleFilterChange("pr_ct_satisfaction_opportunity", e)}
                                @clear=${_e => this.handleFilterClear("pr_ct_satisfaction_opportunity")}
                            ></app-report-filter>
                        </div>
                        <div class="filter-container" style="align-items: center;">
                            <app-report-filter
                                .title=${"Satisfaction Level"}
                                .options=${this.satisfaction_filter_options}
                                .value=${this.filters.pr_ct_satisfaction}
                                @optionclick=${e => this.handleFilterChange("pr_ct_satisfaction", e)}
                                @clear=${_e => this.handleFilterClear("pr_ct_satisfaction")}
                            ></app-report-filter>
                        </div>
                    </div>
                </div>
                <div class="mod-base" id="patient-satisfaction-table" data-toggle="table"></div>
            </div>
        `;
        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });
    }
}

customElements.define("report-patient-satisfaction", ReportPatientSatisfaction);
