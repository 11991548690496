import { html, render } from "lit";

/**
 * Renders an icon and label (alert subject/reason) for the given alert/level.
 * @param {object} alert record from db
 */
export default class AppAlertReason extends HTMLElement {
    get alert() {
        return this._alert;
    }
    set alert(val) {
        this._alert = val;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    
                </style>
                <div class="detail-alert-inner level${this.alert.level}"
                    style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: ${this.getAlertColor(this.alert.level)};
                    ">
                    ${this.getAlertIcon(this.alert.level)}
                    <span class="alert-reason-text">
                        ${this.alert.subject}
                    </span>
                </div>
            `
        }

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.alert) return;
        render(this.template(), this);
    }

    /**
     * @param {number} level 
     * @returns {string} hex color string for given alert level
     */
    getAlertColor(level) {
        switch (level) {
            case 1:
                return "#CD1C3C";
            case 2:
                return "#E4A00F";
            case 3:
                return "#201B3A";
        }
    }

    /**
     * @param {number} level
     * @returns {object} lit for colored alert icon and subject
     */
    getAlertIcon(level) {
        const icon_name = level === 1 ? "report" : "warning";
        return html`
            <span
                class="material-symbols-outlined"
                style='
                    margin: 3px 7px 0 0;
                    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 200;
                    font-size: 20px;

            '>
                ${icon_name}
            </span>
        `;
    }
}

customElements.define("app-alert-reason", AppAlertReason);