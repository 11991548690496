import { html, render } from "lit";
import directus from '../../lib/lib-directus';
import { format } from "date-fns";

export default class AppPatientFiles extends HTMLElement {
    constructor() {
        super();
        this._patient = null;
        this._files = [];
    }

    get patient() {
        return this._patient;
    }

    set patient(value) {
        const old_id = this.patient?.id;
        const new_id = value?.id;
        if (old_id !== new_id) this.fetchPatientFiles(new_id);
        this._patient = value;
        this.render();
    }

    get files() {
        return this._files;
    }

    set files(values) {
        if (!values || !Array.isArray(values)) this._files = [];
        else this._files = values;
    }

    connectedCallback() {
        this.template = () => html` <style>
                .patient-files {
                    border-radius: 8px;
                    box-shadow: var(--t-box-shadow);
                }

                .patient-files small {
                    color: var(--t-color-grey);
                }
            </style>
            <div class="m-2 mt-4 p-4 content-background patient-files">
                <div class="row">
                    <div class="col">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Created</th>
                                    <th>File Size</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                ${this._files.length
                ? this._files.map(
                    (file) => html` <tr>
                                              <td class="text-start d-flex d-md-table-cell" style="flex-wrap: wrap;">
                                                  <div class="d-md-none" style="flex: 1; color: #999;">Title</div>
                                                  ${file.title}
                                              </td>
                                              <td class="d-flex d-md-table-cell" style="flex-wrap: wrap;">
                                                  <div class="d-md-none text-start" style="flex: 1; color: #999;">
                                                      Created
                                                  </div>
                                                  ${format(new Date(file.uploaded_on), "MMM do, yyyy")}
                                              </td>
                                              <td class="d-flex d-md-table-cell" style="flex-wrap: wrap;">
                                                  <div class="d-md-none text-start" style="flex: 1; color: #999;">
                                                      Size
                                                  </div>
                                                  ${this.formatBytes(file.filesize)}
                                              </td>
                                              <td class="d-flex d-md-table-cell" style="flex-wrap: wrap;">
                                                  <div class="d-md-none text-start" style="flex: 1; color: #999;">
                                                      Download
                                                  </div>
                                                  <a
                                                      href="javascript:void(0);"
                                                      @click=${(_e) => this.downloadFile(file)}
                                                      target="_blank"
                                                      download=${file.filename_download}>
                                                      <span class="material-symbols-outlined">file_download</span>
                                                  </a>
                                              </td>
                                          </tr>`
                )
                : html` <tr>
                                          <td colspan="4">
                                              <i>no files for ${this._patient.first_name} ${this._patient.last_name}</i>
                                          </td>
                                      </tr>`}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>`;

        this.style = Object.assign(this.style, {
            display: "block",
        });

        this.render();
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        render(this.template(), this);
    }

    async fetchPatientFiles(patient_id) {
        const res = await directus.files.readByQuery({
            filter: {
                patient_id: {
                    _eq: patient_id,
                },
            },
            sort: ["-uploaded_on"],
        });

        this.files = res.data;
        this.render();
    }

    async downloadFile(file) {
        const token = await directus.auth.token;
        const file_url = `/assets/${file.id}?download=true&access_token=${token}`;
        window.open(file_url, "_blank");
    }
}

customElements.define("app-patient-files", AppPatientFiles);
