import cache from './lib-cache';
import directus from './lib-directus';

/**
 * @typedef {Object} ClientSetting
 * @property {string} id - UUID of the setting
 * @property {string} client_id - The client id that the setting belongs to
 * @property {string} key - The system identifier for the setting. Must follow slug rules.
 * @property {string} value - String representation of the value for the setting. If this is binary (like an image) must be base64 encoded
 * @property {boolean} secure - Indicates whether this is a secure setting. If so, the value is write only and restricted to certain roles. The value will be stored encrypted on the server.
 * @property {string} group - The setting group, if any, the setting belongs to. Used for visual grouping in the UI
 * @property {string} user_created - The user id that created the setting
 * @property {Date} date_created - The date the setting was created
 * @property {string} user_updated - The user id that last updated the setting 
 * @property {Date} date_updated - The date the setting was last updated
 */

class ClientSettings {
    static async loadSettings(client_id) {
        let result = await directus.items("client_setting").readByQuery(
            {
                filter: {
                    client_id
                }
            }
        );
        if(!this._settings)
            this._settings = {}

        //convert the rows to a dictionary convenient for use
        let dict = {ALL:{}};
        for(let setting of result.data) {
            dict.ALL[setting.key] = setting
            if(setting.group) {
                if(!dict[setting.group])
                    dict[setting.group] = {};
                dict[setting.group][setting.key] = setting;
            }
        }
        this._settings[client_id] = dict;
    }

    /**
     * Get all settings for a client
     * @param {string} client_id 
     * @param {string} group - Optional. If specified, will only return settings for the specified group
     * @returns {Object<string, Object<string, ClientSetting>>}
     */
    static async getSettings(client_id) {
        if(!this._settings)
            await this.loadSettings(client_id);
        if(!this._settings[client_id])
            await this.loadSettings(client_id);

        return this._settings[client_id];
    }

    /**
     * Set the value of an existing setting. If the setting does not exist, an error will be thrown
     * @param {string} client_id 
     * @param {string} key 
     * @param {string} value 
     */
    static async setValue(client_id, key, value) {
        if(!this._settings)
            await this.loadSettings(client_id);
        if(!this._settings[client_id])
            await this.loadSettings(client_id);

        let setting = this._settings[client_id].ALL[key];
        if(!setting)
            throw new Error("Invalid setting");

        await directus.items("client_setting").updateOne(setting.id, { value });
        await this.loadSettings(client_id);
    }

    /**
     * Add a new setting for the client
     * @param {ClientSetting} setting
     */
    static async addSetting(setting) {
        let client_id = setting.client_id;
        if(!client_id)
            throw new Error("Missing client id");

        if(!this._settings)
            await this.loadSettings(client_id);
        if(!this._settings[client_id])
            await this.loadSettings(client_id);

        if(!setting.key)
            throw new Error("Missing key")

        if(this._settings[client_id]?.ALL[setting.key])
            throw new Error("Setting already exists");

        await directus.items("client_setting").createOne(setting);
        await this.loadSettings(client_id);
        return this._settings[client_id].ALL[setting.key];
    }
}

export default ClientSettings;
