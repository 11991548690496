import { html, render } from "lit";
import ModalBase from "../util/modal-base";

/**
 * @param {PatientCommunicationLog[]} call_log
 */
export default class AppSoftphoneCallLogModal extends ModalBase {

    set call_log(value) {
        this._call_log = value;
        this.render();
    }

    get call_log() {
        return this._call_log;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    .modal-confirm-btn {
                        background-color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                    }
                    .modal-confirm-btn:hover {
                        background-color: var(--t-color-danger-darkened);
                        border-color: var(--t-color-danger-darkened);
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Patient Call Log</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" style="max-height: 500px; overflow-y: scroll;">
                            <table 
                                class="call-log-table"
                                style="
                                    font-size: 12px;
                            ">  
                                <thead>
                                    <th>TIMESTAMP</th>
                                    <th>EVENT</th>
                                    <th>MESSAGE</th>
                                </thead>
                                <tbody>
                                    ${this.call_log.map(
                entry => html`
                                        <tr>
                                            <td data-label="TIMESTAMP">${entry.date_created.toLocaleString()}</td>
                                            <td data-label="EVENT">${entry.event}</td>
                                            <td data-label="MESSAGE" style="white-space: pre-line;">${entry.message}</td>
                                        </tr>
                                        `
            )}
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-softphone-call-log-modal", AppSoftphoneCallLogModal);
