import { html, render } from "lit";
import { styleMap } from "lit/directives/style-map.js";
import { getResponseText, getSupplementalResponseText } from "../lib/lib-util";
import "../components/app-button"
import "../components/patient/app-patient-note-text";

class ComponentQuestionHistoryDetail extends HTMLElement {
    constructor() {
        super();
        this.expanded_checkpoint_survey_ids = new Set();
    }

    get checkpoints() {
        return this._checkpoints;
    }

    set checkpoints(value) {
        this._checkpoints = value;
        this.render();
    }

    get selected_question() {
        return this._selected_question;
    }

    set selected_question(value) {
        if (this._selected_question !== value) {
            this._selected_question = value;
            this.expanded_checkpoint_survey_ids = new Set();
            this.render();
        }
    }

    get field_definition() {
        return this._field_definition;
    }

    set field_definition(value) {
        this._field_definition = value;
        this.render();
    }

    get form_supplemental_questions() {
        return this._form_supplemental_questions;
    }

    set form_supplemental_questions(value) {
        this._form_supplemental_questions = value;
        this.render();
    }

    get survey_question_notes() {
        return this._survey_question_notes;
    }

    set survey_question_notes(value) {
        this._survey_question_notes = value;
        this.render();
    }

    get hovered_checkpoint_survey_id() {
        return this._hovered_checkpoint_survey_id;
    }

    set hovered_checkpoint_survey_id(value) {
        this._hovered_checkpoint_survey_id = value;
        this.render();
    }

    connectedCallback() {
        this.template = () => html`
            <style>
                #question_detail tr {
                    margin-bottom: 0;
                    border: unset;
                    border-left: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                }
                #question_detail thead {
                    border: 1px solid #ccc;
                    border-bottom: none;
                }
                #question_detail .parent-row {
                    border-top: 1px solid #ccc;
                }
                #question_detail tr:last-child {
                    border-bottom: 1px solid #ccc;
                }
                #question_detail td, #question_detail th {
                    text-align: left;
                }
                #question_detail td {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: #201B3A;
                }
                #question_detail th {
                    font-weight: 800;
                    font-size: 10px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #6E6C7B;
                }
                #question_detail #main-table {
                    border: none; 
                }
                #question_detail .supplemental-table {
                    border: none;
                    margin: 0 0 0 8px;
                }
                #question_detail .supplemental-table tr {
                    margin-left: 0;
                    border: 0;
                }
                #question_detail .supplemental-table td {
                    border: 0;
                }
                #question_detail .supplemental-answer td:first-of-type {
                    padding-left: 16px;
                }
                #question_detail .supplemental-answer td:last-of-type {
                    margin-left: 20px;
                }
                #question_detail .cell--empty {
                    display: none;
                }
                #question_detail .cell--button {
                    padding: unset;
                }
                #question_detail .cell--button .material-symbols-outlined {
                    border-radius: 6px;
                    color: var(--t-color-primary-darkened);
                    padding: 4px;
                }
                #question_detail .cell--button:hover {
                    cursor: pointer
                }
                #question_detail .cell--button:hover .material-symbols-outlined {
                    background: #e7e5f0;
                }
                @media (min-width: 601px) {
                    #question_detail .supplemental-answer td:last-of-type {
                        margin-left: unset;
                    }   
                    #question_detail .cell--empty {
                        display: unset;
                    }
                    #question_detail .cell--caret {
                        width: 32px;
                    }
                    #question_detail .cell--button {
                        width: 42px;
                    }
                }
            </style>
            <div id="question_detail">
                <table id="main-table" style="width: 100%">
                    <thead>
                        <th class="cell--caret"></th>
                        <th>
                            CHECKPOINT
                        </th>
                        <th>
                            RESPONSE
                        </th>
                        <th>
                            DATE RESPONDED
                        </th>
                        <th class="cell--button"></th>
                    </thead>
                    <tbody>
                        ${this.checkpoints.map((checkpoint) => {
            const { survey_id } = checkpoint;
            const is_expanded = this.expanded_checkpoint_survey_ids.has(survey_id)
            const supplemental_questions = (this.form_supplemental_questions[checkpoint.form_design_id]?.[this.selected_question?.field] || [])
                .filter(question => this.hasBeenAsked(checkpoint.submission, question.field));
            const has_supplemental_questions = !!supplemental_questions?.length;

            const base_row_styles = { cursor: "pointer" }
            if (is_expanded) base_row_styles.borderBottom = "none"
            const row_styles =
                survey_id === this.hovered_checkpoint_survey_id
                    ? { ...base_row_styles, backgroundColor: "#f0f0f0" }
                    : base_row_styles;
            const child_row_classes = `child-row${is_expanded ? " child-row--expanded" : ""}`

            return html`
                                <tr 
                                    class="parent-row"
                                    @click=${_e => this.handleRowClick(checkpoint)}
                                    style=${styleMap(row_styles)}
                                >
                                    <td class="cell--caret">
                                        <span style="display: flex; align-items: center; justify-content: center;">
                                            <span class="material-symbols-outlined">
                                                ${is_expanded ? 'expand_less' : 'expand_more'}
                                            </span>
                                        <span>
                                    </td>
                                    <td style="font-weight: 700;" class="cell--checkpoint">
                                        ${checkpoint.source === "baseline" ? "Baseline" : "Week " + checkpoint.week}
                                    </td>
                                    <td>
                                        ${getResponseText(
                checkpoint,
                this.selected_question.field,
                this.field_definition
            )}
                                    </td>
                                    <td>
                                        ${new Date(checkpoint.survey_completion_date).toLocaleDateString()}
                                    </td>
                                    <td 
                                        class="cell--button"
                                        @click=${(e) => this.handleViewDetailsClick(e, survey_id)}
                                    >
                                        <span style="display: flex; justify-content: center;">
                                            <span 
                                                class="material-symbols-outlined" 
                                                role="button"
                                            >
                                                dataset_linked
                                            </span>      
                                        </span>
                                    </td>
                                </tr>
                                ${is_expanded ? html`
                                    ${has_supplemental_questions ? html`
                                        <tr class=${child_row_classes}>
                                            <td class="cell--empty"></td>
                                            <td colspan="4">
                                                <table class="table table-responsive supplemental-table">
                                                    <tbody>
                                                        <tr class=${child_row_classes}>
                                                            <td colspan="3" style="font-weight: 700;">Supplemental Questions:</td>
                                                        </tr>
                                                        ${supplemental_questions.map(question => html`
                                                                <tr class="supplemental-answer ${child_row_classes}">
                                                                    <td style="white-space: nowrap;">
                                                                        <span style="font-weight: 700; margin-right: 4px;">
                                                                            &bull;
                                                                        </span>
                                                                        ${question.label}
                                                                    </td>
                                                                    <td style="width: 99%;">
                                                                        ${getSupplementalResponseText(checkpoint.submission, question)}
                                                                    </td>
                                                                </tr>
                                                            `)
                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    ` : null}
                                    <tr class=${child_row_classes}>
                                        <td class="cell--empty"></td>
                                        <td colspan="4">
                                            <div style="margin-left: 16px;">
                                                <span style="font-weight: 700; margin-right: 8px;">
                                                    Notes:
                                                </span>
                                                <span colspan="3" style="padding-right: 32px;">
                                                    <app-patient-note-text
                                                        .content=${this.survey_question_notes[survey_id]?.[this.selected_question.field]}
                                                    >
                                                    </app-patient-note-text>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ` : null}
                            `;
        })}
                    </tbody>
                </table>
            </div>
        `;
        this.render();
    }

    render() {
        if (!this.template) return;

        if (!this.selected_question) return;

        if (!this.checkpoints) return;

        if (!this.field_definition) return;

        render(this.template(), this);
    }

    /**
     * Handles checkpoint row expansion upon click
     * @param {object} checkpoint vw_measures_delta record
     */
    handleRowClick(checkpoint) {
        if (this.expanded_checkpoint_survey_ids.has(checkpoint.survey_id)) {
            this.expanded_checkpoint_survey_ids.delete(checkpoint.survey_id);
        }
        else {
            this.expanded_checkpoint_survey_ids.add(checkpoint.survey_id);
        }
        this.render();
    }

    /**
     * @param {Event} e <app-button> click event
     * @param {string} survey_id vw_measures_delta.survey_id, corresponds to hh_patient_reported_measures.id
     * @fires ComponentQuestionHistoryChart#viewdetailsclick
     */
    handleViewDetailsClick(e, survey_id) {
        e.stopPropagation();
        this.dispatchEvent(
            new CustomEvent("viewdetailsclick", {
                detail: { survey_id },
            })
        );
    }

    /**
     * @param {object} checkpoint_submission hh_patient_reported_measures.submission json
     * @param {string} field_key name / key of field to check
     * @returns {boolean}
     */
    hasBeenAsked(checkpoint_submission, field_key) {
        const value = checkpoint_submission[field_key]
        if (value !== null && value !== undefined) {
            return true;
        }
        return false;
    }
}

customElements.define("app-question-history-detail", ComponentQuestionHistoryDetail);
export default ComponentQuestionHistoryDetail;
