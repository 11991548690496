import { html, render } from "lit";
import * as bootstrap from "bootstrap";

/**
 * @param {AvoidableEventsMetrics} metrics flat object with all impact metrics
 * A widget displaying metrics around average costs per visit.
 */
export default class AppAvoidableEventsVisitCost extends HTMLElement {
    constructor() {
        super();
        this.metrics = {};
    }

    set metrics(value) {
        this._metrics = value || {};
        this.render();
    }
    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #avoidable-events-visit-cost h6 {
                        margin: unset;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 26px;
                        color: var(--t-color-dark);
                    }
                    #avoidable-events-visit-cost .widget-row {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    #avoidable-events-visit-cost .widget-metric {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                        height: 84px;
                        position: relative;
                    }
                    #avoidable-events-visit-cost .widget-metric__value {
                        font-weight: 700;
                        line-height: 56px;
                        font-size: 40px;
                        letter-spacing: 0.5px;
                    }
                    #avoidable-events-visit-cost .widget-metric__label {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 26px;
                        color: var(--t-color-dark);
                        text-align: center;
                        white-space: pre-line;
                    }
                </style>
                <div 
                    id="avoidable-events-visit-cost" 
                    style="
                        height: 100%; 
                        display: flex; 
                        flex-direction: column;
                    "
                >
                    <div style="
                        margin-top: 8px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-around;
                    ">
                        <div class="widget-row">
                            <div 
                                class="widget-metric"
                                title="Avg Reimbursement Per Patient<hr />Avg # of Visits per Patient"
                                data-bs-html="true"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                data-bs-custom-class="widget-formula-tooltip"
                            >
                                <span class="widget-metric__value" style="color: var(--t-color-success);">
                                    ${"$" + (Math.round(this.metrics.avg_reimbusement_per_visit) || 0).toLocaleString()}
                                </span>
                                <span class="widget-metric__label">Average Reimbursement Per Visit</span>
                            </div>
                        </div>
                        <div class="widget-row">
                            <div 
                                class="widget-metric"
                                title="Avg Cost Per Patient<hr />Avg # of Visits per Patient"
                                data-bs-html="true"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                data-bs-custom-class="widget-formula-tooltip"
                            >
                                <span class="widget-metric__value" style="color: var(--t-color-danger);">
                                    ${"$" + (Math.round(this.metrics.avg_cost_per_visit) || 0).toLocaleString()}
                                </span>
                                <span class="widget-metric__label">Average Cost Per Visit</span>
                            </div>
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
        });

        this.render();
        [...this.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((el) => new bootstrap.Tooltip(el));
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

}

customElements.define("app-avoidable-events-visit-cost", AppAvoidableEventsVisitCost);
