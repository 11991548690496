import { html, render } from "lit";
import { navigate } from "shared/lib/lib-router";

/**
 * A widget with patient satisfaction metrics for the current client.
 * @param {object} metrics reponse from /dashboard_metrics
 */
export default class AppDashboardSatisfaction extends HTMLElement {
    constructor() {
        super();
        this.satisfaction_counts = {
            very_satisfied: 0,
            somewhat_satisfied: 0,
            unsatisfied: 0,
            very_unsatisfied: 0
        };
        this.report_filters = { patient_status: ["active"] };
    }

    set metrics(value) {
        this._metrics = value;
        this.render();
    }

    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            const patient_satisfaction = this.metrics.patient_satisfaction || [];
            const very_satisfied_metrics = patient_satisfaction.find(item => item.satisfaction === 0);
            const somewhat_satisfied_metrics = patient_satisfaction.find(item => item.satisfaction === 1);
            const unsatisfied_metrics = patient_satisfaction.find(item => item.satisfaction === 2);
            const very_unsatisfied_metrics = patient_satisfaction.find(item => item.satisfaction === 3);

            const very_satisfied_percentage = Math.round(very_satisfied_metrics?.percentage) || 0;
            const somewhat_satisfied_percentage = Math.round(somewhat_satisfied_metrics?.percentage) || 0;
            const unsatisfied_percentage = Math.round(unsatisfied_metrics?.percentage) || 0;
            const very_unsatisfied_percentage = Math.round(very_unsatisfied_metrics?.percentage) || 0;

            const very_satisfied_count = very_satisfied_metrics?.satisfaction_count || 0;
            const somewhat_satisfied_count = somewhat_satisfied_metrics?.satisfaction_count || 0;
            const unsatisfied_count = unsatisfied_metrics?.satisfaction_count || 0;
            const very_unsatisfied_count = very_unsatisfied_metrics?.satisfaction_count || 0;

            const not_yet_asked_percentage = this.metrics ?
                100 - very_satisfied_percentage - somewhat_satisfied_percentage - unsatisfied_percentage - very_unsatisfied_percentage
                : 0;

            const not_yet_asked_count = this.metrics ?
                this.metrics.total_patient_count - very_satisfied_count - somewhat_satisfied_count - unsatisfied_count - very_unsatisfied_count
                : 0;

            return html`
                <style>
                    #dashboard-satisfaction h6 {
                        margin: unset;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 26px;
                        color: var(--t-color-dark);
                    }
                    #dashboard-satisfaction .satisfaction-widget-row {
                        display: flex;
                        align-items: center;
                        align-self: center;
                        width: 100%;
                        max-width: 300px;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    #dashboard-satisfaction .satisfaction-widget-row__percentage {
                        margin-left: auto; 
                        font-weight: 700;
                    }
                    #dashboard-satisfaction .satisfaction-widget-row__value {
                        margin-left: 32px;
                        width: 16px;
                        text-align: right;
                    }
                </style>
                <div 
                    id="dashboard-satisfaction" 
                    style="
                        height: 100%; 
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                    "
                    @click=${_e => navigate(`reports/patient/satisfaction?filters=${JSON.stringify(this.report_filters)}`)}
                >
                    <h6>Last Reported Satisfaction Level (Active Patients)</h6>
                    <div style="margin: auto 0; display: flex; flex-direction: column;">
                        <div class="satisfaction-widget-row">
                            <app-color-label .text=${"Very Satisfied"} .color=${"var(--t-color-success)"}></app-color-label>    
                            <span class="satisfaction-widget-row__percentage">
                                ${very_satisfied_percentage}%
                            </span>
                            <span class="satisfaction-widget-row__value">
                                ${very_satisfied_count}
                            </span>
                        </div>
                        <div class="satisfaction-widget-row">
                            <app-color-label .text=${"Satisfied"} .color=${"var(--t-color-success-light)"}></app-color-label>    
                            <span class="satisfaction-widget-row__percentage">
                                ${somewhat_satisfied_percentage}%
                            </span>
                            <span class="satisfaction-widget-row__value">
                                ${somewhat_satisfied_count}
                            </span>
                        </div>
                        <div class="satisfaction-widget-row">
                            <app-color-label .text=${"Unsatisfied"} .color=${"var(--t-color-warning)"}></app-color-label>    
                            <span class="satisfaction-widget-row__percentage">
                                ${unsatisfied_percentage}%
                            </span>
                            <span class="satisfaction-widget-row__value">
                                ${unsatisfied_count}
                            </span>
                        </div>
                        <div class="satisfaction-widget-row">
                            <app-color-label .text=${"Very Unsatisfied"} .color=${"var(--t-color-danger)"}></app-color-label>    
                            <span class="satisfaction-widget-row__percentage">
                                ${very_unsatisfied_percentage}%
                            </span>
                            <span class="satisfaction-widget-row__value">
                                ${very_unsatisfied_count}
                            </span>
                        </div>
                        <div class="satisfaction-widget-row">
                            <app-color-label .text=${"Not Yet Asked"} .color=${"var(--t-color-grey)"}></app-color-label>    
                            <span class="satisfaction-widget-row__percentage">
                                ${not_yet_asked_percentage}%
                            </span>
                            <span class="satisfaction-widget-row__value">
                                ${not_yet_asked_count}
                            </span>
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px",
        });
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-dashboard-satisfaction", AppDashboardSatisfaction);
