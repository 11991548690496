import { html, render } from 'lit';
import { range } from 'lit/directives/range.js';
import { map } from 'lit/directives/map.js';

/*
Filter data looks like:
[
    {
        [group field name]: [group field value],
        [aggregate function]: {
            [aggregate function column name]: [aggrgate value]
        }
    }
]
 */

class AppFilterBigNumber extends HTMLElement {
    set data(value) {
        this._data = value;
        this.render();
    }

    get data() {
        return this._data;
    }

    set filter_state(value) {
        this._filter_state = value;
        this.render();
    }

    /** @type {import('./app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;
    }

    set num_ticks(value) {
        this._num_ticks = value;
    }

    get num_ticks() {
        if (this._num_ticks > this.max_value)
            return this.max_value;
        return this._num_ticks || 5;
    }

    get max_value() {
        let max = 0;
        for (let row of this.data) {
            let value = parseInt(row[this.filter_state.aggregate[0].aggregate_function][this.filter_state.aggregate[0].name]);
            if (value > max)
                max = value;
        }
        if (!max)
            return 100;
        return max;
    }

    connectedCallback() {
        this.template = () => html`
            <style>
                #app-filter-big-number {
                    background-color: white;
                }
                #app-filter-big-number h6 {
                    margin: unset;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 26px;
                    color: var(--t-color-dark);
                }
                #app-filter-big-number .widget-key-value {
                    display: flex;
                    justify-content: space-between;
                    width: 124px;
                    font-size: 14px;
                    margin-bottom: 8px;
                }
                #app-filter-big-number .widget-key-value span:last-child {
                    margin-left: 8px;
                }
            </style>
                <div 
                    id="app-filter-big-number" 
                    style="
                        box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                        height: 100%; 
                        cursor: pointer; 
                        border-radius: 8px;"
                    @click=${_e => this.drilldown()}
                >
                    <div style="
                        width: 100%;
                        display: flex; 
                        flex-direction: row; 
                        align-items: center; 
                        justify-content: center;
                        padding: 5px;
                        font-size: 14px;
                        font-weight: 700;
                        color: var(--t-color-grey);
                        ">
                        (${this.filter_state.aggregate[0].aggregate_function} of ${this.filter_state.aggregate[0].field_config.label})
                    </div>
                        
                    <div style="
                        display: flex; 
                        flex-direction: column; 
                        align-items: center; 
                        justify-content: center;
                        height: calc(100% - 26px);
                    ">
                        <div style="
                            display: flex; 
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: 32px;
                        " >
                            <span style="
                                font-weight: 700;
                                font-size: 50px;
                                line-height: 56px;
                                letter-spacing: 0.5px;
                                color: var(--t-color-dark);
                            "
                            >
                                ${this.data[0][this.filter_state.aggregate[0].aggregate_function][this.filter_state.aggregate[0].name]}
                            </span>
                            <span style="
                                font-size: 14px;
                                font-weight: 700;
                                color: var(--t-color-grey);
                            ">
                            ${this.filter_state.aggregate[0].field_config.label}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        `;
        this.render();
    }

    render() {
        if (!this.template)
            return;

        if (!this.data)
            return;

        if (!this.filter_state)
            return;

        render(this.template(), this);
    }

    drilldown(row) {
        let filter_state = structuredClone(this.filter_state);
        filter_state.aggregate = [];
        filter_state.aggregate_group = [];
        this.dispatchEvent(new CustomEvent('drilldown', { composed: true, bubbles: true, detail: filter_state }));
    }

}

customElements.define('app-filter-big-number', AppFilterBigNumber);
export default AppFilterBigNumber;