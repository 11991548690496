import { html, render } from "lit";
import { ref, createRef } from "lit/directives/ref.js";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"

import directus from "shared/lib/lib-directus";
import DataDefinition from "shared/lib/lib-data-definition";
import { adddirectusClientFilter, getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";
import { getISODateStringWithoutTime } from "shared/lib/lib-date";

import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";

/**
 * @todo report superclass with common sort/filter functions, calls this.fetchData()
 * An encapuslated component showing 'high-risk' (level 1) alerts from the last 30 days.
 * Data matches dashboard widget <app-dashboard-high-risk-events>.
 */
export default class ReportPatientHighRiskIncidentBreakout extends HTMLElement {
    static get config() {
        return {
            icon: "warning",
            report: "high-risk-incident-breakout",
            title: "Hospitalization Risk & Care Event Incidents",
            description: "Patient Hospitalization Risk & Care Event Incidents from the last 30 days.",
            component: ReportPatientHighRiskIncidentBreakout
        };
    }

    constructor() {
        super();
        this.data = [];
        this.data_table = null;
        this.survey_definition = null;
        this._loading_data = true;

        // filters
        this.patient_status_filter_options = [];
        this.incident_type_filter_options = [];
        this.alert_level_filter_options = [];
        const filter_string = new URL(location.href).searchParams?.get("filters")
        this.filters = JSON.parse(filter_string) || {};
        //pagination
        this._current_page = 1;
        this._page_size = 1000000;
        this._all_pages_fetched = false;

        //sort
        this.sort_fields = [
            { column: "alert_date_created", direction: "desc" },
        ];

        this.incident_rules = [
            {
                label: "Fall",
                filter_name: "falls",
                //color: "#ffffff",
                background_color: "#9d3056",
            },
            {
                label: "Med Change",
                filter_name: "medication_changes",
                //color: "#ffffff",
                background_color: "#9d3056",
            },
            {
                label: "Med Side Effect",
                filter_name: "medication_side_effects",
                //color: "#ffffff",
                background_color: "#9d3056",
            },
            {
                label: "Med Refill",
                filter_name: "medication_refills",
                //color: "#ffffff",
                background_color: "#9d3056",
            },
            {
                label: "Dyspnea",
                filter_name: "dyspnea",
                //color: "#ffffff",
                background_color: "#9d3056",
            },
            {
                label: "Unsatisfied with Care",
                filter_name: "unsatisfied_with_care",
                color: "#000000",
                background_color: "#ffa726",
            },
            {
                label: "HHA Services Understood",
                filter_name: "understanding_of_hha_services",
                color: "#000000",
                background_color: "#ffa726",
            },
            {
                label: "Care Experience",
                filter_name: "care_experience",
                color: "#000000",
                background_color: "#ffa726",
            },
            {
                label: "Home Safety",
                filter_name: "home_safety",
                color: "#000000",
                background_color: "#ffa726",
            },
        ];


        // @todo: This list was originally loaded from the alert_rule table, which isn't quite relevant
        // to this particular report. Hard coding it for now, until we have a better solution in place (Mark Gilcrease)        
        this.event_categories = new Set([
            {
                id: "care_experience",
                label: "Care Experience",
                alert_rule_descriptions: [
                    "care_experience",
                ]
            },
            {
                id: "dyspnea",
                label: "Dyspnea",
                alert_rule_descriptions: [
                    "dyspnea",
                ]
            },
            {
                id: "falls",
                label: "Falls",
                alert_rule_descriptions: [
                    "falls",
                ]
            },
            {
                id: "home_safety",
                label: "Home Safety",
                alert_rule_descriptions: [
                    "home_safety"
                ]
            },
            {
                id: "medication_changes",
                label: "Medication Changes",
                alert_rule_descriptions: [
                    "medication_changes"
                ]
            },
            {
                id: "medication_refills",
                label: "Medication Refills",
                alert_rule_descriptions: [
                    "medication_refills",
                ]
            },
            {
                id: "medication_side_effects",
                label: "Medication Side Effects",
                alert_rule_descriptions: [
                    "medication_side_effects",
                ]
            },
            {
                id: "understanding_of_hha_services",
                label: "Understanding of HHA Services",
                alert_rule_descriptions: [
                    "understanding_of_hha_services"
                ]
            },
            {
                id: "unsatisfied_with_care",
                label: "Unsatisfied with Care",
                alert_rule_descriptions: [
                    "unsatisfied_with_care"
                ]
            },
        ]);


        this.header_ref = createRef();
        this.content_ref = createRef();
    }

    get filters_expanded() {
        return this._filters_expanded;
    }

    set filters_expanded(value) {
        this._filters_expanded = value;
        this.render();
    }

    connectedCallback() {
        //This alows showing / hiding the filter dropdown through css and avoid multiple filter instances
        const filters_breakpoint_query = window.matchMedia("(min-width: 1275px)");
        this.filters_expanded = filters_breakpoint_query.matches
        filters_breakpoint_query.addEventListener("change", (evt) => {
            this.filters_expanded = evt.matches
            if (!evt.matches) {
                this.initFilterDropdown();
            }
        });

        this.template = () => {
            return html`
                 <div id="main-content" class="container-fluid">
                        <div id="widget_wrapper">
                                <div class="button-list">
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Patient Status"}
                                            .options=${this.patient_status_filter_options}
                                            .value=${this.filters.patient_status}
                                            @optionclick=${e => this.handleFilterChange("patient_status", e)}
                                            @clear=${_e => this.handleFilterClear("patient_status")}
                                        ></app-report-filter>
                                    </div>
                                    <!-- <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Primary Clinician"}
                                            .options=${this.primary_clinician_options || []}
                                            .value=${this.filters.primary_clinician}
                                            @optionclick=${e => this.handleFilterChange("primary_clinician", e)}
                                            @clear=${_e => this.handleFilterClear("primary_clinician")}
                                        ></app-report-filter>
                                    </div> -->
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Date Reported"}
                                            .is_datepicker=${true}
                                            .value=${this.filters.alert_date_created_range}
                                            @dateselect=${e => this.handleDateFilterChange(e)}
                                            @clear=${_e => this.handleFilterClear("alert_date_created_range")}
                                        ></app-report-filter>
                                    </div>
                                    <div class="filter-container" style="align-items: center;">
                                        <app-report-filter
                                            .title=${"Incidents"}
                                            .options=${this.incident_type_filter_options}
                                            .value=${this.filters.event_categories}
                                            @optionclick=${e => this.handleFilterChange("event_categories", e)}
                                            @clear=${_e => this.handleFilterClear("alert_rule_description")}
                                        ></app-report-filter>
                                    </div>
                               
                            </div>
                        </div>
                        <div class="mod-base" id="patient-high-risk-events-table" data-toggle="table"></div>
                    </div>
                </div>
            `
        }

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Load survey definitions, fetch report data, and update table.
     */
    async init() {
        this.fetchFilterOptions();
        await this.fetchAlertData();
        this.initTable();
        this.setTableHeight();
    }

    /**
     * Fetches latest report data and updates the data table. 
     */
    async fetchAlertData() {
        if (!this._page_size) {
            // still being calculated/initialized
            return;
        }

        this._loading_data = true;
        const client_id = getCurrentClientId()
        const {
            patient_status = ['active', 'inactive'],
            primary_clinician,
            alert_date_created_range,
        } = this.filters

        const alert_rule_description = Array.from(this.event_categories)
            .filter(category => this.filters.event_categories?.includes(category.id))
            .map(category => category.alert_rule_descriptions)
            .join()
            .split(',');

        const res = await directus.transport.get('/vbh/reports/high_risk_incident_breakout', {
            params: {
                filters: {
                    client_ids: [client_id],
                    patient_status,
                    primary_clinician,
                    alert_date_created_range,
                    alert_rule_description,
                },
                page_size: this._page_size,
                page: this._current_page,
                sort: JSON.stringify(this.sort_fields)
            }
        });

        // Tabulator Table
        this.data = this.formatRecords(res.data);
        this.data_table?.setData(this.data);

        this._all_pages_fetched = res.data.length < this._page_size;

        this.render();
        this._loading_data = false;
    }

    /**
     * Format records to display tabulator table.
     * @param {*} records 
     * @returns 
     */
    formatRecords(records) {
        return records.map(record => ({
            incident_id: record.incident_id,
            patient_id: record.patient_id,
            survey_id: record.survey_id,
            patient_status: (record.patient_status.charAt(0).toUpperCase() + record.patient_status.slice(1)) || "",
            patient_first_name: record.patient_first_name,
            patient_last_name: `${record.patient_first_name || ""} ${record.patient_last_name || ""}`,
            primary_clinician_last_name: `${record.primary_clinician_first_name || ""} ${record.primary_clinician_last_name || ""}`,
            alert_level: record.alerts.length > 0 ? "Level " + record.alerts[0].alert_level : "No Alert",
            alert_status: record.alerts.length > 0 ? record.alerts[0].alert_status : "No Alert",
            alert_message: record.alerts.length > 0 ? record.alerts[0].alert_message : "No Notes",
            alert_date_created: record.alert_date_created,
            alert_rule_description: this.formatRuleDescription(record.incident_type, record)
        }));
    }


    formatRuleDescription(incident_type, row) {
        const rule = this.incident_rules.find(rule => rule.filter_name === incident_type);
        if (rule) {
            const hasAlert = row.alerts && row.alerts.length > 0;
            const backgroundColor = hasAlert ? rule.background_color : "#e0e0e0";
            const color = !hasAlert ? rule.color : "#e0e0e0"; // Gray if no alert
            return `<span style="display: inline-block; color: ${color}; background-color: ${backgroundColor}; margin-bottom: 5px; margin-right: 5px; padding: 0px 5px; border-radius: 5px;">${rule.label}</span>`;
        }
        return '';
    }



    async fetchFilterOptions() {
        const alert_definition_request = DataDefinition.getDefinition("alert");
        const patient_definition_request = DataDefinition.getDefinition("patient");

        const primary_clinician_request = directus.items("junction_directus_users_client").readByQuery({
            fields: ["*", "directus_users_id.*"],
            filter: adddirectusClientFilter({
                directus_users_id: {
                    role: {
                        name: { _in: ["Clinician", "Client"] }
                    }
                },
            })
        });

        const [
            alert_definition,
            patient_definition,
            primary_clinician_data
        ] = await Promise.all([
            alert_definition_request,
            patient_definition_request,
            primary_clinician_request
        ]);


        this.alert_level_filter_options = alert_definition.field_dictionary.level.meta.options.choices.map(
            choice => ({
                value: String(choice.value),
                label: choice.text,
            })
        );
        this.patient_status_filter_options = patient_definition.field_dictionary.status.meta.options.choices
            .filter(choice => choice.value === "active" || choice.value === "inactive")
            .map(
                choice => ({
                    value: choice.value,
                    label: choice.text,
                })
            );
        this.incident_type_filter_options = Array.from(this.event_categories).map(category => ({
            value: category.id,
            label: category.label,
        }));

        const unique_clinician_ids = new Set();
        this.primary_clinician_options = primary_clinician_data.data
            .map(item => ({
                value: item.directus_users_id.id,
                label: `${item.directus_users_id.first_name || ''} ${item.directus_users_id.last_name || ''}`,
            }))
            .filter(option => {
                if (!unique_clinician_ids.has(option.value)) {
                    unique_clinician_ids.add(option.value);
                    return true;
                }
                return false;
            });

        this.render();
    }

    /**
     * @param {CustomEvent} e AppReportFilter#dateselect event
     */
    handleDateFilterChange(e) {
        const { start, end } = e.detail
        this.filters["alert_date_created_range"] = `${getISODateStringWithoutTime(start)},${getISODateStringWithoutTime(end)}`;
        this.fetchAlertData();
    }

    /**
     * handler for AppReportFilter#clear
     * @param {string} filter_key one of this.filters
     */
    handleFilterClear(filter_key) {
        delete this.filters[filter_key];
        this.fetchAlertData();
    }

    /**
     * @param {string} filter_key one of this.filters
     * @param {CustomEvent} e AppReportFilter#optionclick event
     */
    handleFilterChange(filter_key, e) {
        if (e.detail) this.filters[filter_key] = e.detail;
        else delete this.filters[filter_key];
        this.fetchAlertData();
    }

    /**
     * Initializes an instance of BootstrapTable with the specified options. 
     */
    initTable() {
        //console.log("initTable" + JSON.stringify(this.filters));
        this.data_table = new Tabulator("#patient-high-risk-events-table", {
            data: this.data,           //load row data from array
            history: true,             //allow undo and redo actions on the table
            pagination: "local",       //paginate the data
            paginationSize: 25,         //allow 7 rows per page of data
            paginationCounter: "rows", //display count of paginated rows in footer
            layout: "fitDataStretch",
            layoutColumnsOnNewData: true, //fit columns to width of table (optional)
            movableColumns: true,      //allow column order to be changed
            initialSort: [             //set the initial sort order of the data
                { column: "alert_date_created", dir: "desc" },
            ],
            columnDefaults: {
                tooltip: true,         //show tool tips on cells
            },
            columns: [
                { title: "Patient Status", field: "patient_status" },
                { title: "Patient Name", field: "patient_last_name" },
                {
                    title: "Alert Level",
                    field: "alert_level",
                    hozAlign: "center",
                    formatter: function (cell, formatterParams, onRendered) {
                        const value = cell.getValue();
                        let color = "";
                        let textColor = "";
                        let fontWeight = "";
                        if (value === "Level 1") {
                            color = "#9d3056";
                            textColor = "white";
                            fontWeight = "bold";
                        } else if (value === "Level 2") {
                            color = "#e59b41";
                            textColor = "white";
                            fontWeight = "bold";
                        } else if (value === "Level 3") {
                            color = "#ffeb3b";
                            textColor = "white";
                            fontWeight = "bold";
                        }
                        cell.getElement().style.backgroundColor = color;
                        cell.getElement().style.color = textColor;
                        return value;
                    }
                },
                { title: "Alert Status", field: "alert_status" },
                { title: "Date Reported", field: "alert_date_created", formatter: this.dateFormatter },
                {
                    title: "Incidents",
                    field: "alert_rule_description",
                    formatter: "html",
                    headerSort: false,
                    widthGrow: 5,
                    cellClick: function (e, cell) {
                        const data = cell.getRow().getData();
                        navigate(`patients/${data.patient_id}/checkpoints/${data.survey_id}`);
                    }
                },
                { title: "Notes", field: "alert_message" },
            ]
        });

        this.data_table.on("dataLoaded", function () {
            this.recalc();
        });
    }

    dateFormatter(cell) {
        const time = cell.getValue();

        if (time && time != "")
            return DateTime.fromISO(time).toFormat('MM/dd/yyyy');
        return "";
    }
    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }
}

customElements.define("report-patient-high-risk-incident-breakout", ReportPatientHighRiskIncidentBreakout);
