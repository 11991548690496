import { html, render } from "lit";
import cache from "../lib/lib-cache";

import "../components/patient/app-patient-header";
import "../components/availability/app-availability-view";

export default class ScenePatientAvailability extends HTMLElement {
    constructor() {
        super();
        this.boundResizeHandler = this.handleWindowResize.bind(this);
        this.sms_id = null;
    }
    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
        this.sms_id = value.params?.sms_id;
        this.loadPatient();
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <app-patient-header .page=${"activity"} .patient=${this.patient}></app-patient-header>
                <div style="margin: 16px 24px; " >
                    <app-availability-view
                        .patient=${this.patient}
                        .sms_id=${this.sms_id}
                    ></app-availability-view>
                </div>
            `;
        }

        this.style.display = "block";
        this.style.height = "100%";
        this.style.overflowY = "auto";

        this.render();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        render(this.template(), this);
    }

    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = document.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

    async loadPatient() {
        this.patient = await cache.getPatient(this.patient_id);
        if (!this._patient_header_height) {
            this._patient_header_height = this.querySelector('app-patient-header')?.clientHeight;
        }
        this.render();
    }
}

customElements.define("scene-patient-availability", ScenePatientAvailability);