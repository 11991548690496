import { html, render } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { differenceInMinutes, differenceInHours, differenceInCalendarDays } from "date-fns";
import directus from "../lib/lib-directus";
import { navigate } from "../lib/lib-router";
import AppConfirmationModal from "./app-confirmation-modal";

/**
 * Renders a scrollable list of notifications for the current user.
 * Clicking a notification takes you to the corresponding URL.
 * @fires AppNotificationsList#notificationsupdated when notifications are fetched from the server
 */
export default class AppNotificationsList extends HTMLElement {
    constructor() {
        super();
        this.notifications = [];

        const five_minutes_ms = 60 * 5 * 1000;
        setInterval(this.fetchNotifications.bind(this), five_minutes_ms);
    }

    /**
     * Queries directus for notifications received by the current user.
     * @todo auth token lost when refreshing certain pages (e.g. patient details)
     */
    async fetchNotifications() {
        const current_user = await directus.users.me.read();

        // TODO: currently the user can see all notifications in the system
        // will this work for our client users? (non admins)
        const notifications_result = await directus.items("directus_notifications").readByQuery({
            filter: {
                recipient: { _eq: current_user.id },
                delivery_method: { _eq: "app" },
                status: "inbox",
            },
            sort: "-timestamp",
            limit: 5000
        });
        if (!notifications_result.data.length) {
            // TODO: empty state / error handling
        }
        this.notifications = notifications_result.data;
        this.render();
        this.dispatchEvent(new CustomEvent("notificationsupdated"));
    }

    /**
     * Returns a human readable time of the notification.
     * @param {string} timestamp
     * @returns {string} formatted time e.g. 11h ago.
     */
    formatNotificatonTime(timestamp) {
        const now = new Date();
        const timestamp_date = new Date(timestamp);
        const time_difference_mins = differenceInMinutes(now, timestamp_date);
        const time_difference_hrs = differenceInHours(now, timestamp_date);
        const time_difference_days = differenceInCalendarDays(now, timestamp_date);

        if (time_difference_mins < 60) return time_difference_mins + "m ago";
        else if (time_difference_hrs < 24) return time_difference_hrs + "h ago";
        else return time_difference_days + "d ago";
    }

    connectedCallback() {
        this.template = () => {
            const notification_rows = this.notifications.map((notification) => {
                // Remove the 'click here to view' link going to directus admin
                const message_without_link = notification.message?.split("\n\n<a")[0];
                return html`<li
                    class="list-group-item"
                    @click=${(_e) => this.handleNotificationClick(notification)}
                    style="
                        cursor: ${(notification.item || notification.collection === "patient-import") ? "pointer" : "not-allowed"};
                        display: flex;
                        flex-direction: column;
                    ">
                    <div class="d-flex" style="width: 100%; align-items: flex-start; margin-bottom: 6px;">
                        <h6
                            style="
                            text-transform: uppercase; 
                            font-size: 12px;
                            margin: unset;
                            font-weight: 600;
                            align-self: center;
                            line-height: 15px;
                        ">
                            ${notification.subject}
                        </h6>
                        <span
                            style="
                            margin-left: auto; 
                            font-size: 12px; 
                            font-style: italic;
                            white-space: nowrap;
                            padding-left: 12px;
                            line-height: 15px;
                        ">
                            ${this.formatNotificatonTime(notification.timestamp)}
                        </span>
                    </div>
                    <div
                        style="
                        font-size: 13px;
                    ">
                        ${unsafeHTML(message_without_link)}
                    </div>
                    <button
                        type="button"
                        class="btn btn-outline-danger btn-sm notification-archive-button notification-archive-button--small"
                        @click=${(e) => this.handleArchiveClick(e, notification)}>
                        Archive
                    </button>
                </li>`;
            });
            return html`
                <style>
                    #notifications-header {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                        box-shadow: 0 4px 2px -2px rgba(0, 39, 77, 0.08);
                        margin-bottom: 4px;
                        padding: 8px 12px;
                        height: 40px;
                    }
                    #notifications-header h5 {
                        margin-bottom: unset;
                    }
                    #notifications-container {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border-top: none;
                    }
                    #notifications-container li {
                        border-left: none;
                        border-right: none;
                        padding: 12px 16px 18px 16px;
                    }
                    #notifications-container li:first-child {
                        border-top: none;
                        padding-top: 8px;
                    }
                    .notification-archive-button {
                        color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                        height: 20px;
                        display: flex;
                        align-items: center;
                    }
                    .notification-archive-button--small {
                        position: relative;
                        margin-left: auto;
                        top: 6px;
                        color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                        height: 20px;
                        display: flex;
                        align-items: center;
                    }
                    .notification-archive-button:hover {
                        color: var(--t-color-white);
                        background-color: var(--t-color-danger);
                    }
                </style>
                ${notification_rows.length
                    ? html`<div class="d-flex" id="notifications-header">
                          <h5>Notifications</h5>
                          <button
                              class="btn btn-sm btn-outline-danger notification-archive-button"
                              style="margin-left: auto"
                              @click=${(e) => this.handleArchiveAllClick(e)}>
                              Archive All
                          </button>
                      </div>`
                    : ""}
                <ul class="list-group" id="notifications-container" style="max-height: 500px; overflow-y: auto;">
                    ${notification_rows.length
                    ? notification_rows
                    : html`<div style="height: 200px; display: flex; align-items: center; justify-content: center;">
                              No new notifications!
                          </div>`}
                </ul>
            `;
        };

        this.fetchNotifications();

        Object.assign(this.style, {
            width: "345px",
            display: "block",
        });
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Click handler for 'Archive' button
     * @todo 'are you sure' confirmation?
     */
    async handleArchiveClick(e, notification) {
        e.stopPropagation();
        await this.archiveNotification(notification);
    }

    async handleArchiveAllClick(e) {
        e.stopPropagation();
        const confirmation_modal = new AppConfirmationModal();
        confirmation_modal.modal_text = "Are you sure you want to archive all notifications?";
        await confirmation_modal.showModal();
        const result = await confirmation_modal.onDidDismiss();
        if (result?.confirmed) {
            const all_notification_ids = this.notifications.map((notification) => notification.id);
            await directus.items("directus_notifications").updateMany(all_notification_ids, {
                status: "archived",
            });
            this.fetchNotifications();
        }
    }

    async archiveNotification(notification) {
        await directus.items("directus_notifications").updateOne(notification.id, {
            status: "archived",
        });
        this.fetchNotifications();
    }

    /**
     * Navigates to patient checkpoint details upon notification click.
     * Assumes that notification.item will always be a patient id, if defined.
     * @param {Object} notification directus notification
     */
    handleNotificationClick(notification) {
        if (!notification.item && notification.collection !== "patient-import") return;
        switch (notification.collection) {
            case "alert":
                directus
                    .items("alert")
                    .readOne(notification.item)
                    .then((alert) => {
                        navigate(`patients/${alert.patient_id}/alerts`);
                    });
                break;
            case "patient_note":
                directus
                    .items("note")
                    .readOne(notification.item)
                    .then((note) => {
                        navigate(`patients/${note.patient_id}/notes`);
                    });
                break;
            case "patient-import":
                navigate("patient-import/pending");
                break;
            default:
                navigate(`patients/${notification.item}/checkpoints`);
        }
    }
}

customElements.define("app-notifications-list", AppNotificationsList);
