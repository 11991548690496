import { html, render } from 'lit';

/**
 * A basic 'pill' badge/label.
 * @param {string} text label text
 * @param {boolean} is_alert if true, pill is the 'danger' color rather than primary
 */
export default class AppPill extends HTMLElement {
    set show_close(value) {
        this._show_close = value;
        this.render();
    }

    get show_close() {
        return this._show_close;
    }

    set show_edit(value) {
        this._show_edit = value;
        this.render();
    }

    get show_edit() {
        return this._show_edit;
    }

    set is_alert(value) {
        this._is_alert = value;
    }

    get is_alert() {
        return this._is_alert;
    }

    set color(value) {
        this._color = value;
        this.render();
    }

    get color() {
        if (this.is_alert)
            return "--t-color-danger";

        if (!this._color)
            return "--t-color-primary";

        return this._color;
    }

    set text(value) {
        this._text = value;
        this.render();
    }

    get text() {
        return this._text;
    }

    connectedCallback() {
        this.template = () => html`
        <span
            class="badge rounded-pill"
            style="
                background-color: var(${this.color});
                line-height: 14px;
                font-size: ${this.style.fontSize || "10px"};
                padding: ${this.style.padding || "4px 10px"};
                width: fit-content;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            ">
            <span>
            ${this.text}
            </span>
            ${this.show_edit ?
                html`
                <span
                    @click=${e => this.handleEditClick(e)}
                    class="material-symbols-outlined"
                    style='
                        margin-left: 10px;
                        font-size: ${this.style.fontSize || "10px"};
                        cursor: pointer;
                        margin-right: 2px;
                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                    '
                    >edit</span>
                    `
                : ''}
            ${this.show_close ?
                html`
                <span
                    @click=${e => this.handleCloseClick(e)}
                    class="material-symbols-outlined"
                    style='
                        cursor: pointer;
                        margin-right: 7px;
                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                    '
                    >close</span>
                    `
                : ''}
        </span>
        `;

        this.render();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    handleCloseClick(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.dispatchEvent(new CustomEvent("close", { composed: true, bubbles: true }));
    }

    handleEditClick(e) {
        this.dispatchEvent(new CustomEvent("edit", { composed: true, bubbles: true }));
    }
}

customElements.define("app-pill", AppPill);
