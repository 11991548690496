import { html, render } from "lit";
import ApexCharts from 'apexcharts';
import { fi } from "date-fns/locale";

/**
 * @param {object} metrics response from /dashboard_metrics
 * A widget with availability metrics for the current client.
 */
export default class AppDashboardCommunication extends HTMLElement {
    constructor() {
        super();
        this.chart = null; // Initialize chart instance variable
        this._metrics = null;
    }

    set metrics(value) {
        console.log('Received metrics:', value);
        this._metrics = value;
        this.requestUpdate();
    }

    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.render();
    }

    disconnectedCallback() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }

    requestUpdate() {
        if (!this.updateRequested) {
            this.updateRequested = true;
            Promise.resolve().then(() => {
                this.updateRequested = false;
                this.render();
            });
        }
    }

    render() {
        const template = html`
            <style>
                #dashboard-communication {
                    height: 100%; 
                    display: flex; 
                    flex-direction: column;
                    position: relative;
                }
                #communicationBarChart {
                        width: 100%;
                        height: 300px;
                        margin: 0 -10px 0 -10px;
                        position: absolute;
                        top: 27px;
                        left: 17px;
                        margin: 0 auto;
                                    }


            </style>
            <div id="dashboard-communication">
                <h6>Patient Communication Metrics</h6>
                <div id="communicationBarChart"></div>
            </div>
        `;
        Object.assign(this.style, {
            display: "block",
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            padding: "24px",
        });
        render(template, this);

        // Wait for the next frame to ensure the DOM is updated
        requestAnimationFrame(() => {
            const chartElement = this.querySelector("#communicationBarChart");
            if (chartElement && chartElement.offsetHeight > 0 && chartElement.offsetWidth > 0) {
                this.updateChart();
            } else {
                console.error('Chart element not ready:', chartElement);
            }
        });
    }

    updateChart() {
        console.log('Updating chart, metrics:', this.metrics);
        if (!this.metrics || !this.metrics.patient_dashboard_metrics) {
            console.error('No metrics data available');
            return;
        }

        const m = this.metrics.patient_dashboard_metrics;
        const chartElement = this.querySelector("#communicationBarChart");

        if (!chartElement) {
            console.error('Chart element not found');
            return;
        }

        // Define a helper function to safely get values
        const safeGet = (obj, key, defaultValue = 0) => obj && obj[key] !== undefined ? obj[key] : defaultValue;

        // Calculate totals for stacked SMS values
        const sentSMSTotal = safeGet(m, 'sent_sms_general') + safeGet(m, 'sent_sms_education');
        const scheduledSMSTotal = safeGet(m, 'scheduled_sms_general') + safeGet(m, 'scheduled_sms_education');

        const options = {
            chart: {
                type: 'bar',
                borderRadius: 5,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
                toolbar: {
                    show: false,
                },
                redrawOnParentResize: true,
                fontFamily: 'Quicksand',
                fontWeight: 600,
                fontSize: 12,
                dropShadow: {
                    enabled: true,
                    top: 8,
                    left: 5,
                    blur: 4,
                    opacity: 0.07
                },
                height: 340,
                width: '100%',
                stacked: true,
                offsetX: -20,
                offsetY: -22,
            },
            fill: {
                opacity: .9,
                //type: "gradient",
                //colors: ['#3e3f67',],
                //colors: ['#483369', '#cd5c73', '#f68666', '#904377', '#ffbc5d', '#f9f871'],
                //colors: ['#3E3F67', '#835180', '#C66582', '#F78874', '#FFBC64', '#F9F871'],
                //colors: ['#3E3F67', '#6e4287', '#7D6193', '#3E3F67', '#835180', '#C66582', '#F78874', '#FFBC64', '#F9F871', '#199afb', '#C483B9', '#E996C9'],
                //gradient: {
                //    shadeIntensity: .5,
                //    opacityFrom: 0.7,
                //    opacityTo: 0.9,
                //    stops: [0, 100]
                //}
            },
            series: [
                {
                    name: 'Sent SMS General',
                    color: '#5f81e2',
                    //color: '#78aab4',
                    borderRadius: 5,
                    data: [
                        0, // Phone Calls
                        0, // Received SMS
                        safeGet(m, 'sent_sms_general'), // Sent SMS General
                        0, // Scheduled SMS
                        0, // Self Check
                    ]
                },
                {
                    name: 'Sent SMS Education',
                    color: '#abe0eb',
                    borderRadius: 5,
                    data: [
                        0, // Phone Calls
                        0, // Received SMS
                        safeGet(m, 'sent_sms_education'), // Sent SMS Education
                        0, // Scheduled SMS
                        0, // Self Check
                    ]
                },
                {
                    name: 'Scheduled SMS General',
                    color: '#A072A7',
                    data: [
                        0, // Phone Calls
                        0, // Received SMS
                        0, // Sent SMS
                        safeGet(m, 'scheduled_sms_general'), // Scheduled SMS General
                        0, // Self Check
                    ]
                },
                {
                    name: 'Scheduled SMS Education',
                    color: '#c66582',
                    data: [
                        0, // Phone Calls
                        0, // Received SMS
                        0, // Sent SMS
                        safeGet(m, 'scheduled_sms_education'), // Scheduled SMS Education
                        0, // Self Check
                    ]
                },
                {
                    name: 'Phone Calls',
                    color: '#39cde8',
                    data: [
                        safeGet(m, 'phone_voice'),
                        0,  // Received SMS
                        0,  // Sent SMS
                        0,  // Scheduled SMS
                        0   // Self Check
                    ]
                },
                {
                    name: 'Received SMS',
                    color: '#744a8c',

                    data: [
                        0,  // Phone Calls
                        safeGet(m, 'received_sms'),
                        0,  // Sent SMS
                        0,  // Scheduled SMS
                        0   // Self Check
                    ]
                },
                {
                    name: 'Self Check',
                    color: '#ffbc64',
                    data: [
                        0,  // Phone Calls
                        0,  // Received SMS
                        0,  // Sent SMS
                        0,  // Scheduled SMS
                        safeGet(m, 'self_check_Self Check') + safeGet(m, 'self_check_General Self Check'),
                    ]
                }
            ],
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: '#000000',
                        fontSize: '12px',
                        fontWeight: 500,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
                axisBorder: {
                    show: false,
                },
            },
            xaxis: {
                type: 'category',
                categories: ['Phone Calls', 'Received SMS', 'Sent SMS', 'Scheduled SMS', 'Self Check'],
                labels: {
                    show: false,  // Hide the x-axis labels
                },
                axisTicks: {
                    show: false  // Hide x-axis tick marks
                },
                axisBorder: {
                    show: false  // Hide the x-axis border
                },
                crosshairs: {
                    show: false  // Hide crosshairs
                },
            },
            plotOptions: {
                bar: {
                    columnHeight: 20,
                    horizontal: true,
                    columnWidth: '100%',
                    barHeight: '35%',
                    stacked: true,  // Enable stacked bars here
                    borderRadius: 3,
                    fill: {
                        opacity: .5
                    },
                    borderRadiusApplication: 'end',
                    borderRadiusWhenStacked: 'last',
                    //distributed: true,
                    dataLabels: {
                        enabled: false,  // Disable individual data labels inside the bars
                        total: {
                            enabled: true,
                            formatter: function (val, opts) {
                                const { series } = opts.w.globals;
                                const total = series.reduce((acc, cur) => acc + cur[opts.dataPointIndex], 0);
                                return total;  // Return total for each row
                            },
                            offsetX: 10,  // Offset to display total at the end of the row
                            style: {
                                fontSize: '12px',
                                fontWeight: 600
                            }
                        }
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val, opts) {
                        return val;
                    }
                }
            },
            grid: {
                show: false  // Hide grid lines
            },
            legend: {
                show: false  // Hide the legend entirely
            }
        };

        console.log('Chart options:', options);

        try {
            if (this.chart) {
                console.log('Updating existing chart');
                this.chart.updateOptions(options);
            } else {
                console.log('Creating new chart');
                this.chart = new ApexCharts(chartElement, options);
                this.chart.render();
            }
        } catch (error) {
            console.error('Error creating/updating chart:', error);
        }
    }


}

customElements.define("app-dashboard-communication", AppDashboardCommunication);