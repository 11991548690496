import { html, render } from 'lit';
import directus from "../../lib/lib-directus";
import cache from "../../lib/lib-cache";
import SurveyDefinition from "../../lib/lib-survey-definition";
import "./app-patient-note";
import { Collapse } from 'bootstrap';

class AppPatientNotes extends HTMLElement {
    set patient_id(value) {
        if (!value)
            return;
        this._patient_id = value;
        if (!(this.patient?.id == value)) this.init();
    }

    get patient_id() {
        return this._patient_id;
    }
    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    set notes(value) {
        this._notes = value;
        this.render();
    }

    get notes() {
        return this._notes;
    }

    set alerts(value) {
        this._alerts = value;
        this.render();
    }

    get alerts() {
        return this._alerts;
    }

    constructor() {
        super();
        this.notes_by_checkpoint = {};
        this.patient_profile_notes = [];
    }

    connectedCallback() {
        this.template = () => html`
            <style>
                .note-header {
                    background-color: #4e4f7f;
                    color: white;
                    padding: 5px 10px;
                    font-size: 12px;
                    font-weight: 700;
                    display: block;
                    border-radius: 3px;
                    margin: 0 0 0 0px;
                }
            </style>
            <div class="staff-task-modal-inner" style="max-height: 550px; overflow-y: auto;">
                <!-- new note -->
                <div style="margin: 25px 0 5px 0; display: flex; flex-direction: row; align-items: center">
                    <h3
                        style="
                        color: #201B3A; 
                        text-decoration: none; 
                        font-size: 14px; 
                        margin: 0px;
                        font-weight: 700;">
                        Notes
                    </h3>
                    <span
                        @click=${(_e) => this.handleAddNote()}
                        class="material-symbols-outlined"
                        style='
                        margin-left: 10px;
                        cursor: pointer;
                        font-size: 18px;
                        color: var(--t-color-primary);
                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                    '
                        >add_circle</span
                    >
                </div>
                <div class="collapse hide" id="add_note">
                    <app-patient-note
                        id="new_note"
                        @save=${(e) => this.handleSaveNewNote(e.detail)}
                        @cancel=${(_e) => this.handleCancelAddNote()}
                        .editing=${true}
                        .note=${{
                client_id: this.patient.client_id,
                patient_id: this.patient?.id
            }}
                        style="display: block; width: 100%;"></app-patient-note>
                </div>

                <!-- notes list -->
                <div style="margin-top: 15px;">
                    <div class="note-header">
                        PATIENT NOTES
                    </div>
                ${this.patient_profile_notes.length === 0 ? html`
                    
                    <span style="margin: 0 auto;
                        margin: 2px 0 5px 0;
                        text-align: center;
                        display: block;
                        color: #ccc;
                    ">No notes yet</span>` : ""}

                    ${this.patient_profile_notes.map(note => html`
                        <app-patient-note
                            .note=${note}
                            @delete=${(_e) => this.handleDeleteNote(note)}
                            
                        ></app-patient-note>
                    `)}
                    ${Object.values(this.notes_by_checkpoint).map(notes_data => html`
                        <div class="note-header">
                            CHECKPONT ${new Date(notes_data.checkpoint.survey_completion_date).toLocaleDateString()}
                        </div>
                        ${Object.values(notes_data.manual_alert_notes).map(alert_notes => html`
                            <div class="p-notes-22" style="display: flex; align-items: center">
                             <span
                                    class="material-symbols-outlined 1"
                                    style='
                                        margin-left: 10px;
                                        color: #ca463d;
                                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                    '
                                >
                                    report
                                </span>
                                <span style="font-weight: 600; color: #606060;">
                                    ${alert_notes[0].alert.subject}
                                </span>
                               
                            </div>
                            ${alert_notes.map(note => html`
                                <app-patient-note
                                    .note=${note}
                                    @delete=${(_e) => this.handleDeleteNote(note)}
                                    
                                ></app-patient-note>
                            `)}
                        `)}    
                        ${Object.values(notes_data.question_notes).map(question_notes => html`
                            <div class="p-notes-1" style="display: flex; align-items: center">
                               
                                ${!!question_notes.find(note => !!note.alert)
                    ? html`
                                        <span
                                            class="material-symbols-outlined 2"
                                            style='
                                                color: #ca463d;
                                                font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                            '
                                        >
                                            report
                                        </span>
                                    `
                    : ""}

                                <span class="staff-dash-cp-notes" style="font-weight: 600; color: #606060;">
                                    ${this.survey_definition.getFieldLabel(question_notes[0].question)}
                                </span>
                                
                            </div>
                            ${question_notes.map(note => html`
                                <app-patient-note
                                    .note=${note}
                                    @delete=${(_e) => this.handleDeleteNote(note)}
                                    
                                ></app-patient-note>
                            `)}
                        `)} 
                    `)}
                </div>
            </div>
        `
    }

    render() {
        if (!this.template)
            return;
        if (!this.patient)
            return;
        if (!this.notes)
            return;
        if (!this.alerts)
            return;

        render(this.template(), this);
    }

    async init() {
        if (this._loading) return;
        //vaadin router will set the patient_id multiple times, so add a guard to prevent redundant loads
        this._loading = true;
        this.survey_definition = await SurveyDefinition.getSurveyDefinition();
        this.patient = await cache.getPatient(this.patient_id);
        await this.loadAlerts();
        await this.loadNotes();
        this._loading = false;
        if (!this._patient_header_height) {
            this._patient_header_height = this.querySelector('app-patient-header')?.clientHeight;
        }
        this.render();
    }

    async handleAddNote() {
        let collapser = Collapse.getOrCreateInstance("#add_note");
        let new_note = this.querySelector("#new_note");
        new_note.editing = true;
        new_note.note = {
            client_id: this.patient.client_id,
            patient_id: this.patient_id,
            content: " "
        };
        collapser.show();
    }

    async handleDeleteNote(_note) {
        await this.loadNotes();
        this.render();
    }

    async handleSaveNewNote(_content) {
        //clear out the new note
        let new_note = this.querySelector("#new_note");
        new_note.editing = true;

        let collapser = Collapse.getOrCreateInstance("#add_note");
        collapser.hide();
        await this.loadNotes();
        this.render();
    }

    async handleCancelAddNote() {
        let collapser = Collapse.getOrCreateInstance("#add_note");
        collapser.hide();
    }

    async loadAlerts() {
        let result = await directus.items("alert").readByQuery({
            filter: {
                patient_id: this.patient_id,
            },
            fields: ["id", "level", "survey_id", "question", "subject"],
        });
        this.alerts = result.data;
    }

    async loadNotes() {
        let result = await directus.items("note").readByQuery({
            fields: [
                "*",
                "user_created.first_name",
                "user_created.last_name",
                "survey_id.id",
                "survey_id.survey_completion_date",
            ],
            filter: {
                patient_id: { _eq: this.patient_id },
            },
            sort: ["-date_created", "survey_id", "question"],
        });
        this.notes = result.data || [];

        this.notes_by_checkpoint = {}
        this.patient_profile_notes = [];

        for (let note of this.notes) {
            if (!note.survey_id) {
                this.patient_profile_notes.push(note);
                continue;
            }

            //cherry pick matching alerts for the notes, if any
            let alert = this.alerts.find((alert) => {
                return (
                    alert.survey_id == note.survey_id.id && alert.question == note.question
                ) || (
                        alert.id === note.alert_id
                    );
            });
            if (alert) note.alert = alert;

            // organize notes by checkpoint and type
            if (!this.notes_by_checkpoint[note.survey_id.id]) {
                this.notes_by_checkpoint[note.survey_id.id] = {
                    patient_profile_notes: [],
                    question_notes: {},
                    manual_alert_notes: {},
                    checkpoint: note.survey_id,
                };
            }
            const checkpoint_notes = this.notes_by_checkpoint[note.survey_id.id]
            if (note.question) {
                if (!checkpoint_notes.question_notes[note.question]) {
                    checkpoint_notes.question_notes[note.question] = [];
                }
                checkpoint_notes.question_notes[note.question].push(note);
            }
            else if (note.alert) {
                if (!checkpoint_notes.manual_alert_notes[note.alert.id]) {
                    checkpoint_notes.manual_alert_notes[note.alert.id] = [];
                }
                checkpoint_notes.manual_alert_notes[note.alert.id].push(note);
            }
        }
    }
}

customElements.define('app-patient-notes', AppPatientNotes);
export default AppPatientNotes;
