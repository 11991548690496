import directus from "./lib-directus";
import ApplicationState from "applicationstate";

/**
 * Initialize the current client in storage.
 * Should typically be called upon a login or app refresh.
 */
 export async function initCurrentClient() {
    if (currentUserIsAdmin()) {
        // admins do not have user.client_access, as they can view all clients implicitly
        const all_clients = await directus.items("client").readByQuery({
            fields: ["id", "name", "status"],
            sort: "name",
        });
        ApplicationState.set("app.client_access", all_clients.data);
    }
    else {
        const current_user = ApplicationState.get("app.user");
        const mapped_clients = current_user.client_access.map(client_access_item => client_access_item.client_id);
        ApplicationState.set("app.client_access", mapped_clients);
    }

    const client_options = getAvailableClients()
    const current_client_id = getCurrentClientId()

    if (!current_client_id && client_options.length) {
        // Default the current client to the first in the list
        await setCurrentClient(client_options[0])
    }
}

/**
 * Persistently sets current client in ApplicationState
 * @param {object} client one of user.client_access.client_id
 */
export async function setCurrentClient(client) {
    await ApplicationState.set("app.user_current_client", client, {immutable: true})
}

/**
 * Gets the current client from storage
 * @returns {object} one of user.client_access.client_id
 */
export function getCurrentClient() {
    return ApplicationState.get("app.user_current_client");
}

/**
 * Gets the current client id from storage
 * @returns {string}
 */
export function getCurrentClientId() {
    return getCurrentClient()?.id
}

/**
 * Get the list of clients the current user has access to.
 * @returns {Array} client_id objects from user.client_access
 */
export function getAvailableClients() {
    const available_clients = ApplicationState.get("app.client_access");
    return available_clients;
}

/**
 * Get the current user from local indexdb storage
 * @returns {object} The current user object
 */
export function getCurrentUser() {
    const current_user = ApplicationState.get("app.user");
    return current_user;
}

/**
 * Get the current user id
 * @returns {string} The current user id
 */
export function getCurrentUserId() {
    return getCurrentUser()?.id;
}

/**
 * @todo: does this need to handle null? 
 * Adds a client parameter to given directus items filter, based on the user's client access.
 * @param {Filter} filter_object directus items filter
 * @param {boolean} use_all_available_clients if true an _in clause will be used with all the user's clients.
 * @returns {Filter} filter with client field (unless admin)
 */
export function adddirectusClientFilter(filter_object, use_all_available_clients = false) {
    const available_client_ids = getAvailableClients().map(client => client.id)
    const current_client_id = getCurrentClientId();

    const client_filter = use_all_available_clients ? {_in: available_client_ids} : current_client_id
    
    return {
        ...filter_object,
        client_id: client_filter
    }
}

export function currentUserIsAdmin() {
    const current_user = ApplicationState.get("app.user");
    return current_user.role.name === "Administrator";
}

export default {
    initCurrentClient,
    setCurrentClient,
    getCurrentClient,
    getCurrentClientId,
    getAvailableClients,
    adddirectusClientFilter,
}
