import { html, render } from "lit";
import "../scorecard/component-patient-checkpoints";
import "../components/patient/app-patient-header";
import cache from "../lib/lib-cache";
import { navigate } from "../lib/lib-router";

class ScenePatientCheckpoints extends HTMLElement {
    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
        this.boundResizeHandler = this.handleWindowResize.bind(this);
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        if (value || !(this.patient?.id == value)) this.loadPatient();
    }

    get patient_id() {
        return this._patient_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    connectedCallback() {
        this.template = () => html`
            <app-patient-header .page=${"trends"} .patient=${this.patient}></app-patient-header>
            <div style="height: calc(100% - 32px - ${this._patient_header_height}px); overflow-y: auto; padding-top: 16px;">
                <app-patient-checkpoints
                    style="margin: 0 32px 16px 32px;"
                    .patient_id=${this.patient_id}
                    @viewdetailsclick=${this.handleViewDetailsClick}
                ></app-patient-checkpoints>
            </div>
        `;

        this.style.display = "block";
        this.style.height = "100%";
        this.style.overflowY = "auto";
        this.render();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;

        render(this.template(), this);

        if (!this._patient_header_height) {
            this._patient_header_height = this.querySelector('app-patient-header')?.clientHeight;
            this._patient_header_height && this.render();
        }
    }

    /**
     * Navigate to <scene-patient-checkpoint-details> upon clicking checkpoint 'view details' button
     * @param {CustomEvent} e ComponentPatientCheckpoints#viewdetailsclick
     */
    handleViewDetailsClick(e) {
        navigate(`patients/${this.patient_id}/checkpoints/${e.detail.survey_id}`);
    }

    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = document.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

    async loadPatient() {
        this.patient = await cache.getPatient(this.patient_id);
        this.render();
    }
}

customElements.define("scene-patient-checkpoints", ScenePatientCheckpoints);
export default ScenePatientCheckpoints;
