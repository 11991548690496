import { format, parse, parseISO } from "date-fns";
import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import { convertUtcToLocal, formatToLocalTime, convertUtcToLocalTime } from "../../lib/lib-date";
import DataDefinition from "../../lib/lib-data-definition";
import directus from "../../lib/lib-directus";

export default class AppPatientActivityModal extends ModalBase {

    constructor(source_record, type, readonly = true) {
        super();

        this._source_record = source_record;
        this._type = type;
        this.extraTitle = "";
        this.readonly = readonly;
        this.spinner = "";
        //this.confirm_delete = true;
    }

    set source_record(value) {
        if (!value)
            return;
        this._source_record = value;

    }

    get source_record() {
        return this._source_record;
    }

    set type(value) {
        if (!value)
            return;

        this._type = value;
    }

    get type() {
        return this._type;
    }

    connectedCallback() {
        this.template = () => html`
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down cal-modal-for-all">
                <div class="modal-content ${this.type}">
                    <div class="modal-header">
                        <h5 class="modal-title">${this.makeModalTitle()}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ${this.openTemplate()}
                        ${this.staticTemplate()}
                    </div>
                </div>
            </div>
        `;

        this.render();
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }

    openTemplate() {

        //console.log("source record", this.source_record);
        //console.log("TYPE", this.type);
        let full_date_string = '';
        if (this.type == "Checkpoints") {
            full_date_string = format(convertUtcToLocal(this.source_record.date_to_use), "MM/dd/y 'at' h:mm aaa");
        } else if (this.type == "Visits") {
            full_date_string = format(convertUtcToLocal(this.source_record.visit_date), "MM/dd/y");
        } else if (this.type == "Status History") {
            full_date_string = format(convertUtcToLocal(this.source_record?.date_changed), "MM/dd/y 'at' h:mm aaa");
        } else {
            full_date_string = format(convertUtcToLocal(this.source_record?.date_created), "MM/dd/y 'at' h:mm aaa");
        }
        if (this.type == "Agency Call") {
            return html`
                <div style="font-size: 12px; margin-bottom: 2px;">
                    From
                    <span style="font-weight: 700; margin: 0 2px;">
                        ${this.source_record.user_created?.first_name + " " + this.source_record.user_created?.last_name}
                    </span>
                    on
                    <span title=${full_date_string}>
                        ${format(convertUtcToLocalTime(this.source_record.date_created), "MM/dd/y 'at' h:mm aaa")}
                    </span>
                </div>
                <div>
                    Note: ${this.source_record.notes != null ? this.source_record.notes : ""}
                </div>
                <div>
                    Status Reason: ${this.source_record.status_reason != null ? this.source_record.status_reason : ""}
                </div>
            `;
        } else if (this.type == "Care Team Call") {
            return html`
                <div style="font-size: 12px; margin-bottom: 2px;">
                    From
                    <span style="font-weight: 700; margin: 0 2px;">
                        ${this.source_record.user_created?.first_name} ${this.source_record.user_created?.last_name}
                    </span>
                    on
                    <span title=${full_date_string}>
                        ${convertUtcToLocalTime(this.source_record.date_created).toLocaleDateString()}
                    </span>
                </div>
                <div style="display:none">
                    Duration: ${this.source_record.call_duration > 90 ? (Math.round(this.source_record.call_duration / 60) + " minutes") : ((this.source_record.call_duration || 0) + " seconds")}
                </div>
                <div>
                 <strong>Status Reason:</strong> ${this.source_record.status_reason != null ? this.source_record.status_reason.replace(/_/g, ' ') : ""}
  
                </div>
                <div>
                   <strong>Note:</strong> ${this.source_record.notes != null ? this.source_record.notes : ""}
                </div>
            `;
            //} else if (this.type == "Checkpoints" && this.source_record?.task_type == "self") {
            //    return html`
            //        <div style="font-size: 14px; margin-bottom: 2px;">
            //            <span style="font-weight: 700; margin-right: 2px;">
            //                Self Check
            //            </span>
            //            at
            //            <span title=${full_date_string}>
            //                ${convertUtcToLocalTime(this.source_record.date_to_use).toLocaleDateString()}
            //            </span>
            //        </div>
            //        Note: ${this.source_record.description}
            //    `;
            //} else if (this.type == "SMS") {
            //    return html`
            //        <div style="margin-bottom: 5px;">
            //            From
            //            <span style="font-weight: 700; margin: 0 2px;">
            //                ${this.source_record.user_created?.first_name} ${this.source_record.user_created?.last_name}
            //            </span>
            //            on
            //            <span title=${full_date_string}>
            //                ${convertUtcToLocalTime(this.source_record.scheduled_date).toLocaleDateString()}
            //            </span>
            //        </div>
            //        <div>
            //            <strong>Content</strong>
            //            <div>${this.source_record.sms_message}</div>
            //        </div>
            //    `;
        } else if (this.type == "Checkpoints") {
            return html`
    <div style="font-size: 14px; margin-bottom: 10px;">
    
    ${this.source_record.status_reason ? html`

        <div class="status_reason" style="margin-bottom:10px;">
        <span style="font-weight: 700; margin-right: 2px;">Status Reason: </span>

        ${this.source_record.status_reason.replace(/_/g, ' ')}
      </div>` : ''
                }
    <span style="font-weight: 700; margin-right: 2px;">
            Date: 
        </span>
        ${full_date_string}
    </div>
    <div style="font-size: 14px; margin-bottom: 10px;">
        ${this.source_record.alerts ? html`<div style="font-weight: 700; font-size:14px; margin-bottom: 10px;">Alerts:</div>` : ''}
       ${this.source_record.alerts?.map(alert => html`
    <div>
        ${alert.subject ? html`
            <div class="modal-alert-subject">
                ${alert.subject}
            </div>
        ` : ''}

        ${alert.notes && alert.notes.length > 0 ? html`
            <div class="modal-alert-note" style="padding-left: 17px;">
                ${alert.notes.map(note => html`<div>${note.content}</div>`)}
            </div>
            
        ` : ''}
        
    </div>`
                )}
        ${this.source_record.description ? html`
            <span class="visit-activity-content" style="margin: 20px 0 -5px 0; display: block;" data-bs-dismiss="modal">
                <strong>Message: </strong><br>
                ${this.source_record.description}
            </span>
        ` : ''}
        ${this.source_record.status === 'complete' ? html`
            <span class="visit-activity-content" style="margin: 20px 0 -5px 0; display: block; text-align: right;" data-bs-dismiss="modal">
                <a href=${`patients/${this.source_record.patient_id}/checkpoints/${this.source_record.survey_id}`}>View Completed Checkpoint Detail</a>
            </span>
        ` : ''}
    </div>
`;
        } else if (this.type == "Visits") {
            return html`
                <div style="font-size: 14px; margin-bottom: 2px;">
                    <div>
                        <span style="font-weight: 700; margin-right: 2px;">
                            Visit Date: 
                        </span>
                        <span>
                            ${full_date_string}
                        </span>
                    </div>
                    <div>
                        <span style="font-weight: 700; margin-right: 2px;">
                            Staff Member: 
                        </span>
                        <span>
                            ${this.source_record.careteam_member_id?.first_name} ${this.source_record.careteam_member_id?.last_name}
                        </span>
                    </div>
                    <div>
                        <span style="font-weight: 700; margin-right: 2px;">
                           Visit Status:   
                        </span>
                        <span>
                           ${this.source_record.visit_status} 
                        </span>
                    </div>
                </div>
            `;
        } else if (this.type == "Status History") {
            const reason = this.source_record.reason
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
            return html`
            <div style="margin-bottom: 2px;">
                <span style="font-weight: 700; margin-right: 2px;">
                    Status changed from ${this.source_record.old_status} to ${this.source_record.new_status}
                </span>
                on
                <span title=${full_date_string}>
                    ${convertUtcToLocalTime(this.source_record.date_changed).toLocaleDateString()}
                </span>
            </div>
            <div><b>Reason: </b>${reason}</div>
            <div><b>Notes: </b>${this.source_record.notes}</div>
        `;

        } else if (this.type == "Note") {
            return html`
                <div style="font-size: 12px; margin-bottom: 2px;">
                    Added by
                    <span class="added-by" style="font-weight: 700; margin: 0 2px;">
                        ${this.source_record.user_created?.first_name} ${this.source_record.user_created?.last_name}
                    </span>
                    on
                    <span class="added-by" title=${full_date_string}>
                        ${convertUtcToLocalTime(this.source_record.date_created).toLocaleDateString()}
                    </span>checkpoint
                </div>
                <app-patient-note-text .content=${this.source_record.content}></app-patient-note-text>
            `
        };
    }
    staticTemplate() {

        console.log('static templatre', this.confirm_delete);
        console.log('source recordzzz', this.source_record)

        const isCurrentTask = window.location.href.includes(this.source_record.id); // check to see if the current task is the same as the one being viewed

        const buttons_row = !this.confirm_delete ? html`
            <div class="row cal-event-delete-cont">
              <div class="delete-btn-row">
                    <a href="javascript:void(0)"
                    class="btn btn-danger ${isCurrentTask ? 'disabled' : ''}"
                    @click=${(e) => this.confirmArchive(e, true)}>Delete</a>
                

                 ${isCurrentTask ? html`
                        <span style="" class="material-symbols-outlined">info</span>
                        <span>You cannot delete a task you are currently working on.</span>
                    ` : ''}

                    </div> 

                <div class="text-end">
                     
       <!--     <a href="javascript:void(0);" class="btn btn-primary edit-btn" @click=${(e) => {
                e.stopPropagation();
                this.editing = !this.editing;
                this.render();
            }}>Edit</a> -->
            
                   <!-- <a href="javascript:void(0)" class="btn btn-danger" @click=${() => this.dismiss(false)}>Close</a> -->
                </div>
            </div>
        `: html`
                <div class="row cal-event-delete-conf-cont">
                    <div class="col text-end">
                        <strong>Are you sure you want to delete?</strong>
                    <!--    <a href="javascript:void(0)"
                            class="btn btn-danger"
                            @click=${(e) => this.delete(e)}>Delete</a>   -->

                            <button @click="${(e) => this.delete(e)}" class="btn btn-primary btn-danger" type="button">
                             <span class="spinner-grow spinner-grow-sm d-none" role="status" aria-hidden="true"></span>
                             <span class="btn-text">Delete</span>
                            </button>



                        <a href="javascript:void(0)"
                            class="btn btn-secondary"
                            @click=${(e) => this.confirmArchive(e, false)}>Cancel</a>
                    </div>
                </div>
            `
        return html`
            <div class="cal-modal-actions-placeholder">
                ${this.readonly ? null : buttons_row}
            </div>
        `
    }
    makeModalTitle() {
        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        if (this.type == "Checkpoints" && this.source_record?.task_type == "self")
            return html`Self Check - ${this.source_record.form_design.title}`;
        if (this.type == "Visits")
            return html`${this.source_record.visit_description}${this.extraTitle}`;
        if (this.type == "Checkpoints")
            return html`${this.source_record.title} - ${capitalizeFirstLetter(this.source_record.status)?.replace(/_/g, ' ') ?? 'No reason provided'}`;
        //if (this.type == "SMS") {
        //    const types = {
        //        "general": "General",
        //        "education": "Education",
        //        "clinician": "Clinician",
        //    };
        //    const type = types[this.source_record.notes];
        //    const title = type ? "- " + type : "";
        //    return html`SMS ${title}`;
        //}

        return html`${this.type}${this.extraTitle}`;
    }


    //////////////// DELETIONS HAPPEN HERE ////////////////////
    async delete(e) {

        let buttonText;
        try {
            e.stopPropagation();
            this.disabled = true;
            this.render();

            const button = e.currentTarget;
            this.spinner = button.querySelector('.spinner-grow');
            buttonText = button.querySelector('.btn-text');

            this.spinner.classList.remove('d-none');
            buttonText.textContent = 'Deleting...';

            // Perform deletion based on the task type
            if (this.source_record.task_type === "survey" || this.source_record.task_type === "self") {
                const surveyRecord = await directus.items("task").readOne(this.source_record.id, {
                    fields: ["survey_id"]
                });

                const survey_id = surveyRecord.survey_id;
                console.log('survey___id', survey_id);

                if (survey_id) {
                    await directus.items("hh_patient_reported_measures").deleteOne(survey_id);
                }
                await directus.items("task").deleteOne(this.source_record.id);
            }

            // Dismiss the modal or any UI components
            //await this.dismiss(true);

        } catch (err) {
            console.error("Error deleting record:", err); // Log the error
            this.errors = this.errors || {};
            this.errors.global = "Error deleting this record. If the problem persists, please contact support.";
            this.disabled = false;
            this.render();
        } finally {
            if (this.spinner) {
                this.spinner.classList.add('d-none');
            }
            if (buttonText) {
                buttonText.textContent = 'Delete';
            }

            console.log('Dispatching refresh-calendar event');
            this.dismiss({ confirmed: true });
        }
    }


    confirmArchive(e, confirm_delete) {
        console.log('DELEEEETE', e);
        e.stopPropagation();
        console.log('confirm delete',)
        this.confirm_delete = confirm_delete;
        this.render();
    }
    async loadDefinition() {
        this.patient_communication_definition = await DataDefinition.getDefinition("patient_communication");
        this.render();
    }
}

customElements.define("app-patient-activity-modal", AppPatientActivityModal);