export default {
    getAvoidableEventsMetrics
}

/**
 * Calculate all metrics for reuse across the avoidable events impact dashboard.
 * @param {object} client_kpis server data/response with string values
 * @param {object} financial_model server data/response with string values
 * @returns {AvoidableEventsMetrics} flat object with all needed metrics
 */
export function getAvoidableEventsMetrics(client_kpis = {}, financial_model = {}) {
    const skipped_keys = new Set([
        "id", 
        "client_id",
        "interval",
        "year",
        "month",
        "model_name",
        "user_created",
        "user_updated",
        "date_created",
        "date_updated",
        "avg_reimbursement_ppc",
        "avg_cost_ppc",
    ]);

    // Get stored/column metrics, converting string values to numbers and removing non-metric keys.
    const parsed_model = {};
    const parsed_kpis = {};

    Object.entries(financial_model).forEach(([key, val]) => {
        if (!skipped_keys.has(key)) parsed_model[key] = Number(val);
    });
    Object.entries(client_kpis).forEach(([key, val]) => {
        if (!skipped_keys.has(key)) parsed_kpis[key] = Number(val);
    });

    const {
        scheduling_time,
        travel_time,
        point_of_care_time,
        documentation_time,
        qa_time,
        care_coordination_time,
        missed_confirmation_call,
        missed_travel_time,
        missed_emr_visit_time,
        missed_adjust_schedule,
        missed_qa_review_time,
        missed_notify_md_time,
        readmit_documentation_time,
        readmit_documentation_qa_time,
        readmit_notify_md_time,
        readmit_roc_travel_time,
        readmit_roc_visit_time,
        readmit_roc_oasis_plan_time,
        readmit_roc_qa_time,
        readmit_update_md_time,
        readmit_find_signed_md_order_time,
        estimated_excessive_visit_percentage,
        percent_missed_visits_with_travel,
    } = parsed_model;

    const {
        total_active_patient_census,
        total_billable_visits_rendered,
        total_missed_visits,
        total_transfers,
        total_submitted_claims,
        total_estimated_cost,
    } = parsed_kpis;

    // base metrics
    const avg_time_spent_per_visit = scheduling_time + travel_time + point_of_care_time + documentation_time + qa_time + care_coordination_time;
    const avg_time_missed_visit_without_travel = missed_confirmation_call + missed_emr_visit_time + missed_adjust_schedule + missed_qa_review_time + missed_notify_md_time;
    const avg_time_missed_visit_with_travel = missed_confirmation_call + missed_travel_time + missed_emr_visit_time + missed_adjust_schedule + missed_qa_review_time + missed_notify_md_time;
    const avg_time_spent_per_hospital_readmission = readmit_documentation_time + readmit_documentation_qa_time + readmit_notify_md_time + readmit_roc_travel_time + readmit_roc_visit_time + readmit_roc_oasis_plan_time + readmit_roc_qa_time + readmit_update_md_time + readmit_find_signed_md_order_time;
    const percent_missed_visits_no_travel = 100 - percent_missed_visits_with_travel;
    const missed_visits_with_travel = total_missed_visits * (percent_missed_visits_with_travel / 100);
    const missed_visits_without_travel = total_missed_visits * (percent_missed_visits_no_travel / 100);

    // event metrics
    const avg_visits_per_patient = total_billable_visits_rendered / total_active_patient_census;
    const avg_cost_ppc = total_estimated_cost ? (total_estimated_cost / total_active_patient_census) : Number(financial_model.avg_cost_ppc || 0);
    const avg_reimbursement_ppc = total_submitted_claims ? (total_submitted_claims / total_active_patient_census) : Number(financial_model.avg_reimbursement_ppc || 0);
    const avg_cost_per_visit =   avg_cost_ppc / avg_visits_per_patient;
    const avg_reimbusement_per_visit = avg_reimbursement_ppc / avg_visits_per_patient;
    // round to nearest cent
    const avg_cost_per_minute = parseFloat((avg_cost_per_visit / avg_time_spent_per_visit).toFixed(2));
    const avg_reimbusement_per_minute = parseFloat((avg_reimbusement_per_visit / avg_time_spent_per_visit).toFixed(2));
    const excessive_visits_utilized = total_active_patient_census * (estimated_excessive_visit_percentage / 100);

    // missed visits with/without travel
    const minutes_lost_staff_time_with_travel = missed_visits_with_travel * avg_time_missed_visit_with_travel;
    const minutes_lost_staff_time_without_travel = missed_visits_without_travel * avg_time_missed_visit_without_travel;
    const direct_cost_with_travel = minutes_lost_staff_time_with_travel * avg_cost_per_minute;
    const direct_cost_without_travel = minutes_lost_staff_time_without_travel * avg_cost_per_minute;
    const lost_time_in_visits_with_travel = minutes_lost_staff_time_with_travel / avg_time_spent_per_visit;
    const lost_time_in_visits_without_travel = minutes_lost_staff_time_without_travel / avg_time_spent_per_visit;
    const revenue_lost_with_travel = lost_time_in_visits_with_travel * avg_reimbusement_per_visit;
    const revenue_lost_without_travel = lost_time_in_visits_without_travel * avg_reimbusement_per_visit;

    // readmissions
    const readmissions_lost_time = total_transfers * avg_time_spent_per_hospital_readmission;
    const readmissions_direct_cost = readmissions_lost_time * avg_cost_per_minute;
    const readmissions_time_in_visits = readmissions_lost_time / avg_time_spent_per_visit;
    const readmissions_revenue_lost = readmissions_time_in_visits * avg_reimbusement_per_visit;

    // excessive visit utililzation
    const excessive_visits_count = total_active_patient_census * (estimated_excessive_visit_percentage / 100);
    const excessive_visits_lost_staff_time = excessive_visits_count * avg_time_spent_per_visit;
    const excessive_visits_direct_cost = excessive_visits_lost_staff_time * avg_cost_per_minute;
    const excessive_visits_lost_time_in_visits = excessive_visits_lost_staff_time / avg_time_spent_per_visit;
    const excessive_visits_revenue_lost = excessive_visits_lost_time_in_visits * avg_reimbusement_per_visit;

    // visit conversion lost
    const total_lost_staff_time = minutes_lost_staff_time_with_travel + minutes_lost_staff_time_without_travel+ readmissions_lost_time + excessive_visits_lost_staff_time;
    const visit_conversion_lost_staff_time = total_lost_staff_time / avg_time_spent_per_visit;
    const visits_new_patient_conversion = visit_conversion_lost_staff_time / avg_visits_per_patient;

    // direct cost / opportunity lost
    const direct_costs_lost_time = total_lost_staff_time * avg_cost_per_minute;
    const revenue_opportunity_lost = visit_conversion_lost_staff_time * avg_reimbusement_per_visit;
    const total_lost_revenue = direct_costs_lost_time + revenue_opportunity_lost;

    return {
        ...parsed_model,
        ...parsed_kpis,
        avg_time_spent_per_visit,
        avg_time_missed_visit_without_travel,
        avg_time_missed_visit_with_travel,
        avg_time_spent_per_hospital_readmission,
        percent_missed_visits_no_travel,
        missed_visits_with_travel,
        missed_visits_without_travel,
        avg_cost_per_visit,
        avg_reimbusement_per_visit,
        avg_cost_per_minute,
        avg_reimbusement_per_minute,
        avg_visits_per_patient,
        avg_cost_ppc,
        avg_reimbursement_ppc,
        excessive_visits_utilized,
        minutes_lost_staff_time_with_travel,
        minutes_lost_staff_time_without_travel,
        direct_cost_with_travel,
        direct_cost_without_travel,
        lost_time_in_visits_with_travel,
        lost_time_in_visits_without_travel,
        revenue_lost_with_travel,
        revenue_lost_without_travel,
        readmissions_lost_time,
        readmissions_direct_cost,
        readmissions_time_in_visits,
        readmissions_revenue_lost,
        excessive_visits_count,
        excessive_visits_lost_staff_time,
        excessive_visits_direct_cost,
        excessive_visits_lost_time_in_visits,
        excessive_visits_revenue_lost,
        total_lost_staff_time,
        visit_conversion_lost_staff_time,
        visits_new_patient_conversion,
        direct_costs_lost_time,
        revenue_opportunity_lost,
        total_lost_revenue,
    }
}

/**
 * @typedef {Object} AvoidableEventsMetrics
 * @property {number} scheduling_time
 * @property {number} travel_time
 * @property {number} point_of_care_time
 * @property {number} documentation_time
 * @property {number} qa_time
 * @property {number} care_coordination_time
 * @property {number} missed_confirmation_call
 * @property {number} missed_travel_time
 * @property {number} missed_emr_visit_time
 * @property {number} missed_adjust_schedule
 * @property {number} missed_qa_review_time
 * @property {number} missed_notify_md_time
 * @property {number} readmit_documentation_time
 * @property {number} readmit_documentation_qa_time
 * @property {number} readmit_notify_md_time
 * @property {number} readmit_roc_travel_time
 * @property {number} readmit_roc_visit_time
 * @property {number} readmit_roc_oasis_plan_time
 * @property {number} readmit_roc_qa_time
 * @property {number} readmit_update_md_time
 * @property {number} readmit_find_signed_md_order_time
 * @property {number} avg_reimbursement_ppc
 * @property {number} avg_cost_ppc
 * @property {number} estimated_excessive_visit_percentage
 * @property {number} percent_missed_visits_with_travel
 * @property {number} total_active_patient_census
 * @property {number} total_billable_visits_rendered
 * @property {number} total_missed_visits
 * @property {number} total_transfers
 * @property {number} total_resumptions
 * @property {number} avg_time_spent_per_visit
 * @property {number} avg_time_missed_visit_without_travel
 * @property {number} avg_time_missed_visit_with_travel
 * @property {number} avg_time_spent_per_hospital_readmission
 * @property {number} percent_missed_visits_no_travel
 * @property {number} missed_visits_with_travel
 * @property {number} missed_visits_without_travel
 * @property {number} avg_cost_per_visit
 * @property {number} avg_reimbusement_per_visit
 * @property {number} avg_cost_per_minute
 * @property {number} avg_reimbusement_per_minute
 * @property {number} avg_visits_per_patient
 * @property {number} excessive_visits_utilized
 * @property {number} minutes_lost_staff_time_with_travel
 * @property {number} minutes_lost_staff_time_without_travel
 * @property {number} direct_cost_with_travel
 * @property {number} direct_cost_without_travel
 * @property {number} lost_time_in_visits_with_travel
 * @property {number} lost_time_in_visits_without_travel
 * @property {number} revenue_lost_with_travel
 * @property {number} revenue_lost_without_travel
 * @property {number} readmissions_lost_time
 * @property {number} readmissions_direct_cost
 * @property {number} readmissions_time_in_visits
 * @property {number} readmissions_revenue_lost
 * @property {number} excessive_visits_count
 * @property {number} excessive_visits_lost_staff_time
 * @property {number} excessive_visits_direct_cost
 * @property {number} excessive_visits_lost_time_in_visits
 * @property {number} excessive_visits_revenue_lost
 * @property {number} total_lost_staff_time
 * @property {number} visit_conversion_lost_staff_time
 * @property {number} visits_new_patient_conversion
 * @property {number} direct_costs_lost_time
 * @property {number} revenue_opportunity_lost
 * @property {number} total_lost_revenue
 * @property {number} total_submitted_claims
 * @property {number} total_estimated_cost
 */