import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
import { getCurrentClientId } from "shared/lib/lib-user";
import NotificationRules from "shared/lib/lib-notification-settings";

export default class SceneSettingsNotifications extends HTMLElement {
    get alert_rules() {
        return this._alert_rules;
    }

    set alert_rules(value) {
        this._alert_rules = value;
        this.render();
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = !!value;
        this.render();
    }

    constructor() {
        super();
        this._disabled = true;
        this._alert_rules = {
            level_1: {},
            level_2: {},
            level_3: {},
            patient_sms: {},
        }
    }

    connectedCallback() {
        this.template = () => {
            return html`
            <div class="row">
                <div class="col">
                    <h2 style="font-size: 24px; font-weight: 600; margin-bottom: 16px; margin-top: 8px;">
                        Default Notification Settings
                    </h2>
                    <hr />
                    <p>
                        Use these settings to configure how which notifications go to which delivery method.
                        These settings will be used by default for everyone in your organization.
                        There are individual user settings available in "Care Team" settings.
                        The "force off" and "force on" settings will override any individual user settings.
                    </p>
                    <p><strong>Note: </strong> changes are saved automatically.</p>
                </div>
            </div>

            <div class="row m-2">
                <div class="col">
                    <h5>Level 1 Alerts</h5>
                    ${this.selectTemplate("SMS", "sms", 1, this.alert_rules?.level_1.sms)}
                    ${this.selectTemplate("Email", "email", 1, this.alert_rules?.level_1.email)}
                    ${this.selectTemplate("App", "app", 1, this.alert_rules?.level_1.app)}
                </div>
            </div>
                    
            <div class="row m-2">
                <div class="col">
                    <h5 class="mt-1">Level 2 Alerts</h5>
                    ${this.selectTemplate("SMS", "sms", 2, this.alert_rules?.level_2.sms)}
                    ${this.selectTemplate("Email", "email", 2, this.alert_rules?.level_2.email)}
                    ${this.selectTemplate("App", "app", 2, this.alert_rules?.level_2.app)}
                </div>
            </div>
                    
            <div class="row m-2">
                <div class="col">
                    <h5 class="mt-1">Level 3 Alerts</h5>
                    ${this.selectTemplate("SMS", "sms", 3, this.alert_rules?.level_3.sms)}
                    ${this.selectTemplate("Email", "email", 3, this.alert_rules?.level_3.email)}
                    ${this.selectTemplate("App", "app", 3, this.alert_rules?.level_3.app)}
                </div>
            </div>

            <div class="row m-2">
                <div class="col">
                    <h5 class="mt-1">Patient SMS Alerts</h5>
                    ${this.selectTemplate("SMS", "sms", 4, this.alert_rules?.patient_sms.sms)}
                    ${this.selectTemplate("Email", "email", 4, this.alert_rules?.patient_sms.email)}
                </div>
            </div>`;
        }

        this.init();
        this.render();
    }

    async init() {
        this.rule_service = new NotificationRules(
            getCurrentClientId(),
            null,
            directus.items("notification_rule"),
        );
        await this.loadNotificationRules();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    selectTemplate(name, key, level, rule) {
        return html`
        <div class="row mb-2">
            <div class="col">
                <label for="level-${level}-${key}">${name}</label>
            </div>
            <div class="col-9">
                <select id="level-1-${key}" class="form-select" ?disabled=${this.disabled} @change=${(e) => this.setRule(
            level,
            key,
            rule,
            e.target.value
        )}>
                    <option value="off" ?selected=${this.isSelected(rule, "off")}>Off</option>
                    <option value="on" ?selected=${this.isSelected(rule, "on")}>On</option>
                    <option value="force_on" ?selected=${this.isSelected(rule, "force_on")}>Force On</option>
                    <option value="force_off" ?selected=${this.isSelected(rule, "force_off")}>Force Off</option>
                </select>
            </div>
        </div>`;
    }

    isSelected(rule, setting) {
        if (!rule) return false;
        if (setting === "force_on" && rule && rule.ignore_user_id && rule.ignore_delivery_method !== true) {
            return true;
        }
        if (setting === "force_off" && rule && rule.ignore_delivery_method === true && rule.ignore_user_id) {
            return true;
        }
        if (setting === "off" && !rule) {
            return true;
        }
        if (setting === "on" && rule) {
            return true;
        }
        return false;
    }

    async setRule(level, delivery_method, rule, value) {
        switch (value) {
            case "on":
                this.rule_service.addClientRule(level, delivery_method);
                break;
            case "off":
                this.rule_service.removeRule(level, delivery_method);
                break;
            case "force_on":
                this.rule_service.addClientRule(level, delivery_method, false, true);
                break;
            case "force_off":
                this.rule_service.addClientRule(level, delivery_method, true, true);
                break;
        }
    }

    async toggleRule(level, delivery_method, rule) {
        if (rule) {
            await directus.items('notification_rule').deleteOne(rule.id);
        } else {
            await directus.items('notification_rule').createOne({
                status: "published",
                level,
                delivery_method,
                client_id: getCurrentClientId(),
            });
        }
        this.loadNotificationRules();
    }

    async loadNotificationRules() {
        this.disabled = true;
        this.alert_rules = await this.rule_service.loadRuleMap();
        this.disabled = false;
    }
}

customElements.define("scene-settings-notifications", SceneSettingsNotifications);
