import { html, render } from "lit";
import * as bootstrap from "bootstrap";

/**
 * @param {AvoidableEventsMetrics} metrics flat object with all impact metrics
 * A widget displaying metrics around excessive visits.
 */
export default class AppAvoidableEventsExcessiveVisit extends HTMLElement {
    constructor() {
        super();
        this.metrics = {};
    }

    set metrics(value) {
        this._metrics = value || {};
        this.render();
    }
    get metrics() {
        return this._metrics;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #avoidable-events-excessive h6 {
                        margin: unset;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 26px;
                        color: var(--t-color-dark);
                    }
                    #avoidable-events-excessive .widget-row {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    #avoidable-events-excessive .widget-metric {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                        width: 126px;
                        height: 84px;
                        position: relative;
                    }
                    #avoidable-events-excessive .widget-metric__value {
                        font-weight: 700;
                        line-height: 56px;
                        font-size: 40px;
                        color: var(--t-color-dark);
                        letter-spacing: 0.5px;
                    }
                    #avoidable-events-excessive .widget-metric__label {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: var(--t-color-grey);
                        text-align: center;
                        white-space: pre-line;
                    }
                </style>
                <div 
                    id="avoidable-events-excessive" 
                    style="
                        height: 100%; 
                        display: flex; 
                        flex-direction: column;
                    "
                >
                    <h6 style="margin: 0 6px;">
                        Excessive Visit Utilization
                    </h6>
                    <div style="margin-top: 8px;">
                        <div class="widget-row">
                            ${this.renderMetric("# of Patients", "total_active_patient_census", "Total Active Patient Census")}
                            ${this.renderMetric("% of Patients w/ 1+ Excessive Visit", "estimated_excessive_visit_percentage", null, false, true)}
                            ${this.renderMetric("# of Excessive Visits", "excessive_visits_count", "Total Active Patient Census\n*\nEst % of Patients with 1+ excessive visit")}
                        </div>
                        <div class="widget-row">
                            ${this.renderMetric("Minutes of Lost Staff Time", "excessive_visits_lost_staff_time", "# of Excessive Visits\n*\nAvg Time Spent per Visit")}
                            ${this.renderMetric("Direct Cost Lost", "excessive_visits_direct_cost", "Minutes of Lost Staff Time\n*\nAvg Cost Per Min", true)}
                        </div>
                        <div class="widget-row">
                            ${this.renderMetric("Lost Time Converted to Visits", "excessive_visits_lost_time_in_visits", "Minutes of Lost Staff Time<hr />Avg Time Spent Per Visit")}
                            ${this.renderMetric("Potential New Revenue Lost", "excessive_visits_revenue_lost", "Lost Time Converted to Visits\n*\nAvg Reimbursement per Visit", true)}
                        </div>
                    </div>
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "var(--t-color-white)",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "24px 12px 12px 12px",
        });

        this.render();
        [...this.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((el) => new bootstrap.Tooltip(el));
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    renderMetric(label, metric_key, formula_text, is_dollar_amount = false, is_percentage = false) {
        return html`
            <div 
                class="widget-metric"
                title=${formula_text}
                data-bs-html="true"
                data-bs-toggle=${formula_text ? "tooltip" : ""}
                data-bs-placement="right"
                data-bs-custom-class="widget-formula-tooltip"
            >
                <span class="widget-metric__value" style="color: ${is_dollar_amount ? "var(--t-color-danger)" : "var(--t-color-dark)"};">
                    ${(is_dollar_amount ? "$" : "") +
            (Math.round(this.metrics[metric_key]) || 0).toLocaleString() +
            (is_percentage ? "%" : "")
            }
                </span>
                <span class="widget-metric__label">${label}</span>
            </div>
        `
    }

}

customElements.define("app-avoidable-events-excessive-visit", AppAvoidableEventsExcessiveVisit);
