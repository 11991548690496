import { html, render } from "lit";

export default class AppPatientOasisDetail extends HTMLElement {
    constructor() {
        super();
        this._patient = null;
        this._oasis = [];
    }

    get patient() {
        return this._patient;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get oasis() {
        return this._oasis;
    }

    set oasis(value) {
        this._oasis = value;
        this.render();
    }

    connectedCallback() {
        this.template = () => html` <style>
                .patient-files {
                    border-radius: 8px;
                    box-shadow: var(--t-box-shadow);
                }

                .patient-files small {
                    color: var(--t-color-grey);
                }

                @media(min-width: 768px) {
                    td.oasis-name {
                        width: 50%;
                    }
                }
            </style>
            <div class="m-4 p-4 content-background patient-files">
                <div class="row">
                    <div class="col">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${this.oasisTemplate(this.oasis?.assessment_results?.ASSESSMENT)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>`;

        this.style = Object.assign(this.style, {
            display: "block",
        });

        this.render();
    }

    oasisTemplate(assessment) {
        if (!assessment) {
            return "";
        }
        const rows = [];
        for (const key in assessment) {
            rows.push(html`<tr>
                <td class="text-md-end oasis-name">
                    <div class="d-md-none text-start" style="flex: 1; color: #999;">
                        Name
                    </div>
                    ${key}
                </td>
                <td class="text-md-start">
                    <div class="d-md-none text-start" style="flex: 1; color: #999;">
                        Value
                    </div>
                    ${assessment[key]}
                </td>
            </tr>`)
        }
        return rows;
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        render(this.template(), this);
    }
}

customElements.define("app-patient-oasis-detail", AppPatientOasisDetail);
