import { html, render } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { until } from 'lit/directives/until.js';
import { Dropdown } from 'bootstrap';
import { Collapse } from 'bootstrap';
import AppFilterUtil from './util/app-filter-util';

class AppFilterFieldSelectList extends HTMLElement {

    set label(value) {
        this._label = value;
    }

    get label() {
        return this._label || 'Select field';
    }

    set collection_config(value) {
        this._collection_config = value;
    }

    /** @type {import('./app-filter').CollectionConfig} */
    get collection_config() {
        return this._collection_config;
    }

    set selected_field(value) {
        this._selected_field = value;
    }

    get selected_field() {
        return this._selected_field;
    }

    set exclude_paths(value) {
        this._exclude_paths = value;
    }

    /** @type {string[]} - A list of paths to exclude from showing in the dropdown list */
    get exclude_paths() {
        return this._exclude_paths || [];
    }

    connectedCallback() {
        this.template = () => html`
        ${until(
            (async () => {
                //wait for the collection config to be auto-configured
                await this.collection_config.configured;
                return html`
                        <div class="dropdown">
                            <button 
                                style="font-size: 12px; padding: 5px; background-color: white;" 
                                class="btn dropdown-toggle" type="button" 
                                data-bs-toggle="dropdown" aria-expanded="false">
                                ${unsafeHTML(AppFilterUtil.getDisplayField(this.selected_field) || this.label)}
                            </button>
                            <ul style="font-size: 12px;" class="dropdown-menu">
                                ${this.getFieldItems(this.collection_config)}
                            </ul>
                        </div>
                    `
            })(),
            html`Loading...`
        )
            }
        `;

        this.render();
    }

    render() {
        if (!this.template)
            return;

        if (!this.collection_config)
            return;

        render(this.template(), this);
    }

    handleSelectField(e, field_path) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let element = this.querySelector('.dropdown-toggle');
        let dropdown = new Dropdown(element);
        dropdown.hide();
        element.focus();
        this.selected_field = field_path;
        this.render();
        this.dispatchEvent(new CustomEvent('select-field', { bubbles: true, composed: true, detail: field_path }));
    }

    /**
     * 
     * @param {Event} e 
     * @param {import('./app-filter').FieldConfig} field 
     */
    handleToggleExpandedField(e, field) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let expand_element = this.querySelector(`#app-filter-item-select-${field.field}`);
        let collapse = Collapse.getOrCreateInstance(expand_element);
        collapse.toggle();
    }

    getFieldItems(collection_config, path) {
        return html`
            ${collection_config.fields.map(
            /**
             * @param {import('./app-filter').FieldConfig} field 
             */
            (field) => {
                let field_path;
                if (path)
                    field_path = `${path}.${field.field}`;
                else
                    field_path = field.field;

                if (this.exclude_paths.includes(field_path))
                    return '';

                if (field.meta?.hidden)
                    return '';

                return html`
                    <li>
                        <div
                            style="display: flex; flex-direction: row; align-items: center;"
                        >
                            <a
                                @click=${e => this.handleSelectField(e, field_path, field)}
                                class="dropdown-item ${field == this.selected_field ? 'active' : ''}" 
                                href="#">${field.label}</a>
                            ${field.related_collection && field.related_collection.depth > 0 ?
                        html`
                                <span
                                    @click=${e => this.handleToggleExpandedField(e, field)}
                                    class="material-symbols-outlined"
                                    data-bs-toggle="collapse"
                                    data-bs-target="${`#app-filter-item-select-${field_path}`}"
                                    style='
                                        margin-right: 10px;
                                        font-size: 14px;
                                        cursor: pointer;
                                        color: var(--t-color-dark);
                                        font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 48;
                                    '>expand_more</span>
                            `: ''}
                        </div>
                        ${field.related_collection && field.related_collection.depth > 0 ?
                        html`
                        <div class="collapse" id="${`app-filter-item-select-${field_path}`}">
                            <ul>
                                ${this.getFieldItems(field.related_collection, field_path)}
                            </ul>
                        </div>
                        `: ''}
                    </li>
                    `
            }
        )}
        `;
    }

}

customElements.define('app-filter-field-select-list', AppFilterFieldSelectList);
export default AppFilterFieldSelectList;