import { html, render } from "lit";
import directus from "../lib/lib-directus";

export default class AppPasswordReset extends HTMLElement {
    constructor() {
        super();
    }

    get token() {
        return this._token;
    }

    set token(value) {
        this._token = value;
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = !!value;
        this.render();
    }

    get appname() {
        return this._app_name;
    }

    set appname(value) {
        this._app_name = value;
        this.render();
    }

    connectedCallback() {
        this.baseTemplate = (body) => html` <style>
                .form-signin {
                    background: var(--bs-white);
                    width: 100%;
                    max-width: 330px;
                    padding: 0;
                    margin: auto;
                    margin-top: 10vh;
                    border-radius: 8px;
                    box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                }

                .form-signin-logo {
                    
                    border-radius: 8px 8px 0 0;
                    padding: 15px 15px 0 15px;
                }

                .form-signin-controls {
                    padding: 15px;
                }

                .form-signin-controls .checkbox {
                    font-weight: 400;
                }

                .form-signin-controls .form-floating:focus-within {
                    z-index: 2;
                }

                .form-signin-controls input#new-password {
                    margin-bottom: -1px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }

                .form-signin-controls input#repeat-password {
                    margin-bottom: 10px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            </style>
            <main class="form-signin" id="form-signin">
                <div class="form-signin-logo"><img class="mb-4" src="images/vb-h-logo-white-alpha.svg" alt="" /></div>
                
                <div class="form-signin-controls">
                    ${this.error ? html`<div class="alert alert-danger error-message">${this.error}</div>` : ""}
                    ${this.success ? html`<div class="alert alert-success error-message">${this.success}</div>` : ""}
                    ${body}
                </div>
            </main>`;

        this.startTemplate = () => html`
            <form onsubmit="return false" @submit=${(e) => this.handleResetStart(e)}>
                <!-- <h1 class="h3 mb-3 fw-normal">Forgot Password?</h1> -->
                <p class="mb-3">Enter your email and if you have an account we'll send a password to reset your password.</p>

                <div class="form-floating">
                    <input type="email" class="form-control" id="login-email" placeholder="name@example.com" ?disabled=${this.disabled} />
                    <label for="login-email">Email address</label>
                </div>

                <button class="mt-3 w-100 btn btn-lg btn-primary" id="login-submit" 
                type="submit" ?disabled=${this.disabled}>Reset Password</button>
                <!-- <p class="mt-5 mb-3 login-">&copy; 2024 - VisitBridge Health</p> -->
            </form>`;

        this.finishTemplate = () => html`
            <form onsubmit="return false" @submit=${(e) => this.handleResetComplete(e)}>
               <!-- <h1 class="h3 mb-3 fw-normal">Forgot Password?</h1> -->
                <p class="mb-3">Enter your email and if you have an account we'll send a password to reset your password.</p>

                <div class="form-floating">
                    <input type="password" class="form-control" id="new-password" ?disabled=${this.disabled} />
                    <label for="new-password">New Password</label>
                </div>
                <div class="form-floating">
                    <input type="password" class="form-control" id="repeat-password" ?disabled=${this.disabled} />
                    <label for="repeat-password">Repeat Password</label>
                </div>

                <button class="mt-3 w-100 btn btn-lg btn-primary" id="login-submit" type="submit" ?disabled=${this.disabled}>Reset Password</button>
               <!-- <p class="mt-5 mb-3 text-muted">&copy; 2024 - VisitBridge Health</p> -->
            </form>`;

        Object.assign(this.style, {
            flex: 1,
        });

        const search_str = window.location.search.substring(1);
        const search = {};
        search_str.split("&").map((i) => {
            const split = i.split("=");
            return search[split[0]] = split[1];
        });

        if (search.token) {
            this.token = search.token;
        }

        this.render();
    }

    render() {
        if (!this.baseTemplate) return;

        if (this.token) {
            console.log("has token");
            render(this.baseTemplate(this.finishTemplate()), this);
        } else {
            console.log("no token");
            render(this.baseTemplate(this.startTemplate()), this);
        }
    }

    async handleResetStart(e) {
        this.disabled = true;
        try {
            e.preventDefault();
            e.stopPropagation();

            const email_input = this.querySelector("#login-email");

            const port = window.location.port != '' ? ":" + window.location.port : "";
            const URL = `${window.location.protocol}//${window.location.hostname}${port}/${this.appname}/resetpassword`;
            console.log('url', URL);
            console.log('port', port);
            await directus.auth.password.request(email_input.value, URL);
            this.success = "Go ahead and check your email.";
        } catch (err) {
            this.error = err.message;
            this.disabled = false;
        }
        this.render();
    }

    async handleResetComplete(e) {
        this.error = "";
        this.success = "";
        this.disabled = true;

        try {
            e.preventDefault();
            e.stopPropagation();

            const new_password = this.querySelector("#new-password");
            const repeat_password = this.querySelector("#repeat-password");

            if (new_password.value !== repeat_password.value) {
                this.error = "Passwords do not match";
                this.disabled = false;
                return;
            }

            const res = await directus.auth.password.reset(this.token, new_password.value);
            window.location.href = `/${this.appname}/`;
        } catch (err) {
            this.error = err.message;
            this.disabled = false;
        }
    }
}

customElements.define("app-password-reset", AppPasswordReset);
