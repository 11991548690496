import { html, render } from "lit";
import ModalBase from './util/modal-base';

/**
 * @param modal_text
 */
export default class AppWarningModal extends ModalBase {
    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    app-warning-modal .modal-confirm-btn {
                        background-color: var(--t-color-warning);
                        border-color: var(--t-color-warning);
                        color: white;
                    }
                    app-warning-modal .btn.modal-confirm-btn:hover {
                        background-color: var(--t-color-warning-dark);
                        border-color: var(--t-color-warning-dark);
                        color: white;
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" style="display: flex; align-items: center;">
                                <span class="material-symbols-outlined" style="margin-right: 4px; color: var(--t-color-warning);">
                                    warning
                                </span>
                                Warning
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p style="margin: 0;">${this.modal_text}</p>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-primary btn-warning modal-confirm-btn"
                                @click=${(_e) => this.handleConfirmClick()}>
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Click handler for 'Confirm' button.
     * Resolves the ModalBase promise by calling dismiss()
     */
    async handleConfirmClick() {
        this.dismiss({ confirmed: true });
    }
}

customElements.define("app-warning-modal", AppWarningModal);
