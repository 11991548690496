import { html, render } from "lit";
import ModalBase from './util/modal-base';

export default class AppSettingsScheduleModal extends ModalBase {
    constructor() {
        // Call super with required modal configuration
        super({
            id: 'settings-schedule-modal',
            title: 'Schedule Settings'
        });

        this.name = '';
        this.type = 'patient';
        this.tags = [];
        this.error_message = '';

        // Initialize the template
        this.template = () => html`
            <style>
                .modal-body div {
                    margin: 10px 0;
                }
                .form-group {
                    text-align: left;
                    margin-bottom: 1rem;
                }
                .form-check {
                    margin: 0.5rem 0;
                }
                .tag-group {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                }
                .tag-check {
                    flex: 0 0 auto;
                }
            </style>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Schedule Settings</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ${this.error_message ? html`
                            <div class="alert alert-danger">
                                ${this.error_message}
                            </div>
                        ` : ''}
                        <div class="form-group">
                            <label for="scheduleName">Schedule Name</label>
                            <input type="text" class="form-control" id="scheduleName" 
                                .value=${this.name}
                                @input=${(e) => this.name = e.target.value}>
                        </div>
                        
                        <div class="form-group">
                            <label>Schedule Type</label>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="scheduleType" id="typePatient" 
                                    .checked=${this.type === 'patient'}
                                    @change=${() => this.type = 'patient'}>
                                <label class="form-check-label" for="typePatient">
                                    Patient
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="scheduleType" id="typeEpisode" 
                                    .checked=${this.type === 'episode'}
                                    @change=${() => this.type = 'episode'}>
                                <label class="form-check-label" for="typeEpisode">
                                    Episode
                                </label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Tags</label>
                            <div class="tag-group">

                            <!-- Add tags here -->
                                ${['soc', 'roc', 'recert', 'dc'].map(tag => html`
                                    <div class="form-check tag-check">
                                        <input class="form-check-input" type="checkbox" 
                                            id="tag${tag}" 
                                            .checked=${this.tags.includes(tag)}
                                            @change=${(e) => this.handleTagChange(tag, e.target.checked)}>
                                        <label class="form-check-label" for="tag${tag}">
                                            ${tag.toUpperCase()}
                                        </label>
                                    </div>
                                `)}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal" @click=${() => this.handleCancel()}>Cancel</button>
                        <button type="button" class="btn btn-primary modal-confirm-btn" @click=${this.handleSave}>Save</button>
                    </div>
                </div>
            </div>
        `;
    }

    connectedCallback() {
        this.render();
        this.addEventListener("shown.bs.modal", this.render);
    }

    handleTagChange(tag, checked) {
        if (checked) {
            this.tags = [...this.tags, tag];
        } else {
            this.tags = this.tags.filter(t => t !== tag);
        }
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    handleCancel() {
        this.dismiss({
            confirmed: false
        });
    }

    handleSave = () => {
        try {
            // Disable save button to prevent double submission
            const saveButton = this.querySelector('.modal-confirm-btn');
            if (saveButton) {
                saveButton.disabled = true;
            }

            if (!this.name.trim()) {
                this.error_message = 'Please enter a schedule name';
                document.querySelector("app-toaster")?.toast('Please enter a schedule name', 'error');
                if (saveButton) {
                    saveButton.disabled = false;
                }
                this.render();
                return;
            }

            this.error_message = '';
            this.message = this.name + ' saved successfully';
            this.dismiss({
                confirmed: true,
                name: this.name,
                type: this.type,
                tags: this.tags,
                message: this.message
            });
            console.log('Schedule saved successfully from modal:', this.message);

        } catch (error) {
            console.error('Error in schedule modal:', error);
            this.error_message = 'Error saving schedule. Please try again.';
            document.querySelector("app-toaster")?.toast('Error saving schedule. Please try again.', 'error');

            // Re-enable save button on error
            const saveButton = this.querySelector('.modal-confirm-btn');
            if (saveButton) {
                saveButton.disabled = false;
            }
            this.render();
        }
    }
}

customElements.define("app-settings-schedule-modal", AppSettingsScheduleModal);
