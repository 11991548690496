import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
////import BootstrapTable from 'bootstrap-table/dist/bootstrap-table';
import DataDefinition from "shared/lib/lib-data-definition";
import { Dropdown } from 'bootstrap';
import { ref, createRef } from "lit/directives/ref.js";
import { getCurrentClientId } from "shared/lib/lib-user";
import { navigate } from "shared/lib/lib-router";
import { noteCellFormatter } from "../util/lib-report";
import { format, parseISO, differenceInDays, startOfWeek, formatISO, isValid, getISOWeek, getYear } from 'date-fns';
import { getISODateStringWithoutTime } from "shared/lib/lib-date";
import "shared/components/app-report-filter";
import "shared/components/app-chip-sort";
import ApexCharts from 'apexcharts'

export default class ReportPatientVisits extends HTMLElement {
    static get config() {
        return {
            icon: "personal_injury",
            report: "visits",
            title: "Patient Visits",
            description: "Visits and Transfers.",
            component: ReportPatientVisits
        };
    }
    get status_filter() {
        return this._status_filter;
    }

    set status_filter(value) {
        this._status_filter = value;
    }
    set filter_state(value) {
        this._filter_state = value;
    }
    /** @type {import('shared/components/filter/app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;

    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    get selected_columns() {
        return this._selected_columns;
    }

    set table_columns(value) {
        this._table_columns = value;
    }

    get table_columns() {
        return this._table_columns;
    }
    constructor() {
        super();
        //this.data = [];
        //this.data_table = null;
        //this.survey_definition = null;
        //this._loading_data = true;
        this._options = {
            ajaxURL: "/items/visit",
            ajaxParams: {},
            sortMode: "remote",
            filterMode: "remote",
            //pagination: true,
            //paginationMode: "remote",
            //paginationSize: 25,
            paginationCounter: "rows",
            layout: "fitDataFill",
            progressiveLoad: "scroll",
            progressiveLoadScrollMargin: 300,
            responsiveLayout: "collapse",
            //responsiveLayoutCollapseStartOpen: this.toggle,
            alignEmptyValues: "bottom",
            initialSort: [{ column: "current_episode_start", dir: "desc" },],
            columnDefaults: {
                tooltip: false,
            },
            checkbox: false
        };
        this._selected_columns = [
            "patient_id.status",
            "patient_id.status_reason",
            "patient_id.current_soc",
            "patient_id.first_name",
            "patient_id.last_name",
            "patient_id.mrn",
            "patient_id.primary_diagnosis",
            "patient_id.current_episode_id.start_date",
            "patient_id.current_episode_id.end_date",
            //"episodes.start_date",
            //"episodes.end_date",
            "patient_id.current_episode_start",
            "patient_id.current_episode_end",
            "patient_id.current_soc",
            "careteam_member_id",
            "careteam_member_id.first_name",
            "careteam_member_id.last_name",
            "visit_type",
            "visit_description",
            "visit_date",
            "client_id.name",
            //"iso_start",
            //"ext_referral",
            //"referral_source"
        ];
        this._table_columns = [


            //           {
            //               formatter: "responsiveCollapse",
            //               field: "responiveIcon",
            //               headerSort: false,
            //               titleFormatter: function (cell) {
            //
            //                   var isExpanded = false;
            //
            //                   // Create a container for the toggle button
            //                   var container = document.createElement("div");
            //                   container.className = "tabulator-responsive-collapse-toggle-header";
            //
            //                   // Add SVG for 'expand all' and 'collapse all'
            //                   container.innerHTML = `<svg class="tabulator-responsive-collapse-toggle-open" viewBox="0 0 24 24">
            //                                            <line x1="7" y1="12" x2="17" y2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
            //                                            <line y1="7" x1="12" y2="17" x2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
            //                                         </svg>
            //                                         <svg class="tabulator-responsive-collapse-toggle-close" viewBox="0 0 24 24" style="display: none;">
            //                                            <line x1="7" y1="12" x2="17" y2="12" fill="none" stroke-width="3" stroke-linecap="round"></line>
            //                                         </svg>`;
            //
            //                   // Event listener for the toggle
            //                   container.addEventListener("click", function () {
            //                       var table = cell.getTable();
            //                       table.getRows().forEach(function (row) {
            //                           var rowToggle = row.getElement().querySelector('.tabulator-responsive-collapse-toggle');
            //                           var rowContent = row.getElement().querySelector('.tabulator-responsive-collapse');
            //
            //                           if (rowToggle && rowContent) {
            //                               if (isExpanded) {
            //                                   rowToggle.classList.remove('open');
            //                                   rowContent.style.display = 'none'; // Collapse the content
            //                               } else {
            //                                   rowToggle.classList.add('open');
            //                                   rowContent.style.display = ''; // Expand the content
            //                               }
            //                           }
            //                       });
            //
            //                       container.querySelector('.tabulator-responsive-collapse-toggle-open').style.display = isExpanded ? '' : 'none';
            //                       container.querySelector('.tabulator-responsive-collapse-toggle-close').style.display = isExpanded ? 'none' : '';
            //
            //
            //                       isExpanded = !isExpanded;
            //                   });
            //
            //                   return container;
            //               }
            //           },
            {
                name: "Date",
                fields: ["visit_date"],
                field: "visit_date",
                formatter: "datetime",
                formatterParams: {
                    inputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",  // Corrected input format
                    outputFormat: "MM/dd/yy",
                    invalidPlaceholder: "(invalid date)"
                },
                sorter: "datetime", sorterParams: {
                    format: "MM/dd/yy",
                }
            },

            {

                name: "Patient",
                fields: ["patient_id.first_name", "patient_id.last_name"],
                field: "patient_id.first_name",


            },
            {
                name: "Description",
                fields: ["visit_description"],
                field: "Description",
                sorter: "string",

            },
            //{
            //    name: "visit_type",
            //    fields: ["visit_type"],
            //    field: "type",
            //    minWidth: 50,
            //},
            {

                name: "careteam_member_id",
                fields: ["careteam_member_id.first_name", "careteam_member_id.last_name"],
                field: "careteam_member_id.first_name",


            },


        ];

        this._filter_config = {
            collection: { name: "patient", auto_configure: false, depth: 0 },
            search_fields: [
                "first_name",
                "last_name",
                "status",
                "source",
                "mrn",
                "medicare_id",
                "referral_source",
                "ext_referral"
            ],
            field_rules: {
                directus_users: {
                    mode: "whitelist",
                    fields: [
                        "first_name",
                        "last_name",
                    ],
                }
            }
        };
        this.dropdown = null;
    }

    connectedCallback() {


        //  const toggleButton = this.querySelector('#toggle-all-rows-btn');
        //  if (toggleButton) {
        //      toggleButton.addEventListener('click', () => {
        //          this.expandCollapseAllRows('expand');
        //          console.log("toggle all rows");
        //      });
        //  }

        this.template = () =>
            html`
                <style>
                    import-patients-imported .item-row:hover {
                        background-color: #f0f0f0;
                        cursor: pointer;
                    }

                    import-patients-imported li.disabled {
                        cursor: not-allowed;
                    }

                    .actions-menu .btn.btn-primary.dropdown-toggle {
                        background-color: #E4958B;
                        border-color: #E4958B; 
                    }
                </style>
                <div class="container-fluid" style="height: 100%; padding: 0;">
                    <div class="row" style="">
                     <div class="actions-menu">
                         <div class="row" style="padding: 0 16px; border-radius: 8px;" id="patients-import-container">
                    <div class="col-md-6 visit-table-toolbar">
            
                        <app-filter 
                            @filter_change=${e => this.handleFilterChange(e.detail)}
                            style="margin: 0 15px 20px 15px;"
                            .filter_state=${this.filter_state}
                            .config=${this._filter_config}
                            .enable_aggregate=${false}
                            .disable_sort=${true}
                            .show_filters=${false}
                            .expanded=${true}
                        ></app-filter>
                    </div> 
                   
                     </div>
                     </div>
                    <div class="row" style="padding: 0 16px; border-radius: 8px;" id="patients-import-container">
                    <div class="col-md-6 visit-table-container">
                        <app-filter-data-view-server
                            .config=${this._filter_config}
                            .filter_state=${this.filter_state}
                            .status_filter=${this.status_filter}
                            .selected_columns=${this.selected_columns}
                            .options=${this._options}
                            .table_columns=${this.table_columns}
                            .selection_type=${"multiple"}
                            .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        },

                    ]
                }}
                            @select-row=${e => this.handleSelect(e.detail)}
                            @expand-collapse-all=${e => this.handleExpandCollapseAll(e.detail.action)}
                            style="padding: 0;"
                        ></app-filter-data-view-server>
                    </div>
                
                
                <div class="col-md-6">
                <div id="visitByCareTeamChart"></div>
                <div id="taskVsVisitChart"></div>
                
                <div class="col-12 col-lg-6 col-xl-4" style="background: var(--t-color-white); box-shadow: var(--t-box-shadow); border-radius: 8px; display: block; padding: 24px;">
                 
                
                <div id="dashboard-high-risk" style="
                        height: 100%; 
                        cursor: pointer; 
                        display: flex; 
                        flex-direction: column;
                    ">
                    <h6>Recent Hospitalizations</h6>
                    
             
  

                </div>
                </div>
                </div>
                </div>
                </div>
            `;


        this.loadData();
        this.render();
        this.init();

        //this.addEventListener('click', event => { // listner for toggle all rows buttons expand/collapse (not being used)
        //    if (event.target.id === 'toggle-all-rows-btn') {
        //        this.expandCollapseAllRows('expand');
        //    }
        //});

    }
    async loadData() {
        try {
            await this.loadTasks();  // Load tasks first
            await this.loadVisits();  // Now load visits which will process visits and tasks
        } catch (error) {
            console.error("Error loading or processing data:", error);
        }
    }


    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    r

    init() {
        // let filter_state = ApplicationState.get('app.import_queued_patients.filter_state');
        let filter_state;
        if (!filter_state) {
            // default filter to non-active imported patients
            filter_state = { user_search: "" };
            filter_state.group = {
                type: "_and",
                filters: [
                    //{
                    //    "field": "status",
                    //    "op": "_in",
                    //    "value": [this.status_filter]
                    //}
                ],
                groups: [],
            };
        }
        let filter_element = this.querySelector('app-filter');
        filter_element.filter_state = filter_state;
        this.filter_state = filter_state;

        let dropdownElement = this.querySelector(".dropdown-toggle");
        if (dropdownElement) {
            this.dropdown = new Dropdown(dropdownElement, {
                autoClose: "outside"
            });
        }


        this.render();
    }

    async loadTasks() {
        try {
            const response = await directus.items("task").readByQuery({
                filter: {
                    status: "complete"
                },
                fields: ["completed_date"],  // Updated to the correct field name
                sort: ["completed_date"]
            });
            this.tasks = response.data;
        } catch (error) {
            console.error('Error loading tasks:', error);
            this.tasks = [];  // Ensure tasks is at least an empty array to prevent errors
        }
    }


    async loadVisits() {
        try {
            const response = await directus.items("visit").readByQuery({
                fields: [
                    "visit_description",
                    "status",
                    "visit_date",
                    "patient_id.first_name",
                    "patient_id.last_name",
                    "careteam_member_id.first_name",
                    "careteam_member_id.last_name"
                ],
                sort: ["visit_date"]
            });
            console.log('Loaded visits:', response.data);
            this.processVisits(response.data);
            return response.data;
        } catch (error) {
            console.error('Error loading visits:', error);
        }
    }
    processVisits(visits) {
        this.nonOasisVisits = visits.filter(visit => !visit.visit_description.includes("OASIS") && visit.careteam_member_id);
        this.oasisVisits = visits.filter(visit => visit.visit_description.includes("OASIS"));
        this.renderVisitByCareTeamChart(this.nonOasisVisits);
        this.renderTaskVsVisitChart(this.nonOasisVisits, this.tasks); // Assuming tasks are loaded similarly
    }


    //updateCharts() {
    //    // Assuming you have a chart library already integrated
    //    this.renderVisitByCareTeamChart(this.nonOasisVisits);
    //    this.renderTaskVsVisitChart(this.nonOasisVisits, this.tasks); // Ensure tasks are loaded and processed similarly
    //}

    /*     renderVisitByCareTeamChart(visits) {
            const data = visits.reduce((acc, visit) => {
                if (visit.careteam_member_id && visit.careteam_member_id.first_name && visit.careteam_member_id.last_name) {
                    const key = `${visit.careteam_member_id.first_name} ${visit.careteam_member_id.last_name}`;
                    acc[key] = (acc[key] || 0) + 1;
                }
                return acc;
            }, {});
    
            const chartOptions = {
                series: [{
                    data: Object.values(data)
                }],
                chart: {
                    type: 'bar'
                },
                xaxis: {
                    //type: 'datetime',
                    categories: Object.keys(data)
                },
                stroke: {
                    curve: 'smooth'
                },
                colors: '#F29188',
                theme: {
                    mode: 'light',
                    palette: 'palette3',
                    monochrome: {
                        enabled: false,
                        color: '#F29188',
                        shadeTo: 'dark',
                        shadeIntensity: 0.65
                    },
                }
            };
    
            const chart = new ApexCharts(document.querySelector("#visitByCareTeamChart"), chartOptions);
            chart.render();
        }
     */
    renderVisitByCareTeamChart(visits) {
        const data = visits.reduce((acc, visit) => {
            if (visit.careteam_member_id && visit.careteam_member_id.first_name && visit.careteam_member_id.last_name) {
                const key = `${visit.careteam_member_id.first_name} ${visit.careteam_member_id.last_name}`;
                acc[key] = acc[key] || { visits: 0, patients: new Set() };
                acc[key].visits++;
                acc[key].patients.add(visit.patient_id);
            }
            return acc;
        }, {});

        const categories = Object.keys(data);
        const visitsData = categories.map(key => data[key].visits);
        const patientsData = categories.map(key => data[key].patients.size);

        const chartOptions = {
            series: [
                {
                    name: 'Visits',
                    type: 'column',
                    fillOpacity: 1,
                    data: visitsData
                },
                {
                    name: 'Patients',
                    type: 'area',
                    data: patientsData,
                    fillOpacity: 0.1,
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: .5,
                            opacityFrom: 0,
                            opacityTo: 0.9,
                            stops: [0, 90, 100]
                        }
                    }
                }
            ],
            chart: {
                height: 450,
                type: 'line',
                stacked: false
            },
            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [0]
            },
            colors: ['#353658', '#FF6384'],
            stroke: {
                width: [1, 1, 4]
            },
            xaxis: {
                categories: categories,
                //title: {
                //    text: 'Care Team Member'
                //}
            },
            yaxis: [
                {
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false,
                        color: '#FF6384'
                    },
                    labels: {
                        style: {
                            colors: '#FF6384'
                        }
                    },
                    //title: {
                    //    text: 'Visits',
                    //    style: {
                    //        color: '#FF6384'
                    //    }
                    //}
                },
                {
                    opposite: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false,
                        color: '#36A2EB'
                    },
                    labels: {
                        style: {
                            colors: '#36A2EB'
                        }
                    },
                    //title: {
                    //    text: 'Patients',
                    //    style: {
                    //        color: '#36A2EB'
                    //    }
                    //}
                }
            ],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== 'undefined') {
                            return y.toFixed(0);
                        }
                        return y;
                    }
                }
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40
            }
        };

        const chart = new ApexCharts(document.querySelector("#visitByCareTeamChart"), chartOptions);
        chart.render();
    }

    aggregateByWeek(items, dateField) {
        var aggregatedData = {};
        items.forEach(item => {
            var dateStr = item[dateField];
            if (!dateStr) {
                console.warn(`Missing ${dateField} for item:`, item);
                return;  // Skip this item if no date is available
            }

            try {
                var date = parseISO(dateStr);
                var weekNumber = getISOWeek(date);
                var year = getYear(date);
                var weekKey = `${year}-W${weekNumber}`;

                if (!aggregatedData[weekKey]) {
                    aggregatedData[weekKey] = {
                        totalVisits: 0,
                        totalPatients: new Set()
                    };
                }
                aggregatedData[weekKey].totalVisits++;
                aggregatedData[weekKey].totalPatients.add(item.patient_id);
            } catch (error) {
                console.error('Error processing date:', dateStr, 'Error:', error);
            }
        });

        // Calculate averages
        Object.keys(aggregatedData).forEach(weekKey => {
            var data = aggregatedData[weekKey];
            data.averageVisitsPerPatient = data.totalVisits / data.totalPatients.size;
        });

        return aggregatedData;
    }





    renderTaskVsVisitChart(visits, tasks) {
        var visitData = this.aggregateByWeek(visits, 'visit_date');
        var taskData = this.aggregateByWeek(tasks, 'completed_date');

        var chartData = {
            labels: [],
            visitAverages: [],
            taskAverages: []
        };

        // Merge the week keys from both datasets
        var weekKeys = [...new Set([...Object.keys(visitData), ...Object.keys(taskData)])].sort();

        weekKeys.forEach(weekKey => {
            chartData.labels.push(weekKey);
            chartData.visitAverages.push(visitData[weekKey] ? visitData[weekKey].averageVisitsPerPatient : 0);
            chartData.taskAverages.push(taskData[weekKey] ? taskData[weekKey].averageVisitsPerPatient : 0);
        });

        // Render the chart using ApexCharts
        const chartOptions = {
            chart: {
                type: 'area'
            },
            stroke: {
                curve: 'smooth'
            },
            series: [
                {
                    name: 'Average Visits per Patient',
                    data: chartData.visitAverages
                },
                {
                    name: 'Average CP per Patient',
                    data: chartData.taskAverages
                }
            ],
            xaxis: {
                //type: 'datetime',
                categories: chartData.labels
            }
        };

        const chart = new ApexCharts(document.querySelector("#taskVsVisitChart"), chartOptions);
        chart.render();
    }









    async loadHospitalizations() {
        const visits = await directus.items("visit").readByQuery({
            filter: {
                visit_description: {
                    _contains: "transfer"
                }
            },
            fields: ["patient_id.name", "visit_date", "careteam_member_id.first_name", "careteam_member_id.last_name", "visit_description", "patient_id.tasks.*"]
        });

        // Filter to include only those with at least one completed task
        const filteredVisits = visits.data.filter(visit => {
            return visit.patient_id.tasks.some(task => task.completed_date);
        });

        return filteredVisits;
    }

    prepareChartData(data) {
        const categories = Object.keys(data);
        const seriesData = Object.values(data).map(value => ({
            data: value
        }));

        return {
            series: [{
                name: 'Non-OASIS Visits',
                data: seriesData
            }],
            xaxis: {
                categories: categories
            }
        };
    }

    renderChart(nonOasisData) {
        const chartOptions = {
            chart: {
                type: 'bar'
            },
            series: [{
                name: 'Non-OASIS Visits',
                data: Object.values(nonOasisData)
            }],
            xaxis: {
                categories: Object.keys('first_name')
            }
        };

        const chart = new ApexCharts(document.querySelector("#nonOasisChart"), chartOptions);
        chart.render();
    }

    //renderTables(oasisVisits, hospitalizations) {
    //    // Assuming you have functions to render these tables
    //    renderOasisTable(oasisVisits);
    //    renderHospitalizationsTable(hospitalizations);
    //}


    async loadActivity() {
        let result = await directus.items("care_event").readByQuery({
            filter: {
                patient_id: this.visit.patient_id
            },
            fields: [

            ]
        });
        this.activity = result.data;
    }

    async loadCareteam() {
        let result = await directus.items("patient_access").readByQuery({
            filter: {
                patient_id: this.visit.patient_id,
                user_id: {
                    client_access: {
                        client_id: getCurrentClientId()
                    }
                }
            },
            fields: [
                'user_id.id',
                'user_id.first_name',
                'user_id.last_name',
                'user_id.phone_number'
            ]
        });
        let rows = result.data;
        let careteam = rows.map(row => row.user_id);
        this.careteam = careteam;
    }
    /**
     * Updates this._page_size based on window dimensions to support infinite scroll
     */
    //updatePageSize() {
    //    const report_height = this.content_ref.value.clientHeight;
    //    const header_height = this.header_ref.value.clientHeight;
    //    const thead_height = document.querySelector('thead').clientHeight;

    //    const scroll_container_max_height = report_height - header_height - thead_height;
    //    this._page_size = Math.ceil((scroll_container_max_height) / MIN_ROW_HEIGHT) + 1;
    //    this._current_page = 1;
    //    this._all_pages_fetched = false
    //    this.fetchData();
    //}
}

customElements.define("report-patient-visits", ReportPatientVisits);
