import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import { format } from "date-fns";
import directus from "../../lib/lib-directus";
import Cache from "../../lib/lib-cache";
import { getCurrentClientId } from "../../lib/lib-user";
import { parseISODateWithoutTime } from "../../lib/lib-date";

export default class AppPatientProfileModal extends ModalBase {
    get patient() {
        return this._patient;
    }

    set patient(value) {
        if (value) {
            // Assuming `primary_phone` and `secondary_phone` are the fields you want formatted
            value.primary_phone = this.formatPhoneNumber(value.primary_phone);
            value.secondary_phone = this.formatPhoneNumber(value.secondary_phone);
        }
        this._patient = value;
        this.expandPatient();
        this.render();
    }

    get has_changes() {
        return this._has_changes;
    }

    set has_changes(value) {
        this._has_changes = !!value;
        this.render();
    }

    get editable() {
        return this._editable;
    }

    set editable(value) {
        this._editable = !!value;
        this.render();
    }

    get clinicians() {
        return this._clinicians;
    }

    set clinicians(values) {
        this._clinicians = values;
    }

    get case_managers() {
        return this._case_managers;
    }

    set case_managers(values) {
        this._case_managers = values;
    }

    set care_team(values) {
        this._care_team = values;
        this.render();
    }

    get care_team() {
        return this._care_team;
    }

    constructor() {
        super();
        this._has_changes = false;
        this._editable = false;
        this._clinicians = null;
        this._care_team = null;
        this.changes = {};
        this.patient_access_changes = {};
    }

    connectedCallback() {
        this.template = () => {
            const { changes, patient, has_changes, editable } = this;
            const birth_date = changes.birth_date || patient.birth_date;

            const care_team_ids = this.care_team?.map(team_member => team_member.id) || [];
            const filtered_clinicians = this.clinicians?.filter(clinician => !care_team_ids.includes(clinician.id)) || [];

            return html`
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Profile Information
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col text-end">
                            </div>
                        </div>
                        ${!editable ? html`
                            <div class="row">
                                <h1 class="col">
                                    ${patient.first_name} ${patient.last_name}
                                    <a class="btn btn-link text-muted"
                                        style="position: absolute; right: 10px;"
                                        @click=${(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.editable = !this.editable
                    }}
                                    >
                                        <span class="material-symbols-outlined">edit</span>
                                    </a>
                                </h1>
                            </div>
                            <div class="row">
                                <div class="col" style="text-transform: capitalize;">${patient.sex}</div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span class="text-muted">DOB:</span> ${format(parseISODateWithoutTime(patient.birth_date), "MM/dd/yyyy")}
                                </div>
                            </div>
                            <div class="row mt-4">
                                <label class="form-label col-sm-4 text-muted">Medicare ID</label>
                                <div class="col-sm-8">${patient.medicare_id}</div>
                            </div>
                            <div class="row">
                                <label class="form-label col-sm-4 text-muted">MRN</label>
                                <div class="col-sm-8">${patient.mrn}</div>
                            </div>
                            <div class="row">
                                <label class="form-label col-sm-4 text-muted">Primary Phone</label>
                                <div class="col-sm-8">
                                    <span class="material-symbols-outlined" style='
                                        font-size: 1rem;
                                        margin-right: 7px;
                                        color: var(--t-color-primary);
                                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                    '>phone</span>
                                    <a href="tel:${patient.primary_phone}">${patient.primary_phone}</a></div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="form-label col-sm-4 text-muted">Secondary Phone</label>
                                <div class="col-sm-8">
                                    <span class="material-symbols-outlined" style='
                                        font-size: 1rem;
                                        margin-right: 7px;
                                        color: var(--t-color-primary);
                                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                    '>phone</span>
                                    <a href="tel:${patient.secondary_phone}">${patient.secondary_phone}</a></div>
                                </div>
                            </div>
                            <div class="row">
                                <label class="form-label col-sm-4 text-muted">Primary Clinician</label>
                                <div class="col-sm-8">${patient.primary_clinician_id ?
                        patient.primary_clinician_id.first_name + " " + patient.primary_clinician_id.last_name
                        : "Not set"}</div>
                            </div>
                            <div class="row">
                                <label class="form-label col-sm-4 text-muted">Case Manager</label>
                                <div class="col-sm-8">${patient.case_manager_id ?
                        patient.case_manager_id.first_name + " " + patient.case_manager_id.last_name
                        : "Not set"}</div>
                            </div>
                            ${this.editing_disabled ? html`
                            <div class="row mt-2">
                                <small class="text-muted"><strong>Note:</strong> This profile is managed by your EHR or other system, demographics cannot be edited here.</small>
                            </div>` : ""}
                        ` : html`
                        <form class="needs-validation" novalidate @submit=${(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                            <h5>Demographic</h5>
                            <div class="row">
                                <div class="col-sm-5">
                                    <label for="profile-first_name" class="form-label">First Name</label>
                                    <input type="text"
                                        id="profile-first_name"
                                        class="form-control"
                                        name="first_name"
                                        .value=${changes.first_name || patient.first_name}
                                        placeholder="first name"
                                        @input=${(e) => this.setPatientField(e)} />
                                </div>
                                <div class="col-sm-7">
                                    <label for="profile-last_name" class="form-label">Last Name</label>
                                    <input type="text"
                                        id="profile-last_name"
                                        class="form-control"
                                        name="last_name"
                                        .value=${changes.last_name || patient.last_name}
                                        placeholder="last name"
                                        @input=${(e) => this.setPatientField(e)} />
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <label for="profile-sex" class="form-label">Sex</label>
                                    <select id="profile-sex" class="form-select" name="sex" @change=${(e) => this.setPatientField(e)}>
                                        <option value="male" ?selected=${changes.sex === "male" || patient.sex === "male"}>Male</option>
                                        <option value="female" ?selected=${changes.sex === "female" || patient.sex === "female"}>Female</option>
                                        <option value="unknown" ?selected=${changes.sex === "unknown" || patient.sex === "unknown"}>Unknown</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <label for="profile-birth_date" class="form-label">Date of Birth</label>
                                    <input type="date"
                                        id="profile-birth_date"
                                        class="form-control"
                                        name="birth_date"
                                        .value=${birth_date instanceof Date ? format(birth_date, "yyyy-MM-dd") : birth_date}
                                        placeholder="first name"
                                        @input=${(e) => this.setPatientField(e)} />
                                </div>
                            </div>
                            <hr>
                            <h5>Contact</h5>
                            <div class="row mt-2">
                                <div class="col-sm">
                                    <label for="profile-primary_phone" class="form-label">Primary Phone</label>
                                    <input type="tel"
                                        id="profile-primary_phone"
                                        class="form-control"
                                        name="primary_phone"
                                        .value=${changes.primary_phone || patient.primary_phone}
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        placeholder="primary phone"
                                        @input=${(e) => this.setPatientField(e)} />
                                </div>
                                <div class="col-sm">
                                    <label for="profile-secondary_phone" class="form-label">Secondary Phone</label>
                                    <input type="tel"
                                        id="profile-secondary_phone"
                                        class="form-control"
                                        name="secondary_phone"
                                        .value=${changes.secondary_phone || patient.secondary_phone}
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        placeholder="secondary phone"
                                        @input=${(e) => this.setPatientField(e)} />
                                </div>
                            </div>
                            <hr>
                            <h5>Medical Identifiers</h5>
                            <div class="row mt-2">
                                <div class="col-sm">
                                    <label for="profile-medicare_id" class="form-label">Medicare ID</label>
                                    <input type="text"
                                        id="profile-medicare_id"
                                        class="form-control"
                                        name="medicare_id"
                                        .value=${changes.medicare_id || patient.medicare_id}
                                        placeholder="Medicare ID"
                                        @input=${(e) => this.setPatientField(e)} />
                                </div>
                                <div class="col-sm">
                                    <label for="profile-mrn" class="form-label">MRN</label>
                                    <input type="text"
                                        id="profile-mrn"
                                        class="form-control"
                                        name="mrn"
                                        .value=${changes.mrn || patient.mrn}
                                        placeholder="medical record number"
                                        @input=${(e) => this.setPatientField(e)} />
                                </div>
                            </div>
                            <hr>
                            <h5>Care Team</h5>
                            <div class="row">
                                <div class="col">
                                    <div class="input-group">
                                        <select id="potential-care-team-member" class="form-control">
                                            <option value="" disabled selected>Choose a clinician to add...</option>
                                            ${filtered_clinicians.length ? filtered_clinicians.map((clinician) => html`
                                            <option value="${clinician.id}">${clinician.first_name} ${clinician.last_name}</option>
                                            `) : ""}
                                        </select>
                                        <button
                                            type="button"
                                            class="btn btn-primary"
                                            @click=${(e) => this.handleCareTeamAdd()}
                                        >Add</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th title="Primary Clinician">PC</th>
                                                <th title="Case Manager">CM</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        ${this.care_team.length ? this.care_team.map((member) => html`
                                            <tr>
                                                <td>${member.first_name} ${member.last_name}</td>
                                                <td>
                                                    <input
                                                        type="radio"
                                                        name="primary_clinician"
                                                        value="${member.id}"
                                                        ?checked="${this.patient?.primary_clinician_id?.id === member.id}"
                                                        @change="${(e) => this.handlePrimaryClinicianChange(member)}"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="radio"
                                                        name="case_manager"
                                                        value="${member.id}"
                                                        ?checked="${this.patient?.case_manager_id?.id === member.id}"
                                                        @change="${(e) => this.handleCaseManagerChange(member)}"
                                                    />
                                                </td>
                                                <td>
                                                    <a href="javascript:void(0);" @click="${(e) => this.handleRemoveCareTeamMember(e, member)}">
                                                        <span class="material-symbols-outlined text-danger">delete</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        `) : ""}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--
                            <div class="row">
                                <div class="col">
                                    <label for="patient-primary_clinician" class="form-label">Primary Clinician</label>
                                    <select class="form-select" name="primary_clinician_id" @change=${(e) => this.setPatientField(e)}>
                                        <option ?selected=${!patient.primary_clinician_id} disabled>Select a primary clinician</option>
                                        ${Array.isArray(this.clinicians) ? this.clinicians.map((clinician) => html`
                                        <option
                                            value="${clinician.id}"
                                            ?selected=${changes.primary_clinician_id ? changes.primary_clinician_id === clinician.id : patient.primary_clinician_id?.id === clinician.id}
                                        >${clinician.first_name} ${clinician.last_name}</option>
                                        `) : ""}
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label for="patient-primary_clinician" class="form-label">Clinician</label>
                                    <select class="form-select" name="case_manager_id" @change=${(e) => this.setPatientField(e)}>
                                        <option ?selected=${!patient.case_manager_id} disabled>Select a case manager</option>
                                        ${Array.isArray(this.case_managers) ? this.case_managers.map((case_manager) => html`
                                        <option
                                            value="${case_manager.id}"
                                            ?selected=${changes.case_manager_id ? changes.case_manager_id === case_manager.id : patient.case_manager_id?.id === case_manager.id}
                                        >${case_manager.first_name} ${case_manager.last_name}</option>
                                        `) : ""}
                                    </select>
                                </div>
                            </div>
                            -->
                        </form>
                        `}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                            ${editable ? "Cancel" : "Close"}
                        </button>
                        ${editable ? html`<button
                            ?disabled=${!has_changes}
                            type="button"
                            class="btn btn-primary btn-danger modal-confirm-btn"
                            @click=${(_e) => this.handleConfirmClick()}>
                            Confirm
                        </button>` : ""}
                    </div>
                </div>
            </div>`;
        }

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        this.loadClinicians();
        this.loadPatientCareTeam();

        render(this.template(), this);
    }

    async expandPatient() {
        if (
            typeof this.patient.primary_clinician_id !== "object" ||
            typeof this.patient.case_manager_id !== "object"
        ) {
            this._patient = await directus.items("patient").readOne(this._patient.id, {
                fields: [
                    '*',
                    'current_episode_id.*',
                    'client_id.*',
                    'case_manager_id.*',
                    'primary_clinician_id.*',
                ]
            });
            this.render();
        }
    }

    async handleConfirmClick() {
        try {
            if (Object.keys(this.patient_access_changes).length > 0) {
                const to_remove = [];
                const to_add = [];
                for (const id in this.patient_access_changes) {
                    const action = this.patient_access_changes[id];

                    switch (action) {
                        case "remove":
                            to_remove.push(id);
                            break;
                        case "add":
                            const access_record = this.patient_access.find((i) => i.user_id === id);
                            // It already exists, and we've staged and unstaged a remove
                            if (access_record) break;
                            to_add.push({
                                patient_id: this.patient.id,
                                user_id: id,
                            });
                            break;
                    }
                }

                if (to_remove.length) {
                    await directus.items("patient_access").deleteMany(to_remove);
                    for (const id of to_remove) {
                        const pa = this.patient_access.find((i) => i.id === id);
                        if (
                            this.patient.case_manager_id === pa?.user_id?.id ||
                            this.patient.case_manager_id?.id === pa?.user_id?.id
                        ) {
                            this.changes.case_manager_id = null;
                        }
                        if (
                            this.patient.primary_clinician_id === pa?.user_id.id ||
                            this.patient.primary_clinician_id?.id === pa?.user_id.id
                        ) {
                            this.changes.primary_clinician_id = null;
                        }
                    }
                }

                if (to_add.length)
                    await directus.items("patient_access").createMany(to_add);
            }

            // If we have any patient changes, save those.
            if (Object.keys(this.changes).length > 0) {
                if (this.changes?.primary_clinician_id?.id)
                    this.changes.primary_clinician_id = this.changes.primary_clinician_id.id;
                if (this.changes?.case_manager_id?.id)
                    this.changes.case_manager_id = this.changes.case_manager_id.id;

                if (this.changes?.primary_phone) {
                    this.changes.primary_phone = this.changes.primary_phone.replace(/\D/g, '')
                }

                if (this.changes?.secondary_phone) {
                    this.changes.secondary_phone = this.changes.secondary_phone.replace(/\D/g, '')
                }

                const res = await directus.items("patient").updateOne(this.patient.id, this.changes, {
                    fields: [
                        '*',
                        'current_episode_id.*',
                        'client_id.*',
                    ]
                });

                this.changes = {};
            }
        } catch (err) {
            console.error(err);
            this.error_message = err.message;
            return;
        }

        // reload the patient to make sure that all
        this.patient = await Cache.getPatient(this.patient.id, true);
        this.dismiss({
            should_update: this.editable,
            patient: this.patient,
        });
    }

    async handleCareTeamAdd() {
        const id_to_add = this.querySelector("#potential-care-team-member").value;
        if (!id_to_add) return;

        this.care_team.push(this.clinicians.find((i) => i.id === id_to_add));
        this.patient_access_changes[id_to_add] = "add";
        this.hasChanges();
    }

    async handlePrimaryClinicianChange(member) {
        this.changes.primary_clinician_id = member;
        this.hasChanges();
    }

    async handleCaseManagerChange(member) {
        this.changes.case_manager_id = member;
        this.hasChanges();
    }

    /**
     * Removes the care team member
     * @param member
     * @returns {Promise<void>}
     */
    async handleRemoveCareTeamMember(e, member) {
        e.stopPropagation();
        const to_remove = this.patient_access.find((i) => member.id === i.user_id?.id);
        if (!to_remove) return;
        this.patient_access_changes[to_remove.id] = "remove";
        this.care_team = this.care_team.filter((i) => i.id !== to_remove.user_id?.id);
        this.hasChanges();
    }
    formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const digits = phoneNumber.replace(/\D/g, '');
        if (digits.length === 10) {
            return digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else {
            return phoneNumber; // Return as-is if not exactly 10 digits
        }
    }
    setPatientField(e) {
        const { value, name, type } = e.target;

        switch (type) {
            case "tel":
                if (value === this.patient[name]) {
                    this.changes[name] = null;
                    delete this.changes[name];
                } else {
                    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    // Adjusted the formatting here
                    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                    this.changes[name] = e.target.value;
                }

                break;
            case "date":
                if (value === this.patient[name]) {
                    this.changes[name] = null;
                    delete this.changes[name];
                } else {
                    // We have to do this crazy because of UTC oddities on first set, not sure why, but this fixes that.
                    const segments = value.split("-");
                    const new_date = new Date();
                    new_date.setFullYear(parseInt(segments[0]));
                    new_date.setMonth(parseInt(segments[1]) - 1);
                    new_date.setDate(parseInt(segments[2]));
                    new_date.setHours(12, 0, 0, 0);
                    this.changes[name] = new_date;
                }
                break;
            case "text":
            default:
                if (value === this.patient[name]) {
                    this.changes[name] = null;
                    delete this.changes[name];
                } else {
                    if (name === "case_manager_id") {
                        this.changes[name] = this.case_managers.find((i) => i.id === value);
                    } else if (name === "primary_clinician_id") {
                        this.changes[name] = this.clinicians.find((i) => i.id === value);
                    } else {
                        this.changes[name] = value;
                    }
                }
                break;
        }

        this.hasChanges();
    }

    hasChanges() {
        if (
            Object.keys(this.changes).length > 0 ||
            Object.keys(this.patient_access_changes).length > 0
        ) {
            this.has_changes = true;
        } else {
            this.has_changes = false;
        }
    }

    async loadClinicians(force) {
        if (force !== true && this.clinicians) return;
        // only load once unless force is set to true
        try {
            const client_id = typeof this.patient.client_id === "string" ? this.patient.client_id : this.patient.client_id.id;
            const res = await directus.users
                .readByQuery({
                    filter: {
                        client_access: {
                            client_id: client_id,
                        },
                        role: {
                            name: {
                                _in: ["Clinician", "Client"],
                            }
                        }
                    },
                    fields: ["*", "role.*", "client_access.*"],
                });

            const clinicians = [];
            const case_managers = [];

            for (const item of res.data) {
                clinicians.push(item);
                case_managers.push(item);
            }

            this.clinicians = clinicians;
            this.case_managers = case_managers;

            this.render();
        } catch (err) {
            console.error(err);
            this.clinicians = [];
        }
    }

    async loadPatientCareTeam(force) {
        if (!force && this.care_team) return;
        try {
            const res = await directus.items("patient_access").readByQuery({
                filter: {
                    patient_id: this.patient.id,
                },
                fields: ["id", "user_id.*"],
            });

            const client_id = getCurrentClientId();
            this.patient_access = res.data;
            this.care_team = res.data.map((i) => i.user_id);
        } catch (err) {
            console.error(err);
            this.care_team = [];
        }
    }
}

customElements.define("app-patient-profile-modal", AppPatientProfileModal);
