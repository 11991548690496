import { html, render } from "lit";
import { format } from "date-fns";
import SurveyDefinition from "shared/lib/lib-survey-definition";
import { getEpisodeWeek } from "shared/lib/lib-util";
import "shared/components/app-color-label";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"
//import "shared/components/app-report-filter";
window.luxon = { DateTime };

/**
 * A list of active alerts for the client dashboard.
 * @fires AppDashboardAlertList#alertclick when clicking on a list item
 */
class AppDashboardAlertList extends HTMLElement {

    constructor() {
        super();

        this._filter_alert_levels = [];
        this._initialized = false;


    }

    set alerts(value) {
        this._alerts = value;
        if (this.data_table && Array.isArray(value)) {  // Check if table exists and value is an array
            this.data_table.setData(value);  // Update the Tabulator table data
        }
        this.render();

        // console.log("set alerts", this.alerts.length);
    }


    get alerts() {
        return this._alerts;
    }

    set alert_metrics(value) {
        this._alert_metrics = value;
        this.render();
    }

    get alert_metrics() {
        return this._alert_metrics;
    }

    set filter_alert_levels(value) {
        this._filter_alert_levels = value;
        this.render();
    }

    get filter_alert_levels() {
        return this._filter_alert_levels;
    }


    connectedCallback() {
        //console.log("CC:   ", this.alerts.length);

        this.template = () => {
            return html`
        
		<div class="tabulator mod-base" id="all-allerts-table"></div>
		</div>
      `;
        }
    }

    render() {
        //console.log("render", this.alerts.length);
        if (this._initialized) return;
        if (!this.template) return;
        render(this.template(), this);
        this.init();
        if (this.table) {
            this.table.replaceData(this.value);
        }

    }

    async init() {
        //console.log("init", this.alerts.length);
        if (this._initialized) return; // New check
        if (!this.survey_definition) {
            this.survey_definition = await SurveyDefinition.getSurveyDefinition();
        }
        // Ensure that alerts are present and the survey definition is loaded before initializing table and rendering
        if (this.alerts && this.survey_definition && this.isConnected) {
            //this.render();
            //console.log("initTable", this.alerts.length);
            this.initTable();
        }
        this.setTableHeight();
    }

    initTable() {
        //console.log("initTable", this.alerts.length);
        if (this.data_table) return;
        this.data_table = new Tabulator('#all-allerts-table', {
            data: this.alerts,
            history: true,
            pagination: "local",
            paginationSize: 10,
            paginationCounter: "rows",
            layoutColumnsOnNewData: true,
            layout: "fitDataStretch",
            movableColumns: true,
            initialSort: [
                { column: "level", dir: "asc" },
            ],
            columns: [
                { title: "Patient", field: "patient_id.first_name", formatter: this.fieldFormatter, width: '30%' },
                { title: "Stats", field: "status", formatter: this.fieldFormatter, width: '10%' },
                {
                    title: "Level",
                    field: "level",
                    formatter: this.fieldFormatter,
                },
                { title: "Date", field: "date_created", formatter: this.fieldFormatter, width: '10%' },
                {
                    title: "Reason",
                    field: "reasonCustomField",
                    cssClass: "alert-reason",
                    headerSort: false,
                    width: '40%',
                    formatter: this.fieldFormatter.bind(this)
                },

            ],

        });
        this.data_table.on("rowClick", (e, row) => {
            this.handleAlertClick(row.getData());
        });

        // Integrate filter_alert_levels
        if (this._filter_alert_levels.length > 0) {
            this.data_table.setFilter("level", "in", this._filter_alert_levels);
        }
        this._initialized = true;
    }

    fieldFormatter(cell, formatterParams) {
        //console.log("fieldFormatter", this.alerts);
        let value = cell.getValue();
        let columnField = cell.getColumn().getField();
        let alertData = cell.getData();
        switch (columnField) {
            case "patient_id.first_name":
                let lastName = alertData.patient_id.last_name;
                return `${value} ${lastName}`;
            case "date_created":
                return DateTime.fromISO(value).toFormat('MM-dd-yyyy');
            case "reasonCustomField":
                const alertColor = this.getAlertColor(alertData.level);
                const iconName = alertData.level === 1 ? "report" : "warning";
                return `
                <div style="display: flex; justify-content: start; color: ${alertColor}">
                    <span class="material-symbols-outlined" style="margin-right: 7px; font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;">
                        ${iconName}
                    </span>
                    ${alertData.subject}
                </div>
            `;
            default:
                return value;
        }
    }

    getAlertColor(level) {
        switch (level) {
            case 1:
                return "#ca463d";
            case 2:
                return "#E4A00F";
            case 3:
                return "#201B3A";
            default:
                return "#000000";
        }
    }


    /**
     * @param {Object} alert
     * @fires AppDashboardAlertList#alertclick
     */
    handleAlertClick(alert) {
        this.dispatchEvent(
            new CustomEvent("alertclick", {
                detail: alert,
            })
        );
    }

    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }
}
customElements.define("app-dashboard-alert-list", AppDashboardAlertList);
export default AppDashboardAlertList;
