import { html, render } from "lit";
import { format } from "date-fns";
import { getEpisodeWeek } from "../../lib/lib-util";
import SurveyDefinition from "../../lib/lib-survey-definition";

/**
 * Component that displays a check point summary for the most recent patient episode.
 * @param {object} patient
 * @param {object} episode
 * @param {object[]} checkpoints
 */
export default class AppPatientCheckpointSummary extends HTMLElement {
    constructor() {
        super();
        this._patient = null;
        this._episode = null;
        this._checkpoints = null;
    }

    get patient() {
        return this._patient;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get episode() {
        return this._episode;
    }

    set episode(values) {
        this._episode = values;
        this.render();
    }

    get checkpoints() {
        return this._checkpoints;
    }

    set checkpoints(values) {
        if (Array.isArray(values) === false) this._checkpoints = [];
        else this._checkpoints = values;
        this.init();
    }

    connectedCallback() {
        this.template = () => {
            const items = [];
            for (const group in this._field_groups) {
                const filtered_fields = group.fields.filter((field) => this.hasBeenAsked(field.field));
                items.push(this.tableSectionTemplate(group, filtered_fields));
            }
            return html` <style>
                    .summary-table table td,
                    .summary-table table th {
                        text-align: start;
                    }
                </style>
                <div class="row" id="patient-checkpoint-summary">
                    <div class="col pb-2">
                        <h4>
                            Episode: ${format(new Date(this.episode.start_date), "MMM do, yyyy")} to
                            ${format(new Date(this.episode.end_date), "MMM do, yyyy")}
                        </h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <span style="margin-right: 10px;">Key:</span>
                        <span
                            class="material-symbols-outlined"
                            style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #ca463d"
                            >arrow_circle_down</span
                        >
                        declined
                        <span
                            class="material-symbols-outlined"
                            style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #a4d394"
                            >arrow_circle_up</span
                        >
                        improved
                        <span
                            class="material-symbols-outlined"
                            style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #74AAB7"
                            >circle</span
                        >
                        same
                    </div>
                </div>
                <table class="table table-striped summary-table">
                    ${items}
                </table>`;
        };

        this.tableSectionTemplate = (group, filtered_fields) => {
            if (filtered_fields.length === 0) {
                return "";
            }
            return html` <thead>
                    <tr>
                        <td><h3 style="font-size: 1.2rem;">${group.label}</h3></td>
                        ${this.checkpoints.map(
                (cp, _index) =>
                    html`<td>
                                    Week ${getEpisodeWeek(this.episode, cp.survey_completion_date)}
                                    <br /><small>${format(new Date(cp.survey_completion_date), "MMM do, yyyy")}</small>
                                </td>`
            )}
                    </tr>
                </thead>
                <tbody>
                    ${filtered_fields.map((field) => {
                return html`
                            <tr>
                                <td style="text-align: left">${field.label} ${this.getFieldIcon(field)}</td>
                                ${this.answerTemplate(field)}
                            </tr>
                        `;
            })}
                </tbody>`;
        };

        this.answerTemplate = (field) => {
            const items = [];
            for (let index = 0; index < this.checkpoints.length; index++) {
                const cp = this.checkpoints[index];
                const delta = cp["delta_" + field.field];
                if (delta > 0) {
                    items.push(html`<td style="text-align: left;">
                        <div class="d-md-none" style="color: #999; width: 30%; display: inline-block;">
                            Day #${cp.day}
                        </div>
                        <span
                            class="material-symbols-outlined"
                            style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #ca463d"
                            >arrow_circle_down</span
                        >
                        declined
                    </td>`);
                }
                if (delta < 0) {
                    items.push(html`<td style="text-align: left;">
                        <div class="d-md-none" style="color: #999; width: 30%; display: inline-block;">
                            Day #${cp.day}
                        </div>
                        <span
                            class="material-symbols-outlined"
                            style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #a4d394"
                            >arrow_circle_up</span
                        >
                        improved
                    </td>`);
                }

                if (!delta || delta == 0) {
                    items.push(html`<td style="text-align: left;">
                        <div class="d-md-none" style="color: #999; width: 30%; display: inline-block;">
                            Day #${cp.day}
                        </div>
                        <span
                            class="material-symbols-outlined"
                            style="font-size: 1rem; font-variation-settings: 'FILL' 1; color: #74AAB7"
                            >circle</span
                        >
                        same
                    </td>`);
                }
            }
            return items;
        };

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        if (!this.episode) return;
        if (!this.checkpoints) return;
        if (!this._field_groups) return;
        render(this.template(), this);
    }

    async init() {
        this.survey_definition = await SurveyDefinition.getSurveyDefinition();
        this._field_groups = this.survey_definition.getFieldGroups();
        this.render();
    }

    /**
     * Produce an html template containing a properly colored font icon representing the delta's trend: up, down, or no change.
     * @param {object} field The field definition to look up the proper delta
     * @returns html template
     */
    getFieldIcon(field) {
        if (!this.checkpoints) return;
        if (!this.checkpoints.length) return;
        let checkpoint = this.checkpoints[this.checkpoints.length - 1];
        let delta = checkpoint["delta_" + field.field];

        if (delta == 0) return "";

        //improved
        if (delta < 0) {
            return html`
                <span
                    class="material-symbols-outlined"
                    style="
                    font-size: 16px; 
                    color: #a4d394;
                    right: 30px;
                    font-variation-settings: 'FILL' 1
                    ">
                    check_circle
                </span>
            `;
        }

        //declined
        if (delta > 0) {
            return html`
                <span
                    class="material-symbols-outlined"
                    style="
                    font-size: 16px; 
                    right: 30px;
                    color: #ca463d;
                    ">
                    trending_down
                </span>
            `;
        }
    }

    /**
     * Determine if the field was ever used in any checkpoint
     * @param {string} field The field string to test against
     * @returns {boolean}
     */
    hasBeenAsked(field) {
        for (let checkpoint of this.checkpoints) {
            if (checkpoint[field] !== null && checkpoint[field] !== undefined) {
                return true;
            }
        }
        return false;
    }
}

customElements.define("app-patient-checkpoint-summary", AppPatientCheckpointSummary);
