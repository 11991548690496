import ApplicationState from "applicationstate";
import { html, render } from "lit";
import directus from '../lib/lib-directus';
import { getCurrentClientId } from "../lib/lib-user";

import SceneAvoidableEventsMetrics from "./scene-avoidable-events-metrics";
import SceneAvoidableEventsOverview from "./scene-avoidable-events-overview";
import { getAvoidableEventsMetrics } from "../lib/lib-avoidable-events";

export default class SceneAvoidableEvents extends HTMLElement {
    constructor() {
        super();
        const today = new Date();
        this._year = ApplicationState.get('app.avoidable_events.year') || today.getFullYear();
        this._month = ApplicationState.get('app.avoidable_events.month') || today.getMonth() + 1;

        this.client_kpis = {};
        this.financial_model = {};
    }

    set location(value) {
        // check if the path is the same, don't re-render if so.
        if (this._location && this._location.path === value.path) return;
        this._location = value;
        switch (value.route.path) {
            case "/avoidable-events/metrics":
                this.component = new SceneAvoidableEventsMetrics();
                this.page = "metrics";
                break;
            case "/avoidable-events":
            case "/avoidable-events/overview":
            default:
                this.component = new SceneAvoidableEventsOverview();
                this.page = "overview";
        }
        // pass in metrics prop
        this.component.metrics = this.metrics;
    }

    get page() {
        return this._page;
    }

    set page(value) {
        this._page = value;
        this.render();
    }

    get metrics() {
        return this._metrics;
    }
    set metrics(value) {
        this._metrics = value;
        this.component.metrics = value;
        this.render();
    }

    get month() {
        return this._month;
    }
    set month(value) {
        this._month = value;
        ApplicationState.set("app.avoidable_events.month", value);
        this.render();
    }

    get year() {
        return this._year;
    }
    set year(value) {
        this._year = value;
        ApplicationState.set("app.avoidable_events.year", value);
        this.render();
    }


    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #avoidable-events {
                        height: 100%;
                    }
                    #avoidable-events .row {
                        height: 100%;
                    }
                    #avoidable-events .scroll-container {
                        padding: 0 16px 16px 16px;
                        overflow-y: scroll;
                        height: calc(100% - 125px);
                    }
                    #avoidable-events select {
                        font-weight: 600;
                        color: var(--t-color-dark);
                        border: none;
                        box-shadow: var(--t-box-shadow);
                        cursor: pointer;
                    }
                    #avoidable-events #chart_wrapper > div {
                        padding: 0 16px;
                        margin-top: 32px;
                    }
                    #avoidable-events .scroll-container .col-12 > * {
                        height: 360px;
                    }
                </style>
                <div id="avoidable-events" class="container-fluid">
                    <div class="row">
                        <app-avoidable-events-header
                            .page=${this.page}
                            style="padding: 0 32px;"
                            class="col-12"
                        ></app-avoidable-events-header>
                        <div class="scroll-container">
                            ${this.renderDateSelect()}
                            ${this.component}
                        </div>
                    </div>
                </div>
            `;
        };

        this.loadData();
        this.render();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    renderDateSelect() {
        const years = [];
        let start_year = (new Date()).getFullYear() - 2;
        const max_year = start_year + 10;
        for (; start_year <= max_year; start_year++) {
            years.push(html`<option value=${start_year} ?selected=${this.year == start_year}>${start_year}</option>`)
        }
        return html`
            <div style="
                display: flex;
                margin: 16px 0 -16px 24px;
            ">
                <select 
                    class="form-select form-select-lg" 
                    id="month-select"
                    style="width: auto;" 
                    value=${this.month + ""} 
                    @change=${e => this.handleMonthChange(e)}
                >
                    <option value="1" ?selected=${this.month == 1}>January</option>
                    <option value="2" ?selected=${this.month == 2}>Feburary</option>
                    <option value="3" ?selected=${this.month == 3}>March</option>
                    <option value="4" ?selected=${this.month == 4}>April</option>
                    <option value="5" ?selected=${this.month == 5}>May</option>
                    <option value="6" ?selected=${this.month == 6}>June</option>
                    <option value="7" ?selected=${this.month == 7}>July</option>
                    <option value="8" ?selected=${this.month == 8}>August</option>
                    <option value="9" ?selected=${this.month == 9}>September</option>
                    <option value="10" ?selected=${this.month == 10}>October</option>
                    <option value="11" ?selected=${this.month == 11}>November</option>
                    <option value="12" ?selected=${this.month == 12}>December</option>
                </select>
                <select 
                    class="form-select form-select-lg" 
                    id="year-select"
                    style="width: auto; margin-left: 12px;" 
                    @change=${e => this.handleYearChange(e)}
                >
                    ${years}
                </select>
            </div>
        `

    }

    /**
     * Load new data for given month
     * @param {Event} e select change event
     */
    async handleMonthChange(e) {
        this.month = e.target.value;
        this.loadData();
    }

    /**
     * Load new data for given year
     * @param {Event} e select change event
     */
    async handleYearChange(e) {
        this.year = e.target.value;
        this.loadData();
    }

    async loadData() {
        const kpis_query = directus.items("client_kpis").readByQuery({
            filter: {
                client_id: getCurrentClientId(),
                year: this.year,
                _or: [
                    { month: { _null: true } },
                    { month: { _eq: this.month } }
                ],
            },
            limit: 1,
            // Prioritize monthly over yearly KPIs
            sort: "month",
        });

        const financial_model_query = directus.items("financial_model").readByQuery({
            filter: {
                model_name: "default",
                client_id: {
                    _eq: getCurrentClientId(),
                },
            },
            limit: 1,
        });

        const [kpis_res, financial_model_res] = await Promise.all([kpis_query, financial_model_query])
        this.client_kpis = kpis_res.data[0];
        this.financial_model = financial_model_res.data[0];
        this.metrics = getAvoidableEventsMetrics(this.client_kpis, this.financial_model);
    }
}

customElements.define("scene-avoidable-events", SceneAvoidableEvents);
