import { html, render } from "lit";
import ModalBase from './util/modal-base';

/**
 * @param modal_text
 */
export default class AppConfirmationModal extends ModalBase {
    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    .modal-confirm-btn {
                        background-color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                    }
                    .modal-confirm-btn:hover {
                        background-color: var(--t-color-danger-darkened);
                        border-color: var(--t-color-danger-darkened);
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">${this.modal_title ? this.modal_text : 'Confirmation'}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p>${this.modal_text ? this.modal_text : 'Action Success'}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary btn-danger modal-confirm-btn"
                                @click=${(_e) => this.handleConfirmClick()}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Click handler for 'Confirm' button.
     * Resolves the ModalBase promise by calling dismiss()
     */
    async handleConfirmClick() {
        this.dismiss({ confirmed: true });
    }
}

customElements.define("app-confirmation-modal", AppConfirmationModal);
