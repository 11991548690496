import { html, render } from "lit";
import "../components/patient/app-patient-note";
import "../components/patient/app-patient-notes.js";
import cache from "../lib/lib-cache.js";


class ScenePatientNotes extends HTMLElement {
    constructor() {
        super();
        this.boundResizeHandler = this.handleWindowResize.bind(this);
        this._current_tab = "notes";
    }

    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        if (!(this.patient?.id == value)) this.init();
    }

    get patient_id() {
        return this._patient_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    connectedCallback() {
        this.template = () => html`
            <style>
            scene-patient-notes .note-header {
                background-color: var(--t-color-primary);
                color: white;
                padding: 5px;
                font-size: 12px;
                font-weight: 700;
                display: block;
            }
            scene-patient-notes .nav-pills .nav-link {
                font-size: 14px;
                color: var(--t-color-dark);
                border: 1px solid #fff;
                margin-right: 4px;
            }
            scene-patient-notes .nav-pills .nav-link:hover {
                border: 1px solid var(--t-color-dark);
            }
            scene-patient-notes .nav-pills .nav-link.active {
                font-weight: 600;
                background-color: var(--t-color-primary);
            }
            scene-patient-notes .nav-pills .nav-link.active:hover {
                border: 1px solid #fff;
            }
            scene-patient-notes .list-group-item {
                border: none;
                border-radius: 8px;
                box-shadow: var(--t-box-shadow);
                margin-bottom: 8px;
            }
                @media (max-width: 768px) {
                }
                @media (min-width: 768px) {
                }
            </style>
            <app-patient-header .page=${"memos"} .patient=${this.patient}></app-patient-header>
            <div style="height: calc(100% - 32px - ${this._patient_header_height}px); overflow-y: auto; padding-top: 16px;">
            <div style="margin: 0 32px 16px 32px; border-radius: 8px; height: 100%;">
            <ul class="nav nav-pills" style="width: 100%; border-radius: 8px; background: #fff; box-shadow: var(--t-box-shadow); padding: 8px; position: relative; z-index: 1;">
                    ${this.links.map(link => html`
                        <li class="nav-item">
                            <a href=${link.url} class="nav-link${this._current_tab === link.page ? " active" : ""}">${link.title}</a>
                        </li>
                    `)}
                </ul>
                <div style="margin: 20px 0px 16px 0px; background-color: #fff; padding: 10px; box-shadow: var(--t-box-shadow); border-radius: 8px;">
                    <app-patient-notes .patient_id=${this.patient_id}></app-patient-notes>
                </div>
            </div>
            </div>
        `;

        this.render();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    get links() {
        const links = [
            { page: "all", url: `patients/${this.patient.id}/activity`, title: "All" },
            // { page: "alerts", url: `patients/${this.patient.id}/alerts`, title: "Alerts" },
            { page: "notes", url: `patients/${this.patient.id}/activity/notes`, title: "Memos" },
            { page: "communication", url: `patients/${this.patient.id}/activity/communication`, title: "Communication" },
            { page: "calls", url: `patients/${this.patient.id}/activity`, title: "Calls" },
            { page: "checkpoints", url: `patients/${this.patient.id}/activity`, title: "Checkpoints" },
            { page: "activity", url: `patients/${this.patient.id}/activity`, title: "Notes" },
            { page: "availability", url: `patients/${this.patient.id}/activity`, title: "Availability" },
        ];
        return links;
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }

    async init() {
        this.patient = await cache.getPatient(this.patient_id);
    }


    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = document.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

}

customElements.define("scene-patient-notes", ScenePatientNotes);
export default ScenePatientNotes;
