import ApplicationState from "applicationstate";
import directus from './lib-directus';
import { initCurrentClient } from "./lib-user";

/**
 * Ensures the user is logging into a valid app for their role, 
 * throwing an error if this is not the case.
 * @param {object} current_user directus user
 * @throws {Error} when the user has an invalid role for the current app.
 */
export function checkAppAccess(current_user) {
    const role_access_map = {
        "Administrator": ["client", "clinician", "staff"],
        "Client": ["client", "clinician"],
        "Clinician": ["clinician"],
        "Support": ["client", "clinician", "staff"],
        "Survey Manager": ["client", "clinician", "staff"],
        "Survey Team": ["client", "clinician", "staff"],
        "Agency Admin": ["client", "clinician", "staff"],
        "Engagement Specialist": ["client", "clinician", "staff"],
    };
    const app_name = ApplicationState.get("app.name");
    const user_role = current_user.role.name;
    if (user_role == "Administrator") {
        if (current_user.client_access?.length) {
            for (let access of current_user.client_access) {
                access.can_access_app = true;
            }
        }

    }
    if (!role_access_map[user_role].includes(app_name)) {
        throw Error(`You don't have permission for this app.`);
    }
}

/**
 * Check to see if the user session is valid and fetch the user from directus
 * @returns {object} the currently logged in derectus user
 * @throws {Error} when the user session cannot be refreshed or the user loaded
 */
export async function checkSession() {
    try {
        const token = await directus.auth.token;
        if (!token)
            return;
        await directus.auth.refreshIfExpired();
    } catch (cause) {
        clearUserData()
        throw new Error("could not refresh the users session session", { cause });
    }
    try {
        const user = await directus.users.me.read({
            fields: ["*", "client_access.client_id.*", "client_access.can_access_app", "role.name"],
        });
        await ApplicationState.set("app.user", user, { persist: false, immutable: true });
        await initCurrentClient()
        return user;
    } catch (cause) {
        throw new Error("could not load user from directus", { cause });
    }
}

/**
 * Log in to directus via an email and password and set the state object on success
 * @param {string} email the email used to log in to directus
 * @param {string} password the password to log in to directus
 * @returns {object} the user object
 * @throws {Error} when the user fails a login and when the user cannot be loaded
 */
export async function login(email, password) {
    try {
        const token = await directus.auth.login({ email, password });
        ApplicationState.set("app.directus.token", token);
    } catch (cause) {
        console.error(cause);
        throw new Error("Please re-check your email and password.", { cause });
    }

    try {
        const user = await directus.users.me.read({
            fields: ["*", "client_access.client_id.*", "client_access.can_access_app", "role.name"],
        });
        await ApplicationState.set("app.user", user, { persist: false });
        await initCurrentClient()
        return user;
    } catch (cause) {
        console.error(cause);
        throw new Error("Appologies, there was an internal error, please contact support.", { cause });
    }
}

/**
 * Log the user out of directus and destroy all user data in local storage and indexeddb.
 * @param {string} navigate_to the url or relative path to navigate to after logout, defaults to "/"
 */
export async function logout(navigate_to = "/") {
    clearUserData()
    try {
        await directus.auth.logout();
    } catch (err) {
        console.error(err);
    }

    location.href = navigate_to;
}

/**
 * Clears ApplicationState indexedDB and localStorage
 */
function clearUserData() {
    const app_name = ApplicationState.get("app.name");
    let delete_request = window.indexedDB.deleteDatabase(`vbh-${app_name}-app`);

    delete_request.onerror = function (_event) {
        alert("There was a problem resetting the data - please contact tech support");
    };

    delete_request.onsuccess = function (_event) { };

    localStorage.clear();
}
