import { html, render } from "lit";
import "../components/patient/app-patient-checkpoint-details";
import "../components/patient/app-patient-header";
import cache from "../lib/lib-cache";
import directus from "../lib/lib-directus";
import SurveyDefinition from "../lib/lib-survey-definition";

class ScenePatientCheckpointDetails extends HTMLElement {
    constructor() {
        super();
        this.fields = null;
        this.checkpoint = null;
        this.display_error = false;
        this.boundResizeHandler = this.handleWindowResize.bind(this);
    }

    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
        this.survey_id = value.params.survey_id;
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        if (value || !(this.patient?.id == value)) this.loadPatient();
    }

    get patient_id() {
        return this._patient_id;
    }

    set survey_id(value) {
        this._survey_id = value;
        if (value || !(this.survey_id?.id == value)) this.loadCheckpoint();
    }

    get survey_id() {
        return this._survey_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    connectedCallback() {
        this.errorTemplate = () => html`<div class="alert alert-danger">
            There was a problem with your request, please reload the page. If the problem persists please contact
            support.
        </div>`;

        this.template = () => html`
            <style>
                @media print {
                    app-nav-links {
                        display: none;
                        height: 0;
                    }
                    app-patient-checkpoint-details {
                        position: relative;
                        top: -92px;
                    }
                }
            </style>
            <app-patient-header .page=${"checkpoints"} .patient=${this.patient}></app-patient-header>
            <div style=${`height: calc(100% - 32px - ${this._patient_header_height}px); overflow-y: auto; padding-top: 16px;`}>
                <app-patient-checkpoint-details
                    style="margin: 0 32px;"
                    .checkpoint=${this.checkpoint}
                    .patient=${this.patient}
                    .field_groups=${this.field_groups}>
                </app-patient-checkpoint-details>
            </div>
        `;

        Object.assign(this.style, {
            display: "block",
            height: "100%",
        });

        this.render();
        window.addEventListener("resize", this.boundResizeHandler);
    }

    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        if (this.display_error) return render(this.errorTemplate(), this);
        if (!this.patient) return;

        render(this.template(), this);
    }

    /**
     * Update scroll container height at various breakpoints
     */
    handleWindowResize() {
        if (this._patient_header_height) {
            const new_header_height = document.querySelector('app-patient-header').clientHeight;
            if (new_header_height !== this._patient_header_height) {
                this._patient_header_height = new_header_height;
                this.render();
            }
        }
    }

    async loadCheckpoint() {
        try {
            this.survey_definition = await SurveyDefinition.getSurveyDefinition(this.survey_id);
            this.field_groups = this.survey_definition.getFieldGroups();
            let result = await directus.transport.get(`/vbh/measures_delta/${this.survey_id}`);
            this.checkpoint = result.data[0];
            this.render();
        } catch (err) {
            console.error(err);
        }
    }

    async loadPatient() {
        this.patient = await cache.getPatient(this.patient_id);
        if (!this._patient_header_height) {
            this._patient_header_height = this.querySelector('app-patient-header')?.clientHeight;
        }
        this.render();
    }
}

customElements.define("scene-patient-checkpoint-details", ScenePatientCheckpointDetails);
export default ScenePatientCheckpointDetails;
