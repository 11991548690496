import { html, render } from "lit";
import ModalBase from "../util/modal-base";

/**
 * @param modal_text
 */
export default class AppSoftphoneSettingsModal extends ModalBase {
    /**
     * This is the twilio Device instance
     */
    set device(value) {
        if (!value)
            return;
        this._device = value;
        this.render();
    }

    get device() {
        return this._device;
    }

    /**
     * @type {MediaDeviceInfo[]}
     */
    get input_devices() {
        if (!this.device)
            return [];
        return Array.from(this.device.audio.availableInputDevices.values());
    }

    /**
     * @type {MediaDeviceInfo[]}
     */
    get output_devices() {
        if (!this.device)
            return [];
        return Array.from(this.device.audio.availableOutputDevices.values());
    }

    get is_output_device_selection_supported() {
        return this.device?.audio?.isOutputSelectionSupported;
    }

    set selected_input_device(value) {
        if (!value)
            return;
        if (!this.device)
            return alert("Failed to set input device: no current audio device");
        this.device.audio.setInputDevice(value);
    }

    /**
     * @type {MediaDeviceInfo}
     */
    get selected_input_device() {
        return this.device?.audio?.inputDevice;
    }

    set selected_output_device(value) {
        if (!value)
            return;
        if (!this.device)
            return alert("Failed to set output device: no current audio device");
        this.device.audio.setOutputDevice(value);
    }

    /**
     * @type {MediaDeviceInfo}
     */
    get selected_output_device() {
        return this.device?.audio?.outputDevice;
    }

    /**
     * @type {MediaTrackConstraints}
     */
    get audio_constraints() {
        return this.device?.audio?.audioConstraints;
    }

    /**
     * @type {MediaTrackSupportedConstraints}
     */
    get supported_constraints() {
        if (!this._supported_constraints)
            this._supported_constraints = navigator.mediaDevices.getSupportedConstraints();
        return this._supported_constraints;
    }

    get noise_suppression_supported() {
        return this.supported_constraints?.noiseSuppression;
    }

    get noise_suppression() {
        return this.audio_constraints?.noiseSuppression;
    }

    set noise_suppression(value) {
        if (typeof value !== "boolean")
            return;

        for (let track of this.audio_tracks) {
            let constraints = track.getConstraints();
            constraints.noiseSuppression = value;
            track.applyConstraints(constraints);
        }
    }

    get auto_gain_control_supported() {
        return this.supported_constraints.autoGainControl;
    }

    get auto_gain_control() {
        return this.audio_constraints?.autoGainControl;
    }

    set auto_gain_control(value) {
        if (typeof value !== "boolean")
            return;

        for (let track of this.audio_tracks) {
            let constraints = track.getConstraints();
            constraints.autoGainControl = value;
            track.applyConstraints(constraints);
        }
    }

    get echo_cancellation_supported() {
        return this.supported_constraints?.echoCancellation;
    }

    get echo_cancellation() {
        return this.audio_constraints?.echoCancellation;
    }

    set echo_cancellation(value) {
        if (typeof value !== "boolean")
            return;

        for (let track of this.audio_tracks) {
            let constraints = track.getConstraints();
            constraints.echoCancellation = value;
            track.applyConstraints(constraints);
        }
    }

    /** @type {MediaStreamTrack[]} */
    get audio_tracks() {
        return this.device.audio.inputStream.getAudioTracks();
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    .modal-confirm-btn {
                        background-color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                    }
                    .modal-confirm-btn:hover {
                        background-color: var(--t-color-danger-darkened);
                        border-color: var(--t-color-danger-darkened);
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Call Settings</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-floating">
                                <select 
                                    @change=${e =>
                    this.selected_input_device = this.input_devices.find(
                        device => device.deviceId == e.target.value
                    )}
                                    class="form-select" 
                                    id="input_device">
                                    ${this.input_devices.map(
                        device => html`
                                        <option 
                                            ?selected=${device.deviceId == this.selected_input_device.deviceId}
                                            .device=${device}
                                            value=${device.deviceId}>
                                            ${device.label}
                                        </option>
                                        `
                    )}
                                </select>
                                <label for="input_device">Select input device</label>
                            </div>
                            <div class="form-floating">
                                <select 
                                    @change=${e =>
                    this.selected_output_device = this.output_devices.find(
                        device => device.deviceId == e.target.value
                    )}
                                    class="form-select" 
                                    id="speaker_device">
                                    ${this.output_devices.map(
                        device => html`
                                        <option 
                                            ?selected=${device.deviceId == this.selected_output_device.deviceId}
                                            .device=${device}
                                            value=${device.deviceId}>
                                            ${device.label}
                                        </option>
                                        `
                    )}
                                </select>
                                <label for="input_device">Select output (speaker) device</label>
                            </div>
                            <!-- <div class="form-check form-switch">
                                <input 
                                    ?disabled=${this.noise_suppression_supported} 
                                    @click=${e => this.noise_suppression = e.target.value} 
                                    .value=${this.noise_suppression}
                                    class="form-check-input" 
                                    type="checkbox" 
                                    role="switch" 
                                    id="enable_noise_suppression">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Enable noise suppression</label>
                            </div>
                            <div class="form-check form-switch">
                                <input 
                                    ?disabled=${this.echo_cancellation_supported} 
                                    @click=${e => this.echo_cancellation = e.target.value} 
                                    .value=${this.echo_cancellation}
                                    class="form-check-input" 
                                    type="checkbox" 
                                    role="switch" 
                                    id="enable_echo_cancellation">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Enable echo cancellation</label>
                            </div>
                            <div class="form-check form-switch">
                                <input 
                                    ?disabled=${this.auto_gain_control_supported} 
                                    @click=${e => this.auto_gain_control = e.target.value} 
                                    .value=${this.auto_gain_control}
                                    class="form-check-input" 
                                    type="checkbox" 
                                    role="switch" 
                                    id="enable_auto_gain_control">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Enable automatic volume control</label>
                            </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary btn-danger modal-confirm-btn"
                                @click=${(_e) => this.handleConfirmClick()}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    /**
     * Click handler for 'Confirm' button.
     * Resolves the ModalBase promise by calling dismiss()
     */
    async handleConfirmClick() {
        this.dismiss({ confirmed: true });
    }
}

customElements.define("app-softphone-settings-modal", AppSoftphoneSettingsModal);
