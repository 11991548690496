import { html, render } from "lit";
import directus from "../../lib/lib-directus";
import { getRandomString } from "../../lib/lib-util";
import ModalBase from "../util/modal-base";
import DataDefinition from "../../lib/lib-data-definition";

import "../form/form-input";
import "../form/form-select";
import "../form/form-textarea";
import { getCurrentClientId } from "../../lib/lib-user";
import ClientSettings from "../../lib/lib-client-settings";


export default class AppSettingsCareTeamAddModal extends ModalBase {
    get disabled() {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = !!value;
        this.render();
    }

    get caregiver() {
        return this._caregiver;
    }

    set caregiver(value) {
        this._caregiver = value;
    }

    get client_access() {
        return this._client_access;
    }

    set client_access(value) {
        this.client_access = value;
        this.render();
    }

    get duplicates() {
        return this._duplicates;
    }

    set duplicates(values) {
        this._duplicates = values;
        this.render();
    }

    get selected_duplicate() {
        return this._selected_duplicate;
    }

    set selected_duplicate(value) {
        this._selected_duplicate = value;
        this.render();
    }

    get errors() {
        return this._errors;
    }

    set errors(values) {
        this._errors = values || {};
        this.render();
    }

    get can_submit() {
        return this._can_submit;
    }

    set can_submit(value) {
        this._can_submit = !!value;
        this.render();
    }

    get is_existing() {
        return this._is_existing;
    }

    set is_existing(value) {
        this._is_existing = value;
        this.render();
    }

    get error_message() {
        return this._error_message;
    }

    set error_message(value) {
        this._error_message = value;
        this.render();
    }

    constructor() {
        super();
        this._caregiver = {
            "id": null,
            "first_name": "",
            "last_name": "",
            "email": "",
            "password": getRandomString(16),
            "location": null,
            "title": "",
            "description": "",
            "tags": null,
            "avatar": null,
            "language": null,
            "theme": "auto",
            "tfa_secret": null,
            "status": "archived",
            "role": null,
            "token": null,
            "provider": "default",
            "external_identifier": null,
            "auth_data": null,
            "email_notifications": false,
            "phone_number": "",
        };
        this._client_access = {};
        this.clinical_role_choices = [];
        this.qualification_level_conditions = [];
        this.roles = [];
        this._errors = {};
        this._disabled = false;
    }

    connectedCallback() {
        this.template = () => {
            const { caregiver, errors, selected_duplicate } = this;
            let qualification_level_choices = [];
            if (this.client_access.clinical_role) {
                let conditions = this.qualification_level_conditions.find((i) => i.name === this.client_access.clinical_role + "_conditions");
                qualification_level_choices = conditions.options.choices || [];
            }
            return html`
            <style>
                .modal-cancel-btn {
                    background-color: var(--t-color-grey);
                    border-color: var(--t-color-grey);
                }
                .modal-cancel-btn:hover {
                    background-color: var(--t-color-grey-darkened);
                    border-color: var(--t-color-grey-darkened);
                }
                .modal-confirm-btn {
                    background-color: var(--t-color-danger);
                    border-color: var(--t-color-danger);
                }
                .modal-confirm-btn:hover {
                    background-color: var(--t-color-danger-darkened);
                    border-color: var(--t-color-danger-darkened);
                }
            </style>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">New Care Team Member</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        ${this.error_message ? html`
                        <div class="row">
                            <div class="col">
                                <div class="alert alert-danger">
                                    ${this.error_message}
                                </div>
                            </div>
                        </div>
                        ` : ""}
                        ${selected_duplicate ? html`
                        <div class="row">
                            <div class="col">
                                <h4>Add ${selected_duplicate.first_name} ${selected_duplicate.last_name}?</h4>
                                <p>Do you want to add ${selected_duplicate.first_name} ${selected_duplicate.last_name} to your organization?</p>
                                <p>You will need to specify their role.</p>
                                <div class="alert alert-success" @click=${(e) => this.toggleDuplicate(e, d)}>
                                    <div class="d-flex align-items-center">
                                        <div class="me-2">
                                            <span class="material-symbols-outlined">check_circle</span>
                                        </div>
                                        <div>
                                            <div>
                                                <strong>Email:</strong> ${selected_duplicate.email}<br />
                                                <strong>Phone:</strong> ${selected_duplicate.phone_number}<br />
                                                <strong>Role:</strong> ${selected_duplicate.role?.name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ` : ""}
                        ${!this.selected_duplicate && this.duplicates && this.duplicates.length ? html`
                        <div class="row">
                            <div class="col">
                                <h4>NOTE:</h4>
                                <p>It looks like there are other users who use this email or phone number. Do you want to use any of these?</p>
                                ${this.duplicates.map((d) => html`
                                <div class="alert alert-warning" @click=${(e) => this.toggleDuplicate(e, d)}>
                                    <div class="d-flex align-items-center">
                                        <div class="me-2">
                                            <span class="material-symbols-outlined">check_circle</span>
                                        </div>
                                        <div>
                                            <h5>${d.first_name} ${d.last_name}</h5>
                                            <div>
                                                <strong>Email:</strong> ${d.email}<br />
                                               <!-- <strong>Phone:</strong> ${d.phone_number}<br />
                                                <strong>Role:</strong> ${d.role?.name} -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                `)}
                            </div>
                        </div>
                        ` : ""}
                        <form>
                            ${!this.selected_duplicate ? html`
                            <div class="row">
                                <div class="col-sm-5">
                                    <form-input
                                        inputid="caregiver-first_name"
                                        .errors=${errors.first_name}
                                        name="first_name"
                                        placeholder="First name"
                                        label="First Name"
                                        .value="${caregiver.first_name}"
                                        ?disabled=${this.disabled}
                                        @input=${(e) => this.setCareGiverField(e)}
                                    />
                                </div>
                                <div class="col-sm-7">
                                    <form-input type="text"
                                        inputid="caregiver-last_name"
                                        name="last_name"
                                        .errors=${errors.last_name}
                                        placeholder="Last name"
                                        label="Last Name"
                                        .value=${caregiver.last_name}
                                        @input=${(e) => this.setCareGiverField(e)} />
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col">
                                    <form-input type="tel"
                                        label="Phone"
                                        placeholder="Phone"
                                        inputid="caregiver-phone"
                                        name="phone_number"
                                        .errors=${errors.phone_number}
                                        .value=${caregiver.phone_number}
                                        ?disabled=${this.disabled}
                                        @input=${(e) => this.setCareGiverField(e)}
                                    />
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col">
                                    <form-input label="Email"
                                        inputid="caregiver-email"
                                        type="email"
                                        name="email"
                                        .errors=${errors.email}
                                        placeholder="Email"
                                        .value="${caregiver.email}"
                                        ?disabled=${this.disabled}
                                        @input=${(e) => this.setCareGiverField(e)}
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <form-textarea label="Description"
                                        inputid="caregiver-description"
                                        placeholder="Helpful information about this care team member..."
                                        value="${caregiver.description}"
                                        ?disabled=${this.disabled}
                                        @input=${(e) => this.setCareGiverField(e)}
                                    />
                                </div>
                            </div>
                            <hr>
                            <h5>Role</h5>
                            <div class="row">
                                <div class="col">
                                    <form-select
                                        inputid="caregiver_role"
                                        name="role"
                                        .errors=${errors.role}
                                        placeholder="Select main role"
                                        label="Main Role"
                                        value="${caregiver.role?.id}"
                                        .options=${this.roles}
                                        ?disabled=${this.disabled}
                                        @change=${(e) => this.setRole(e)}
                                    />
                                </div>
                            </div>
                            ` : ""}
                            ${caregiver.role?.name === "Clinician" || selected_duplicate?.role?.name === "Clinician" ? html`
                            <div class="row mt-1">
                                <div class="col">
                                    <form-select inputid="caregiver-clinical_role"
                                        name="clinical_role"
                                        .errors=${errors.clinical_role}
                                        label="Clinical Role"
                                        placeholder="Select clinical role"
                                        @change=${(e) => this.setClientAccessField(e)}
                                        ?disabled=${this.disabled}
                                        .options=${this.clinical_role_choices}
                                    />
                                </div>
                                <div class="col" style="display: flex; flex-direction: column;">
                                    <label class="form-label">Allow App Access</label>
                                    <app-toggle-switch 
                                        id="user-active-toggle"
                                        .enabled_text=${"Enabled"}
                                        .disabled_text=${"Disabled"}
                                        .enabled=${false}
                                        style="margin-bottom: 16px;"
                                        @toggle =${(e) => { this.enable_app_access = e.detail }}
                                    >
                                    </app-toggle-switch>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col">
                                    <form-select inputid="caregiver-qualification_level"
                                        name="qualification_level"
                                        .errors=${errors.qualification_level}
                                        label="Qualification Level"
                                        placeholder="Select qualification level"
                                        ?disabled=${this.disabled || qualification_level_choices.length === 0}
                                        @change=${(e) => this.setClientAccessField(e)}
                                        .options=${qualification_level_choices}
                                    />
                                </div>
                            </div>` : ""}
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            ?disabled=${!this.can_submit || this.disabled}
                            type="button"
                            class="btn btn-primary btn-danger modal-confirm-btn"
                            @click=${(_e) => this.handleConfirmClick()}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
            `;
        };

        this.render();
        this.loadFields();
        this.loadRoles();
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
    }

    setCareGiverField(e) {
        const { name, type, value } = e.target;

        switch (type) {
            case "tel":
                var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
                this.caregiver[name] = e.target.value;
                break;
            case "date":
                // We have to do this crazy because of UTC oddities on first set, not sure why, but this fixes that.
                const segments = value.split("-");
                const new_date = new Date();
                new_date.setFullYear(parseInt(segments[0]));
                new_date.setMonth(parseInt(segments[1]) - 1);
                new_date.setDate(parseInt(segments[2]));
                new_date.setHours(12, 0, 0, 0);
                this.caregiver[name] = new_date;
                break;
            default:
                this.caregiver[name] = value;
                break;
        }

        if (name === "email") {
            this.caregiver.email = this.caregiver.email.toLowerCase();
        }

        if (name === "email" || name === "phone_number") {
            this.detectDuplicates();
        }

        this.validateForm(false);
    }

    validateForm(show_errors) {
        const errors = {};
        let has_errors = false;
        const { caregiver, client_access } = this;
        const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!this.selected_duplicate) {
            if (!caregiver.first_name) {
                errors.first_name = ["Please provide a first name"];
                has_errors = true;
            } else {
                errors.first_name = null;
            }

            if (!caregiver.last_name) {
                errors.last_name = ["Please provide a last name"];
                has_errors = true;
            } else {
                errors.last_name = null;
            }

            /*
            if (!caregiver.phone_number) {
                errors.phone_number = ["please provide a phone number"];
                has_errors = true;
            } else {
                errors.phone_number = null;
            }
            */

            if (!caregiver.email) {
                errors.email = ["Please provide an email"];
                has_errors = true;
            } else if (EMAIL_REGEX.test(caregiver.email) === false) {
                errors.email = ["Please provide a valid email"];
                has_errors = true;
            } else {
                errors.email = null;
            }

            if (!caregiver.role) {
                errors.role = ["Please select a role"];
                has_errors = true;
            } else {
                errors.role = null;
            }
        }

        if (caregiver.role?.name === "Clinician") {
            if (!client_access.clinical_role) {
                errors.clinical_role = ["Please provide a clinical role"];
                has_errors = true;
            } else {
                errors.clinical_role = null;
            }

            if (!client_access.qualification_level) {
                errors.qualification_level = ["Please provide a qualification level"];
                has_errors = true;
            } else {
                errors.qualification_level = null;
            }
        }

        if (show_errors && has_errors) {
            this.errors = errors;
        } else {
            this.errors = {};
        }

        if (has_errors) {
            this.can_submit = false;
        } else {
            this.can_submit = true;
        }

        this.render();

        return !has_errors;
    }

    setClientAccessField(e) {
        const { name, value } = e.target;

        this.client_access[name] = value;

        this.validateForm();
    }

    setRole(e) {
        const role = this.roles.find((i) => {
            return i.value === e.target.value;
        });

        if (role) {
            if (this.selected_duplicate) {
                this.selected_duplicate.role = role.definition;
            } else {
                this.caregiver.role = role.definition;
            }
        }

        this.validateForm();
    }

    async loadFields() {
        const def = await DataDefinition.getDefinition("junction_directus_users_client");
        this.clinical_role_choices = def.field_dictionary.clinical_role.meta.options.choices || [];
        this.qualification_level_conditions = def.field_dictionary.qualification_level.meta.conditions || [];
        this.render();
    }

    async loadRoles() {
        const client_id = getCurrentClientId();
        const client_settings = await ClientSettings.getSettings(client_id);
        const selfmanaged = client_settings?.company?.selfmanaged?.value;
        let roles = ["Clinician", "Client"];

        if (selfmanaged) {
            roles = ["Clinician", "Agency Admin", "Engagement Specialist"];
        }
        const res = await directus.roles.readByQuery({
            filter: {
                name: {
                    _in: roles
                }
            }
        });

        const role_values = res.data.map((i) => {
            let name = i.name;
            if (name === "Client") {
                name = "Manager";
            }
            return { text: name, value: i.id, definition: i };
        });

        this.roles = role_values;
        this.render();
    }

    detectDuplicates() {
        clearTimeout(this._duplicates_debounce);

        this._duplicates_debounce = setTimeout(async () => {
            const filters = [];
            if (this.caregiver.email) {
                filters.push({
                    email: { _eq: this.caregiver.email },
                });
            }

            if (filters.length === 0) return;

            const search = await directus.users.readByQuery({
                filter: {
                    _or: filters,
                },
                fields: [
                    '*',
                    'client_access.*',
                    'role.*',
                ],
            });

            this.is_existing = search.data.length > 0;
            this.duplicates = search.data;
        }, 1000);
    }


    toggleDuplicate(e, duplicate) {
        e.stopPropagation();
        if (this.selected_duplicate && this.selected_duplicate.id === duplicate.id) {
            this.selected_duplicate = null;
        } else {
            this.selected_duplicate = duplicate;
            this.validateForm();
        }
    }

    async handleConfirmClick(e) {
        this.error_message = "";
        this.disabled = true;
        try {
            let caregiver_id;
            const client_id = getCurrentClientId();
            if (this.selected_duplicate) {
                caregiver_id = this.selected_duplicate.id;
                this.client_access.client_id = client_id;
                this.client_access.directus_users_id = caregiver_id;
                await directus.items("junction_directus_users_client").createOne(this.client_access);
            } else {
                if (!this.validateForm()) return;

                let new_caregiver = { ...this.caregiver };
                if (typeof new_caregiver.role === "object") {
                    new_caregiver.role = new_caregiver.role.id;
                }

                new_caregiver = await directus.users.createOne(
                    new_caregiver
                );
                caregiver_id = new_caregiver.id;

                this.client_access.client_id = client_id;
                this.client_access.directus_users_id = caregiver_id;
                let junction_record = await directus.items("junction_directus_users_client").createOne(this.client_access);

                if (this.caregiver.role?.name === "Client" || this.caregiver.role?.name === "Agency Admin" || this.caregiver.role?.name === "Engagement Specialist") {
                    await directus.transport.post(`/vbh/app_access/${client_id}/${caregiver_id}`, {
                        can_access_app: true
                    });
                }
                if (this.caregiver.role?.name === "Clinician") {
                    if (this.enable_app_access) {
                        await directus.transport.post(`/vbh/app_access/${client_id}/${caregiver_id}`, {
                            can_access_app: true
                        });
                    }
                }
            }

            const caregiver = await directus.users.readOne(caregiver_id, {
                fields: [
                    '*',
                    'role.*',
                    'client_access.*',
                ],
            });

            this.dismiss({ caregiver });
        } catch (err) {
            this.error_message = err.message;
            this.disabled = false;
        }

    }
}

customElements.define("app-settings-careteam-add-modal", AppSettingsCareTeamAddModal);
