import { html, render } from "lit";
import ModalBase from '../util/modal-base';
import './app-calendar-view.js';
import { getCurrentClient } from "../../lib/lib-user.js";

class AppCalendarViewModal extends ModalBase {
    constructor(patient) {
        super();
        this.patient = patient;
        this.addFlag = true;
        this.type = "all";
        this.sms_id = null;
    }

    connectedCallback() {
        this.render();
    }

    render() {
        const patientName = this.patient.first_name + " " + this.patient.last_name;
        const template = html`
            <style>
                .modal-header {
                    justify-content: center;
                }
                .btn-close {
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    right: 20px;
                    top: 15px;
                }
            </style>
            <div class="modal-dialog" style="max-width: 100%; margin: 0; height: 100%;">
                <div class="modal-content" style="border: none; height: 100%; border-radius: unset;">
                    <div class="modal-header" style="border: none;">
                        <h4 style="font-weight: 600;">
                            <a href="/staff/patients/${this.patient.id}/checkpoints" @click=${e => this.redirectToLink(e)} data-bs-dismiss="modal">
                                ${patientName}
                            </a>
                        </h4>
                        <h4 style="margin-left:5px;">  - ${getCurrentClient()?.name}</h4>
                        <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    
                                   <app-calendar-view
                    .patient=${this.patient}
                    .type=${this.type}
                    .addFlag=${this.addFlag}
                    .sms_id=${this.sms_id}
                ></app-calendar-view>
                </div>
            </div>
        `;

        render(template, this);
    }

    redirectToLink(e) {
        window.location.href = e.target.href;
    }
}

customElements.define('app-calendar-view-modal', AppCalendarViewModal);
export default AppCalendarViewModal;
