import { html, render } from "lit";
import cache from "../lib/lib-cache";
import directus from "../lib/lib-directus";

import "../components/patient/app-patient-header";
import "../components/patient/app-patient-oasis-detail";

export default class ScenePatientOasisDetail extends HTMLElement {
    constructor() {
        super();
        console.log("ScenePatientOasisDetail constructor");
    }

    set location(value) {
        this._location = value;
        this.patient_id = value.params.patient_id;
        this.oasis_id = value.params.oasis_id;
    }

    get location() {
        return this._location;
    }

    set patient_id(value) {
        this._patient_id = value;
        if (!(this.patient?.id == value)) this.loadPatient();
    }

    get patient_id() {
        return this._patient_id;
    }

    set patient(value) {
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    set oasis_id(value) {
        this._oasis_id = value;
        if (!(this.oasis?.id == value)) this.loadOasis();
    }

    get oasis_id() {
        return this._oasis_id;
    }

    set oasis(value) {
        this._oasis = value;
        this.render();
    }

    get oasis() {
        return this._oasis;
    }

    connectedCallback() {
        this.template = () => html`
            <app-patient-header .page=${"oasis"} .patient=${this.patient}></app-patient-header>
            <app-patient-oasis-detail .patient=${this.patient} .oasis=${this.oasis}></app-patient-oasis-detail>
        `;

        this.style.display = "block";
        this.style.height = "100%";
        this.style.overflowY = "auto";

        this.render();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;
        if (!this.oasis) return;

        render(this.template(), this);
    }

    async loadPatient() {
        this.patient = await cache.getPatient(this.patient_id);
        this.render();
    }

    async loadOasis() {
        this.oasis = await directus.items("hh_assessment_oasis").readOne(this.oasis_id);
        this.render();
    }
}

customElements.define("scene-patient-oasis-detail", ScenePatientOasisDetail);
