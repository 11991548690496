import { format } from 'date-fns';

/**
 * BootstrapTable cell formatter for report notes columns
 * @param {Object[]} notes array of flattened notes data for the current report record/row
 * @returns {string} html for a formatted list of notes with user details
 */
export function noteCellFormatter(notes) {
    return notes.map(note => `
        <div class="report-note">
            <div style="font-size: 12px; display: flex; align-items: center; position: relative;">
                Added by
                <span class="added-by" style="font-weight: 700; margin-left: 5px; margin-right: 5px;">
                    ${note?.user_created_first_name} ${note?.user_created_last_name}
                </span>
                on
                <span
                    style="margin-left: 5px; margin-right: 5px;"
                    class="added-date"
                    title=${note?.date_created ? format(new Date(note?.date_created), "MM/dd/y 'at' h:mm aaa") : null}
                >
                    ${new Date(note?.date_created).toLocaleDateString()}
                </span>
            </div>
            <span style="margin-left: 8px;">
                ${note.content}
            </span>
        </div>
    `).join('')
}