import { html, nothing, render } from 'lit';
import ApplicationState from 'applicationstate';
import { navigate } from '../lib/lib-router';
import '../components/filter/app-filter';
//import '../components/filter/app-filter-data-view';
import '../components/filter/app-filter-data-view-server';
import '../components/filter/app-filter-saved-views';
import '../components/filter/app-filter-bar-graph';
import '../components/filter/app-filter-big-number';
import { getCurrentClientId } from '../lib/lib-user';
import "../components/filter/app-filter-csv-export";
import AppPatientProfile from '../components/patient/app-patient-profile';
import { format } from 'date-fns';
import { DateTime } from "luxon";
window.luxon = { DateTime };

export default class SceneInsightsPatients extends HTMLElement {
    set filter_state(value) {
        this._filter_state = value;
    }

    /** @type {import('shared/components/filter/app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;
    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    get selected_columns() {
        return this._selected_columns;
    }

    set view(value) {
        this._view = value;
        //this.render();
    }

    get view() {
        return this._view;
    }

    get options() {
        return this._options;
    }

    set options(value) {
        this._options = value;

    }
    set table_columns(value) {
        this._table_columns = value;
    }
    get table_columns() {
        return this._table_columns;
    }
    constructor() {
        super();
        this._options = {
            ajaxURL: "/items/patient",
            ajaxParams: {},
            sortMode: "remote",
            filterMode: "remote",
            pagination: true,
            paginationMode: "remote",
            paginationSize: 25,
            paginationCounter: "rows",
            alignEmptyValues: "bottom",
            layout: "fitColumns",
            //layoutColumnsOnNewData: true,  
            movableColumns: true,
            initialSort: [{ column: "current_episode_start", dir: "desc" },],
            columnDefaults: {
                tooltip: true,
            },
            checkbox: false
        };
        this._selected_columns = [
            'first_name',
            'last_name',
            'client_id.name',
            'current_episode_start',
            'current_episode_end',
            'status',
            'birth_date',
            'mrn',
            "current_episode_id.start_date",
            //"visits",
            "visits.visit_date",
            "visits.visit_type",
            "visits.visit_status",
            "visit.visit_description",



        ];
        this.table_columns = [
            {
                name: "Patient Name",
                fields: ["last_name", "first_name"],
                field: "patient_name",

                sorter: "string",
                sorterParams: {
                    field: "last_name"
                },
                link: ", ",
            },
            {
                name: "Agency",
                fields: ["client_id.name"],
                field: "client_id.name"
            },

            {
                name: "Status",
                fields: ["status"],
                field: "status",
                formatter: function (cell, formatterParams) {
                    var value = cell.getValue();
                    return value.replace(/_/g, " ");
                },
            },
            {
                name: "DOB",
                fields: ["birth_date"],
                field: "birth_date"
            },
            {
                name: "MRN",
                fields: ["mrn"],
                field: "mrn"
            },
            //{
            //  name: "Episode",
            //  fields: ["current_episode_start", "current_episode_end"],
            //  field: "current_episode_range",
            //  sorter: "date",
            //    sorterParams: {
            //        format: "yyyy-MM-dd",
            //        field: "current_episode_start"
            //    },
            //  formatter: function(cell, formatterParams) {
            //
            //    let rowData = cell.getRow().getData();
            //    
            //    let start = format(new Date(rowData.current_episode_start), "MM/dd/yyyy");
            //    let end = format(new Date(rowData.current_episode_end), "MM/dd/yyyy");
            //
            //    return start + " - " + end;
            //
            //  } 
            //},        
            {
                name: "Episode",
                field: "current_episode_start",
                sorter: "date",
                sorterParams: {
                    format: "yyyy-MM-dd",
                    alignEmptyValues: "bottom",
                },
                formatter: function (cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let start = rowData.current_episode_start ? format(new Date(rowData.current_episode_start), "MM/dd/yyyy") : "(no start date)";
                    let end = rowData.current_episode_end ? format(new Date(rowData.current_episode_end), "MM/dd/yyyy") : "(no end date)";
                    return start + " - " + end;
                }
            },

            //{
            //    name: "Current Episode 2",
            //    field: "current_episode_start", // Assuming this is the field name in your data
            //    formatter: "datetime",
            //    formatterParams: {
            //        inputFormat: "yyyy-MM-dd",
            //        outputFormat: "MM/dd/yyyy",
            //        invalidPlaceholder: "(invalid date)"
            //    },
            //    sorter: "date",
            //    sorterParams: {
            //        format: "yyyy-MM-dd",
            //        alignEmptyValues: "top",
            //    }
            //},
            //
            //
            //{
            //    name: "Current 11",
            //    fields: ["current_episode_start", "current_episode_end"],
            //    field: "current_epsiode_range",
            //    link: " - ",
            //    formatter: "datetime",
            //    formatterParams: {
            //        inputFormat: "yyyy-MM-dd",
            //        outputFormat: "MM/dd/yyyy",
            //
            //        invalidPlaceholder: "",
            //        alignEmptyValues: "top",
            //    },
            //    sorter: "date", sorterParams: {
            //        format: "yyyy-MM-dd",
            //        field: "current_episode_start",
            //        alignEmptyValues: "top",
            //    },
            //}
        ];
        this._filter_config = {
            collection: { name: 'patient', auto_configure: true, depth: 3 },
            search_fields: [
                'first_name',
                'last_name',
                'mrn',
                'status',
                'client_id.name',
            ],
            field_rules: {
                hh_patient_reported_measures: {
                    mode: "blacklist",
                    fields: [
                        "user_created",
                        "date_created",
                        "user_updated",
                        "escalated_checkpoint",
                        "checkpoint_summary",
                        "pr_med_mgmt_discussion",
                        "pr_med_bottle_review",
                        "pr_ct_healthy_routine_discussion",
                        "pr_ct_healthy_routine_discussion",
                        "pr_ct_med_routine_established",
                        "pr_ct_listend_understood",
                        "pr_ct_visit_communication",
                        "pr_ct_gentle",
                        "pr_ct_kind_respectful",
                        "pr_ct_preparedness_visit",
                        "pr_ct_listened_understood",
                        "pr_ct_pain",
                        "submission",
                        "survey_schedule_item_id",
                    ],
                },
                patient: {
                    mode: "blacklist",
                    fields: [
                        "created_by_integration_id",
                        "updated_by_integration_id",
                        "scorecard",
                        "access",
                        "checkpoint_results",
                        "accordion-9h-2nxw",
                        "internal_memo",
                        "tasks",
                        "sort_date",
                        "user_created",
                        "user_updated",
                        "date_created",
                        "date_updated",
                        "last_task_id",
                    ],
                },
                client: {
                    mode: "whitelist",
                    fields: [
                        "name",
                    ]
                },
                episode: {
                    mode: "blacklist",
                    fields: [
                        "survey_schedule_id",
                        "auto_create_tasks",
                        "patient_id",
                        "user_created",
                        "user_updated",
                        "date_created",
                        "date_updated",
                    ],
                },
                alert: {
                    mode: "blacklist",
                    fields: [
                        "sort",
                    ],
                },
                directus_users: {
                    mode: "whitelist",
                    fields: [
                        "first_name",
                        "last_name",
                    ],
                }
            }
        };
    }

    connectedCallback() {
        this.template = () => {

            return html`
                <style>
                    /* TODO */
                    .task-row:hover {
                        background-color: #f0f0f0;
                        cursor: pointer;
                    }
                </style>
                <div class="container-fluid" style="height: 100%;">
                    <div class="row" style="padding: 0 32px;">
                        <nav class="navbar" id="patient-insights-nav">
                            <div style="display: flex; flex-direction: column; align-items:left;">
                                
                                <app-filter 
                                    @filter_change=${e => this.handleFilterChange(e.detail)}
                                    style="
                                    margin-right: 10px;
                                    "
                                    .expanded=${true}
                                    .filter_state=${this.filter_state}
                                    .config=${this._filter_config}
                                    .enable_aggregate=${false}
                                    .disable_sort=${false}
                                    .show_filters=${true}
                                ></app-filter>
                            </div>
                            <div style="display: flex; flex-direction: column; align-items:left;">
                                
                                <app-filter-saved-views
                                    .screen=${"patient_insights"}
                                    @filter_change=${e => this.handleFilterChange(e.detail)}
                                    .collection_name=${"patient"}
                                    .filter_state=${this.filter_state}
                                ></app-filter-saved-views>
                            </div>
                            <app-filter-csv-export 
                                style="position: absolute; top: -55px; right: 10px;"
                                .filter_state=${this.filter_state}
                                .selected_columns=${this.selected_columns}
                                .config=${this._filter_config} 
                                .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        }
                    ]
                }
                }
                            ></app-filter-csv-export>
                        </nav> 
                    </div>
                    <div class="row" style="padding: 0 32px;" id="patient-insights-scroll-container">
                        <app-filter-data-view-server
                            @data=${e => this.data = e.detail}
                            .config=${this._filter_config}
                            .options=${this.options}
                            .table_columns=${this.table_columns}
                            .filter_state=${this.filter_state}
                            .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        }
                    ]
                }
                }
                            .selected_columns=${this.selected_columns}
                            @select-row=${e => this.handleSelectPatient(e.detail)}
                            style="padding: 0;"
                        ></app-filter-data-view-server>
                    </div>
                </div>
            `
        };

        this.render();
        this.init();

        this.addEventListener("select-row", (e) => {
            if (e.detail?.id) {
                this.handleSelectPatient(e.detail);
            }
        });
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    init() {
        let filter_state = ApplicationState.get('app.insights_patients.filter_state');
        let filter_element = this.querySelector('app-filter');
        filter_element.filter_state = filter_state;
        this.filter_state = filter_state;
        this.render();
    }

    async handleFilterChange(filter_state) {
        ApplicationState.set('app.insights_patients.filter_state', filter_state, { immutable: true });
        this.filter_state = filter_state;
        this.render();
    }

    async handleSelectPatient(patient) {
        //await navigate(`patients/${patient.id}/checkpoints`);
        let component = new AppPatientProfile();
        component.patient = patient;
        /** @type {import("../app-drawer").DrawerOptions} */
        let drawer_options = {
            placement: 'end',
            title: `${patient.first_name} ${patient.last_name}`,
            component,
            show_backdrop: false
        }
        this.dispatchEvent(
            new CustomEvent('show_drawer',
                {
                    bubbles: true,
                    composed: true,
                    detail: drawer_options
                })
        )
    }

}

customElements.define('scene-insights-patients', SceneInsightsPatients);
