import ApplicationState from "applicationstate";
import { html, render } from "lit";
import { range } from 'lit/directives/range.js';
import { map } from 'lit/directives/map.js';
import DataDefinition from "../../lib/lib-data-definition";
import directus from "../../lib/lib-directus";
import ModalBase from '../util/modal-base';

const PAGE_SIZE = 20;

export default class AppFilterRelationSelectorModal extends ModalBase {

    set collection_name(value) {
        this._collection_name = value;
        this.init();
    }

    get collection_name() {
        return this._collection_name;
    }

    /**
     * @type {DataDefinition}
     */
    set definition(value) {
        this._definition = value;
    }

    get definition() {
        return this._definition;
    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    get selected_columns() {
        return this._selected_columns;
    }

    set multiselect(value) {
        this._multiselect = value;
    }

    get multiselect() {
        return this._multiselect;
    }

    get filter_config() {
        if (!this._filter_config)
            this._filter_config = {
                collection: { name: this.collection_name, auto_configure: true, depth: 3 },
            };
        return this._filter_config;
    }

    constructor() {
        super();
        this._page = 0;
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #filter-relation-modal .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    #filter-relation-modal .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    #filter-relation-modal .modal-confirm-btn {
                        background-color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                    }
                    #filter-relation-modal .modal-confirm-btn:hover {
                        background-color: var(--t-color-danger-darkened);
                        border-color: var(--t-color-danger-darkened);
                    }
                </style>
                <div style="max-width: fit-content" class="modal-dialog modal-dialog-centered" id="filter-relation-modal">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">${this.title}</h5>
                            <app-filter 
                                @filter_change=${e => this.handleFilterChange(e.detail)}
                                style="
                                    margin-left: 40px;
                                "
                                .value=${this.saved_query}
                                .config=${this.filter_config}
                                .enable_aggregate=${false}
                                ></app-filter>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="select_item_form" novalidate>
                            <div class="modal-body">
                                <app-filter-data-view
                                    .config=${this.filter_config}
                                    .filter_state=${this.filter_state}
                                    .selected_columns=${this.selected_columns}
                                    .selection_type=${this.multiselect ? 'multiple' : 'single'}
                                    @select-row=${e => this.handleSelectRow(e.detail)}
                                ></app-filter-data-view>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    id="confirm-button"
                                    @click=${e => this.handleSelectClick()}>
                                    Select
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    async init() {
        /** @type {DataDefinition} */
        this.definition = await DataDefinition.getDefinition(this.collection_name);
        this.selected_columns = this.definition.field_definition.slice(0, 4).map(
            column => column.field
        );
        this.render();
    }

    handleSelectRow(data) {
        this.selected_data = data;
    }

    async handleSelectClick() {
        if (!this.selected_data)
            return await this.dismiss();

        let value;
        if (Array.isArray(this.selected_data))
            value = this.selected_data.map(data => data.id);
        else
            value = this.selected_data.id;
        await this.dismiss({ value, data: this.selected_data });
    }

    async handleFilterChange(filter_state) {
        ApplicationState.set('app.tasks.filter_state', filter_state, { immutable: true });
        this.filter_state = filter_state;
        this.render();
    }
}

customElements.define("app-filter-relation-selector-modal", AppFilterRelationSelectorModal);
