/**
 * Renders a colored circle with label text
 * @param {string} text 
 * @param {string} color a css color string
 */
export default class AppColorLabel extends HTMLElement {
    connectedCallback() {
        this.innerHTML = /*html*/`
            <span
                class="material-symbols-outlined"
                style="
                    color: ${this.color}; 
                    font-size: 18px; 
                    height: 18px; 
                    font-variation-settings: 'FILL' 1;
                    align-self: flex-start;
                    margin-top: 2px;
            ">
                circle
            </span>
            <span style="margin-left: 2px;">
                ${this.text}
            <span>
        `

        Object.assign(this.style, {
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            height: "fit-content",
        })
    }
}

customElements.define("app-color-label", AppColorLabel);