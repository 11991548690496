import { html, render } from 'lit';
import { map } from 'lit/directives/map.js';
import { Dropdown } from 'bootstrap';
import AppFilterUtil from './util/app-filter-util';

/**
 * @typedef {Object} AggregateGroupColumn
 * @property {string} name - The name of the column
 * @property {import('./app-filter').FieldConfig} field_config - The field configuration object
 */

class AppFilterAggregateGroup extends HTMLElement {
    set collection_config(value) {
        this._collection_config = value;
        this.render();
    }

    /** @type {import('./app-filter').CollectionConfig} */
    get collection_config() {
        return this._collection_config;
    }

    set aggregate_group(value) {
        this._aggregate_group = value;
        this.render();
    }

    /**
     * @type {AggregateGroupColumn[]}
     */
    get aggregate_group() {
        return this._aggregate_group;
    }

    set aggregate_group_query(value) {
        this.parseAggregateGroupQuery(value);
        this.render();
    }

    get aggregate_group_query() {
        return this.getAggretateGroupQuery();
    }

    set max_groups(value) {
        this._max_groups = value;
    }

    get max_groups() {
        return this._max_groups || 1;
    }

    constructor() {
        super();
        this._aggregate_group = [];
    }

    connectedCallback() {
        this.template = () => html`
        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
        ">
            ${map(this.aggregate_group, (column, index) => html`
            <div 
                class="rounded-pill"
                style="
                    margin-right: 5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    font-size: 12px;
                    width: fit-content;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    padding-left: 5px;
                    padding-right: 5px;
                    border: 1px solid var(--t-color-grey);
                "
            >
                <span>
                    ${column.field_config.label}
                </span>
                <span
                    @click=${(e) => this.handleDeleteColumn(column)}
                    class="material-symbols-outlined"
                    style='
                        font-size: 14px;
                        margin-left: 5px;
                        color: var(--t-color-grey);
                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                    '>cancel</span>
            </div>
            `)}
            ${this.aggregate_group?.length < this.max_groups ?
                html`
            <div class="dropdown">
                <button style="font-size: 12px; padding: 5px;" class="btn dropdown-toggle app-filter-item-field-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    + Add
                </button>
                <ul style="font-size: 12px;" class="dropdown-menu">
                    ${this.collection_config?.fields?.map(
                    (field) => html`
                        <li>
                            <a
                                @click=${e => this.handleAddColumn(e, field)}
                                class="dropdown-item" 
                                href="#">${field.label}</a>
                        </li>
                        `
                )}
                </ul>
            </div>
            `: ''}
        </div>
        `;

        this.render();
    }

    render() {
        if (!this.template)
            return;

        render(this.template(), this);
    }

    /**
     * 
     * @param {AggregateGroupColumn[]} aggregate_group
     */
    getAggretateGroupQuery(aggregate_group) {
        if (!aggregate_group)
            aggregate_group = this.aggregate_group;
        return AppFilterUtil.getAggretateGroupQuery(aggregate_group);
    }

    /**
     * Add a column to the sortable list
     * @param {Event} e 
     * @param {import('./app-filter').FieldConfig} column 
     */
    handleAddColumn(e, column) {
        e.preventDefault();
        e.stopImmediatePropagation();

        let elements = this.querySelectorAll('.dropdown-toggle');
        let element = elements[elements.length - 1];
        let dropdown = new Dropdown(element);
        dropdown.hide();

        this.aggregate_group.push(
            {
                name: column.field,
                field_config: column
            }
        );

        this.render();
        element.focus();
        this.dispatchEvent(new CustomEvent('item-change', { bubbles: true, composed: true }));
    }

    /**
     * Handle removing a column
     * @param {SortColumn} column - The column to remove
     */
    handleDeleteColumn(column) {
        this.aggregate_group.splice(
            this.aggregate_group.findIndex(
                aggregate_group_column => aggregate_group_column.name == column.name
            ), 1
        );
        this.render();
        this.dispatchEvent(new CustomEvent('item-change', { bubbles: true, composed: true }));
    }

    parseAggregateGroupQuery(query) {
        for (let item in query) {
            let column_name = item;
            let field_config = this.collection_config.fields.find(
                field => field.field == column_name
            );
            /**@type {AggregateGroupColumn} */
            let aggregate_group_column = {
                name: column_name,
                field_config
            }

            this.aggregate_group.push(aggregate_group_column);
        }
    }
}

customElements.define('app-filter-aggregate-group', AppFilterAggregateGroup);
export default AppFilterAggregateGroup;